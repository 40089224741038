import ActionTypes from "../../constants/action-types";

const { SET_USER_DATA, SET_PAYMENT_DETAILS, SET_PROJECT_INFO, SET_CREDITS, SET_VC } = ActionTypes;

export const initialState = {
  /** New Start Started Here */
  isLoggedIn: false,
  dimensionName: "horizontal",
  /* ignored if set to -1 (unit - seconds) */
  projectTimeLimit: -1,
  projectInfo: {},
  credits: {},
  vc: {}
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        isLoggedIn: true,
        ...payload,
      };
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        isLoggedIn: true,
        ...payload,
      };
    case SET_PROJECT_INFO:
      return {
        ...state,
        ...payload,
      };
    case SET_CREDITS:
      return {
        ...state,
        credits: {
          ...action.payload
        }
      };
    case SET_VC:
      return {
        ...state,
        vc: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
