/* eslint-disable camelcase, prefer-const, one-var, operator-assignment, prefer-destructuring */
import { Map, Record } from "immutable";

/**
 * @typedef {Map<"x" | "y", number | undefined>} MouseClientPosition position relative to window
 */

/**
 * unique classname of selection box - for multiple classes, separate them with spaces
 *
 * (multimove and switch reuses TransformManager so classnames should be unique when using this option)
 * @typedef {string | null} SelectionBoxUniqueClassName
 */

/**
 * unique id of workspace
 * (multimove and switch modes are treated as separate workspaces)
 * @typedef {string | null} WorkspaceUniqueId
 */

/**
 * @typedef SelectionBoxMouseStatus
 * @property {boolean} result whether mouse is on transform manager
 * @property {MouseClientPosition | null} mouseClientPosition
 */

export const ImperativeRotateRecord = Record({ token: "", rotateTo: 0 });

const composedPath = (el) => {
  const path = [];
  while (el) {
    path.push(el);
    if (el.tagName === "HTML") {
      path.push(document);
      path.push(window);
      return path;
    }
    el = el.parentElement;
  }
  return path;
};

export const isMouseOn = (e, value) => {
  let i;
  const pathArray =
    e.path || (e.composedPath && e.composedPath()) || composedPath(e.target);
  if (pathArray.length > 0) {
    for (i = 0; i < pathArray.length; i += 1) {
      if (
        pathArray[i].className !== undefined &&
        typeof pathArray[i].className !== "object"
      ) {
        if (pathArray[i].className.indexOf(value) !== -1) {
          return true;
        }
      } else if (
        pathArray[i].className !== undefined &&
        typeof pathArray[i].className === "object"
      ) {
        if (pathArray[i].className.animVal === value) return true;
      }
    }
  }
  return false;
};

/**
 * @param {MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent} event mouse/touch event
 * @param {MouseClientPosition} clientOffset offset to add to mouse position
 * @returns {MouseClientPosition} position relative to window
 */
export const getMouseClientPosition = (event, clientOffset) => {
  let clientX;
  let clientY;

  if (event.touches && event.touches.length > 0) {
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  } else if (event.changedTouches && event.changedTouches.length > 0) {
    clientX = event.changedTouches[0].clientX;
    clientY = event.changedTouches[0].clientY;
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }

  if (clientOffset) {
    clientX = clientX + clientOffset.get("x");
    clientY = clientY + clientOffset.get("y");
  }

  return Map({
    x: clientX,
    y: clientY,
  });
};

/**
 * rotate point around given pivot (center)
 * @param {number} px x of pivot
 * @param {number} py y of pivot
 * @param {number} x x of point to rotate
 * @param {number} y y of point to rotate
 * @param {number} angle angle in degrees
 * @returns object containing rotated co-ord
 */
export const getRotatedPoint = (px, py, x, y, angle) => {
  const radian = (angle * Math.PI) / 180;
  return {
    x: (x - px) * Math.cos(radian) - (y - py) * Math.sin(radian) + px,
    y: (x - px) * Math.sin(radian) + (y - py) * Math.cos(radian) + py,
  };
};

/**
 * This function checks whether mouse position is inside selection box visually
 * but it cannot be used to check whether click is actually made on selection box
 * @param {object} params
 * @param {React.MutableRefObject<HTMLDivElement | null>} params.ref ref of transform manager
 * @param {SelectionBoxUniqueClassName} params.uniqueClassName unique classname of selection box
 * @param {React.MutableRefObject<HTMLDivElement | null>} params.workspaceRef ref of workspace
 * @param {WorkspaceUniqueId} params.workspaceUniqueId unique id of workspace
 * @param {MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent} params.event mouse/touch event
 * @returns {SelectionBoxMouseStatus | null} status or null on error
 */
export const isMouseOverSelectionBox = ({
  ref = null,
  uniqueClassName = null,
  workspaceRef = null,
  workspaceUniqueId = null,
  event,
} = {}) => {
  let toReturn = null;

  try {
    let selectionBox;
    let workspace;

    if (ref && ref.current) {
      selectionBox = ref.current;
    } else if (uniqueClassName) {
      const elList = document.getElementsByClassName(uniqueClassName);
      if (elList.length > 0) {
        selectionBox = elList[0];
      }
    }

    if (workspaceRef && workspaceRef.current) {
      workspace = workspaceRef.current;
    } else if (workspaceUniqueId) {
      workspace = document.getElementById(workspaceUniqueId);
    }

    if (selectionBox && workspace && event) {
      const clientMousePosition = getMouseClientPosition(event);

      if (
        clientMousePosition.get("x") === undefined ||
        clientMousePosition.get("y") === undefined
      ) {
        throw new Error("clientX or clientY not found!");
      }

      const angle = selectionBox.dataset.angle; // in deg

      const selBoxDim = {
        w: selectionBox.offsetWidth,
        h: selectionBox.offsetHeight,
      };

      const selBoxStyles = window.getComputedStyle(selectionBox);

      const selBoxMatrix = new window.DOMMatrixReadOnly(selBoxStyles.transform);
      const selBoxPos = {
        x: selBoxMatrix.m41,
        y: selBoxMatrix.m42,
      };

      const transformOriginPx = selBoxStyles.transformOrigin.split(" ");
      const selBoxPivot = {
        x: selBoxPos.x + parseFloat(transformOriginPx[0]),
        y: selBoxPos.y + parseFloat(transformOriginPx[1]),
      };

      const workspaceBounds = workspace.getBoundingClientRect();

      const mousePos = {
        // wrt to workspace
        x:
          clientMousePosition.get("x") -
          workspaceBounds.x +
          workspace.scrollLeft,
        y:
          clientMousePosition.get("y") -
          workspaceBounds.y +
          workspace.scrollTop,
      };

      const rotatedMousePos = getRotatedPoint(
        selBoxPivot.x,
        selBoxPivot.y,
        mousePos.x,
        mousePos.y,
        -angle // rotate mouse position at which selection box will be at angle 0
      );

      if (
        rotatedMousePos.x > selBoxPos.x &&
        rotatedMousePos.y > selBoxPos.y &&
        rotatedMousePos.x < selBoxPos.x + selBoxDim.w &&
        rotatedMousePos.y < selBoxPos.y + selBoxDim.h
      ) {
        toReturn = {
          result: true,
          mouseClientPosition: clientMousePosition,
        };
      } else {
        toReturn = {
          result: false,
          mouseClientPosition: clientMousePosition,
        };
      }
    } else {
      toReturn = {
        result: false,
        mouseClientPosition: null,
      };
    }
  } catch (error) {
    // errorlog(error, error.message, "isMouseOverSelectionBox");
    toReturn = null;
  }

  return toReturn;
};

/**
 * @param {MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent} e mouse/touch event
 * @returns {boolean} whether mouse is on workspace
 */
export const isMouseOnWorkspace = (e) => {
  /** @todo add tool-bar class to appropriate containers */
  return (
    !isMouseOn(e, "context-menu") &&
    !isMouseOn(e, "popup-component") &&
    !isMouseOn(e, "editicon-container") &&
    !isMouseOn(e, "bezier-path") &&
    !isMouseOn(e, "scene-item") &&
    !isMouseOn(e, "tool-bar") &&
    !isMouseOn(e, "handle") &&
    !isMouseOn(e, "keyframe-item") &&
    !isMouseOn(e, "cropBox") &&
    !isMouseOn(e, "cropSelectionBox") &&
    !isMouseOn(e, "unCroppedBox")
  );
};

export const getCorner = (pivotX, pivotY, cornerX, cornerY, angle) => {
  let x, y, distance, diffX, diffY;

  /// get distance from center to point
  diffX = cornerX - pivotX;
  diffY = cornerY - pivotY;
  distance = Math.sqrt(diffX * diffX + diffY * diffY);

  /// find angle from pivot to corner
  angle += Math.atan2(diffY, diffX);

  /// get new x and y and round it off to integer
  x = pivotX + distance * Math.cos(angle);
  y = pivotY + distance * Math.sin(angle);

  return { x, y };
};

export const getSelectionBoxBounds = (data) => {
  let angle = (data.angle * Math.PI) / 180;
  let c1, c2, c3, c4; /// corners
  let bx1, by1, bx2, by2;
  let bounds;
  c1 = getCorner(data.cx, data.cy, data.x, data.y, angle);
  c2 = getCorner(data.cx, data.cy, data.x + data.width, data.y, angle);
  c3 = getCorner(
    data.cx,
    data.cy,
    data.x + data.width,
    data.y + data.height,
    angle
  );
  c4 = getCorner(data.cx, data.cy, data.x, data.y + data.height, angle);
  /// get bounding box
  bx1 = Math.min(c1.x, c2.x, c3.x, c4.x);
  by1 = Math.min(c1.y, c2.y, c3.y, c4.y);
  bx2 = Math.max(c1.x, c2.x, c3.x, c4.x);
  by2 = Math.max(c1.y, c2.y, c3.y, c4.y);

  bounds = {
    x: parseFloat(bx1.toFixed(2)),
    y: parseFloat(by1.toFixed(2)),
    width: parseFloat((bx2 - bx1).toFixed(2)),
    height: parseFloat((by2 - by1).toFixed(2)),
  };

  return bounds;
};

export const getObjectsBounds = (objects) => {
  let minX = 0,
    maxX = 0,
    minY = 0,
    maxY = 0;
  let count = 0;
  objects.entrySeq().forEach(([, item]) => {
    let bounds, x, y, width, height;
    bounds = getSelectionBoxBounds({
      x: item.get("x"),
      y: item.get("y"),
      cx: item.get("x") + item.get("width") / 2,
      cy: item.get("y") + item.get("height") / 2,
      width: item.get("width"),
      height: item.get("height"),
      angle: item.get("angle"),
    });

    x = bounds.x;
    y = bounds.y;
    width = bounds.width;
    height = bounds.height;

    if (count === 0) {
      minX = x;
      maxX = x + width;
      minY = y;
      maxY = y + height;
      count += 1;
    } else {
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x + width);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y + height);
    }
  });

  return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
};

/**
 * Wrapper for workspace item bounds for convenience
 * @param {object} workspaceItem immutable workspace item
 */
export const getItemBounds = (workspaceItem) => {
  return getSelectionBoxBounds({
    x: workspaceItem.get("x"),
    y: workspaceItem.get("y"),
    width: workspaceItem.get("width"),
    height: workspaceItem.get("height"),
    cx: workspaceItem.get("x") + workspaceItem.get("width") / 2,
    cy: workspaceItem.get("y") + workspaceItem.get("height") / 2,
    angle: workspaceItem.get("angle"),
  });
};

function formatData(itemMap) {
  return {
    x: itemMap.get("x"),
    y: itemMap.get("y"),
    width: itemMap.get("width"),
    height: itemMap.get("height"),
    angle: itemMap.get("angle"),
    cx: itemMap.get("x") + itemMap.get("width") / 2,
    cy: itemMap.get("y") + itemMap.get("height") / 2,
  };
}

/**
 * @description Use this function to get items aligned to top
 * @param {Map} selectionBounds
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignTop(selectionBounds, selectedObjects) {
  let y = selectionBounds.get("y");
  let allItems = selectedObjects;
  allItems.entrySeq().forEach(([key, item]) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    let diff = itemBounds.y - y;
    let item_y = item.get("y") - diff;
    let item_to_update = Map({ y: item_y });
    allItems = allItems.set(key, item.merge(item_to_update));
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned to bottom
 * @param {Map} selectionBounds
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignBottom(selectionBounds, selectedObjects) {
  let y = selectionBounds.get("y");
  let height = selectionBounds.get("height");
  let allItems = selectedObjects;
  allItems.entrySeq().forEach(([key, item]) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    let diff = itemBounds.y + itemBounds.height - (y + height);
    let item_y = item.get("y") - diff;
    let item_to_update = Map({ y: item_y });
    allItems = allItems.set(key, item.merge(item_to_update));
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned to left
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignLeft(selectionBounds, selectedObjects) {
  let x = selectionBounds.get("x"),
    item_to_update;
  let allItems = selectedObjects;
  allItems.entrySeq().forEach(([key, item]) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    let diff = itemBounds.x - x;
    let item_x = item.get("x") - diff;
    item_to_update = Map({ x: item_x });
    allItems = allItems.set(key, item.merge(item_to_update));
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned to right
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignRight(selectionBounds, selectedObjects) {
  let x = selectionBounds.get("x"),
    item_to_update;
  let width = selectionBounds.get("width");
  let allItems = selectedObjects;
  allItems.entrySeq().forEach(([key, item]) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    let diff = itemBounds.x + itemBounds.width - (x + width);
    let item_x = item.get("x") - diff;
    item_to_update = Map({ x: item_x });
    allItems = allItems.set(key, item.merge(item_to_update));
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned to middle
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignMiddle(selectionBounds, selectedObjects) {
  let y = selectionBounds.get("y") + selectionBounds.get("height") / 2;
  let allItems = selectedObjects;
  allItems.entrySeq().forEach(([key, item]) => {
    let item_y = y - item.get("height") / 2;
    let item_to_update = Map({ y: item_y });
    allItems = allItems.set(key, item.merge(item_to_update));
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned to center
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignCenter(selectionBounds, selectedObjects) {
  let x = selectionBounds.get("x") + selectionBounds.get("width") / 2;
  let allItems = selectedObjects;
  allItems.entrySeq().forEach(([key, item]) => {
    let item_x = x - item.get("width") / 2;
    let item_to_update = Map({ x: item_x });
    allItems = allItems.set(key, item.merge(item_to_update));
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned vertically
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignVertically(selectionBounds, selectedObjects) {
  let sY = selectionBounds.get("y"),
    sHeight = selectionBounds.get("height");
  let allItems = selectedObjects;
  allItems = allItems.sort((item1, item2) => {
    if (item1.get("y") === item2.get("y")) {
      return 0;
    }
    return item1.get("y") < item2.get("y") ? -1 : 1;
  });
  let totalItemsHeight = allItems.reduce((partialSum, item) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    return partialSum + itemBounds.height;
  }, 0);
  let remHeight;
  if (sHeight >= totalItemsHeight) {
    remHeight = sHeight - totalItemsHeight;
  } else {
    remHeight = totalItemsHeight - sHeight;
  }
  let distributeHeight = remHeight / (allItems.size - 1);
  let prevItemSpace = sY;
  allItems.entrySeq().forEach(([key, item]) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    let shiftY = prevItemSpace;
    let diffX = shiftY - itemBounds.y;
    let item_y = item.get("y") + diffX;
    let item_to_update = Map({ y: item_y });
    allItems = allItems.set(key, item.merge(item_to_update));
    if (sHeight >= totalItemsHeight) {
      prevItemSpace += itemBounds.height + distributeHeight;
    } else {
      prevItemSpace += itemBounds.height - distributeHeight;
    }
  });
  return allItems;
}

/**
 * @description Use this function to get items aligned horizontally
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignHorizontally(selectionBounds, selectedObjects) {
  let sx = selectionBounds.get("x"),
    sWidth = selectionBounds.get("width");
  let allItems = selectedObjects;
  allItems = allItems.sort((item1, item2) => {
    if (item1.get("x") === item2.get("x")) {
      return 0;
    }
    return item1.get("x") < item2.get("x") ? -1 : 1;
  });
  let totalItemsWidth = allItems.reduce((partialSum, item) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    return partialSum + itemBounds.width;
  }, 0);
  let remWidth;
  if (sWidth >= totalItemsWidth) {
    remWidth = sWidth - totalItemsWidth;
  } else {
    remWidth = totalItemsWidth - sWidth;
  }
  let distributeWidth = remWidth / (allItems.size - 1);
  let prevItemSpace = sx;
  allItems.entrySeq().forEach(([key, item]) => {
    let itemData = formatData(item);
    let itemBounds = getSelectionBoxBounds(itemData);
    let shiftX = prevItemSpace;
    let diffX = shiftX - itemBounds.x;
    let item_x = item.get("x") + diffX;
    let item_to_update = Map({ x: item_x });
    allItems = allItems.set(key, item.merge(item_to_update));
    if (sWidth >= totalItemsWidth) {
      prevItemSpace += itemBounds.width + distributeWidth;
    } else {
      prevItemSpace += itemBounds.width - distributeWidth;
    }
  });
  return allItems;
}

/**
 * @description Use this function to tidy up items (horizontal and middle align)
 * @param {Map} selectionBounds bounds within which items to be aligned
 * @param {Map} selectedObjects Selected workspace items
 * @returns {Map} the aligned items
 */
export function alignTidyUp(selectionBounds, selectedObjects) {
  let alignedItems = alignHorizontally(selectionBounds, selectedObjects);
  alignedItems = alignMiddle(selectionBounds, alignedItems);
  return alignedItems;
}

/**
 * Function to get clip-path string to clip item which will produce border radius effect
 * @param {object} item
 */
export const getRadiusClipPath = (item, zoomFactor = 1) => {
  const width = item.get("width") * zoomFactor;
  const height = item.get("height") * zoomFactor;

  const topLeft = item.getIn(["radius", "tl"]);
  const topRight = item.getIn(["radius", "tr"]);
  const bottomRight = item.getIn(["radius", "br"]);
  const bottomLeft = item.getIn(["radius", "bl"]);

  const maxArcRadius = Math.min(width, height) / 2;

  const tl_arc_r = maxArcRadius * topLeft / 100;
  const tl_start = { x: 0, y: tl_arc_r };
  const tl_end = { x: tl_arc_r, y: 0 };

  const tr_arc_r = maxArcRadius * topRight / 100;
  const tr_start = { x: width - tr_arc_r, y: 0 };
  const tr_end = { x: width, y: tr_arc_r };

  const br_arc_r = maxArcRadius * bottomRight / 100;
  const br_start = { x: width, y: height - br_arc_r };
  const br_end = { x: width - br_arc_r, y: height };

  const bl_arc_r = maxArcRadius * bottomLeft / 100;
  const bl_start = { x: bl_arc_r, y: height };
  const bl_end = { x: 0, y: height - bl_arc_r };

  let clipPath = `M ${tl_start.x} ${tl_start.y} A ${tl_arc_r} ${tl_arc_r}, 0, 0, 1, ${tl_end.x} ${tl_end.y}`; // top-left
  clipPath = `${clipPath} L ${tr_start.x} ${tr_start.y} A ${tr_arc_r} ${tr_arc_r}, 0, 0, 1, ${tr_end.x} ${tr_end.y}`; // top-right
  clipPath = `${clipPath} L ${br_start.x} ${br_start.y} A ${br_arc_r} ${br_arc_r}, 0, 0, 1, ${br_end.x} ${br_end.y}`; // bottom-right
  clipPath = `${clipPath} L ${bl_start.x} ${bl_start.y} A ${bl_arc_r} ${bl_arc_r}, 0, 0, 1, ${bl_end.x} ${bl_end.y} Z`; // bottom-left

  return clipPath;
};

export const applyCropToDropItem = (dropItem, targetPlot, isCenterY = true) => {
  const drop_image_aspect_ratio = dropItem.libAssetHeight / dropItem.libAssetWidth;
  const item_plot = {
    w: targetPlot.width,
    h: targetPlot.height,
    x: targetPlot.x,
    y: targetPlot.y,
  }
  let uncropped_new_plot = {
    w: item_plot.w,
    h: item_plot.h,
    x: 0,
    y: 0,
  };

  uncropped_new_plot.h = uncropped_new_plot.w * drop_image_aspect_ratio; // try to match width and scale height

  if (uncropped_new_plot.h < item_plot.h) { // match height and scale width, else empty area will be shown
    uncropped_new_plot.h = item_plot.h;
    uncropped_new_plot.w = uncropped_new_plot.h / drop_image_aspect_ratio;
  }

  if (isCenterY) {
    const total_vertical_space = Math.abs(uncropped_new_plot.h - item_plot.h);
    uncropped_new_plot.y = -total_vertical_space / 2; // center crop
  }

  const total_horizontal_space = Math.abs(uncropped_new_plot.w - item_plot.w);
  uncropped_new_plot.x = -total_horizontal_space / 2; // center crop

  let drop_item_original = {
    width: uncropped_new_plot.w / item_plot.w,
    height: uncropped_new_plot.h / item_plot.h,
    x: uncropped_new_plot.x / item_plot.w,
    y: uncropped_new_plot.y / item_plot.h,
  }

  return { drop_item_original, item_plot };
}
