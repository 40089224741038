import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import vmTheme from "../constants/theme";
import { font } from "../constants/font";
import Typography from "./Typography";
import { setWorkspaceTextFocus } from "../redux/actions/appUtils";
import Action from "./Action";
import { STATIC_PATH } from "../constants/config";
import content from "../constants/content";

const ScriptContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .restriction {
    width: fit-content;
    display: inline-block;
  }
  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .music-upload{
    label{
      padding: 2px 0px 2px 20px;
      &::before{
        position: absolute;
      }
    }
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: ${(props) => props.height || "160px"};
  font: ${font.normal_14};
  padding: ${(props) => props.padding || "8px"};
  resize: ${(props) => props.resize || "none"};
  color: ${(props) => props.theme.panelPrimaryColor};
  outline: none;
  background: ${(props) => props.theme.polarColor};
  border-radius: 8px;
  border: none;
  &::placeholder {
    color: ${(props) => props.theme.sbPlaceholderColor};
    font: ${font.normal_14};
  }
`;

const TextAreaContainer = styled.div`
  border: 1px solid ${(props) => props.theme.searchBorder};
  border-radius: 8px;
  background: ${(props) => props.theme.polarColor};
`;

const TextArea = ({
  value,
  onChange,
  label,
  placeholder,
  children,
  labelColor,
  labelFont,
  resize,
  height,
  padding,
  defaultText,
  onUploadClick,
  isUploadVoice = false
}) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.app.get("theme"));

  const handleInputFocus = useCallback(
    (focused) => {
      dispatch(setWorkspaceTextFocus(focused));
    },
    [dispatch]
  );

  return (
    <ScriptContainer>
      <div className="bottom-section">
        <Typography
          innerContent={label}
          color={labelColor || vmTheme[theme].panelPrimaryColor}
          font={labelFont || font.mediumMiniBase}
          padding={"2px 0px 4px"}
        />
     
        {isUploadVoice && <Action
          text={content.UPLOAD_VOICE}
          width="132px"
          height="32px"
          onClick={onUploadClick}
          borderWidth={"1px"}
          customClass={"music-upload"}
          marginTop={"0px"}
          marginBottom={"0px"}
          background={vmTheme[theme].polarColor}
          borderColor={vmTheme[theme].searchBorder}
          hoverColor={vmTheme[theme].primaryBorderColor}
          font={font.mediumBase_21}
          fontColor={vmTheme[theme].panelPrimaryColor}
          icon={`${STATIC_PATH}uploads.svg`}
        />}
      </div>
      <TextAreaContainer>
        <StyledTextarea
          value={value}
          onChange={onChange}
          onFocus={() => handleInputFocus(true)}
          onBlur={() => handleInputFocus(false)}
          placeholder={placeholder}
          resize={resize}
          height={height}
          padding={padding}
        />
        {defaultText ? (
          <Typography
            display="block"
            innerContent={defaultText}
            font={font.normalBase_21}
            padding="8px"
            enableTrim={false}
            color={vmTheme[theme].labelTextColor}
          />
        ) : null}
      </TextAreaContainer>
      {children}
    </ScriptContainer>
  );
};

// Define prop types for the TextArea component
TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  children: PropTypes.element,
  labelColor: PropTypes.string,
  labelFont: PropTypes.string,
  resize: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  defaultText: PropTypes.string,
  onUploadClick: PropTypes.func,
  isUploadVoice: PropTypes.bool,
};

export default TextArea;