export default {
  START_RECORDING: "START_RECORDING",
  DASHBOARD: "DASHBOARD",
  OPTION: "OPTION",
  OFFICE_FILES: "OFFICE_FILES",
  HEADER_DASHBOARD: "HEADER_DASHBOARD",
  IMPORT: "IMPORT",
  CANCEL: "CANCEL",
  CANCELING: "CANCELING",
  FAIL_TO_CANCEL: "FAIL_TO_CANCEL",
  CLOSE: "CLOSE",
  REMOVE_BACKGROUND_MAIN: "REMOVE_BACKGROUND_MAIN",
  REMOVE_BACKGROUND_SUBTEXT: "REMOVE_BACKGROUND_SUBTEXT",
  REMOVE_BACKGROUND_IMAGE_SUBTEXT: "REMOVE_BACKGROUND_IMAGE_SUBTEXT",
  GREEN_SCREEN_MAIN: "GREEN_SCREEN_MAIN",
  GREEN_SCREEN_SUBTEXT: "GREEN_SCREEN_SUBTEXT",
  REVERSE_VIDEO: "REVERSE_VIDEO",
  REVERSE_VIDEO_SUBTEXT: "REVERSE_VIDEO_SUBTEXT",
  TRANSPARENCY: "TRANSPARENCY",
  DIRECTION: "DIRECTION",
  BRIGHTNESS: "BRIGHTNESS",
  BLUR: "BLUR",
  OFFSET: "OFFSET",
  THICKNESS: "THICKNESS",
  INTENSITY: "INTENSITY",
  DEPTH: "DEPTH",
  COLOR: "COLOR",
  COLOR_CH: "COLOR_CH",
  RESET_CLR: "RESET_CLR",
  THEMES: "THEMES",
  CUSTOM: "CUSTOM",
  RANGE_FROM_LEFT: "RANGE_FROM_LEFT",
  RANGE_FROM_CENTER: "RANGE_FROM_CENTER",
  COLORLESS: "COLORLESS",
  PROPERTIES: "PROPERTIES",
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  UPLOAD: "UPLOAD",
  MUSIC: "MUSIC",
  TRANSITIONS: "TRANSITIONS",
  FAVORITES: "FAVORITES",
  GIPHY: "GIPHY",
  SUBTITLE: "SUBTITLE",
  EXPORT: "EXPORT",
  EXPORT_VIDEO: "EXPORT_VIDEO",
  RESOLUTION: "RESOLUTION",
  THUMBNAIL: "THUMBNAIL",
  CREATE_NEW_VIDEO: "CREATE_NEW_VIDEO",
  SMART_THUMBNAIL: "SMART_THUMBNAIL",
  MAKE_COPY: "MAKE_COPY",
  MANAGE_EXPORT: "MANAGE_EXPORT",
  KEYBOARD_SHORTCUTS: "KEYBOARD_SHORTCUTS",
  VERSION_HISTORY: "VERSION_HISTORY",
  REFRESH: "REFRESH",
  HELP: "HELP",
  UPLOAD_LIMIT: "UPLOAD_LIMIT",
  UPLOAD_MEDIA: "UPLOAD_MEDIA",
  DRAG_AND_DROP: "DRAG_AND_DROP",
  FILE_UPLOADED_HERE: "FILE_UPLOADED_HERE",
  CREATE_FOLDER: "CREATE_FOLDER",
  CREATE: "CREATE",
  MY_FILES: "MY_FILES",
  CLEAR_ALL: "CLEAR_ALL",
  PROCESSING: "PROCESSING",
  SELECT_ALL: "SELECT_ALL",
  SETTINGS: "SETTINGS",
  EDIT_MUSIC: "EDIT_MUSIC",
  CROP: "CROP",
  CLIP_EXTRACT: "CLIP_EXTRACT",
  BG_REMOVAL: "BG_REMOVAL",
  REPLACE: "REPLACE",
  DUPLICATE: "DUPLICATE",
  COPY: "COPY",
  PASTE: "PASTE",
  COPY_STYLE: "COPY_STYLE",
  PASTE_STYLE: "PASTE_STYLE",
  ADD_A_HEADING: "ADD_A_HEADING",
  ADD_A_SUBHEADING: "ADD_A_SUBHEADING",
  ADD_BODY_TEXT: "ADD_BODY_TEXT",
  ADD_BULLET_TEXT: "ADD_BULLET_TEXT",
  MOVE_TO: "MOVE_TO",
  RENAME: "RENAME",
  DELETE: "DELETE",
  VIDEO_SPEED: "VIDEO_SPEED",
  AUDIO: "AUDIO",
  ADJUST_VIDEO: "ADJUST_VIDEO",
  ADJUST_IMAGE: "ADJUST_IMAGE",
  VOLUME: "VOLUME",
  UPGRADE: "UPGRADE",
  EDIT_VIDEO: "EDIT_VIDEO",
  EDIT_IMAGE: "EDIT_IMAGE",
  ANIMATION: "ANIMATION",
  APPLY_TO_ALL_VIDEOS: "APPLY_TO_ALL_VIDEOS",
  APPLY_TO_ALL_IMAGES: "APPLY_TO_ALL_IMAGES",
  FILTER_or0: "FILTER_or0",
  FILTER_ep1: "FILTER_ep1",
  FILTER_fe2: "FILTER_fe2",
  FILTER_bl3: "FILTER_bl3",
  FILTER_su4: "FILTER_su4",
  FILTER_af5: "FILTER_af5",
  FILTER_so6: "FILTER_so6",
  FILTER_se7: "FILTER_se7",
  FILTER_ca8: "FILTER_ca8",
  FILTER_aq9: "FILTER_aq9",
  FILTER_no10: "FILTER_no10",
  FILTER_wh11: "FILTER_wh11",
  FILTER_re12: "FILTER_re12",
  FILTER_ed13: "FILTER_ed13",
  FILTER_da14: "FILTER_da14",
  FILTER_pe15: "FILTER_pe15",
  FILTER_da16: "FILTER_da16",
  FILTER_ro17: "FILTER_ro17",
  FILTER_gr18: "FILTER_gr18",
  FILTER_st19: "FILTER_st19",
  FILTER_st20: "FILTER_st20",
  FILTER_se21: "FILTER_se21",
  FILTER_se22: "FILTER_se22",
  FILTER_se23: "FILTER_se23",
  FILTERS: "FILTERS",
  RESET_ALL: "RESET_ALL",
  ADJUSTMENT: "ADJUSTMENT",
  SATURATION: "SATURATION",
  CONTRAST: "CONTRAST",
  HUE: "HUE",
  TINT: "TINT",
  VIGNETTE: "VIGNETTE",
  IMG_FILTER_APPLYALL_H: "IMG_FILTER_APPLYALL_H",
  VID_FILTER_APPLYALL_H: "VID_FILTER_APPLYALL_H",
  APPLY_ALL: "APPLY_ALL",
  CLEAN_AUDIO: "CLEAN_AUDIO",
  CLEAN_AUDIO_SUBTEXT: "CLEAN_AUDIO_SUBTEXT",
  MUTE: "MUTE",
  DETACH_AUDIO: "DETACH_AUDIO",
  SOUND_EFFECTS: "SOUND_EFFECTS",
  VIEW_ALL: "VIEW_ALL",
  AUDIO_SPEED: "AUDIO_SPEED",
  PROPERTY: "PROPERTY",
  APPLY_TO_ALL_TEXTS: "APPLY_TO_ALL_TEXTS",
  TEXT_APPLYALL_H: "TEXT_APPLYALL_H",
  CURRENT_FONT_SIZE: "CURRENT_FONT_SIZE",
  CURRENT_FONT_STYLE: "CURRENT_FONT_STYLE",
  CURRENT_TEXT_FX: "CURRENT_TEXT_FX",
  FONT_SIZE: "FONT_SIZE",
  FONT: "FONT",
  SEARCH_FONTS: "SEARCH_FONTS",
  CH_FONT: "CH_FONT",
  BRAND_FONTS: "BRAND_FONTS",
  UPLOADED_FONTS: "UPLOADED_FONTS",
  DEFAULT_FONTS: "DEFAULT_FONTS",
  LETTER_SPACING: "LETTER_SPACING",
  LINE_HEIGHT: "LINE_HEIGHT",
  BOLD: "BOLD",
  ITALIC: "ITALIC",
  UNDERLINE: "UNDERLINE",
  BULLET: "BULLET",
  TEXT_TRANSFORM: "TEXT_TRANSFORM",
  EFFECTS: "EFFECTS",
  NONE: "NONE",
  SHADOW: "SHADOW",
  LIFT: "LIFT",
  HOLLOW: "HOLLOW",
  GLITCH: "GLITCH",
  ECHO: "ECHO",
  NEON: "NEON",
  SPLICE: "SPLICE",
  OUTLINE: "OUTLINE",
  MONTSERRAT: "MONTSERRAT",
  TEXT_TILE_DUMMY: "TEXT_TILE_DUMMY",
  VIDEO_SETTINGS: "VIDEO_SETTINGS",
  IMAGE_SETTINGS: "IMAGE_SETTINGS",
  AUDIO_SETTINGS: "AUDIO_SETTINGS",
  TEXT_SETTINGS: "TEXT_SETTINGS",
  PREMIUM_USED: "PREMIUM_USED",
  TWO_X_IMAGE: "TWO_X_IMAGE",
  FILE_TYPE: "FILE_TYPE",
  NAME: "NAME",
  FREE_USAGE_CREDIT_EXCEEDED: "FREE_USAGE_CREDIT_EXCEEDED",
  RADIUS: "RADIUS",
  ALIGN_ELEMENTS: "ALIGN_ELEMENTS",
  SPACE_EVENLY: "SPACE_EVENLY",
  POSITION: "POSITION",
  ALIGN_TO_PAGE: "ALIGN_TO_PAGE",
  FORWARD: "FORWARD",
  BACKWARD: "BACKWARD",
  TO_FRONT: "TO_FRONT",
  TO_BACK: "TO_BACK",
  TOP: "TOP",
  MIDDLE: "MIDDLE",
  LEFT: "LEFT",
  CENTER: "CENTER",
  BOTTOM: "BOTTOM",
  RIGHT: "RIGHT",
  VERTICALLY: "VERTICALLY",
  TIDY_UP: "TIDY_UP",
  HORIZONTALLY: "HORIZONTALLY",
  ROTATE: "ROTATE",
  ALIGNMENT: "ALIGNMENT",
  UNLOCK: "UNLOCK",
  FLIP: "FLIP",
  EXPAND: "EXPAND",
  APPLY: "APPLY",
  NO_TRANSITION: "NO_TRANSITION",
  FADE: "FADE",
  SIDE_LEFT: "SIDE_LEFT",
  SIDE_TOP: "SIDE_TOP",
  SIDE_BOTTOM: "SIDE_BOTTOM",
  SIDE_RIGHT: "SIDE_RIGHT",
  SENSITIVITY: "SENSITIVITY",
  FOLDER_NAME: "FOLDER_NAME",
  MOVE_HERE: "MOVE_HERE",
  FIT: "FIT",
  BUFFERING: "BUFFERING",
  ADD_MEDIA: "ADD_MEDIA",
  RECORD: "RECORD",
  SAVE: "SAVE",
  RESIZE: "RESIZE",
  RENAME_FOLDER: "RENAME_FOLDER",
  DELETE_FOLDER: "DELETE_FOLDER",
  DELETE_FOLDER_PROMPT: "DELETE_FOLDER_PROMPT",
  DELETE_FILE_PROMPT: "DELETE_FILE_PROMPT",
  NO_ASSETS_FOUND: "NO_ASSETS_FOUND",
  RENAME_FILE: "RENAME_FILE",
  DELETE_FILE: "DELETE_FILE",
  REMOVE_WATERMARK_PROMPT: "REMOVE_WATERMARK_PROMPT",
  FILE_RENAME_SUCCESSFUL: "FILE_RENAME_SUCCESSFUL",
  FILE_DELETE_SUCCESSFUL: "FILE_DELETE_SUCCESSFUL",
  FOLDER_RENAME_SUCCESSFUL: "FOLDER_RENAME_SUCCESSFUL",
  FOLDER_DELETE_SUCCESSFUL: "FOLDER_DELETE_SUCCESSFUL",
  FILE_SUCCESSFULLY_MOVED: "FILE_SUCCESSFULLY_MOVED",
  FOLDER_SUCCESSFULLY_MOVED: "FOLDER_SUCCCESSFULLY_MOVED",
  FOLDER_CREATED_SUCCESSFULLY: "FOLDER_CREATED_SUCCESSFULLY",
  FAILED_TO_UPLOAD: "FAILED_TO_UPLOAD",
  UPLOADING: "UPLOADING",
  UPLOAD_IN_PROCESS: "UPLOAD_IN_PROCESS",
  FILE_UPLOADED: "FILE_UPLOADED",
  ZOOM: "ZOOM",
  UNDO: "UNDO",
  REDO: "REDO",
  PREVIEW: "PREVIEW",
  SHARE: "SHARE",
  TOGGLE_MENU: "TOGGLE_MENU",
  TIMELINE: "TIMELINE",
  TIMELINE_ZOOM: "TIMELINE_ZOOM",
  RESET_TIMELINE_ZOOM: "RESET_TIMELINE_ZOOM",
  TOGGLE_TIMELINE: "TOGGLE_TIMELINE",
  PLAY_FROM_PLAYHEAD: "PLAY_FROM_PLAYHEAD",
  SPLIT: "SPLIT",
  PLAYHEAD_FORWARD: "PLAYHEAD_FORWARD",
  PLAYHEAD_BACKWARD: "PLAYHEAD_BACKWARD",
  SAVING: "SAVING",
  SAVED: "SAVED",
  EXPORT_SUCCESS: "EXPORT_SUCCESS",
  PAY_AND_DOWNLOAD: "PAY_AND_DOWNLOAD",
  PAY_AND_PUBLISH: "PAY_AND_PUBLISH",
  MONTHLY_CREDIT_EXCEED: "MONTHLY_CREDIT_EXCEED",
  UPGRADE_TO_PREMIUM: "UPGRADE_TO_PREMIUM",
  DOWNLOAD_WITHOUT_WATERMARK: "DOWNLOAD_WITHOUT_WATERMARK",
  DOWNLOAD: "DOWNLOAD",
  KEEP_WATERMARK: "KEEP_WATERMARK",
  TOTAL_AMOUNT: "TOTAL_AMOUNT",
  CHANGE: "CHANGE",
  MUSIC_USAGE_EXCEEDED: "MUSIC_USAGE_EXCEEDED",
  STARTER_MUSIC_LIMIT: "STARTER_MUSIC_LIMIT",
  TEAM_MUSIC_LIMIT: "TEAM_MUSIC_LIMIT",
  PREMIUM_USAGE_EXCEEDED: "PREMIUM_USAGE_EXCEEDED",
  EXHAUSTED_MONTHLY_LIMIT: "EXHAUSTED_MONTHLY_LIMIT",
  EXHAUSTED_MONTHLY_LIMIT_ENTERPRISE: "EXHAUSTED_MONTHLY_LIMIT_ENTERPRISE",
  STOCK_USAGE_PER_MONTH: "STOCK_USAGE_PER_MONTH",
  MUSIC_USAGE_PER_MONTH: "MUSIC_USAGE_PER_MONTH",
  FAILED_TO_DOWNLOAD: "FAILED_TO_DOWNLOAD",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  FILE_NOT_SUPPORTED: "FILE_NOT_SUPPORTED",
  FILE_IN_SAMEPLACE: "FILE_IN_SAMEPLACE",
  FOLDER_IN_SAMEPLACE: "FOLDER_IN_SAMEPLACE",
  TRY_AGAIN_WITH_DIFFERENT_FILE_TYPE: "TRY_AGAIN_WITH_DIFFERENT_FILE_TYPE",
  INVALID_REQUEST: "INVALID_REQUEST",
  UPLOAD_LIMIT_REACHED: "UPLOAD_LIMIT_REACHED",
  FILE_TOO_BIG: "FILE_TOO_BIG",
  UPLOAD_ERROR: "UPLOAD_ERROR",
  CONTACT_US: "CONTACT_US",
  ENTER_PROJECT_TITLE: "ENTER_PROJECT_TITLE",
  EMPTY_PROJECT: "EMPTY_PROJECT",
  AND_MUCH_MORE: "AND_MUCH_MORE",
  ENTERPRISE_PLAN: "ENTERPRISE_PLAN",
  ENJOY_NEW_FEATURES: "ENJOY_NEW_FEATURES",
  COMPANY_NAME: "COMPANY_NAME",
  CHOOSE_YOUR_PRICING_PLAN: "CHOOSE_YOUR_PRICING_PLAN",
  COMPARE_PLAN: "COMPARE_PLAN",
  TEAM: "TEAM",
  HIGH_DEFINITION: "HIGH_DEFINITION",
  TEAM_COLLABORATION: "TEAM_COLLABORATION",
  CUSTOM_BRANDING: "CUSTOM_BRANDING",
  UPLOAD_VIDEO: "UPLOAD_VIDEO",
  LOOKING_FOR_A_MORE_AWESOME: "LOOKING_FOR_A_MORE_AWESOME",
  EVERYTHING_IN_STARTER: "EVERYTHING_IN_STARTER",
  TOTAL_TIME: "TOTAL_TIME",
  TIME_AT_PLAYHEAD: "TIME_AT_PLAYHEAD",
  UNAUTHORIZED: "UNAUTHORIZED",
  FAILED_TO_GET_PANEL_DATA: "FAILED_TO_GET_PANEL_DATA",
  FAILED_TO_GET_CATEGORY_DATA: "FAILED_TO_GET_CATEGORY_DATA",
  FAILED_TO_FAVOURITE: "FAILED_TO_FAVOURITE",
  FAILED_TO_REMOVE_FAVOURITE: "FAILED_TO_REMOVE_FAVOURITE",
  FAILED_TO_SEARCH_MUSIC_DATA: "FAILED_TO_SEARCH_MUSIC_DATA",
  FAILED_TO_GET_GIPHY_DATA: "FAILED_TO_GET_GIPHY_DATA",
  FAILED_TO_EXPORT: "FAILED_TO_EXPORT",
  FAILED_TO_CREATE_FOLDER: "FAILED_TO_CREATE_FOLDER",
  FAILED_TO_DELETE_FOLDER: "FAILED_TO_DELETE_FOLDER",
  FAILED_TO_DELETE_FILE: "FAILED_TO_DELETE_FILE",
  FAILED_TO_RENAME_FILE: "FAILED_TO_RENAME_FILE",
  FAILED_TO_RENAME_FOLDER: "FAILED_TO_RENAME_FOLDER",
  FAILED_TO_MOVE_FOLDER: "FAILED_TO_MOVE_FOLDER",
  FAILED_TO_MOVE_FILE: "FAILED_TO_MOVE_FILE",
  FAILED_TO_GET_FILESYSTEM: "FAILED_TO_GET_FILESYSTEM",
  FAILED_TO_GET_UPLOADED_FILES: "FAILED_TO_GET_UPLOADED_FILES",
  FAILED_TO_DUPLICATE_PROJECT: "FAILED_TO_DUPLICATE_PROJECT",
  FAILED_TO_CREATE_BLANK_PROJECT: "FAILED_TO_CREATE_BLANK_PROJECT",
  FAILED_TO_GET_PROJECT_DETAILS: "FAILED_TO_GET_PROJECT_DETAILS",
  FAILED_TO_GET_USER_PROJECT_DETAILS: "FAILED_TO_GET_USER_PROJECT_DETAILS",
  FAILED_TO_GENERATE_NONCE: "FAILED_TO_GENERATE_NONCE",
  FAILED_TO_EXPORT_VIDEO: "FAILED_TO_EXPORT_VIDEO",
  FAILED_TO_LOAD_DATA: "FAILED_TO_LOAD_DATA",
  MEDIA_UNAVAILABLE: "MEDIA_UNAVAILABLE",
  ADD: "ADD",
  RESTORE_SUCCESSFULLY: "RESTORE_SUCCESSFULLY",
  RESTORE: "RESTORE",
  RESTORE_MESSAGE: "RESTORE_MESSAGE",
  RESTORE_FAILED: "RESTORE_FAILED",
  NO_VERSION_AVAILABLE: "NO_VERSION_AVAILABLE",
  ANIMATE: "ANIMATE",
  LANDSCAPE: "LANDSCAPE",
  POETRAIT: "POETRAIT",
  SQUARE: "SQUARE",
  TEXT_EFFECTS_APPLY_ALL: "TEXT_EFFECTS_APPLY_ALL",
  AUTOGENRATE: "AUTOGENRATE",
  AST_NO_SUB: "AST_NO_SUB",
  TRANSLATE: "TRANSLATE",
  MERGE_TEXT: "MERGE_TEXT",
  ADD_TEXT: "ADD_TEXT",
  YES: "YES",
  CONTACT_SALES: "CONTACT_SALES",
  PRICING: "PRICING",
  LAUNCH_PLUGIN: "LAUNCH_PLUGIN",
  ADD_TO_CHROME: "ADD_TO_CHROME",
  SUPPORT: "SUPPORT",
  FEEDBACK: "FEEDBACK",
  TERMS: "TERMS",
  GET_VMAKER_FOR_FREE: "GET_VMAKER_FOR_FREE",
  LINK_NOT_COPIED: "LINK_NOT_COPIED",
  LINK_COPIED: "LINK_COPIED",
  LOGIN: "LOGIN",
  FAILED_TO_SHARE: "FAILED_TO_SHARE",
  EDIT: "EDIT",
  VIEW: "VIEW",
  EDIT_PROJECT: "EDIT_PROJECT",
  APPLY_ALL_TEXT_SUCCESS: "APPLY_ALL_TEXT_SUCCESS",
  ADD_NEW_SUBTITLE_LINE: "ADD_NEW_SUBTITLE_LINE",
  ADD_SUBTITLE: "ADD_SUBTITLE",
  SUBTITLES: "SUBTITLES",
  LC_TITLE: "LC_TITLE",
  LC_MCRT: "LC_MCRT",
  FULL_PROJECT: "FULL_PROJECT",
  APPLY_EFFECTS_TO_ALL_IMAGES: "APPLY_EFFECTS_TO_ALL_IMAGES",
  APPLY_EFFECTS_TO_ALL_VIDEOS: "APPLY_EFFECTS_TO_ALL_VIDEOS",
  APPLY_ALL_VIDEOS_SUCCESS: "APPLY_ALL_VIDEOS_SUCCESS",
  APPLY_ALL_IMAGES_SUCCESS: "APPLY_ALL_IMAGES_SUCCESS",
  APPLY_TO_ALL_PROP: "APPLY_TO_ALL_PROP",
  APPLY_EFFECTS_TO_ALL_PROPERTIES: "APPLY_EFFECTS_TO_ALL_PROPERTIES",
  APPLY_ALL_PROPERTIES_SUCCESS: "APPLY_ALL_PROPERTIES_SUCCESS",
  WENT_WRONG: "WENT_WRONG",
  ENTER: "Enter",
  FAILED_TO_LOAD_SUBTITLE_DATA: "FAILED_TO_LOAD_SUBTITLE_DATA",
  FAILED_TO_IMPORT_SUBTITLE_DATA: "FAILED_TO_IMPORT_SUBTITLE_DATA",
  SUBTITLE_IMPORTED_SUCCESSFULLY: "SUBTITLE_IMPORTED_SUCCESSFULLY",
  FAILED_TO_UPDATE_SUBTITLE_DATA: "FAILED_TO_UPDATE_SUBTITLE_DATA",
  SMEDIA_CLR_TITLE: "SMEDIA_CLR_TITLE",
  SMEDIA_CLR_DES: "SMEDIA_CLR_DES",
  SMEDIA_CLR_ALL: "SMEDIA_CLR_ALL",
  SMEDIA_CLR_CNFM: "SMEDIA_CLR_CNFM",
  SMEDIA_CLR: "SMEDIA_CLR",
  COMING_SOON: "COMING_SOON",
  CPL: "CPL",
  CPL_RCL: "CPL_RCL",
  GENERATE_SUBTITLE: "GENERATE_SUBTITLE",
  IMPORT_SUBTITLE: "IMPORT_SUBTITLE",
  AUTO_CC_TT: "AUTO_CC_TT",
  TRANSLATING_SUBTITLE: "TRANSLATING_SUBTITLE",
  AUTO_CC_CN: "AUTO_CC_CN",
  MY_IMPRESSION: "MY_IMPRESSION",
  ALREADY_GENERATED: "ALREADY_GENERATED",
  CNFM_AUTOGEN_TITLE: "CNFM_AUTOGEN_TITLE",
  CNFM_AUTOGEN_DESC: "CNFM_AUTOGEN_DESC",
  CNFM_IMPORT_SUBTITLE_TITLE: "CNFM_IMPORT_SUBTITLE_TITLE",
  CNFM_IMPORT_SUBTITLE_DESC: "CNFM_IMPORT_SUBTITLE_DESC",
  LEFT_ALIGN: "LEFT_ALIGN",
  CENTER_ALIGN: "CENTER_ALIGN",
  RIGHT_ALIGN: "RIGHT_ALIGN",
  TEXT_COLOR: "TEXT_COLOR",
  TOGGLE_CASE: "TOGGLE_CASE",
  ELEMENTS: "ELEMENTS",
  STYLES: "STYLES",
  VIRAL_STYLE: "VIRAL_STYLE",
  THIS_WORKS_BEST_FOR_VERTICAL_VIDEOS: "THIS_WORKS_BEST_FOR_VERTICAL_VIDEOS",
  TRANSITION_BANNER: "TRANSITION_BANNER",
  TRANSITIONS_APPLY_ALL: "TRANSITIONS_APPLY_ALL",
  BACKGROUND: "BACKGROUND",
  DISTANCE: "DISTANCE",
  ANGLE: "ANGLE",
  APPLY_VIRAL_FAILED: "APPLY_VIRAL_FAILED",
  TRANSITION_REMOVED: "TRANSITION_REMOVED",
  SUBTITLE_STYLE: "SUBTITLE_STYLE",
  SELECT_TRANSTION_EFFECT: "SELECT_TRANSTION_EFFECT",
  SUPPORTED_SUBTITLE_FORMATS: "SUPPORTED_SUBTITLE_FORMATS",
  GREEN_SCREEN_NOT_ALLOWED_WITH_BG: "GREEN_SCREEN_NOT_ALLOWED_WITH_BG",
  BG_NOT_ALLOWED_WITH_GREEN_SCREEN: "BG_NOT_ALLOWED_WITH_GREEN_SCREEN",
  UNABLE_TO_ADD_ENTER_EFFECT: "UNABLE_TO_ADD_ENTER_EFFECT",
  UNABLE_TO_ADD_EXIT_EFFECT: "UNABLE_TO_ADD_EXIT_EFFECT",
  BURN_SUBTITLES: "BURN_SUBTITLES",
  SUITABLE_FOR: "SUITABLE_FOR",
  EDIT_AS_PROJECT: "EDIT_AS_PROJECT",
  SUBTITLE_STYLES: "SUBTITLE_STYLES",
  INCLUDE_THE_SUBTITLE: "INCLUDE_THE_SUBTITLE",
  UPLOAD_EXIST: "UPLOAD_EXIST",
  HEY_WAIT: "HEY_WAIT",
  UPLOAD_ANYWAY: "UPLOAD_ANYWAY",
  WILL_USE_THAT: "WILL_USE_THAT",
  RESTRICT_VIDEO_DURATION: "RESTRICT_VIDEO_DURATION",
  VIDEO_TOO_LONG: "VIDEO_TOO_LONG",
  UPLOAD_UNABLE_TO_DELETE: "UPLOAD_UNABLE_TO_DELETE",
  NO_SEARCH_FOUND: "NO_SEARCH_FOUND",
  UNABLE_TO_EXPORT: "UNABLE_TO_EXPORT",
  ADD_LANGUAGE: "ADD_LANGUAGE",
  DEFAULT: "DEFAULT",
  NO_TRANSCRIPTION: "NO_TRANSCRIPTION",
  TRANSLATE_SUBTITLE: "TRANSLATE_SUBTITLE",
  INSERT_SUBTITLE: "INSERT_SUBTITLE",
  FAILED_TO_TRANSLATE_SUBTITLE: "FAILED_TO_TRANSLATE_SUBTITLE",
  SUBTITLE_TRANSLATED_SUCCESSFULLY: "SUBTITLE_TRANSLATED_SUCCESSFULLY",
  MY_EXPORTS: "MY_EXPORTS",
  SHARE_PROJECT_WITH_OTHERS: "SHARE_PROJECT_WITH_OTHERS",
  NEW_TEXT_ANIMATIONS: "NEW_TEXT_ANIMATIONS",
  LOADING_SUBTITLE: "LOADING_SUBTITLE",
  SUBTITLE_DOWNLOAD_FAILED: "SUBTITLE_DOWNLOAD_FAILED",
  SUBTITLE_DOWNLOAD_SUCCESS: "SUBTITLE_DOWNLOAD_SUCCESS",
  INSERTING_SUBTITLE: "INSERTING_SUBTITLE",
  WHOOPS: "WHOOPS",
  UPGRADE_SUB_TEXT: "UPGRADE_SUB_TEXT",
  CREDIT_CARD: "CREDIT_CARD",
  PAYPAL: "PAYPAL",
  UPDATE: "UPDATE",
  COMPARE_PLANS: "COMPARE_PLANS",
  TRANSLATION_CREDITS: "TRANSLATION_CREDITS",
  CREDITS: "CREDITS",
  PREVIEW_BLURRY: "PREVIEW_BLURRY",
  PREMIUM_ASSETS: "PREMIUM_ASSETS",
  EXPORT_LIMIT_EXCEED: "EXPORT_LIMIT_EXCEED",
  EXPORT_LIMIT: "EXPORT_LIMIT",
  BACKGROUND_LIMIT_EXCEED: "BACKGROUND_LIMIT_EXCEED",
  PAY_PER_EXPORT: "PAY_PER_EXPORT",
  UPGRADE_BG_REMOVAL: "UPGRADE_BG_REMOVAL",
  UPGRADE_BG_GREEN: "UPGRADE_BG_GREEN",
  SUBTEXT_BG_REMOVAL: "SUBTEXT_BG_REMOVAL",
  SUBTEXT_SUBTITLE: "SUBTEXT_SUBTITLE",
  CHOOSE_PLAN: "CHOOSE_PLAN",
  DURATION_LIMIT_EXCEED: "DURATION_LIMIT_EXCEED",
  SUBTEXT_GREEN_REMOVAL: "SUBTEXT_GREEN_REMOVAL",
  UPGRADE_TRANSLATE: "UPGRADE_TRANSLATE",
  SUBTEXT_TRANSLATE: "SUBTEXT_TRANSLATE",
  UPLOADSRT: "UPLOADSRT",
  SALES_QUERY: "SALES_QUERY",
  THANKS_FOR_REACHING: "THANKS_FOR_REACHING",
  SUPPORT_MAIN_TEXT: "SUPPORT_MAIN_TEXT",
  SUPPORT_SUBTEXT: "SUPPORT_SUBTEXT",
  WAITING: "WAITING",
  UNSPLASH: "UNSPLASH",
  PEXELS_VIDEO: "PEXELS_VIDEO",
  PIXABAY: "PIXABAY",
  PIXABAY_VIDEO: "PIXABAY_VIDEO",
  MORE: "MORE",
  APP_AND_INTEGRATION: "APP_AND_INTEGRATION",
  PEXELS: "PEXELS",
  AVATARS: "AVATARS",
  AI_AVATAR: "AI_AVATAR",
  AI_AVATARS: "AI_AVATARS",
  LANGUAGE: "LANGUAGE",
  CHARACTER: "CHARACTER",
  SCRIPT: "SCRIPT",
  SCRIPTS: "SCRIPTS",
  VOICES: "VOICES",
  MALE: "MALE",
  HE_HIM: "HE_HIM",
  SHE_HER: "SHE_HER",
  LET_GET_YOUR_TEAM_WITH_VMAKER: "LET_GET_YOUR_TEAM_WITH_VMAKER",
  CREDITS_EXHAUSTED: "CREDITS_EXHAUSTED",
  ADDITIONAL_CREDITS: "ADDITIONAL_CREDITS",
  AVATAR_CREDITS: "AVATAR_CREDITS",
  REQUEST_SENT: "REQUEST_SENT",
  FAILED_TO_SEND: "FAILED_TO_SEND",
  SUBMIT: "SUBMIT",
  RUN_OUT_OF_CREDITS: "RUN_OUT_OF_CREDITS",
  UPGRADE_TO_ENJOY_CREDITS: "UPGRADE_TO_ENJOY_CREDITS",
  DISCOVER_AI_AVATAR: "DISCOVER_AI_AVATAR",
  SENDING: "SENDING",
  ESTIMATED_NUMBER_OF_VIDEOS: "ESTIMATED_NUMBER_OF_VIDEOS",
  PUBLISH_TO_YOUTUBE: "PUBLISH_TO_YOUTUBE",
  SELECT_CHANNEL: "SELECT_CHANNEL",
  YOUTUBE: "YouTube",
  DIRECT_DOWNLOAD: "DIRECT_DOWNLOAD",
  UPGRADE_TO_DOWNLOAD: "UPGRADE_TO_DOWNLOAD",
  CONGRATS_FREE_PUBLISH: "CONGRATS_FREE_PUBLISH",
  ADD_MORE_CHANNEL: "ADD_MORE_CHANNEL",
  PUBLISH: "PUBLISH",
  PUBLISH_IN_PROGRESS: "PUBLISH_IN_PROGRESS",
  PROCESSING_YOUR_VIDEO: "PROCESSING_YOUR_VIDEO",
  YOUR_VIDEO_PUBLISHED_SUCCESSFULLY: "YOUR_VIDEO_PUBLISHED_SUCCESSFULLY",
  YOUR_VIDEO_EXPORTED_SUCCESSFULLY: "YOUR_VIDEO_EXPORTED_SUCCESSFULLY",
  OPEN_LINK: "OPEN_LINK",
  DOWNLOAD_VIDEO: "DOWNLOAD_VIDEO",
  FULL_BODY: "FULL_BODY",
  CIRCLE: "CIRCLE",
  APPEARANCE: "APPEARANCE",
  GENERATE_VIDEO: "GENERATE_VIDEO",
  DELETE_THIS_CHANNEL: "DELETE_THIS_CHANNEL",
  PUBLISH_VIDEOS: "PUBLISH_VIDEOS",
  EXPORT_FAILED: "EXPORT_FAILED",
  DONT_WORRY: "DONT_WORRY",
  PLEASE_RE_EXPORT: "PLEASE_RE_EXPORT",
  MONTH_USER: "MONTH_USER",
  YEARLY_USER: "YEARLY_USER",
  GIF: "GIF",
  GIFT_IS_WAITING: "GIFT_IS_WAITING",
  RENDER_IN_PROGRESS: "RENDER_IN_PROGRESS",
  RENDERING: "RENDERING",
  STITCH_AUDIO_TO_GIF: "STITCH_AUDIO_TO_GIF",
  STITCH_ALL_AUDIO_FILES: "STITCH_ALL_AUDIO_FILES",
  STITCH_AUDIO_TO_VIDEO: "STITCH_AUDIO_TO_VIDEO",
  STITCH_ALL_FINAL_VIDEO: "STITCH_ALL_FINAL_VIDEO",
  READY_FOR_YOUTUBE_UPLOAD: "READY_FOR_YOUTUBE_UPLOAD",
  READY_FOR_YOUTUBE_LINK: "READY_FOR_YOUTUBE_LINK",
  READY_FOR_FACEBOOK_UPLOAD: "READY_FOR_FACEBOOK_UPLOAD",
  READY_FOR_FACEBOOK_LINK: "READY_FOR_FACEBOOK_LINK",
  GENERATE_DOWNLOAD_LINK: "GENERATE_DOWNLOAD_LINK",
  GENERATE_FINAL_DOWNLOAD_LINK: "GENERATE_FINAL_DOWNLOAD_LINK",
  VT_DOWNLOAD: "VT_DOWNLOAD",
  RENDER_EXPORT_FAILED: "RENDER_EXPORT_FAILED",
  RENDERING_FAILED: "RENDERING_FAILED",
  YOUTUBE_TITLE: "YOUTUBE_TITLE",
  VIDEO_IS_READY_FOR_EXPORT: "VIDEO_IS_READY_FOR_EXPORT",
  PREVIEW_IN_SD_QUALITY: "PREVIEW_IN_SD_QUALITY",
  SELECT_QUALITY: "SELECT_QUALITY",
  GENDER: "GENDER",
  NO_MATCHES_FOUND: "NO_MATCHES_FOUND",
  UPLOAD_AUDIO: "UPLOAD_AUDIO",
  ADD_CUSTOM_AVATAR: "ADD_CUSTOM_AVATAR",
  SELECT: "SELECT",
  REPLACE_VOICE: "REPLACE_VOICE",
  UPLOAD_VOICE: "UPLOAD_VOICE",
  UPLOAD_IMPORT_AUDIOS: "UPLOAD_IMPORT_AUDIOS",
  REPLACE_AVATAR: "REPLACE_AVATAR",
  YOU_CANNOT: "YOU_CANNOT",
  NO_AUDIO_FOUND: "NO_AUDIO_FOUND",
  STOCK_AVATARS: "STOCK_AVATARS",
  CUSTOM_AVATARS: "CUSTOM_AVATARS",
  ADD_TO_PROJECT: "ADD_TO_PROJECT"
};
