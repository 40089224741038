import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

export const LabelInput = styled.input.attrs({ type: "text" })`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  border: 1px solid
    ${(props) => (props.error ? props.theme.textboxErrorColor : props.theme.primaryBorderColor)};
  border-radius: 6px;
  padding: 8px;
  height: 36px;
  font: ${font.normalBase};
  color: ${props => props.theme.panelPrimaryColor};
  margin-bottom: 10px;
  margin: ${(props) => props.margin || "8px 0"};
  padding-left: ${(props) => (props.icon ? "40px" : "8px")};
  &:focus {
    outline: 0px;
    box-shadow: none;
    border-color: ${(props) =>
    props.error ? "red" : props.theme.primaryBorderColor};
  }
  &:hover {
    border-color: ${(props) =>
    props.error ? "red" : props.theme.primaryBorderColor};
  }
  ::placeholder {
    font: ${font.normalBase};
    padding-left: ${(props) => (props.icon ? "30px" : "0")};
    color: ${(props) => props.theme.labelPlaceholder};
  }
`;

const WrapperInput = styled.div`
  position: relative;
`;

const InputImage = styled.img`
  position: absolute;
  left: 10px;
  top: 14px;
`;

const Label = styled.label`
  display: block;
  font: ${(props) => props.labelFont || font.normalBase_16};
  color: ${(props) => props.labelColor};
  margin: ${(props) => props.labelMargin || "0px"};
`;

const LabelInputField = ({
  placeholder,
  onChange,
  onFocus,
  onBlur,
  width,
  icon,
  value,
  labelText,
  labelFont,
  labelColor,
  labelMargin,
  margin,
  error, // Added new error prop
}) => {
  return (
    <WrapperInput>
      {labelText && (
        <Label
          labelFont={labelFont}
          labelColor={labelColor}
          labelMargin={labelMargin}
        >
          {labelText}
        </Label>
      )}
      <LabelInput
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        width={width}
        icon={icon}
        value={value}
        margin={margin}
        error={error} // Pass error prop to styled component
      />
      {icon && <InputImage src={icon} />}
    </WrapperInput>
  );
};

LabelInputField.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  width: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  labelText: PropTypes.string,
  labelColor: PropTypes.string,
  labelFont: PropTypes.string,
  labelMargin: PropTypes.string,
  margin: PropTypes.string,
  error: PropTypes.bool, // Define error prop type
};

export default LabelInputField;
