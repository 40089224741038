import styled from "styled-components";
import { STATIC_PATH } from "../../constants/config";
import { font } from "../../constants/font";

export const PricingCard = styled.div`
  ~ #modal-portal .upgrade-modal {
    z-index: 109898;
    @media screen and (min-width: 768px) and (max-width: 992px) {
      width: 95%;
      height: auto;
      overflow: hidden;
    }
    @media screen and (max-width: 767px) {
      width: 95%;
      height: 100%;
      overflow: hidden;
    }
    @media screen and (min-width: 993px) and (max-width: 1020px) {
      width: 95%;
      height: 80%;
      overflow: hidden;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width:767px){
    flex-wrap: nowrap;
    padding-bottom: 20px;
    gap: 20px;
  }
`;
export const Container = styled.div`
  padding: 40px 32px 32px;
`;
export const SubHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  flex-wrap: wrap;
`;
export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`
export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const UpgradCard = styled.div`
  background: ${(props) =>
    props.enterprise
      ? "linear-gradient(122deg, #ffffff 0%, #ffffff 100%) padding-box,linear-gradient(180deg, #A253FF 0%, #7353FF 100%)"
      : "#ffffff"};
  box-shadow: 0px 1px 2px #6e6e6e29;
  border: ${(props) =>
    props.enterprise
      ? "2px solid transparent"
      : props.isPremium
        ? "2px solid #F55322"
        : "1px solid #e5e5e5"};
  border-radius: 18px;
  width: ${(props) => props.width || "320px"};
  height: ${(props) => props.height || "auto"};
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 3px 4px #6e6e6e29;
    transform: scale(1.01);
    ${Image} {
      transform: rotate(360deg) scale(1.02);
      transition-duration: 1s;
    }
  }
  ${(props) =>
    props.isPremium &&
    `
  &:after{
    content:"Recommended";
    background: transparent linear-gradient(180deg, #FF781A 0%, #F55322 100%);
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px 14px;
    border-radius: 0px 16px 0px 8px;
    font: ${font.largeBold_14};
    color: #FFFFFF;
  }
`}
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const Head = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  margin-bottom: 10px;
`;
export const Body = styled.div`
  gap: 24px;
  display: flex;
  padding: 32px 0px 0px;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 767px) and (max-width: 992px) {
    justify-content: center;
    height: 67vh;
    overflow: auto;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    height: 75vh;
    overflow: auto;
    flex-wrap: wrap;
  }
`;

export const AmountSection = styled.div`
  display: flex;
  position: relative;
  label:nth-child(2) {
    position: absolute;
    bottom: 6px;
    left: 64px;
  }
  label:nth-child(1) {
    position: relative;
    padding: 15px 10px;
    &::before {
      content: "$";
      width: 1px;
      height: 1px;
      font-size: 12px;
      position: absolute;
      left: 0px;
      top: 5px;
    }
  }
`;

export const UnorderList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 215px;
`;

export const List = styled.li`
  padding-left: 30px;
  margin: 12px 0 0;
  width: 260px;
  position: relative;
  &::before {
    content: url(${STATIC_PATH}orange-tick.svg);
    position: absolute;
    left: 0px;
    top: 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: self-start;
  flex-wrap: wrap;
`;

export const FormDataHeader = styled.div`
  width: 620px;
  padding: ${(props) => props.padding || "40px 0px 24px"};
  position: relative;
  .message-input input {
    height: 75px;
  }
  .back-icon {
    left: 0;
    top: 15px;
    cursor: pointer;
  }
  .enterprise-section {
    position: relative;
    width: 50%;
    margin: 0 auto;
    &::before {
      content: url(${STATIC_PATH}enterprise-icon.svg);
      position: absolute;
      left: 50px;
      top: 4px;
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        left: 10px;
      }
      @media only screen and (max-width: 767px) {
        left: 0%;
      }
    }
    @media only screen and (max-width: 767px) {
      width: 65%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: auto;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  p {
    text-align: center;
    padding: 8px 40px 32px;
    margin: 0px;
    font: ${font.normal14_400};
    color: #313345;
    span {
      font: ${font.normal14};
    }
  }
`;

export const FormSection = styled.div`
  padding: 0 40px;
  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;

export const Error = styled.div`
  text-align: left;
  margin: 10px 0 0;
  color: #d00000;
  font: ${font.normalMini_14};
  transition: all 0.4s;
`;

export const TwoCol = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  > div {
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    .drop-down {
      margin: 0;
    }
  }
`;

export const Wrappers = styled.div`
  position: relative;
  top: 13px;
`;

export const Success = styled.div`
  text-align: center;
  color: #00baff;
  transition: all 0.4s;
`;
