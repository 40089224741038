import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { STATIC_PATH } from "../../constants/config";
import {
  Icons,
  PlayerContainer,
  PlayerControls,
  Seek,
  SeekBar,
  PlayTimer,
  VolumeContainer,
  VolumeSlider,
} from "../../animo-player/animo-component";
import {
  playPausePlayer,
  setAnimoFullScreen,
  setGlobalVolume,
  setPlayAll,
  setPlayHeadTime,
} from "../../redux/actions/appUtils";
import { secondsToMinute } from "../timeline/timeline-helper";
import ActionTypes from "../../constants/action-types";
import Slider from "../zoom/zoom-slider";
import { Duration, PlayButton } from "./shorts-components";

const ShortsPlayerControlPanel = ({ startTime, endTime, videoRef }) => {
  const t1Ref = useRef(null);

  const appReducer = useSelector((state) => state.app);
  const isPlayAll = appReducer.get("isPlayAll");
  const t1 = appReducer.get("t1");
  const pausePlayer = appReducer.get("isPausePlayer");
  const animoPlayerId = appReducer.get("animoPlayerId");
  const dimensionName = useSelector((state) => state.userDetails.dimensionName);
  let duration;
  if (endTime) {
    duration = endTime - startTime;
  } else {
    duration = useSelector((state) => state.projectDetails.get("duration"));
  }
  const dispatch = useDispatch();
  const seekBarRef = useRef();
  const playSeekTimer = useRef();
  const currentTime = useRef();

  const [percentage, setPercentage] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPausePlayer, setIsPausePlayer] = useState(false);
  const [isShowControl, setIsShowControl] = useState(false);

  useEffect(() => {
    // Setting playhead to play start of a shorts.
    dispatch(setPlayHeadTime({ playhead: startTime }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);


  useEffect(() => {
    // Keep the ref updated with the latest value of t1 for pausing the player on tab visibilitychange.
    t1Ref.current = t1;
  }, [t1]);

  /**
  * Function to pause the player on moving to other tab.
  */
  const handleVisibilityChange = () => {
    if (document.hidden) {
      const playhead = t1Ref.current ? t1Ref.current.time() : startTime;
      dispatch(playPausePlayer({ playhead, isPause: true }));
    }
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPausePlayer(pausePlayer);
  }, [pausePlayer]);

  useEffect(() => {
    if (isPlayAll && !isPausePlayer) {
      playSeekTimer.current = setInterval(() => {
        // use recent props for interval callback
        const curTime = t1.time() - startTime;
        let percent;
        if (curTime >= 0) {
          currentTime.current = secondsToMinute(curTime);
          percent = (curTime / duration) * 100;
          setPercentage(percent);
        }
        if (percent >= 100) {
          // pausing and resetting the playhead to start on play end.
          dispatch(playPausePlayer({ playhead: startTime, isPause: true }));
          setPercentage(0);
        }
      }, 1000 / 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayAll, isPausePlayer]);

  useEffect(() => {
    if (isPausePlayer) {
      clearInterval(playSeekTimer.current);
    }
  }, [isPausePlayer]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
      dispatch({
        type: ActionTypes.SET_RESIZE,
      });
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlayPause = () => {
    if (videoRef?.current && !videoRef.current.paused) {
      videoRef.current.pause();
      videoRef.current.currentTime = startTime;
      videoRef.current.style.display = "none";
    }
    const playhead = t1 ? t1.time() : 0;
    if (playhead === endTime) {
      dispatch(playPausePlayer({ playhead: startTime, isPause: false }));
      return;
    }

    if (!isPlayAll && isPausePlayer) {
      dispatch(playPausePlayer({ playhead, isPause: false }));
      dispatch(setPlayAll(true, false));
    } else if (!isPlayAll) {
      // Initial play
      dispatch(setPlayAll(true, false));
    } else if (isPausePlayer && isPlayAll) {
      // Play after pause
      dispatch(playPausePlayer({ playhead, isPause: false }));
    } else if (!isPausePlayer && isPlayAll) {
      // Pause
      dispatch(playPausePlayer({ playhead, isPause: true }));
    }
  };

  const seekHandler = (e) => {
    const seekBound = seekBarRef.current?.getBoundingClientRect();
    const seekPercentage = (e.pageX - seekBound.x) / seekBound.width;
    const playhead = seekPercentage * duration;
    setPercentage((playhead / duration) * 100);
    currentTime.current = secondsToMinute(playhead);
    dispatch(setPlayHeadTime({ playhead: playhead + startTime }));
  };

  const handleFullScreen = (fullScreen) => {
    setIsFullScreen(fullScreen);
    dispatch(setAnimoFullScreen({ isFullScreen: fullScreen }));
    dispatch(setPlayAll(false, false));
  };

  const fullScreenHandler = () => {
    const playhead = t1 ? t1.time() : 0;
    dispatch(playPausePlayer({ playhead, isPause: true }));
    if (!document.fullscreenElement) {
      document.getElementById(animoPlayerId).requestFullscreen();
      handleFullScreen(true);
    } else {
      document.exitFullscreen();
      handleFullScreen(false);
    }
  };

  const changeVolumeHandler = (e) => {
    setVolume(e.target.value);
    dispatch(setGlobalVolume({ globalVolume: Number(e.target.value) }));
    dispatch(setPlayHeadTime({ playhead: (t1 && t1.time()) || 0 }));
  };

  const muteHandler = () => {
    let globalVolume;
    if (volume) {
      setVolume(0);
      globalVolume = 0;
    } else {
      setVolume(1);
      globalVolume = 1;
    }
    dispatch(setGlobalVolume({ globalVolume }));
    // Seek the player to update the current volume.
    dispatch(setPlayHeadTime({ playhead: (t1 && t1.time()) || 0 }));
  };

  const mouseEnterHandler = () => {
    setIsShowControl(true);
    if (!isPlayAll && videoRef) {
      videoRef.current.style.display = "block";
      videoRef.current.play().catch(() => { });
    }
  };

  const mouseLeaveHandler = () => {
    setIsShowControl(false);
    if (videoRef?.current && !isPlayAll) {
      videoRef.current.pause();
      videoRef.current.currentTime = startTime;
      videoRef.current.style.display = "none";
    }
  };

  const volumeIcon =
    volume === 0 ? `mute` : volume <= 0.49 ? `low-volume` : `high-volume`;

  return (
    <>
      <PlayerControls
        onClick={handlePlayPause}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        {!isPlayAll && (
          <>
            <PlayButton>
              <Icons
                src={`${STATIC_PATH}shorts-play.svg`}
                alt="play"
                width="64px"
                height="64px"
              />
            </PlayButton>
            <Duration
              left={
                dimensionName === "vertical"
                  ? "190px"
                  : dimensionName === "horizontal"
                    ? "8px"
                    : "125px"
              }
            >
              {secondsToMinute(duration)}
            </Duration>
          </>
        )}
      </PlayerControls>
      {isPlayAll && (
        <PlayerContainer
          height="32px"
          width="98%"
          display={isShowControl ? "flex" : "none"}
          isShorts={true}
          onMouseEnter={() => setIsShowControl(true)}
        >
          <Icons
            height="22px"
            width="22px"
            src={`${STATIC_PATH}${isPlayAll && !isPausePlayer ? "animo-pause" : "play-icon"}.svg`}
            id="playIcon"
            alt=""
            onClick={handlePlayPause}
          />
          <PlayTimer>
            {currentTime.current || secondsToMinute(duration)}
          </PlayTimer>
          <SeekBar
            ref={seekBarRef}
            onClick={seekHandler}
            isFullScreen={isFullScreen}
          >
            <Seek width={`${percentage}%`} />
          </SeekBar>
          <VolumeContainer>
            <Icons
              height="22px"
              width="22px"
              src={`${STATIC_PATH}animo-${volumeIcon}.svg`}
              alt="animo-volume"
              onClick={muteHandler}
            />
            <VolumeSlider className="animo-volume-tool-bar">
              <Slider
                width={"64px"}
                height={"4px"}
                border={"1px solid #DADADC"}
                borderRadius={"2px"}
                background={"#DADADC"}
                margin={"0"}
                thumb={{
                  background: "#637191 0% 0% no-repeat padding-box",
                  border: "none",
                  height: "10px",
                  width: "10px",
                  hoverBG: "#00A7E5",
                  hoverBorder: "1.7px solid #637191",
                }}
                onChangeSliderValue={() => { }}
                onMouseUp={changeVolumeHandler}
                value={volume}
                min={0}
                max={1}
                inputMin={0}
                inputMax={1}
                step={0.01}
                progressBackground={`#00A7E5`}
                isChangeBg={true}
              />
            </VolumeSlider>
          </VolumeContainer>
          <Icons
            height="22px"
            width="22px"
            src={`${STATIC_PATH}fullscreen${isFullScreen ? "-exit" : ""}.svg`}
            alt=""
            onClick={fullScreenHandler}
          />
        </PlayerContainer>
      )}
    </>
  );
};

ShortsPlayerControlPanel.propTypes = {
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  videoRef: PropTypes.object,
};

export default ShortsPlayerControlPanel;
