import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import Header from "../animo-player/header";
import vmTheme from "../constants/theme";
import ContentValue from "../constants/lang/en.json";
import PublishContainer from "./publish-container";
import Upgrade from "../containers/upgrade/upgrade";
import { useCustomCallback } from "../helper/hooks/useCustomCallback";
import { getCredits, getUserDetails, setProjectDetails, setUserPaymentDetails } from "../redux/actions/projectUtils";
import { setAnimoPlayer, setAnimoPlayerId } from "../redux/actions/appUtils";
import openUrl from "../helper/openUrl";
import Toast from "../common-components/Toast";
import SupportForm from "../containers/upgrade/supportForm";
import Loader from "../common-components/Loader";
import AppConfig, { ENVIRONMENT } from "../constants/config";
import useNotify from "../helper/hooks/useNotify";
import proceedWait from "../helper/proceedWait";

const Publisher = ({ match, isPublish }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { warn } = useNotify();

  const [isMobileShow, setIsMobileShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(10);

  const appReducer = useSelector((state) => state.app);
  const theme = appReducer.get("theme");
  const { shortId } = location.state || {};
  const userDetails = useSelector((state) => state.userDetails);
  const { projectId } = userDetails;

  useEffect(() => {
    const { params = { id: "" } } = match;
    getUserDetails().then((userDetailsRes) => {
      setProgress(75);
      dispatch(getCredits());
      dispatch(setAnimoPlayerId("animo-player"));
      dispatch(setProjectDetails(params.id, userDetailsRes, !isPublish, isPublish)).then((res) => {
        setProgress(95);
        dispatch(setUserPaymentDetails(isPublish ? res.projectId : params.id));
        const hasShorts = res.project.shorts && Object.keys(res.project.shorts).length !== 0;
        if (hasShorts && !shortId) {
          openUrl(`${window.location.origin}/shorts/${params.id}`, true);
        }
        if (isPublish && userDetailsRes.userId !== res.userId) {
          warn("You don't have access to this project.");
          openUrl(AppConfig.API_URL, true);
        } else {
          setIsLoading(false);
        }
      }).catch(async () => {
        warn("You don't have access to this project.");
        await proceedWait(3000);
        openUrl(AppConfig.API_URL, true);
      });
    }).catch(() => {
      dispatch(setProjectDetails(params.id, {}, false));
      setIsLoading(false);
      warn("Please login to access to this project");
      openUrl(AppConfig.API_URL, true);
    });
    dispatch(setAnimoPlayer({ isAnimoPlayer: true }));

    const cleanUp = history.listen((location) => {
      const basePath = location.pathname.split('/')[1];
      if (['render', 'publish', 'shorts', 'preview'].includes(basePath)) {
        dispatch(setAnimoPlayer({ isAnimoPlayer: true }));
      } else {
        dispatch(setAnimoPlayer({ isAnimoPlayer: false }));
        window.location.reload(); // to trigger all the api in editor.
      }
    });
    return () => {
      cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAction = (name) => {
    if (name === "mobileAction") {
      setIsMobileShow(!isMobileShow);
    } else {
      history.push(`${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${projectId}`);
    }
  };

  const _onKeyDown = (e) => {
    if (e.keyCode === 27) {
      setIsMobileShow(false);
    }
  };
  const onKeyDown = useCustomCallback({ memoizedCallback: _onKeyDown });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <IntlProvider locale="en" messages={{ ...ContentValue }}>
      <ThemeProvider theme={vmTheme[theme]}>
        <Header isExport={true} onAction={onAction} isMobileShow={isMobileShow} />
        <PublishContainer isExport={true} isMobileShow={isMobileShow} isPublish={isPublish} shortId={shortId} />
        <Upgrade isPremium={true} />
        <SupportForm />
        <Toast />
        {isLoading && <Loader progress={progress} />}
      </ThemeProvider>
    </IntlProvider>
  );
};

Publisher.propTypes = {
  match: PropTypes.object,
  isPublish: PropTypes.bool
};
export default Publisher;
