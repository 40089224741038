import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import { PlotType, SliderType, StepperType } from "../timeline-proptypes";
import { TimelineScrollContext } from "../contexts/timeline-scroll-context";
import { RULER_OPTIONS, SLIDER_GAP_TYPES, SLIDER_TYPES, TRACK_TYPES } from "../timeline-constants";
import { getPanelName, isAudioOnly, isImageOnly, isPropertyOnly, isTextOnly, isVideoOnly, memoize, pxToStep, secondsToTimestamp } from "../timeline-helper";
import { Duration, EffectDuration, InnerThumb, MenuIcon, OuterThumb, SliderContainer, SliderOverlay } from "./timeline-slider-components";
import { changeAudioVolume, updateItem, updateTimelineTime } from "../../../redux/actions/timelineUtils";
import { STATIC_PATH } from "../../../constants/config";
import AudioPlayer from "../audioPlayer/timeline-audio-player";
import SliderBG from "../sliderBG/slider-background";
import ContextMenu from "../../../common-components/ContextMenu";
import { audioDetachHelper } from "../../panels/library-helper";
import { setAdvancedEdit, setDetachData, setExpandPanel, setPropertyPanel, setSwapInfo, setSwapItem } from "../../../redux/actions/appUtils";
import vmTheme from "../../../constants/theme";
import AudioFadeController from "../audioFader/audio-fade-controller";

const CONTEXT_ICON_WIDTH = 33;
const ICON_OFFSET = 10;

class TimelineSliderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isContextOpen: false,
      contextMenuPosition: null,
      isPlay: false,
      isMute: false,
      volume: 1,
      pasteDuration: null,
    };

    this.handleSliderMouseDown = this.handleSliderMouseDown.bind(this);
    this.assignSliderRef = this.assignSliderRef.bind(this);
    this.menuClickHandler = this.menuClickHandler.bind(this);
    this.volumeHandler = this.volumeHandler.bind(this);
    this._getContextOption = this._getContextOption.bind(this);
    this.setMenuPosition = this.setMenuPosition.bind(this);
    this.closeContextMenu = this.closeContextMenu.bind(this);

    this.sliderRef = React.createRef(null);
    this.enterEffectRef = React.createRef(null);
    this.exitEffectRef = React.createRef(null);
    this.menuRef = React.createRef(null);
    this.contextMenuRef = React.createRef(null);

    this.getContextOption = memoize(this._getContextOption);
    this.memoizeSliderPlot = memoize((sliderPlot) => sliderPlot);
  }

  componentDidMount() {
    const { item } = this.props;
    if (
      item &&
      (isVideoOnly(item.get("type"), item.get("subType")) ||
        isAudioOnly(item.get("type")))
    ) {
      this.setState({
        volume: item.get("volume"),
        isMute: item.get("volume") === 0,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // ignore redux dispatch functions here!
    return (
      this.state !== nextState ||
      this.props.slider !== nextProps.slider ||
      this.props.isSelected !== nextProps.isSelected ||
      this.props.selectSlider !== nextProps.selectSlider ||
      this.props.isDragging !== nextProps.isDragging ||
      this.props.sliderMoved !== nextProps.sliderMoved ||
      this.props.isSliderDragging !== nextProps.isSliderDragging ||
      this.props.trackHeight !== nextProps.trackHeight ||
      this.props.trackType !== nextProps.trackType ||
      this.props.forDragPosition !== nextProps.forDragPosition ||
      this.props.isThumbDrag !== nextProps.isThumbDrag ||
      this.props.timelinePlot !== nextProps.timelinePlot ||
      this.props.handleGapRemove !== nextProps.handleGapRemove ||
      this.props.canUseMagnet !== nextProps.canUseMagnet ||
      this.props.itemContainer !== nextProps.itemContainer ||
      this.props.item !== nextProps.item ||
      this.props.timelineScrollPos.x !== nextProps.timelineScrollPos.x ||
      this.props.timelineScrollPos.y !== nextProps.timelineScrollPos.y ||
      this.props.sliderRef !== nextProps.sliderRef ||
      this.props.stepper.stepSizePx !== nextProps.stepper.stepSizePx ||
      this.props.theme !== nextProps.theme ||
      this.props.isAdvancedEdit !== nextProps.isAdvancedEdit
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isSelected && prevProps.isSelected) {
      if (this.state.isContextOpen) {
        this.closeContextMenu();
      }
      if (this.props.isAdvancedEdit === this.props.item?.get("id")) {
        this.props.setAdvancedEdit("");
      }
    }

    if (this.props.item && this.props.item !== prevProps.item) {
      const { item } = this.props;
      const volume = item.get("volume");
      if (
        (isVideoOnly(item.get("type"), item.get("subType")) ||
          isAudioOnly(item.get("type"))) &&
        volume !== this.state.volume
      ) {
        this.setState({ volume });
      }
    }
  }

  componentWillUnmount() {
    this.getContextOption.clear();
    this.memoizeSliderPlot.clear();
  }

  /**
   * mouse down handler for whole slider
   * @param {event} event
   */
  handleSliderMouseDown(event) {
    const { selectSlider, slider } = this.props;

    if (
      event.target === this.sliderRef.current ||
      event.target === this.enterEffectRef.current ||
      event.target === this.exitEffectRef.current ||
      (event.type !== "click" && event.target === this.menuRef.current)
    ) {
      // ignore if event triggered on thumbs
      selectSlider({
        event,
        sliderId: slider.id,
        canInitiateDrag: slider.isDraggable,
      });
    }
  }

  setMenuPosition(e) {
    const { timelineScrollPos, timelinePlot, rulerSlider } = this.props;
    e.preventDefault();

    if (e.type === "contextmenu") {
      const x = e.clientX;
      const y = e.clientY;
      const posX = timelineScrollPos.x + (e.clientX - timelinePlot.x);
      const pasteDuration =
        pxToStep({ rulerSlider, x: posX }) * RULER_OPTIONS.interval; // Multiplying step with interval to get the duration in sec.
      this.setState({
        isContextOpen: true,
        contextMenuPosition: { x, y, eventType: e.type },
        pasteDuration,
      });
    } else {
      this.setState((prev) => {
        return {
          isContextOpen: !prev.isContextOpen,
          contextMenuPosition: {
            x: this.menuRef.current.getBoundingClientRect().left,
            y: this.menuRef.current.getBoundingClientRect().top,
            eventType: e.type,
          },
          pasteDuration: null,
        };
      });
    }
  }

  closeContextMenu() {
    this.setState({ isContextOpen: false });
  }

  menuClickHandler(actionName) {
    const {
      duplicateHandler,
      handleGapRemove,
      copyToClipboard,
      pasteFromClipboard,
      deleteHandler,
      itemContainer,
      slider,
      item,
      setReplaceItem,
      setSwapInfo,
      setPanel,
      setExpand,
      detachAudio,
      isAdvancedEdit,
      setAdvancedEdit
    } = this.props;
    const { pasteDuration } = this.state;
    const { GAP, AUDIO } = SLIDER_TYPES;

    this.closeContextMenu();

    switch (actionName) {
      case "PLAY":
        this.setState({ isPlay: true });
        break;
      case "DELETE":
        deleteHandler();
        break;
      case "DUPLICATE":
        duplicateHandler();
        break;
      case "COPY":
        copyToClipboard();
        break;
      case "PASTE":
        {
          const itemDetails = {};
          const isGapSlider = slider.sliderType === GAP;
          const isAudio = slider.sliderType === AUDIO;
          itemDetails.track = isGapSlider
            ? slider.gapSliderDetail.leftThumbPosition.trackIndex
            : item.get("track") + 1;
          itemDetails.container = itemContainer;
          if (pasteDuration === null) {
            // Logic to paste the copied item into the start of the slider from where the context menu is been clicked.
            itemDetails.itemId = !isGapSlider && item.get("id");
            itemDetails.startTime = isGapSlider
              ? slider.enterStart.position.step * RULER_OPTIONS.interval
              : isAudio
                ? item.get("playStart")
                : item.get("enterStart");
            itemDetails.isGapSlider = isGapSlider;
          }
          pasteFromClipboard(pasteDuration, itemDetails);
        }
        break;
      case "DELETE_GAP":
        handleGapRemove({ deleteType: "gap-delete-selected" });
        break;
      case "DELETE_ALL_GAP":
        handleGapRemove({
          deleteType: "gap-delete-single-track",
        });
        break;
      case "REPLACE":
        {
          const { panelName, itemType } = getPanelName(
            item.get("type"),
            item.get("subType")
          );
          setPanel(panelName);
          setExpand(true);
          setReplaceItem(item);
          setSwapInfo(
            fromJS({ panelName, itemType, container: itemContainer })
          );
        }
        break;
      case "DETACH_AUDIO": {
        const toUpdate = audioDetachHelper({
          item,
          container: itemContainer,
        });
        detachAudio({ toUpdate });
      }
        break;
      case "ADVANCED_EDIT":
        setAdvancedEdit(isAdvancedEdit ? "" : item.get("id"));
        break;
      default:
        break;
    }
  }

  volumeHandler(vol) {
    const { slider, itemContainer, changeVolume, item } = this.props;
    this.setState({ isMute: vol === 0, volume: vol });
    if (slider.sliderType === SLIDER_TYPES.AUDIO) {
      this.props.changeAudioVolume({
        id: item.get("id"),
        volume: vol,
      });
    } else {
      changeVolume({
        container: itemContainer,
        selectedItem: slider.id,
        toUpdate: { volume: vol },
      });
    }
  }

  _getContextOption(params = {}) {
    const { slider, volume } = params;
    const { canPaste, item, isAdvancedEdit } = this.props;
    const { sliderType, itemType, itemSubType } = slider;
    const { GAP, SUBTITLE } = SLIDER_TYPES;
    let contextMenuOptions = [];
    const canDetachAudio =
      isVideoOnly(itemType, itemSubType) &&
      sliderType !== SLIDER_TYPES.AUDIO &&
      !item.get("isAudioDetached");
    const isEnableReplace =
      !isTextOnly(itemType) && sliderType !== GAP && sliderType !== SUBTITLE;

    if (sliderType === SLIDER_TYPES.AUDIO) {
      contextMenuOptions.push({
        title: "Play",
        clickHandler: this.menuClickHandler,
        actionName: "PLAY",
      }, {
        title: "Advanced Edit",
        clickHandler: this.menuClickHandler,
        actionName: "ADVANCED_EDIT",
      });
    }

    if (sliderType !== GAP && sliderType !== SUBTITLE) {
      contextMenuOptions.push(
        {
          title: "Duplicate",
          clickHandler: this.menuClickHandler,
          actionName: "DUPLICATE",
        },
        {
          title: "Copy",
          clickHandler: this.menuClickHandler,
          actionName: "COPY",
        },
      );
    }

    if (sliderType !== GAP || sliderType === SUBTITLE) {
      contextMenuOptions.push({
        title: "Delete",
        clickHandler: this.menuClickHandler,
        actionName: "DELETE",
      });
    }

    if (sliderType === GAP) {
      contextMenuOptions = [
        {
          title: "Delete this gap",
          clickHandler: this.menuClickHandler,
          actionName: "DELETE_GAP",
        },
      ];
      if (slider.itemType !== SLIDER_GAP_TYPES.SUBTITLE) {
        contextMenuOptions.push({
          title: "Delete all gaps on this track",
          clickHandler: this.menuClickHandler,
          actionName: "DELETE_ALL_GAP",
        });
      }
    }

    if (
      sliderType === SLIDER_TYPES.AUDIO ||
      isVideoOnly(itemType, itemSubType)
    ) {
      contextMenuOptions.push({
        changeHandler: this.volumeHandler,
        defaultVolume: volume,
        actionName: "VOLUME",
      });
    }
    const newMenuItems = [];
    if (canPaste && sliderType !== SUBTITLE) {
      newMenuItems.push({
        title: "Paste",
        clickHandler: this.menuClickHandler,
        actionName: "PASTE",
      });
    }
    if (isEnableReplace) {
      newMenuItems.push({
        title: "Replace",
        clickHandler: this.menuClickHandler,
        actionName: "REPLACE",
      });
    }
    if (canDetachAudio) {
      newMenuItems.push({
        title: "Detach Audio",
        clickHandler: this.menuClickHandler,
        actionName: "DETACH_AUDIO",
        isEnableBorder: true,
      });
    }

    if (newMenuItems.length) {
      // Find the index of the "COPY" title in the array
      const copyIndex = contextMenuOptions.findIndex(
        (item) => item.actionName === "COPY"
      );
      // Insert the new object below the "COPY" title
      newMenuItems.forEach((newMenuItem, id) => {
        contextMenuOptions.splice(copyIndex + id + 1, 0, newMenuItem);
      });
    }

    if (sliderType === SLIDER_TYPES.AUDIO && isAdvancedEdit === item.get("id")) {
      contextMenuOptions = [
        {
          title: "Close Edit",
          clickHandler: this.menuClickHandler,
          actionName: "ADVANCED_EDIT",
          isClose: true
        }
      ]
    }

    return contextMenuOptions;
  }

  assignSliderRef(r) {
    const { sliderRef: ref, slider } = this.props;
    this.sliderRef.current = r;
    if (typeof ref === "function") {
      ref(r, slider);
    } else if (ref && typeof ref === "object") {
      ref.current = r;
    }
  }

  render() {
    const { isSelected, slider, selectSlider, isDragging, sliderMoved, isSliderDragging, trackHeight, canPaste, enableContext } = this.props;
    const { stepper, trackType, forDragPosition, isThumbDrag, timelinePlot, isAdvancedEdit } = this.props;
    const { canUseMagnet, item, timelineScrollPos } = this.props;
    const { isPlay, isMute, volume, contextMenuPosition, isContextOpen } = this.state;

    let forTrackChange = false;
    let useOriginalPos = false;
    if (isSliderDragging && isSelected && !forDragPosition && !isThumbDrag) {
      forTrackChange = true;
    }
    if (isSliderDragging && isSelected && !forDragPosition && isThumbDrag) {
      useOriginalPos = true;
    }

    let enterStartPosition = slider.enterStart.position;
    if (!useOriginalPos) {
      if (forDragPosition && slider.enterStart.dragPosition) {
        enterStartPosition = slider.enterStart.dragPosition;
      } else if (slider.enterStart.trackPosition) {
        enterStartPosition = slider.enterStart.trackPosition;
      }
    }

    let exitEndPosition = slider.exitEnd.position;
    if (!useOriginalPos) {
      if (forDragPosition && slider.exitEnd.dragPosition) {
        exitEndPosition = slider.exitEnd.dragPosition;
      } else if (slider.exitEnd.trackPosition) {
        exitEndPosition = slider.exitEnd.trackPosition;
      }
    }

    const sliderPlot = {
      x: enterStartPosition.x,
      y: enterStartPosition.y,
      width: exitEndPosition.x - enterStartPosition.x,
      height: trackHeight,
    };
    const memoizedSliderPlot = this.memoizeSliderPlot.executor(
      [sliderPlot.x, sliderPlot.y, sliderPlot.width, sliderPlot.height],
      sliderPlot
    );

    let colors = vmTheme[this.props.theme].SLIDER_COLORS.IMAGE;
    if (trackType === TRACK_TYPES.AUDIO) {
      colors = vmTheme[this.props.theme].SLIDER_COLORS.AUDIO;
    } else if (trackType === TRACK_TYPES.OBJECT) {
      if (isImageOnly(slider.itemType, slider.itemSubType)) {
        colors = vmTheme[this.props.theme].SLIDER_COLORS.IMAGE;
      } else if (isVideoOnly(slider.itemType, slider.itemSubType)) {
        colors = vmTheme[this.props.theme].SLIDER_COLORS.VIDEO;
      } else if (isTextOnly(slider.itemType, slider.itemSubType)) {
        colors = vmTheme[this.props.theme].SLIDER_COLORS.TEXT;
      } else if (isPropertyOnly(slider.itemType)) {
        colors = vmTheme[this.props.theme].SLIDER_COLORS.SHAPE;
      }
    } else if (trackType === TRACK_TYPES.VIDEO) {
      colors = vmTheme[this.props.theme].SLIDER_COLORS.VIDEO;
    }
    if (slider.sliderType === SLIDER_TYPES.SUBTITLE) {
      colors = vmTheme[this.props.theme].SLIDER_COLORS.SUBTITLE;
    }
    if (slider.sliderType === SLIDER_TYPES.MINI_SUBTITLE) {
      colors = vmTheme[this.props.theme].SLIDER_COLORS.MINI_SUBTITLE;
    }
    if (slider.isLocked) {
      colors = vmTheme[this.props.theme].SLIDER_COLORS.LOCKED;
    }
    if (slider.sliderType === SLIDER_TYPES.GAP) {
      colors = vmTheme[this.props.theme].SLIDER_COLORS.GAP;
      if (isSelected) {
        colors = {
          default: colors.selected,
          selected: colors.selected,
        };
      } else {
        colors = {
          default: colors.default,
          selected: colors.default,
        };
      }
    }

    let defaultColors = colors.default;
    let selectedColors = colors.selected;
    if (forTrackChange && colors.trackChange) {
      defaultColors = colors.trackChange;
      selectedColors = colors.trackChange;
    }

    const thumbs = [];
    const effectDurationList = [];

    let classSuffix = "";
    if (isSelected) {
      classSuffix = `${classSuffix} timeline-slider--selected`;
    }
    if (isDragging && !isSelected) {
      classSuffix = `${classSuffix} timeline-slider--disabled`;
    }
    if (forDragPosition) {
      classSuffix = `${classSuffix} timeline-slider--is-drag-slider timeline-slider--disabled`;
      if (slider.isOverlapping) {
        classSuffix = `${classSuffix} timeline-slider--is-overlapping`;
      }
    }
    if (forTrackChange) {
      classSuffix = `${classSuffix} timeline-slider--for-track-change`;
      if (slider.toNewTrackAbove || slider.toNewTrackBelow) {
        classSuffix = `${classSuffix} timeline-slider--hidden`;
      }
      if (slider.isOverlapping) {
        classSuffix = `${classSuffix} timeline-slider--is-overlapping`;
      }
    }
    if (useOriginalPos) {
      classSuffix = `${classSuffix} timeline-slider--use-org-pos`;
    }
    if (isThumbDrag && isSelected) {
      classSuffix = `${classSuffix} timeline-slider--is-thumb-drag`;
    }

    let enterStartClass = `timeline-slider-thumb--enterStart ${classSuffix}`;
    let exitEndClass = `timeline-slider-thumb--exitEnd ${classSuffix}`;
    let enterEndClass = `timeline-slider-thumb--enterEnd ${classSuffix}`;
    let exitStartClass = `timeline-slider-thumb--exitStart ${classSuffix}`;
    const enterEffectClass = classSuffix;
    const exitEffectClass = classSuffix;
    const sliderClass = `timeline-slider timeline-slider--st-${slider.sliderType} ${classSuffix}`;
    const OUTER_THUMB_INNER_OFFSET = 10; // right edge of enterStart thumb, left edge of exitEnd thumb
    const INNER_THUMB_WIDTH = 6;

    if (slider.enterEnd) {
      let enterEndPosition = slider.enterEnd.position;
      if (!useOriginalPos) {
        if (forDragPosition && slider.enterEnd.dragPosition) {
          enterEndPosition = slider.enterEnd.dragPosition;
        } else if (slider.enterEnd.trackPosition) {
          enterEndPosition = slider.enterEnd.trackPosition;
        }
      }

      if (
        enterEndPosition.x - enterStartPosition.x <=
        OUTER_THUMB_INNER_OFFSET + INNER_THUMB_WIDTH / 2
      ) {
        enterStartClass = `${enterStartClass} timeline-slider-thumb--hidden`;
      }
      if (
        !slider.isDraggable ||
        enterEndPosition.x - enterStartPosition.x <= INNER_THUMB_WIDTH / 2
      ) {
        enterEndClass = `${enterEndClass} timeline-slider--disabled timeline-slider-thumb--hidden`;
      }
    }
    if (slider.exitStart) {
      let exitStartPosition = slider.exitStart.position;
      if (!useOriginalPos) {
        if (forDragPosition && slider.exitStart.dragPosition) {
          exitStartPosition = slider.exitStart.dragPosition;
        } else if (slider.exitStart.trackPosition) {
          exitStartPosition = slider.exitStart.trackPosition;
        }
      }

      if (
        exitEndPosition.x - exitStartPosition.x <=
        OUTER_THUMB_INNER_OFFSET + INNER_THUMB_WIDTH / 2
      ) {
        exitEndClass = `${exitEndClass} timeline-slider-thumb--hidden`;
      }
      if (
        !slider.isDraggable ||
        exitEndPosition.x - exitStartPosition.x <= INNER_THUMB_WIDTH / 2
      ) {
        exitStartClass = `${exitStartClass} timeline-slider--disabled timeline-slider-thumb--hidden`;
      }
    }
    if (sliderPlot.width < 24) {
      enterStartClass = `${enterStartClass} timeline-slider-thumb--hidden`;
      exitEndClass = `${exitEndClass} timeline-slider-thumb--hidden`;
    }
    if (!slider.isDraggable) {
      enterStartClass = `${enterStartClass} timeline-slider--disabled timeline-slider-thumb--hidden`;
      exitEndClass = `${exitEndClass} timeline-slider--disabled timeline-slider-thumb--hidden`;
    }

    thumbs.push(
      <OuterThumb
        key={"enterStart"}
        className={enterStartClass}
        $thumbType={"enterStart"}
        $x={0} // will be always 0 as thumbs are absolute to slider
        $color={defaultColors.outerThumbs}
        $hoverColor={selectedColors.outerThumbs}
        onPointerDown={
          !isDragging
            ? (event) =>
              selectSlider({
                event,
                thumbId: "enterStart",
                sliderId: slider.id,
                canInitiateDrag: slider.isDraggable,
              })
            : undefined
        }
        onClick={
          !isDragging
            ? (event) =>
              selectSlider({
                event,
                thumbId: "enterStart",
                sliderId: slider.id,
                canInitiateDrag: slider.isDraggable,
              })
            : undefined
        }
      />
    );

    thumbs.push(
      <OuterThumb
        key={"exitEnd"}
        className={exitEndClass}
        $thumbType={"exitEnd"}
        $x={exitEndPosition.x - enterStartPosition.x}
        $color={defaultColors.outerThumbs}
        $hoverColor={selectedColors.outerThumbs}
        onPointerDown={
          !isDragging
            ? (event) =>
              selectSlider({
                event,
                thumbId: "exitEnd",
                sliderId: slider.id,
                canInitiateDrag: slider.isDraggable,
              })
            : undefined
        }
        onClick={
          !isDragging
            ? (event) =>
              selectSlider({
                event,
                thumbId: "exitEnd",
                sliderId: slider.id,
                canInitiateDrag: slider.isDraggable,
              })
            : undefined
        }
      />
    );

    if (slider.enterEnd && (!this.props.item.get("transitionExitId") || this.props.item.get("transitionExitId") === "none" || !this.props.item.get("transitionExitEffect") || this.props.item.get("transitionExitEffect") === "none_transition")) {
      let enterEndPosition = slider.enterEnd.position;
      if (forDragPosition && slider.enterEnd.dragPosition) {
        enterEndPosition = slider.enterEnd.dragPosition;
      } else if (slider.enterEnd.trackPosition) {
        enterEndPosition = slider.enterEnd.trackPosition;
      }
      thumbs.push(
        <InnerThumb
          key={"enterEnd"}
          className={enterEndClass}
          $thumbType={"enterEnd"}
          $x={enterEndPosition.x - enterStartPosition.x}
          $color={defaultColors.innerThumbs}
          $hoverColor={selectedColors.innerThumbs}
          onPointerDown={
            !isDragging
              ? (event) =>
                selectSlider({
                  event,
                  thumbId: "enterEnd",
                  sliderId: slider.id,
                  canInitiateDrag: slider.isDraggable,
                })
              : undefined
          }
          onClick={
            !isDragging
              ? (event) =>
                selectSlider({
                  event,
                  thumbId: "enterEnd",
                  sliderId: slider.id,
                  canInitiateDrag: slider.isDraggable,
                })
              : undefined
          }
        />
      );
      effectDurationList.push(
        <EffectDuration
          key={"enterEnd-effectDuration"}
          ref={this.enterEffectRef}
          className={enterEffectClass}
          $x={0} // will be always 0 as thumbs are absolute to slider
          $width={enterEndPosition.x - enterStartPosition.x}
          $color={defaultColors.effect}
          $hoverColor={selectedColors.effect}
        />
      );
    }

    if (slider.exitStart && (!this.props.item.get("transitionEnterId") || this.props.item.get("transitionEnterId") === "none" || !this.props.item.get("transitionEnterEffect") || this.props.item.get("transitionEnterEffect") === "none_transition")) {
      let exitStartPosition = slider.exitStart.position;
      if (forDragPosition && slider.exitStart.dragPosition) {
        exitStartPosition = slider.exitStart.dragPosition;
      } else if (slider.exitStart.trackPosition) {
        exitStartPosition = slider.exitStart.trackPosition;
      }
      thumbs.push(
        <InnerThumb
          key={"exitStart"}
          className={exitStartClass}
          $thumbType={"exitStart"}
          $x={exitStartPosition.x - enterStartPosition.x}
          $color={defaultColors.innerThumbs}
          $hoverColor={selectedColors.innerThumbs}
          onPointerDown={
            !isDragging
              ? (event) =>
                selectSlider({
                  event,
                  thumbId: "exitStart",
                  sliderId: slider.id,
                  canInitiateDrag: slider.isDraggable,
                })
              : undefined
          }
          onClick={
            !isDragging
              ? (event) =>
                selectSlider({
                  event,
                  thumbId: "exitStart",
                  sliderId: slider.id,
                  canInitiateDrag: slider.isDraggable,
                })
              : undefined
          }
        />
      );
      effectDurationList.push(
        <EffectDuration
          key={"exitStart-effectDuration"}
          ref={this.exitEffectRef}
          className={exitEffectClass}
          $x={exitStartPosition.x - enterStartPosition.x}
          $width={exitEndPosition.x - exitStartPosition.x}
          $color={defaultColors.exitEffect || defaultColors.effect}
          $hoverColor={selectedColors.exitEffect || selectedColors.effect}
        />
      );
    }

    const isContextSupported = slider.sliderType !== SLIDER_TYPES.MINI_SUBTITLE;
    const isShowContextMenu = isContextSupported && isSelected && sliderPlot.width > 70 && !sliderMoved && !canUseMagnet && !isAdvancedEdit;

    let contextMenu;
    if (isShowContextMenu) {
      let menuLeft;
      const sliderPlotX2 = sliderPlot.x + sliderPlot.width;
      let visibleX1 = sliderPlot.x;
      let visibleX2 = sliderPlotX2;

      if (sliderPlot.x < timelineScrollPos.x) {
        visibleX1 = timelineScrollPos.x;
      }
      if (visibleX2 > timelineScrollPos.x + timelinePlot.width) {
        visibleX2 = timelineScrollPos.x + timelinePlot.width;
      }
      const visibleWidth = visibleX2 - visibleX1;
      menuLeft = visibleX1 + visibleWidth / 2 - CONTEXT_ICON_WIDTH / 2;
      // stop right
      if (menuLeft + CONTEXT_ICON_WIDTH + ICON_OFFSET > sliderPlotX2) {
        menuLeft = sliderPlotX2 - CONTEXT_ICON_WIDTH - ICON_OFFSET;
      }
      // stop left
      if (menuLeft - ICON_OFFSET < sliderPlot.x) {
        menuLeft = sliderPlot.x + ICON_OFFSET;
      }
      menuLeft -= sliderPlot.x;

      contextMenu = (
        <MenuIcon
          ref={this.menuRef}
          id="context-menu-icon"
          src={`${STATIC_PATH}${vmTheme[this.props.theme].icons.menuIcon}`}
          draggable={false}
          alt=""
          $left={menuLeft}
          width={CONTEXT_ICON_WIDTH}
          onClick={this.setMenuPosition}
        />
      );
    }

    let audioPlayer = null;
    if (slider.sliderType === SLIDER_TYPES.AUDIO &&
      isSelected && sliderPlot.width > 40 && !isAdvancedEdit) {
      audioPlayer = (
        <AudioPlayer
          sliderId={slider.id}
          isPlay={isPlay}
          isMute={isMute}
          onAudioStop={() => this.setState({ isPlay: false })}
        />
      );
    }

    let audioWaveColor = colors.default.audioWave;
    let audioWaveBgColor = colors.default.bg;
    let textColor = colors.default.text;
    if (isSelected) {
      audioWaveColor = colors.selected.audioWave;
      audioWaveBgColor = colors.selected.bg;
      textColor = colors.selected.text;
    }

    const sliderBG = (
      <SliderBG
        slider={slider}
        sliderPlot={memoizedSliderPlot}
        sliderData={item}
        trackType={trackType}
        stepper={stepper}
        isMute={isMute}
        isDragSlider={forDragPosition}
        isDragging={isDragging}
        isThumbDrag={isThumbDrag}
        audioWaveColor={audioWaveColor}
        audioWaveBgColor={audioWaveBgColor}
        textColor={textColor}
        timelinePlotWidth={timelinePlot.width}
      />
    );

    const contextOptions = this.getContextOption.executor(
      [slider, volume, canPaste, isAdvancedEdit],
      { slider, volume }
    );

    return (
      <SliderContainer
        ref={this.assignSliderRef}
        data-timeline-slider-id={slider.id}
        className={sliderClass}
        $plot={memoizedSliderPlot}
        $colors={colors}
        $forTrackChange={forTrackChange}
        onPointerDown={!isDragging ? this.handleSliderMouseDown : undefined}
        onClick={!isDragging ? this.handleSliderMouseDown : undefined}
        onContextMenu={isContextSupported ? this.setMenuPosition : undefined}
      >
        {!forTrackChange &&
          isSelected &&
          slider.sliderType !== SLIDER_TYPES.GAP &&
          slider.sliderType !== SLIDER_TYPES.MINI_SUBTITLE &&
          sliderPlot.width > 60 && !isAdvancedEdit && (
            <Duration isDragging={sliderMoved}>
              {secondsToTimestamp({
                seconds:
                  ((sliderMoved
                    ? sliderPlot.x -
                    RULER_OPTIONS.paddingLeft +
                    sliderPlot.width
                    : sliderPlot.width) *
                    RULER_OPTIONS.interval) /
                  stepper.stepSizePx,
                mode: "indicator",
              })}
            </Duration>
          )}
        {audioPlayer}
        {isContextSupported && isContextOpen && enableContext && (
          <ContextMenu
            ref={this.contextMenuRef}
            positionEvent={contextMenuPosition}
            options={contextOptions}
            closeContextMenu={this.closeContextMenu}
            width={slider.sliderType === SLIDER_TYPES.GAP ? "218px" : "150px"}
          />
        )}
        {contextMenu}
        {!forTrackChange && (
          <div className="timeline-slider--content">
            <SliderOverlay
              $color={defaultColors.overlay}
              $hoverColor={selectedColors.overlay}
              $gradient={defaultColors.gradient}
              $hoverGradient={selectedColors.hoverGradient}
            />
            {sliderBG}
            {effectDurationList}
          </div>
        )}
        {(item && isAdvancedEdit !== item.get("id")) && thumbs}
        {(slider.sliderType === SLIDER_TYPES.AUDIO && isAdvancedEdit === item.get("id")) &&
          <AudioFadeController
            item={item}
            width={sliderPlot.width}
            cursorHeight={trackHeight}
            selectSlider={selectSlider}
            slider={slider}
          />}
      </SliderContainer>
    );
  }
}

TimelineSliderComponent.propTypes = {
  slider: SliderType.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectSlider: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  sliderMoved: PropTypes.bool.isRequired,
  isSliderDragging: PropTypes.bool.isRequired,
  trackHeight: PropTypes.number.isRequired,
  trackType: PropTypes.string.isRequired,
  forDragPosition: PropTypes.bool.isRequired,
  isThumbDrag: PropTypes.bool,
  timelinePlot: PlotType.isRequired,
  handleGapRemove: PropTypes.func,
  canUseMagnet: PropTypes.bool,
  enableContext: PropTypes.bool,
  itemContainer: PropTypes.string,
  item: PropTypes.object,
  timelineScrollPos: PropTypes.object,
  sliderRef: PropTypes.func,
  changeVolume: PropTypes.func,
  stepper: StepperType,
  copyToClipboard: PropTypes.func,
  pasteFromClipboard: PropTypes.func,
  canPaste: PropTypes.bool,
  duplicateHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  changeAudioVolume: PropTypes.func,
  detachAudio: PropTypes.func,
  setReplaceItem: PropTypes.func,
  setSwapInfo: PropTypes.func,
  setPanel: PropTypes.func,
  setExpand: PropTypes.func,
  rulerSlider: PropTypes.object,
  theme: PropTypes.string,
  isAdvancedEdit: PropTypes.string,
  setAdvancedEdit: PropTypes.func
};

const mapStateToProps = ({ app }) => {
  return {
    theme: app.get("theme"),
    isAdvancedEdit: app.get('isAdvancedEdit'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeVolume: (payload) => dispatch(updateItem(payload)),
  changeAudioVolume: (data) => dispatch(changeAudioVolume(data)),
  updateTimeline: (data) => dispatch(updateTimelineTime(data)),
  setReplaceItem: (data) => dispatch(setSwapItem(data)),
  setSwapInfo: (data) => dispatch(setSwapInfo(data)),
  setPanel: (data) => dispatch(setPropertyPanel(data)),
  setExpand: (data) => dispatch(setExpandPanel(data)),
  detachAudio: (data) => dispatch(setDetachData(data)),
  setAdvancedEdit: (data) => dispatch(setAdvancedEdit(data)),
});

const TimelineSlider = connect(mapStateToProps, mapDispatchToProps)(TimelineSliderComponent);

const TimelineSliderWithEvents = (props) => {
  const timelineScrollPos = useContext(TimelineScrollContext);
  return <TimelineSlider {...props} timelineScrollPos={timelineScrollPos} />;
};

export default TimelineSliderWithEvents;
