import styled, { keyframes } from "styled-components";
import { STATIC_PATH } from "../../../constants/config";

export const UploadMain = styled.div`
  ~ #modal-portal .upload--modal {
    width: 1056px;
    z-index: 999 !important;
    border-radius: ${(props) =>
        props.enableFooter ? "12px 12px 0px 0px" : "12px"};
    @media screen and (min-width: 993px) and (max-width: 1092px) {
      width: 95%;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      width: 95%;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      width: 95%;
      height: auto;
    }
    .modal-close-icon {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      &:hover {
        padding: 4px;
        background-color: #f0f1f5;
      }
    }
  }
`;

export const Skip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  height: 76px;
  padding: ${(props) => props.padding || "0px"};
  .skip-btn-btn {
    margin: 0px 80px 0px 0px;
    padding: 5px 20px;
    transition: all 0.3s;
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 14px;
      top: 8px;
      right: -20px;
      background: #d7dde0;
    }
    @media screen and (max-width: 767px) {
      margin: 0px;
    }
    &:hover {
      transform: scale(1.02);
      border-color: #00baff;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: start;
    padding: 6px 0px 0px;
    gap: 16px;
    margin-bottom: 20px;
    height: auto;
  }
`;

export const UploadWrapper = styled.div`
  padding: 24px 0px 16px 0px;
  .manual-btn-btn {
    position: relative;
    border: 1px solid #d7dde0;
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 20px;
      top: 6px;
      right: -20px;
      background: #d7dde0;
      @media screen and (max-width: 767px) {
        content: unset;
      }
    }
    &:hover {
      background: rgb(240, 251, 254);
      border: 1px solid #00baff;
    }
    @media screen and (max-width: 767px) {
      margin: 10px;
    }
  }
`;

export const UploadContainer = styled.div`
  margin: 24px;
  border: ${(props) =>
        props.dragging ? "2px dashed #00baff" : "2px dashed #e3e4e8"};
  width: auto;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0px;
  }
`;

const floatCloud = keyframes`
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.03);
  }
`;

export const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 14px;
  .upload-btn-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    label {
      position: relative;
      padding-left: 32px;
      @media only screen and (max-width: 767px) {
        width: auto;
      }
      &::before {
        content: url(${STATIC_PATH}sm-upload.svg);
        position: absolute;
        left: 5px;
        top: -1px;
      }
    }
    @media only screen and (max-width: 767px) {
      width: auto;
      padding: 5px 30px;
    }
  }
  img {
    animation: ${floatCloud} 2s infinite alternate;
  }
`;


export const UploadFooter = styled.div`
  display: flex;
  justify-content: end;
  margin: 0;
  padding: 16px 30px;
  background-color: rgb(255, 255, 255);
  border-radius: 0 0 12px 12px;
  height: ${(props) => (props.enableFooter ? "auto" : "0px")};
  opacity: ${(props) => (props.enableFooter ? "1" : "0")};
  z-index: ${(props) => (props.enableFooter ? "1" : "-1")};
  transition: all 0.1s ease-out;
  label {
    padding: 0px 20px;
  }
  .hover-color {
    background: #00baff !important;
  }
`;

export const ExportContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 14px 24px 24px;
  flex-wrap: wrap;
  justify-content: start;
  overflow-y: auto;
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    /* height: 430px; */
  }
  @media only screen and (max-width: 767px) {
    justify-content: center;
    /* height: 430px; */
  }
`;

export const ExportList = styled.div`
  background: #f5f6fa;
  width: 235px;
  height: auto;
  border-radius: 8px;
  padding: 8px 8px 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  border: ${(props) => props.isSelected ? `1px solid #00BAFF` : "transparent"};
  .option-image {
    width: 125px;
    height: 125px;
    margin: auto;
    display: block;
    object-fit: contain;
    border-radius: 4px;
  }
  .duration {
    position: absolute;
    left: 10px;
    bottom: 42px;
    background-color: #313345;
    padding: 2px 4px;
    border-radius: 4px;
  }
  &:hover {
    transform: scale(1.02);
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 31%;
    height: auto;
  }
  @media only screen and (max-width: 767px) {
    width: 47%;
    height: auto;
  }
`;

export const LibraryWrapper = styled.div`
  overflow-y: auto;
  height: 420px;
  .hover-color,
  .cancel-btn-btn {
    &:hover {
      transform: scale(1.02);
    }
  }
  .library-placeholder {
    @media screen and (max-width: 1024px) {
      height: 400px;
      overflow: hidden;
    }
  }
  @media screen and (max-width: 1024px) {
    overflow-y: auto;
    .rect {
      width: 55%;
    }
  }
`;

export const Folder = styled.div`
  width: ${(props) => props.width || "240px"};
  height: ${(props) => props.height || "48px"};
  background: #e6f7ff 0% 0% no-repeat padding-box;
  border: 1px solid #c9e4f4;
  border-radius: 8px;
  display: inline-block;
  padding: 5px 8px;
  margin: ${(props) => props.margin || "10px 0 10px 0px"};
  position: relative;
  display: flex;
  align-items: center;
  gap: 13px;
  padding-left: 20px;
  cursor: pointer;
  .dot-menu {
    display: none;
  }
  &:hover {
    border-color: #1dd6a1;
    .dot-menu {
      display: block;
    }
  }
  &:before {
    content: url(${STATIC_PATH}foldericons.svg);
    left: 12px;
    top: 10px;
    transform: scale(1);
    transition: all 0.5s ease-in-out;
    position: absolute;
  }
  &:hover::before {
    transform: scale(1.1);
  }
  @media (max-width: 576px) {
    width: 155px;
    padding-right: 5px;
  }
`;

export const NewFolderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  gap: ${(props) => props.gap || "0px 10px"};
  margin: ${(props) => props.margin || "0 0 25px"};
  height: max-content;
  @media screen and (max-width: 1024px) {
    gap: 10px;
  }
`;

export const customStyles = `
  width: fit-content;
  margin: 0px 20px;
  &::after{
    content: "";
    position: absolute;
    bottom: -2px;
    width: 73.3vw;
    height: 2px;
    background: #eee;
    left: -20px;
    @media screen and (max-width: 767px){
      width: 95vw;
    }
  }
`