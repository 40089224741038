
export function setMicrosoftClarity(set, key, value) {
    try {
        if (window.clarity) {
            window.clarity(set, key, value)
        }
    } catch (error) {
        // error(error, error.message, 'Error In Microsoft Clarity')
    }
}

export function setStageClarity(stage) {
    try {
        if (window.clarity) {
            setMicrosoftClarity("set", "stage", stage);
        }
    } catch (error) {
        // error(error, error.message, 'Error In Microsoft Clarity')
    }
}