import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const Switch = styled.label`
  position: relative;
  display: inline-block;
  border-radius: ${(props) => props.borderRadius};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  &::before {
    content: "";
    border-radius: 50%;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background: ${(props) =>
        props.toggleColor ||
        `transparent ${(props) =>
          props.theme.magentaColor} 0% 0% no-repeat padding-box`};
      &::before {
        transform: translateX(calc(${(props) => props.width} - 20px));
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px transparent ${(props) => props.theme.magentaColor} 0%
        0% no-repeat padding-box;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.headerImgBorderColor};
    transition: transform 0.4s ease; 
    border-radius: 34px;
    &::before {
      position: absolute;
      content: "";
      height: ${(props) => props.afterHeight || "16px"}; 
      width: ${(props) => props.afterWidth || "16px"}; 
      left: ${(props) => props.afterLeft || "2px"};; 
      bottom: ${(props) => props.afterBottom || "2px"};
      background-color: ${(props) => props.theme.polarColor};
      border-radius: 50%;
      transition: transform 0.4s ease; /* Apply transition on transform property */
    }
    ${(props) =>
      props.isDisabled &&
      css`
        cursor: not-allowed;
      `}
  }
`;

const Toggle = (props) => {
  const { clickHandler, value, isDisabled, afterWidth, afterHeight,afterLeft,afterBottom } = props;
  return (
    <Switch
      {...props}
      afterWidth={afterWidth}
      afterHeight={afterHeight}
      afterBottom={afterBottom}
      afterLeft={afterLeft}
    >
      <input
        type="checkbox"
        checked={value}
        onClick={isDisabled ? undefined : clickHandler}
      />
      <span className="slider" />
    </Switch>
  );
};

/**
 * Default Props Value for Toggle
 */
Toggle.defaultProps = {
  value: false,
  clickHandler: () => {},
  borderRadius: "34px",
  height: "20px",
  width: "32px",
};

/**
 * Initialize Prop Types for Toggle
 */
Toggle.propTypes = {
  value: PropTypes.bool,
  clickHandler: PropTypes.func,
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  bordercolor: PropTypes.string,
  afterWidth: PropTypes.string,
  afterHeight: PropTypes.string,
  afterBottom: PropTypes.string,
  afterLeft: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default Toggle;
