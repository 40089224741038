const isEqual = (newArgs, oldArgs) => {
    if (newArgs.length !== oldArgs.length) {
        return false;
    }
    const len = newArgs.length;
    for (let idx = 0; idx < len; idx += 1) {
        if (newArgs[idx] !== oldArgs[idx]) {
            return false;
        }
    }
    return true;
};

export const createMemoizedFunction = () => {
    const cache = {
        deps: undefined,
        result: undefined,
    };

    return (func, deps) => {
        if (!deps || !cache.deps || !isEqual(deps, cache.deps)) {
            cache.result = func();
            cache.deps = deps;
        }
        return cache.result;
    };
}