import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

// dual ring spinner
const ldsDualRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledDualRing = styled.div`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.spinnerHeight ? props.spinnerHeight : "80px")};

  &:after {
    content: " ";
    display: block;
    width: 70px;
    height: 70px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${(props) => props.theme.checkBoxTickColor};
    border-color: ${(props) => props.theme.checkBoxTickColor} transparent
      ${(props) => props.theme.checkBoxTickColor} transparent;
    animation: ${ldsDualRingAnimation} 1.2s linear infinite;
  }
`;

// ring spinner
const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledRing = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  height: ${(props) => (props.spinnerHeight ? props.spinnerHeight : "80px")};
  z-index: 1;
`;

const StyledRingElement = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid ${(props) => props.background || props.theme.checkBoxTickColor};
  border-radius: 50%;
  animation: ${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) => props.theme.checkBoxTickColor} transparent
    transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

// ellipsis spinner
const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const StyledEllipsis = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  height: ${(props) => (props.spinnerHeight ? props.spinnerHeight : "80px")};
`;

const StyledEllipsisElement = styled.div`
  position: absolute;
  top: 33px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.background || props.theme.secondaryBorderColor};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 32px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(4) {
    left: 56px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`;

// dotted spinner
const ldsDottedAnimation = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const StyledDotted = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: ${(props) => (props.spinnerHeight ? props.spinnerHeight : "80px")};
  width: ${(props) => (props.spinnerHeight ? props.spinnerHeight : "80px")};
`;

const StyledDottedElement = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background: ${(props) => props.background || props.theme.checkBoxTickColor};
  border-radius: 50%;
  animation: ${ldsDottedAnimation} 1.2s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }

  &:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }

  &:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }

  &:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }

  &:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }

  &:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }

  &:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }

  &:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }

  &:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }

  &:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }

  &:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }

  &:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
`;

// roller spinner
const ldsRollerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledRoller = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: ${props => props.height || '80px'};
`;

const StyledRollerElement = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${(props) => props.background || props.theme.checkBoxTickColor};
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${(props) => props.theme.polarColor};
    margin: -4px 0 0 -4px;
  }
`;

const StyledRollerElement1 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.036s;
  &:after {
    top: 63px;
    left: 63px;
  }
`;

const StyledRollerElement2 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.072s;
  &:after {
    top: 68px;
    left: 56px;
  }
`;

const StyledRollerElement3 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.108s;
  &:after {
    top: 71px;
    left: 48px;
  }
`;

const StyledRollerElement4 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.144s;
  &:after {
    top: 72px;
    left: 40px;
  }
`;

const StyledRollerElement5 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.18s;
  &:after {
    top: 71px;
    left: 32px;
  }
`;

const StyledRollerElement6 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.216s;
  &:after {
    top: 68px;
    left: 24px;
  }
`;

const StyledRollerElement7 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.252s;
  &:after {
    top: 63px;
    left: 17px;
  }
`;

const StyledRollerElement8 = styled(StyledRollerElement)`
  animation: ${ldsRollerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.288s;
  &:after {
    top: 56px;
    left: 12px;
  }
`;

export const SPINNER_TYPE = {
  DUAL_RING_SPINNER: "dualRingSpinner",
  RING_SPINNER: "ringSpinner",
  ELLIPSIS_SPINNER: "ellipsisSpinner",
  DOTTED_SPINNER: "dottedSpinner",
  ROLLER_SPINNER: "rollerSpinner",
};

export default function Spinner({ type, spinnerHeight, spinnerColor }) {
  switch (type) {
    case SPINNER_TYPE.DUAL_RING_SPINNER: {
      return <StyledDualRing spinnerHeight={spinnerHeight} />;
    }
    case SPINNER_TYPE.RING_SPINNER: {
      return (
        <StyledRing spinnerHeight={spinnerHeight}>
          <StyledRingElement background={spinnerColor} />
          <StyledRingElement background={spinnerColor} />
          <StyledRingElement background={spinnerColor} />
        </StyledRing>
      );
    }
    case SPINNER_TYPE.ELLIPSIS_SPINNER: {
      return (
        <StyledEllipsis spinnerHeight={spinnerHeight}>
          <StyledEllipsisElement background={spinnerColor} />
          <StyledEllipsisElement background={spinnerColor} />
          <StyledEllipsisElement background={spinnerColor} />
          <StyledEllipsisElement background={spinnerColor} />
        </StyledEllipsis>
      );
    }
    case SPINNER_TYPE.DOTTED_SPINNER: {
      return (
        <StyledDotted spinnerHeight={spinnerHeight}>
          {[...Array(12)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledDottedElement key={index} background={spinnerColor} />
          ))}
        </StyledDotted>
      );
    }
    case SPINNER_TYPE.ROLLER_SPINNER: {
      return (
        <StyledRoller spinnerHeight={spinnerHeight}>
          <StyledRollerElement1 />
          <StyledRollerElement2 />
          <StyledRollerElement3 />
          <StyledRollerElement4 />
          <StyledRollerElement5 />
          <StyledRollerElement6 />
          <StyledRollerElement7 />
          <StyledRollerElement8 />
        </StyledRoller>
      );
    }
    default: {
      return null;
    }
  }
}

Spinner.propTypes = {
  type: PropTypes.string,
  spinnerHeight: PropTypes.number,
  spinnerColor: PropTypes.string
};
