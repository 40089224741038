import { Record } from "immutable";
import content from "./content";
import { STATIC_PATH } from "./config";
import vmTheme from "./theme/index";

const {
  MOVE_TO,
  RENAME,
  DELETE,
  NO_TRANSITION,
  FADE,
  SIDE_LEFT,
  SIDE_TOP,
  SIDE_BOTTOM,
  SIDE_RIGHT,
  LANDSCAPE,
  POETRAIT,
  SQUARE,
} = content;

export const PORTAL_ID = "modal-portal";

export const MOBILE_THRESHOLD = 768;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PANEL = {
  NONE: "NONE",
  VIRALSTYLE: "VIRALSTYLE",
  PROPERTIES: "PROPERTIES",
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  MUSIC: "MUSIC",
  UPLOAD: "UPLOAD",
  TRANSITIONS: "TRANSITIONS",
  FAVOURITE: "FAVOURITE",
  GIPHY: "GIPHY",
  VERSION_HISTORY: "VERSION_HISTORY",
  SUBTITLE: "SUBTITLE",
  UNSPLASH: "UNSPLASH",
  PEXELS: "PEXELS",
  PEXELS_VIDEO: "PEXELS_VIDEO",
  PIXABAY: "PIXABAY",
  PIXABAY_VIDEO: "PIXABAY_VIDEO",
  MORE: "MORE",
  AVATAR: "AVATAR"
};

export const PanelRecord = Record({
  selectedPanel: PANEL.NONE,
  isExpand: false,
});

export const PropertyWindowRecord = Record({
  component: "",
  subWindow: "",
  isTextColor: false,
  type: "",
  isOpened: false,
});

export const PANEL_ITEMS = (theme) => {
  return [
    {
      name: PANEL.AVATAR,
      src: `${STATIC_PATH}${vmTheme[theme].icons.avatar}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.avatarSelected}`,
      content: content.AI_AVATARS,
    },
    {
      name: PANEL.VIDEO,
      src: `${STATIC_PATH}${vmTheme[theme].icons.videoIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.videoIcon}`,
      content: content.VIDEO,
    },
    {
      name: PANEL.IMAGE,
      src: `${STATIC_PATH}${vmTheme[theme].icons.imageIcon}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.imageIconSelected}`,
      content: content.IMAGE,
    },
    {
      name: PANEL.MUSIC,
      src: `${STATIC_PATH}${vmTheme[theme].icons.musicIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.musicIcon}`,
      content: content.MUSIC,
    },
    {
      name: PANEL.SUBTITLE,
      src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
      content: content.SUBTITLE,
    },
    {
      name: PANEL.PROPERTIES,
      src: `${STATIC_PATH}${vmTheme[theme].icons.property}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.propertySelected}`,
      content: content.ELEMENTS,
    },
    {
      name: PANEL.TEXT,
      src: `${STATIC_PATH}${vmTheme[theme].icons.textIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.textIcon}`,
      content: content.TEXT,
    },
    {
      name: PANEL.UPLOAD,
      src: `${STATIC_PATH}${vmTheme[theme].icons.uploadIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.uploadIcon}`,
      content: content.UPLOAD,
    },

    // {
    //   name: PANEL.GIPHY,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.giphyIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.giphySelectedIcon}`,
    //   content: content.GIPHY,
    // },
    {
      name: PANEL.TRANSITIONS,
      src: `${STATIC_PATH}transitions-panel-icon.svg`,
      selectedSrc: `${STATIC_PATH}transitions-panel-selected.svg`,
      content: content.TRANSITIONS,
    },

    // {
    //   name: PANEL.UNSPLASH,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.UNSPLASH,
    // },
    // {
    //   name: PANEL.PEXELS,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PEXELS,
    // },
    // {
    //   name: PANEL.PEXELS_VIDEO,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PEXELS_VIDEO,
    // },
    // {
    //   name: PANEL.PIXABAY,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PIXABAY,
    // },
    // {
    //   name: PANEL.PIXABAY_VIDEO,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PIXABAY_VIDEO,
    // }
    {
      name: PANEL.MORE,
      src: `${STATIC_PATH}${vmTheme[theme].icons.moreIcon}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.moreActiveIcon}`,
      content: content.MORE,
    },
  ];
};

export const PROPERTIES_FILTER = [
  {
    category: "Shapes (With Text)",
    source: [
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/29eae6290a834014b648b0743638a29e-shape-0030.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/e3b66477d06f46e58cc7f56800ace373-shape-0018.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/29eae6290a834014b648b0743638a29e-shape-0030.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/e3b66477d06f46e58cc7f56800ace373-shape-0018.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/1bb3463aa83d4f3899de4dc7eace3694-shape-0009.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/10b95b3a7cef401daadd65d9695c0a13-shape-0011.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/4ce2b2ee54fb45fdb0337c7d6cc10a86-shape-0013.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/29eae6290a834014b648b0743638a29e-shape-0030.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/e3b66477d06f46e58cc7f56800ace373-shape-0018.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/1bb3463aa83d4f3899de4dc7eace3694-shape-0009.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/10b95b3a7cef401daadd65d9695c0a13-shape-0011.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/4ce2b2ee54fb45fdb0337c7d6cc10a86-shape-0013.png",
      },
    ],
  },
];

export const VIDEO_FILTERS = [
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
];

export const TRANSITION_PANEL_ITEMS = [
  {
    src: `${STATIC_PATH}no-transition.svg`,
    title: NO_TRANSITION,
  },
  {
    src: `${STATIC_PATH}fade.svg`,
    title: FADE,
  },
  {
    src: `${STATIC_PATH}side-left.svg`,
    title: SIDE_LEFT,
  },
  {
    src: `${STATIC_PATH}side-top.svg`,
    title: SIDE_TOP,
  },
  {
    src: `${STATIC_PATH}side-bottom.svg`,
    title: SIDE_BOTTOM,
  },
  {
    src: `${STATIC_PATH}side-right.svg`,
    title: SIDE_RIGHT,
  },
];

export const MENU_OPTIONS = [MOVE_TO, RENAME, DELETE];

export const CONSTANTS = {
  LIBRARY_TYPE: {
    TEXT: "TEXT",
    PROPERTY: {
      MASPROP: "MASPROP",
      PROP_SHAPE_FRAME: "PROP,SHAPE,FRAME",
    },
    VIDEO: {
      STOCKVID: "STOCKVID",
      MASSTOCKVID: "MASSTOCKVID",
      PEXELS_VIDEO: "PEXELS_VIDEO",
      PIXABAY_VIDEO: "PIXABAY_VIDEO"
    },
    IMAGE: {
      STOCKIMG: "STOCKIMG",
      MASSTOCKIMG: "MASSTOCKIMG",
      UNSPLASH: "UNSPLASH",
      PEXELS: "PEXELS",
      PIXABAY: "PIXABAY"
    },
    MUSIC: {
      MASBGM: "MASBGM",
      MASSFX: "MASSFX",
      SFX: "SFX",
      BGM: "BGM",
    },
    GIPHY: {
      STOCKGIPHY: "GIPHY",
      MASGIPHY: "MASGIPHY",
    },
    VIRALSTYLE: {
      STOCKGIPHY: "VIRALSTYLE",
      MASGIPHY: "MASVIRALSTYLE",
    },
  },
};

export const FILE_FORMATS = [
  { label: "mp4", id: "mp4", value: "mp4" },
  { label: "avi", id: "avi", value: "avi" },
  { label: "mkv", id: "mkv", value: "mkv" },
  { label: "flv", id: "flv", value: "flv" },
  { label: "wmv", id: "wmv", value: "wmv" },
  { label: "webm", id: "webm", value: "webm" },
];

export const PLAN = {
  FREE: "free",
  LITE: "lite",
  STARTER: "starter",
  TEAM: "team",
  ENTERPRISE: "enterprise",
  TIER1: "tier1",
  TIER2: "tier2",
  TIER3: "tier3",
  TIER4: "tier4",
  TIER5: "tier5",
};

export const PLAN_CONFIG = {
  SIZE_OPTIONS: {
    [PLAN.LITE]: 500 * 1024 * 1024,  // 500MB
    [PLAN.STARTER]: 2 * 1024 * 1024 * 1024, // 2GB
    [PLAN.TEAM]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER1]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER2]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER3]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER4]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER5]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.ENTERPRISE]: 50 * 1024 * 1024 * 1024 // 50GB
  }
}

export const QUALITY_OPTIONS = (name, plan, exportquality) => {
  const quality = exportquality || ['4K', '2K', 'FHD', 'HD', 'SD'];
  switch (name) {
    case "horizontal":
      return [
        {
          label: "SD 480p",
          id: "480p",
          value: "pre0001",
          subtext: "Facebook Ad, Youtube Ad, LinkedIn Ad",
          name: "SD",
          isUpgrade: !(quality.indexOf("SD") > -1),

        },
        {
          label: "HD 720p",
          id: "720p",
          value: "pre0002",
          subtext: "Most Preferred",
          name: "HD",
          isUpgrade: !(quality.indexOf("HD") > -1),

        },
        {
          label: "Full HD 1080p",
          id: "1080p",
          value: "pre0003",
          subtext: "Audio: AAC 320kbps Stereo, 48000hz",
          name: "FHD",
          isUpgrade: !(quality.indexOf("FHD") > -1),
          icon: "starter.svg"
        },
        {
          label: "2k WQHD 1440p",
          id: "1440p",
          value: "pre0004",
          subtext: "Facebook Ad, Youtube Ad, LinkedIn Ad",
          name: "2K",
          isUpgrade: !(quality.indexOf("2K") > -1),
          icon: "team.svg"
        },
        {
          label: "4k Ultra HD 2160p",
          id: "2160p",
          value: "pre0005",
          subtext: "Facebook Ad, Youtube Ad, LinkedIn Ad",
          name: "4K",
          isUpgrade: !(quality.indexOf("4K") > -1),
          icon: "enterprice.svg"
        },
      ];
    case "vertical":
      return [
        {
          label: "SD 360p",
          id: "360p",
          value: "pre0006",
          subtext: "Select Resolution",
          name: "SD",
          isUpgrade: !(quality.indexOf("SD") > -1),

        },
        {
          label: "HD 720p",
          id: "720p",
          value: "pre0007",
          subtext: "Select Resolution",
          name: "HD",
          isUpgrade: !(quality.indexOf("HD") > -1),

        },
        {
          label: "Full HD 1080p",
          id: "1080p",
          value: "pre0008",
          subtext: "Select Resolution",
          name: "FHD",
          isUpgrade: !(quality.indexOf("FHD") > -1),
          icon: "starter.svg"
        },
        {
          label: "2k WQHD 1440p",
          id: "1440p",
          value: "pre0009",
          subtext: "Select Resolution",
          name: "2K",
          isUpgrade: !(quality.indexOf("2K") > -1),
          icon: "team.svg"
        },
        {
          label: "4k Ultra HD 2160p",
          id: "2160p",
          value: "pre0010",
          subtext: "Select Resolution",
          name: "4K",
          isUpgrade: !(quality.indexOf("4K") > -1),
          icon: "enterprice.svg"
        },
      ];
    default:
      return [
        {
          label: "SD 400p",
          id: "400p",
          value: "pre0011",
          subtext: "Select Resolution",
          name: "SD",
          isUpgrade: !(quality.indexOf("SD") > -1),

        },
        {
          label: "HD 600p",
          id: "600p",
          value: "pre0012",
          subtext: "Select Resolution",
          name: "HD",
          isUpgrade: !(quality.indexOf("HD") > -1),

        },
        {
          label: "Full HD 1080p",
          id: "1080p",
          value: "pre0013",
          subtext: "Select Resolution",
          name: "FHD",
          isUpgrade: !(quality.indexOf("FHD") > -1),
          icon: "starter.svg"
        },
        {
          label: "2k WQHD 1440p",
          id: "1440p",
          value: "pre0014",
          subtext: "Select Resolution",
          name: "2K",
          isUpgrade: !(quality.indexOf("2K") > -1),
          icon: "team.svg"
        },
        {
          label: "4k Ultra HD 2160p",
          id: "2160p",
          value: "pre0015",
          subtext: "Select Resolution",
          name: "4K",
          isUpgrade: !(quality.indexOf("4K") > -1),
          icon: "enterprice.svg"
        },
      ];
  }
};

export const DEFAULT_QUALITY_OPTIONS = (name, plan, exportquality) => {
  let value = null;
  switch (plan) {
    case PLAN.ENTERPRISE:
      value = QUALITY_OPTIONS(name, plan, exportquality)[2].value;
      break;
    case PLAN.TIER1:
    case PLAN.TIER2:
    case PLAN.TIER3:
    case PLAN.TIER4:
    case PLAN.TIER5:
    case PLAN.TEAM:
      value = QUALITY_OPTIONS(name, plan, exportquality)[1].value;
      break;
    case PLAN.STARTER:
      value = QUALITY_OPTIONS(name, plan, exportquality)[1].value;
      break;
    case PLAN.LITE:
      value = QUALITY_OPTIONS(name, plan, exportquality)[0].value;
      break;
    default:
      value = QUALITY_OPTIONS(name, PLAN.LITE, exportquality)[0].value;
      break;
  }
  return value;
};

export const APP_Z_INDICES = {
  workspaceWrapper: {
    default: 0,
    onPlay: 2,
  },
  timeline: {
    default: 3,
  },
  header: {
    default: 1,
    onPlay: "auto",
  },
  itemtoolbar: {
    default: 4,
  },
  itemtoolbarconfirm: {
    default: 5,
  },
  headerPreviewButton: {
    // inside header
    default: 3,
  },
  sidebar: {
    default: 1,
  },
  libraryDragItem: {
    default: 6,
  },
  customtooltip: {
    default: 99999,
  },
  playerbufferloader: {
    // inside workspace
    default: 999,
  },
  playerprogressloader: {
    // inside workspace
    default: 999,
  },
  colorpicker: {
    default: 998,
  },
};

export const KEYBOARD_SHORTCUTS_DATA = {
  Slides: [
    {
      name: "Add Scene",
      keys: ["Cmd", "M"],
    },
    {
      name: "Select Next Scene",
      keys: ["↓"],
    },
    {
      name: "Select Previous Scene",
      keys: ["↑"],
    },
    {
      name: "Move Scene Up",
      keys: ["↑"],
    },
    {
      name: "Move Scene Down",
      keys: ["↓"],
    },
    {
      name: "Move Scene First",
      keys: ["Cmd", "Shift", "↑"],
    },
    {
      name: "Move Scene Last",
      keys: ["Cmd", "Shift", "↓"],
    },
    {
      name: "Duplicate Scene",
      keys: ["Cmd", "D"],
    },
    {
      name: "Delete Scene",
      keys: ["Delete"],
    },
    {
      name: "Select Multiple Scene",
      keys: ["Cmd", "Click"],
    },
    {
      name: "Change Transition",
      keys: ["Shift", "T"],
    },
    {
      name: "Copy Scene With Audio",
      keys: ["Cmd", "Shift", "C"],
    },
  ],
  Timeline: [
    {
      name: "Increase Item Start 1 Sec",
      keys: ["Opt", "S"],
    },
    {
      name: "Decrease Item Start 1 Sec",
      keys: ["Opt", "A"],
    },
    {
      name: "Increase Item End 1 Sec",
      keys: ["Opt", "W"],
    },
    {
      name: "Decrease Item End 1 Sec",
      keys: ["Opt", "Q"],
    },
    {
      name: "Increase Enter Effect Time 1 Sec",
      keys: ["Shift", "Opt", "S"],
    },
    {
      name: "Decrease Enter Effect Time 1 Sec",
      keys: ["Shift", "Opt", "A"],
    },
    {
      name: "Increase Exit Effect Time 1 Sec",
      keys: ["Shift", "Opt", "W"],
    },
    {
      name: "Decrease Exit Effect Time 1 Sec",
      keys: ["Shift", "Opt", "Q"],
    },
    {
      name: "Change Enter Effect",
      keys: ["Opt", "←"],
    },
    {
      name: "Change Exit Effect",
      keys: ["Opt", "→"],
    },
    {
      name: "Increase Scene Duration",
      keys: ["Opt", "L"],
    },
    {
      name: "Decrease Scene Duration",
      keys: ["Opt", "K"],
    },
    {
      name: "Increase Playhead 1 Sec",
      keys: ["→"],
    },
    {
      name: "Decrease Playhead 1 Sec",
      keys: ["←"],
    },
    {
      name: "Increase Playhead 5 Sec",
      keys: ["Shift", "→"],
    },
    {
      name: "Decrease Playhead 5 Sec",
      keys: ["Shift", "←"],
    },
  ],
  Menubar: [
    {
      name: "Export Project",
      keys: ["Cmd", "E"],
    },
    {
      name: "Undo",
      keys: ["Cmd", "Z"],
    },
    {
      name: "Rndo",
      keys: ["Cmd", "Y"],
    },
  ],
  Text: [
    {
      name: "Align Right",
      keys: ["Cmd", "Shift", "R"],
    },
    {
      name: "Align Left",
      keys: ["Cmd", "Shift", "L"],
    },
    {
      name: "Align Center",
      keys: ["Cmd", "Shift", "E"],
    },
    {
      name: "To Uppercase",
      keys: ["Cmd", "Shift", "U"],
    },
    {
      name: "To Lowercase",
      keys: ["Cmd", "Shift", "S"],
    },
    {
      name: "To Capitalize",
      keys: ["Cmd", "Shift", "C"],
    },
    {
      name: "To Removecase",
      keys: ["Cmd", "'"],
    },
    {
      name: "Set Bold",
      keys: ["Cmd", "B"],
    },
    {
      name: "Set Italic",
      keys: ["Cmd", "|"],
    },
    {
      name: "Increase Font Size",
      keys: ["Cmd", "Shift", ">"],
    },
    {
      name: "Decrease Font Size",
      keys: ["Cmd", "Shift", "<"],
    },
    {
      name: "Change Font Family",
      keys: ["Cmd", "Shift", "F"],
    },
  ],
  Player: [
    {
      name: "Play/Pause",
      keys: ["Space"],
    },
    {
      name: "PlayAll/Pause",
      keys: ["Shift", "Space"],
    },
  ],
  Workspace: [
    {
      name: "Move Object Down 10px",
      keys: ["↓"],
    },
    {
      name: "Move Object Top 10px",
      keys: ["↑"],
    },
    {
      name: "Move Object Left 10px",
      keys: ["←"],
    },
    {
      name: "Move Object Right 10px",
      keys: ["→"],
    },
    {
      name: "Move Object Down 1px",
      keys: ["Shift", "↓"],
    },
    {
      name: "Move Object Top 1px",
      keys: ["Shift", "↑"],
    },
    {
      name: "Move Object Left 1px",
      keys: ["Shift", "←"],
    },
    {
      name: "Move Object Right 1px",
      keys: ["Shift", "→"],
    },
    {
      name: "Select All Objects",
      keys: ["Cmd", "A"],
    },
    {
      name: "Deselect All Objects",
      keys: ["Cmd", "Shift", "A"],
    },
    {
      name: "Select Multiple Objects",
      keys: ["Cmd", "Click"],
    },
    {
      name: "Copy Item",
      keys: ["Cmd", "C"],
    },
    {
      name: "Cut Item",
      keys: ["Cmd", "X"],
    },
    {
      name: "Paste Item",
      keys: ["Cmd", "V"],
    },
    {
      name: "Delete Item",
      keys: ["DeleteBackspace"],
    },
    {
      name: "Zoom In",
      keys: ["Cmd", "Opt", "+"],
    },
    {
      name: "Zoom Out",
      keys: ["Cmd", "Opt", "-"],
    },
    {
      name: "Bring Front",
      keys: ["Cmd", "Shift", "↑"],
    },
    {
      name: "Move Last",
      keys: ["Cmd", "Shift", "↓"],
    },
    {
      name: "Bring First",
      keys: ["Cmd", "↑"],
    },
    {
      name: "Move Back",
      keys: ["Cmd", "↓"],
    },
    {
      name: "Flip Item",
      keys: ["Opt", "F"],
    },
    {
      name: "Group Item",
      keys: ["Cmd", "Opt", "G"],
    },
    {
      name: "Ungroup Item",
      keys: ["Cmd", "Opt", "Shift", "G"],
    },
  ],
  Library: [
    {
      name: "Open Prebuild",
      keys: ["Shift", "1"],
    },
    {
      name: "Open Character",
      keys: ["Shift", "2"],
    },
    {
      name: "Open Properties",
      keys: ["Shift", "3"],
    },
    {
      name: "Open Text",
      keys: ["Shift", "4"],
    },
    {
      name: "Open Background",
      keys: ["Shift", "5"],
    },
    {
      name: "Open Image",
      keys: ["Shift", "6"],
    },
    {
      name: "Open Video",
      keys: ["Shift", "7"],
    },
    {
      name: "Open Bgm",
      keys: ["Shift", "8"],
    },
    {
      name: "Open Screen Effects",
      keys: ["Shift", "9"],
    },
    {
      name: "Open Uploads",
      keys: ["Shift", "U"],
    },
  ],
};
export const VIDEO_RESIZE_DETAILS = (theme) => {
  return {
    horizontal: {
      projectWidth: 800,
      projectHeight: 450,
      src:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
            <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
            <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
              <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
            </g>
            <g id="Group_78745" data-name="Group 78745" transform="translate(98 361)">
              <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
            </g>
          </g>
        </svg>`
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
          <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78745" data-name="Group 78745" transform="translate(98 361)">
          <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>`,
      selectedSrc:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
          <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78745" data-name="Group 
        78745" transform="translate(98 361)">
          <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#fdc100" fill-rule="evenodd"/>
        </g>
      </g>
    </svg> 
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
        <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#e6f8ff" fill-rule="evenodd"/>
      </g>
      <g id="Group_78745" data-name="Group 78745" transform="translate(98 361)">
        <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#00baff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      title: LANDSCAPE,
      name: "Landscape (16.9)",
    },
    vertical: {
      projectWidth: 450,
      projectHeight: 800,
      src:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
          <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
          <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>`
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
        <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
        <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      selectedSrc:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
          <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
          <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#fdc100" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
        <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
        <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#00baff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      title: POETRAIT,
      name: "Portrait (9:16)",
    },
    square: {
      projectWidth: 450,
      projectHeight: 450,
      src:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
          <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
          <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
        <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
        <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      selectedSrc:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
          <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
          <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#fdc100" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
        <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
        <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#00baff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      title: SQUARE,
      name: "Square (1:1)",
    },
  };
};

export const VALID_IMAGE_TYPES = ["UPIMAGE", "STOCKIMG", "PIXABAY", "PEXELS"];

export const ANIMO_RENDER_TYPES = ["PROP", "SHAPE", "TEXT"];

export const DEFAULT_FILTER_CODE = "6464646464640032";

export const PROJECT_CONTAINERS = ["workspaceItems", "audios"];

export const isImageSubtypeOnly = (subType) => {
  return (
    subType === "IMAGE" ||
    subType === "ISESS" ||
    subType === "ISSIG" ||
    subType === "GETTY" ||
    subType === "RECORD"
  );
};

export const colors = {
  highlightText: "#54536E",
  Green: "#2AC79A",
  white: "#FFFFFF",
  OrangeDark: "#FF7F00",
};

export const teamBgColor = {
  [PLAN.LITE]: "#ebecf0",
  [PLAN.STARTER]: "rgb(255, 195, 0)",
  [PLAN.TIER1]: "rgb(89, 211, 255)",
  [PLAN.TIER2]: "rgb(89, 211, 255)",
  [PLAN.TIER3]: "rgb(89, 211, 255)",
  [PLAN.TIER4]: "rgb(89, 211, 255)",
  [PLAN.TIER5]: "rgb(89, 211, 255)",
  [PLAN.TEAM]: "rgb(89, 211, 255)",
  [PLAN.ENTERPRISE]: "#C790FA",
};

export const teamTextColor = {
  [PLAN.LITE]: "#000",
  [PLAN.STARTER]: "#fff",
  [PLAN.TIER1]: "#fff",
  [PLAN.TIER2]: "#fff",
  [PLAN.TIER3]: "#fff",
  [PLAN.TIER4]: "#fff",
  [PLAN.TIER5]: "#fff",
  [PLAN.TEAM]: "#fff",
  [PLAN.ENTERPRISE]: "#fff",
};

export const iconPlan = {
  [PLAN.LITE]: "",
  [PLAN.STARTER]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER1]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER2]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER3]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER4]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER5]: `${STATIC_PATH}white-star.png`,
  [PLAN.TEAM]: `${STATIC_PATH}white-star.png`,
  [PLAN.ENTERPRISE]: `${STATIC_PATH}white-star.png`,
};

export const plans = {
  [PLAN.STARTER]: "#FFC300",
  [PLAN.ENTERPRISE]: "#D4AFFF",
  [PLAN.LITE]: "#D8D8D8",
  [PLAN.TEAM]: "#5AD4FF",
  [PLAN.TIER1]: "#5AD4FF",
  [PLAN.TIER2]: "#5AD4FF",
  [PLAN.TIER3]: "#5AD4FF",
  [PLAN.TIER4]: "#5AD4FF",
  [PLAN.TIER5]: "#5AD4FF",
};

export const STARTER_PLAN = [
  "S_PLAN_1",
  "S_PLAN_2",
  "S_PLAN_3",
  "S_PLAN_4",
  "S_PLAN_5",
];

export const TEAM_PLAN = [
  "T_PLAN_1",
  "T_PLAN_2",
  "T_PLAN_3",
  "T_PLAN_4",
  "T_PLAN_5",
];

const DOWNLOAD_SUBMENU = [
  { name: ".SRT format", actionName: "SRT" },
  { name: ".VTT format", actionName: "VTT" },
  { name: ".SBV format", actionName: "SBV" },
  { name: ".ASS format", actionName: "ASS" },
];

export const SUBTITLE_MENU_OPTIONS = [
  {
    icon: "sm-vm-download.svg",
    name: "Download",
    actionName: "DOWNLOAD",
    subOptions: DOWNLOAD_SUBMENU,
  },
  { icon: "sm-vm-delete.svg", name: "Delete", actionName: "DELETE" },
];

export const HEADER_MENU = [
  { icon: "vm-upload.svg", name: "Upload Subtitle", actionName: "UPLOAD" },
  {
    icon: "sm-vm-download.svg",
    name: "Download",
    actionName: "DOWNLOAD",
    subOptions: DOWNLOAD_SUBMENU,
  },
  {
    icon: "sm-vm-delete.svg",
    name: "Clear subtitle",
    actionName: "CLEAR_ALL",
  },
];

export const PLAN_CONTENT = {
  [PLAN.STARTER]: {
    default: [
      "Everything in Lite plus",
      "Remove green screen",
      "5 GB upload storage limit",
      "Auto Subtitles 60 mins/month",
      "Export in 1080p resolution",
    ],
    durationExceed: [
      "Everything in Lite plus",
      "30 min video export length ",
      "Green screen removal",
      "5 GB upload storage limit",
      "Auto Subtitles 45 mins/month",
      "10 video exports per month",
      "Export in 1080p resolution",
    ],
    resolutionExceed: [
      "Everything in Lite plus",
      "Export in 1080p resolution",
      "30 min video export length ",
      "Green screen removal",
      "5 GB upload storage limit",
      "Auto Subtitles 45 mins/month",
      "10 video exports per month",
    ]
  },
  [PLAN.TEAM]: {
    default: [
      "Everything in Starter plus",
      "Remove video background (15 mins/month)",
      "30 GB upload storage limit",
      "Auto Subtitles 120 mins/month",
      "Export in 2k resolution",
      "30 Video exports per month",
    ],
    durationExceed: [
      "Everything in Starter plus",
      "45 min video export length",
      "Video background Removal 15 mins/month",
      "30 GB upload storage limit",
      "Auto Subtitle 90 min/month",
      "30 video exports per month",
      "Export in 2k resolution"
    ],
    resolutionExceed: [
      "Everything in Starter plus",
      "Export in 2k resolution",
      "45 min video export length",
      "Video background Removal 15 mins/month",
      "30 GB upload storage limit",
      "Auto Subtitle 90 min/month",
      "30 video exports per month",
    ]
  },
  [PLAN.ENTERPRISE]: {
    default: [
      "Everything in Teams plus",
      "Custom credits for video background removal",
      "50+ GB upload storage limit",
      "Custom credits for auto-subtitle and translation",
      "Priority support",
      "Unlimited video exports"
    ],
    durationExceed: [
      "Everything in Teams plus",
      "Custom credits for video background removal",
      "50+ GB upload storage limit",
      "Custom credits for auto-subtitle and translation",
      "Priority support",
      "Unlimited video exports"
    ],
    resolutionExceed: [
      "Everything in Teams plus",
      "Custom credits for video background removal",
      "50+ GB upload storage limit",
      "Custom credits for auto-subtitle and translation",
      "Priority support",
      "Unlimited video exports"
    ]
  }
};

export const PLAN_TYPES = [
  {
    icon: "starter-icon.svg",
    name: "Starter",
    plan: PLAN.STARTER,
    titleSubtext: "If you are just getting started",
    action: "Upgrade to Starter",
    actionBgColor: "#00BAFF",
  },
  {
    icon: "teams-icon.svg",
    name: "Teams",
    plan: PLAN.TEAM,
    titleSubtext: "The Pros and the ones to be",
    action: "Upgrade to Teams",
    actionBgColor: "#00BAFF",
  },
  {
    icon: "enterprise-icon.svg",
    name: "Enterprise Plan",
    plan: PLAN.ENTERPRISE,
    titleSubtext: "",
    action: "Contact Us",
    description:
      "Go beyond the standard with larger pool of credits and custom features.",
    actionBgColor: "#00BAFF",
  },
];

export const APP_INTEGRATION = [
  {
    name: PANEL.PEXELS,
    title: content.PEXELS,
    src: "vm-pexels.svg",
    content: "Import pictures from Pexels",
  },
  {
    name: PANEL.PIXABAY,
    src: "vm-pixabay.svg",
    content: "Import pictures from Pixabay",
    title: content.PIXABAY,
  },
  {
    name: PANEL.PEXELS_VIDEO,
    src: "vm-pexels.svg",
    content: "Import videos from Pexels",
    title: content.PEXELS_VIDEO,
  },
  {
    name: PANEL.PIXABAY_VIDEO,
    src: "vm-pixabay.svg",
    content: "Import videos from Pixabay",
    title: content.PIXABAY_VIDEO,
  },
  {
    name: PANEL.UNSPLASH,
    src: "vm-unsplash.svg",
    content: "Import pictures from Unsplash",
    title: content.UNSPLASH,
  },
  {
    name: PANEL.GIPHY,
    src: "giphy.svg",
    content: "Add gifs from giphy to designs",
    title: content.GIPHY,
  },
  {
    name: PANEL.FAVOURITE,
    src: `left-menu-fav.svg`,
    title: content.FAVORITES,
    content: "Selected favourites display in one place"
  }
];

export const FAVOURITES_LIMIT = {
  PROPERTY: 8,
  MUSIC: 4,
  IMAGE: 4,
  VIDEO: 4
};

export const ONBOARDING_CONTENT = {
  ANYOTHER_PLEASE_SPECIFY: "Any other (please specify)",
  PERSONAL: "Personal use",
  PROFESSIONAL: "Professional use"
};

export const PLAN_PRISING_RES = {
  "starter": {
    "monthly": 24,
    "yearly": 216
  },
  "team": {
    "monthly": 39,
    "yearly": 300
  }
};

export const BUSSINESS_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "qq.com",
  "hotmail.com",
  "icloud.com",
  "comcast.net",
  "yopmail.net",
  "live.com",
  "freemail.com",
  "me.com",
  "ymail.com",
  "outlook.com",
  "rediffmail.com",
  "yopmail.com",
  "GMAIL.com",
  "GAMIL.com",
  "gamil.com",
  "googlemail.com",
  "GoogleMail.com",
  "Gmail.com",
  "googleMail.com",
  "GOogleMail.com",
  "GMAIl.com",
  "g00glemail.com",
  "gmail.c0m",
  "gmail.net",
  "gmail.org",
  "GMAIL.net",
  "googlmail.org"
];

export const SOCIAL_MEDIA_OPTIONS = [
  { icon: "delete-icon.svg", name: "Delete", actionName: "DELETE" },
];

export const queuePhaseMessages = [
  "Connecting to server...",
  "Initiating project export...",
  "Adding your request to the export..",
  "Request successfully added...",
  "....",
  "Project added to queue...",
  "Polling machines to render this project..",
  "....",
  "Assigning machine..",
  "This could take a few minutes..."
];

export const phase1Messages = [
  "Connecting to server...",
  "Phase 1",
  "Gathering project information",
  "....",
  "Downloading project frame information",
  "....",
  "Verifying uploads",
  "Render machine found and assigned",
  "....",
  "Stack files sent to render machine",
  "Submission successful",
  "....",
  "Staging assets, videos and properties",
  "Integrating high-quality assets to your video render..",
  "Assembling layers..",
  "Processing all the layers parallelly..",
  "Merging all the layers together..",
  "Stream #0:0 -> #0:0 (h264 (native) -> png (native))...",
  "Auto tuning project for better compression output",
  "exportId: $exportId",
  "format: mp4",
  `projectWidth:`,
  `projectHeight: `,
  "Loading next steps.."
];

export const phase2Updates = [
  "Connecting to server...",
  "Initiating Phase 2..",
  "....",
  "Initiating H.264 video compression..",
  "....",
  "Checking project for audio files.",
  "Audio files found...",
  "....",
  "Stream #0:0(eng): Video: png, rgb24, libx265, [SAR 1:1 DAR 16:9], q=2-31, 200 kb/s, 23.98 fps, 23.98 tbn, 23.98 tbc (default)",
  "Stream mapping:",
  "Map successful...",
  "....",
  "Download_source_audio",
  "Input #0, audio,",
  "Duration: 00:00:00.04, start: 0.000000, bitrate: 1038878 kb/s",
  "Stream#0:0: Video: mjpeg (Baseline), yuvj444p(pc, bt470bg/unknown/unknown), 4948x3299 [SAR 300:300 DAR 4948:3299], 25 tbr, 25 tbn, 25 tbc",
  "Stream mapping:",
  "Stream #0:0 -> #0:0 (mjpeg (native) -> mjpeg (native))"
];

export const phase3Updates = [
  "Connecting to server...",
  "Phase 3",
  "Upload started",
  "....",
  "Validating Export id",
  "....",
  "Validation successful",
  "....",
  "Initiating streaming files...",
  "generating streaming files with different streaming versions...",
  "....",
  "Generating successful...",
  "Creating download link..."
];

export const FRAMES = [
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M60.74,30.37v.22a31.35,31.35,0,0,1-.64,6,30.23,30.23,0,0,1-2,6.06l-.19.42a3.56,3.56,0,0,1-.64,1.31,28.93,28.93,0,0,1-1.49,2.53l-.27.44a30.39,30.39,0,0,1-3.29,4,29.47,29.47,0,0,1-2.15,2c-.5.43-1,.85-1.54,1.25s-1.21.86-1.83,1.26a30.38,30.38,0,0,1-31.32.86l-1-.57-.87-.56a29.67,29.67,0,0,1-3.85-3c-.51-.47-1-1-1.48-1.47L8,50.86c-.49-.53-1-1.09-1.41-1.67-.24-.29-.47-.58-.69-.89a29.19,29.19,0,0,1-1.78-2.74l0,0c-.15-.25-.29-.5-.43-.75l0-.05a29.54,29.54,0,0,1-1.42-3.07A30.43,30.43,0,0,1,2.63,18,30.6,30.6,0,0,1,12.21,6a31.12,31.12,0,0,1,7-3.89,30.37,30.37,0,0,1,41.55,28Z",
        "clipWidth": 60.742,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 60.742
      }
    },
    "thumbnail": "frame-0003.png",
    "d": [],
    "asset_type": 0,
    "width": 60.742,
    "id": "frame-0003",
    "type": "FRAME",
    "height": 60.742,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M75,59.51h0V75H0V57.83H0V57.7H0V55.55H0V0H75V59.49Z",
        "clipWidth": 75,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 75
      }
    },
    "thumbnail": "frame-0001.png",
    "d": [],
    "asset_type": 0,
    "width": 75,
    "id": "frame-0001",
    "type": "FRAME",
    "height": 75,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M10.93,0H64.07A10.93,10.93,0,0,1,75,10.93V64.07A10.93,10.93,0,0,1,64.07,75H10.93A10.93,10.93,0,0,1,0,64.07V10.93A10.93,10.93,0,0,1,10.93,0Z",
        "clipWidth": 75,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 75
      }
    },
    "thumbnail": "frame-0002.png",
    "d": [],
    "asset_type": 0,
    "width": 75,
    "id": "frame-0002",
    "type": "FRAME",
    "height": 75,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M62,53.65H0L1,52l.75-1.3L3,48.46l2.21-3.83,1.1-1.9.09-.17,1.72-3L8,39.54l.1,0,.22-.39,0,0,0,0,4.92-8.53L31,0,45.4,25l6.17,10.67.26.46.22.38,1.31,2.27L55.78,43l2.88,5Z",
        "clipWidth": 61.9531,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 53.654
      }
    },
    "thumbnail": "frame-0004.png",
    "d": [],
    "asset_type": 0,
    "width": 61.9531,
    "id": "frame-0004",
    "type": "FRAME",
    "height": 53.654,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M37.5,0,49.86,22.42,75,27.25,57.5,45.93l3.18,25.4L37.5,60.46,14.32,71.33l3.18-25.4L0,27.25l25.14-4.83L37.5,0",
        "clipWidth": 75,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 71.33
      }
    },
    "thumbnail": "frame-0013.png",
    "d": [],
    "asset_type": 0,
    "width": 75,
    "id": "frame-0013",
    "type": "FRAME",
    "height": 71.33,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M66.2,24.16l-1.87,5.67-.06.18-.23.71-.05.14-3.24,9.86,0,.09L60.34,42l0,.07L58.46,47.7l-1.29,3.92L55,58.3,53.43,63l-40.92-.13-1.6-5L9,51.89l-.74-2.31L8.05,49,6.78,45v0L5.69,41.66v0L2.39,31.38,0,23.94,33.18,0,62,21.1Z",
        "clipWidth": 66.2,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 63.02
      }
    },
    "thumbnail": "frame-0007.png",
    "d": [],
    "asset_type": 0,
    "width": 66.2,
    "id": "frame-0007",
    "type": "FRAME",
    "height": 63.02,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M65.31,25.55c0,.25-.12.5-.18.74a33.11,33.11,0,0,1-1.92,5.46,1.42,1.42,0,0,0-.08.18c-.13.29-.26.58-.4.86l-.07.16A44.64,44.64,0,0,1,60,37.57c-.34.51-.69,1-1,1.54q-.72,1-1.5,2c-.1.13-.2.27-.3.39q-1.44,1.83-3,3.59a91.14,91.14,0,0,1-6.45,6.44c-2.26,2-4.61,4-7,5.86-2.61,2-5.22,4-7.72,5.75-2.23-1.58-4.54-3.27-6.85-5.07-2.54-2-5.07-4.06-7.52-6.26-1.7-1.53-3.35-3.12-4.93-4.75a2.2,2.2,0,0,0-.47-.49h0a63.07,63.07,0,0,1-7.57-9.55l0,0c-.28-.45-.55-.89-.81-1.34h0c-.51-.86-1-1.73-1.42-2.61l-.07-.12-.18-.4a1.11,1.11,0,0,1-.07-.16L3,32.11A1.17,1.17,0,0,0,2.9,32,32,32,0,0,1,.37,23.93,26.72,26.72,0,0,1,2,9.4c6.84-12.51,23.09-12,31-1.54,7.91-10.49,24.17-11,31,1.54a29.3,29.3,0,0,1,1.28,4A26.53,26.53,0,0,1,65.31,25.55Z",
        "clipWidth": 66,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 63.18
      }
    },
    "thumbnail": "frame-0012.png",
    "d": [],
    "asset_type": 0,
    "width": 66,
    "id": "frame-0012",
    "type": "FRAME",
    "height": 63.18,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M65.52,56.16H33.84l2.27-3.93,1.42-2.46.91-1.57.61-1.07,3.09-5.35,2.4-4.16,1.62-2.79.64-1.11,2.88-5,9.2,16,1,1.76L61,48.29l.18.31h0l.06.1.31.53s.07,0,.09-.06v.2L62,50l.64,1.11,1.73,3,1.16,2ZM32.73,0,16.89,27.43H48.57ZM15.84,28.73l-2.88,5L9.84,39.12l-.24.42-2.29,4L7,44l-.17.31-.41.7L5,47.54l-.12.2-.1.17L4.69,48,3.56,50,1.1,54.26,0,56.16H31.68l-2.22-3.84ZM37.65,47.32l.68-1.17,1-1.8.09-.16,0-.09.14-.24,2.13-3.68,4.64-8,2.15-3.71H16.89L30.68,52.31l.32.56.23.39,1.5,2.6,2.07-3.6Z",
        "clipWidth": 65.52,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 56.16
      }
    },
    "thumbnail": "frame-0006.png",
    "d": [],
    "asset_type": 0,
    "width": 65.52,
    "id": "frame-0006",
    "type": "FRAME",
    "height": 56.16,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M56.63,24.35,50.79,42.11l-.35,1.06-1,3L48.53,49l-.48,1.45A6,6,0,0,1,46.36,53a6.49,6.49,0,0,1-4.28,1.79L14.6,54.67A6.22,6.22,0,0,1,11,53.43a6.77,6.77,0,0,1-1.22-1.11,5.89,5.89,0,0,1-1-1.52,4.52,4.52,0,0,1-.18-.47L7.52,46.79l-.28-.87-.06-.18-.74-2.31-.17-.53-1-3.26h0L4.3,36.75v0l-2.83-8.8L.25,24.17a6.7,6.7,0,0,1,2.3-7l4.62-3.33a2.38,2.38,0,0,0,.6-.44L24.83,1.09a6.71,6.71,0,0,1,7.37,0L54.38,17.34a6.64,6.64,0,0,1,2.42,4.19A5.93,5.93,0,0,1,56.63,24.35Z",
        "clipWidth": 56.89,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 54.76
      }
    },
    "thumbnail": "frame-0008.png",
    "d": [],
    "asset_type": 0,
    "width": 56.89,
    "id": "frame-0008",
    "type": "FRAME",
    "height": 54.76,
    "name_en": "Frame",
    "is_fav": false
  },
  {
    "clipDetails": {
      "clip_1": {
        "imgDetails": {},
        "clipData": "M49.27,42.52v.06l-.71.42-2.41,1.39-1.6.93-3.39,2-7.95,4.6-8.52,5-7.31-4.21L8.3,47.41,5.87,46l-.25-.14-.35-.21-.44-.25L1.08,43.26l-.26-.15-.77-.44,0-9.18V25.94L0,14.25,24.59,0,49.22,14.17v3.5l0,18.52v6.33Z",
        "clipWidth": 49.27,
        "clipY": 0,
        "clipX": 0,
        "clipHeight": 56.84
      }
    },
    "thumbnail": "frame-0009.png",
    "d": [],
    "asset_type": 0,
    "width": 49.27,
    "id": "frame-0009",
    "type": "FRAME",
    "height": 56.84,
    "name_en": "Frame",
    "is_fav": false
  }
];

export const CIRCLE_FRAME = {
  "clipDetails": {
    "clip_1": {
      "imgDetails": {},
      "clipData": "M60.74,30.37v.22a31.35,31.35,0,0,1-.64,6,30.23,30.23,0,0,1-2,6.06l-.19.42a3.56,3.56,0,0,1-.64,1.31,28.93,28.93,0,0,1-1.49,2.53l-.27.44a30.39,30.39,0,0,1-3.29,4,29.47,29.47,0,0,1-2.15,2c-.5.43-1,.85-1.54,1.25s-1.21.86-1.83,1.26a30.38,30.38,0,0,1-31.32.86l-1-.57-.87-.56a29.67,29.67,0,0,1-3.85-3c-.51-.47-1-1-1.48-1.47L8,50.86c-.49-.53-1-1.09-1.41-1.67-.24-.29-.47-.58-.69-.89a29.19,29.19,0,0,1-1.78-2.74l0,0c-.15-.25-.29-.5-.43-.75l0-.05a29.54,29.54,0,0,1-1.42-3.07A30.43,30.43,0,0,1,2.63,18,30.6,30.6,0,0,1,12.21,6a31.12,31.12,0,0,1,7-3.89,30.37,30.37,0,0,1,41.55,28Z",
      "clipWidth": 60.742,
      "clipY": 0,
      "clipX": 0,
      "clipHeight": 60.742
    }
  },
  "thumbnail": "frame-0003.png",
  "d": [],
  "asset_type": 0,
  "width": 60.742,
  "id": "frame-0003",
  "type": "FRAME",
  "height": 60.742,
  "name_en": "Frame",
  "is_fav": false
};

export const DEFAULT_FRAME_BG = "#C1C1C1";