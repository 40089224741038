import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const LoadingBars = keyframes`
  0% {
    background-color: rgb(237, 242, 244);
    opacity: 1;
  }
  50% {
    background-color: rgb(240, 246, 248);
    opacity: 0.5;
  }
  100% {
    background-color: rgb(237, 242, 244);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 28px 12px;
  margin-top: 20px;
  margin: ${(props) => (props.margin ? "20px 24px 0px" : "20px 0px 0px")};
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
`;

const Item = styled.div`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: relative;
  max-width: 22%;
  width: 100%;
  height: 50px;
  margin: 4px 10px;
  @media screen and (min-width: 768px) and (max-width:992px){
    max-width: 28%;
    height: 50px;
  }
  @media screen and (max-width:768px){
    max-width: 41%;
  }
`;

const RoundBox = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  animation: 1s ease-out 0s infinite normal none running ${LoadingBars};
`;

const ListElement = styled.div`
  width: 22%;
  height: 155px;
  border-radius: 8px;
  margin: 4px 10px;
  animation: 1s ease-out 0s infinite normal none running ${LoadingBars};
  @media screen and (min-width: 768px) and (max-width:992px){
    width: 28%;
    height: 150px;
  }
  @media screen and (max-width:768px){
    width: 41%;
  }
`;

const Rect = styled.div`
  width: ${(props) => props.width || "70%"};
  float: left;
  top: ${(props) => props.top || "18px"};
  left: ${(props) => props.left || "50px"};
  height: ${(props) => props.height || "12px"};
  position: absolute;
  animation: 1s ease-out 0s infinite normal none running ${LoadingBars};
  border-radius: 50px;
`;

const createItems = (count) => {
    return Array.from({ length: count }, (_, index) => (
        <Item key={index}>
            <RoundBox />
            <Rect className="rect" />
        </Item>
    ));
};

const Items = (count) => {
    return Array.from({ length: count }, (_, index) => (
        <ListElement key={index} />
    ));
};

const FolderPlaceholder = ({ margin, folderCount, fileCount, className }) => (
    <div className={className}>
        <Wrapper margin={margin}>{createItems(folderCount || 8)}</Wrapper>
        <Wrapper margin={margin}>{Items(fileCount || 12)}</Wrapper>
    </div>
);

FolderPlaceholder.propTypes = {
    folderCount: PropTypes.string,
    fileCount: PropTypes.string,
    margin: PropTypes.bool,
    className: PropTypes.string
};

export default FolderPlaceholder;