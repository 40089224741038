/* eslint-disable no-continue */
import React, { Component, createRef } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { addCacheClearQuery } from '../../../helper/addCacheClearQuery';
import { getAudioSource } from '../../../helper/URLHelper';
import { STATIC_PATH } from '../../../constants/config';
import { ProgressBar } from "../../../common-components/MusicTile";
import vmTheme from '../../../constants/theme';
import { Icon, IconSVG } from '../timeline-components';

export class AudioPlayer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      source: "",
      duration: 0,
      percentage: 0,
      isPlaying: false
    }
    this.AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = null;
    this.audioRef = createRef(null);
    /** @type {Object.<string, { gainNode: GainNode, srcNode: MediaElementAudioSourceNode }>} */
    this.audioNodes = {};
    this.playPauseAudio = this.playPauseAudio.bind(this);
    this.initializeAudioContext = this.initializeAudioContext.bind(this);
    this.timeUpdateHandler = this.timeUpdateHandler.bind(this);
    this.pauseAudio = this.pauseAudio.bind(this);
  }

  componentDidMount() {
    const { audioData } = this.props;
    this.initializeAudioContext();
    const { src } = getAudioSource({
      item: {
        type: audioData.get("type"),
        subType: audioData.get("subType"),
        src: audioData.get("src"),
      },
    });
    this.setState({ source: src, duration: audioData.get("musicEnd") - audioData.get("musicStart") });
  }

  componentDidUpdate(prevProp) {
    if (this.props.isPlay !== prevProp.isPlay && this.props.isPlay && !this.state.isPlaying) {
      this.playPauseAudio();
    }
  }

  componentWillUnmount() {
    this.cleanupAudioContext();
  }

  /**
  * Event to get the current time of the audio
  * @param {Event} e
  */
  timeUpdateHandler = (e) => {
    const { audioData } = this.props;
    if (e.target.currentTime) {
      const { currentTime } = e.target;
      const musicStart = audioData.get("musicStart") || 0;
      const duration = parseFloat(this.state.duration);
      const percentage = ((currentTime - musicStart) / duration) * 100;
      // To avoid percentage overflow
      this.setState({ percentage: percentage > 100 ? 100 : percentage });
    }
    if (
      this.state.percentage >= 100 ||
      audioData.get("musicEnd").toFixed(1) === this.audioRef.current.currentTime.toFixed(1)
    ) {
      this.pauseAudio();
    }
  };

  cleanupAudioContext() {
    if (this.audioContext) {
      this.audioContext.close();
    }
    this.audioNodes = {};
  }

  initializeAudioContext() {
    this.audioContext = new this.AudioContext();
    try {
      const audioCtx = this.audioContext;
      const audio = this.audioRef.current;

      if (!audio || !audioCtx) {
        // this shouldn't happen but just in case
        return;
      }

      const srcNode = audioCtx.createMediaElementSource(this.audioRef.current);
      const gainNode = audioCtx.createGain();
      srcNode.connect(gainNode);
      gainNode.connect(audioCtx.destination);

      this.audioNodes[this.props.audioData.get("id")] = { srcNode, gainNode };
    } catch (error) {
      // if an error is catched here, probably there is an issue in handling audio context
    }
  }

  playPauseAudio() {
    try {
      const { audioData, globalVolume } = this.props;
      const playhead = 0;
      const audioId = audioData.get("id");
      const audio = this.audioRef.current;
      const audioNode = this.audioNodes[audioId];
      const audioCtx = this.audioContext;

      if (!audioNode || !audio || !audioCtx || !audioData) {
        return;
      }

      const playStart = 0;
      const playEnd = audioData.get("playEnd") - audioData.get("playStart");

      const { srcNode } = audioNode;
      let { gainNode } = audioNode;

      // disconnect old gain node to cleanup old scheduled fade
      gainNode.disconnect(audioCtx.destination);
      srcNode.disconnect(gainNode);

      // create new node to schedule fade
      gainNode = audioCtx.createGain();
      srcNode.connect(gainNode);
      gainNode.connect(audioCtx.destination);
      audioNode.gainNode = gainNode;

      if (playhead >= playStart && playhead < playEnd) {
        const ctxTime = audioCtx.currentTime;
        const defaultAmplitude = audioData.get("defaultAmplitude")
          ? audioData.get("defaultAmplitude")
          : 1;
        const fadeDetails = audioData.get("fadeDetails");
        const musicStart = parseFloat(audioData.get("musicStart"));
        const currentTime = musicStart;

        if (fadeDetails) {
          let addedFadeIdx = 0;
          for (let fadeIdx = 0; fadeIdx < fadeDetails.size; fadeIdx += 1) {
            const fadeDetail = fadeDetails.get(fadeIdx);
            const time = parseFloat(fadeDetail.get("t"));
            const amplitude =
              parseFloat(fadeDetail.get("a")) * globalVolume;

            const fadeTime = playStart + (time - musicStart);
            if (
              fadeTime < playStart ||
              fadeTime > playEnd ||
              fadeTime < playhead
            ) {
              continue;
            }

            const localTime = fadeTime - playhead;
            const scheduleTime = ctxTime + localTime;

            if (scheduleTime < 0) {
              continue;
            }

            if (addedFadeIdx === 0) {
              gainNode.gain.setValueAtTime(amplitude, 0);
              if (ctxTime !== 0) {
                gainNode.gain.setValueAtTime(amplitude, scheduleTime);
              }
            } else {
              const prevFadeDetail = fadeDetails.get(fadeIdx - 1);
              const prevAmplitude =
                parseFloat(prevFadeDetail.get("a")) * globalVolume;
              if (amplitude === prevAmplitude) {
                gainNode.gain.setValueAtTime(amplitude, scheduleTime);
              } else {
                gainNode.gain.linearRampToValueAtTime(amplitude, scheduleTime);
              }
            }

            addedFadeIdx += 1;
          }

          if (addedFadeIdx === 0 && fadeDetails.size !== 0) {
            const fadeDetail = fadeDetails.get(fadeDetails.size - 1);
            const amplitude = parseFloat(fadeDetail.get("a")) * globalVolume;
            gainNode.gain.setValueAtTime(amplitude, 0);
          }
          if (fadeDetails.size === 0) {
            gainNode.gain.setValueAtTime(defaultAmplitude, 0);
          }
        } else {
          audio.volume = audioData.get("volume") * globalVolume;
        }

        audio.currentTime = currentTime;
      }
      this.audioRef.current.play().catch(() => { });
      this.setState({ isPlaying: true });
    } catch (error) {
      console.error(error);
      // errors happening here might be fatal
      // example: audio volume is increased in first loop and second loop failed to decrease it
      // this scenario will not happen in most cases as we pause audio before applying gain, but just in case...
    }
  }

  pauseAudio() {
    const { audioData, isPlay, onAudioStop } = this.props;
    this.audioRef.current.pause();
    this.audioRef.current.currentTime = audioData.get("musicStart");
    if (isPlay) {
      onAudioStop();
    }
    this.setState({
      percentage: 0,
      isPlaying: false
    });
  }

  render() {
    const { isMute, theme } = this.props;
    return (
      <>
        {isMute ? (
          <IconSVG>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_187907"
                    data-name="Rectangle 187907"
                    width="20"
                    height="20"
                    fill="none"
                  />
                </clipPath>
              </defs>
              <g id="volume_full_player_white" transform="translate(-266 -504)">
                <rect
                  id="Rectangle_17903"
                  data-name="Rectangle 17903"
                  width="24"
                  height="24"
                  transform="translate(266 504)"
                  fill="#fff"
                  opacity="0"
                />
                <g
                  id="Volume-01"
                  transform="translate(268 506)"
                  clipPath="url(#clip-path)"
                >
                  <path
                    id="Subtraction_16"
                    data-name="Subtraction 16"
                    d="M8.771,15.611a1.039,1.039,0,0,1-.743-.315L4.605,11.884l5.21-5.21v7.871a1.083,1.083,0,0,1-.659.988A1.129,1.129,0,0,1,8.771,15.611ZM13.158,14a.624.624,0,0,1-.391-1.112l.015-.014c.014-.011.036-.029.066-.056.088-.079.17-.159.251-.243a6.945,6.945,0,0,0,.787-.987,6.973,6.973,0,0,0,0-7.667,7.128,7.128,0,0,0-.565-.75l.886-.886a8.111,8.111,0,0,1,.724.95,8.224,8.224,0,0,1,0,9.041A8.089,8.089,0,0,1,14,13.442c-.142.146-.25.247-.316.306-.04.035-.071.063-.095.082l-.04.034A.63.63,0,0,1,13.158,14Zm-1.292-2.525a.543.543,0,0,1-.446-.208.626.626,0,0,1,.094-.875l.013-.013.011-.01c.026-.025.061-.059.105-.107A2.844,2.844,0,0,0,12,9.785a3.874,3.874,0,0,0,.535-2.036A3.865,3.865,0,0,0,12,5.714a2.856,2.856,0,0,0-.354-.475c-.052-.056-.089-.09-.106-.105a.165.165,0,0,0-.023-.021.705.705,0,0,1-.072-.068l.884-.884.023.021.032.03c.05.045.113.108.177.177a4.234,4.234,0,0,1,.507.675,5.135,5.135,0,0,1,.716,2.685,5.135,5.135,0,0,1-.716,2.685,4.255,4.255,0,0,1-.5.677c-.068.072-.129.133-.178.177-.02.02-.041.038-.061.055l-.013.011-.012.009A1.009,1.009,0,0,1,11.867,11.474Zm-8.454-.165H1.065A1.066,1.066,0,0,1,0,10.244v-4.9A1.06,1.06,0,0,1,1.065,4.3H4.028L7.994.316A1.079,1.079,0,0,1,8.754,0,1.059,1.059,0,0,1,9.815,1.067v3.84l-6.4,6.4Zm9.123-9.123v0a.624.624,0,0,1,.622-.685q.031,0,.062,0l-.682.682Z"
                    transform="translate(2.469 2.25)"
                    fill="#4d4b5c"
                  />
                  <rect
                    id="Rectangle_187906"
                    data-name="Rectangle 187906"
                    width="1.25"
                    height="22.5"
                    rx="0.625"
                    transform="translate(17.132 0.629) rotate(45)"
                    fill="#4d4b5c"
                  />
                </g>
              </g>
            </svg>
          </IconSVG>
        ) : this.state.isPlaying ? (
          <Icon
            draggable="false"
            src={`${STATIC_PATH}stop-audio.svg`}
            alt="play-audio"
            onClick={this.pauseAudio}
          />
        ) : (
          <Icon
            draggable="false"
            src={`${STATIC_PATH}audio-play.svg`}
            alt="play-audio"
            onClick={this.playPauseAudio}
          />
        )}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          ref={this.audioRef}
          src={addCacheClearQuery(this.state.source)}
          onTimeUpdate={this.timeUpdateHandler}
          muted={isMute}
          crossOrigin="anonymous"
        />
        {this.state.isPlaying && (
          <ProgressBar
            className="progress"
            width={`${this.state.percentage}%`}
            opacity={1}
            color={vmTheme[theme].rgbaPurpleColor}
            borderRight={`2px solid ${vmTheme[theme].svgIconPurple}`}
          />
        )}
      </>
    )
  }
}

AudioPlayer.propTypes = {
  isPlay: PropTypes.bool,
  isMute: PropTypes.bool,
  onAudioStop: PropTypes.func,
  theme: PropTypes.string,
  audioData: PropTypes.object,
  globalVolume: PropTypes.number
};

const mapStateToProps = ({ app, projectDetails }, ownProps) => {
  return {
    theme: app.get("theme"),
    globalVolume: app.get("globalVolume"),
    audioData: projectDetails.getIn(["audios", ownProps.sliderId]),
  };
};

export default connect(mapStateToProps)(AudioPlayer);
