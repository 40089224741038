import styled, { css, keyframes } from "styled-components";
import { font } from "../../constants/font";
import { APP_Z_INDICES } from "../../constants";
import { STATIC_PATH } from "../../constants/config";

export const HeaderComponent = styled.div.attrs((props) => {
  return {
    style: {
      zIndex: props.$isPlayAll
        ? APP_Z_INDICES.header.onPlay
        : APP_Z_INDICES.header.default,
    },
  };
})`
  width: 100%;
  height: 48px;
  background: transparent ${(props) => props.theme.headerColor} 0% 0% no-repeat
    padding-box;
  opacity: 1;
  display: grid;
  grid-template-columns: ${(props) =>
    props.isShorts ? "66px 1fr 100px" : "66px 1fr 100px 1fr"};
  align-items: center;
  position: relative;
  z-index: ${APP_Z_INDICES.header.default};
  @media (max-width: 768px) {
    grid-template-columns: auto 1fr auto;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  .upgrade--btn {
    margin-right: 16px;
    &:hover {
      background: ${(props) => props.theme.rgbaWhite24};
      transform: scale(1.02);
      opacity: 0.8;
    }
    label {
      position: relative;
      padding-left: 20px;
      &::before {
        content: url(${STATIC_PATH}vm-star.svg);
        position: absolute;
        left: 0px;
        top: 2px;
      }
    }
    @media (max-width: 768px) {
      display:none;
    }
  }
  &.header-last {
    justify-self: right;
    label {
    }
    @media (max-width: 768px) {
      ul {
        width: auto;
        padding: 0px;
        li {
          padding: 0px 18px 0px 0px;
        }
      }
      svg {
        width: auto;
        height: auto;
        padding: 0px 18px 0px 0px;
        cursor: pointer;
        transition: all 0.4s ease 0s;
        &:hover {
          transform: scale(1.4);
        }
      }
    }
  }
`;

export const ImageContainer = styled.div`
  border-right: 1px solid ${(props) => props.theme.headerImgBorderColorLight};
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 40px;
  }
  @media (max-width: 768px) {
    padding: 0px 5px;
    border-right: 0px;
  }
`;

export const Img = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  &.is-disabled {
    pointer-events: none;
  }
`;

export const Button = styled.div`
  font-family: "VM-Poppins";
  padding: 15px 16px 14px 16px;
  text-align: left;
  font: ${font.normalMini_13};
  letter-spacing: 0px;
  color: ${(props) => props.theme.polarColor};
  opacity: 1;
  cursor: pointer;
  @media (max-width: 768px) {
    font: ${font.normalMini_14};
    border-left: 1px solid rgba(255, 255, 255, 0.16);
    border-right-color: rgba(255, 255, 255, 0.16);
  }
`;

export const TitleInput = styled.input`
  width: 190px;
  height: 30px;
  margin-left: 16px;
  text-align: left;
  font: ${font.boldDefault_23};
  letter-spacing: 0px;
  color: ${(props) => props.theme.polarColor};
  background: transparent;
  border: none;
  opacity: 1;
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.polarColor};
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const IconContainer = styled.ul`
  width: fit-content;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  padding: 0px 29px;
  list-style: none;
  margin: 0;
  position: relative;
  ${(props) =>
    props.border &&
    css`
      ${props.border}
    `}
  cursor: pointer;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      transition: all 0.4s;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
  @media (max-width: 768px) {
    border-right: 0px;
    border-left: 0px;
    margin: 0px auto;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0px;
    height: 200%;
    width: 1px;
    background-color: ${(props) => props.theme.rgbaBlack16};
    @media (max-width: 768px) {
      content: unset;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    height: 200%;
    width: 1px;
    background-color: ${(props) => props.theme.rgbaBlack16};
    @media (max-width: 768px) {
      content: unset;
    }
  }
`;
export const PreviewImage = styled.img`
  width: 243px;
  height: 136px;
`;

export const Icon = styled.img`
  height: ${(props) => props.height || "24px"};
  width: ${(props) => props.width || "24px"};
  cursor: pointer;
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "auto"};
  transition: all 0.3s;
  &:hover {
    transform: scale(1.01);
  }
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "auto"};
`;

export const PlayIcon = styled(Icon)`
  transition: all 0.3s;
  z-index: ${APP_Z_INDICES.headerPreviewButton.default};
`;
PlayIcon.displayName = "PlayIcon";

export const ModalContainer = styled.div.attrs((props) => ({
  height: props.isShowModal ? (props.isShorts ? "100%" : "440px") : "0px",
  overflow: props.isShowModal ? "unset" : "hidden",
  left: props.isShorts ? "0" : "unset",
  display: props.isShorts ? "flex" : "unset",
  justifyContent: props.isShorts ? "center" : "unset",
  alignItems: props.isShorts ? "center" : "unset",
  top: props.isShorts ? "0px" : "43px",
}))`
  width: fill-available;
  z-index: 15;
  position: absolute;
  height: ${(props) => props.height};
  overflow: ${(props) => props.overflow};
  left: ${(props) => props.left};
  display: ${(props) => props.display};
  top: ${(props) => props.top};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  .previewImage {
    width: 243px;
    height: 136px;
    object-fit: contain;
    margin: 10px auto 15px;
    border-radius: 6px;
    display: block;
    cursor: pointer;
  }
  .premiumHeading {
    display: block;
    position: relative;
    padding-bottom: 4px;
  }
  .premiumContent {
    white-space: break-spaces;
    display: block;
  }
`;

export const Modal = styled.div`
  position: relative;
  margin-right: 8px;
  padding: 16px;
  width: 298px;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 26px ${(props) => props.theme.exportBoxShadow};
  border: 1px solid ${(props) => props.theme.veryLightGray};
  border-radius: 12px;
  float: right;
`;

export const ModalBody = styled.div`
  max-height: 330px;
  overflow: hidden;
  overflow-y: auto;
  padding: ${(props) => props.padding || "10px 16px"};
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    max-height: 415px;
    touch-action: auto;
  }
`;
export const PremiumText = styled.div`
  background: ${(props) => props.theme.premiumTextColor};
  padding: 10px;
  border-radius: 6px;
  margin: 0px 0 15px;
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.propertyColor};
  font: ${font.boldSmall};
  padding: ${(props) =>
    props.isShorts ? "24px 10px 10px 24px" : "16px 16px 10px"};
  img {
    /* width: 14px;
    height: 14px; */
    position: relative;
    bottom: ${(props) => (props.isShorts ? "17px" : "0px")};
    cursor: pointer;
  }
`;

export const Resolution = styled.div`
  height: 84px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};
`;

export const Thumbnail = styled.div`
  height: 177px;
  padding: 16px;
  overflow: hidden;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  opacity: 0.97;
`;

export const FileModal = styled.div`
  top: 42px;
  left: 75px;
  position: absolute;
  z-index: 5;
  width: ${(props) => (props.isShorts ? "200px" : "298px")};
  height: auto;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 5px ${(props) => props.theme.rgbaBlack26};
  border: 1px solid ${(props) => props.theme.veryLightGray};
  border-radius: 8px;
  user-select: none;
  transition: height 0.5s;
  @media (max-width: 768px) {
    transition: height 0.5s;
    height: 0px;
    &.file-modal-tool-bar {
      top: auto;
      bottom: 0;
      width: 100%;
      left: 0;
      height: auto;
      border-radius: 20px 20px 0 0;
      box-shadow: 0px -4px 32px ${(props) => props.theme.rgbaBlack};
      padding: 15px 20px 0px;
      max-height: 62vh;
      overflow-y: auto;
      z-index: 9999;
      touch-action: auto;
    }
  }
`;

export const FileContainer = styled.div`
  padding: 16px 24px;
  color: ${(props) => props.theme.primaryColor};
  border-bottom: 1px solid ${(props) => props.theme.solitudeColor};
  align-items: center;
  ${(props) =>
    props.display &&
    css`
      display: ${props.display};
    `}
`;

export const List = styled.ul`
  border-bottom: 1px solid ${(props) => props.theme.solitudeColor};
  padding: 0;
  list-style: none;
  margin: 0px;
  position: relative;
  /* overflow: hidden; */
`;

export const ListItem = styled.li`
  color: ${(props) => props.theme.primaryColor};
  padding: 8px 24.42px;
  cursor: pointer;
  font-size: 15px;
  font-family: "VM-Poppins";
  .resizeDropdown {
    position: relative;
    &:after {
      content: url(${STATIC_PATH}right-arrow.svg);
      position: absolute;
      right: 0px;
      top: 0;
    }
  }
  .resizeMenu {
    display: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.liBgColor};
    transform: scale(1.02);
    .resizeMenu {
      display: flex;
      flex-direction: column;
    }
  }
  a {
    text-decoration: none;
  }
`;

export const ResizeButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export const Profile = styled.img`
  width: 39px;
  height: 39px;
  border-radius: 2px;
`;

export const ProfileDetails = styled.div`
  padding-left: 10px;
`;

export const Close = styled.div`
  border: 1px solid ${(props) => props.theme.closeIconColor};
  width: fit-content;
  margin: 23px auto 0;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    color: ${(props) => props.theme.polarColor};
    background-color: ${(props) => props.theme.closeIconColor};
    label {
      color: ${(props) => props.theme.polarColor};
    }
  }
  label {
    margin: 0;
  }
`;

export const IconSVG = styled.div`
  ${(props) =>
    props.isSelected &&
    css`
      & #resizeContainer {
        fill: ${(props) => props.theme.svgIconLightColor};
        stroke: ${(props) => props.theme.svgIconColor};
      }
      & text {
        fill: ${(props) => props.theme.svgIconColor};
      }
    `}
  &:hover {
    & #resizeContainer {
      fill: ${(props) => props.theme.svgIconLightColor};
      stroke: ${(props) => props.theme.svgIconColor};
    }
    & text {
      fill: ${(props) => props.theme.svgIconColor};
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ResizeDropdown = styled.ul`
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px ${(props) => props.theme.rgbaLightBlack2};
  border: 1px solid ${(props) => props.theme.resizeBorder};
  border-radius: 8px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: -178px;
  top: 0px;
  @media (max-width: 768px) {
    right: 0px;
  }
`;

export const ResizeDropdownLi = styled.li`
  list-style: none;
  padding: 2px 0px 2px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  label {
    color: ${(props) =>
    props.isHovered
      ? props.theme.resizeHoverColor
      : props.theme.primaryColor};
  }
  &:hover {
    background-color: ${(props) => props.theme.btnBgLightColor};
    color: ${(props) => props.theme.resizeHoverColor};
    svg {
      * {
        fill: ${(props) => props.theme.resizeHoverColor};
        stroke: ${(props) => props.theme.resizeHoverColor};
      }
    }
    label {
      color: ${(props) => props.theme.resizeHoverColor};
      background-color: ${(props) => props.theme.btnBgLightColor};
    }
  }
`;

export const ShareOption = styled.div`
  background-color: ${(props) => props.theme.polarColor};
  width: 400px;
  height: auto;
  position: absolute;
  right: ${(props) => (props.isShorts ? "5px" : "150px")};
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 15px;
  border-radius: 8px;
  z-index: 99999;
  .shareInput-section {
    .dropdown {
      margin: 12px 3px;
    }
  }
  .share-heading {
    position: relative;
    margin: 15px 0 25px;
    padding-left: 25px;
    &::before {
      content: url(${STATIC_PATH}share.svg);
      background: ${(props) => props.theme.headerColor};
      border-radius: 4px;
      padding: 5px;
      position: absolute;
      top: 2px;
      left: 40px;
      @media (max-width: 768px) {
        left: 45px;
      }
    }
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px);
  }
  .shared-details {
    display: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    background-color: unset;
    &:hover {
      background-color: unset;
      label {
        font-weight: 500;
      }
    }
    label {
      padding: 16px 0px 3px;
      display: block;
      color: ${(props) => props.theme.propertyColor};
    }
  }
`;

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0 0;
  border-top: 2px solid ${(props) => props.theme.headerWhiteBorder};
  .copy {
    background: ${(props) => props.theme.copyBgColor};
    padding: 5px 14px;
    border-radius: 50px;
    color: ${(props) => props.theme.polarColor};
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const SaveIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  background-image: ${(props) =>
    `url(${STATIC_PATH}${props.isSaving ? "header-saving.svg" : props.theme.icons.headerSave
    })`};
  background-size: cover;
  &:hover {
    background-image: url(${STATIC_PATH}${(props) => props.theme.icons.headerSave});
  }
  ${(props) =>
    props.isSaving &&
    css`
      @keyframes saveAnimation {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
      animation: saveAnimation 1s infinite;
    `}
`;

export const AutoSaveWrap = styled.div`
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DropdownWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 10px;
  cursor: pointer;
`;

export const DropdownHeader = styled.div`
  cursor: pointer;
  label {
    position: relative;
    cursor: pointer;
    &::after {
      content: url(${STATIC_PATH}mini-arrow.svg);
      position: absolute;
      right: -17px;
      top: 2px;
      transform: rotate(180deg);
      ${(props) =>
    props.cardContent &&
    css`
          transform: rotate(0deg);
          top: 4px;
        `}
    }
  }
`;

export const DropdownContent = styled.div`
  height: ${(props) => (props.height ? "0px" : "auto")};
  opacity: ${(props) => (props.height ? "0" : "1")};
  transition: height 1s;
  label {
    font-size: 11.5px;
  }
`;
export const StockUsage = styled.div`
  background-color: ${(props) => props.theme.subtitleActiveBgColor};
  padding: ${(props) => props.padding || "12px"};
  border-radius: 8px;
  margin: ${(props) => props.margin || "0px"};;
  a {
    text-decoration: underline;
  }
  &:empty {
    background-color: transparent;
  }
`;

export const Line = styled.div`
  color: #F5E3B3;
  border: 1px solid #F5E3B3;
  margin: 12px 0px;
`;

export const ExportFooter = styled.div`
  padding: ${(props) => props.footerPadding || "1px 16px 10px"};
  box-shadow: 0px -2px 8px ${(props) => props.theme.rgbaLightBlack2};
  position: relative;
  > div:first-child {
    &::before {
      content: ${(props) => (props.isUpgradeStyle ? `url(${STATIC_PATH}vm-white-star.svg)` : 'unset')};
      position: absolute;
      left: 77px;
      top: 7px;
    }
  }
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Spin = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }

 `;

export const Spinner = styled.div`
  position: absolute;
  left: 45%;
  top: 50%;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border: 6px solid ${(props) => props.theme.loaderBorderColor};
  border-radius: 50%;
  border-top: 6px solid ${(props) => props.theme.loaderBorderSecondary};
  -webkit-animation: ${Spin} 2s linear infinite;
  animation: ${Spin} 2s linear infinite;
`;

export const SmartImageContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.blackColor};

  img {
    width: 100%;
    height: 136px;
    border: 1px solid whitesmoke;
    object-fit: contain;
  }

  img.progressive-img-loader {
    object-fit: cover;
  }
`;

export const ExportModal = styled.div`
  position: relative;
  margin-right: 8px;
  width: ${(props) => (props.isShorts ? "424px" : "298px")};
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 26px ${(props) => props.theme.exportBoxShadow};
  border: 1px solid ${(props) => props.theme.veryLightGray};
  border-radius: ${(props) => (props.isShorts ? "16px" : "12px")};
  float: right;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 550px;
    z-index: 99;
    display: ${(props) => (props.isShowModal ? "block" : "none")};
  }
`;

export const ExportWrap = styled.div`
  padding: 16px;
`;

export const SubHeader = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
`;

export const SubtitleToggle = styled.div.attrs((props) => {
  return {
    style: {
      "--ept-iconsBorder": props.theme.iconsBorder,
    },
  };
})`
  display: flex;
  height: 36px;
  border: 1px solid var(--ept-iconsBorder);
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin: 8px 0px 0px;
`;

export const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TopWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0px 8px;
  gap: 4px;
`;


export const ExportExceed = styled.div`
  height: auto;
  background-color: ${(props) => props.theme.subtitleActiveBgColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.padding || "10px 10px 10px"};
  margin: ${(props) => props.margin || "10px 0px 0px"};
  width: 100%;
  border-radius: 4px;
  .restriction-btn{
    padding: 0px !important;
  }
  &.downloadExceed{
    flex-direction: column;
  }
`;

export const PayExportWrapper = styled.div`
  background: ${(props) => props.theme.polarColor};
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px 0px;
  margin: 0px 0px 10px;
`;

export const PayWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;