import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Publish from "./publish";
import Footer from "../animo-player/footer";
import { Flex, LeftSection, WorkspaceWrapper, RightSection, HeadingSec, Icon } from "./publish-component";
import Workspace from "../containers/workspace/workspace";
import { Loader } from "../animo-player/animo-component";
import Spinner, { SPINNER_TYPE } from "../common-components/Spinner";
import PlayerControlPanel from "../animo-player/player-controls";
import ShortsPlayerControlPanel from "../containers/shorts/shorts-player-control";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";
import { validateKeydown } from "../helper/keymap";
import ActionTypes from "../constants/action-types";
import { useCustomCallback } from "../helper/hooks/useCustomCallback";
import content from "../constants/content";

const PublishContainer = ({ isExport, isMobileShow, isPublish, shortId }) => {

  const dispatch = useDispatch();
  const appReducer = useSelector((state) => state.app);
  const isWorkspaceTextFocus = appReducer.get("isWorkspaceTextFocus");
  const textStatus = appReducer.get("textStatus");
  const initStages = appReducer.get("initStages");
  const animoPlayerId = appReducer.get("animoPlayerId");
  const projectDetails = useSelector((state) => state.projectDetails);
  const isShorts = !!shortId;
  let shortData;
  if (isShorts) {
    shortData = projectDetails.getIn(["shorts", shortId]);
  }

  const [playerShortcut, setPlayerShortcut] = useState("");

  const handleKeyDown = (e) => {
    setPlayerShortcut((prevState) => {
      const optionArr = validateKeydown(e, isWorkspaceTextFocus, textStatus);
      let tempState = prevState;
      optionArr.forEach(({ component, option }) => {
        if ((component === "playerControl") && ((!isWorkspaceTextFocus && !textStatus.get("isFocused")))) {
          // Prevent the default behaviour on arrow click.
          if (["ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
          }
          let keyoption = option;
          if (keyoption !== "") {
            if (prevState === keyoption) {
              keyoption += "_AGAIN";
            }
            tempState = keyoption;
          }
        }
      });
      return tempState;
    });
  };

  const handleKeyUp = () => {
    if (playerShortcut) {
      setPlayerShortcut("");
    }
  };

  const handleResize = () => {
    dispatch({
      type: ActionTypes.SET_RESIZE,
    });
  };

  const onKeyDown = useCustomCallback({ memoizedCallback: handleKeyDown });
  const onKeyUp = useCustomCallback({ memoizedCallback: handleKeyUp });
  const onResize = useCustomCallback({ memoizedCallback: handleResize });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.addEventListener("keyup", onKeyUp);
      window.addEventListener("resize", onResize);
    };
  }, [onKeyDown, onKeyUp, onResize]);

  let workspaceComp = null;
  if (initStages.get("projectLoaded")) {
    workspaceComp = <Workspace />;
  }
  return (
    <Flex>
      <LeftSection>
        <HeadingSec>
          <Typography
            content={content.VIDEO_IS_READY_FOR_EXPORT}
            color="#32304A"
            font={font.boldSmall}
            display="block"
          />
          <Icon src={`${STATIC_PATH}info-outline.svg`} />
          <Typography
            content={content.PREVIEW_IN_SD_QUALITY}
            color="#787B80"
            font={font.normalMicroLarge_18}
          />
        </HeadingSec>
        <WorkspaceWrapper id={animoPlayerId}>
          {workspaceComp || (
            <Loader>
              <Spinner
                type={SPINNER_TYPE.RING_SPINNER}
                spinnerHeight={"130px"}
              />
            </Loader>
          )}
          {isShorts && shortData ? (
            <ShortsPlayerControlPanel
              shortcutName={playerShortcut}
              startTime={shortData.get("start")}
              endTime={shortData.get("end")}
            />
          ) : (
            <PlayerControlPanel shortcutName={playerShortcut} />
          )}
        </WorkspaceWrapper>
        <Footer isExport={isExport} />
      </LeftSection>
      <RightSection isMobileShow={isMobileShow}>
        <Publish isShortsPlayer={!!(isShorts && shortData)} shortId={shortId} isPublish={isPublish} />
      </RightSection>
    </Flex>
  );
};

PublishContainer.propTypes = {
  isExport: PropTypes.bool,
  isMobileShow: PropTypes.bool,
  isPublish: PropTypes.bool,
  shortId: PropTypes.string
};
export default PublishContainer;
