import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import AppConfig from "../../../../constants/config";
import ActionTypes from "../../../../constants/action-types";
import LabeledInput from "../../../../common-components/LabeledInput";
import content from "../../../../constants/content";
import { font } from "../../../../constants/font";
import Action from "../../../../common-components/Action";
import useNotify from "../../../../helper/hooks/useNotify";
import useApi from "../../../../helper/hooks/useApi";
import EstimatedVideoRange from "../../../../common-components/Range";
import { Modal } from "../../../../common-components/modal";
import { BUSSINESS_DOMAINS } from "../../../../constants";
import Typography from "../../../../common-components/Typography";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Body = styled.div`
  padding: 0;
`;

const Footer = styled.div``;

const AvatarFormSection = styled.div`
  padding: 14px 20px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const RequiredStar = styled.span`
  color: #f52222;
  margin-left: 4px;
`;

const rangeOptions = ["1 - 10", "10 - 50", "50 - 100", "100 - 200", "200+"];

const AvatarForm = () => {
  const [formErrors, setFormErrors] = useState({
    name: "",
    businessEmail: "",
    estimatedNumber: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { success, warn } = useNotify();
  const dispatch = useDispatch();
  const { post } = useApi();
  const [formValue, setFormValue] = useState({
    name: "",
    businessEmail: "",
    estimatedNumber: "",
  });
  const showAvatarForm = useSelector((state) =>
    state.app.get("showAvatarForm")
  );

  const handleRangeSelect = (selectedRange) => {
    setFormValue((prevState) => ({
      ...prevState,
      estimatedNumber: selectedRange,
    }));
  };

  const isBusinessEmail = (email) =>
    BUSSINESS_DOMAINS.includes(email.split("@")[1]);

  const closeHandler = () => {
    dispatch({ type: ActionTypes.SHOW_AVATAR_FORM, payload: false });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formValue.name) {
      errors.name = "Please enter your name.";
      isValid = false;
    }
    if (!formValue.businessEmail) {
      errors.businessEmail = "Please enter your business email.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValue.businessEmail)) {
      errors.businessEmail = "Please enter a valid email address.";
      isValid = false;
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(formValue.businessEmail)) {
      errors.businessEmail = "Please enter a valid email address (lowercase only).";
      isValid = false;
    } else if (isBusinessEmail(formValue.businessEmail)) {
      errors.businessEmail = "Please enter a valid business email.";
      isValid = false;
    }

    if (!formValue.estimatedNumber) {
      errors.estimatedNumber = "Please enter an estimated number of videos.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const onResetValue = () => {
    setFormValue({
      name: "",
      businessEmail: "",
      estimatedNumber: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      const req = {
        name: formValue.name,
        businessEmail: formValue.businessEmail,
        estimatedNumber: formValue.estimatedNumber,
        Environment: process.env.NODE_ENV,
        subject: `Discover AI Avatars with Vmaker AI`,
        toAddress: "ranga@animaker.com, akbar@animaker.com, jerwin@animaker.com",
      };

      try {
        const res = await post(
          `${AppConfig.API_URL}${AppConfig.API.SEND_MAIL}`,
          req
        );
        if (res.success === true) {
          success(content.REQUEST_SENT);
        } else {
          warn(content.FAILED_TO_SEND);
        }
      } catch (error) {
        console.error("Error sending mail:", error);
        warn(content.FAILED_TO_SEND);
      } finally {
        setIsLoading(false);
        closeHandler();
        onResetValue();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal width="512px" showModal={showAvatarForm} onClose={closeHandler}>
      <AvatarFormSection>
        <Header>
          <Typography
            content={content.DISCOVER_AI_AVATAR}
            display="block"
            margin="0 auto"
            color="#46445D"
            align="center"
            padding="0 30px"
            font={font.boldSmall}
            enableTrim={false}
          />
        </Header>
        <Body>
          <LabeledInput
            label="Name"
            type="text"
            name="name"
            value={formValue.name}
            onChange={handleInputChange}
            required
            rootMargin="10px 0"
            isDisabled={isLoading}
          />
          {formErrors.name && (
            <Typography
              content={formErrors.name}
              color="#ff0000"
              font={font.normalMini_13}
            />
          )}

          <LabeledInput
            label="Business Email"
            type="email"
            name="businessEmail"
            value={formValue.businessEmail}
            onChange={handleInputChange}
            required
            rootMargin="10px 0"
            isDisabled={isLoading}
          />
          {formErrors.businessEmail && (
            <Typography
              content={formErrors.businessEmail}
              color="#ff0000"
              font={font.normalMini_13}
            />
          )}
          <Label>
            <Typography
              content={content.ESTIMATED_NUMBER_OF_VIDEOS}
              font={font.normal14}
              color="#313345"
              padding="10px 0"
              display="block"
            />
            <RequiredStar>*</RequiredStar>
          </Label>

          <EstimatedVideoRange
            rangeOptions={rangeOptions}
            onRangeSelect={handleRangeSelect}
          />
          {formErrors.estimatedNumber && (
            <Typography
              content={formErrors.estimatedNumber}
              color="#ff0000"
              font={font.normalMini_13}
            />
          )}
        </Body>
        <Footer>
          <Action
            onClick={handleSubmit}
            borderRadius="8px"
            text={isLoading ? content.SENDING : content.SUBMIT}
            marginTop="24px"
            background="#00baff"
            isDisabled={
              !formValue.name ||
              !formValue.businessEmail ||
              !formValue.estimatedNumber
            }
            isLoading={isLoading}
          />
        </Footer>
      </AvatarFormSection>
    </Modal>
  );
};

export default AvatarForm;
