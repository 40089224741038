import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { PORTAL_ID } from "../../../constants";
import { STATIC_PATH } from "../../../constants/config";
import Typography from "../../../common-components/Typography";
import vmTheme from "../../../constants/theme";
import { font } from "../../../constants/font";
import { CloseIcon, MenuWrapper, OptionWrapper } from "../timeline-components";

const AudioContextMenu = ({ menuOptions, onClick, onClose, width }, ref) => {
    const optionRef = useRef(null);
    const theme = useSelector((state) => state.app.get("theme"));

    const [position, setPosition] = useState({ x: 0, y: 0 });

    useLayoutEffect(() => {
        if (optionRef.current) {
            const optionBound = optionRef.current.getBoundingClientRect();
            setPosition({
                x: menuOptions.x,
                y: menuOptions.y - optionBound.height,
            });
        }
    }, [menuOptions.x, menuOptions.y]);

    const handleOutsideClick = (e) => {
        const preventClosing = optionRef.current && optionRef.current.contains(e.target);
        if (!preventClosing && e.target.id !== "context-menu-icon") {
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener("pointerdown", handleOutsideClick);

        return () => {
            window.removeEventListener("pointerdown", handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignRef = (r) => {
        optionRef.current = r;
        if (typeof ref === "function") {
            ref(r);
        } else if (ref && typeof ref === "object") {
            ref.current = r;
        }
    };

    return ReactDOM.createPortal(
        <>
            <MenuWrapper ref={assignRef} position={position} width={width} >
                {menuOptions.options.map(({ title, actionName }) => {
                    return (
                        <OptionWrapper key={title}>
                            <Typography
                                innerContent={title}
                                display="block"
                                margin="0 auto"
                                cursor="pointer"
                                color={vmTheme[theme].polarColor}
                                align="center"
                                font={font.normalMicroLarge}
                                onClick={() => onClick(actionName)}
                            />
                            <CloseIcon src={`${STATIC_PATH}vm-close-black.svg`} alt="close" onClick={onClose} />
                        </OptionWrapper>
                    );
                }
                )}
            </MenuWrapper>
            <div className="backdrop" />
        </>,
        document.getElementById(PORTAL_ID)
    );
};

AudioContextMenu.propTypes = {
    menuOptions: PropTypes.object,
    width: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
};

export default forwardRef(AudioContextMenu);
