import ActionTypes from "../../constants/action-types";
import { API, API_URL } from "../../constants/config";
import triggerSimpleAjax from "../../helper/httpHelper";

export function getFileSystem(pagination, payload, canDispatch = true) {
  const { currentFolder = 0, size } = payload || {};
  const apiUrl = `${API_URL}${API.GET_FF}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        {
          roottype: "folder",
          parent: currentFolder,
          from: 0,
          size: 100,
        },
        (folder) => {
          triggerSimpleAjax(
            apiUrl,
            "POST",
            true,
            {
              roottype: "file",
              parent: currentFolder,
              from: 0,
              size: (size || 25) + pagination,
            },
            (file) => {
              const response = {
                folder,
                file,
              };
              if (canDispatch) {
                dispatch({
                  type: ActionTypes.SET_UPLOAD_DATA,
                  payload: {
                    folderData: response.folder,
                    fileData: response.file,
                  },
                });
              }
              res(response);
            },
            (fileErr) => {
              rej(fileErr);
            }
          );
        },
        (err) => {
          rej(err);
        }
      );
    });
}

export function initiateCreateFolder(payload) {
  const apiUrl = `${API_URL}${API.CREATE_FOLDER}`;

  return new Promise((res) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (err) => {
        res(err);
      }
    );
  });
}

export function deleteCreatedFolder(payload) {
  const apiUrl = `${API_URL}${API.DELETE_FOLDER}`;

  return new Promise((res) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (err) => {
        res(err);
      }
    );
  });
}

export function renameFolder(payload) {
  const apiUrl = `${API_URL}${API.RENAME_FOLDER}`;

  return new Promise((res) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (err) => {
        res(err);
      }
    );
  });
}

export function moveFolder(payload) {
  const apiUrl = `${API_URL}${API.MOVE_FOLER}`;

  return new Promise((res) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (err) => {
        res(err);
      }
    );
  });
}

export const getUploadedFiles = (payload) => {
  const apiUrl = `${API_URL}${API.GET_UPLOADED_FILES}`;

  return new Promise((res) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      { from: 0, size: 100, ...payload },
      (response) => {
        res(response);
      },
      (err) => {
        res(err);
      }
    );
  });
};

export const getSearchFiles = (pagination, payload) => {
  const apiUrl = `${API_URL}${API.SOAK}`;

  return new Promise((res) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {
        from: pagination,
        size: 100,
        action: "uploaded",
        fromScroll: false,
        ...payload,
      },
      (response) => {
        if (response?.aggs?.length) {
          response.total = response.aggs[0].doc_count;
        }
        res(response);
      },
      (err) => {
        res(err);
      }
    );
  });
};
