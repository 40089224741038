import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";

const TabWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.totalTabs ? props.totalTabs : 3}, 1fr)`};
  grid-template-rows: 44px;
  margin-bottom: 17px;
  border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};
  ${(props) => props.customStyles && css`${props.customStyles}`}
`;

const TabButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font: ${font.normalBase};
  color: ${(props) => props.theme.secondaryColor};
  background: none;
  border: none;
  padding: 0;
  overflow: hidden;
  transition: color 0.3s;

  img {
    width: 16px;
    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }
`;

const Indicator = styled.div`
  position: absolute;
  bottom: 0px;
  left: 2px;
  height: 2px;
  z-index: 0;
  width: ${(props) => `${100 / props.totalTabs}%`};
  background-color: ${(props) => props.theme.secondaryBorderColor};
  border-radius: 6px;
  transform: ${(props) => `translateX(${props.activeTab * 100}%)`};
  transition: transform 0.3s linear;
  @media screen and (max-width: 767px) {
    width: calc(100% / 3);
    transform: ${(props) => `translateX(${props.activeTab * 100}%)`};
  }
`;

export const TabToggleComponent = (props) => {
  const { tabs, currentTab, setCurrentTab, customStyles } = props;

  return (
    <TabWrapper totalTabs={tabs.length} customStyles={customStyles}>
      {tabs.map((tab) => (
        <TabButton
          className={`imgf--tab-btn ${currentTab === tab.label ? "tab-btn-active" : ""}`}
          onClick={() => setCurrentTab(tab.label)}
          key={tab.label}
        >
          {tab.icon && (
            <img
              draggable={false}
              src={`${STATIC_PATH}${currentTab === tab.label ? tab.activeIcon : tab.icon}`}
              alt="filters"
            />
          )}
          {tab.label}
        </TabButton>
      ))}
      <Indicator
        totalTabs={tabs.length}
        activeTab={tabs.findIndex((tab) => tab.label === currentTab)}
      />
    </TabWrapper>
  );
};

TabToggleComponent.propTypes = {
  tabs: PropTypes.array,
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  customStyles: PropTypes.string,
};

export default TabToggleComponent;
