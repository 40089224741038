/* eslint-disable operator-assignment */

import { fromJS } from "immutable";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { ArrangeListHeading, ArrangeListItemStyled, ArrangePopupStyled, RadiusLink, RadiusPopupStyled, TransformButton, TransformFlipContainer, TransformHeading, TransformPopupStyled, TransformRotateContainer, TransparencyPopupStyled } from "./itemtoolbar-components";
import content from "../../constants/content";
import { applyFlip, bulkObjectUpdate, moveUpdate, updateTimelineTime } from "../../redux/actions/timelineUtils";
import NumberInput from "../../common-components/NumberInputComponent";
import Slider from "../zoom/zoom-slider";
import { STATIC_PATH } from "../../constants/config";
import { alignBottom, alignCenter, alignHorizontally, alignLeft, alignMiddle, alignRight, alignTidyUp, alignTop, alignVertically, getObjectsBounds, getRadiusClipPath } from "../../helper/TransformManagerHelper";
import { handleLayerPosition, isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import { getSelectedItemIds, getSelectionContainer } from "../../helper/IndividualSelectHelper";
import { flipPositionToState, flipStateToPosition, getNextRotation, rotationDisplayValue } from "./itemtoolbar-helper";
import { updateImperativeRotate } from "../../redux/actions/appUtils";
import vmTheme from "../../constants/theme";
import { singleClipFrameToFrame } from "../frame/frame-helper";

export const TransparencyPopup = () => {
    const _selectedItems = useSelector((state) => state.app.get("selectedItems"));
    const originalOpacity = useSelector((state) => {
        const selectedItems = state.app.get("selectedItems");
        const workspaceItems = state.projectDetails.get("workspaceItems");
        const firstItem = workspaceItems.get(selectedItems.first());

        if (!firstItem) {
            return 0;
        }

        return selectedItems.reduce(
            (prevOpacity, itemId) => {
                const itemOpacity = workspaceItems.getIn([itemId, "opacity"]);
                return prevOpacity < itemOpacity ? prevOpacity : itemOpacity
            },
            firstItem.get("opacity")
        );
    });
    const theme = useSelector((state) => state.app.get('theme'));
    const dispatch = useDispatch();
    const intl = useIntl();
    const [opacity, setOpacity] = useState(originalOpacity);
    const [selectedItems, setSelectedItems] = useState(_selectedItems);

    const onChange = (e, newOpacity) => {
        selectedItems.valueSeq().forEach((itemId) => {
            const itemEl = document.getElementById(itemId);
            if (itemEl) {
                itemEl.style.opacity = newOpacity / 100;
            }
        });
        setOpacity(newOpacity / 100);
    }

    const onSubmit = () => {
        // do not update internal state here as number input component will trigger this function on unmount!
        if (originalOpacity !== opacity) {
            const toUpdate = {};
            selectedItems.forEach((itemId) => {
                toUpdate[itemId] = {
                    data: { opacity },
                };
            })
            dispatch(bulkObjectUpdate({ workspaceItems: toUpdate }));
        }
    }

    useEffect(() => {
        setOpacity(originalOpacity);
    }, [originalOpacity]);

    // to delay selection change for submit on unmount
    useEffect(() => {
        setSelectedItems(_selectedItems);
    }, [_selectedItems])

    const displayValue = Math.round(opacity * 1000) / 10;
    return (
        <TransparencyPopupStyled>
            <p className="item-tool-tps--heading">
                {intl.formatMessage({ id: content.TRANSPARENCY })}
            </p>
            <NumberInput
                min={0}
                max={100}
                step={0.5}
                value={displayValue}
                precision={1}
                onChange={onChange}
                onSubmit={onSubmit}
                suffix="%"
                saveOnUnmount={true}
            />
            <div className="item-tool-tps--slider">
                <Slider
                    min={0}
                    max={100}
                    inputMin={0}
                    inputMax={100}
                    value={displayValue}
                    step={0.5}
                    onChangeSliderValue={onChange}
                    onMouseUp={onSubmit}
                    border={"none"}
                    borderRadius={"3px"}
                    background={vmTheme[theme].veryLightGray}
                    margin={"0"}
                    height={"5px"}
                    thumb={{
                        background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                        border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                        height: "16px",
                        width: "16px",
                        hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                        hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                    }}
                    progressBackground={vmTheme[theme].secondaryBorderColor}
                    isChangeBg={true}
                />
            </div>
        </TransparencyPopupStyled>
    );
}

const ArrangeListItem = (props) => {
    let { className = "" } = props;
    const { isDisabled, imgSrc, hoverImgSrc, disabledImgSrc } = props;

    const intl = useIntl();
    const msg = intl.formatMessage({ id: props.langId, defaultMessage: props.defaultMessage });

    const style = {};

    if (imgSrc) {
        style["--it-popup-src"] = `url(${imgSrc})`;
    }
    if (hoverImgSrc) {
        style["--it-popup-hoverSrc"] = `url(${hoverImgSrc})`;
    }
    if (disabledImgSrc) {
        style["--it-popup-disabledSrc"] = `url(${disabledImgSrc})`;
    }
    if (isDisabled) {
        className = `${className} tool-disabled`;
    }

    return (
        <ArrangeListItemStyled
            className={className}
            onClick={props.onClick}
            style={style}
        >
            <img draggable="false" alt={msg} src={imgSrc} />
            <p>{msg}</p>
        </ArrangeListItemStyled>
    );
}
ArrangeListItem.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    imgSrc: PropTypes.string,
    hoverImgSrc: PropTypes.string,
    disabledImgSrc: PropTypes.string,
    langId: PropTypes.string,
    defaultMessage: PropTypes.string,
};

export const LayerPopup = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const workspaceWidth = useSelector((state) => state.app.get("projectWidth"));
    const workspaceHeight = useSelector((state) => state.app.get("projectHeight"));
    const selectedItems = useSelector((state) => state.app.get("selectedItems"));
    const workspaceItems = useSelector((state) => state.projectDetails.get("workspaceItems"));
    const selectedItemId = selectedItems.first();
    const theme = useSelector((state) => state.app.get('theme'));

    const iconStatus = useMemo(() => {
        const currentItem = workspaceItems.get(selectedItemId);
        const status = {
            back: false,
            backward: false,
            front: false,
            forward: false,
        }

        if (currentItem) {
            const lastTrack = workspaceItems.reduce((prevTrack, item) => {
                return item.get("track") > prevTrack ? item.get("track") : prevTrack;
            }, 0);
            status.back = currentItem.get("track") > 0;
            status.backward = currentItem.get("track") > 0;
            status.front = currentItem.get("track") < lastTrack;
            status.forward = currentItem.get("track") < lastTrack;
        }

        return status;
    }, [workspaceItems, selectedItemId]);

    const handleTrackChange = (type) => {
        const toUpdate = handleLayerPosition({
            container: "workspaceItems",
            itemId: selectedItemId,
            items: workspaceItems,
            type,
        });
        if (toUpdate.length) {
            dispatch(updateTimelineTime({ toUpdate }))
        }
    }

    const handleAlign = (type) => {
        let alignedItems;
        const selectedItem = workspaceItems.get(selectedItemId);
        const selectionBounds = fromJS({ x: 0, y: 0, width: workspaceWidth, height: workspaceHeight });
        const selectedObjects = fromJS({ [selectedItemId]: selectedItem });

        if (type === "top") {
            alignedItems = alignTop(selectionBounds, selectedObjects);
        } else if (type === "middle") {
            alignedItems = alignMiddle(selectionBounds, selectedObjects);
        } else if (type === "bottom") {
            alignedItems = alignBottom(selectionBounds, selectedObjects);
        } else if (type === "left") {
            alignedItems = alignLeft(selectionBounds, selectedObjects);
        } else if (type === "center") {
            alignedItems = alignCenter(selectionBounds, selectedObjects);
        } else if (type === "right") {
            alignedItems = alignRight(selectionBounds, selectedObjects);
        }

        if (alignedItems) {
            dispatch(
                moveUpdate({
                    items: alignedItems,
                    currentContainer: "workspaceItems",
                    selectedItems,
                })
            );
        }
    }

    return (
        <ArrangePopupStyled className="layer-popup">
            <ul>
                {/* position menu */}
                <ArrangeListHeading className="layer-popup-item">
                    <p>{intl.formatMessage({ id: content.POSITION })}</p>
                </ArrangeListHeading>
                <ArrangeListItem
                    isDisabled={!iconStatus.forward}
                    langId={content.FORWARD}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.forwardIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.forwardIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.forwardDisabledIcon}`}
                    onClick={() => handleTrackChange("forward")}
                />
                <ArrangeListItem
                    isDisabled={!iconStatus.backward}
                    langId={content.BACKWARD}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backwardIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backwardIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backwardDisabledIcon}`}
                    onClick={() => handleTrackChange("backward")}
                />
                <ArrangeListItem
                    isDisabled={!iconStatus.front}
                    langId={content.TO_FRONT}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.frontIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.frontIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.frontDisabledIcon}`}
                    onClick={() => handleTrackChange("front")}
                />
                <ArrangeListItem
                    isDisabled={!iconStatus.back}
                    langId={content.TO_BACK}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backDisabledIcon}`}
                    onClick={() => handleTrackChange("back")}
                />

                {/* page align menu */}
                <ArrangeListHeading className="layer-popup-item">
                    <p>{intl.formatMessage({ id: content.ALIGN_TO_PAGE })}</p>
                </ArrangeListHeading>
                <ArrangeListItem
                    langId={content.TOP}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.topAlignIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.topAlignIcon}`}
                    onClick={() => handleAlign("top")}
                />
                <ArrangeListItem
                    langId={content.LEFT}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.leftAlignIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.leftAlignIcon}`}
                    onClick={() => handleAlign("left")}
                />
                <ArrangeListItem
                    langId={content.MIDDLE}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.middleAlignIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.middleAlignIcon}`}
                    onClick={() => handleAlign("middle")}
                />
                <ArrangeListItem
                    langId={content.CENTER}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.centerAlignIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.centerAlignIcon}`}
                    onClick={() => handleAlign("center")}
                />
                <ArrangeListItem
                    langId={content.BOTTOM}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.bottomAlignIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.bottomAlignIcon}`}
                    onClick={() => handleAlign("bottom")}
                />
                <ArrangeListItem
                    langId={content.RIGHT}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.rightAlignIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.rightAlignIcon}`}
                    onClick={() => handleAlign("right")}
                />
            </ul>
        </ArrangePopupStyled>
    );
}

export const ArrangePopup = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const selectedItems = useSelector((state) => state.app.get("selectedItems"));
    const workspaceItems = useSelector((state) => state.projectDetails.get("workspaceItems"));

    const handleAlign = (type) => {
        let alignedItems;
        const selectedObjects = workspaceItems.filter((obj, key) => selectedItems.keyOf(key) !== undefined);
        const selectionBounds = fromJS(getObjectsBounds(selectedObjects));

        if (type === "top") {
            alignedItems = alignTop(selectionBounds, selectedObjects);
        } else if (type === "middle") {
            alignedItems = alignMiddle(selectionBounds, selectedObjects);
        } else if (type === "bottom") {
            alignedItems = alignBottom(selectionBounds, selectedObjects);
        } else if (type === "left") {
            alignedItems = alignLeft(selectionBounds, selectedObjects);
        } else if (type === "center") {
            alignedItems = alignCenter(selectionBounds, selectedObjects);
        } else if (type === "right") {
            alignedItems = alignRight(selectionBounds, selectedObjects);
        } else if (type === "vertically") {
            alignedItems = alignVertically(selectionBounds, selectedObjects);
        } else if (type === "tidyup") {
            alignedItems = alignTidyUp(selectionBounds, selectedObjects);
        } else if (type === "horizontally") {
            alignedItems = alignHorizontally(selectionBounds, selectedObjects);
        }

        if (alignedItems) {
            dispatch(
                moveUpdate({
                    items: alignedItems,
                    currentContainer: "workspaceItems",
                    selectedItems,
                })
            );
        }
    }

    return (
        <ArrangePopupStyled>
            <ul>
                {/* align items menu */}
                <ArrangeListHeading>
                    <p>{intl.formatMessage({ id: content.ALIGN_ELEMENTS })}</p>
                </ArrangeListHeading>
                <ArrangeListItem
                    langId={content.TOP}
                    imgSrc={`${STATIC_PATH}top-align.svg`}
                    hoverImgSrc={`${STATIC_PATH}top-align.svg`}
                    onClick={() => handleAlign("top")}
                />
                <ArrangeListItem
                    langId={content.LEFT}
                    imgSrc={`${STATIC_PATH}left-align.svg`}
                    hoverImgSrc={`${STATIC_PATH}left-align.svg`}
                    onClick={() => handleAlign("left")}
                />
                <ArrangeListItem
                    langId={content.MIDDLE}
                    imgSrc={`${STATIC_PATH}middle-align.svg`}
                    hoverImgSrc={`${STATIC_PATH}middle-align.svg`}
                    onClick={() => handleAlign("middle")}
                />
                <ArrangeListItem
                    langId={content.CENTER}
                    imgSrc={`${STATIC_PATH}center-align.svg`}
                    hoverImgSrc={`${STATIC_PATH}center-align.svg`}
                    onClick={() => handleAlign("center")}
                />
                <ArrangeListItem
                    langId={content.BOTTOM}
                    imgSrc={`${STATIC_PATH}bottom-align.svg`}
                    hoverImgSrc={`${STATIC_PATH}bottom-align.svg`}
                    onClick={() => handleAlign("bottom")}
                />
                <ArrangeListItem
                    langId={content.RIGHT}
                    imgSrc={`${STATIC_PATH}right-align.svg`}
                    hoverImgSrc={`${STATIC_PATH}right-align.svg`}
                    onClick={() => handleAlign("right")}
                />

                {/* space evenly menu */}
                <ArrangeListHeading>
                    <p>{intl.formatMessage({ id: content.SPACE_EVENLY })}</p>
                </ArrangeListHeading>
                <ArrangeListItem
                    langId={content.VERTICALLY}
                    imgSrc={`${STATIC_PATH}vertically.svg`}
                    hoverImgSrc={`${STATIC_PATH}vertically.svg`}
                    onClick={() => handleAlign("vertically")}
                />
                <ArrangeListItem
                    langId={content.TIDY_UP}
                    imgSrc={`${STATIC_PATH}tidyup.svg`}
                    hoverImgSrc={`${STATIC_PATH}tidyup.svg`}
                    onClick={() => handleAlign("tidyup")}
                />
                <ArrangeListItem
                    langId={content.HORIZONTALLY}
                    imgSrc={`${STATIC_PATH}horizontally.svg`}
                    hoverImgSrc={`${STATIC_PATH}horizontally.svg`}
                    onClick={() => handleAlign("horizontally")}
                />
            </ul>
        </ArrangePopupStyled>
    );
}

const FlipSection = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const childrenSelection = useSelector(state => state.app.get("childrenSelection"));
    const _selectedItems = useSelector(state => state.app.get("selectedItems"));
    const selectedFrameClip = useSelector((state) => state.app.get("selectedFrameClip"));
    const projectDetails = useSelector((state) => state.projectDetails);
    const theme = useSelector((state) => state.app.get('theme'));

    const { selectionContainer, selectedItems } = useMemo(() => {
        return {
            selectionContainer: getSelectionContainer({
                childrenSelection,
                selectedItems: _selectedItems,
            }),
            selectedItems: getSelectedItemIds({
                childrenSelection,
                selectedItems: _selectedItems,
            }),
        };
    }, [
        childrenSelection,
        _selectedItems,
    ]);
    const firstItemId = selectedItems.first();
    const orgSelectedItem = projectDetails.getIn([selectionContainer, firstItemId]);

    const selectedItem = useMemo(() => {
        let item = orgSelectedItem;
        if (item.get("isSingleClipFrame")) {
            item = singleClipFrameToFrame(item);
        }
        return item;
    }, [orgSelectedItem]);

    const getFlipInfo = useCallback(() => {
        let flipItem = selectedItem;
        let isClipFlip = false;
        let flipState = {
            isHorizontalFlip: false,
            isVerticalFlip: false,
            isDisabled: true,
            shouldHide: false,
        };

        if (selectedItem && selectedFrameClip.get("clipId")) {
            const imgDetails = selectedItem.getIn(["clipDetails", selectedFrameClip.get("clipId"), "imgDetails"]);
            if (
                imgDetails
                && (isImageOnly(imgDetails.get("type"), imgDetails.get("subType")) || isVideoOnly(imgDetails.get("type"), imgDetails.get("subType")))
            ) {
                flipItem = imgDetails;
                isClipFlip = true;
            }
        } else if (selectedItem && (selectedItem.get("type") === "GROUP" || selectedItem.get("type") === "TEXT")) {
            flipItem = undefined;
            flipState.shouldHide = true;
        }

        if (flipItem) {
            flipState = flipPositionToState(flipItem.get("flipPosition"));
            flipState.isDisabled = false;
            flipState.shouldHide = false;
        }

        return {
            selectedItem,
            flipItem,
            isClipFlip,
            flipState,
        }
    }, [selectedItem, selectedFrameClip]);

    const flipState = useMemo(() => getFlipInfo().flipState, [getFlipInfo]);

    const handleFlip = (type = "hor") => {
        const flipInfo = getFlipInfo();
        const { isClipFlip, selectedItem, flipItem } = flipInfo;

        if (!flipItem) {
            return;
        }

        const newFlipState = { ...flipInfo.flipState };
        if (type === "hor") {
            newFlipState.isHorizontalFlip = !newFlipState.isHorizontalFlip;
        }
        if (type === "ver") {
            newFlipState.isVerticalFlip = !newFlipState.isVerticalFlip;
        }
        const flipPosition = flipStateToPosition(newFlipState);

        const toUpdate = [
            {
                container: selectionContainer,
                id: selectedItem.get("id"),
                data: { flipPosition },
                clipId: isClipFlip ? selectedFrameClip.get("clipId") : undefined,
            }
        ];
        dispatch(applyFlip(toUpdate));
    }

    if (flipState.shouldHide) {
        return null;
    }

    let horButtonCls = "";
    if (flipState.isHorizontalFlip) {
        horButtonCls = `${horButtonCls} is-flip-hor`;
    }
    let verButtonCls = "";
    if (flipState.isVerticalFlip) {
        verButtonCls = `${horButtonCls} is-flip-ver`;
    }

    return (
        <TransformFlipContainer>
            <TransformHeading>{intl.formatMessage({ id: content.FLIP })}</TransformHeading>
            <TransformButton
                className={horButtonCls}
                onClick={() => handleFlip("hor")}
            >
                <img draggable="false" alt="flip horizontally" src={`${STATIC_PATH}${vmTheme[theme].icons.flipSideIcon}`} />
            </TransformButton>
            <TransformButton
                className={verButtonCls}
                onClick={() => handleFlip("ver")}
            >
                <img draggable="false" alt="flip vertically" src={`${STATIC_PATH}${vmTheme[theme].icons.flipTopIcon}`} />
            </TransformButton>
        </TransformFlipContainer>
    );
}

const RotateSection = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.app.get('theme'));
    const selectedItems = useSelector((state) => state.app.get("selectedItems"));
    const originalAngle = useSelector((state) => {
        let rotation = 0;
        const selectedItems = state.app.get("selectedItems");
        const childrenSelection = state.app.get("childrenSelection");
        const selectionContainer = getSelectionContainer({
            childrenSelection,
            selectedItems,
        });
        const clipId = state.app.getIn(["selectedFrameClip", "clipId"]);

        if (selectedItems.size === 1 && selectionContainer === "workspaceItems" && !clipId) {
            const id = selectedItems.first();
            rotation = state.projectDetails.getIn([selectionContainer, id, "angle"]);
        }

        return rotation;
    });
    const [angle, setAngle] = useState(() => rotationDisplayValue(Math.round(originalAngle)));
    const canUpdateState = useRef(false);
    const isMultipleSelection = selectedItems.size > 1;

    useEffect(() => {
        canUpdateState.current = true;
        dispatch(updateImperativeRotate({ reset: true }));
        return () => {
            canUpdateState.current = false;
            dispatch(updateImperativeRotate({ reset: true }))
        };
    }, [dispatch]);

    const onChange = (e, newRotation) => setAngle(Math.round(newRotation));

    const onSubmit = () => {
        if (isMultipleSelection && canUpdateState.current) {
            setAngle(0);
        }
        const stateAngle = rotationDisplayValue(angle, true);
        if (originalAngle !== stateAngle) {
            dispatch(updateImperativeRotate({ rotateTo: angle }));
        }
    }

    const handleRotateButtonClick = (direction) => {
        const newRotation = getNextRotation({
            angle,
            direction,
        });
        dispatch(updateImperativeRotate({ rotateTo: newRotation }));
    }

    useEffect(() => {
        setAngle(rotationDisplayValue(Math.round(originalAngle)));
    }, [originalAngle]);

    const displayValue = Math.round(angle);
    return (
        <TransformRotateContainer>
            <TransformHeading className="rotate-heading">{intl.formatMessage({ id: content.ROTATE })}</TransformHeading>
            <NumberInput
                min={-180}
                max={180}
                step={1}
                value={displayValue}
                precision={0}
                onChange={onChange}
                onSubmit={onSubmit}
                suffix="°"
                saveOnUnmount={false}
                draggable
                dragStepPercent={0.05}
            />
            <TransformButton onClick={() => handleRotateButtonClick("anticlockwise")}>
                <img draggable="false" alt="rotate clockwise" src={`${STATIC_PATH}${vmTheme[theme].icons.rotateAntiClockWise}`} />
            </TransformButton>
            <TransformButton onClick={() => handleRotateButtonClick("clockwise")}>
                <img draggable="false" alt="rotate anticlockwise" src={`${STATIC_PATH}${vmTheme[theme].icons.rotateClockwise}`} />
            </TransformButton>
        </TransformRotateContainer>
    )
}

export const TransformPopup = () => {
    const selectedItems = useSelector((state) => state.app.get("selectedItems"));
    const childrenSelection = useSelector(state => state.app.get("childrenSelection"));
    const selectedFrameClip = useSelector((state) => state.app.get("selectedFrameClip"));

    const selectionContainer = useMemo(() => {
        return getSelectionContainer({
            childrenSelection,
            selectedItems,
        });
    }, [
        childrenSelection,
        selectedItems,
    ]);

    let flip = null;
    if (selectedItems.size === 1) {
        flip = <FlipSection />;
    }

    let rotate = null;
    if (selectedItems.size > 0 && selectionContainer === "workspaceItems" && !selectedFrameClip.get("clipId")) {
        rotate = <RotateSection />
    }

    return (
        <TransformPopupStyled>
            {flip}
            {rotate}
        </TransformPopupStyled>
    );
}

export const RadiusPopup = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const zoomFactor = useSelector((state) => state.app.get("zoomFactor"));
    const childrenSelection = useSelector(state => state.app.get("childrenSelection"));
    const _selectedItems = useSelector(state => state.app.get("selectedItems"));
    const projectDetails = useSelector((state) => state.projectDetails);

    const { selectionContainer, selectedItems } = useMemo(() => {
        return {
            selectionContainer: getSelectionContainer({
                childrenSelection,
                selectedItems: _selectedItems,
            }),
            selectedItems: getSelectedItemIds({
                childrenSelection,
                selectedItems: _selectedItems,
            }),
        };
    }, [
        childrenSelection,
        _selectedItems,
    ]);
    const firstItemId = selectedItems.first();
    const selectedItem = projectDetails.getIn([selectionContainer, firstItemId]);

    const [radius, setRadius] = useState(
        () => fromJS({
            tl: 0,
            tr: 0,
            br: 0,
            bl: 0,
            linked: true,
        })
    );
    const [item, setItem] = useState(selectedItem);

    useEffect(() => {
        let _radius = fromJS({
            tl: 0,
            tr: 0,
            br: 0,
            bl: 0,
            linked: true,
        });
        if (selectedItem && selectedItem.get("radius")) {
            _radius = selectedItem.get("radius");
        }
        setRadius(_radius);
        // to delay selection change for submit on unmount
        setItem(selectedItem);
    }, [selectedItem]);

    const applyRadiusClipPath = (item) => {
        const itemId = item.get("id");
        const clipEl = document.getElementById(itemId);
        if (clipEl) {
            let clipPath = getRadiusClipPath(item, zoomFactor);
            clipPath = `path("${clipPath}")`;
            clipEl.style.clipPath = clipPath;
        }
    }

    /**
     * 
     * @param {Event} e
     * @param {number} newPercent
     * @param {"tl" | "tr" | "br" | "bl"} type 
     */
    const onChange = (e, newPercent, type) => {
        let newRadiusState = radius;

        const cornerKeys = ["tl", "tr", "br", "bl"];
        if (newRadiusState.get("linked")) {
            cornerKeys.forEach((corner) => {
                newRadiusState = newRadiusState.set(corner, newPercent);
            });
        } else if (cornerKeys.includes(type)) {
            newRadiusState = newRadiusState.set(type, newPercent);
        }

        if (newRadiusState !== radius) {
            if (item) {
                const radiusItem = item.set("radius", newRadiusState);
                applyRadiusClipPath(radiusItem);
            }
            setRadius(newRadiusState);
        }
    }

    const onSubmit = () => {
        if (radius !== item.get("radius")) {
            const toUpdate = {
                [item.get("id")]: {
                    data: {
                        radius,
                    },
                }
            };
            dispatch(bulkObjectUpdate({ workspaceItems: toUpdate }));
        }
    }

    const toggleLink = () => {
        const newRadius = radius.set("linked", !radius.get("linked"));
        const toUpdate = {
            [selectedItem.get("id")]: {
                data: {
                    radius: newRadius,
                },
            }
        };
        dispatch(bulkObjectUpdate({ workspaceItems: toUpdate }));
    }

    let radiusIcon;
    if (radius.get("linked")) {
        radiusIcon = (
            <RadiusLink className="linked" onClick={toggleLink}>
                <img draggable="false" alt="unlink borders" src={`${STATIC_PATH}radius-linked.svg`} />
            </RadiusLink>
        );
    } else {
        radiusIcon = (
            <RadiusLink className="unlinked" onClick={toggleLink}>
                <img draggable="false" alt="link borders" src={`${STATIC_PATH}radius-unlinked.svg`} />
            </RadiusLink>
        );
    }

    return (
        <RadiusPopupStyled>
            <p>{intl.formatMessage({ id: content.RADIUS })}</p>
            <div>
                <NumberInput
                    min={0}
                    max={100}
                    step={1}
                    value={radius.get("tl")}
                    precision={0}
                    onChange={(e, v) => onChange(e, v, "tl")}
                    onSubmit={onSubmit}
                    suffix=""
                    saveOnUnmount={true}
                    draggable
                />
                <NumberInput
                    min={0}
                    max={100}
                    step={1}
                    value={radius.get("tr")}
                    precision={0}
                    onChange={(e, v) => onChange(e, v, "tr")}
                    onSubmit={onSubmit}
                    suffix=""
                    saveOnUnmount={false}
                    draggable
                />
                <NumberInput
                    min={0}
                    max={100}
                    step={1}
                    value={radius.get("bl")}
                    precision={0}
                    onChange={(e, v) => onChange(e, v, "bl")}
                    onSubmit={onSubmit}
                    suffix=""
                    saveOnUnmount={true}
                    draggable
                />
                <NumberInput
                    min={0}
                    max={100}
                    step={1}
                    value={radius.get("br")}
                    precision={0}
                    onChange={(e, v) => onChange(e, v, "br")}
                    onSubmit={onSubmit}
                    suffix=""
                    saveOnUnmount={true}
                    draggable
                />
                {radiusIcon}
            </div>
        </RadiusPopupStyled>
    );
}
