import styled, { css } from "styled-components";
import { APP_Z_INDICES, PANEL } from "../../constants";

const OuterLayer = styled.div`
  background-color: ${(props) => props.theme.outerLayerBgColor};
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  pointer-events: none;
  @media (max-width: 768px) {
    &.properties-tool-bar {
      position: fixed;
      bottom: 0;
      top: auto;
      height: 80px;
      width: 100%;
      z-index: 12;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const MenuSection = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: inline-block;
  width: 65px;

  ${(props) =>
    props.isExpand &&
    css`
      width: 414px;
    `}
`;

const MenuContainer = styled.div`
  pointer-events: auto;
  background-color: ${(props) => props.theme.polarColor};
  max-width: 65px;
  height: 100%;
  width: 100%;
  float: left;
  position: relative;
  border-right: 1px solid ${(props) => props.theme.subtitleBtnBorder};
  z-index: ${APP_Z_INDICES.sidebar.default};
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0px 20px 0px 0px;
    li {
      float: left;
    }
  }
`;

const MenuList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: 85vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    * {
      touch-action: auto;
    }
    display: flex;
    overflow-x: auto;
    padding: 0px 8px;
    touch-action: auto;
  }
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: ${(props) =>
    props.isSelected && !props.restricted
      ? props.theme.secondaryBorderColor
      : "transparent"};
`;

const List = styled.li`
  margin: 18px 0px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  ${(props) =>
    props.isSelected &&
    css`
      &:before {
        content: "";
        width: 4px;
        height: 40px;
        position: absolute;
        left: 0px;
        background: ${(props) => props.theme.secondaryBorderColor};
      }
      @media (max-width: 768px) {
        &:before {
          display: none;
        }
        label {
          color: ${(props) => props.theme.propertyColor};
          font-weight: 600;
        }
      }
    `}
  ${(props) =>
    props.isSelected &&
    css`
      &:before {
        content: "";
        width: 4px;
        height: 40px;
        position: absolute;
        left: 0px;
        background: ${(props) => props.theme.secondaryBorderColor};
      }
    `}
  ${(props) =>
    props.isDisable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  @media (max-width: 768px) {
    float: left;
    min-width: 72px;
    img {
      max-width: 100%;
      height: auto;
    }
    label {
      font-size: 12px;
    }
  }
  &:hover {
    ${IconWrapper} {
      background-color: ${(props) =>
        props.isSelected && !props.restricted
          ? props.theme.secondaryBorderColor
          : props.theme.subtitleBgColor};
    }
    &:nth-last-child(2) {
      background-color: ${(props) => props.isSelected && "transparent"};
    }
  }

  &:last-child {
    position: fixed;
    bottom: 0;
    background: #fff;
    padding-top: 10px;
    left: 0;
    width: 65px;
    ${IconWrapper} {
      background-color: transparent;
    }
    @media (max-width: 768px) {
      position: relative;
      padding-top: 0px;
    }
  }
`;
const Icon = styled.img`
  max-width: 100%;
  height: auto;
  transform: ${(props) => props.transform || "rotate(0deg)"};
`;

const ToggleSection = styled.div.attrs((props) => {
  const headerHeight = props.workspaceStage.top.menu;
  const timelineHeight = props.workspaceStage.bottom.timeline;
  let { className = "" } = props;

  const style = {
    height: `calc(100% - ${headerHeight + timelineHeight}px)`,
  };

  if (props.isPropertyWindow && !props.isExpand) {
    // disabling prop window instead of unmounting it, so that it will be easier to animate if needed in future
    className = `${className} prop-window-disabled`;
  }

  if (!props.animateWindow) {
    style.transition = "none";
  }

  return {
    className,
    style,
  };
})`
  pointer-events: auto;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.polarColor};
  position: absolute;
  transition: transform 0.8s ease-in-out;
  max-width: ${(props) =>
    props.maxWidth === PANEL.SUBTITLE ? "600px" : "348px"};
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  left: 0;
  transform: translateX(-100%) translateX(65px);
  @media (max-width: 768px) {
    left: 0;
    max-width: 100vw;
    box-shadow: 0px -4px 32px ${(props) => props.theme.rgbaBlack};
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
    transform: translateX(0) translateY(100%) translateY(-500px);
    right: 0;
    height: 0px !important;
    opacity: 0;
    z-index: -9;
  }
  ${(props) =>
    props.isExpand &&
    css`
      transform: translateX(65px);
      @media (max-width: 768px) {
        height: 510px !important;
        transform: translateY(-510px);
        opacity: 1;
        z-index: auto;
      }
    `}

  &.prop-window-disabled {
    pointer-events: none !important;
    opacity: 0;
    transition:
      transform 0.8s ease-in-out,
      opacity 0.8s steps(1);
  }
`;

const CollapseIcon = styled.div.attrs((props) => {
  const workspaceTop = props.workspaceStage.top;
  const collapseMinOffset =
    workspaceTop.menu + workspaceTop.toolbar + workspaceTop.padding;
  return {
    style: {
      top: `max(50%, ${collapseMinOffset}px)`,
    },
  };
})`
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 80px;
  line-height: 5;
  background: ${(props) => props.theme.polarColor};
  box-shadow: 1px 0px 2px ${(props) => props.theme.boxShadowColor};
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
  text-align: center;
  @media (max-width: 768px) {
    left: 50%;
    width: 100px;
    height: 40px;
    background: transparent;
    border: transparent;
    box-shadow: none;
    position: relative;
    transform: translateX(-50%) translateY(-805px);
    &::after {
      content: "";
      transform: none;
      background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.16);
      border-image: initial;
      border-radius: 3px;
      width: 40px;
      height: 6px;
      position: absolute;
      left: 30px;
      top: 17px;
    }
    // background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    // border: 1px solid ${(props) => props.theme.rgbaBlack};
    // border-radius: 3px;
    // width: 40px;
    // height: 6px;
    // transform: translateX(-50%) translateY(-270px);
    // // display: none;
    img {
      display: none;
    }
  }
`;

const ToggleWrapper = styled.div`
  height: 100%;
  padding: ${(props) => (props.maxWidth === PANEL.SUBTITLE ? "0px" : "16px")};
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 0px;
    ${(props) =>
      props.isExpand &&
      css`
        padding: 16px;
      `}
  }
`;

export {
  OuterLayer,
  MenuSection,
  Wrapper,
  MenuContainer,
  MenuList,
  Icon,
  ToggleSection,
  ToggleWrapper,
  CollapseIcon,
  List,
  IconWrapper,
};
