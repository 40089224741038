import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConfig, { STATIC_PATH } from "../../../constants/config";
import { font } from "../../../constants/font";
import useFileUpload from "../../../helper/hooks/useFileUpload";
import content from "../../../constants/content";
import { UploadContainer, UploadSection } from "./upload-components";
import Action from "../../../common-components/Action";
import Typography from "../../../common-components/Typography";
import { resetUploadModal, setUploadModal } from "../../../redux/actions/appUtils";
import useNotify from "../../../helper/hooks/useNotify";
import vmTheme from "../../../constants/theme";
import { getAudioMetaData } from "../../../helper/getAudioMetaData";

const DragUpload = () => {
    const editFileRef = useRef(null);
    const dispatch = useDispatch();
    const { success } = useNotify();
    const { prepareMedia, uploadResponse, isFailed } = useFileUpload();
    const theme = useSelector((state) => state.app.get("theme"));
    const [dragging, setDragging] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const userDetails = useSelector((store) => store.userDetails);
    const { userId } = userDetails;
    const appReducer = useSelector((state) => state.app);
    const uploadData = appReducer.get("upload").toJS();

    useEffect(() => {
        if (uploadResponse?.res?.id && !uploadData.isCancel) {
            dispatch(setUploadModal({
                show: false,
                assetId: uploadResponse?.res?.id,
                assetData: uploadResponse.res,
            }));
            success(content.FILE_UPLOADED);
        }
        if(isFailed){
            dispatch(resetUploadModal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadResponse, isFailed]);

    const onAction = useCallback(() => {
        editFileRef.current.click();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [editFileRef]);

    const processFile = (file) => {
        const blobSrc = URL.createObjectURL(file);
        getAudioMetaData(blobSrc);

        const payload = {
            active: 1,
            created: new Date().toISOString(),
            duration: 42,
            id: file.id,
            name: file.name,
            parent: "0",
            product: "vmaker",
            roottype: "file",
            source_type: "mp3",
            src: file.src,
            tags: "userupload",
            type: "AUDIO",
            user_id: userId,
            isBlob: true,
        };

        dispatch(setUploadModal({
            show: false,
            assetId: uploadResponse?.res?.id,
            assetData: payload,
        }));
    };

    const handleFileUpload = (e) => {
        const files = e.target.files[0];
        if (files) {
            prepareMedia(files);
            e.target.value = null;
            processFile(files);
        }
    };

    const onDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            prepareMedia(file);
            processFile(file);
        }
    }, []);

    const onDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }, []);

    const onDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    return (
        <UploadContainer
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            dragging={dragging}
        >
            <input
                type="file"
                ref={editFileRef}
                style={{ display: "none" }}
                accept={AppConfig.UPLOADS.SUPPORTED_AUDIO_TYPES}
                onChange={handleFileUpload}
            />
            <UploadSection>
                <img
                    src={dragging ? `${STATIC_PATH}vm-upload-color.svg` : `${STATIC_PATH}vm-uploads.svg`}
                    alt="upload"
                />
                <Typography
                    content={content.DRAG_AND_DROP}
                    color={vmTheme[theme].panelPrimaryColor}
                    font={font.semi_bold_16}
                    cursor="pointer"
                    padding="3px 0px"
                />
                <Typography
                    innerContent={`Supported file formats: .mp3, .wav, .aac (Max File Upload Size)`}
                    color={vmTheme[theme].sbSecondaryColor}
                    font={font.normal14_400}
                    padding="2px 0px"
                    width={"auto"}
                    align={"center"}
                    cursor="pointer"
                    enableTrim={false}
                />
                <Action
                    text={content.UPLOAD_AUDIO}
                    className="upload-btn-btn"
                    cursor={"pointer"}
                    width="376px"
                    height="48px"
                    borderRadius={"8px"}
                    enableTrim={true}
                    font={font.mediumMiniBase}
                    hoverColor={vmTheme[theme].liteHoverColor}
                    isLoading={isLoading}
                    background={vmTheme[theme].secondaryBorderColor}
                    onClick={onAction}
                />
            </UploadSection>
        </UploadContainer>
    );
};

export default DragUpload;
