/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BgContainer } from "../slider/timeline-slider-components";
import { changeAudioVolume } from "../../../redux/actions/timelineUtils";
import AudioContextMenu from "./audio-context-menu";
import VolumeTooltip from "./volume-tooltip";

let points = [];
let completeWidth = 0;
let audioStartPosition = 0;
export class AudioFadeController extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fadePoints: [],
            menuOptions: {},
            isShowMenu: false,
            draggingOptions: {}
        }
        this.svgBoxRef = React.createRef();
        this.svgRef = React.createRef();
        this.pathRef = React.createRef();
        this.menuRef = React.createRef();
        this.pathDragged = false;
    }

    componentDidMount() {
        this.handleUpdatePoints();
        document.addEventListener('mouseup', this.handleRemoveListeners);
    }

    componentDidUpdate(prevProps) {
        const { zoomFactor, item, width } = this.props;
        if ((prevProps.width !== width) ||
            (prevProps.zoomFactor !== zoomFactor) ||
            (prevProps.item.get("fadeDetails") !== item.get("fadeDetails"))) {
            this.handleUpdatePoints();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleRemoveListeners);
    }

    handleRemoveListeners = (e) => {
        const { item } = this.props;
        const svgElem = this.svgRef.current;
        if (svgElem) {
            svgElem.removeEventListener('mousemove', this.hadleMoveAudioAmp);
            svgElem.removeEventListener("mouseleave", this.hadleLeaveAudioAmp);
            svgElem.removeEventListener('mouseup', this.hadleLeaveAudioAmp);
            if (this.fadePointMoving === true) {
                this.handleUpdateFadePoints(e, item.get("id"), item.get("musicDuration"), this);
                this.setState({
                    fadePoints: points
                })
            }
            if (this.pathDragged === true) {
                this.handleUpdateFadePoints(e, item.get("id"), item.get("musicDuration"), this);
                this.setState({
                    fadePoints: points
                });
                this.pathDragged = false
            }
        }
        this.setState({
            draggingOptions: {},
            isShowTooltip: false
        });
        this.resetCursor();
    };

    handleUpdatePoints = () => {
        const { item, width, cursorHeight } = this.props;
        const fadeDetails = item.get("fadeDetails");
        const duration = item.get("musicDuration");
        if (fadeDetails && fadeDetails.size > 0) {
            const trackPlayTime = item.get("musicEnd") - item.get("musicStart");
            completeWidth = Math.round((duration / trackPlayTime) * width);
            const secondsPerPixel = duration / completeWidth;
            const pixelsPerSecond = 1 / secondsPerPixel;
            audioStartPosition = item.get("musicStart") * pixelsPerSecond;
            points = this.handleFadePoints(fadeDetails, duration, cursorHeight, completeWidth, item.get("id"));
            points = points.sort((a, b) => (parseFloat(a.xPerc) < parseFloat(b.xPerc)) ?
                -1 : ((parseFloat(a.xPerc) === parseFloat(b.xPerc)) && (parseFloat(a.yPerc) < parseFloat(b.yPerc))) ?
                    -1 : 1);
            this.setState({
                fadePoints: points
            }, () => {
                const svgElem = this.svgRef.current;
                const pathElem = this.pathRef.current;
                this.handleUpdateAmpPath(pathElem, svgElem, item.get("id"), completeWidth, audioStartPosition, width);
            })
        } else {
            const musicStart = item.get("musicStart");
            const musicEnd = item.get("musicEnd");
            const volume = item.get("volume");
            this.handleGenerateFadePoints({ musicStart, musicEnd, musicDuration: duration, id: item.get("id"), volume })
        }
    }

    handleFadePoints(fadeDetails, duration, svgHeight, completeWidth, id) {
        const pointsArr = [];
        if (fadeDetails) {
            const yMin = 0.25 * svgHeight
            const yMax = svgHeight * 0.9;
            const yRange = yMax - yMin;
            fadeDetails.forEach(point => {
                const time = parseFloat(point.get("t"))
                const amp = parseFloat(point.get("a"));
                const xPos = (time / duration) * completeWidth;
                const yPos = yMax - (amp * yRange);
                const xPerc = xPos / completeWidth;
                const yPerc = yPos / svgHeight;
                pointsArr.push({
                    id,
                    xPerc,
                    yPerc
                });
            });
        }
        return pointsArr;
    }

    handleUpdateAmpPath(pathElem, svgElem, id, completeWidth, audioStartPosition, width) {
        let newAmpPath;
        const svgHeight = svgElem.height.animVal.value;
        const svgWidth = svgElem.width.animVal.value;
        let pointsData = points.filter(point => point.id === id);
        pointsData = pointsData.sort((a, b) => (parseFloat(a.xPerc) < parseFloat(b.xPerc)) ?
            -1 : ((parseFloat(a.xPerc) === parseFloat(b.xPerc)) && (parseFloat(a.yPerc) < parseFloat(b.yPerc))) ?
                -1 : 1);
        let yDefault = width - 2;
        if (pointsData.length === 0) {
            newAmpPath = `M0 ${yDefault}`;
        } else {
            const yStart = parseFloat(pointsData[0].yPerc) * svgHeight;
            newAmpPath = `M0 ${yStart}`;
        }
        if (pointsData.length === 1) {
            yDefault = parseFloat(pointsData[0].yPerc) * svgHeight;
        }
        pointsData.forEach(point => {
            const xPos = (parseFloat(point.xPerc) * completeWidth) - parseFloat(audioStartPosition);
            const yPos = parseFloat(point.yPerc) * svgHeight;
            newAmpPath += ` L${xPos} ${yPos}`;
        });
        if (pointsData.length === 0) {
            newAmpPath += ` L${svgWidth} ${yDefault}`;
        } else {
            const yEnd = parseFloat(pointsData[pointsData.length - 1].yPerc) * svgHeight;
            newAmpPath += ` L${svgWidth} ${yEnd}`;
        }
        if (pointsData.length === 1) {
            yDefault = parseFloat(pointsData[0].yPerc) * svgHeight;
        }
        pathElem.setAttribute("d", newAmpPath);
    }

    handleListeners = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (this.pathDragged === false) {
            this.scaleClick(event);
        } else {
            this.pathDragged = false;
        }
        if (this.state.isShowMenu === true) {
            this.setState({
                isShowMenu: false,
                menuOptions: {},
            })
        } else {
            this.setState({
                menuOptions: {
                    x: event.clientX,
                    y: event.clientY,
                    eventType: event.eventType,
                    selectedIndex: -1,
                    options: [{
                        title: "Add Key",
                        actionName: "ADD",
                    }]
                },
                isShowMenu: true,
            });
        }
    };

    handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { item, playHead } = this.props;
        if (this.pathDragged === false) {
            this.scaleClick(e);
            if (playHead < item.get("playEnd")) {
                this.setState({
                    menuOptions: {
                        x: e.clientX,
                        y: e.clientY,
                        width: 97,
                        selectedIndex: -1,
                        options: [{
                            title: "Add Key",
                            actionName: "ADD",
                        }]
                    },
                    isShowMenu: true,
                })
            }
        } else {
            this.pathDragged = false;
        }
    };

    handleAdjustVolume = (fadeData) => {
        try {
            if (fadeData.length) {
                let maxVolume = Math.max(...fadeData.map(d => d.a));
                if (maxVolume > 1) {
                    maxVolume = 1;
                } else if (maxVolume < 0) {
                    maxVolume = 0;
                }
                maxVolume = parseFloat(maxVolume.toFixed(1));
                // Updating the volume and fadeDetails to redux.
                this.props.changeAudioVolume({
                    id: this.props.item.get("id"),
                    fadeDetails: fadeData,
                    volume: maxVolume,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    handleUpdateFadePoints = (e, id, duration) => {
        try {
            let updatedFadePoints = [];
            const pointsData = points.filter(point => point.id === id);
            pointsData.forEach(point => {
                const { xPerc, yPerc } = point;
                const time = parseFloat(xPerc) * parseFloat(duration);
                const amplitude = (0.9 - parseFloat(yPerc)) / (0.9 - 0.25);
                updatedFadePoints.push({
                    't': parseFloat(time.toFixed(1)),
                    'a': parseFloat(amplitude.toFixed(1))
                });
            });
            updatedFadePoints = updatedFadePoints.sort((a, b) => (parseFloat(a.t) < parseFloat(b.t)) ?
                -1 : ((parseFloat(a.t) === parseFloat(b.t)) && (parseFloat(a.a) < parseFloat(b.a))) ?
                    -1 : 1);
            this.handleAdjustVolume(updatedFadePoints);
        } catch (error) {
            console.error("Error in Updating Fade Points", error);
        }
    };

    handlePathMove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { item, width, cursorHeight } = this.props;
        const svgElem = this.svgRef.current;
        const pathElem = this.pathRef.current;
        if (svgElem && pathElem) {
            const temp = this;
            // eslint-disable-next-line no-inner-declarations
            function hadleMoveAudioAmp(e) {
                temp.fadePointMoving = false;
                temp.pathDragged = true;
                const x = e.offsetX / completeWidth;
                let pointsData = points;
                pointsData = pointsData.sort((a, b) => (parseFloat(a.xPerc) < parseFloat(b.xPerc)) ?
                    -1 : ((parseFloat(a.xPerc) === parseFloat(b.xPerc)) && (parseFloat(a.yPerc) < parseFloat(b.yPerc))) ?
                        -1 : 1);
                const closest = pointsData.reduce((a, b) => {
                    return Math.abs(b.xPerc - x) < Math.abs(a.xPerc - x) ? b : a;
                });
                const ind = pointsData.findIndex(d => d.xPerc === closest.xPerc);
                const currentXperc = e.offsetX / completeWidth;
                const bool = currentXperc < pointsData[ind].xPerc ? "prev" : "next";
                const yMin = cursorHeight * 0.25;
                const yMax = cursorHeight * 0.9;
                const cy = e.offsetY < yMin ? yMin : e.offsetY >= yMax ? yMax : e.offsetY;
                let amp = -1;
                if (bool === "prev") {
                    if (ind === 0) {
                        pointsData[ind].yPerc = parseFloat(cy) / cursorHeight;
                        amp = pointsData[ind].yPerc;
                    } else {
                        const currentCY = parseFloat(cy) / cursorHeight;
                        const moved = pointsData[ind].yPerc - currentCY;
                        const yMinPerc = yMin / cursorHeight;
                        const yMaxPerc = yMax / cursorHeight;
                        if ((pointsData[ind].yPerc - moved >= yMinPerc && pointsData[ind].yPerc - moved <= yMaxPerc) &&
                            (pointsData[ind - 1].yPerc - moved >= yMinPerc && pointsData[ind - 1].yPerc - moved <= yMaxPerc)) {
                            pointsData[ind].yPerc -= moved;
                            pointsData[ind - 1].yPerc = pointsData[ind - 1].yPerc - moved;
                            amp = pointsData[ind - 1].yPerc;
                        }
                    }
                } else if (bool === "next") {
                    if (ind === pointsData.length - 1) {
                        pointsData[ind].yPerc = parseFloat(cy) / cursorHeight;
                        amp = pointsData[ind].yPerc;
                    } else {
                        const currentCY = parseFloat(cy) / cursorHeight;
                        const moved = pointsData[ind].yPerc - currentCY;
                        const yMinPerc = yMin / cursorHeight;
                        const yMaxPerc = yMax / cursorHeight;
                        if ((pointsData[ind].yPerc - moved >= yMinPerc && pointsData[ind].yPerc - moved <= yMaxPerc) &&
                            (pointsData[ind + 1].yPerc - moved >= yMinPerc && pointsData[ind + 1].yPerc - moved <= yMaxPerc)) {
                            pointsData[ind].yPerc -= moved;
                            pointsData[ind + 1].yPerc = pointsData[ind + 1].yPerc - moved;
                            amp = pointsData[ind + 1].yPerc;
                        }
                    }
                }
                points = pointsData;

                temp.setState({
                    fadePoints: pointsData
                });
                temp.pathDragged = true;
                temp.handleUpdateAmpPath(pathElem, svgElem, item.get("id"), completeWidth, audioStartPosition, width)
                if (amp !== -1) {
                    temp.setState({
                        draggingOptions: {
                            x: e.clientX,
                            y: e.clientY,
                            amp: (0.9 - parseFloat(amp)) / (0.9 - 0.25)
                        },
                        isShowTooltip: true,
                        isShowMenu: false
                    })
                }
            }
            // eslint-disable-next-line no-inner-declarations
            function hadleLeaveAudioAmp(e) {
                temp.handleUpdateFadePoints(e, item.get("id"), item.get("musicDuration"), temp, -1)
                temp.setState({
                    draggingOptions: {},
                    isShowTooltip: false,
                })
            }
            svgElem.addEventListener('mousemove', hadleMoveAudioAmp);
            svgElem.addEventListener('mouseup', hadleLeaveAudioAmp);
            temp.hadleLeaveAudioAmp = hadleLeaveAudioAmp;
            temp.hadleMoveAudioAmp = hadleMoveAudioAmp;
        }
    };

    handleCircleMouseDown(e, id, ind, isFlag = false) {
        e.preventDefault();
        e.stopPropagation();
        const { item, width, cursorHeight } = this.props;
        const svgElem = this.svgRef.current;
        const temp = this;
        if (svgElem) {
            const elem = document.getElementById(id);
            // eslint-disable-next-line no-inner-declarations
            function hadleMoveAudioAmp(e) {
                temp.fadePointMoving = true;
                const yMin = cursorHeight * 0.25;
                const yMax = cursorHeight * 0.9;
                const xMin = 0;
                const xMax = width;
                const cx = e.offsetX < xMin ? xMin : e.offsetX >= xMax ? xMax - 1 : e.offsetX;
                const cy = e.offsetY < yMin ? yMin : e.offsetY >= yMax ? yMax : e.offsetY;
                elem.setAttribute("cx", cx);
                elem.setAttribute("cy", cy);
                elem.setAttribute("r", 6)
                const xPercNew = (parseFloat(cx) + parseFloat(audioStartPosition)) / completeWidth;
                const yPercNew = parseFloat(cy) / cursorHeight;
                points[ind].xPerc = xPercNew;
                points[ind].yPerc = yPercNew;
                const pathElem = temp.pathRef.current;
                temp.handleUpdateAmpPath(pathElem, svgElem, item.get("id"), completeWidth, audioStartPosition, width)
                temp.setState({
                    draggingOptions: {
                        x: e.clientX,
                        y: e.clientY,
                        amp: (0.9 - parseFloat(yPercNew)) / (0.9 - 0.25)
                    },
                    isShowTooltip: true,
                    isShowMenu: false
                })
            }
            // eslint-disable-next-line no-inner-declarations
            function hadleLeaveAudioAmp(e) {
                temp.fadePointMoving = false;
                temp.handleUpdateFadePoints(e, item.get("id"), item.get("musicDuration"), temp, ind);
                temp.setState({
                    fadePoints: points,
                    draggingOptions: {},
                    isShowTooltip: false,
                })
            }
            if (isFlag === true) {
                svgElem.removeEventListener('mousemove', hadleMoveAudioAmp);
                svgElem.removeEventListener("mouseleave", hadleLeaveAudioAmp);
                svgElem.removeEventListener('mouseup', hadleLeaveAudioAmp);
                return;
            }
            svgElem.addEventListener('mousemove', hadleMoveAudioAmp);
            svgElem.addEventListener('mouseup', hadleLeaveAudioAmp);
            temp.hadleLeaveAudioAmp = hadleLeaveAudioAmp;
            temp.hadleMoveAudioAmp = hadleMoveAudioAmp;
        }
    }

    handleCircleClick = (e, ind) => {
        e.preventDefault();
        e.stopPropagation();
        this.scaleClick(e);
        if (this.props.item.get("fadeDetails").size > 4) {
            this.setState({
                isShowMenu: true,
                menuOptions: {
                    x: e.clientX,
                    y: e.clientY,
                    eventType: e.type,
                    selectedIndex: ind,
                    options: [{
                        title: "Delete",
                        actionName: "DELETE",
                    }]
                },
            })
        }
    };

    handleCircleMove = (e, id, type) => {
        const elem = document.getElementById(id);
        if (elem) {
            if (type === "enter") {
                elem.setAttribute("r", 6)
            } else if (type === "leave") {
                elem.setAttribute("r", 4)
            }
        }
    };

    handleRemoveFadePoints = () => {
        try {
            if (this.state.menuOptions) {
                const ind = this.state.menuOptions.selectedIndex;
                if (ind !== undefined && ind !== -1 && this.props.item.get("fadeDetails").size > 4) {
                    const fadeDetails = this.props.item.get("fadeDetails").toJS();
                    fadeDetails.splice(ind, 1);
                    points.splice(ind, 1);
                    const updatedFadePoints = fadeDetails.sort((a, b) => (parseFloat(a.t) < parseFloat(b.t)) ?
                        -1 : ((parseFloat(a.t) === parseFloat(b.t)) && (parseFloat(a.a) < parseFloat(b.a))) ?
                            -1 : 1);
                    this.handleAdjustVolume(updatedFadePoints);
                    this.setState({
                        fadePoints: points,
                        isShowMenu: false,
                        menuOptions: {},
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    handleFadePoint = () => {
        try {
            const { item, playHead } = this.props;
            const svgElem = this.svgRef.current;
            const pathElem = this.pathRef.current;
            if (pathElem && svgElem) {
                const dVal = pathElem.getAttribute('d');
                const svgWidth = svgElem.width.animVal.value;
                const svgHeight = svgElem.height.animVal.value;
                const duration = item.get("musicDuration");
                const trackPlayTime = item.get("musicEnd") - item.get("musicStart");
                const completeWidth = Math.round((duration / trackPlayTime) * svgWidth);
                const secondsPerPixel = duration / completeWidth;
                const pixelsPerSecond = 1 / secondsPerPixel;
                const playHeadTimeWrtTrack =
                    (parseFloat(playHead) - parseFloat(item.get("playStart"))) + parseFloat(item.get("musicStart"));
                const audioStartPosition = item.get("musicStart") * pixelsPerSecond;
                const playHeadXPos = (playHeadTimeWrtTrack * pixelsPerSecond) - audioStartPosition;
                const dArray = dVal.split(" ");
                let xPrev;
                let yPrev;
                let xNext;
                let yNext;
                for (let i = 0; i < dArray.length; i += 2) {
                    const elem = dArray[i];
                    const xVal = elem.slice(1);
                    if (parseFloat(xVal) > playHeadXPos) {
                        xPrev = dArray[i - 2].slice(1); yPrev = dArray[i - 1];
                        xNext = xVal; yNext = dArray[i + 1];
                        break;
                    }
                }
                const stepY = (parseFloat(yNext) - parseFloat(yPrev)) / (parseFloat(xNext) - parseFloat(xPrev));
                const xDiff = parseFloat(playHeadXPos) - parseFloat(xPrev);
                const playHeadYPos = parseFloat(yPrev) + (stepY * xDiff);
                const xPerc = (parseFloat(playHeadXPos) + parseFloat(audioStartPosition)) / completeWidth;
                const yPerc = parseFloat(playHeadYPos) / svgHeight;
                const time = parseFloat(xPerc) * parseFloat(duration);
                const amplitude = (0.9 - parseFloat(yPerc)) / (0.9 - 0.25);
                points.push({
                    id: item.get("id"),
                    xPerc,
                    yPerc
                });
                this.setState({
                    fadePoints: points,
                    isShowMenu: false,
                    menuOptions: {},
                });
                const fadeDetails = item.get("fadeDetails").toJS();
                fadeDetails.push({
                    't': parseFloat(time.toFixed(1)),
                    'a': parseFloat(amplitude.toFixed(1))
                });
                const updatedFadePoints = fadeDetails.sort((a, b) => (parseFloat(a.t) < parseFloat(b.t)) ?
                    -1 : ((parseFloat(a.t) === parseFloat(b.t)) && (parseFloat(a.a) < parseFloat(b.a))) ?
                        -1 : 1);
                this.handleAdjustVolume(updatedFadePoints);
            }
        } catch (error) {
            console.error(error)
        }
    };

    handleMenuOptions = (action) => {
        if (action === "ADD") {
            this.handleFadePoint();
        } else if (action === "DELETE") {
            this.handleRemoveFadePoints();
        }
    };

    scaleClick = (event) => {
        const { slider, selectSlider } = this.props;
        selectSlider({
            event,
            sliderId: slider.id,
            canInitiateDrag: slider.isDraggable,
        });
    };

    resetCursor = () => {
        this.svgBoxRef.current.style.cursor = "grab";
    };

    render() {
        const { item, width, cursorHeight } = this.props;
        return (
            <BgContainer ref={this.svgBoxRef}>
                <svg
                    ref={this.svgRef}
                    style={{ position: "absolute", display: "block", zIndex: 1 }}
                    width={width || 0}
                    height={cursorHeight}
                    onClick={(e) => {
                        this.handleListeners(e);
                    }}
                >
                    {
                        <>
                            <path
                                ref={this.pathRef}
                                d={``}
                                stroke="#FFFFFF"
                                strokeWidth={1}
                                fill="none"
                                cursor="ns-resize"
                                onClick={(e) =>
                                    this.handleMouseDown(e)
                                }
                                onMouseDown={(e) => {
                                    this.handlePathMove(e);
                                    this.svgBoxRef.current.style.cursor = "ns-resize";
                                }}
                            />
                            {this.state.fadePoints.length
                                ? this.state.fadePoints.map((point, ind) => {
                                    const xPos = parseFloat(point.xPerc) * completeWidth - parseFloat(audioStartPosition);
                                    const yPos = parseFloat(point.yPerc) * cursorHeight;
                                    return (!Number.isNaN(xPos) && !Number.isNaN(yPos) &&
                                        (<circle
                                            key={`key_point_${item.get("id")}_${point.xPerc}_${point.yPerc}_${ind}`}
                                            id={`point_${item.get("id")}_${ind}`}
                                            cx={xPos}
                                            cy={yPos}
                                            r={4}
                                            stroke={"#424158"}
                                            fill={"#FFFFFF"}
                                            cursor={"pointer"}
                                            onMouseMove={(e) => this.handleCircleMove(e, `point_${item.get("id")}_${ind}`, "enter")
                                            }
                                            onMouseLeave={(e) => this.handleCircleMove(e, `point_${item.get("id")}_${ind}`, "leave")
                                            }
                                            onMouseDown={(e) => {
                                                this.handleCircleMouseDown(e, `point_${item.get("id")}_${ind}`, ind);
                                                this.svgBoxRef.current.style.cursor = "pointer";
                                            }}
                                            onClick={(e) => {
                                                this.handleCircleClick(e, ind);
                                            }}
                                        />
                                        )
                                    );
                                })
                                : null}
                        </>
                    }
                </svg>
                {this.state.isShowMenu &&
                    <AudioContextMenu
                        ref={this.menuRef}
                        menuOptions={this.state.menuOptions}
                        onClick={this.handleMenuOptions}
                        onClose={() => this.setState({ isShowMenu: false })}
                    />}
                {this.state.isShowTooltip ?
                    <VolumeTooltip options={this.state.draggingOptions} /> : null}
            </BgContainer>
        );
    }
}

AudioFadeController.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    cursorHeight: PropTypes.string,
    selectSlider: PropTypes.func,
    slider: PropTypes.object,
    playHead: PropTypes.number,
    changeAudioVolume: PropTypes.func,
    zoomFactor: PropTypes.number,
}

const mapStateToProps = (state) => {
    return {
        theme: state.app.get("theme"),
        playHead: state.app.get("playhead"),
        zoomFactor: state.app.get("zoomFactor"),
    };
};

const mapDispatchToProps = (dispatch) => ({
    changeAudioVolume: (data) => dispatch(changeAudioVolume(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioFadeController);
