/// <reference path="./timeline-types.js" />
/* eslint-disable no-continue, operator-assignment, no-restricted-syntax, import/no-cycle */

import { Set as ImmutableSet, List, Map, OrderedMap, fromJS } from "immutable";
import {
  TIMELINE_HEIGHT,
  RULER_OPTIONS,
  SLIDER_TYPES,
  TRACK_TYPES,
  TRACK_OPTIONS,
  SLIDER_GAP_TYPES,
  TIMELINE_MODES,
} from "./timeline-constants";
import { PANEL, PROJECT_CONTAINERS } from "../../constants";

export const memoize = (expensiveFunc) => {
  const cache = {
    diffArr: undefined,
    result: undefined,
  };
  const isEqual = (newArgs, oldArgs) =>
    newArgs.every((arg, index) => arg === oldArgs[index]);

  return {
    executor: (diffArr, ...executorArgs) => {
      if (!diffArr || !cache.diffArr || !isEqual(diffArr, cache.diffArr)) {
        cache.diffArr = diffArr;
        cache.result = expensiveFunc(...executorArgs);
      }
      return cache.result;
    },
    clear: () => {
      cache.diffArr = undefined;
      cache.result = undefined;
    },
  };
};

/** @todo this is a temporary method to generate id for new item on track change */
const GlobalIdsArray = [];
export const randomString = (prefix) => {
  let id = `${
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substring(0, 5) + new Date().getTime().toString()
  }`;
  if (prefix) {
    id = prefix + id;
  }
  if (GlobalIdsArray.includes(id)) {
    return randomString(prefix);
  }
  GlobalIdsArray.push(id);
  return id;
};

export const isImageOnly = (type, subType) => {
  return (
    type === "IMG" ||
    type === "UPIMAGE" ||
    type === "STOCKIMG" ||
    ((type === "PEXELS" || type === "PIXABAY") && subType === "IMAGE") ||
    type === "UNSPLASH"
  );
};

export const isVideoOnly = (type, subType) => {
  return (
    type === "STOCKVID" ||
    type === "UPVIDEO" ||
    type === "VIDEO" ||
    ((type === "PEXELS" || type === "PIXABAY") && subType === "VIDEO") ||
    subType === "VIDEO" ||
    subType === "OBGVID"
  );
};

export const isUpVideo = (type) => type === "UPVIDEO";

export const isUpAudio = (type, subType) => type === "UPVIDEO" || subType === "UPAUDIO";

export const isCustomBG = (type, subType) => {
  return (
    type === "BG" &&
    subType !== "OBGIMG" &&
    !isImageOnly(type, subType) &&
    !isVideoOnly(type, subType)
  );
};

export const isAudioOnly = (type) => type === "MUSIC" || type === "EAB" || type === "SSM";

export const isTextOnly = (type) => type === "TEXT";

export const isGiphyOnly = (type, subType) => subType === "GIPHY";

export const isPropertyOnly = (type) => type === "PROP" || type === "SHAPE" || type === "FRAME";

export const isUpImageSVG = (item) => {
  return (
    item &&
    item.get("subType") === "UPIMAGE" &&
    item.get("sourceType") === "svg" &&
    item.get("colors")
    // && item.get("colors").size > 0
  );
};

/**
 * util func to keep keep a number within range
 * @param {number} val
 * @param {number} min
 * @param {number} max
 */
export const clamp = (val, min, max) => {
  let newVal = val;
  if (val <= min) {
    newVal = min;
  } else if (val >= max) {
    newVal = max;
  }
  return newVal;
};

/**
 * checks whether given point is within given plot
 * @param {object} params
 * @param {Plot} params.plot
 * @param {Point} params.point
 * @param {"x" | "y" | undefined} params.specificAxis
 */
export const isPointWithinPlot = (params = {}) => {
  const { plot, point, specificAxis } = params;

  let result = false;
  if (specificAxis === "x") {
    result = point.x >= plot.x && point.x <= plot.x + plot.width;
  } else if (specificAxis === "y") {
    result = point.y >= plot.y && point.y <= plot.y + plot.height;
  } else {
    result =
      point.x >= plot.x &&
      point.x <= plot.x + plot.width &&
      point.y >= plot.y &&
      point.y <= plot.y + plot.height;
  }

  return result;
};

/**
 * Gets mouse position relative to screen.
 * Though this function can handle touch, mouse and pointer events, always prefer pointer events as
 * same pointer event will be triggered regardless of input types.
 * @param {PointerEvent | TouchEvent | React.TouchEvent | React.PointerEvent} event mouse/touch event
 * @param {MouseClientPosition} clientOffset offset to add to mouse position
 * @returns {MouseClientPosition} position relative to window
 */
export const getMouseClientPosition = (event, clientOffset) => {
  let clientX;
  let clientY;

  if (event.touches && event.touches.length > 0) {
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  } else if (event.changedTouches && event.changedTouches.length > 0) {
    clientX = event.changedTouches[0].clientX;
    clientY = event.changedTouches[0].clientY;
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }

  if (clientOffset) {
    clientX = clientX + clientOffset.x;
    clientY = clientY + clientOffset.y;
  }

  return { x: clientX, y: clientY };
};

/**
 * Calculates position and dimension of timeline. Can be used to set initial state as well as to handle resize
 * @param {CalculateTimelinePlotParams} params
 * @returns {TimelinePlot}
 */
export const calculateTimelinePlot = (params = {}) => {
  const {
    currentTimelinePlot,
    sideBarMenuWidth = 0,
    isExpand,
    isMinimize,
  } = params;
  let { mouseMovedBy } = params;
  const maximumHeight =
    (window.innerHeight * TIMELINE_HEIGHT.maximumPercent) / 100;
  let initialHeight =
    (window.innerHeight * TIMELINE_HEIGHT.initialPercent) / 100;
  if (Number.isFinite(TIMELINE_HEIGHT.initialPx)) {
    initialHeight = TIMELINE_HEIGHT.initialPx;
  }
  if (initialHeight > maximumHeight) {
    initialHeight = maximumHeight;
  }
  if (initialHeight < TIMELINE_HEIGHT.minimum) {
    initialHeight = TIMELINE_HEIGHT.minimum;
  }

  const plot = {
    x: sideBarMenuWidth,
    y: 0,
    width: window.innerWidth - sideBarMenuWidth,
    height: initialHeight,
    ...currentTimelinePlot,
  };

  if (isExpand) {
    plot.height = initialHeight;
  } else if (isMinimize) {
    plot.height = TIMELINE_HEIGHT.minimum;
  }

  if (currentTimelinePlot) {
    const maximumHeight =
      (window.innerHeight * TIMELINE_HEIGHT.maximumPercent) / 100;
    if (!mouseMovedBy) {
      // consider mouse is not moved. (e.g. to handle window resize)
      mouseMovedBy = { x: 0, y: 0 };
    }

    plot.width = window.innerWidth - plot.x;
    plot.height -= mouseMovedBy.y;
    if (plot.height > maximumHeight) {
      plot.height = maximumHeight;
    }
    if (plot.height < TIMELINE_HEIGHT.minimum) {
      plot.height = TIMELINE_HEIGHT.minimum;
    }
  }

  plot.y = window.innerHeight - plot.height;
  return plot;
};

/**
 * @param {object} params
 * @param {{ start: number, end: number }} params.fromRange original range
 * @param {{ start: number, end: number }} params.toRange original range
 * @param {{ start: number, end: number }} params.num number in original range
 * @returns number within required range
 */
export const scaleWithinRange = (params = {}) => {
  const { fromRange, toRange, num } = params;

  const percentInRange =
    (num - fromRange.start) / (fromRange.end - fromRange.start);
  return (toRange.end - toRange.start) * percentInRange + toRange.start;
};

const findMaxScaleDuration = (duration) => {
  let requiredMaxDuration = RULER_OPTIONS.timeScale.maxScaleDuration.find(
    (m) => {
      const { min, max } = m;
      let found = false;

      if (min !== null && max !== null && min <= duration && duration <= max) {
        found = true;
      } else if (min === null && max !== null && duration <= max) {
        found = true;
      } else if (min !== null && max === null && min <= duration) {
        found = true;
      }

      return found;
    }
  );

  if (requiredMaxDuration) {
    requiredMaxDuration = requiredMaxDuration.duration;
  } else {
    requiredMaxDuration = 1; // in seconds
  }

  return requiredMaxDuration;
};

/**
 * calculates stepper distance
 * @param {CalculateStepperSizeParams} params
 * @returns {StepperState}
 */
export const calculateStepperSize = (params = {}) => {
  const { timelinePlot, timeScale, prevDisplayDuration } = params;
  let { duration, excessDuration } = params;

  duration = parseFloat(duration.toFixed(1)); // rounding error will be minimal if this method is used consistently throughout project

  let displayDuration = duration;
  if (timeScale <= 0) {
    const m = RULER_OPTIONS.timeScale.minScaleOffsetPercent;
    displayDuration = duration + (duration * (1 - m / 100) * -timeScale) / 100;
  } else {
    const maxScaleDuration = findMaxScaleDuration(duration);
    const zoomedInDur =
      duration < maxScaleDuration ? duration : maxScaleDuration;
    displayDuration = duration + ((zoomedInDur - duration) * timeScale) / 100;
  }

  if (Number.isFinite(prevDisplayDuration)) {
    displayDuration = prevDisplayDuration;
  }

  const totalStepSize =
    timelinePlot.width -
    (RULER_OPTIONS.paddingLeft + RULER_OPTIONS.paddingRight);
  let displaySteps = Math.round(displayDuration / RULER_OPTIONS.interval);
  if (displaySteps <= 0) {
    displaySteps = 1;
  }
  const stepSizePx = totalStepSize / displaySteps;

  let steps = 0;
  if (timeScale < 0) {
    let stepsDuration = displayDuration;
    if (excessDuration !== undefined) {
      stepsDuration = displayDuration + excessDuration;
    } else {
      stepsDuration = duration * 2;
    }
    steps = Math.ceil(stepsDuration / RULER_OPTIONS.interval);
    excessDuration = stepsDuration - duration;
  } else {
    let stepsDuration = duration;
    if (excessDuration !== undefined) {
      stepsDuration = duration + excessDuration;
    } else {
      excessDuration = duration;
      stepsDuration = duration + excessDuration;
    }
    steps = Math.ceil(stepsDuration / RULER_OPTIONS.interval);
  }

  return {
    steps,
    excessDuration,
    duration,
    stepSizePx,
    timeScale,
    displayDuration,
    videoExcessSteps: 0,
  };
};

/**
 * @param {Plot} params
 * @returns path string for given bounding box
 */
export const plotToPath = (params = {}) => {
  const { x, y, width, height } = params;
  return `M${x},${y} L${x + width},${y} L${x + width},${y + height} L${x},${
    y + height
  } L${x},${y}`;
};

/**
 * converts seconds to timestamp
 * NOTE: rounds seconds internally
 * @param {object} params
 * @param {number} params.seconds
 * @param {"ruler" | "playercontrol" | "indicator" | "subtitle-edit"} params.mode
 * @returns {string | { hrs: number, mins: number, secs: number }} based on mode, returns either text or object
 */
export const secondsToTimestamp = (params = {}) => {
  const { seconds = 0, mode = "ruler" } = params;

  let msRounding = 1;
  if (mode === "subtitle-edit") {
    msRounding = 2;
  }

  let hrs = Math.floor(seconds / 3600);
  let mins = Math.floor((seconds % 3600) / 60);
  let secs = seconds % 60;
  secs = parseFloat(secs.toFixed(msRounding));

  let timestamp;

  if (mode === "ruler") {
    let text = `${secs}s`;
    if (mins > 0) {
      text = `${mins}m ${text}`;
    }
    if (hrs > 0) {
      text = `${hrs}h ${text}`;
    }
    timestamp = text;
  } else if (mode === "playercontrol") {
    hrs = hrs < 10 ? `0${hrs}` : hrs.toString();
    mins = mins < 10 ? `0${mins}` : mins.toString();
    secs = secs < 10 ? `0${secs}` : secs.toString();
    timestamp = `${hrs}:${mins}:${secs}`;
  } else if (mode === "indicator") {
    let text = secs < 10 ? `0${secs}` : secs.toString();
    text = `${mins}:${text}`;
    if (hrs > 0) {
      text = `${hrs}:${text}`;
    }
    timestamp = text;
  } else if (mode === "subtitle-edit") {
    hrs = hrs.toString();
    mins = mins < 10 ? `0${mins}` : mins.toString();
    secs = secs < 10 ? `0${secs.toFixed(msRounding)}` : secs.toFixed(msRounding);
    timestamp = `${hrs}:${mins}:${secs}`;
  } else {
    timestamp = { hrs, mins, secs };
  }

  return timestamp;
};

/**
 * calculates step for given second using stepper state
 * @param {object} params
 * @param {number} params.seconds
 * @param {StepperState} params.stepper
 */
export const secondsToStep = (params = {}) => {
  const { seconds, stepper } = params;

  let step = seconds / RULER_OPTIONS.interval;
  if (Math.ceil(step) - step < RULER_OPTIONS.stepCorrection) {
    step = Math.ceil(step);
  } else {
    step = Math.floor(step);
  }
  return {
    x: RULER_OPTIONS.paddingLeft + step * stepper.stepSizePx,
    step,
  };
};

/**
 * To convert the duration from sec to mm:ss format
 * @param {*} duration
 * @returns mm.ss format
 */
export const secondsToMinute = (duration) => {
  const minutes = Math.floor(duration / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.round(duration % 60)
    .toString()
    .padStart(2, "0");
  return `${minutes}.${seconds}`;
};

/**
 * Function to create new thumb template for item
 * @param {CreateItemThumbParams} params
 */
const createItemThumb = (params = {}) => {
  const { item, thumbId, timeId, stepper, sliderY } = params;
  const thumbStep = secondsToStep({ seconds: item.get(timeId), stepper });

  /** @type {ThumbPosition} */
  const templatePosition = {
    sliderId: item.get("id"),
    step: thumbStep.step,
    thumbId,
    trackIndex: item.get("track"),
    trackType: TRACK_TYPES.OBJECT,
    x: thumbStep.x,
    y: sliderY,
  };
  /** @type {Thumb} */
  const templateThumb = {
    id: thumbId,
    isDragging: false,
    position: templatePosition,
    sliderId: item.get("id"),
  };

  if (isVideoOnly(item.get("type"), item.get("subType"))) {
    const speed = Number.isFinite(item.get("speed")) ? item.get("speed"): 1;
    if (thumbId === "enterStart") {
      const videoStartThumbStep = secondsToStep({
        seconds: item.get("videoStart") / speed,
        stepper,
      });
      templateThumb.mediaMin = {
        ...templateThumb.position,
        step: templateThumb.position.step - videoStartThumbStep.step,
        x:
          RULER_OPTIONS.paddingLeft +
          (templateThumb.position.x - videoStartThumbStep.x),
      };
    } else if (thumbId === "exitEnd") {
      const videoEndThumbStep = secondsToStep({
        seconds: (item.get("videoDuration") - item.get("videoEnd"))/speed,
        stepper,
      });

      templateThumb.mediaMax = {
        ...templateThumb.position,
        step: templateThumb.position.step + videoEndThumbStep.step,
        x: templateThumb.position.x + videoEndThumbStep.x,
      };
    }
  }

  return templateThumb;
};

/**
 * Function to create new thumb template for video
 * @param {CreateVideoThumbParams} params
 */
const createVideoTrackThumbs = (params = {}) => {
  const { video, stepper, sliderY } = params;

  const enterStartThumbStep = secondsToStep({
    seconds: video.get("playStart"),
    stepper,
  });
  const exitEndThumbStep = secondsToStep({
    seconds: video.get("playEnd"),
    stepper,
  });
  const videoStartThumbStep = secondsToStep({
    seconds: video.get("videoStart"),
    stepper,
  });

  /** @type {Thumb} */
  const enterStartThumb = {
    id: "enterStart",
    isDragging: false,
    position: {
      sliderId: video.get("id"),
      step: enterStartThumbStep.step,
      thumbId: "enterStart",
      trackIndex: 0, // only one track for video
      trackType: TRACK_TYPES.VIDEO,
      x: enterStartThumbStep.x,
      y: sliderY,
    },
    sliderId: video.get("id"),
  };
  /** @type {Thumb} */
  const exitEndThumb = {
    id: "exitEnd",
    isDragging: false,
    position: {
      sliderId: video.get("id"),
      step: exitEndThumbStep.step,
      thumbId: "exitEnd",
      trackIndex: 0, // only one track for video
      trackType: TRACK_TYPES.VIDEO,
      x: exitEndThumbStep.x,
      y: sliderY,
    },
    sliderId: video.get("id"),
  };

  enterStartThumb.mediaMin = {
    ...enterStartThumb.position,
    step: enterStartThumb.position.step - videoStartThumbStep.step,
    x:
      RULER_OPTIONS.paddingLeft +
      (enterStartThumb.position.x - videoStartThumbStep.x),
  };
  if (isVideoOnly(video.get("type"), video.get("subType"))) {
    const videoEndThumbStep = secondsToStep({
      seconds: video.get("videoDuration") - video.get("videoEnd"),
      stepper,
    });
    exitEndThumb.mediaMax = {
      ...exitEndThumb.position,
      step: exitEndThumb.position.step + videoEndThumbStep.step,
      x: exitEndThumb.position.x + videoEndThumbStep.x,
    };
  } else {
    let maxDuration = TRACK_OPTIONS[TRACK_TYPES.VIDEO].nonVideoMaxDuration;
    if (maxDuration < video.get("videoDuration")) {
      // if current config mismatches with old project
      maxDuration = video.get("videoDuration");
    }
    const videoEndThumbStep = secondsToStep({
      seconds: maxDuration - video.get("videoEnd"),
      stepper,
    });
    exitEndThumb.mediaMax = {
      ...exitEndThumb.position,
      step: exitEndThumb.position.step + videoEndThumbStep.step,
      x: exitEndThumb.position.x + videoEndThumbStep.x,
    };
  }

  return { enterStartThumb, exitEndThumb };
};

/**
 * Function to create thumbs for audio
 * @param {CreateAudioThumbParams} params
 */
const createAudioThumbs = (params = {}) => {
  const { audio, stepper, sliderY } = params;
  const speed = Number.isFinite(audio.get("speed")) ? audio.get("speed"): 1;

  const enterStartThumbStep = secondsToStep({
    seconds: audio.get("playStart"),
    stepper,
  });
  const exitEndThumbStep = secondsToStep({
    seconds: audio.get("playEnd"),
    stepper,
  });
  const musicStartThumbStep = secondsToStep({
    seconds: audio.get("musicStart") / speed,
    stepper,
  });
  const musicEndThumbStep = secondsToStep({
    seconds: (audio.get("musicDuration") - audio.get("musicEnd")) /speed,
    stepper,
  });

  /** @type {Thumb} */
  const enterStartThumb = {
    id: "enterStart",
    isDragging: false,
    position: {
      sliderId: audio.get("id"),
      step: enterStartThumbStep.step,
      thumbId: "enterStart",
      trackIndex: audio.get("track"),
      trackType: TRACK_TYPES.AUDIO,
      x: enterStartThumbStep.x,
      y: sliderY,
    },
    sliderId: audio.get("id"),
  };
  /** @type {Thumb} */
  const exitEndThumb = {
    id: "exitEnd",
    isDragging: false,
    position: {
      sliderId: audio.get("id"),
      step: exitEndThumbStep.step,
      thumbId: "exitEnd",
      trackIndex: audio.get("track"),
      trackType: TRACK_TYPES.AUDIO,
      x: exitEndThumbStep.x,
      y: sliderY,
    },
    sliderId: audio.get("id"),
  };

  enterStartThumb.mediaMin = {
    ...enterStartThumb.position,
    step: enterStartThumb.position.step - musicStartThumbStep.step,
    x:
      RULER_OPTIONS.paddingLeft +
      (enterStartThumb.position.x - musicStartThumbStep.x),
  };
  exitEndThumb.mediaMax = {
    ...exitEndThumb.position,
    step: exitEndThumb.position.step + musicEndThumbStep.step,
    x: exitEndThumb.position.x + musicEndThumbStep.x,
  };

  return { enterStartThumb, exitEndThumb };
};

/**
 * Function to create thumbs for subtitle
 * @param {CreateSubtitleThumbParams} params
 */
const createSubtitleThumbs = (params = {}) => {
  const { stepper, subtitleId, subtitleItem, track } = params;

  const subtitleStartStep = secondsToStep({
    seconds: subtitleItem.get("enterStart"),
    stepper,
  });
  const subtitleEndStep = secondsToStep({
    seconds: subtitleItem.get("exitEnd"),
    stepper,
  });

  /** @type {Thumb} */
  const enterStartThumb = {
    id: "enterStart",
    isDragging: false,
    position: {
      sliderId: subtitleId.timelineId,
      step: subtitleStartStep.step,
      thumbId: "enterStart",
      trackIndex: track.trackIndex,
      trackType: track.trackType,
      x: subtitleStartStep.x,
      y: track.subtitlePlot.y,
    },
    sliderId: subtitleId.timelineId,
  };
  const exitEndThumb = {
    id: "exitEnd",
    isDragging: false,
    position: {
      sliderId: subtitleId.timelineId,
      step: subtitleEndStep.step,
      thumbId: "exitEnd",
      trackIndex: track.trackIndex,
      trackType: track.trackType,
      x: subtitleEndStep.x,
      y: track.subtitlePlot.y,
    },
    sliderId: subtitleId.timelineId,
  };

  return { enterStartThumb, exitEndThumb };
};

/**
 * Function to create thumbs for subtitle
 * @param {CreateMiniSubtitleThumbParams} params
 */
const createMiniSubtitleThumbs = (params = {}) => {
  const { stepper, track, id, enterStart, exitEnd } = params;

  const subtitleStartStep = secondsToStep({
    seconds: enterStart,
    stepper,
  });
  const subtitleEndStep = secondsToStep({
    seconds: exitEnd,
    stepper,
  });

  /** @type {Thumb} */
  const enterStartThumb = {
    id: "enterStart",
    isDragging: false,
    position: {
      sliderId: id,
      step: subtitleStartStep.step,
      thumbId: "enterStart",
      trackIndex: track.trackIndex,
      trackType: track.trackType,
      x: subtitleStartStep.x,
      y: track.subtitlePlot.y,
    },
    sliderId: id,
  };
  /** @type {Thumb} */
  const exitEndThumb = {
    id: "exitEnd",
    isDragging: false,
    position: {
      sliderId: id,
      step: subtitleEndStep.step,
      thumbId: "exitEnd",
      trackIndex: track.trackIndex,
      trackType: track.trackType,
      x: subtitleEndStep.x,
      y: track.subtitlePlot.y,
    },
    sliderId: id,
  };

  return { enterStartThumb, exitEndThumb };
};

/**
 * function to find position difference between two thums
 * @param {GetRelativeThumbPositionParams} params
 */
const getRelativeThumbPosition = (params = {}) => {
  const { thumb1, thumb2 } = params;

  const stepDiff = thumb1.position.step - thumb2.position.step;
  const xDiff = thumb1.position.x - thumb2.position.x;

  return { step: stepDiff, x: xDiff };
};

/**
 * Function to update minmax positions of thumbs.
 * This function should be used whenever a thumb's position is changed as a thumb might decide how far other thumbs can move
 * @param {UpdateThumbLimitsParams} params
 * @returns {UpdateThumbLimitsReturn}
 */
const updateThumbLimits = (params = {}) => {
  const {
    enterEndThumb,
    enterStartThumb,
    exitEndThumb,
    exitStartThumb,
    stepper,
    sliders,
  } = params;

  const MIN_STEP = 1;
  const updatedEnterStartThumb = { ...enterStartThumb };
  const updatedExitEndThumb = { ...exitEndThumb };
  let updatedEnterEndThumb;
  let updatedExitStartThumb;
  if (enterEndThumb) {
    updatedEnterEndThumb = { ...enterEndThumb };
  }
  if (exitStartThumb) {
    updatedExitStartThumb = { ...exitStartThumb };
  }

  if (updatedEnterEndThumb && !updatedExitStartThumb) {
    // only enterEnd
    const maxPositionDiff = getRelativeThumbPosition({
      stepper,
      thumb1: updatedExitEndThumb,
      thumb2: updatedEnterEndThumb,
    });
    updatedEnterStartThumb.max = {
      // to stop moving enterStart when enterEnd thumb collides with exitEnd
      ...updatedEnterStartThumb.position,
      step: updatedEnterStartThumb.position.step + maxPositionDiff.step,
      x: updatedEnterStartThumb.position.x + maxPositionDiff.x,
    };
    updatedEnterEndThumb.min = {
      ...updatedEnterStartThumb.position,
      // adding steps so that we can have minimum interval between thumbs
      step: updatedEnterStartThumb.position.step + MIN_STEP,
      x: (updatedEnterStartThumb.position.step + MIN_STEP) * stepper.stepSizePx,
    };
    updatedEnterEndThumb.max = updatedExitEndThumb.position;
    updatedExitEndThumb.min = updatedEnterEndThumb.position;
  } else if (!updatedEnterEndThumb && updatedExitStartThumb) {
    // only exitStart
    updatedEnterStartThumb.max = updatedExitStartThumb.position;
    updatedExitStartThumb.min = updatedEnterStartThumb.position;
    updatedExitStartThumb.max = {
      ...updatedExitEndThumb.position,
      // removing steps so that we can have minimum interval between thumbs
      step: updatedExitEndThumb.position.step - MIN_STEP,
      x: (updatedExitEndThumb.position.step - MIN_STEP) * stepper.stepSizePx,
    };
    const minPositionDiff = getRelativeThumbPosition({
      stepper,
      thumb1: updatedExitStartThumb,
      thumb2: updatedEnterStartThumb,
    });
    updatedExitEndThumb.min = {
      // to stop moving exitEnd when exitStart thumb collides with enterStart
      ...updatedExitEndThumb.position,
      step: updatedExitEndThumb.position.step - minPositionDiff.step,
      x: updatedExitEndThumb.position.x - minPositionDiff.x,
    };
  } else if (updatedEnterEndThumb && updatedExitStartThumb) {
    // both enterEnd and exitStart
    const positionDiff = getRelativeThumbPosition({
      stepper,
      thumb1: updatedExitStartThumb,
      thumb2: updatedEnterEndThumb,
    });
    updatedEnterStartThumb.max = {
      // to stop moving enterStart when enterEnd thumb collides with exitStart
      ...updatedEnterStartThumb.position,
      step: updatedEnterStartThumb.position.step + positionDiff.step,
      x: updatedEnterStartThumb.position.x + positionDiff.x,
    };
    updatedEnterEndThumb.min = {
      ...updatedEnterStartThumb.position,
      // adding steps so that we can have minimum interval thumbs
      step: updatedEnterStartThumb.position.step + MIN_STEP,
      x: (updatedEnterStartThumb.position.step + MIN_STEP) * stepper.stepSizePx,
    };
    updatedEnterEndThumb.max = updatedExitStartThumb.position;
    updatedExitStartThumb.min = updatedEnterEndThumb.position;
    updatedExitStartThumb.max = {
      ...updatedExitEndThumb.position,
      // removing steps so that we can have minimum interval between thumbs
      step: updatedExitEndThumb.position.step - MIN_STEP,
      x: (updatedExitEndThumb.position.step - MIN_STEP) * stepper.stepSizePx,
    };
    updatedExitEndThumb.min = {
      // to stop moving exitEnd when exitStart thumb collides with enterEnd
      ...updatedExitEndThumb.position,
      step: updatedExitEndThumb.position.step - positionDiff.step,
      x: updatedExitEndThumb.position.x - positionDiff.x,
    };
  } else if (!updatedEnterEndThumb && !updatedExitStartThumb) {
    // no enterEnd and exitStart
    updatedEnterStartThumb.max = {
      ...updatedExitEndThumb.position,
      // removing steps so that we can have minimum interval between thumbs
      step: updatedExitEndThumb.position.step - MIN_STEP,
      x: (updatedExitEndThumb.position.step - MIN_STEP) * stepper.stepSizePx,
    };
    updatedExitEndThumb.min = {
      ...updatedEnterStartThumb.position,
      // adding steps so that we can have minimum interval between thumbs
      step: updatedEnterStartThumb.position.step + MIN_STEP,
      x: (updatedEnterStartThumb.position.step + MIN_STEP) * stepper.stepSizePx,
    };
  }

  let prevThumb;
  if (
    sliders &&
    updatedEnterStartThumb.min &&
    updatedExitEndThumb.min.sliderId !== SLIDER_TYPES.RULER &&
    sliders[updatedEnterStartThumb.min.sliderId] &&
    sliders[updatedEnterStartThumb.min.sliderId][
      updatedEnterStartThumb.min.thumbId
    ]
  ) {
    const prevSlider = sliders[updatedEnterStartThumb.min.sliderId];
    prevThumb = { ...prevSlider[updatedEnterStartThumb.min.thumbId] };
    prevThumb.max = updatedEnterStartThumb.position;
  }

  let nextThumb;
  if (
    sliders &&
    updatedExitEndThumb.max &&
    updatedExitEndThumb.max.sliderId !== SLIDER_TYPES.RULER &&
    sliders[updatedExitEndThumb.max.sliderId] &&
    sliders[updatedExitEndThumb.max.sliderId][updatedExitEndThumb.max.thumbId]
  ) {
    const nextSlider = sliders[updatedExitEndThumb.max.sliderId];
    nextThumb = { ...nextSlider[updatedExitEndThumb.max.thumbId] };
    nextThumb.min = updatedExitEndThumb.position;
  }

  return {
    enterEndThumb: updatedEnterEndThumb,
    enterStartThumb: updatedEnterStartThumb,
    exitEndThumb: updatedExitEndThumb,
    exitStartThumb: updatedExitStartThumb,
    prevThumb,
    nextThumb,
  };
};

/**
 * Function to create default sliders
 * Ruler, playhead thumb and playhead indicator are also considered as sliders.
 * Ruler slider will be immovable and will represent extreme ends of all other sliders
 * @param {CreateDefaultSlidersParams} params
 */
const createDefaultSliders = (params = {}) => {
  const { playhead, prevSliders, stepper } = params;
  const rulerStepStart = secondsToStep({ seconds: 0, stepper });
  const rulerStepEnd = secondsToStep({ seconds: stepper.duration, stepper });
  const rulerMaxStepEnd = {
    x:
      RULER_OPTIONS.paddingLeft +
      (stepper.steps + stepper.videoExcessSteps) * stepper.stepSizePx,
    step: stepper.steps + stepper.videoExcessSteps,
  };

  /**
   * consider ruler as a slider
   * @type {Slider}
   */
  const rulerSlider = {
    enterStart: {
      id: "enterStart",
      isDragging: false,
      position: {
        sliderId: SLIDER_TYPES.RULER,
        step: rulerStepStart.step,
        thumbId: "enterStart",
        trackIndex: 0,
        trackType: TRACK_TYPES.RULER,
        x: rulerStepStart.x,
        y: 0,
      },
      sliderId: SLIDER_TYPES.RULER,
    },
    exitEnd: {
      id: "exitEnd",
      isDragging: false,
      position: {
        sliderId: SLIDER_TYPES.RULER,
        step: rulerStepEnd.step,
        thumbId: "exitEnd",
        trackIndex: 0,
        trackType: TRACK_TYPES.RULER,
        x: rulerStepEnd.x,
        y: 0,
      },
      sliderId: SLIDER_TYPES.RULER,
    },
    rulerEnd: {
      id: "rulerEnd",
      isDragging: false,
      position: {
        sliderId: SLIDER_TYPES.RULER,
        step: rulerMaxStepEnd.step,
        thumbId: "rulerEnd",
        trackIndex: 0,
        trackType: TRACK_TYPES.RULER,
        x: rulerMaxStepEnd.x,
        y: 0,
      },
      sliderId: SLIDER_TYPES.RULER,
    },
    id: SLIDER_TYPES.RULER,
    itemId: SLIDER_TYPES.RULER,
    itemType: SLIDER_TYPES.RULER,
    itemSubType: SLIDER_TYPES.RULER,
    sliderType: SLIDER_TYPES.RULER,
    isLocked: false,
    isDraggable: false,
    toNewTrackAbove: false,
    toNewTrackBelow: false,
    isOverlapping: false,
  };
  if (RULER_OPTIONS.projectMaxDuration !== 0) {
    let { projectMaxDuration } = RULER_OPTIONS;
    if (stepper.duration > projectMaxDuration) {
      // might be old project with different config set
      projectMaxDuration = stepper.duration;
    }
    const projectMaxStep = secondsToStep({
      seconds: projectMaxDuration,
      stepper,
    });
    rulerSlider.projectEnd = {
      id: "projectEnd",
      isDragging: false,
      position: {
        sliderId: SLIDER_TYPES.RULER,
        step: projectMaxStep.step,
        thumbId: "projectEnd",
        trackIndex: 0,
        trackType: TRACK_TYPES.RULER,
        x: projectMaxStep.x,
        y: 0,
      },
      sliderId: SLIDER_TYPES.RULER,
    };
  }

  const playheadThumbStep = secondsToStep({ seconds: playhead, stepper });

  /**
   * consider playhead thumb as a slider
   * @type {Slider}
   */
  const playheadThumbSlider = {
    enterStart: {
      id: "enterStart",
      isDragging: false,
      position: {
        sliderId: SLIDER_TYPES.PLAYHEAD_THUMB,
        step: playheadThumbStep.step,
        thumbId: "enterStart",
        trackIndex: 0,
        trackType: TRACK_TYPES.RULER,
        x: playheadThumbStep.x,
        y: 0,
      },
      min: rulerSlider.enterStart.position,
      max: rulerSlider.exitEnd.position,
      sliderId: SLIDER_TYPES.PLAYHEAD_THUMB,
    },
    id: SLIDER_TYPES.PLAYHEAD_THUMB,
    itemId: SLIDER_TYPES.PLAYHEAD_THUMB,
    itemType: SLIDER_TYPES.PLAYHEAD_THUMB,
    itemSubType: SLIDER_TYPES.PLAYHEAD_THUMB,
    sliderType: SLIDER_TYPES.PLAYHEAD_THUMB,
    isLocked: false,
    isDraggable: true,
    toNewTrackAbove: false,
    toNewTrackBelow: false,
    isOverlapping: false,
  };

  /**
   * consider playhead indicator as a slider
   * @type {Slider}
   */
  const playheadIndicatorSlider = {
    enterStart: {
      id: "enterStart",
      isDragging: false,
      position: {
        sliderId: SLIDER_TYPES.PLAYHEAD_INDICATOR,
        step: rulerStepStart.step,
        thumbId: "enterStart",
        trackIndex: 0,
        trackType: TRACK_TYPES.RULER,
        x: rulerStepStart.x,
        y: 0,
      },
      min: rulerSlider.enterStart.position,
      max: rulerSlider.exitEnd.position,
      sliderId: SLIDER_TYPES.PLAYHEAD_INDICATOR,
    },
    id: SLIDER_TYPES.PLAYHEAD_INDICATOR,
    itemId: SLIDER_TYPES.PLAYHEAD_INDICATOR,
    itemType: SLIDER_TYPES.PLAYHEAD_INDICATOR,
    itemSubType: SLIDER_TYPES.PLAYHEAD_INDICATOR,
    sliderType: SLIDER_TYPES.PLAYHEAD_INDICATOR,
    isLocked: false,
    isDraggable: true,
    isVisible: false,
    toNewTrackAbove: false,
    toNewTrackBelow: false,
    isOverlapping: false,
  };

  if (prevSliders && prevSliders[playheadIndicatorSlider.id]) {
    // try to retain old indicator step
    const prevEnterStart = prevSliders[playheadIndicatorSlider.id].enterStart;
    const { max } = playheadIndicatorSlider.enterStart;
    if (prevEnterStart.position.step <= max.step) {
      const indicatorStep = secondsToStep({
        seconds: prevEnterStart.position.step * RULER_OPTIONS.interval,
        stepper,
      });
      playheadIndicatorSlider.enterStart.position.x = indicatorStep.x;
      playheadIndicatorSlider.enterStart.position.step = indicatorStep.step;
    }
  }

  /** @type {Sliders} */
  const sliders = {
    [rulerSlider.id]: rulerSlider,
    [playheadThumbSlider.id]: playheadThumbSlider,
    [playheadIndicatorSlider.id]: playheadIndicatorSlider,
  };

  return sliders;
};

/**
 * @param {AddSnapPointParams} params
 */
export const addSnapPoint = (params = {}) => {
  const { mutableTimelineSnapPoints, slider, isSeekOnly = false } = params;

  const thumbsForSnap = [slider.enterStart];
  if (slider.exitEnd) {
    thumbsForSnap.push(slider.exitEnd);
  }

  for (const thumb of thumbsForSnap) {
    const { thumbId, trackIndex, trackType, step, x, y } = thumb.position;
    const partitionStart =
      step - (step % RULER_OPTIONS.snapOptions.stepPartitionSize);
    if (!mutableTimelineSnapPoints[trackType]) {
      mutableTimelineSnapPoints[trackType] = {};
    }
    if (!mutableTimelineSnapPoints[trackType][trackIndex]) {
      mutableTimelineSnapPoints[trackType][trackIndex] = {};
    }
    if (!mutableTimelineSnapPoints[trackType][trackIndex][partitionStart]) {
      mutableTimelineSnapPoints[trackType][trackIndex][partitionStart] = [];
    }
    const snapPoints =
      mutableTimelineSnapPoints[trackType][trackIndex][partitionStart];
    snapPoints.push({
      sliderId: slider.id,
      step,
      thumbId,
      trackIndex,
      trackType,
      sliderType: slider.sliderType,
      x,
      y,
      snapPointId: `${thumbId}${trackIndex}${slider.id}${thumbId}`, // keep this unique
      partitionStart,
      isSeekOnly,
    });
  }

  return mutableTimelineSnapPoints;
};

/**
 * @param {AddDefaultSnapPointsParams} params
 */
export const addDefaultSnapPoints = (params = {}) => {
  const { mutableTimelineSnapPoints, sliders } = params;

  const rulerSlider = sliders[SLIDER_TYPES.RULER];
  const playheadThumbSlider = sliders[SLIDER_TYPES.PLAYHEAD_THUMB];
  if (rulerSlider) {
    addSnapPoint({ mutableTimelineSnapPoints, slider: rulerSlider });
  }
  if (playheadThumbSlider) {
    addSnapPoint({ mutableTimelineSnapPoints, slider: playheadThumbSlider });
  }

  return mutableTimelineSnapPoints;
};

/**
 * @param {RemoveDefaultSnapPointsParams} params
 */
export const removeDefaultSnapPoints = (params = {}) => {
  let { timelineSnapPoints } = params;
  timelineSnapPoints = { ...timelineSnapPoints };
  delete timelineSnapPoints[TRACK_TYPES.RULER];
  return timelineSnapPoints;
};

/**
 * prepares all possible thumbs in timeline using passed stepper
 * @param {GetSlidersParams} params
 */
export const getSliders = (params = {}) => {
  const { playhead, project, stepper, prevSliders, tracks, timelineMode } = params;

  const sliders = createDefaultSliders({ playhead, prevSliders, stepper });
  const rulerSlider = sliders[SLIDER_TYPES.RULER];

  /** @type {TimelineSnapPoints} */
  const timelineSnapPoints = {};
  addDefaultSnapPoints({
    mutableTimelineSnapPoints: timelineSnapPoints,
    sliders,
  });

  Reflect.ownKeys(tracks).forEach((trackType) => {
    const trackList = tracks[trackType];
    trackList.forEach((track) => {
      const itemSliderY = track.itemPlot.y;
      const trackOptions = TRACK_OPTIONS[track.trackType];
      let useRulerMinMax = false;
      if (trackOptions) {
        useRulerMinMax = trackOptions.allowTrimOnSameTrack;
      }
      track.itemIds.forEach((itemId, itemIndexInTrack) => {
        /** @type {Slider | null} */
        let slider = null;
        if (track.trackType === TRACK_TYPES.OBJECT) {
          const item = project.getIn(["workspaceItems", itemId]);
          slider = {
            id: item.get("id"),
            itemId: item.get("id"),
            sliderType: SLIDER_TYPES.OBJECT,
            itemType: item.get("type"),
            itemSubType: item.get("subType"),
            toNewTrackAbove: false,
            toNewTrackBelow: false,
            isLocked: Boolean(item.get("isLocked")),
            isDraggable: true,
            useRulerMinMax,
            isOverlapping: false,
          };

          let enterStartThumb = createItemThumb({
            item,
            stepper,
            thumbId: "enterStart",
            timeId: "enterStart",
            sliderY: itemSliderY,
          });
          let exitEndThumb = createItemThumb({
            item,
            stepper,
            thumbId: "exitEnd",
            timeId: "exitEnd",
            sliderY: itemSliderY,
          });
          let enterEndThumb;
          if (
            item.get("enterEffectName") &&
            item.get("enterEffectName") !== "no_Effect"
          ) {
            enterEndThumb = createItemThumb({
              item,
              stepper,
              thumbId: "enterEnd",
              timeId: "enterEnd",
              sliderY: itemSliderY,
            });
          }
          let exitStartThumb;
          if (
            item.get("exitEffectName") &&
            item.get("exitEffectName") !== "no_Effect"
          ) {
            exitStartThumb = createItemThumb({
              item,
              stepper,
              thumbId: "exitStart",
              timeId: "exitStart",
              sliderY: itemSliderY,
            });
          }

          const updatedThumbs = updateThumbLimits({
            enterEndThumb,
            enterStartThumb,
            exitEndThumb,
            exitStartThumb,
            stepper,
          });

          enterStartThumb = updatedThumbs.enterStartThumb;
          exitEndThumb = updatedThumbs.exitEndThumb;
          enterEndThumb = updatedThumbs.enterEndThumb;
          exitStartThumb = updatedThumbs.exitStartThumb;

          slider.enterStart = enterStartThumb;
          slider.exitEnd = exitEndThumb;
          if (enterEndThumb) {
            slider.enterEnd = enterEndThumb;
          }
          if (exitStartThumb) {
            slider.exitStart = exitStartThumb;
          }

          if (itemIndexInTrack === 0) {
            // first slider in track
            slider.enterStart.min = rulerSlider.enterStart.position;
          }

          if (itemIndexInTrack === track.itemIds.length - 1) {
            // last slider in track
            slider.exitEnd.max = rulerSlider.exitEnd.position;
          }

          if (itemIndexInTrack > 0) {
            // link prev slider and current slider to set each other as drag limits
            const prevSlider = sliders[track.itemIds[itemIndexInTrack - 1]];
            slider.enterStart.min = prevSlider.exitEnd.position;
            prevSlider.exitEnd.max = slider.enterStart.position;
          }
        } else if (track.trackType === TRACK_TYPES.VIDEO) {
          const video = project.getIn(["workspaceBG", itemId]);

          /** @type {Slider} */
          slider = {
            id: video.get("id"),
            itemId: video.get("id"),
            sliderType: SLIDER_TYPES.VIDEO,
            itemType: video.get("type"),
            itemSubType: video.get("subType"),
            toNewTrackAbove: false,
            toNewTrackBelow: false,
            isLocked: false,
            isDraggable: true,
            useRulerMinMax,
            isOverlapping: false,
          };

          let { enterStartThumb, exitEndThumb } = createVideoTrackThumbs({
            video,
            stepper,
            sliderY: itemSliderY,
          });

          const updatedThumbs = updateThumbLimits({
            enterStartThumb,
            exitEndThumb,
            stepper,
          });

          enterStartThumb = updatedThumbs.enterStartThumb;
          exitEndThumb = updatedThumbs.exitEndThumb;

          slider.enterStart = enterStartThumb;
          slider.exitEnd = exitEndThumb;

          if (itemIndexInTrack === 0) {
            // first slider in track
            slider.enterStart.min = rulerSlider.enterStart.position;
          }

          if (itemIndexInTrack === track.itemIds.length - 1) {
            // last slider in track
            slider.exitEnd.max = rulerSlider.exitEnd.position;
          }

          if (itemIndexInTrack > 0) {
            // link prev slider and current slider to set each other as drag limits
            const prevSlider = sliders[track.itemIds[itemIndexInTrack - 1]];
            slider.enterStart.min = prevSlider.exitEnd.position;
            prevSlider.exitEnd.max = slider.enterStart.position;
          }
        } else if (track.trackType === TRACK_TYPES.AUDIO) {
          const audio = project.getIn(["audios", itemId]);

          /** @type {Slider} */
          slider = {
            id: audio.get("id"),
            itemId: audio.get("id"),
            sliderType: SLIDER_TYPES.AUDIO,
            itemType: audio.get("type"),
            itemSubType: audio.get("subType"),
            toNewTrackAbove: false,
            toNewTrackBelow: false,
            isLocked: Boolean(audio.get("isLocked")),
            isDraggable: true,
            useRulerMinMax,
            isOverlapping: false,
          };

          let { enterStartThumb, exitEndThumb } = createAudioThumbs({
            audio,
            stepper,
            sliderY: itemSliderY,
          });

          const updatedThumbs = updateThumbLimits({
            enterStartThumb,
            exitEndThumb,
            stepper,
          });

          enterStartThumb = updatedThumbs.enterStartThumb;
          exitEndThumb = updatedThumbs.exitEndThumb;

          slider.enterStart = enterStartThumb;
          slider.exitEnd = exitEndThumb;

          if (itemIndexInTrack === 0) {
            // first slider in track
            slider.enterStart.min = rulerSlider.enterStart.position;
          }

          if (itemIndexInTrack === track.itemIds.length - 1) {
            // last slider in track
            slider.exitEnd.max = rulerSlider.exitEnd.position;
          }

          if (itemIndexInTrack > 0) {
            // link prev slider and current slider to set each other as drag limits
            const prevSlider = sliders[track.itemIds[itemIndexInTrack - 1]];
            slider.enterStart.min = prevSlider.exitEnd.position;
            prevSlider.exitEnd.max = slider.enterStart.position;
          }
        }

        if (slider) {
          sliders[slider.id] = slider;
          addSnapPoint({
            mutableTimelineSnapPoints: timelineSnapPoints,
            slider,
          });
        }
      });

      if (track.subtitleIds && track.subtitlePlot) {
        track.subtitleIds.forEach((subtitleId, subtitleIdxInTrack) => {
          const targetSlider = sliders[subtitleId.itemId];
          const subtitleItem = project.getIn([
            "localSubtitle",
            subtitleId.timelineId,
          ]);
          const targetItem = project.getIn([
            subtitleId.itemContainer,
            subtitleId.itemId,
          ]);

          let itemStart = targetItem.get("playStart");
          let itemEnd = targetItem.get("playEnd");
          if (subtitleId.itemContainer === "workspaceItems") {
            itemStart = targetItem.get("enterStart");
            itemEnd = targetItem.get("exitEnd");
          }

          let mediaStart = targetItem.get("videoStart");
          if (subtitleId.itemContainer === "audios") {
            mediaStart = targetItem.get("musicStart");
          }

          if (timelineMode === TIMELINE_MODES.MAIN) {
            const sliderId = `ms-${subtitleId.itemId}`;

            if (sliders[sliderId]) {
              return;
            }

            /** @type {Slider} */
            const slider = {
              id: sliderId,
              isDraggable: false,
              isLocked: Boolean(targetItem.get("isLocked")),
              isOverlapping: false,
              itemId: sliderId,
              itemSubType: SLIDER_TYPES.MINI_SUBTITLE,
              itemType: SLIDER_TYPES.MINI_SUBTITLE,
              preventSelection: true,
              sliderType: SLIDER_TYPES.MINI_SUBTITLE,
              toNewTrackAbove: false,
              toNewTrackBelow: false,
            };

            const { enterStartThumb, exitEndThumb } = createMiniSubtitleThumbs({
              enterStart: itemStart,
              exitEnd: itemEnd,
              id: sliderId,
              stepper,
              track,
            });
            const updatedThumbs = updateThumbLimits({
              enterStartThumb,
              exitEndThumb,
              stepper,
            });
            slider.enterStart = updatedThumbs.enterStartThumb;
            slider.exitEnd = updatedThumbs.exitEndThumb;

            slider.enterStart.min = rulerSlider.enterStart.position;
            slider.exitEnd.max = rulerSlider.exitEnd.position;

            slider.meta = {
              firstSubtitleId: subtitleId,
              targetId: subtitleId.itemId,
            };

            sliders[slider.id] = slider;
          } else {
            /** @type {Slider} */
            const slider = {
              id: subtitleId.timelineId,
              itemId: subtitleId.timelineId,
              sliderType: SLIDER_TYPES.SUBTITLE,
              itemType: SLIDER_TYPES.SUBTITLE,
              itemSubType: SLIDER_TYPES.SUBTITLE,
              toNewTrackAbove: false,
              toNewTrackBelow: false,
              isLocked: false,
              isDraggable: true,
              useRulerMinMax: false, // should not use trim logic for subtitle
              isOverlapping: false,
              subtitleId,
            };

            let { enterStartThumb, exitEndThumb } = createSubtitleThumbs({
              stepper,
              subtitleId,
              subtitleItem,
              track,
            });

            const updatedThumbs = updateThumbLimits({
              enterStartThumb,
              exitEndThumb,
              stepper,
            });

            enterStartThumb = updatedThumbs.enterStartThumb;
            exitEndThumb = updatedThumbs.exitEndThumb;

            slider.enterStart = enterStartThumb;
            slider.exitEnd = exitEndThumb;

            if (subtitleId.isStart) {
              const leftSubtitle = project.getIn([
                "subtitle",
                "data",
                subtitleId.dropId,
                subtitleId.leftId,
              ]);
              if (leftSubtitle) {
                const lEndStep = secondsToStep({
                  seconds: itemStart + leftSubtitle.get("end") - mediaStart,
                  stepper,
                });
                if (lEndStep.step <= targetSlider.enterStart.position.step) {
                  slider.enterStart.min = targetSlider.enterStart.position;
                } else {
                  slider.enterStart.min = {
                    // WARN: ensure that this thumb is not used to get slider (which might crash the app)
                    sliderId: `${targetItem.get("id")}-${
                      subtitleId.dropId
                    }-${leftSubtitle.get("id")}-i`,
                    step: lEndStep.step,
                    thumbId: "exitEnd",
                    trackIndex: slider.enterStart.position.trackIndex,
                    trackType: slider.enterStart.position.trackType,
                    x: lEndStep.x,
                    y: slider.enterStart.position.y,
                  };
                }
              } else {
                slider.enterStart.min = targetSlider.enterStart.position;
              }
            } else {
              const prevSlider =
                sliders[track.subtitleIds[subtitleIdxInTrack - 1].timelineId];
              slider.enterStart.min = prevSlider.exitEnd.position;
              prevSlider.exitEnd.max = slider.enterStart.position;
            }

            if (subtitleId.isEnd) {
              const rightSubtitle = project.getIn([
                "subtitle",
                "data",
                subtitleId.dropId,
                subtitleId.rightId,
              ]);
              if (rightSubtitle) {
                const rEndStep = secondsToStep({
                  seconds: itemStart + rightSubtitle.get("start") - mediaStart,
                  stepper,
                });
                if (targetSlider.exitEnd.position.step <= rEndStep.step) {
                  slider.exitEnd.max = targetSlider.exitEnd.position;
                } else {
                  slider.exitEnd.max = {
                    // WARN: ensure that this thumb is not used to get slider (which might crash the app)
                    sliderId: `${targetItem.get("id")}-${
                      subtitleId.dropId
                    }-${rightSubtitle.get("id")}-i`,
                    step: rEndStep.step,
                    thumbId: "exitEnd",
                    trackIndex: slider.exitEnd.position.trackIndex,
                    trackType: slider.exitEnd.position.trackType,
                    x: rEndStep.x,
                    y: slider.exitEnd.position.y,
                  };
                }
              } else {
                slider.exitEnd.max = targetSlider.exitEnd.position;
              }
            }

            sliders[slider.id] = slider;
            addSnapPoint({
              mutableTimelineSnapPoints: timelineSnapPoints,
              slider,
              isSeekOnly: true,
            });
          }
        });
      }
    });
  });

  return {
    sliders,
    timelineSnapPoints,
  };
};

/**
 * updates passed track index in trackPosition
 * @param {ChangeSliderTrackParams} params
 * @returns new reference of updated slider
 */
const changeSliderTrack = (params = {}) => {
  const {
    slider,
    targetTrack,
    useRulerMinMax = false,
    toNewTrackAbove = false,
    toNewTrackBelow = false,
    useOriginalPosition = false,
  } = params;
  const sliderThumbs = ["enterStart", "exitEnd", "enterEnd", "exitStart"];

  let trackPlot = targetTrack.itemPlot;
  if (slider.sliderType === SLIDER_TYPES.SUBTITLE) {
    trackPlot = targetTrack.subtitlePlot;
  }

  let updatedSlider = {
    ...slider,
    useRulerMinMax,
  };

  for (const thumbKey of sliderThumbs) {
    /** @type {Thumb} */
    const thumb = slider[thumbKey];
    if (thumb) {
      let updatedThumb = thumb;

      if (!updatedThumb.trackPosition || useOriginalPosition) {
        updatedThumb = {
          ...updatedThumb,
          trackPosition: { ...updatedThumb.position },
        };
      }
      updatedThumb = {
        ...updatedThumb,
        trackPosition: {
          ...updatedThumb.trackPosition,
          trackType: targetTrack.trackType,
          trackIndex: targetTrack.trackIndex,
          y: trackPlot.y,
        },
      };

      updatedSlider = {
        ...updatedSlider,
        [thumbKey]: updatedThumb,
        toNewTrackAbove,
        toNewTrackBelow,
      };
    }
  }

  return updatedSlider;
};

/**
 * comparator function to sort slider ids by track index in ascending order
 * @param {CompareSliderByTracksParams} params
 */
const compareSliderByTracks = (params = {}) => {
  const { slider1Id, slider2Id, sliders } = params;
  const priorityByTrackType = {
    [TRACK_TYPES.RULER]: 0,
    [TRACK_TYPES.OBJECT]: 1,
    [TRACK_TYPES.VIDEO]: 2,
    [TRACK_TYPES.AUDIO]: 3,
  };
  const priorityByItemType = {
    OBJECT: 0,
    AUDIO: 0,
    IMAGE: 1,
    VIDEO: 2,
  };

  const slider1 = sliders[slider1Id];
  const slider2 = sliders[slider2Id];

  let slider1TrackType = slider1.enterStart.position.trackType;
  let slider2TrackType = slider2.enterStart.position.trackType;

  if (priorityByTrackType[slider1TrackType] === undefined) {
    slider1TrackType = TRACK_TYPES.OBJECT;
  }
  if (priorityByTrackType[slider2TrackType] === undefined) {
    slider2TrackType = TRACK_TYPES.OBJECT;
  }

  // first sort by track type in ascending order
  let result =
    priorityByTrackType[slider1TrackType] -
    priorityByTrackType[slider2TrackType];

  if (result === 0) {
    // sort by track index in ascending order
    result =
      slider1.enterStart.position.trackIndex -
      slider2.enterStart.position.trackIndex;

    if (result === 0) {
      let slider1ItemType = slider1.itemType;
      let slider2ItemType = slider2.itemType;

      if (
        isImageOnly(slider1.itemType, slider1.itemSubType) ||
        isCustomBG(slider1.itemType, slider1.itemSubType)
      ) {
        slider1ItemType = "IMAGE";
      } else if (isVideoOnly(slider1.itemType, slider1.itemSubType)) {
        slider1ItemType = "VIDEO";
      } else if (isAudioOnly(slider1.itemType, slider1.itemSubType)) {
        slider1ItemType = "AUDIO";
      } else {
        slider1ItemType = "OBJECT";
      }

      if (
        isImageOnly(slider2.itemType, slider2.itemSubType) ||
        isCustomBG(slider2.itemType, slider2.itemSubType)
      ) {
        slider2ItemType = "IMAGE";
      } else if (isVideoOnly(slider2.itemType, slider2.itemSubType)) {
        slider2ItemType = "VIDEO";
      } else if (isAudioOnly(slider2.itemType, slider2.itemSubType)) {
        slider2ItemType = "AUDIO";
      } else {
        slider2ItemType = "OBJECT";
      }

      if (priorityByItemType[slider1ItemType] === undefined) {
        slider1ItemType = "OBJECT";
      }
      if (priorityByItemType[slider2ItemType] === undefined) {
        slider2ItemType = "OBJECT";
      }

      // sort by item type in descending order to handle new track creation for video or image type with increased height, if needed
      result =
        priorityByItemType[slider2ItemType] -
        priorityByItemType[slider1ItemType];
    }
  }

  return result;
};

/**
 * @param {GetNeighbourThumbs} params
 */
export const getNeighbourThumbs = (params = {}) => {
  const {
    direction = "both",
    timelineSnapPoints,
    thumb,
    sliderIdsToSkip,
    mode = "seek",
    sliderTypes,
  } = params;

  const nearestSnapPoints = {
    left: [],
    right: [],
    leftStep: null,
    rightStep: null,
  };

  const thumbStep = thumb.position.step;
  const partitionStart =
    thumbStep - (thumbStep % RULER_OPTIONS.snapOptions.stepPartitionSize);

  let allSnapPartitions = ImmutableSet();
  for (const trackType of Reflect.ownKeys(timelineSnapPoints)) {
    const snapTracks = timelineSnapPoints[trackType];

    for (const snapTrackIndex of Reflect.ownKeys(snapTracks)) {
      const snapTrack = snapTracks[snapTrackIndex];
      for (const p of Reflect.ownKeys(snapTrack)) {
        allSnapPartitions = allSnapPartitions.add(Number(p));
      }
    }
  }

  allSnapPartitions = allSnapPartitions.toList().sort();
  let leftIndex = null;
  let rightIndex = null;
  const lastIndex = allSnapPartitions.size - 1;
  let leftDone = direction === "right";
  let rightDone = direction === "left";

  for (const [i, p] of allSnapPartitions.entrySeq()) {
    if (
      // prettier-ignore
      leftIndex === null
      // prettier-ignore
      || (p <= partitionStart && p >= allSnapPartitions.get(leftIndex))
    ) {
      leftIndex = i;
    }
    if (p >= partitionStart) {
      rightIndex = i;
    }
    if (rightIndex !== null) {
      break;
    }
  }

  if (leftIndex === null || rightIndex === null) {
    // if this happens, there is something wrong with the state
    return nearestSnapPoints;
  }

  const handleSnap = (snapPoint) => {
    if (sliderTypes && !sliderTypes.includes(snapPoint.sliderType)) {
      return;
    }
    if (
      snapPoint.sliderId === thumb.sliderId ||
      (sliderIdsToSkip && sliderIdsToSkip.includes(snapPoint.sliderId))
    ) {
      return;
    }

    if (
      // prettier-ignore
      (direction === "left" || direction === "both")
      && (
        // prettier-ignore
        (mode === "seek" && snapPoint.step < thumbStep)
        // prettier-ignore
        || (mode === "snap" && !snapPoint.isSeekOnly && snapPoint.step <= thumbStep)
      )
    ) {
      const curDis = thumbStep - snapPoint.step;
      const prevDis =
        nearestSnapPoints.leftStep === null
          ? curDis + 1
          : thumbStep - nearestSnapPoints.leftStep;

      if (curDis < prevDis) {
        nearestSnapPoints.leftStep = snapPoint.step;
        nearestSnapPoints.left = [snapPoint];
      } else if (curDis === prevDis) {
        nearestSnapPoints.left.push(snapPoint);
      }
    }

    if (
      // prettier-ignore
      (direction === "right" || direction === "both")
      && (
        // prettier-ignore
        (mode === "seek" && thumbStep < snapPoint.step)
        // prettier-ignore
        || (mode === "snap" && !snapPoint.isSeekOnly && thumbStep <= snapPoint.step)
      )
    ) {
      const curDis = snapPoint.step - thumbStep;
      const prevDis =
        nearestSnapPoints.rightStep === null
          ? curDis + 1
          : nearestSnapPoints.rightStep - thumbStep;

      if (curDis < prevDis) {
        nearestSnapPoints.rightStep = snapPoint.step;
        nearestSnapPoints.right = [snapPoint];
      } else if (curDis === prevDis) {
        nearestSnapPoints.right.push(snapPoint);
      }
    }
  };

  const trackPriority =
    mode === "seek"
      ? RULER_OPTIONS.snapOptions.seekPriorityByTracks
      : RULER_OPTIONS.snapOptions.snapPriorityByTracks;

  while (
    // prettier-ignore
    (leftIndex >= 0 && !leftDone)
    // prettier-ignore
    || (rightIndex <= lastIndex && !rightDone)
  ) {
    const leftPartition = allSnapPartitions.get(leftIndex);
    const rightPartition = allSnapPartitions.get(rightIndex);

    for (const trackType of trackPriority) {
      const snapTracks = timelineSnapPoints[trackType];

      if (!snapTracks) {
        continue;
      }

      for (const snapTrackIndex of Reflect.ownKeys(snapTracks)) {
        const snapTrack = snapTracks[snapTrackIndex];
        const leftSnapPoints = snapTrack[leftPartition];
        const rightSnapPoints = snapTrack[rightPartition];

        if (leftSnapPoints && !leftDone) {
          leftSnapPoints.forEach(handleSnap);
        }

        if (rightSnapPoints && !rightDone) {
          rightSnapPoints.forEach(handleSnap);
        }
      }
    }

    if (nearestSnapPoints.leftStep !== null) {
      // found nearest thumb in current partition
      leftDone = true;
    }
    if (nearestSnapPoints.rightStep !== null) {
      // found nearest thumb in current partition
      rightDone = true;
    }
    leftIndex -= 1;
    rightIndex += 1;
  }

  return nearestSnapPoints;
};

/**
 * Function to adjust slider movement and snap to other sliders
 * @param {SnapSelectionParams} params
 */
const snapSelection = (params = {}) => {
  const { maximumStep, moveBy, sliders, stepper, timelineSnapPoints } = params;
  let { selectedSliders } = params;
  const allowedSliderTypesToSnap = [
    SLIDER_TYPES.OBJECT,
    SLIDER_TYPES.VIDEO,
    SLIDER_TYPES.AUDIO,
    SLIDER_TYPES.SUBTITLE,
  ];
  const { allowedThumbs } = RULER_OPTIONS.snapOptions;
  let snapDis = RULER_OPTIONS.snapOptions.minDistance;
  if (stepper.timeScale === RULER_OPTIONS.timeScale.max) {
    // considering stepper size on full zoom for precise trimming
    // as we don't want to skip multiple steps when zoomed in for huge snap threshold
    snapDis = stepper.stepSizePx / 2;
  } else if (stepper.stepSizePx >= RULER_OPTIONS.snapOptions.minDistance) {
    snapDis = stepper.stepSizePx;
  }

  const failedResult = {
    matchingSnapPoints: undefined,
    moveBy: undefined,
  };

  /** @type {MatchingSnapPoints} */
  const matchingSnapPoints = {};
  /** @type {{ x: number, y: number, step: number } | undefined} */
  let correctedMoveBy;
  /** @type {string[]} */
  let thumbIdsToCheck = [];
  /** @type {string[]} */
  const selectedSliderIds = [];

  selectedSliders = selectedSliders.filter((selection) => {
    const slider = sliders[selection.sliderId];
    const canSnap =
      slider &&
      slider.isDraggable &&
      allowedSliderTypesToSnap.includes(slider.sliderType);
    if (canSnap) {
      selectedSliderIds.push(slider.id);
    }
    return canSnap;
  });

  if (
    selectedSliders.length > 1 ||
    (selectedSliders.length === 1 && !selectedSliders[0].thumbId)
  ) {
    // at least one slider is being moved
    thumbIdsToCheck = allowedThumbs;
  } else if (
    selectedSliders.length === 1 &&
    selectedSliders[0].thumbId &&
    allowedThumbs.includes(selectedSliders[0].thumbId)
  ) {
    thumbIdsToCheck = [selectedSliders[0].thumbId];
  }

  if (thumbIdsToCheck.length === 0) {
    return failedResult;
  }

  for (const selection of selectedSliders) {
    const slider = sliders[selection.sliderId];

    for (const thumbId of thumbIdsToCheck) {
      /** @type {Thumb} */
      const thumb = slider[thumbId];

      if (!thumb) {
        continue; /* eslint-disable-line no-continue */
      }

      const originalPostion = thumb.position;
      const futurePosition = { ...originalPostion };
      if (thumb.trackPosition) {
        futurePosition.trackIndex = thumb.trackPosition.trackIndex;
        futurePosition.trackType = thumb.trackPosition.trackType;
      }

      if (correctedMoveBy) {
        futurePosition.x = futurePosition.x + correctedMoveBy.x;
        futurePosition.step = futurePosition.step + correctedMoveBy.step;
      } else {
        futurePosition.x = originalPostion.x + moveBy.x;
        futurePosition.step = originalPostion.step + moveBy.step;

        if (
          typeof maximumStep === "number" &&
          Math.abs(futurePosition.step - originalPostion.step) > maximumStep
        ) {
          if (moveBy.step < 0) {
            // towards left
            futurePosition.step = originalPostion.step - maximumStep;
            futurePosition.x =
              RULER_OPTIONS.paddingLeft +
              futurePosition.step * stepper.stepSizePx;
          } else if (moveBy.step > 0) {
            // towards right
            futurePosition.step = originalPostion.step + maximumStep;
            futurePosition.x =
              RULER_OPTIONS.paddingLeft +
              futurePosition.step * stepper.stepSizePx;
          }
        }
      }

      const dummyThumb = {
        ...thumb,
        position: futurePosition,
      };

      const nearestThumbs = getNeighbourThumbs({
        direction: "both",
        sliderIdsToSkip: selectedSliderIds,
        thumb: dummyThumb,
        timelineSnapPoints,
        mode: "snap",
      });

      let snapPoint = null;
      const leftSnapPoint = nearestThumbs.left[0];
      const rightSnapPoint = nearestThumbs.right[0];
      const firstTimeSnap = !correctedMoveBy;

      if (!leftSnapPoint && !rightSnapPoint) {
        continue;
      }

      if (leftSnapPoint && !rightSnapPoint) {
        snapPoint = leftSnapPoint;
      } else if (rightSnapPoint && !leftSnapPoint) {
        snapPoint = rightSnapPoint;
      } else if (leftSnapPoint && rightSnapPoint && firstTimeSnap) {
        const lDis = Math.abs(leftSnapPoint.step - futurePosition.step);
        const rDis = Math.abs(rightSnapPoint.step - futurePosition.step);
        if (lDis <= rDis) {
          snapPoint = leftSnapPoint;
        } else {
          snapPoint = rightSnapPoint;
        }
      } else if (leftSnapPoint && rightSnapPoint && !firstTimeSnap) {
        if (leftSnapPoint.step === futurePosition.step) {
          snapPoint = leftSnapPoint;
        } else if (rightSnapPoint.step === futurePosition.step) {
          snapPoint = rightSnapPoint;
        }
      }

      if (snapPoint && Math.abs(snapPoint.x - futurePosition.x) <= snapDis) {
        if (
          // prettier-ignore
          firstTimeSnap
          // prettier-ignore
          || (!firstTimeSnap && futurePosition.step === snapPoint.step)
        ) {
          const { snapPointId } = snapPoint;
          if (!matchingSnapPoints[snapPointId]) {
            matchingSnapPoints[snapPointId] = {
              snapPoint,
              aligningSnapPoints: [],
              sliderId: thumb.sliderId,
              thumbId: thumb.id,
            };
          }
        }

        if (firstTimeSnap) {
          correctedMoveBy = { ...moveBy };
          correctedMoveBy.step = snapPoint.step - thumb.position.step;
          correctedMoveBy.x = snapPoint.x - thumb.position.x;
        }
      }
    }
  }

  return {
    matchingSnapPoints,
    moveBy: correctedMoveBy,
  };
};

/**
 * @param {SetAligningSnapPointsParams} params
 */
const setAligningSnapPoints = (params = {}) => {
  const { matchingSnapPoints, timelineSnapPoints } = params;
  let updatedMatchingSnapPoints = matchingSnapPoints;

  for (const snapPointId of Reflect.ownKeys(matchingSnapPoints)) {
    const matchingSnapPoint = matchingSnapPoints[snapPointId];
    const aligningSnapPoints = [];

    for (const snapTrackType of RULER_OPTIONS.snapOptions
      .snapPriorityByTracks) {
      const snapTrackList = timelineSnapPoints[snapTrackType];

      if (!snapTrackList) {
        continue; /* eslint-disable-line no-continue */
      }

      for (const snapTrackIndex of Reflect.ownKeys(snapTrackList)) {
        const snapTrack = snapTrackList[snapTrackIndex];
        /** @type {SnapPoints | undefined} */
        const snapPoints = snapTrack
          ? snapTrack[matchingSnapPoint.snapPoint.partitionStart]
          : undefined;

        if (!snapPoints) {
          continue; /* eslint-disable-line no-continue */
        }

        for (const snapPoint of snapPoints) {
          if (snapPoint.step === matchingSnapPoint.snapPoint.step) {
            aligningSnapPoints.push(snapPoint);
          }
        }
      }
    }

    aligningSnapPoints.sort(
      (snapPoint1, snapPoint2) => snapPoint1.y - snapPoint2.y
    );

    updatedMatchingSnapPoints = {
      ...updatedMatchingSnapPoints,
      [snapPointId]: {
        ...updatedMatchingSnapPoints[snapPointId],
        aligningSnapPoints,
      },
    };
  }

  return updatedMatchingSnapPoints;
};

/**
 * moves selected sliders to specified position
 * @param {MoveToTrackParams} params
 */
export const moveToTrack = (params = {}) => {
  const {
    moveTo: actualMoveTo,
    moveType,
    startedFrom,
    sliders,
    tracks,
  } = params;
  let moveTo = actualMoveTo;
  const moveBy = {
    x: actualMoveTo.x - startedFrom.x,
    y: actualMoveTo.y - startedFrom.y,
  };
  const { selectedSliders: originalSelectedSliders } = params;

  /** @type {SelectedSliders} */
  const selectedSliders = [];
  let canChangeTrack = originalSelectedSliders.length > 0;
  let trackType = null;
  const allowedTrackTypes = [
    TRACK_TYPES.OBJECT,
    TRACK_TYPES.AUDIO,
    // TRACK_TYPES.VIDEO,
  ];
  let updatedSliders = sliders;
  let selectionHasDifferentTracks = false;
  let visualMediaSelectionCount = 0;
  let prevTrackIndex = null;
  let selectionDragPlot = null;

  for (const selectedSlider of originalSelectedSliders) {
    // to filter only available slider (as failsafe) and also to prevent moving sliders of different track types
    const slider = sliders[selectedSlider.sliderId];
    if (slider && slider.enterStart && slider.exitEnd) {
      const { trackType: currentTrackType, trackIndex: currentTrackIndex } =
        slider.enterStart.position;
      if (trackType === null) {
        trackType = currentTrackType;
      }
      if (
        selectedSlider.thumbId ||
        !slider.isDraggable ||
        currentTrackType !== trackType ||
        !allowedTrackTypes.includes(currentTrackType) ||
        !tracks[currentTrackType] ||
        !tracks[currentTrackType][currentTrackIndex] ||
        isCustomBG(slider.itemType, slider.itemSubType) ||
        // subtitles are not allowed to change track as they are tied to video/audio items
        slider.sliderType === SLIDER_TYPES.SUBTITLE
      ) {
        canChangeTrack = false;
        break;
      } else {
        selectedSliders.push(selectedSlider);
        if (prevTrackIndex === null) {
          prevTrackIndex = currentTrackIndex;
        }
        if (prevTrackIndex !== currentTrackIndex) {
          selectionHasDifferentTracks = true;
        }
        if (
          slider.sliderType === SLIDER_TYPES.VIDEO ||
          (slider.sliderType === SLIDER_TYPES.OBJECT &&
            (isImageOnly(slider.itemType, slider.itemSubType) ||
              isVideoOnly(slider.itemType, slider.itemSubType)))
        ) {
          visualMediaSelectionCount += 1;
        }

        const { enterStart, exitEnd } = slider;
        const track = tracks[currentTrackType][currentTrackIndex];

        const sliderDragPlot = {
          x: enterStart.position.x + moveBy.x,
          y: enterStart.position.y + moveBy.y,
          width: exitEnd.position.x - enterStart.position.x,
          height: track.itemPlot.height,
        };

        if (!selectionDragPlot) {
          selectionDragPlot = sliderDragPlot;
        } else {
          let selX = selectionDragPlot.x;
          let selX2 = selectionDragPlot.x + selectionDragPlot.width;
          let selY = selectionDragPlot.y;
          let selY2 = selectionDragPlot.y + selectionDragPlot.height;

          if (sliderDragPlot.x < selX) {
            selX = sliderDragPlot.x;
          }
          if (sliderDragPlot.x + sliderDragPlot.width > selX2) {
            selX2 = sliderDragPlot.x + sliderDragPlot.width;
          }
          if (sliderDragPlot.y < selY) {
            selY = sliderDragPlot.y;
          }
          if (sliderDragPlot.y + sliderDragPlot.height > selY2) {
            selY2 = sliderDragPlot.y + sliderDragPlot.height;
          }

          selectionDragPlot.x = selX;
          selectionDragPlot.y = selY;
          selectionDragPlot.width = selX2 - selX;
          selectionDragPlot.height = selY2 - selY;
        }
      }
    }
  }

  const selectionHasVisualMediaOnly =
    visualMediaSelectionCount > 0 &&
    visualMediaSelectionCount === selectedSliders.length;
  if (!selectionDragPlot) {
    canChangeTrack = false;
  }

  if (
    canChangeTrack &&
    selectionHasDifferentTracks &&
    selectedSliders.length > 1
  ) {
    selectedSliders.sort((selection1, selection2) =>
      compareSliderByTracks({
        slider1Id: selection1.sliderId,
        slider2Id: selection2.sliderId,
        sliders: updatedSliders,
      })
    );

    const firstSlider = updatedSliders[selectedSliders[0].sliderId];
    const {
      trackType: firstSliderTrackType,
      trackIndex: firstSliderTrackIndex,
    } = firstSlider.enterStart.position;
    const firstSliderTrack =
      tracks[firstSliderTrackType][firstSliderTrackIndex];
    moveTo = {
      // recalculate abosute position as drag could have started from any selected slider
      x: firstSlider.enterStart.position.x + moveBy.x,
      y:
        firstSlider.enterStart.position.y +
        firstSliderTrack.itemPlot.height / 2 +
        moveBy.y,
    };
  }

  if (canChangeTrack && selectedSliders.length > 0 && trackType !== null) {
    const slider = updatedSliders[selectedSliders[0].sliderId]; // checking specific selected slider as other sliders will be moved based on this slider
    const { trackType: originalTrackType, trackIndex: originalTrackIndex } =
      slider.enterStart.position;
    let currentTrackType = originalTrackType;
    let currentTrackIndex = originalTrackIndex;
    if (slider.enterStart.trackPosition) {
      currentTrackType = slider.enterStart.trackPosition.trackType;
      currentTrackIndex = slider.enterStart.trackPosition.trackIndex;
    }
    const originalTrackDetail = tracks[originalTrackType][originalTrackIndex];
    const currentTrackDetail = tracks[currentTrackType][currentTrackIndex];
    let toNewTrackAbove = false;
    let toNewTrackBelow = false;

    // NOTE:
    //  use itemPlot if space occupied by subtitle
    //    need to be considered as trackchange trigger region
    //  for now using overall plot of siblings for track change
    //  DO NOT USE subtitlePlot at any case unless subtitle supports track change (which will not happen for foreseeable future)!
    /** @type {"plot" | "itemPlot"} */
    const siblingPlotKey = "plot";

    let targetTrack = null;

    let trackTypesToCheck = [];
    if (slider.sliderType === SLIDER_TYPES.OBJECT) {
      if (selectionHasVisualMediaOnly && !selectionHasDifferentTracks) {
        trackTypesToCheck = [TRACK_TYPES.OBJECT /* , TRACK_TYPES.VIDEO */];
      } else {
        trackTypesToCheck = [TRACK_TYPES.OBJECT];
      }
    } /* else if (slider.sliderType === SLIDER_TYPES.VIDEO) {
      trackTypesToCheck = [TRACK_TYPES.OBJECT, TRACK_TYPES.VIDEO];
    } */ else if (slider.sliderType === SLIDER_TYPES.AUDIO) {
      trackTypesToCheck = [TRACK_TYPES.AUDIO];
    }

    for (let tcIdx = 0; tcIdx < trackTypesToCheck.length; tcIdx = tcIdx + 1) {
      const trackTypeToCheck = trackTypesToCheck[tcIdx];
      for (let t = 0; t < tracks[trackTypeToCheck].length; t = t + 1) {
        const trackDetail = tracks[trackTypeToCheck][t];
        /** flag to check whether condition to add new track is passed */
        let gapCheckPassed = false;

        if (
          !selectionHasDifferentTracks &&
          trackTypeToCheck !== TRACK_TYPES.VIDEO
        ) {
          // allow to insert new track only when all selected tracks are in same track
          // since selection in different tracks are difficult to handle
          const siblingTracks = [];

          if (tracks[trackTypeToCheck][t - 1]) {
            siblingTracks.push(tracks[trackTypeToCheck][t - 1]);
          }
          if (tracks[trackTypeToCheck][t + 1]) {
            siblingTracks.push(tracks[trackTypeToCheck][t + 1]);
          }
          if (tracks[trackTypeToCheck].length === 1 && t === 0) {
            const bottomDistance =
              trackDetail[siblingPlotKey].height +
              TRACK_OPTIONS[trackTypeToCheck].marginTop;
            const topDistance = -bottomDistance;

            /** @type {TrackDetail} */
            const dummyTopTrack = {
              ...trackDetail,
              [siblingPlotKey]: {
                ...trackDetail[siblingPlotKey],
                y: trackDetail[siblingPlotKey].y + topDistance,
              },
            };
            siblingTracks.push(dummyTopTrack);

            /** @type {TrackDetail} */
            const dummyBottomTrack = {
              ...trackDetail,
              [siblingPlotKey]: {
                ...trackDetail[siblingPlotKey],
                y: trackDetail[siblingPlotKey].y + bottomDistance,
              },
            };
            siblingTracks.push(dummyBottomTrack);
          } else if (
            tracks[trackTypeToCheck].length > 1 &&
            (t === 0 || t === tracks[trackTypeToCheck].length - 1)
          ) {
            let direction = 1;
            if (t === 0) {
              direction = -1;
              if (TRACK_OPTIONS[trackTypeToCheck].isReversed) {
                direction = 1;
              }
            } else if (t === tracks[trackTypeToCheck].length - 1) {
              direction = 1;
              if (TRACK_OPTIONS[trackTypeToCheck].isReversed) {
                direction = -1;
              }
            }
            const dummyTrackY =
              trackDetail[siblingPlotKey].y +
              direction *
                (trackDetail[siblingPlotKey].height +
                  TRACK_OPTIONS[trackTypeToCheck].marginTop);

            /** @type {TrackDetail} */
            const dummySiblingTrack = {
              ...trackDetail,
              [siblingPlotKey]: {
                ...trackDetail[siblingPlotKey],
                y: dummyTrackY,
              },
            };
            siblingTracks.push(dummySiblingTrack);
          }

          for (const siblingTrack of siblingTracks) {
            let topTrack = trackDetail;
            let bottomTrack = siblingTrack;

            if (
              siblingTrack[siblingPlotKey].y < trackDetail[siblingPlotKey].y
            ) {
              // sibling track is above current track
              topTrack = siblingTrack;
              bottomTrack = trackDetail;
            }

            const topTrackY2 =
              topTrack[siblingPlotKey].y + topTrack[siblingPlotKey].height;
            const trackGap = bottomTrack[siblingPlotKey].y - topTrackY2;
            const gapCenterY = topTrackY2 + trackGap * 0.5;
            let gapHeight = 15;
            if (
              TRACK_OPTIONS[trackTypeToCheck] &&
              typeof TRACK_OPTIONS[trackTypeToCheck] === "number"
            ) {
              gapHeight =
                TRACK_OPTIONS[trackTypeToCheck].newTrackTriggerRegionHeight;
            }
            if (gapHeight < trackGap) {
              // if gap between tracks is huge (because of subtitles), it will be really inconvinient for users to change tracks for smaller gapHeight
              // so use trackGap as trigger region
              gapHeight = trackGap;
            }

            const gapPlot = {
              x: bottomTrack[siblingPlotKey].x,
              y: gapCenterY - gapHeight * 0.5,
              width: bottomTrack[siblingPlotKey].width,
              height: gapHeight,
            };

            if (isPointWithinPlot({ plot: gapPlot, point: moveTo })) {
              gapCheckPassed = true;
              targetTrack = trackDetail;
              if (topTrack === trackDetail) {
                toNewTrackBelow = true;
              } else if (bottomTrack === trackDetail) {
                toNewTrackAbove = true;
              }
              break;
            }
          }
        }

        if (gapCheckPassed) {
          break;
        } else {
          const trackPlot = { ...trackDetail[siblingPlotKey] };
          if (trackTypeToCheck === TRACK_TYPES.VIDEO) {
            if (trackPlot.y <= moveTo.y) {
              targetTrack = trackDetail;
              break;
            }
          } else {
            let dh = 0;
            if (TRACK_OPTIONS[trackTypeToCheck].marginTop !== undefined) {
              dh = TRACK_OPTIONS[trackTypeToCheck].marginTop;
            }
            trackPlot.height += dh;
            if (TRACK_OPTIONS[trackTypeToCheck].isReversed) {
              trackPlot.y -= dh;
            }

            if (isPointWithinPlot({ plot: trackPlot, point: moveTo })) {
              targetTrack = trackDetail;
              break;
            }
          }
        }
      }

      if (targetTrack) {
        break;
      }
    }

    if (
      targetTrack &&
      (targetTrack !== currentTrackDetail || // different track is under mouse
        slider.toNewTrackAbove !== toNewTrackAbove ||
        slider.toNewTrackBelow !== toNewTrackBelow)
    ) {
      updatedSliders = { ...updatedSliders };
      if (selectionHasDifferentTracks) {
        const tracksToAdd =
          targetTrack.trackIndex - originalTrackDetail.trackIndex;
        for (const selection of selectedSliders) {
          // update all sliders
          const selectedSlider = updatedSliders[selection.sliderId];

          const selSliderOrgTrackIndex =
            selectedSlider.enterStart.position.trackIndex;
          const selSliderOrgTrackDetail =
            tracks[trackType][selSliderOrgTrackIndex];

          const selSliderTargetTrackIndex =
            selSliderOrgTrackIndex + tracksToAdd;
          let selSliderTargetTrackDetail =
            tracks[trackType][selSliderTargetTrackIndex];

          if (!selSliderTargetTrackDetail) {
            // slider will move to track which didn't exist, so create a temporary track to update track index in slider state
            /** @type {TrackDetail} */
            const newTrack = {
              plot: {
                // no need to set correct position for new tracks as recalculating all tracks and sliders will be expensive
                // just hide sliders related to this track in ui
                x: selSliderOrgTrackDetail.plot.x,
                y: 0,
                width: selSliderOrgTrackDetail.plot.width,
                height: 0,
              },
              trackType,
              trackIndex: selSliderTargetTrackIndex,
              itemIds: [],
            };
            newTrack.itemPlot = { ...newTrack.plot };
            selSliderTargetTrackDetail = newTrack;
          }

          let useRulerMinMax = false;
          if (TRACK_OPTIONS[selSliderOrgTrackDetail.trackType]) {
            useRulerMinMax =
              TRACK_OPTIONS[selSliderOrgTrackDetail.trackType]
                .allowTrimOnSameTrack;
          }
          if (
            moveType === "drag" &&
            selSliderOrgTrackDetail !== selSliderTargetTrackDetail
          ) {
            useRulerMinMax = true;
          }
          /** @type {Slider} */
          const updatedSlider = changeSliderTrack({
            slider: selectedSlider,
            targetTrack: selSliderTargetTrackDetail,
            useRulerMinMax,
            toNewTrackAbove: false,
            toNewTrackBelow: false,
            useOriginalPosition: false,
          });
          updatedSliders[updatedSlider.id] = updatedSlider;
        }
      } else {
        for (const selection of selectedSliders) {
          // update all sliders which are in same track
          const selectedSlider = updatedSliders[selection.sliderId];
          let useRulerMinMax = false;
          if (TRACK_OPTIONS[originalTrackDetail.trackType]) {
            useRulerMinMax =
              TRACK_OPTIONS[originalTrackDetail.trackType].allowTrimOnSameTrack;
          }
          if (moveType === "drag" && originalTrackDetail !== targetTrack) {
            useRulerMinMax = true;
          }
          /** @type {Slider} */
          const updatedSlider = changeSliderTrack({
            slider: selectedSlider,
            targetTrack,
            useRulerMinMax,
            toNewTrackAbove,
            toNewTrackBelow,
            useOriginalPosition: false,
          });
          updatedSliders[updatedSlider.id] = updatedSlider;
        }
      }
    }
  }

  return {
    updatedSliders,
  };
};

/**
 * @param {GetTrackPositionParams} params
 * @returns {GetTrackPositionReturn}
 */
export const getTrackPosition = (params = {}) => {
  const {
    slider,
    thumbIds = ["enterStart", "enterEnd", "exitStart", "exitEnd"],
  } = params;
  const positions = {};

  for (const thumbId of thumbIds) {
    if (slider[thumbId]) {
      let { position } = slider[thumbId];
      if (slider[thumbId].trackPosition) {
        position = slider[thumbId].trackPosition;
      }
      positions[thumbId] = position;
    }
  }

  return positions;
};

/**
 * @param {IsSelectedSliderParams} params
 */
const isSelectedSlider = (params = {}) => {
  const { selectedSliders, slider } = params;
  return selectedSliders.some((selectedSlider) => {
    return selectedSlider.sliderId === slider.id;
  });
};

/**
 * @param {UpdateSlidersOverlapParams} params
 */
const updateSlidersOverlap = (params = {}) => {
  const { selectedSliders, sliders, tracks } = params;
  const allowedSliderTypes = [
    SLIDER_TYPES.AUDIO,
    SLIDER_TYPES.OBJECT,
    SLIDER_TYPES.VIDEO,
  ];
  let updatedSliders = sliders;

  const isOverlap = selectedSliders.some((selection) => {
    const failed = false;
    const found = true;
    let status = failed;

    const slider = sliders[selection.sliderId];
    if (
      !slider ||
      !slider.enterStart ||
      !slider.exitEnd ||
      slider.toNewTrackAbove ||
      slider.toNewTrackBelow ||
      !allowedSliderTypes.includes(slider.sliderType)
    ) {
      return failed;
    }

    const { enterStart: enterStartPos, exitEnd: exitEndPos } = getTrackPosition(
      { slider, thumbIds: ["enterStart", "exitEnd"] }
    );
    if (!enterStartPos || !exitEndPos) {
      return failed;
    }

    const { trackIndex, trackType } = enterStartPos;
    if (!tracks[trackType] || !tracks[trackType][trackIndex]) {
      return failed;
    }

    const currentTrack = tracks[trackType][trackIndex];

    for (const currentItemId of currentTrack.itemIds) {
      const currentSlider = sliders[currentItemId];

      if (
        currentItemId === slider.id /* need not check same slider */ ||
        !currentSlider ||
        isSelectedSlider({
          selectedSliders,
          slider: currentSlider,
        }) /* we need to check unselected sliders alone */ ||
        !currentSlider.enterStart ||
        !currentSlider.exitEnd
      ) {
        continue; /* eslint-disable-line no-continue */
      }

      const curSldEnterStartPos = currentSlider.enterStart.position;
      const curSldExitEndPos = currentSlider.exitEnd.position;

      const selectedIsBefore = exitEndPos.step <= curSldEnterStartPos.step;
      const selectedIsAfter = enterStartPos.step >= curSldExitEndPos.step;

      if (selectedIsBefore) {
        // we can safely skip to next selected slider as track item ids are sorted and checking further is meaningless
        status = failed;
        break;
      } else if (!selectedIsBefore && !selectedIsAfter) {
        // it is safe to assume selected slider is overlapping with current slider since selection is neither before nor after
        status = found;
        break;
      }
    }

    return status;
  });

  if (isOverlap) {
    selectedSliders.forEach((selection) => {
      const slider = sliders[selection.sliderId];
      if (
        !slider ||
        !slider.enterStart ||
        !slider.exitEnd ||
        slider.toNewTrackAbove ||
        slider.toNewTrackBelow ||
        !allowedSliderTypes.includes(slider.sliderType)
      ) {
        return;
      }

      updatedSliders = {
        ...updatedSliders,
        [slider.id]: {
          ...slider,
          isOverlapping: true,
        },
      };
    });
  }

  return updatedSliders;
};

/**
 * Function to restrict slider/thumb movement (used in {@link moveSliders})
 * @param {RestrictSliderPosition} params
 */
const restrictSliderPosition = (params = {}) => {
  const {
    newPosition: _newPosition,
    direction,
    maximumStep,
    thumbPosition,
    stepper,
    alignWithStep,
  } = params;
  const newPosition = { ..._newPosition };

  if (
    typeof maximumStep === "number" &&
    Math.abs(thumbPosition.step - newPosition.step) > maximumStep
  ) {
    if (direction === "left") {
      newPosition.step = thumbPosition.step - maximumStep;
    } else if (direction === "right") {
      newPosition.step = thumbPosition.step + maximumStep;
    }
    newPosition.x =
      RULER_OPTIONS.paddingLeft + newPosition.step * stepper.stepSizePx;
  }

  if (alignWithStep) {
    newPosition.x =
      RULER_OPTIONS.paddingLeft + newPosition.step * stepper.stepSizePx;
  }

  return newPosition;
};

/**
 * moves selected sliders to specified position
 * @param {MoveSlidersParams} params
 */
export const moveSliders = (params = {}) => {
  const {
    moveTo,
    startedFrom,
    moveType,
    tracks,
    sliders,
    stepper,
    timelineSnapPoints,
    scrollType = "not-scrolling",
  } = params;
  let { selectedSliders } = params;

  let updatedSliders = sliders;
  const rulerSlider = updatedSliders[SLIDER_TYPES.RULER];
  let maximumStep = null; // maximum steps a slider can be moved
  let snapResult = null;
  let canSnap = false;

  /** @type {"overlap" | "block"} */
  let dragMode = "overlap";
  const fSelectedSliders = [];
  for (const selection of selectedSliders) {
    const selectedSlider = updatedSliders[selection.sliderId];
    if (selectedSlider && selectedSlider.isDraggable) {
      fSelectedSliders.push(selection);
      if (selection.thumbId && selectedSliders.length > 1) {
        dragMode = "block";
      }
    }
  }
  selectedSliders = fSelectedSliders;

  const moveBy = {
    x: moveTo.x - startedFrom.x,
    y: moveTo.y - startedFrom.y,
    step: 0,
  };
  let dragMoveBy = null;
  moveBy.step = scaleWithinRange({
    fromRange: {
      start: rulerSlider.enterStart.position.x,
      end: rulerSlider.exitEnd.position.x,
    },
    toRange: {
      start: rulerSlider.enterStart.position.step,
      end: rulerSlider.exitEnd.position.step,
    },
    num: rulerSlider.enterStart.position.x + moveBy.x,
  });
  let correctedStep = moveBy.step;
  if (Math.ceil(moveBy.step) - moveBy.step < RULER_OPTIONS.stepCorrection) {
    correctedStep = Math.ceil(moveBy.step);
  } else if (Math.ceil(moveBy.step) - moveBy.step < 0.5) {
    correctedStep = Math.ceil(moveBy.step);
  } else {
    correctedStep = Math.floor(moveBy.step);
  }
  moveBy.step = correctedStep;

  let direction = "still";
  if (moveBy.x < 0) {
    direction = "left";
  } else if (moveBy.x > 0) {
    direction = "right";
  }

  if (direction !== "still") {
    // sort selection before moving as one slider might block another when looping unsorted selection
    selectedSliders.sort((selection1, selection2) => {
      const slider1 = updatedSliders[selection1.sliderId];
      const slider2 = updatedSliders[selection2.sliderId];

      let result = 0;
      if (slider1 && slider2) {
        if (direction === "left") {
          // sort in ascending order as left most slider will move first
          result =
            slider1.enterStart.position.step - slider2.enterStart.position.step;
        } else {
          // sort in descending order as right most slider will move first
          result =
            slider2.enterStart.position.step - slider1.enterStart.position.step;
        }
      }

      return result;
    });

    for (const selection of selectedSliders) {
      const slider = updatedSliders[selection.sliderId];
      let isThumbDrag = false;
      /** @type {Thumb} */
      let thumb = null;
      if (selection.thumbId && slider[selection.thumbId]) {
        thumb = slider[selection.thumbId];
        isThumbDrag = true;
      } else if (direction === "left") {
        thumb = slider.enterStart;
      } else {
        thumb = slider.exitEnd ? slider.exitEnd : slider.enterStart; // playhead will have enterStart only
      }

      let curMaxStep;
      if (direction === "left") {
        let { min } = thumb;
        if (
          dragMode === "overlap" &&
          slider.useRulerMinMax &&
          thumb.id === "enterStart"
        ) {
          min = rulerSlider.enterStart.position;
        }
        curMaxStep = thumb.position.step - min.step;
      } else {
        let { max } = thumb;
        const isLastSliderOfTrack = max.sliderId === SLIDER_TYPES.RULER; // to allow last slider expand beyond project duration
        if (
          (dragMode === "overlap" || isLastSliderOfTrack) &&
          slider.useRulerMinMax &&
          thumb.id === "exitEnd"
        ) {
          max = rulerSlider.rulerEnd.position;
          if (
            rulerSlider.projectEnd &&
            rulerSlider.projectEnd.position.step <
              rulerSlider.rulerEnd.position.step
          ) {
            max = rulerSlider.projectEnd.position;
          }
        }
        curMaxStep = max.step - thumb.position.step;
      }

      if (isThumbDrag) {
        let mediaMaxStep;
        if (
          direction === "left" &&
          thumb.id === "enterStart" &&
          thumb.mediaMin
        ) {
          mediaMaxStep = thumb.position.step - thumb.mediaMin.step;
        } else if (
          direction === "right" &&
          thumb.id === "exitEnd" &&
          thumb.mediaMax
        ) {
          mediaMaxStep = thumb.mediaMax.step - thumb.position.step;
        }

        if (
          mediaMaxStep !== undefined &&
          (curMaxStep === undefined || mediaMaxStep < curMaxStep)
        ) {
          curMaxStep = mediaMaxStep;
        }
      }

      if (
        curMaxStep !== undefined &&
        (maximumStep === null || curMaxStep < maximumStep)
      ) {
        maximumStep = curMaxStep;
      }
    }
  }

  if (timelineSnapPoints) {
    snapResult = snapSelection({
      maximumStep,
      moveBy,
      selectedSliders,
      sliders: updatedSliders,
      stepper,
      timelineSnapPoints,
    });
    canSnap = snapResult.moveBy && snapResult.matchingSnapPoints;
    if (canSnap) {
      dragMoveBy = { ...moveBy };
      moveBy.step = snapResult.moveBy.step;
      moveBy.x = snapResult.moveBy.x;
      snapResult.matchingSnapPoints = setAligningSnapPoints({
        matchingSnapPoints: snapResult.matchingSnapPoints,
        timelineSnapPoints,
      });
    }
  }

  for (const selectedSlider of selectedSliders) {
    const slider = updatedSliders[selectedSlider.sliderId];
    /** @type {string[]} */
    let thumbsToMove = [];
    let isSliderMove = false;

    if (selectedSlider.thumbId) {
      if (selectedSlider.thumbId === "exitEnd") {
        thumbsToMove = ["exitStart", selectedSlider.thumbId];
      } else if (selectedSlider.thumbId === "enterStart") {
        thumbsToMove = ["enterStart", "enterEnd"];
      } else {
        thumbsToMove = [selectedSlider.thumbId];
      }
    } else {
      thumbsToMove = ["enterStart", "enterEnd", "exitStart", "exitEnd"];
      isSliderMove = true;
    }

    for (const thumbId of thumbsToMove) {
      /** @type {Thumb | undefined} */
      const thumb = slider[thumbId];
      if (thumb) {
        // playhead will only have enterStart
        let alignWithStep = RULER_OPTIONS.alignWithStep[slider.sliderType];
        if (
          moveType === "drag" &&
          RULER_OPTIONS.alignWithStepOnDrag[slider.sliderType] !== undefined
        ) {
          alignWithStep = RULER_OPTIONS.alignWithStepOnDrag[slider.sliderType];
        }
        if (scrollType === "scrolling") {
          alignWithStep =
            RULER_OPTIONS.scrollOptions[slider.sliderType]
              .alignWithStepOnScroll;
        } else if (scrollType === "scroll-end") {
          alignWithStep =
            RULER_OPTIONS.scrollOptions[slider.sliderType]
              .alignWithStepOnScrollEnd;
        }

        const { position } = thumb;
        let { trackPosition } = thumb;
        if (!trackPosition) {
          // first time this thumb might be being dragged
          trackPosition = { ...position };
        }

        let newPosition;
        if (moveType === "drag") {
          // thumb is dragging
          newPosition = { ...trackPosition };
          newPosition.step = position.step;
          newPosition.x = position.x;
        } else {
          // slider is moved (e.g. by clicking)
          newPosition = { ...position };
        }

        newPosition.x = newPosition.x + moveBy.x;
        newPosition.step = position.step + moveBy.step;

        newPosition = restrictSliderPosition({
          newPosition,
          maximumStep,
          direction,
          thumbPosition: thumb.position,
          alignWithStep,
          stepper,
        });

        /** @type {Thumb} */
        const thumbToUpdate = {
          // thumb
          ...updatedSliders[thumb.sliderId][thumb.id],
          trackPosition: undefined,
          dragPosition: undefined,
          position: thumb.position,
        };

        const canPlace = moveType === "place";

        if (moveType === "drag") {
          thumbToUpdate.trackPosition = newPosition;
          if (isSliderMove) {
            thumbToUpdate.dragPosition = { ...thumb.position };
            const move = dragMoveBy || moveBy;
            thumbToUpdate.dragPosition.x = thumb.position.x + move.x;
            thumbToUpdate.dragPosition.y = thumb.position.y + move.y;
            thumbToUpdate.dragPosition.step = thumbToUpdate.trackPosition.step;
          } else if (dragMoveBy) {
            thumbToUpdate.dragPosition = { ...thumb.position };
            thumbToUpdate.dragPosition.x = thumb.position.x + dragMoveBy.x;
            thumbToUpdate.dragPosition.step =
              thumb.position.step + dragMoveBy.step;
            thumbToUpdate.dragPosition = restrictSliderPosition({
              newPosition: thumbToUpdate.dragPosition,
              maximumStep,
              direction,
              thumbPosition: thumb.position,
              alignWithStep,
              stepper,
            });
          }
        } else if (canPlace) {
          if (isSliderMove && thumbId === "enterStart" && thumb.mediaMin) {
            thumbToUpdate.mediaMin = { ...newPosition };
            thumbToUpdate.mediaMin.step =
              thumb.mediaMin.step + (newPosition.step - thumb.position.step);
            thumbToUpdate.mediaMin.x =
              RULER_OPTIONS.paddingLeft +
              thumbToUpdate.mediaMin.step * stepper.stepSizePx;
          } else if (isSliderMove && thumbId === "exitEnd" && thumb.mediaMax) {
            thumbToUpdate.mediaMax = { ...newPosition };
            thumbToUpdate.mediaMax.step =
              thumb.mediaMax.step + (newPosition.step - thumb.position.step);
            thumbToUpdate.mediaMax.x =
              RULER_OPTIONS.paddingLeft +
              thumbToUpdate.mediaMax.step * stepper.stepSizePx;
          }

          if (
            isSliderMove &&
            (thumb.position.trackType === TRACK_TYPES.OBJECT ||
              thumb.position.trackType === TRACK_TYPES.AUDIO ||
              thumb.position.trackType === TRACK_TYPES.VIDEO) &&
            thumb.trackPosition
          ) {
            // to change track
            newPosition.trackIndex = thumb.trackPosition.trackIndex;
            newPosition.trackType = thumb.trackPosition.trackType;
          }

          thumbToUpdate.position = newPosition;
        }

        updatedSliders = {
          // sliders
          ...updatedSliders,
          [thumb.sliderId]: {
            // slider
            ...updatedSliders[thumb.sliderId],
            [thumb.id]: thumbToUpdate,
          },
        };
      }
    }

    if (
      moveType === "place" &&
      selectedSlider.sliderId !== SLIDER_TYPES.RULER &&
      selectedSlider.sliderId !== SLIDER_TYPES.PLAYHEAD_INDICATOR &&
      selectedSlider.sliderId !== SLIDER_TYPES.PLAYHEAD_THUMB
    ) {
      const enterStartThumb =
        updatedSliders[selectedSlider.sliderId].enterStart;
      const exitEndThumb = updatedSliders[selectedSlider.sliderId].exitEnd;
      const enterEndThumb = updatedSliders[selectedSlider.sliderId].enterEnd;
      const exitStartThumb = updatedSliders[selectedSlider.sliderId].exitStart;

      const updatedThumbs = updateThumbLimits({
        enterEndThumb,
        enterStartThumb,
        exitEndThumb,
        exitStartThumb,
        stepper,
        sliders: updatedSliders,
      });

      updatedSliders = {
        // sliders
        ...updatedSliders,
        [selectedSlider.sliderId]: {
          // slider
          ...updatedSliders[selectedSlider.sliderId],
          enterStart: updatedThumbs.enterStartThumb,
          exitEnd: updatedThumbs.exitEndThumb,
          enterEnd: updatedThumbs.enterEndThumb,
          exitStart: updatedThumbs.exitStartThumb,
        },
      };

      if (updatedThumbs.prevThumb) {
        updatedSliders = {
          // sliders
          ...updatedSliders,
          [updatedThumbs.prevThumb.sliderId]: {
            // slider
            ...updatedSliders[updatedThumbs.prevThumb.sliderId],
            [updatedThumbs.prevThumb.id]: updatedThumbs.prevThumb,
          },
        };
      }

      if (updatedThumbs.nextThumb) {
        updatedSliders = {
          // sliders
          ...updatedSliders,
          [updatedThumbs.nextThumb.sliderId]: {
            // slider
            ...updatedSliders[updatedThumbs.nextThumb.sliderId],
            [updatedThumbs.nextThumb.id]: updatedThumbs.nextThumb,
          },
        };
      }
    }

    updatedSliders = {
      ...updatedSliders,
      [slider.id]: {
        ...updatedSliders[slider.id],
        isOverlapping: false,
      },
    };
  }

  /** @type {MatchingSnapPoints} */
  let matchingSnapPoints = {};
  if (canSnap && moveType === "drag") {
    matchingSnapPoints = snapResult.matchingSnapPoints;
  }

  if (tracks) {
    updatedSliders = updateSlidersOverlap({
      selectedSliders,
      sliders: updatedSliders,
      tracks,
    });
  }

  return {
    sliders: updatedSliders,
    matchingSnapPoints,
  };
};

/**
 * @param {object} params
 * @param {"left" | "right"} params.direction
 * @param {SelectedSliders} params.selectedSliders
 * @param {Sliders} params.sliders
 * @param {StepperState} params.stepper
 * @param {TimelineSnapPoints} params.timelineSnapPoints
 * @param {number} params.timelineMode
 */
export const movePlayheadToNearestThumb = (params = {}) => {
  const { direction, timelineSnapPoints, sliders, stepper, timelineMode } = params;
  const playheadSlider = sliders[SLIDER_TYPES.PLAYHEAD_THUMB];
  const playheadThumb = playheadSlider.enterStart;

  const result = {
    selectedSlidersResult: null,
    moveSlidersResult: null,
    timelineSnapPointsResult: null,
  };

  const neighbourThumbs = getNeighbourThumbs({
    timelineSnapPoints,
    thumb: playheadThumb,
    direction,
    sliders,
    sliderTypes: timelineMode === TIMELINE_MODES.SUBTITLE ? [SLIDER_TYPES.SUBTITLE] : undefined,
  });
  let newPlayheadStep = null;

  if (direction === "right" && neighbourThumbs.rightStep !== null) {
    newPlayheadStep = neighbourThumbs.rightStep;
  } else if (direction === "left" && neighbourThumbs.leftStep !== null) {
    newPlayheadStep = neighbourThumbs.leftStep;
  } else {
    return result;
  }

  if (
    newPlayheadStep === null ||
    newPlayheadStep === playheadThumb.position.step
  ) {
    return result;
  }

  // // eslint-disable-next-line no-unused-vars
  // const willPlaceInSelectionRange =
  //   selectedSliders.length > 0 &&
  //   selectedSliders.some((selection) => {
  //     const slider = sliders[selection.sliderId];
  //     if (slider === playheadSlider) {
  //       return false;
  //     }

  //     const { enterStart } = slider;
  //     let { exitEnd } = slider;
  //     if (!exitEnd) {
  //       exitEnd = enterStart;
  //     }

  //     return !(
  //       newPlayheadStep < enterStart.position.step ||
  //       exitEnd.position.step < newPlayheadStep
  //     );
  //   });

  let thumb;

  if (direction === "right" && neighbourThumbs.rightStep !== null) {
    thumb = neighbourThumbs.right.find((t) => t.thumbId === "enterStart");
    if (!thumb) {
      [thumb] = neighbourThumbs.right;
    }
  } else if (direction === "left" && neighbourThumbs.leftStep !== null) {
    // thumb = neighbourThumbs.left.find((t) => t.thumbId === "exitEnd");
    thumb = neighbourThumbs.left.find((t) => t.thumbId === "enterStart");
    if (!thumb) {
      [thumb] = neighbourThumbs.left;
    }
  }

  if (thumb) {
    result.selectedSlidersResult = [{ sliderId: thumb.sliderId }];
  }

  result.moveSlidersResult = moveSliders({
    moveTo: {
      x: RULER_OPTIONS.paddingLeft + newPlayheadStep * stepper.stepSizePx,
    },
    startedFrom: { x: playheadThumb.position.x },
    moveType: "place",
    scrollType: "not-scrolling",
    selectedSliders: [
      { sliderId: playheadThumb.sliderId, thumbId: playheadThumb.id },
    ],
    sliders,
    stepper,
  });
  result.timelineSnapPointsResult = removeDefaultSnapPoints({
    timelineSnapPoints,
  });
  addDefaultSnapPoints({
    // it is okay to use mutation logic here as we have removed whole trace of default sliders
    mutableTimelineSnapPoints: result.timelineSnapPointsResult,
    sliders: result.moveSlidersResult.sliders,
  });

  return result;
};

/**
 * @param {object} params
 * @param {{ x: number, y: number }} params.mousePosition
 * @param {TrackGroup} params.tracks
 * @param {Sliders} params.sliders
 * @param {SelectedSliders} params.selectedSliders
 * @param {number} params.timelineMode
 */
export const addGapSlider = (params = {}) => {
  const { mousePosition, sliders, selectedSliders, tracks, timelineMode } =
    params;

  /** @type {Slider | null} */
  let gapSlider = null;

  let updatedSliders = sliders;
  const selectedGapSliders = selectedSliders
    .filter((selection) => {
      let result = false;
      const slider = updatedSliders[selection.sliderId];
      if (slider && slider.sliderType === SLIDER_TYPES.GAP) {
        result = true;
      }
      return result;
    })
    .map((selection) => updatedSliders[selection.sliderId]);

  for (const sliderId of Reflect.ownKeys(updatedSliders)) {
    const currentSlider = updatedSliders[sliderId];
    const { enterStart, exitEnd } = currentSlider;
    const { trackType, trackIndex } = currentSlider.enterStart.position;

    let canAddGap = false;
    if (timelineMode === TIMELINE_MODES.MAIN) {
      canAddGap =
        currentSlider.sliderType === SLIDER_TYPES.AUDIO ||
        currentSlider.sliderType === SLIDER_TYPES.OBJECT ||
        currentSlider.sliderType === SLIDER_TYPES.VIDEO;
    } else if (timelineMode === TIMELINE_MODES.SUBTITLE) {
      canAddGap = currentSlider.sliderType === SLIDER_TYPES.SUBTITLE;
    }

    let track = null;
    if (tracks[trackType] && tracks[trackType][trackIndex]) {
      track = tracks[trackType][trackIndex];
    }

    if (track && exitEnd) {
      let trackPlot = track.itemPlot;
      if (
        currentSlider.sliderType === SLIDER_TYPES.SUBTITLE ||
        (currentSlider.sliderType === SLIDER_TYPES.GAP &&
          currentSlider.itemType === SLIDER_GAP_TYPES.SUBTITLE)
      ) {
        trackPlot = track.subtitlePlot;
      }

      const sliderPlot = {
        x: enterStart.position.x,
        width: exitEnd.position.x - enterStart.position.x,
        y: enterStart.position.y,
        height: trackPlot.height,
      };

      const isMouseOnSlider = isPointWithinPlot({
        plot: sliderPlot,
        point: mousePosition,
      });
      if (
        !isMouseOnSlider &&
        currentSlider.sliderType === SLIDER_TYPES.GAP &&
        !selectedGapSliders.includes(currentSlider)
      ) {
        updatedSliders = { ...updatedSliders };
        delete updatedSliders[currentSlider.id];
      } else if (canAddGap && !isMouseOnSlider) {
        let prevThumb;
        if (
          enterStart.min &&
          updatedSliders[enterStart.min.sliderId] &&
          updatedSliders[enterStart.min.sliderId][enterStart.min.thumbId] &&
          enterStart.min.step < enterStart.position.step &&
          currentSlider.isDraggable
        ) {
          prevThumb =
            updatedSliders[enterStart.min.sliderId][enterStart.min.thumbId];
        }

        let nextThumb;
        if (
          exitEnd.max &&
          updatedSliders[exitEnd.max.sliderId] &&
          updatedSliders[exitEnd.max.sliderId][exitEnd.max.thumbId] &&
          exitEnd.max.step > exitEnd.position.step &&
          updatedSliders[exitEnd.max.sliderId].isDraggable
        ) {
          if (
            currentSlider.sliderType !== SLIDER_TYPES.SUBTITLE ||
            (currentSlider.sliderType === SLIDER_TYPES.SUBTITLE &&
              !currentSlider.subtitleId.isEnd)
          ) {
            nextThumb =
              updatedSliders[exitEnd.max.sliderId][exitEnd.max.thumbId];
          }
        }

        const leftGapDetail = {
          plot: {
            x: 0,
            y: trackPlot.y,
            width: 0,
            height: trackPlot.height,
          },
          /** @type {ThumbPosition | null} */
          leftThumbPosition: null,
          /** @type {ThumbPosition | null} */
          rightThumbPosition: null,
        };
        const rightGapDetail = {
          plot: {
            x: 0,
            y: trackPlot.y,
            width: 0,
            height: trackPlot.height,
          },
          /** @type {ThumbPosition | null} */
          leftThumbPosition: null,
          /** @type {ThumbPosition | null} */
          rightThumbPosition: null,
        };

        if (prevThumb) {
          const prevSlider = updatedSliders[prevThumb.sliderId];

          leftGapDetail.plot.x = prevThumb.position.x;
          leftGapDetail.plot.width =
            enterStart.position.x - leftGapDetail.plot.x;
          if (
            prevSlider.id === SLIDER_TYPES.RULER ||
            (currentSlider.sliderType === SLIDER_TYPES.SUBTITLE &&
              prevSlider.sliderType !== SLIDER_TYPES.SUBTITLE)
          ) {
            leftGapDetail.leftThumbPosition = {
              sliderId: prevThumb.position.sliderId,
              step: prevThumb.position.step,
              thumbId: prevThumb.position.thumbId,
              trackIndex: enterStart.position.trackIndex,
              trackType: enterStart.position.trackType,
              x: prevThumb.position.x,
              y: enterStart.position.y,
            };
          } else {
            leftGapDetail.leftThumbPosition = prevThumb.position;
          }
          leftGapDetail.rightThumbPosition = enterStart.position;
        }
        if (nextThumb) {
          rightGapDetail.plot.x = exitEnd.position.x;
          rightGapDetail.plot.width = nextThumb.position.x - exitEnd.position.x;
          rightGapDetail.leftThumbPosition = exitEnd.position;
          rightGapDetail.rightThumbPosition = nextThumb.position;
        }

        const gapsToCheck = [leftGapDetail, rightGapDetail];
        for (const gapDetail of gapsToCheck) {
          if (
            gapDetail.leftThumbPosition &&
            gapDetail.rightThumbPosition &&
            isPointWithinPlot({ plot: gapDetail.plot, point: mousePosition })
          ) {
            const gapSliderId = `gap${gapDetail.leftThumbPosition.sliderId}${gapDetail.rightThumbPosition.sliderId}`;
            gapSlider = {
              enterStart: {
                id: "enterStart",
                isDragging: false,
                position: {
                  sliderId: gapSliderId,
                  step: gapDetail.leftThumbPosition.step,
                  thumbId: "enterStart",
                  trackIndex: gapDetail.leftThumbPosition.trackIndex,
                  trackType: gapDetail.leftThumbPosition.trackType,
                  x: gapDetail.leftThumbPosition.x,
                  y: gapDetail.leftThumbPosition.y,
                },
                sliderId: gapSliderId,
              },
              exitEnd: {
                id: "exitEnd",
                isDragging: false,
                position: {
                  sliderId: gapSliderId,
                  step: gapDetail.rightThumbPosition.step,
                  thumbId: "exitEnd",
                  trackIndex: gapDetail.rightThumbPosition.trackIndex,
                  trackType: gapDetail.rightThumbPosition.trackType,
                  x: gapDetail.rightThumbPosition.x,
                  y: gapDetail.rightThumbPosition.y,
                },
                sliderId: gapSliderId,
              },
              id: gapSliderId,
              itemId: gapSliderId,
              itemType: SLIDER_GAP_TYPES.ITEM,
              itemSubType: SLIDER_GAP_TYPES.ITEM,
              sliderType: SLIDER_TYPES.GAP,
              isLocked: false,
              isDraggable: false,
              toNewTrackAbove: false,
              toNewTrackBelow: false,
              isOverlapping: false,
              gapSliderDetail: {
                leftThumbPosition: { ...gapDetail.leftThumbPosition },
                rightThumbPosition: { ...gapDetail.rightThumbPosition },
              },
            };
            if (currentSlider.sliderType === SLIDER_TYPES.SUBTITLE) {
              gapSlider.itemType = SLIDER_GAP_TYPES.SUBTITLE;
              gapSlider.itemSubType = SLIDER_GAP_TYPES.SUBTITLE;
            }
            updatedSliders = {
              ...updatedSliders,
              [gapSliderId]: gapSlider,
            };
            break;
          }
        }
      }
    }
  }

  return {
    updatedSliders,
    gapSlider,
  };
};

/**
 * @param {GetMagnetSliderIdsParams} params
 */
export const getMagnetSliderIds = (params = {}) => {
  const { selectedSliders, sliders } = params;

  const slidersGroupedByTracks = {};
  let canUseMagnet = false;

  for (const selection of selectedSliders) {
    const slider = sliders[selection.sliderId];
    const allowedSliderTypes = [
      SLIDER_TYPES.AUDIO,
      SLIDER_TYPES.OBJECT,
      SLIDER_TYPES.VIDEO,
    ];
    if (
      slider &&
      slider.isDraggable &&
      allowedSliderTypes.includes(slider.sliderType)
    ) {
      const { trackType, trackIndex } = slider.enterStart.position;
      if (!slidersGroupedByTracks[trackType]) {
        slidersGroupedByTracks[trackType] = {};
      }
      if (!slidersGroupedByTracks[trackType][trackIndex]) {
        slidersGroupedByTracks[trackType][trackIndex] = [];
      }
      slidersGroupedByTracks[trackType][trackIndex].push(slider.id);
      if (slidersGroupedByTracks[trackType][trackIndex].length >= 2) {
        canUseMagnet = true;
      }
    }
  }

  return {
    slidersGroupedByTracks,
    canUseMagnet,
  };
};

/**
 * @param {GetGapSliderIds} params
 */
export const getGapSliderIds = (params = {}) => {
  const { deleteType, selectedSliders, sliders } = params;

  let canDeleteGap = false;
  let slidersGroupedByTracks = {};

  const gapSliderSelection = selectedSliders.find((selection) => {
    return (
      sliders[selection.sliderId] &&
      sliders[selection.sliderId].sliderType === SLIDER_TYPES.GAP
    );
  });
  if (gapSliderSelection && sliders[gapSliderSelection.sliderId]) {
    const gapSlider = sliders[gapSliderSelection.sliderId];

    if (
      deleteType === "gap-delete-all" ||
      deleteType === "gap-delete-single-track"
    ) {
      for (const sliderId of Reflect.ownKeys(sliders)) {
        const slider = sliders[sliderId];
        const allowedSliderTypes = [
          SLIDER_TYPES.AUDIO,
          SLIDER_TYPES.OBJECT,
          SLIDER_TYPES.VIDEO,
        ];
        if (
          slider &&
          slider.isDraggable &&
          allowedSliderTypes.includes(slider.sliderType)
        ) {
          const { trackType, trackIndex } = slider.enterStart.position;
          if (!slidersGroupedByTracks[trackType]) {
            slidersGroupedByTracks[trackType] = {};
          }
          if (!slidersGroupedByTracks[trackType][trackIndex]) {
            slidersGroupedByTracks[trackType][trackIndex] = [];
          }
          slidersGroupedByTracks[trackType][trackIndex].push(slider.id);
          if (slidersGroupedByTracks[trackType][trackIndex].length >= 1) {
            canDeleteGap = true;
          }
        }
      }

      if (deleteType === "gap-delete-single-track") {
        const { trackType, trackIndex } = gapSlider.enterStart.position;
        if (
          slidersGroupedByTracks[trackType] &&
          slidersGroupedByTracks[trackType][trackIndex] &&
          slidersGroupedByTracks[trackType][trackIndex].length > 0
        ) {
          slidersGroupedByTracks = {
            [trackType]: {
              [trackIndex]: slidersGroupedByTracks[trackType][trackIndex],
            },
          };
          canDeleteGap = true;
        } else {
          slidersGroupedByTracks = {};
          canDeleteGap = false;
        }
      }
    } else if (
      deleteType === "gap-delete-selected" &&
      gapSlider.gapSliderDetail &&
      gapSlider.gapSliderDetail.leftThumbPosition &&
      sliders[gapSlider.gapSliderDetail.leftThumbPosition.sliderId] &&
      gapSlider.gapSliderDetail.rightThumbPosition &&
      sliders[gapSlider.gapSliderDetail.rightThumbPosition.sliderId]
    ) {
      let { leftThumbPosition, rightThumbPosition } = gapSlider.gapSliderDetail;
      if (leftThumbPosition.sliderId === SLIDER_TYPES.RULER) {
        leftThumbPosition = null;
      }
      if (rightThumbPosition.sliderId === SLIDER_TYPES.RULER) {
        rightThumbPosition = null;
      }

      for (const thumbPos of [leftThumbPosition, rightThumbPosition]) {
        if (thumbPos && thumbPos.sliderId !== SLIDER_TYPES.RULER) {
          const { sliderId, trackType, trackIndex } = thumbPos;
          if (!slidersGroupedByTracks[trackType]) {
            slidersGroupedByTracks[trackType] = {};
          }
          if (!slidersGroupedByTracks[trackType][trackIndex]) {
            slidersGroupedByTracks[trackType][trackIndex] = [];
          }
          slidersGroupedByTracks[trackType][trackIndex].push(sliderId);
          canDeleteGap = true;
        }
      }
    }
  }

  return {
    canDeleteGap,
    slidersGroupedByTracks,
  };
};

/**
 * @param {RemoveSliderGapsParams} params
 */
export const removeSliderGaps = (params = {}) => {
  const { deleteType, selectedSliders, sliders } = params;

  let updatedSliders = sliders;
  let slidersGroupedByTracks;
  const updatedSliderIds = [];
  let canProcess = false;

  if (deleteType === "magnet") {
    const magnetResult = getMagnetSliderIds({ selectedSliders, sliders });
    slidersGroupedByTracks = magnetResult.slidersGroupedByTracks;
    canProcess = magnetResult.canUseMagnet;
  } else if (deleteType.includes("gap-delete")) {
    const gapResult = getGapSliderIds({ deleteType, selectedSliders, sliders });
    slidersGroupedByTracks = gapResult.slidersGroupedByTracks;
    canProcess = gapResult.canDeleteGap;
  }

  if (slidersGroupedByTracks && canProcess) {
    for (const trackType of Reflect.ownKeys(slidersGroupedByTracks)) {
      const track = slidersGroupedByTracks[trackType];
      for (let trackIndex of Reflect.ownKeys(track)) {
        trackIndex = Number(trackIndex);
        const sliderIdList = track[trackIndex];
        sliderIdList.sort((slider1Id, slider2Id) => {
          const slider1 = sliders[slider1Id];
          const slider2 = sliders[slider2Id];
          return slider1.enterStart.position.x - slider2.enterStart.position.x;
        });

        for (let idIdx = 0; idIdx < sliderIdList.length; idIdx += 1) {
          const currentSlider = updatedSliders[sliderIdList[idIdx]];
          let prevSliderThumbPosition;

          if (
            idIdx > 0 ||
            (idIdx === 0 &&
              (deleteType === "gap-delete-all" ||
                deleteType === "gap-delete-single-track" ||
                (deleteType === "gap-delete-selected" &&
                  sliderIdList.length === 1)))
          ) {
            if (
              currentSlider.enterStart.min &&
              updatedSliders[currentSlider.enterStart.min.sliderId] &&
              updatedSliders[currentSlider.enterStart.min.sliderId][
                currentSlider.enterStart.min.thumbId
              ]
            ) {
              prevSliderThumbPosition =
                updatedSliders[currentSlider.enterStart.min.sliderId][
                  currentSlider.enterStart.min.thumbId
                ].position;
            }
          }

          const usePrevSliderPosition =
            currentSlider.enterStart.min &&
            currentSlider.enterStart.min.sliderId !== SLIDER_TYPES.RULER &&
            updatedSliders[currentSlider.enterStart.min.sliderId] &&
            updatedSliders[currentSlider.enterStart.min.sliderId][
              currentSlider.enterStart.min.thumbId
            ] &&
            ((deleteType === "magnet" &&
              idIdx > 0 &&
              !sliderIdList.includes(currentSlider.enterStart.min.sliderId)) ||
              ((deleteType === "gap-delete-all" ||
                deleteType === "gap-delete-single-track") &&
                !updatedSliders[currentSlider.enterStart.min.sliderId]
                  .isDraggable));
          if (usePrevSliderPosition) {
            prevSliderThumbPosition =
              updatedSliders[currentSlider.enterStart.min.sliderId][
                currentSlider.enterStart.min.thumbId
              ].position;
          }

          if (prevSliderThumbPosition) {
            let updatedSlider = updatedSliders[sliderIdList[idIdx]];

            let stepsToMove = 0;
            let xToMove = 0;
            const thumbsToMove = [
              "enterStart",
              "enterEnd",
              "exitStart",
              "exitEnd",
            ];

            for (const thumbKey of thumbsToMove) {
              if (updatedSlider[thumbKey]) {
                let updatedThumbPosition = updatedSlider[thumbKey].position;

                if (thumbKey === "enterStart") {
                  const enterStartPosition = updatedSlider.enterStart.position;
                  stepsToMove =
                    prevSliderThumbPosition.step - enterStartPosition.step;
                  xToMove = prevSliderThumbPosition.x - enterStartPosition.x;
                }

                let mediaKey = "";
                if (thumbKey === "enterStart") {
                  mediaKey = "mediaMin";
                } else if (thumbKey === "exitEnd") {
                  mediaKey = "mediaMax";
                }

                if (mediaKey && updatedSlider[thumbKey][mediaKey]) {
                  const mediaThumbPosition = {
                    ...updatedSlider[thumbKey][mediaKey],
                    x: updatedSlider[thumbKey][mediaKey].x + xToMove,
                    step: updatedSlider[thumbKey][mediaKey].step + stepsToMove,
                  };
                  updatedSlider = {
                    ...updatedSlider,
                    [thumbKey]: {
                      ...updatedSlider[thumbKey],
                      [mediaKey]: mediaThumbPosition,
                    },
                  };
                }

                updatedThumbPosition = {
                  ...updatedThumbPosition,
                  x: updatedThumbPosition.x + xToMove,
                  step: updatedThumbPosition.step + stepsToMove,
                };
                updatedSlider = {
                  ...updatedSlider,
                  [thumbKey]: {
                    ...updatedSlider[thumbKey],
                    position: updatedThumbPosition,
                  },
                };
              }
            }

            updatedSliders = {
              ...updatedSliders,
              [updatedSlider.id]: updatedSlider,
            };
            updatedSliderIds.push({
              sliderId: updatedSlider.id,
              thumbId: "",
            });
          }
        }
      }
    }
  }

  return {
    updatedSliderIds,
    updatedSliders,
  };
};

/**
 * selects scrollOptions with large threshold if multiple slider types are selected
 * @param {GetScrollOptionsParams} params
 * @returns scrollOptions or null if nothing is selected
 */
export const getScrollOptions = (params = {}) => {
  const { selectedSliders, sliders } = params;

  let scrollOptions = null;

  for (const selectedSlider of selectedSliders) {
    const slider = sliders[selectedSlider.sliderId];
    const currentScrollOptions = RULER_OPTIONS.scrollOptions[slider.sliderType];
    if (
      scrollOptions === null ||
      scrollOptions.clientExtremeThreshold <
        currentScrollOptions.clientExtremeThreshold
    ) {
      scrollOptions = currentScrollOptions;
    }
  }

  return scrollOptions;
};

/**
 * function to get index of slider in selection state
 * @param {GetSliderSelectionIndexParams} params
 */
export const getSliderSelectionIndex = (params = {}) => {
  const { selectedSliders, slider } = params;

  let selectionIndex = -1;
  const isSelected = selectedSliders.some((selectedSlider, index) => {
    if (selectedSlider.sliderId === slider.id) {
      selectionIndex = index;
      return true;
    }
    return false;
  });

  return { isSelected, selectionIndex };
};

/**
 * Function to check whether slider will be visible to the user
 * @param {IsSliderInViewParams} params
 */
export const isSliderInView = (params = {}) => {
  const {
    slider,
    timelineWidth,
    timelineScroll,
    trackListHeight,
    trackHeight,
  } = params;
  const {
    bufferWidth = timelineWidth * 0.5,
    bufferHeight = trackListHeight * 0.5,
  } = params;

  const viewPlot = {
    x: timelineScroll.x - bufferWidth,
    y: timelineScroll.y - bufferHeight,
    width: timelineWidth + bufferWidth,
    height: trackListHeight + bufferHeight,
  };

  const { enterStart, exitEnd } = getTrackPosition({
    slider,
    thumbIds: ["enterStart", "exitEnd"],
  });
  const sliderPlot = {
    x: enterStart.x,
    y: enterStart.y,
    width: exitEnd.x - enterStart.x,
    height: trackHeight,
  };

  const isXWithinBounds = !(
    // AABB collision test
    (
      sliderPlot.x + sliderPlot.width < viewPlot.x ||
      sliderPlot.x > viewPlot.x + viewPlot.width
    )
  );
  const isYWithinBounds = !(
    // AABB collision test
    (
      sliderPlot.y + sliderPlot.height < viewPlot.y ||
      sliderPlot.y > viewPlot.y + viewPlot.height
    )
  );

  return isXWithinBounds && isYWithinBounds;
};

/**
 * Returns sliders under multi-select selection area. Video tracks will not be part of selection.
 * @param {GetSlidersUnderSelectionParams} params
 * @returns {SelectedSliders}
 */
export const getSlidersUnderSelection = (params = {}) => {
  const { plot: selectionPlot, sliders, tracks } = params;
  const selectableTracks = [
    TRACK_TYPES.OBJECT,
    // TRACK_TYPES.VIDEO,
    TRACK_TYPES.AUDIO,
  ]; // order here should sync up with the order shown in ui (track position)
  const selectedSliders = [];

  for (const trackType of selectableTracks) {
    const trackList = tracks[trackType] ? tracks[trackType] : [];
    for (const track of trackList) {
      const itemTrackHeight = track.itemPlot.height;
      for (const itemId of track.itemIds) {
        const slider = sliders[itemId];
        if (slider && slider.isDraggable) {
          const sliderX = slider.enterStart.position.x;
          const sliderX2 = slider.exitEnd.position.x;
          const sliderY = slider.enterStart.position.y;
          const sliderY2 = slider.exitEnd.position.y + itemTrackHeight;

          const isXWithinBounds = !(
            // AABB collision test
            (
              sliderX2 < selectionPlot.x ||
              sliderX > selectionPlot.x + selectionPlot.width
            )
          );
          const isYWithinBounds = !(
            // AABB collision test
            (
              sliderY2 < selectionPlot.y ||
              sliderY > selectionPlot.y + selectionPlot.height
            )
          );

          if (isXWithinBounds && isYWithinBounds) {
            selectedSliders.push({ sliderId: slider.id });
          }
        }
      }
    }
  }

  return selectedSliders;
};

/**
 * Function to get time of moved sliders/thumbs
 * @param {GetUpdatedTimelineParams} params
 */
export const getUpdatedTimeline = (params = {}) => {
  const { project, selectedSliders, sliders } = params;
  const toUpdate = [];

  for (const selectedSlider of selectedSliders) {
    const { sliderId, thumbId } = selectedSlider;
    const isDefaultSlider =
      sliderId === SLIDER_TYPES.RULER ||
      sliderId === SLIDER_TYPES.PLAYHEAD_THUMB ||
      sliderId === SLIDER_TYPES.PLAYHEAD_INDICATOR ||
      sliderId === SLIDER_TYPES.GAP;

    if (!isDefaultSlider) {
      const slider = sliders[sliderId];
      const {
        enterStart,
        enterEnd,
        exitEnd,
        exitStart,
        toNewTrackAbove,
        toNewTrackBelow,
        sliderType,
      } = slider;
      const { trackType, trackIndex } = slider.enterStart.position;
      const { min } = slider.enterStart;
      const { max } = slider.exitEnd;
      const prevSlider = sliders[min && min.sliderId];
      const nextSlider = sliders[max && max.sliderId];

      const currentEnterPosition = slider?.enterStart?.position.step;
      const currentExitPosition = slider?.exitEnd?.position.step;
      const prevExitPosition = prevSlider?.exitEnd?.position.step;
      const nextEnterPosition = nextSlider?.enterStart.position.step;
      const prevItemId = prevSlider?.exitEnd?.sliderId;
      const nextItemId = nextSlider?.enterStart?.sliderId;

      if (sliderType === SLIDER_TYPES.OBJECT) {
        if (trackType === TRACK_TYPES.OBJECT) {
          // obj to obj track
          const enterStartTime =
            enterStart.position.step * RULER_OPTIONS.interval;
          const exitEndTime = exitEnd.position.step * RULER_OPTIONS.interval;
          const enterEndTime = enterEnd
            ? enterEnd.position.step * RULER_OPTIONS.interval
            : undefined;
          const exitStartTime = exitStart
            ? exitStart.position.step * RULER_OPTIONS.interval
            : undefined;
          const itemUpdate = {
            container: "workspaceItems",
            id: slider.itemId,
            toUpdate: {},
            isNewTrack: false,
          };

          const prevItemUpdate = {
            container: "workspaceItems",
            id: prevItemId,
            toUpdate: {},
            isNewTrack: false,
          };

          const nextItemUpdate = {
            container: "workspaceItems",
            id: nextItemId,
            toUpdate: {},
            isNewTrack: false,
          };

          const item = project.getIn(["workspaceItems", slider.itemId]);

          if ((currentEnterPosition !== prevExitPosition) && prevItemId !== SLIDER_TYPES.RULER) {
            // For transitions, IF there is an gap between applied transition videos,have to reset the transitions
            const prevItem = project.getIn(["workspaceItems", prevItemId]);
            if (item && item.get("transitionExitId") && item.get("transitionExitId") !== "none") {
              itemUpdate.toUpdate.transitionExitId = "none";
            }

            if (prevItem && prevItem.get("transitionEnterId") && prevItem.get("transitionEnterId") !== "none") {
              prevItemUpdate.toUpdate.transitionEnterId = "none";
            }
          }
          // For transitions 
          if ((currentExitPosition !== nextEnterPosition) && nextItemId !== SLIDER_TYPES.RULER) {
            const nextItem = project.getIn(["workspaceItems", nextItemId]);
            if (item && item.get("transitionEnterId") && item.get("transitionEnterId") !== "none") {
              itemUpdate.toUpdate.transitionEnterId = "none";
            }

            if (nextItem && nextItem.get("transitionExitId") && nextItem.get("transitionExitId") !== "none") {
              nextItemUpdate.toUpdate.transitionExitId = "none";
            }
          }

          if (enterStartTime !== item.get("enterStart")) {
            itemUpdate.toUpdate.enterStart = enterStartTime;
          }
          if (exitEndTime !== item.get("exitEnd")) {
            itemUpdate.toUpdate.exitEnd = exitEndTime;
          }
          if (
            enterEndTime !== undefined &&
            enterEndTime !== item.get("enterEnd")
          ) {
            itemUpdate.toUpdate.enterEnd = enterEndTime;
          }
          if (
            exitStartTime !== undefined &&
            exitStartTime !== item.get("exitStart")
          ) {
            itemUpdate.toUpdate.exitStart = exitStartTime;
          }
          if (
            enterStart.mediaMin &&
            exitEnd.mediaMax &&
            isVideoOnly(item.get("type"), item.get("subType"))
          ) {
            const speed = Number.isFinite(item.get("speed")) ? item.get("speed") : 1;
            const videoStartTime =
              (enterStart.position.step - enterStart.mediaMin.step) *
              RULER_OPTIONS.interval * speed;
            const videoEndTime =
              videoStartTime +
              (exitEnd.position.step - enterStart.position.step) *
                RULER_OPTIONS.interval * speed;
            if (videoStartTime !== item.get("videoStart")) {
              itemUpdate.toUpdate.videoStart = videoStartTime;
            }
            if (videoEndTime !== item.get("videoEnd")) {
              itemUpdate.toUpdate.videoEnd = videoEndTime;
            }
          }
          if (
            trackIndex !== item.get("track") ||
            toNewTrackAbove ||
            toNewTrackBelow
          ) {

            if ((prevItemId !== SLIDER_TYPES.RULER) && (nextItemId !== SLIDER_TYPES.RULER)) {
              // For transitions, IF track change , need to reset the transitions
              const prevItem = project.getIn(["workspaceItems", prevItemId]);
              const nextItem = project.getIn(["workspaceItems", nextItemId]);

              if (item && item.get("transitionExitId") && item.get("transitionExitId") !== "none") {
                itemUpdate.toUpdate.transitionExitId = "none";
              }

              if (item && item.get("transitionEnterId") && item.get("transitionEnterId") !== "none") {
                itemUpdate.toUpdate.transitionEnterId = "none";
              }

              if (prevItem && prevItem.get("transitionEnterId") && prevItem.get("transitionEnterId") !== "none") {
                prevItemUpdate.toUpdate.transitionEnterId = "none";
              }

              if (nextItem && nextItem.get("transitionExitId") && nextItem.get("transitionExitId") !== "none") {
                nextItemUpdate.toUpdate.transitionExitId = "none";
              }
            }

            if ( (prevExitPosition !== currentEnterPosition) && prevItemId !== SLIDER_TYPES.RULER) {
              // For transitions, IF there is an gap between applied transition videos,have to reset the transitions
              const prevItem = project.getIn(["workspaceItems", prevItemId]);
              if (item && item.get("transitionExitId") && item.get("transitionExitId") !== "none") {
                itemUpdate.toUpdate.transitionExitId = "none";
              }

              if (prevItem && prevItem.get("transitionEnterId") && prevItem.get("transitionEnterId") !== "none") {
                prevItemUpdate.toUpdate.transitionEnterId = "none";
              }
            }
            // For transitions 
            if ((nextEnterPosition !== currentExitPosition) && nextItemId !== SLIDER_TYPES.RULER) {
              const nextItem = project.getIn(["workspaceItems", nextItemId]);
              if (item && item.get("transitionEnterId") && item.get("transitionEnterId") !== "none") {
                itemUpdate.toUpdate.transitionEnterId = "none";
              }

              if (nextItem && nextItem.get("transitionExitId") && nextItem.get("transitionExitId") !== "none") {
                nextItemUpdate.toUpdate.transitionExitId = "none";
              }
            }

            itemUpdate.toUpdate.track = trackIndex;
            if (toNewTrackAbove) {
              itemUpdate.toUpdate.track += 1;
              itemUpdate.isNewTrack = true;
            } else if (toNewTrackBelow) {
              itemUpdate.toUpdate.track -= 1;
              itemUpdate.isNewTrack = true;
            }
          }
          if (Reflect.ownKeys(itemUpdate.toUpdate).length) {
            toUpdate.push(itemUpdate);
          }
         
          if (Reflect.ownKeys(prevItemUpdate.toUpdate).length) {
            toUpdate.push(prevItemUpdate);
          }

          if (Reflect.ownKeys(nextItemUpdate.toUpdate).length) {
            toUpdate.push(nextItemUpdate);
          }
          
        } else if (trackType === TRACK_TYPES.VIDEO) {
          // obj to video track
          const sourceContainer = "workspaceItems";
          const item = project.getIn([sourceContainer, slider.itemId]);
          const playStartTime =
            enterStart.position.step * RULER_OPTIONS.interval;
          let playEndTime = exitEnd.position.step * RULER_OPTIONS.interval;
          let videoStart = 0;
          let videoEnd = 0;
          let videoDuration = 0;

          const videoUpdate = {
            container: "workspaceBG",
            id: slider.itemId,
            newItemData: {},
            isObjToVid: true,
            fromContainer: sourceContainer,
          };

          if (isImageOnly(item.get("type"), item.get("subType"))) {
            const maxDuration =
              TRACK_OPTIONS[TRACK_TYPES.VIDEO].nonVideoMaxDuration;
            if (playEndTime - playStartTime > maxDuration) {
              playEndTime = playStartTime + maxDuration;
            }
            videoDuration = playEndTime - playStartTime;
            videoEnd = videoDuration;
          } else {
            // video
            videoStart = item.get("videoStart");
            videoEnd = item.get("videoEnd");
            videoDuration = item.get("videoDuration");

            const playDuration = videoEnd - videoStart;
            const actualPlayDuration = playEndTime - playStartTime;
            if (actualPlayDuration < playDuration) {
              // user has implicitily trimmed the duration of video by not changing the videoEnd
              videoEnd = videoStart + actualPlayDuration;
            }
            playEndTime = playStartTime + (videoEnd - videoStart);
          }

          // add only timeline related data here
          videoUpdate.newItemData.playStart = playStartTime;
          videoUpdate.newItemData.playEnd = playEndTime;
          videoUpdate.newItemData.videoStart = videoStart;
          videoUpdate.newItemData.videoEnd = videoEnd;
          videoUpdate.newItemData.videoDuration = videoDuration;

          toUpdate.push(videoUpdate);
        }
      } else if (sliderType === SLIDER_TYPES.VIDEO) {
        if (trackType === TRACK_TYPES.VIDEO) {
          // video to video track
          const playStartTime =
            enterStart.position.step * RULER_OPTIONS.interval;
          const playEndTime = exitEnd.position.step * RULER_OPTIONS.interval;
          const videoStartTime =
            (enterStart.position.step - enterStart.mediaMin.step) *
            RULER_OPTIONS.interval;
          const videoEndTime =
            videoStartTime +
            (exitEnd.position.step - enterStart.position.step) *
              RULER_OPTIONS.interval;
          const videoUpdate = {
            container: "workspaceBG",
            id: slider.itemId,
            toUpdate: {},
          };
          const video = project.getIn(["workspaceBG", slider.itemId]);
          if (playStartTime !== video.get("playStart")) {
            videoUpdate.toUpdate.playStart = playStartTime;
          }
          if (playEndTime !== video.get("playEnd")) {
            videoUpdate.toUpdate.playEnd = playEndTime;
          }
          if (videoStartTime !== video.get("videoStart")) {
            videoUpdate.toUpdate.videoStart = videoStartTime;
          }
          if (videoEndTime !== video.get("videoEnd")) {
            videoUpdate.toUpdate.videoEnd = videoEndTime;
            if (!isVideoOnly(video.get("type"), video.get("subType"))) {
              // since except for videos, all items in video track may not have a limit set in project json
              videoUpdate.toUpdate.videoDuration = videoEndTime;
            }
          }
          if (Reflect.ownKeys(videoUpdate.toUpdate).length > 0) {
            toUpdate.push(videoUpdate);
          }
        } else if (trackType === TRACK_TYPES.OBJECT) {
          // video to obj track
          const sourceContainer = "workspaceBG";
          const item = project.getIn([sourceContainer, slider.itemId]);
          const enterStartTime =
            enterStart.position.step * RULER_OPTIONS.interval;
          const exitEndTime = exitEnd.position.step * RULER_OPTIONS.interval;
          const videoUpdate = {
            container: "workspaceItems",
            id: slider.itemId,
            newItemData: {},
            isVidToObj: true,
            fromContainer: sourceContainer,
          };

          // add only timeline related data here
          videoUpdate.newItemData.enterStart = enterStartTime;
          videoUpdate.newItemData.exitEnd = exitEndTime;
          videoUpdate.newItemData.enterEnd = 0;
          videoUpdate.newItemData.exitStart = 0;
          videoUpdate.newItemData.enterEffectName = "no_Effect";
          videoUpdate.newItemData.exitEffectName = "no_Effect";
          videoUpdate.newItemData.videoStart = item.get("videoStart");
          videoUpdate.newItemData.videoEnd = item.get("videoEnd");
          videoUpdate.newItemData.videoDuration = item.get("videoDuration");
          videoUpdate.newItemData.track = enterStart.position.trackIndex;
          videoUpdate.newItemData.isLocked = false;

          if (toNewTrackAbove) {
            videoUpdate.newItemData.track += 1;
            videoUpdate.isNewTrack = true;
          } else if (toNewTrackBelow) {
            videoUpdate.newItemData.track -= 1;
            videoUpdate.isNewTrack = true;
          }

          toUpdate.push(videoUpdate);
        }
      } else if (sliderType === SLIDER_TYPES.AUDIO) {
        const item = project.getIn(["audios", slider.itemId]);

        const speed = Number.isFinite(item.get("speed")) ? item.get("speed") : 1;

        const playStartTime = enterStart.position.step * RULER_OPTIONS.interval;
        const playEndTime = exitEnd.position.step * RULER_OPTIONS.interval;
        const musicStartTime =
          (enterStart.position.step - enterStart.mediaMin.step) *
          RULER_OPTIONS.interval * speed;
        const musicEndTime =
          musicStartTime +
          (exitEnd.position.step - enterStart.position.step) *
            RULER_OPTIONS.interval * speed;
        const audioUpdate = {
          container: "audios",
          id: slider.itemId,
          toUpdate: {},
        };
        const audio = project.getIn(["audios", slider.itemId]);
        if (playStartTime !== audio.get("playStart")) {
          audioUpdate.toUpdate.playStart = playStartTime;
        }
        if (playEndTime !== audio.get("playEnd")) {
          audioUpdate.toUpdate.playEnd = playEndTime;
        }
        if (musicStartTime !== audio.get("musicStart")) {
          audioUpdate.toUpdate.musicStart = musicStartTime;
        }
        if (musicEndTime !== audio.get("musicEnd")) {
          audioUpdate.toUpdate.musicEnd = musicEndTime;
        }
        if (
          trackIndex !== audio.get("track") ||
          toNewTrackAbove ||
          toNewTrackBelow
        ) {
          audioUpdate.toUpdate.track = trackIndex;
          if (toNewTrackAbove) {
            audioUpdate.toUpdate.track -= 1;
            audioUpdate.isNewTrack = true;
          } else if (toNewTrackBelow) {
            audioUpdate.toUpdate.track += 1;
            audioUpdate.isNewTrack = true;
          }
        }
        if (Reflect.ownKeys(audioUpdate.toUpdate).length > 0) {
          toUpdate.push(audioUpdate);
        }
      } else if (sliderType === SLIDER_TYPES.SUBTITLE) {
        const { subtitleId } = slider;
        const targetItem = project.getIn([
          subtitleId.itemContainer,
          subtitleId.itemId,
        ]);
        const subtitleItem = project.getIn([
          "subtitle",
          "data",
          subtitleId.dropId,
          subtitleId.id,
        ]);
        const subtitleUpdate = {
          container: "subtitleData",
          id: subtitleId.id,
          dropId: subtitleId.dropId,
          langId: subtitleId.langId,
          timelineId: subtitleId.timelineId,
          toUpdate: {},
        };

        let mediaStart = targetItem.get("videoStart");
        if (subtitleId.itemContainer === "audios") {
          mediaStart = targetItem.get("musicStart");
        }

        let itemStart = targetItem.get("playStart"); // bg and audio
        if (subtitleId.itemContainer === "workspaceItems") {
          itemStart = targetItem.get("enterStart");
        }

        const enterStartTime =
          enterStart.position.step * RULER_OPTIONS.interval;
        const exitEndTime = exitEnd.position.step * RULER_OPTIONS.interval;
        const speed = Number.isFinite(targetItem.get("speed")) ? targetItem.get("speed"): 1;
        // If item have speed, it will multiply with that speed.
        const subtitleStart = ((enterStartTime - itemStart) * speed) + mediaStart;
        const subtitleEnd = ((exitEndTime - itemStart) * speed) + mediaStart;

        const isStartChanged =
          (thumbId === "enterStart" || !thumbId) &&
          subtitleStart !== subtitleItem.get("start");
        const isEndChanged =
          (thumbId === "exitEnd" || !thumbId) &&
          subtitleEnd !== subtitleItem.get("end");

        if (isStartChanged) {
          subtitleUpdate.toUpdate.start = subtitleStart;
        }
        if (isEndChanged) {
          subtitleUpdate.toUpdate.end = subtitleEnd;
        }

        if (Reflect.ownKeys(subtitleUpdate.toUpdate).length > 0) {
          toUpdate.push(subtitleUpdate);
        }
      }
    }
  }

  return toUpdate;
};

/**
 * returns new stepper with updated time scale for updated project.
 * used to prevent slider jump when user trims a video
 * @param {AdjustTimeScaleParams} params
 * @returns {StepperState}
 */
export const adjustTimeScale = (params = {}) => {
  const { newProject, stepper, timelinePlot } = params;
  let newStepper = stepper;

  const duration = parseFloat(newProject.get("duration").toFixed(1));
  let excessDuration = stepper.excessDuration + (stepper.duration - duration);
  if (duration > stepper.duration && excessDuration < duration) {
    excessDuration = duration;
  }
  let excessSteps = Math.ceil(excessDuration / RULER_OPTIONS.interval);
  let excessWidth = excessSteps * stepper.stepSizePx;

  /*
   * excess space greater than client width is useless.
   * the purpose of increasing excess steps is to prevent scroll and thumb jump
   */
  if (excessWidth > timelinePlot.width) {
    excessWidth = timelinePlot.width;
    excessSteps = Math.ceil(excessWidth / stepper.stepSizePx);
    excessDuration = excessSteps * RULER_OPTIONS.interval;
  }

  const maxScaleDuration = findMaxScaleDuration(duration);
  let reqTimeScale = stepper.timeScale;
  let prevDisplayDuration;
  if (stepper.displayDuration >= duration) {
    // zoom out
    const dd = stepper.displayDuration;
    const d = duration;
    const m = RULER_OPTIONS.timeScale.minScaleOffsetPercent;
    reqTimeScale = 100 * ((dd - d) / (d * (m / 100 - 1)));
  } else {
    // zoom in
    const dd = stepper.displayDuration;
    const m = duration < maxScaleDuration ? duration : maxScaleDuration;
    const d = duration;

    if (m - d === 0) {
      reqTimeScale = 0;
    } else {
      reqTimeScale = 100 * ((dd - d) / (m - d));
    }
  }

  // consider dd = prev project duration = 5, zoomed in duration = 10 and project duration = 7
  // if dd is not maintained on stepper adjustment, thumbs will move away from the mouse causing visual shift
  // as scale range [0, 100] will change dd to new project duration
  if (reqTimeScale >= 0 && stepper.displayDuration < maxScaleDuration) {
    prevDisplayDuration = stepper.displayDuration;
  }

  newStepper = calculateStepperSize({
    duration: newProject.get("duration"),
    timelinePlot,
    timeScale: reqTimeScale,
    excessDuration,
    prevDisplayDuration,
  });
  newStepper.videoExcessSteps = stepper.videoExcessSteps; // to retain current user's state if a colab user edits timeline

  return newStepper;
};

/**
 * returns the type of thumb being dragged for sliders in video track
 * @param {GetSelectedVideoTrackThumbParams} params
 * @returns {"enterStart" | "exitEnd" | ""}
 */
export const getSelectedVideoTrackThumb = (params = {}) => {
  const { selectedSliders, sliders } = params;
  let thumb = "";

  if (
    selectedSliders.length === 1 &&
    selectedSliders[0].sliderId &&
    (selectedSliders[0].thumbId === "enterStart" ||
      selectedSliders[0].thumbId === "exitEnd")
  ) {
    const slider = sliders[selectedSliders[0].sliderId];
    if (slider && slider.enterStart.position.trackType === TRACK_TYPES.VIDEO) {
      thumb = selectedSliders[0].thumbId;
    }
  }

  return thumb;
};

export const buildLocalSubtitles = (project) => {
  const projectHasSubtitle =
    project.get("defaultSubtitle") && project.get("subtitle");

  if (!projectHasSubtitle) {
    return project;
  }

  let localSubtitle = OrderedMap();
  let usedSubtitleItems = Map();

  for (const container of PROJECT_CONTAINERS) {
    const containerData = project.get(container);

    if (!containerData) {
      continue;
    }

    let startKey = "playStart";
    let endKey = "playEnd";
    if (container === "workspaceItems") {
      startKey = "enterStart";
      endKey = "exitEnd";
    }

    const sortedItems = containerData.sort((item1, item2) => {
      let result = item1.get("track") - item2.get("track");
      if (result === 0) {
        result = item1.get(startKey) - item2.get(startKey);
      }
      return result;
    });

    for (const [itemId, item] of sortedItems.entrySeq()) {
      const subtitleListId = item.get("dropId");
      const subtitleMap = project.getIn([
        "subtitle",
        "data",
        subtitleListId,
      ]);

      if (!subtitleMap) {
        continue;
      }

      let mediaStart = item.get("videoStart");
      let mediaEnd = item.get("videoEnd");
      if (container === "audios") {
        mediaStart = item.get("musicStart");
        mediaEnd = item.get("musicEnd");
      }

      /** @type {SubtitleId[]} */
      const subtitleIdsInRange = [];
      const subtitleList = subtitleMap.valueSeq();
      const lastIndex = subtitleList.size - 1;
      for (let idx = 0; idx < subtitleList.size; idx += 1) {
        const subtitleItem = subtitleList.get(idx);
        const subtitleId = subtitleItem.get("id");
        if (usedSubtitleItems.getIn([subtitleListId, subtitleId])) {
          continue;
        }

        const leftSubtitleItem = idx === 0 ? undefined : subtitleList.get(idx - 1);
        const rightSubtitleItem = idx === lastIndex ? undefined : subtitleList.get(idx + 1);

        const subtitleStart = subtitleItem.get("start");
        const subtitleEnd = subtitleItem.get("end");
        if (mediaEnd <= subtitleStart) {
          // we can safely skip rest of the items as subtitles are already sorted by `start`
          break;
        }

        const subtitleIsInRange = !(
          mediaEnd <= subtitleStart || mediaStart >= subtitleEnd
        );
        if (subtitleIsInRange) {
          subtitleIdsInRange.push({
            dropId: subtitleListId,
            id: subtitleId,
            timelineId: `${itemId}-${subtitleListId}-${subtitleId}`,
            itemId,
            itemContainer: container,
            langId: project.get("defaultSubtitle"),
            leftId: leftSubtitleItem ? leftSubtitleItem.get("id") : "",
            rightId: rightSubtitleItem ? rightSubtitleItem.get("id") : "",
          });
          usedSubtitleItems = usedSubtitleItems.setIn(
            [subtitleListId, subtitleId],
            true
          );
        }
      }

      if (subtitleIdsInRange.length) {
        subtitleIdsInRange[0].isStart = true;
        subtitleIdsInRange[subtitleIdsInRange.length - 1].isEnd = true;

        for (const subtitleId of subtitleIdsInRange) {
          let subtitleItem = project.getIn([
            "subtitle",
            "data",
            subtitleId.dropId,
            subtitleId.id,
          ]);

          const itemStart = item.get(startKey);
          const itemEnd = item.get(endKey);

          const subtitleStart = clamp(
            itemStart + subtitleItem.get("start") - mediaStart,
            itemStart,
            itemEnd
          );
          const subtitleEnd = clamp(
            itemStart + subtitleItem.get("end") - mediaStart,
            itemStart,
            itemEnd
          );

          if (item.get("speed")) {
            const startDur = subtitleItem.get("start") - mediaStart;
            const endDur = subtitleItem.get("end") - mediaStart;
            const videoEnterStart = itemStart;
            const subStart = itemStart + startDur;
            const subEnd = itemStart + endDur;
            const subtitleDif = subStart - videoEnterStart;
            const subtitleEndDif = subEnd - videoEnterStart;
            const timelineDif = subtitleDif / item.get("speed");
            const timelineEndDif = subtitleEndDif / item.get("speed");
            const startTime = itemStart + timelineDif;
            const endTime = itemStart + timelineEndDif;

            const subtitleStart = clamp(
              startTime,
              itemStart,
              itemEnd
            );
            const subtitleEnd = clamp(
              endTime,
              itemStart,
              itemEnd
            );

            subtitleItem = subtitleItem.set("enterStart", subtitleStart);
            subtitleItem = subtitleItem.set("exitEnd", subtitleEnd);
          }
          else {
            subtitleItem = subtitleItem.set("enterStart", subtitleStart);
            subtitleItem = subtitleItem.set("exitEnd", subtitleEnd);
          }

          subtitleItem = subtitleItem.set("subtitleId", fromJS(subtitleId));
          localSubtitle = localSubtitle.set(
            subtitleId.timelineId,
            subtitleItem
          );
        }
      }
    }
  }

  // sort again as subtitle need to be listed in order combining both workspaceItems and audios
  const containerPriority = { workspaceItems: 0, audios: 1 };
  localSubtitle = localSubtitle.sort((left, right) => {
    const lEnterStart = left.get("enterStart");
    const rEnterStart = right.get("enterStart");

    let result = lEnterStart - rEnterStart;
    if (result === 0) {
      const lContainer = left.getIn(["subtitleId", "itemContainer"]);
      const rContainer = right.getIn(["subtitleId", "itemContainer"]);
      result = containerPriority[lContainer] - containerPriority[rContainer];
      if (result === 0) {
        const lItemId = left.getIn(["subtitleId", "itemId"]);
        const rItemId = right.getIn(["subtitleId", "itemId"]);
        const lItem = project.getIn([lContainer, lItemId]);
        const rItem = project.getIn([rContainer, rItemId]);
        result = lItem.get("track") - rItem.get("track");
      }
    }

    return result;
  });

  project = project.set("localSubtitle", localSubtitle);
  return project;
};

/**
 * returns plot of each track for passed project data
 * @param {GetTracksParams} params
 */
export const getTracks = (params = {}) => {
  const { project, stepper, timelineMode } = params;
  const trackWidth =
    (stepper.steps + stepper.videoExcessSteps) * stepper.stepSizePx;

  /** @type {TrackGroup} */
  const trackGroup = {
    [TRACK_TYPES.OBJECT]: [],
    // [TRACK_TYPES.VIDEO]: [],
    [TRACK_TYPES.AUDIO]: [],
  };

  const itemsGroup = {
    [TRACK_TYPES.OBJECT]: project.get("workspaceItems"),
    // [TRACK_TYPES.VIDEO]: project.get("workspaceBG"),
    [TRACK_TYPES.AUDIO]: project.get("audios"),
  };

  const orgSubtitleData = project.getIn(["subtitle", "data"]);

  for (const trackType of Reflect.ownKeys(itemsGroup)) {
    const items = itemsGroup[trackType];
    const trackList = trackGroup[trackType];
    let defaultHeight = 0;
    if (trackType === TRACK_TYPES.AUDIO) {
      defaultHeight = TRACK_OPTIONS[TRACK_TYPES.AUDIO].height;
    } else if (trackType === TRACK_TYPES.VIDEO) {
      defaultHeight = TRACK_OPTIONS[TRACK_TYPES.VIDEO].height;
    } else if (trackType === TRACK_TYPES.OBJECT) {
      defaultHeight = TRACK_OPTIONS[TRACK_TYPES.OBJECT].height;
    }

    for (const item of items.valueSeq()) {
      const dropId = item.get("dropId");
      if (timelineMode === TIMELINE_MODES.SUBTITLE) {
        const isSubtitleMedia =
          isUpVideo(item.get("type"), item.get("subType")) ||
          isUpAudio(item.get("type"), item.get("subType"));
        const hasSubtitle = orgSubtitleData && orgSubtitleData.get(dropId);
        if (!isSubtitleMedia && !hasSubtitle) {
          continue;
        }
      }

      const trackIndex =
        trackType !== TRACK_TYPES.VIDEO ? item.get("track") : 0; // video track will not have multiple tracks
      if (!trackList[trackIndex]) {
        trackList[trackIndex] = {
          plot: {
            x: RULER_OPTIONS.paddingLeft,
            y: 0,
            width: trackWidth,
            height: defaultHeight,
          },
          itemPlot: {
            x: RULER_OPTIONS.paddingLeft,
            y: 0,
            width: trackWidth,
            height: defaultHeight,
          },
          trackGapMiddle: 0,
          trackType,
          trackIndex,
          itemIds: [],
        };
      }
      const track = trackList[trackIndex];
      if (
        trackType === TRACK_TYPES.OBJECT &&
        (isImageOnly(item.get("type"), item.get("subType")) ||
          isVideoOnly(item.get("type"), item.get("subType")))
      ) {
        track.plot.height = TRACK_OPTIONS[TRACK_TYPES.OBJECT].withImageHeight;
        track.itemPlot.height = track.plot.height;
      }
      track.itemIds.push(item.get("id"));
    }

    if (trackType === TRACK_TYPES.VIDEO && !trackList[0]) {
      // project did not have any bg, so create empty track
      trackList[0] = {
        plot: {
          x: RULER_OPTIONS.paddingLeft,
          y: 0,
          width: trackWidth,
          height: defaultHeight,
        },
        trackGapMiddle: 0,
        trackType,
        trackIndex: 0,
        itemIds: [],
      };
      trackList[0].itemPlot = { ...trackList[0].plot };
    }

    if (trackType === TRACK_TYPES.AUDIO) {
      // add extra track
      trackList.push({
        plot: {
          x: RULER_OPTIONS.paddingLeft,
          y: 0,
          width: trackWidth,
          height: defaultHeight,
        },
        trackGapMiddle: 0,
        trackType,
        trackIndex: trackList.length,
        itemIds: [],
      });
      trackList[trackList.length - 1].itemPlot = { ...trackList[trackList.length - 1].plot };
    }
  }

  const projectHasSubtitle =
    project.get("localSubtitle") && project.get("localSubtitle").size;
  if (projectHasSubtitle) {
    const localSubtitle = project.get("localSubtitle");

    for (const subtitleItem of localSubtitle.valueSeq()) {
      const subtitleId = subtitleItem.get("subtitleId").toJS(); // make sure subtitleId is native js object instead of immutable in timeline
      const targetItem = project.getIn([
        subtitleId.itemContainer,
        subtitleId.itemId,
      ]);

      let trackType = TRACK_TYPES.OBJECT;
      if (subtitleId.itemContainer === "audios") {
        trackType = TRACK_TYPES.AUDIO;
      } else if (subtitleId.itemContainer === "workspaceBG") {
        trackType = TRACK_TYPES.VIDEO;
      }

      const trackList = trackGroup[trackType];

      if (!trackList || !targetItem) {
        continue;
      }

      let trackIndex = targetItem.get("track");
      if (trackType === TRACK_TYPES.VIDEO) {
        trackIndex = 0;
      }

      const track = trackList[trackIndex];
      if (!track) {
        continue;
      }
      if (!track.subtitleIds) {
        track.subtitleIds = [];
      }
      if (!track.subtitlePlot) {
        let subtitleHeight = TRACK_OPTIONS[trackType].miniSubtitleHeight;
        let subtitleGap = TRACK_OPTIONS[trackType].miniSubtitleGap;
        if (timelineMode === TIMELINE_MODES.SUBTITLE) {
          ({ subtitleHeight, subtitleGap } = TRACK_OPTIONS[trackType]);
        }

        track.subtitlePlot = {
          x: RULER_OPTIONS.paddingLeft,
          y: 0,
          width: trackWidth,
          height: subtitleHeight,
        };
        const subtitleY2 = track.subtitlePlot.y + track.subtitlePlot.height;
        track.itemPlot.y = subtitleY2 + subtitleGap;
        const itemY2 = track.itemPlot.y + track.itemPlot.height;
        track.plot.height = itemY2 - track.subtitlePlot.y;
      }
      track.subtitleIds.push(subtitleId);
    }
  }

  let prevY2 = 0;
  for (const trackType of Reflect.ownKeys(trackGroup)) {
    const newTrackList = [];
    const trackList = trackGroup[trackType];
    let _prevY2 = prevY2;
    let defaultHeight = 0;

    if (trackType === TRACK_TYPES.AUDIO) {
      defaultHeight = TRACK_OPTIONS[TRACK_TYPES.AUDIO].height;
    } else if (trackType === TRACK_TYPES.VIDEO) {
      defaultHeight = TRACK_OPTIONS[TRACK_TYPES.VIDEO].height;
    } else if (trackType === TRACK_TYPES.OBJECT) {
      defaultHeight = TRACK_OPTIONS[TRACK_TYPES.OBJECT].height;
    }

    for (let t = 0; t < trackList.length; t = t + 1) {
      let trackIndex = t;
      if (TRACK_OPTIONS[trackType].isReversed) {
        // since object tracks will be reversed in ui
        trackIndex = trackList.length - 1 - t;
      }

      let track = trackList[trackIndex];
      if (!track) {
        // empty tracks were not removed, so empty track added to prevent crashes
        track = {
          plot: {
            x: RULER_OPTIONS.paddingLeft,
            y: 0,
            width: trackWidth,
            height: defaultHeight,
          },
          trackGapMiddle: 0,
          trackType,
          trackIndex,
          itemIds: [],
          hide: timelineMode === TIMELINE_MODES.SUBTITLE,
        };
        track.itemPlot = { ...track.plot };
      }

      track.itemIds.sort((item1Id, item2Id) => {
        const item1 = itemsGroup[trackType].get(item1Id);
        const item2 = itemsGroup[trackType].get(item2Id);

        let item1EnterStart = 0;
        let item2EnterStart = 0;
        if (trackType === TRACK_TYPES.OBJECT) {
          item1EnterStart = item1.get("enterStart");
          item2EnterStart = item2.get("enterStart");
        } else if (
          trackType === TRACK_TYPES.AUDIO ||
          trackType === TRACK_TYPES.VIDEO
        ) {
          item1EnterStart = item1.get("playStart");
          item2EnterStart = item2.get("playStart");
        }

        return item1EnterStart - item2EnterStart;
      });

      let y = _prevY2;
      if (t === 0) {
        y = y + TRACK_OPTIONS[trackType].groupMarginTop;
      } else {
        y = y + TRACK_OPTIONS[trackType].marginTop;
      }

      track.trackGapMiddle = _prevY2 + (y - _prevY2) / 2;
      track.plot.y = y;
      track.itemPlot.y = track.plot.y + track.itemPlot.y;
      if (track.subtitlePlot) {
        track.subtitlePlot.y = track.plot.y + track.subtitlePlot.y;
      }
      if (!track.hide) {
        _prevY2 = track.plot.y + track.plot.height;
      }

      newTrackList[trackIndex] = track;
    }

    prevY2 = _prevY2;
    trackGroup[trackType] = newTrackList;
  }

  return trackGroup;
};

/**
 * Returns new tracks state with stepper width updated
 * @param {UpdateTrackWidthParams} params
 */
export const updateTrackWidth = (params = {}) => {
  const { tracks, stepper } = params;

  const trackWidth =
    (stepper.steps + stepper.videoExcessSteps) * stepper.stepSizePx;
  const newTracks = { ...tracks };

  for (const trackType of Reflect.ownKeys(newTracks)) {
    const trackList = newTracks[trackType];
    const newTrackList = trackList.map((track) => {
      const newTrack = {
        ...track,
        plot: {
          ...track.plot,
          width: trackWidth,
        },
        itemPlot: {
          ...track.itemPlot,
          width: trackWidth,
        },
      };
      if (newTrack.subtitlePlot) {
        newTrack.subtitlePlot = {
          ...newTrack.subtitlePlot,
          width: trackWidth,
        };
      }
      return newTrack;
    });
    newTracks[trackType] = newTrackList;
  }

  return newTracks;
};

/**
 * function to check whether steps should be increased to allow dragging beyond current project duration
 * @param {ShouldExtendRulerParams} params
 */
export const shouldExtendRuler = (params = {}) => {
  const { selectedSliders, sliders, stepper } = params;
  const slidersToCheck = [
    SLIDER_TYPES.OBJECT,
    SLIDER_TYPES.VIDEO,
    SLIDER_TYPES.AUDIO,
  ];
  const rulerSlider = sliders[SLIDER_TYPES.RULER];
  let totalSteps = stepper.steps + stepper.videoExcessSteps;
  if (rulerSlider && rulerSlider.rulerEnd) {
    totalSteps = rulerSlider.rulerEnd.position.step;
  }

  return selectedSliders.some((selection) => {
    let result = false;
    const slider = sliders[selection.sliderId];
    if (
      slider &&
      slider.enterStart &&
      slidersToCheck.includes(slider.sliderType)
    ) {
      const exitEnd = slider.exitEnd ? slider.exitEnd : slider.enterStart;
      let { position } = exitEnd;
      if (exitEnd.trackPosition) {
        position = exitEnd.trackPosition;
      }
      result = position.step >= totalSteps;
    }
    return result;
  });
};

/**
 * @param {object} params
 * @param {number} params.seekPlayhead
 * @param {Sliders} params.sliders
 * @param {StepperState} params.stepper
 */
export const updatePlayheadSeek = (params = {}) => {
  const { seekPlayhead, sliders, stepper } = params;

  let updatedSliders = sliders;
  const playhead = sliders[SLIDER_TYPES.PLAYHEAD_THUMB];
  const playheadThumb = playhead.enterStart;

  let seekStep = seekPlayhead / RULER_OPTIONS.interval; // just to get intermediate x
  const seekX = RULER_OPTIONS.paddingLeft + seekStep * stepper.stepSizePx;
  seekStep = secondsToStep({ seconds: seekPlayhead, stepper }).step; // get actual step

  if (seekStep.step !== playheadThumb.position.step) {
    /** @type {Thumb} */
    const newPlayheadThumb = {
      ...playheadThumb,
      trackPosition: {
        ...playheadThumb.position,
        step: seekStep,
        x: seekX,
      },
    };

    updatedSliders = {
      ...sliders,
      [playhead.id]: {
        ...playhead,
        [playheadThumb.id]: newPlayheadThumb,
        isSeeking: true,
      },
    };
  }

  return updatedSliders;
};

/**
 * Function to move object by one track up/down
 * @param {HandleLayerPositionParams} params
 */
export const handleLayerPosition = (params = {}) => {
  const { container, itemId, items, type = "forward" } = params;
  const item = items.get(itemId);

  let track;
  const lastTrack = items.reduce((prevTrack, _item) => {
    return _item.get("track") > prevTrack ? _item.get("track") : prevTrack;
  }, 0);

  if (type === "forward") {
    track = item.get("track") + 2;
  } else if (type === "backward") {
    track = item.get("track") - 1;
  } else if (type === "front") {
    track = lastTrack + 1;
  } else if (type === "back") {
    track = 0;
  }

  if (track < 0) {
    track = 0;
  }

  if (!Number.isFinite(track)) {
    return [];
  }

  return [
    {
      container,
      id: item.get("id"),
      toUpdate: { track },
      isNewTrack: true,
    },
  ];
};

/**
 * @param {SplitSubtitleParams} params
 */
export const splitSubtitle = (params = {}) => {
  const { subtitleId, subtitleItem, targetItem } = params;

  let mediaStart = targetItem.get("videoStart");
  if (subtitleId.itemContainer === "audios") {
    mediaStart = targetItem.get("musicStart");
  }

  let itemStart = targetItem.get("playStart"); // bg and audio
  if (subtitleId.itemContainer === "workspaceItems") {
    itemStart = targetItem.get("enterStart");
  }

  const splitTime = params.splitTime - itemStart + mediaStart; // project time -> subtitle time

  let left = null;
  let right = null;
  let wordsDurData = subtitleItem.get("wordsDurData");

  const firstWordWithTime = wordsDurData.find((word) => !word.get("isManual"));
  const isManual = wordsDurData.size === 0 || !firstWordWithTime;
  const subtitleDuration = subtitleItem.get("end") - subtitleItem.get("start");

  if (isManual) {
    /** @type {string[]} */
    const words = subtitleItem.get("text").split(" ");
    wordsDurData = List();

    let startIndex = 0;
    for (const word of words) {
      if (word.length === 0) {
        // increase for space
        startIndex += 1;
        continue;
      }

      wordsDurData = wordsDurData.push(
        Map({
          word,
          startIndex,
          endIndex: word.length - 1,
          isManual: true,
        })
      );
      startIndex = startIndex + word.length + 1; // next index + space index
    }

    const durationPerWord = subtitleDuration / wordsDurData.size;
    wordsDurData = wordsDurData.map((word, wordIdx) => {
      word = word.set(
        "st",
        (subtitleItem.get("start") + wordIdx * durationPerWord).toFixed(2)
      );
      word = word.set(
        "et",
        (subtitleItem.get("start") + (wordIdx + 1) * durationPerWord).toFixed(2)
      );
      return word;
    });
  } else {
    let prevEt = null;
    wordsDurData = wordsDurData.map((word, wordIndex) => {
      if (word.get("isManual")) {
        if (prevEt === null) {
          if (wordIndex === 0) {
            // example scenario: "manual_word manual_word word_with_time"
            prevEt = firstWordWithTime.get("st");
          } else {
            // example scenario: "word_with_time manual_word manual_word"
            prevEt = firstWordWithTime.get("et");
          }
        }

        word = word.set("st", prevEt);
        word = word.set("et", prevEt);
      }
      prevEt = word.get("et");
      return word;
    });
  }

  const wordCount = wordsDurData.size;

  let leftDurData = List().asMutable();
  let rightDurData = List().asMutable();

  for (let curWordIdx = 0, target = leftDurData; curWordIdx < wordCount; curWordIdx += 1) {
    const curWord = wordsDurData.get(curWordIdx);

    const curWordStart = parseFloat(curWord.get("st"));
    const curWordEnd = parseFloat(curWord.get("et"));

    if (target !== rightDurData) {
      // once right target has been chosen, no need to check furthur as we can have only 2 splits
      if (isManual || (!isManual && !curWord.get("isManual"))) {
        // if entire subtitle is manual - choose target based on timing
        // if some words have timing - push manual words to last used target
        // otherwise choose target based on timing
        if (curWordEnd <= splitTime) {
          target = leftDurData;
        } else if (curWordStart <= splitTime && splitTime <= curWordEnd) {
          const ratio = (splitTime - curWordStart) / (curWordEnd - curWordStart);
          if (ratio <= 50) {
            target = leftDurData;
          } else {
            target = rightDurData;
          }
        } else {
          target = rightDurData;
        }
      }
    }

    target.push(curWord);
  }

  leftDurData = leftDurData.asImmutable();
  rightDurData = rightDurData.asImmutable();

  left = subtitleItem;
  right = subtitleItem;

  if (leftDurData.size) {
    const fWordStart = parseFloat(leftDurData.getIn([0, "st"]));
    const start = left.get("start");
    left = left.set("start", Math.min(fWordStart, start)); // user might have extended subtitle time, so try to retain it
    left = left.set("end", parseFloat(leftDurData.getIn([-1, "et"])));
  }
  if (rightDurData.size) {
    const lWordEnd = parseFloat(rightDurData.getIn([-1, "et"]));
    const end = right.get("end");
    right = right.set("start", parseFloat(rightDurData.getIn([0, "st"])));
    right = right.set("end", Math.max(lWordEnd, end)); // user might have extended subtitle time, so try to retain it
  }

  if (leftDurData.size && !rightDurData.size) {
    // all words were moved to left slider
    right = right.set("start", Math.max(left.get("end"), splitTime));
  } else if (!leftDurData.size && rightDurData.size) {
    // all words were moved to right slider
    left = left.set("end", Math.min(right.get("start"), splitTime));
  } else if (!leftDurData.size && !rightDurData.size) {
    // both slider has empty text
    left = left.set("end", splitTime);
    right = right.set("start", splitTime);
  }

  const updateFilthyData = (subtitleItem) => {
    let updatedItem = subtitleItem;

    if (!updatedItem.get("wordsDurData").size) {
      updatedItem = updatedItem.set("hasFilthyData", false);
      updatedItem = updatedItem.set("filthyData", List());
    } else if (updatedItem.get("hasFilthy") && updatedItem.get("filthyData")) {
      const startIndex = updatedItem.getIn(["wordsDurData", 0, "startIndex"]);
      const endIndex =
        updatedItem.getIn(["wordsDurData", -1, "startIndex"]) +
        updatedItem.getIn(["wordsDurData", -1, "endIndex"]);
      let filthyData = updatedItem.get("filthyData");

      filthyData = filthyData.filter((data) => {
        const fStartIndex = data.get("startIndex");
        const fEndIndex = fStartIndex + data.get("endIndex");

        const startIsWithinRange =
          startIndex <= fStartIndex && fStartIndex <= endIndex;
        const endIsWithinRange =
          startIndex <= fEndIndex && fEndIndex <= endIndex;

        return startIsWithinRange && endIsWithinRange;
      });

      if (!filthyData.size) {
        updatedItem = updatedItem.set("hasFilthyData", false);
      }
      updatedItem = updatedItem.set("filthyData", filthyData);
    }

    const updatedWords = updatedItem.get("wordsDurData").map((word) => {
      if (word.get("isManual")) {
        word = word.delete("st");
        word = word.delete("et");
      }
      return word;
    });
    updatedItem = updatedItem.set("wordsDurData", updatedWords);

    return updatedItem;
  };

  const wordDurationDataToText = (durData) => {
    let text = "";
    const wordCount = durData.size;

    for (let wordIdx = 0; wordIdx < wordCount; wordIdx += 1) {
      let word = durData.getIn([wordIdx, "word"]);
      if (wordIdx > 0) {
        const spaces =
          durData.getIn([wordIdx, "startIndex"]) -
          (durData.getIn([wordIdx - 1, "startIndex"]) +
            durData.getIn([wordIdx - 1, "endIndex"]) +
            1);
        word = word.padStart(spaces + word.length, " ");
      }
      text += word;
    }

    return text;
  };

  left = left.set("text", wordDurationDataToText(leftDurData));
  right = right.set("text", wordDurationDataToText(rightDurData));

  left = left.set("wordsDurData", leftDurData);
  if (rightDurData.get(0) && rightDurData.getIn([0, "startIndex"]) > 0) {
    const startIndexOffset = rightDurData.getIn([0, "startIndex"]);
    rightDurData = rightDurData.map((wordData) => {
      const currentStartIndex = wordData.get("startIndex");
      return wordData.set("startIndex", currentStartIndex - startIndexOffset);
    });
    right = right.set("wordsDurData", rightDurData);

    if (right.get("filthyData")) {
      const newFilthyData = right.get("filthyData").map((f) => {
        const currentStartIndex = f.get("startIndex");
        return f.set("startIndex", currentStartIndex - startIndexOffset);
      });
      right = right.set("filthyData", newFilthyData);
    }
  }

  left = updateFilthyData(left);
  right = updateFilthyData(right);

  right = right.set("id", randomString("split"));

  return {
    left,
    right,
  };
};

/**
 * @param {IterViewportSliders} params
 */
export const iterViewportSliders = (params = {}) => {
  const {
    sliders,
    tracks: trackGroup,
    tracksToCheck = [],
    viewX,
    viewY,
    viewWidth,
    viewHeight,
    checkItemIds = true,
    checkSubtitleIds = true,
    reverseAudioTrack = false,
    reverseObjectTrack = false,
    axis = 0,
    callback,
  } = params;

  if (!callback) {
    return;
  }

  let canProcess = true;
  const viewX1 = viewX;
  const viewX2 = viewX + viewWidth;
  const viewY1 = viewY;
  const viewY2 = viewY + viewHeight;
  const LEFT = 1;
  const VISIBLE = 0;
  const RIGHT = 2;

  const checkSliderVisible = axis === 0 || axis === 1;
  const checkTrackVisible = axis === 0 || axis === 2;

  const getSliderViewportStatus = (slider) => {
    const { enterStart, exitEnd } = getTrackPosition({
      slider,
      thumbIds: ["enterStart", "exitEnd"],
    });
    const x1 = enterStart.x;
    const x2 = exitEnd ? exitEnd.x : enterStart.x;
    if (x2 < viewX1) {
      return LEFT;
    }
    if (x1 > viewX2) {
      return RIGHT;
    }
    return VISIBLE;
  };

  for (const trackType of tracksToCheck) {
    const trackList = trackGroup[trackType];
    const trackOptions = TRACK_OPTIONS[trackType];
    if (!trackList || !trackList[0] || !trackOptions) {
      continue;
    }

    let trackIsReversed = trackOptions.isReversed;
    const loopTrackReverse = (
      (reverseAudioTrack && trackType === TRACK_TYPES.AUDIO)
      || (reverseObjectTrack && trackType === TRACK_TYPES.OBJECT)
    );
    if (loopTrackReverse) {
      trackIsReversed = !trackIsReversed;
    }

    const tl = trackList.length;
    const firstTrack = trackList[0];
    const lastTrack = trackList[tl - 1];
    const y1 = Math.min(firstTrack.plot.y, lastTrack.plot.y);
    const y2 = Math.max(
      firstTrack.plot.y + firstTrack.plot.height,
      lastTrack.plot.y + lastTrack.plot.height
    );
    if (checkTrackVisible && (y2 < viewY1 || y1 > viewY2)) {
      // need not process tracks as all are outside viewport
      continue;
    }

    for (let _ti = 0; canProcess && _ti < tl; _ti += 1) {
      let ti = _ti;
      if (loopTrackReverse) {
        ti = tl - 1 - _ti;
      }
      const track = trackList[ti];
      if (!track) {
        continue;
      }

      const y1 = track.plot.y;
      const y2 = track.plot.y + track.plot.height;

      if (checkTrackVisible) {
        if (!trackIsReversed) {
          if (y1 > viewY2) {
            // this track and all tracks after this are outside viewport
            break;
          } else if (y2 < viewY1) {
            // this track is outside viewport but tracks after this might be visible
            continue;
          }
        } else if (trackIsReversed) {
          if (y2 < viewY1) {
            // this track and all tracks after this are outside viewport
            break;
          } else if (y1 > viewY2) {
            // this track is outside viewport but tracks after this might be visible
            continue;
          }
        }
      }

      if (checkItemIds) {
        const idL = track.itemIds.length;
        const firstSlider = sliders[track.itemIds[0]];
        const lastSlider = sliders[track.itemIds[idL - 1]];

        let canProcessItems = true;
        if (checkSliderVisible && firstSlider && lastSlider) {
          const fstatus = getSliderViewportStatus(firstSlider);
          const lstatus = getSliderViewportStatus(lastSlider);
          canProcessItems = !(
            fstatus === lstatus &&
            // either all sliders are on left of viewport or right of viewport. none are visible
            (fstatus === LEFT || fstatus === RIGHT)
          );
        }

        for (let si = 0; canProcessItems && canProcess && si < idL; si += 1) {
          const id = track.itemIds[si];
          const slider = sliders[id];
          if (!slider) {
            continue;
          }
          const status = checkSliderVisible ? getSliderViewportStatus(slider) : VISIBLE;
          if (status === VISIBLE) {
            canProcess = callback(slider, track, si);
          } else if (status === RIGHT) {
            // this slider and all other sliders after this falls outside viewport
            break;
          }
        }
      }

      if (canProcess && checkSubtitleIds && track.subtitleIds) {
        const idL = track.subtitleIds.length;
        const firstSlider =
          track.subtitleIds[0] && sliders[track.subtitleIds[0].timelineId];
        const lastSlider =
          track.subtitleIds[idL - 1] &&
          sliders[track.subtitleIds[idL - 1].timelineId];

        let canProcessItems = true;
        if (checkSliderVisible && firstSlider && lastSlider) {
          const fstatus = getSliderViewportStatus(firstSlider);
          const lstatus = getSliderViewportStatus(lastSlider);
          canProcessItems = !(
            fstatus === lstatus &&
            // either all sliders are on left of viewport or right of viewport. none are visible
            (fstatus === LEFT || fstatus === RIGHT)
          );
        }

        for (let si = 0; canProcessItems && canProcess && si < idL; si += 1) {
          const id = track.subtitleIds[si];
          const slider = id && sliders[id.timelineId];
          if (!slider) {
            continue;
          }
          const status = checkSliderVisible ? getSliderViewportStatus(slider) : VISIBLE;
          if (status === VISIBLE) {
            canProcess = callback(slider, track, si);
          } else if (status === RIGHT) {
            // this slider and all other sliders after this falls outside viewport
            break;
          }
        }
      }
    }

    if (!canProcess) {
      break;
    }
  }
};

/**
 * Function to get the new slider and the current slider with the updated enter and exit time for splitting.
 * @param {item, splitTime, container} params
 * @returns newSlider, currentSlider
 */
export const splitSliderHelper = (params = {}) => {
  const { item, splitTime, container } = params;
  // Setting new ID for the splitted slider
  let newItem = item.set("id", randomString("split"));
  let currentItem = item;
  let currentItemDuration; // Duration of the current item.
  let currentEndDuration; // End duration of a current time.
  const start = container === "workspaceItems" ? "enterStart" : "playStart";
  const end = container === "workspaceItems" ? "exitEnd" : "playEnd";
  
  const speed = Number.isFinite(item.get("speed")) ? item.get("speed") : 1;
  if (
    isVideoOnly(item.get("type"), item.get("subType")) &&
    (container === "workspaceItems" || container === "workspaceBG")
  ) {
    currentItemDuration = splitTime - currentItem.get(start);
    currentEndDuration = parseFloat(
      ((currentItem.get("videoStart") / speed) + currentItemDuration).toFixed(1)
    );
  } else if (container === "audios") {
    currentItemDuration = splitTime - currentItem.get(start);
    currentEndDuration = (currentItem.get("musicStart") / speed) + currentItemDuration;
  }

  if (
    isVideoOnly(item.get("type"), item.get("subType")) &&
    (container === "workspaceItems" || container === "workspaceBG")
  ) {
    newItem = newItem.set("videoStart", currentEndDuration * speed);
    currentItem = currentItem.set("videoEnd", currentEndDuration * speed);
  } else if (container === "audios") {
    newItem = newItem.set("musicStart", currentEndDuration * speed);
    currentItem = currentItem.set("musicEnd", currentEndDuration * speed);
  }
  newItem = newItem.set(start, splitTime);
  currentItem = currentItem.set(end, splitTime);
  const isExitAnimationSplit = item.get("exitStart") < newItem.get(start)
  const isEnterAnimationSplit = item.get("enterEnd") > newItem.get(start)
  const isEnterEffect = item.get("enterEffectName") !== "no_Effect";
  const isExitEffect = item.get("exitEffectName") !== "no_Effect";

  if (container === "workspaceItems") {
    if (!isEnterEffect && !isExitEffect) {
      newItem = newItem.set("exitStart", newItem.get("exitEnd"));
      newItem = newItem.set("enterEnd", newItem.get("enterStart"));
      currentItem = currentItem.set("enterEnd", currentItem.get("enterStart"));
      currentItem = currentItem.set("exitStart", currentItem.get("exitEnd"));
    } else if (isExitAnimationSplit) {
      newItem = newItem.set("exitStart", newItem.get("enterStart"));
      newItem = newItem.set("enterEnd", newItem.get("enterStart"));
      newItem = newItem.set("enterEffectName", "no_Effect");
      newItem = newItem.set("enterEffectIcon", "no_Effect");
    } else if (isEnterAnimationSplit) {
      currentItem = currentItem.set("enterEnd", newItem.get("enterStart"));
      currentItem = currentItem.set("exitStart", newItem.get("exitEnd"));
      currentItem = currentItem.set("exitEffectName", "no_Effect");
      currentItem = currentItem.set("exitEffectIcon", "no_Effect");
    } else {
      newItem = newItem.set("enterEnd", newItem.get("enterStart"));
      currentItem = currentItem.set("exitStart", newItem.get("exitEnd"));
      newItem = newItem.set("enterEffectIcon", "no_Effect");
      newItem = newItem.set("enterEffectName", "no_Effect")
      currentItem = currentItem.set("exitEffectIcon", "no_Effect");
      currentItem = currentItem.set("exitEffectName", "no_Effect");
    }
  }

  return { newItem, currentItem };
};

/**
 * Function to duplicate the item.
 * @param {object} params
 * @param {object} params.itemsToDuplicate
 * @param {object} params.projectDetails
 * @param {number} params.pasteDuration
 * @returns The updated item to be added.
 */
export const duplicateItemHelper = (params = {}) => {
  const {
    itemsToDuplicate,
    projectDetails,
    pasteDuration = null,
    trackToPaste = null,
  } = params;

  itemsToDuplicate.sort((a, b) => a.item.track - b.item.track);
  const toUpdate = [];
  const itemsInPath = new Set();
  let durationDiff;

  if (pasteDuration !== null) {
    const minEnterStartObj = itemsToDuplicate.reduce((minObj, obj) => {
      const { enterStart } = obj.item;
      return enterStart < minObj.item.enterStart ? obj : minObj;
    }, itemsToDuplicate[0]);
    durationDiff = +(
      pasteDuration -
      minEnterStartObj.item[
        minEnterStartObj.container === "audios" ? "playStart" : "enterStart"
      ]
    );
  }

  itemsToDuplicate.forEach(({ container, item }) => {
    const startDuration = container === "audios" ? "playStart" : "enterStart";
    const endDuration = container === "audios" ? "playEnd" : "exitEnd";
    // Track in which the item to be pasted.
    const destinationTrack = trackToPaste !== null ? trackToPaste : item.track;
    // Iterate over projectDetails and group the items by track for each container.
    const itemsByTrack = projectDetails
      .get(container)
      .groupBy((d) => d.get("track"));

    // Check for overlapping of the current item with items on tracks ahead.
    for (let i = destinationTrack; i < itemsByTrack.size; i++) {
      itemsByTrack.get(i).forEach((trackItem) => {
        if (
          pasteDuration === null &&
          ((trackItem.get(startDuration) <= item[startDuration] &&
            trackItem.get(endDuration) > item[startDuration] &&
            trackItem.get(endDuration) <= item[endDuration]) ||
            (trackItem.get(startDuration) < item[endDuration] &&
              trackItem.get(startDuration) > item[startDuration]) ||
            (trackItem.get(startDuration) <= item[startDuration] &&
              trackItem.get(endDuration) >= item[endDuration]))
        ) {
          // Add the track in which item is overlapping.
          itemsInPath.add(i);
        }
        if (
          pasteDuration !== null &&
          ((trackItem.get(startDuration) <=
            item[startDuration] + durationDiff &&
            trackItem.get(endDuration) > item[startDuration] + durationDiff &&
            trackItem.get(endDuration) <= item[endDuration] + durationDiff) ||
            (trackItem.get(startDuration) < item[endDuration] + durationDiff &&
              trackItem.get(startDuration) >
                item[startDuration] + durationDiff) ||
            (trackItem.get(startDuration) <=
              item[startDuration] + durationDiff &&
              trackItem.get(endDuration) >= item[endDuration] + durationDiff))
        ) {
          itemsInPath.add(i);
        }
      });
    }

    // Find the first available track and paste the copied item.
    for (
      let i = destinationTrack;
      i < itemsByTrack.size + itemsToDuplicate.length;
      i++
    ) {
      if (!itemsInPath.has(i)) {
        toUpdate.forEach((updatedItem) => {
          if (
            pasteDuration !== null &&
            updatedItem.newItemData.track === i &&
            ((updatedItem.newItemData[startDuration] <=
              item[startDuration] + durationDiff &&
              updatedItem.newItemData[endDuration] >
                item[startDuration] + durationDiff &&
              updatedItem.newItemData[endDuration] <=
                item[endDuration] + durationDiff) ||
              (updatedItem.newItemData[startDuration] <
                item[endDuration] + durationDiff &&
                updatedItem.newItemData[startDuration] >
                  item[startDuration] + durationDiff) ||
              (updatedItem.newItemData[startDuration] <=
                item[startDuration] + durationDiff &&
                updatedItem.newItemData[endDuration] >=
                  item[endDuration] + durationDiff))
          ) {
            // checking for overlapping items in the updated item and add the track in the list
            itemsInPath.add(i);
          }
          if (
            pasteDuration === null &&
            ((updatedItem.newItemData[startDuration] <= item[startDuration] &&
              updatedItem.newItemData[endDuration] > item[startDuration] &&
              updatedItem.newItemData[endDuration] <= item[endDuration]) ||
              (updatedItem.newItemData[startDuration] <= item[endDuration] &&
                updatedItem.newItemData[startDuration] >=
                  item[startDuration]) ||
              (updatedItem.newItemData[startDuration] <= item[startDuration] &&
                updatedItem.newItemData[endDuration] >= item[endDuration]))
          ) {
            itemsInPath.add(i);
          }
        });
        let newItem = fromJS(item);
        const id = randomString("copy");
        newItem = newItem.set("id", id);
        if (!itemsInPath.has(i)) {
          newItem = newItem.set("track", i);
        } else {
          // handled new item overlapping
          newItem = newItem.set("track", Math.max(...itemsInPath) + 1);
        }
        if (pasteDuration !== null) {
          newItem = newItem.set(
            startDuration,
            item[startDuration] + durationDiff
          );
          newItem = newItem.set(endDuration, item[endDuration] + durationDiff);
          if (container === "workspaceItems") {
            newItem = newItem.set("enterEnd", item.enterEnd ? item.enterEnd + durationDiff : item.enterEnd);
            newItem = newItem.set("exitStart", item.exitStart ? item.exitStart + durationDiff : item.exitStart);
          }
        }

        toUpdate.push({
          container,
          id,
          newItemData: newItem.toJS(),
          isAdd: true,
        });
        break;
      }
    }
  });
  return toUpdate;
};

export const copyHandler = (params = {}) => {
  const { projectDetails, selectedSliders } = params;
  const itemsToCopy = [];
  ["workspaceItems", "workspaceBG", "audios"].forEach((container) => {
    selectedSliders.forEach(({ sliderId }) => {
      if (projectDetails.getIn([container, sliderId])) {
        const item = projectDetails.getIn([container, sliderId]);
        let updatedItem = item;
        if (item.has("transitionEnterId") && item.get("transitionEnterId") !== "none") {
          updatedItem = updatedItem.setIn(["transitionEnterId"], "none");
        }
        if (item.has("transitionExitId") && item.get("transitionExitId") !== "none") {
          updatedItem = updatedItem.setIn(["transitionExitId"], "none");
        }

        const data = {
          container,
          item: updatedItem.toJS(),
        };
        itemsToCopy.push(data);
      }
    });
  });
  if (itemsToCopy.length === 0) {
    return null;
  }

  // Item to be added in clipboard
  const project = {
    data: itemsToCopy,
    key: "VMAKER_CLIPBOARD",
  };

  const encoded = btoa(unescape(encodeURIComponent(JSON.stringify(project))));

  if (navigator.clipboard) {
    navigator.clipboard.writeText(encoded);
  } else {
    const aux = document.createElement("input");
    aux.style.top = 0;
    aux.style.left = 0;
    aux.style.position = "absolute";
    aux.setAttribute("value", encoded);
    document.getElementById("root").appendChild(aux);
    aux.select();
    document.execCommand("copy", false, null);
    aux.remove();
  }
  return project;
};

/**
 * Function to paste the copied item.
 * @param {boolean} canPaste
 * @param {object} copiedItem
 * @returns
 */
export const pasteHandler = (canPaste, copiedItem) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let pastedData;
    try {
      try {
        const textFromClipboard = await navigator.clipboard.readText();
        pastedData = JSON.parse(
          decodeURIComponent(escape(atob(textFromClipboard)))
        );
      } catch (error) {
        if (canPaste && copiedItem !== null) {
          pastedData = copiedItem;
        }
      }
      resolve(pastedData);
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * Function to get the panel name and item type by providing type and subtype.
 * @param {string} type
 * @param {string} subType
 * @returns { panelName: string, itemType: string }  The panel name and the type of the item ex: (Image/Video/...)
 */
export const getPanelName = (type, subType) => {
  let panelName;
  let itemType;
  if (isGiphyOnly(type, subType)) {
    itemType = PANEL.GIPHY;
  } else if (isImageOnly(type, subType)) {
    itemType = PANEL.IMAGE;
  } else if (isVideoOnly(type, subType)) {
    itemType = PANEL.VIDEO;
  } else if (isAudioOnly(type)) {
    itemType = PANEL.MUSIC;
  } else if (isPropertyOnly(type)) {
    itemType = PANEL.PROPERTIES;
  }
  if (type === "UPVIDEO" || type === "UPIMAGE" || subType === "UPAUDIO") {
    panelName = PANEL.UPLOAD;
  } else {
    panelName = itemType;
  }
  return { panelName, itemType };
};

/**
 * Function to convert the click position from px to step count.
 * @param {object} params
 * @param {object} params.rulerSlider
 * @param {number} params.x
 * @returns The step count of the timeline click position in number.
 */
export const pxToStep = (params = {}) => {
  const { rulerSlider, x } = params;

  let step = scaleWithinRange({
    fromRange: {
      start: rulerSlider.enterStart.position.x,
      end: rulerSlider.exitEnd.position.x,
    },
    toRange: {
      start: rulerSlider.enterStart.position.step,
      end: rulerSlider.exitEnd.position.step,
    },
    num: x,
  });
  let correctedStep = step;
  if (Math.ceil(step) - step < RULER_OPTIONS.stepCorrection) {
    correctedStep = Math.ceil(step);
  } else if (Math.ceil(step) - step < 0.5) {
    correctedStep = Math.ceil(step);
  } else {
    correctedStep = Math.floor(step);
  }
  step = correctedStep;
  return step;
};

/**
 * Function to convert the click position from px to step count using stepper size
 * @param {object} params
 * @param {number} params.stepSizePx
 * @param {number} params.x
 * @returns The step count of the timeline click position in number.
 */
export const pxToStep2 = (params = {}) => {
  const { stepSizePx, x } = params;

  let step = scaleWithinRange({
    fromRange: { start: 0, end: stepSizePx },
    toRange: { start: 0, end: 1 },
    num: x,
  });
  let correctedStep = step;
  if (Math.ceil(step) - step < RULER_OPTIONS.stepCorrection) {
    correctedStep = Math.ceil(step);
  } else if (Math.ceil(step) - step < 0.5) {
    correctedStep = Math.ceil(step);
  } else {
    correctedStep = Math.floor(step);
  }
  step = correctedStep;
  return step;
};
