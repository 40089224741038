import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { STATIC_PATH } from "../constants/config";
import { SmartImage } from "../common-components/SmartImage";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import {
  EditVideoTitle,
  ProfilePicContainer,
  SubUserDetails,
  SubUserDetailsContainer,
  TitleTextContainer,
} from "./animo-component";
import { formatDate } from "../helper/formateDate";

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlayerHeader = () => {
  const [showRenameModal, setRenameModal] = useState(false);
  const setRenameAction = useCallback(() => {
    setRenameModal(!showRenameModal);
  }, [showRenameModal]);
  const projectname = useSelector((state) => state.projectDetails.get("name"));
  const { username = "", profileimage, modified } = useSelector(
    (state) => state.userDetails
  );

  return (
    <Root>
      <ProfilePicContainer>
        <SmartImage
          className="profile-pic"
          imageSource={profileimage || `${STATIC_PATH}vmaker.svg`}
          placeHolderImage={profileimage || `${STATIC_PATH}vmaker.svg`}
          cursor="pointer"
          width="48px"
          height="48px"
          alt={""}
        />
        <TitleTextContainer>
          <EditVideoTitle onClick={setRenameAction}>
            <Typography
              innerContent={projectname || "UNtitled"}
              color="#32304A"
              font={font.boldSmall}
              className="rename"
              cursor="pointer"
            />
          </EditVideoTitle>
          <SubUserDetailsContainer>
            <SubUserDetails>
              <Typography
                innerContent={username}
                color="#31304A"
                font={font.normal_21}
              />
              • {formatDate(modified || new Date())}
            </SubUserDetails>
          </SubUserDetailsContainer>
        </TitleTextContainer>
      </ProfilePicContainer>
    </Root>
  );
};

export default PlayerHeader;
