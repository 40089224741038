export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const months = [
    "January", "February", "March",
    "April", "May", "June",
    "July", "August", "September",
    "October", "November", "December"
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formateDate = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}`;

  return formateDate;
}

export function sec2time(timeInSeconds) {
  const pad = (num, size) => { return (`000${num}`).slice(size * -1); };
  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  const milliseconds = time.slice(-3);

  return `${pad(hours, 1)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${milliseconds.slice(0, 2)}`;
}

export function getDuration(timeInSec) {
  const duration = sec2time(timeInSec)
  const hasHour = duration.split(":")[0] !== "0"
  if (hasHour)
    return duration.substr(0, 7)
  return duration.substr(2, 5)
}

export function getRandomInt(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}
export function formatDate(dateStr) {
  const date = new Date(dateStr);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  try {
    return date.toLocaleDateString('en-US', options);
  } catch (e) {
    return new Date().toLocaleDateString('en-US', options);
  }
}
