const isValidEmail = (str) => {
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(str);
}

export const isAnimakerEmail = (mailId) => {
    const domain = mailId.split('@')[1];
    return domain === "animaker.com";
}

export const isVmakerEmail = (mailId) => {
    const domain = ['akbar@animaker.com', 'anish@animaker.com'];
    return domain.includes(mailId);
}

export default isValidEmail;