/* eslint-disable prefer-destructuring, no-restricted-syntax, prefer-template */

import { fromJS } from "immutable";
import { rgbString2hex } from "../../helper/colorHelper";
import { ASSET_URL, UPLOADS } from "../../constants/config";
import { ffFontStyleGrouped } from "./text-constants";
import getElement from "../../helper/getElement";

export const removeSelection = () => {
  try {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
  } catch (error) {
    //
  }
};

/**
 * @param {*} currentText current selected textData
 * @param {*} selectedItem current selected textiteam id
 * @returns {Array} array of hex colors applied to the workspace item in dom.
 */
export function getMultiColors(currentText, selectedItem) {
  // var currentText = (props.textStatus.get("container") === "workspaceItems")?props.selectedObjects.get(props.textStatus.get("id")):props.selectedChildren.get(props.textStatus.get("id"));
  const textData = currentText.get("textData");
  const textContainerColor = textData.getIn([
    "formats",
    "containerStyle",
    "color",
  ]);
  let multiColors = [];
  if (!getElement("#" + selectedItem)) return [];
  // getting '.text-container' node and its children
  let textContainerNode = getElement("#" + selectedItem).firstChild;
  if (
    currentText.get("type") === "SHAPE" &&
    currentText.get("subType") === "CSTMPTH"
  ) {
    textContainerNode = getElement("#" + selectedItem)
      .getElementsByClassName("callout-text-container")[0].firstChild;
  }
  const fontTags = textContainerNode.getElementsByTagName("font");

  if (textContainerNode) {
    textContainerNode.childNodes.forEach((node) => {
      if (node.childNodes) {
        node.childNodes.forEach((node) => {
          if (node.childNodes) {
            node.childNodes.forEach((node) => {
              if (node.childNodes) {
                node.childNodes.forEach((node) => {
                  if (
                    node.nodeName === "#text" &&
                    node.data.trim() !== "" &&
                    !multiColors.includes(textContainerColor)
                  ) {
                    multiColors = [...multiColors, textContainerColor];
                  }
                });
              }
              if (
                node.nodeName === "#text" &&
                node.data.trim() !== "" &&
                !multiColors.includes(textContainerColor)
              ) {
                multiColors = [...multiColors, textContainerColor];
              }
            });
          } else if (
            node.nodeName === "#text" &&
            node.data.trim() !== "" &&
            !multiColors.includes(textContainerColor)
          ) {
            multiColors = [...multiColors, textContainerColor];
          }
        });
      }
      if (
        (node.nodeName === "#text" || node.nodeName === "span") &&
        node.data.trim() !== "" &&
        !multiColors.includes(textContainerColor)
      ) {
        multiColors = [...multiColors, textContainerColor];
      }
    });
  }

  if (fontTags) {
    for (let i = 0; i < fontTags.length; i += 1) {
      if (fontTags[i].color && !multiColors.includes(fontTags[i].color)) {
        multiColors = [...multiColors, fontTags[i].color];
      }
    }
  }

  return multiColors;
}

export function isAllTextSelected(editableElem) {
  let allTextSelected = false;

  if (window.getSelection) {
    allTextSelected =
      window.getSelection().toString().trim() === editableElem.innerText.trim();
  } else if (document.getSelection) {
    allTextSelected =
      document.getSelection().toString().trim() ===
      editableElem.innerText.trim();
  } else if (document.selection) {
    allTextSelected =
      document.selection.createRange().text.trim() ===
      editableElem.innerText.trim();
  }

  return allTextSelected;
}

export function isTextSelected() {
  let textSelected = false;
  const getSelection = window.getSelection || document.getSelection;

  if (getSelection) {
    textSelected = true;
    const selection = getSelection();
    if (
      selection.focusOffset === selection.anchorOffset || // selection is collapsed // selection is collapsed still browser reports it is not (blue selection is not visible)
      (selection.focusOffset !== selection.anchorOffset &&
        selection.toString() === "")
    ) {
      textSelected = false;
    }
  } else if (document.selection) {
    textSelected = document.selection.createRange().text !== "";
  }

  return textSelected;
}

export const getTextColors = ({
  selectedItemId,
  selectedItem,
  textStatus,
  getAllColors = false,
}) => {
  let multiColors = [];
  const selectedTextContainer = getElement(
    `#${selectedItemId} .text-container`
  );

  if (
    getAllColors ||
    (textStatus && !textStatus.get("isFocused")) ||
    (selectedTextContainer && isAllTextSelected(selectedTextContainer))
  ) {
    multiColors = getMultiColors(selectedItem, selectedItemId);
  } else {
    let selectedColor = rgbString2hex(document.queryCommandValue("ForeColor")); // this will have color of selected range of text
    if (selectedColor === "") {
      selectedColor = selectedItem.getIn([
        "textData",
        "formats",
        "containerStyle",
        "color",
      ]);
    }
    multiColors = [selectedColor];
  }

  if (multiColors.length === 0) {
    multiColors = [
      selectedItem.getIn(["textData", "formats", "containerStyle", "color"]),
    ];
  }

  return {
    containerColor: selectedItem.getIn([
      "textData",
      "formats",
      "containerStyle",
      "color",
    ]),
    multiColors,
  };
};

export const preloadTextFonts = (htmlText) => {
  const assetUrl = ASSET_URL;
  const userFontUrl = UPLOADS.font_src;
  const textElement = document.createElement("div");
  textElement.innerHTML = htmlText;
  const partialFontArr = [];

  textElement.querySelectorAll(`[data-ff]`).forEach((node) => {
    let url = "";
    if (
      node.getAttribute("data-uf") === "true" &&
      node.getAttribute("data-src")
    ) {
      url = node.getAttribute("data-src").includes(userFontUrl)
        ? node.getAttribute("data-src")
        : `${userFontUrl}${node.getAttribute("data-src")}`;
    } else if (node.getAttribute("data-uf") !== "true") {
      url = `${assetUrl}animaker/admin-fonts/${node.getAttribute(
        "data-ff"
      )}.css`;
    }

    if (url.length > 0) {
      partialFontArr.push({
        font: node.getAttribute("data-ff"),
        load: node.style.fontFamily,
        byUser: node.getAttribute("data-uf"),
        isLoaded: false,
        url,
        isPartialFont: true,
      });
    }
  });

  textElement.querySelectorAll(`font`).forEach((node) => {
    let url = "";
    let defaultFontFamily;

    if (
      node.getAttribute("data-uf") === "true" &&
      node.getAttribute("data-src") &&
      node.getAttribute("face")
    ) {
      url = node.getAttribute("data-src").includes(userFontUrl)
        ? node.getAttribute("data-src")
        : `${userFontUrl}${node.getAttribute("data-src")}`;
    } else if (
      node.getAttribute("data-uf") !== "true" &&
      node.getAttribute("face")
    ) {
      const fontFace = node
        .getAttribute("face")
        .replace(/, gfs$/, "")
        .replace(/,gfs$/, "");

      for (const fontStyle of ffFontStyleGrouped.keySeq()) {
        if (ffFontStyleGrouped.getIn([fontStyle, fontFace, 0])) {
          defaultFontFamily = ffFontStyleGrouped.getIn([
            fontStyle,
            fontFace,
            0,
            "fontFamily",
          ]);
          break;
        }
      }

      if (!defaultFontFamily) {
        defaultFontFamily = fontFace.split("-")[0];
      }

      url = `${assetUrl}animaker/admin-fonts/${defaultFontFamily}.css`;
    }
    if (url.length > 0) {
      partialFontArr.push({
        font: defaultFontFamily || node.getAttribute("face").split("-")[0],
        load: node.getAttribute("face"),
        byUser: false,
        isLoaded: false,
        url,
        isPartialFont: true,
      });
    }
  });

  return partialFontArr;
};

/**
 * @param {string} textElement html text
 * @param {Array} loadedFonts updated loadedFonts
 * @returns {Array}
 */
export function getPartialFontFamilies(textElement, loadedFonts) {
  const partialFonts = preloadTextFonts(textElement);
  for (const fontData of partialFonts) {
    if (loadedFonts.find((x) => x.get("url") === fontData.url) === undefined) {
      loadedFonts = loadedFonts.push(fromJS(fontData));
    }
  }
  return loadedFonts;
}

const getLineCount = (el, fontSize, lineHeight) => {
  return Math.round(el.offsetHeight / (fontSize * lineHeight));
};

const reduceFontInSteps = (div, span, minFontSize, maxFontSize) => {
  // reduce fontsize step by step till minFontSize to fit text to div
  const MIN_STEPS = 20;
  const MAX_STEPS = 100;
  let steps = Math.ceil((MIN_STEPS * maxFontSize) / minFontSize); // min - 20, step increases based on maxFontSize to minFontSize ratio
  if (steps > MAX_STEPS) {
    steps = MAX_STEPS;
  }
  const stepSize = (maxFontSize - minFontSize) / steps;
  for (
    let currentFontSize = maxFontSize;
    currentFontSize > minFontSize;
    currentFontSize -= stepSize
  ) {
    div.style.fontSize = `${currentFontSize}px`;
    if (div.offsetHeight >= span.offsetHeight) {
      break;
    }
  }
};

export const shrinkText = (div, span, lineHeight) => {
  const initialFontSize = parseFloat(window.getComputedStyle(div).fontSize);
  const spanTextLines = getLineCount(span, initialFontSize, lineHeight);

  let maxFontSize = initialFontSize;
  let minFontSize = maxFontSize;
  const prevLineHeightInPx = maxFontSize * lineHeight;
  const linesAlreadyChecked = Math.floor(div.offsetHeight / prevLineHeightInPx);

  // split area into lines gradually till all text goes into div
  // take font sizes before and after text goes into div
  for (let i = linesAlreadyChecked + 1; i <= spanTextLines; i += 1) {
    minFontSize = div.offsetHeight / i / lineHeight; // fontsize * lineheight_number = lineheight_px
    div.style.fontSize = `${minFontSize}px`;
    if (div.offsetHeight >= span.offsetHeight) {
      break;
    }
    maxFontSize = minFontSize;
  }

  reduceFontInSteps(div, span, minFontSize, maxFontSize);
  return parseFloat(div.style.fontSize);
};

export const getTextBounds = (textData, workspaceWidth) => {
  let textBounds = null;

  try {
    const newDiv = document.createElement("div");
    const subDiv = document.createElement("div");

    subDiv.style.fontFamily = textData.getIn([
      "formats",
      "containerStyle",
      "fontFamily",
    ]);

    const paddingSpace = 15; // empty space on either side of text
    subDiv.style.fontSize = textData.getIn([
      "formats",
      "containerStyle",
      "fontSize",
    ]);

    subDiv.style.textAlign =
      textData.getIn(["formats", "bullet", "type"]) === "unOrderedList"
        ? "left"
        : "center";
    subDiv.style.lineHeight = textData.getIn([
      "formats",
      "containerStyle",
      "lineHeight",
    ]);

    const maxWidth = workspaceWidth - paddingSpace * 2;
    newDiv.style.position = "absolute";
    subDiv.style.width = "auto";
    subDiv.style.height = "auto";
    subDiv.style.maxWidth = `${maxWidth}px`;
    subDiv.style.display = "block";
    subDiv.style.opacity = 0;
    subDiv.style.wordBreak = "break-word";
    subDiv.innerHTML = textData.get("htmlText");
    newDiv.style.padding = `${paddingSpace}px`;
    newDiv.appendChild(subDiv);
    document.body.insertBefore(newDiv, document.body.firstChild);

    textBounds = subDiv.getBoundingClientRect();
    textBounds.width = newDiv.offsetWidth + paddingSpace;
    textBounds.height = subDiv.offsetHeight;
    newDiv.remove();

    return textBounds;
  } catch (err) {
    textBounds = null;
  }

  return textBounds;
};
