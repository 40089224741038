import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  HeaderWrap,
  Anchor,
  SectionSection,
  MobileIcon,
} from "./animo-component";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import AppConfig, { STATIC_PATH } from "../constants/config";
import Action from "../common-components/Action";
import Download from "./download";
import content from "../constants/content";
import Contact from "./contact";
import vmTheme from "../constants/theme";
import { SmartImage } from "../common-components/SmartImage";
import useMobileDetection from "../helper/hooks/useMobileDetection";

const Header = ({ isExport, onAction, isMobileShow }) => {
  const [showDownload, setShowDownload] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const { isLoggedIn = false, profileimage } = useSelector(
    (state) => state.userDetails
  );
  const theme = useSelector((state) => state.app.get("theme"));
  const isMobile = useMobileDetection();

  return (
    <>
      {showDownload && <Download setShowDownload={setShowDownload} />}
      {showContact && <Contact setShowContact={setShowContact} />}
      <HeaderWrap>
        <a href={AppConfig.SITE_URL}>
          <img src={`${STATIC_PATH}vmaker-logos.svg`} alt="vmaker-logo" />
        </a>
        {!isExport && (
          <Anchor>
            <SectionSection className="animo-header-main">
              {!isLoggedIn && (
                <Typography
                  content={content.PRICING}
                  font={font.normal14}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  margin="0px 12px 0px 8px"
                  isAnchor
                  href="https://www.vmaker.com/pricing"
                  target="_blank"
                  className="pricing"
                />
              )}
              {!isLoggedIn && (
                <Action
                  text={content.GET_VMAKER_FOR_FREE}
                  height="36px"
                  width="170px"
                  margin="0px 10px"
                  padding="0px 20px 0px 40px"
                  className="download-btn"
                  onClick={() => setShowDownload(true)}
                />
              )}
              {!isLoggedIn && (
                <Typography
                  content={content.LOGIN}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="login-link"
                  href={AppConfig.SITE_URL}
                  target="_blank"
                  isAnchor
                />
              )}
              {isLoggedIn && (
                <Typography
                  content={content.DASHBOARD}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="dashbaord-link"
                  href={AppConfig.SITE_URL}
                  target="_blank"
                  margin="0px 20px 0px 8px"
                  isAnchor
                />
              )}
              {isLoggedIn && (
                <SmartImage
                  className="profile-pic"
                  imageSource={`${profileimage}`}
                  placeHolderImage={`${STATIC_PATH}vmaker.svg`}
                  cursor="pointer"
                  width="32px"
                  height="32px"
                  alt={"profile-icon"}
                />
              )}
            </SectionSection>
          </Anchor>
        )}
        {isExport && (
          <Anchor>
            {!isMobile ? (
              <>
                <Typography
                  content={content.DASHBOARD}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="dashbaord-link"
                  href={AppConfig.SITE_URL}
                  target="_blank"
                  margin="0px 20px 0px 8px"
                  isAnchor
                />

                <Typography
                  content={content.PRICING}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="dashbaord-link"
                  href="https://www.vmaker.com/pricing"
                  target="_blank"
                  margin="0px 20px 0px 8px"
                  isAnchor
                />
                <Typography
                  content={content.SUPPORT}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="dashbaord-link"
                  href="https://www.vmaker.com/support/"
                  target="_blank"
                  margin="0px 20px 0px 8px"
                  isAnchor
                />
                <Action
                  text={content.EDIT_VIDEO}
                  color={vmTheme[theme].polarColor}
                  background={vmTheme[theme].secondaryBorderColor}
                  hoverColor={vmTheme[theme].openHoverColor}
                  width={"118px"}
                  height="auto"
                  padding="2px 0px 6px"
                  font={font.mediumBase_21}
                  icon={`${STATIC_PATH}vm-add.svg`}
                  borderRadius={"8px"}
                  top="5px"
                  className="edit-btn"
                  onClick={onAction}
                />
              </>
            ) : (
              <MobileIcon
                src={`${STATIC_PATH}download-icon.svg`}
                alt="publish"
                isMobileShow={isMobileShow}
                className="publish-icon"
                onClick={() => onAction("mobileAction")}
              />
            )}
          </Anchor>
        )}
      </HeaderWrap>
    </>
  );
};

Header.propTypes = {
  isExport: PropTypes.bool,
  isMobileShow: PropTypes.bool,
  onAction: PropTypes.func,
};

export default Header;
