export const font = {
  normalMicroMini: "normal normal normal 9px/14px VM-Poppins",
  mediumMicroMini: "normal normal 500 9px/14px VM-Poppins",
  normalMicroMini_18: "normal normal normal 9px/18px VM-Poppins",
  normalMicro: "normal normal normal 10px/14px VM-Poppins",
  normalMicro_16: "normal normal normal 10px/16px VM-Poppins",
  mediumMicro_16: "normal normal 500 10px/16px VM-Poppins",
  normalMicro_10: "normal normal normal 10px/10px VM-Poppins",
  mediumMicro: "normal normal 500 10px/14px VM-Poppins",
  mediumMicro_10: "normal normal 600 10px/14px VM-Poppins",
  normalMicro_11: "normal normal normal 11px/14px VM-Poppins",
  boldMicro: "normal normal 600 11px/14px VM-Poppins",
  normalMicro12: 'normal normal 400 12px/22px VM-Poppins',
  normalMicroLarge: "normal normal normal 12px/12px VM-Poppins",
  normalMicroLarge_16: "normal normal normal 12px/16px VM-Poppins",
  normalMini: "normal normal normal 13px/14px VM-Poppins",
  normalMini_12: "normal normal normal 12px/14px VM-Poppins",
  mediumMini: "normal normal 500 13px/14px VM-Poppins",
  mediumMini_13: "normal normal 500 13px/13px VM-Poppins",
  mediumMiniBase: "normal normal 500 14px/14px VM-Poppins",
  normalMiniBase: "normal normal 400 14px/14px VM-Poppins",
  mediumMini_17: "normal normal 500 14px/17px VM-Poppins",
  boldMini_12: "normal normal 600 12px/14px VM-Poppins",
  mediumMini_12: "normal normal 500 12px/14px VM-Poppins",
  mediumLarge_27: "normal normal 500 18px/27px VM-Poppins",
  normalLarge_27: "normal normal 400 18px/27px VM-Poppins",
  boldMini_14: "normal normal 600 13px/14px VM-Poppins",
  boldMini_18: "normal normal 600 18px/14px VM-Poppins",
  boldMini_15: "normal normal 600 15px/14px VM-Poppins",
  normalBase: "normal normal normal 14px/14px VM-Poppins",
  normalBase_21: "normal normal normal 14px/21px VM-Poppins",
  mediumBase_21: "normal normal 500 14px/21px VM-Poppins",
  normalMini_13: "normal normal normal 13px/13px VM-Poppins",
  normalMini_14: "normal normal normal 14px/21px Poppins;",
  normalMini_20: "normal normal normal 13px/20px VM-Poppins",
  normalMini_17: "normal normal normal 13px/17px VM-Poppins",
  normalDefault_19: "normal normal normal 15px/23px VM-Poppins",
  boldDefault_23: "normal normal 600 15px/23px VM-Poppins",
  boldDefault_19: "normal normal 600 15px/19px VM-Poppins",
  normal: "normal normal normal 15px/14px VM-Poppins",
  normalLargeLine: "normal normal normal 15px/17px VM-Poppins",
  boldBase: "normal normal 600 14px/14px VM-Poppins",
  boldBase_16: "normal normal 600 14px/16px VM-Poppins",
  boldBase_31: "normal normal 600 14px/31px VM-Poppins",
  mediumBase_31: "normal normal 500 14px/31px VM-Poppins",
  boldRegular_25: "normal normal 600 16px/25px VM-Poppins",
  boldSmall: "normal normal 600 18px/27px VM-Poppins",
  boldSmall_22: "normal normal 600 18px/22px VM-Poppins",
  boldMini: "normal normal 600 13px/20px VM-Poppins",
  largeBold: "normal normal 600 24px/17px VM-Poppins",
  largeBold_30: "normal normal 600 24px/30px VM-Poppins",
  largeBold_14: "normal normal 600 16px/14px VM-Poppins",
  medium: "normal normal medium 18px/17px VM-Poppins",
  largeMiniBold_14: "normal normal 700 21px/14px VM-Poppins",
  normalMicroLarge_18: "normal normal normal 12px/18px VM-Poppins",
  normalMicro_22px: "normal normal normal 13px/22px VM-Poppins",
  normalBase_15: "normal normal 400 12px/15px VM-Poppins",
  normalBase_22: "normal normal 400 12px/22px VM-Poppins",
  normalBase_16: "normal normal 400 14px/16px VM-Poppins",
  normalBase_19: "normal normal 400 14px/19px VM-Poppins",
  medium_24: "normal normal 500 24px/32px VM-Poppins",
  medium_12: "normal normal 500 12px/18px VM-Poppins",
  bold_24: "normal normal bold 24px/32px VM-Poppins",
  bold_16: "normal normal 600 20px/16px VM-Poppins",
  bold_18: "normal normal 600 18px/14px VM-Poppins",
  bold_14: "normal normal 600 20px/14px VM-Poppins",
  bold_28: "normal normal 600 28px/45px VM-Poppins",
  largeBold_40: "normal normal 600 40px/22px VM-Poppins",
  semiBold_45: "normal normal 600 20px/45px VM-Poppins",
  bold_45_57_ptsans: "normal normal 700 45px/57px VM-PTSans",
  normal14: "normal normal 500 14px/22px VM-Poppins",
  normal14_400: "normal normal 400 14px/22px VM-Poppins",
  normalSmall_14: "normal normal normal 14px/14px VM-Poppins",
  normal_24: "normal normal normal 14px/24px VM-Poppins",
  normal_12: "normal normal normal 12px/18px VM-Poppins",
  normal_14: "normal normal normal 14px/18px VM-Poppins",
  bold_19: "normal normal 600 16px/19px VM-Poppins",
  normal_13: "normal normal 400 13px/16px VM-Poppins",
  bold_20_16: 'normal normal bold 20px/16px VM-Poppins',
  normal_13_22: 'normal normal normal 13px/22px VM-Poppins',
  normal_14_23: 'normal normal normal 14px/23px VM-Poppins',
  bold_12_18: 'normal normal 600 12px/18px VM-Poppins',
  normal_16: 'normal normal normal 16px / 23px VM-Poppins'
};
