import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "../common-components/Typography";
import { STATIC_PATH } from "../constants/config";
import content from "../constants/content";
import { font } from "../constants/font";
import { DownloadWrapper, BackButton, Image, ExportWrapper, ButtonWrapper, LoaderContainer, ChannelWrap, ProfilePlaceHolder, Footer, PremiumText } from "./publish-component";
import LabelInputField from "../common-components/LabelInputField";
import LabelDropdown from "../common-components/LabelDropdown";
import { DEFAULT_QUALITY_OPTIONS, FILE_FORMATS, PLAN, QUALITY_OPTIONS } from "../constants";
import Action from "../common-components/Action";
import { InfoWrapper, StockUsage, SubtitleToggle, TopWrap, TotalPriceContainer } from "../containers/header/header-components";
import CustomTooltipComponent from "../common-components/CustomTooltip";
import Toggle from "../common-components/Toggle";
import vmTheme from "../constants/theme";
import { setWorkspaceTextFocus } from "../redux/actions/appUtils";
import { updateProjectData } from "../redux/actions/timelineUtils";
import { showUpgrade } from "../redux/actions/modalAction";
import { exportVideo, getCardNonce, vcCall } from "../redux/actions/exportUtils";
import { isEnterPrise } from "../helper/checkUser";
import { getPaymentValue, isExeed } from "../containers/modal/export/export-helper";
import useNotify from "../helper/hooks/useNotify";
import Spinner, { SPINNER_TYPE } from "../common-components/Spinner";
import ExportLoader from "../containers/modal/export/export-loader";
import { GetDropdownData, PremiumSection } from "../containers/modal/export";
import ProgressBarComponent from "../common-components/Progress";
import openUrl from "../helper/openUrl";
import { createVersion } from "../api/versionUtils";
import proceedWait from "../helper/proceedWait";
import PaymentCard from "../containers/modal/export/payment-card";
import { SmartImage } from "../common-components/SmartImage";
import TextArea from "../common-components/TextArea";
import { setStageClarity } from "../helper/trackClarity";
import TRACKERS from "../constants/trackers";
// import Tags from "../common-components/Tags";

const ExportDetails = ({ goBack, isShortsPlayer, shortId, isYoutube, selectedChannel }) => {
  const dispatch = useDispatch();
  const { success, warn } = useNotify();
  const navigate = useHistory();
  const defaultDescription = "Edited on Vmaker AI Video Editor - https://www.vmaker.com/";
  const userDetails = useSelector((store) => store.userDetails);
  const { projectId, dimensionName, plan, exportquality, exportlength, pm, cardType, card, teamId } = userDetails;
  const projectDetails = useSelector((store) => store.projectDetails);
  const hasSubtitle = !!projectDetails.get("localSubtitle").size;
  const projectTitle = projectDetails.get("name");
  const theme = useSelector((state) => state.app.get("theme"));

  let shortData;
  let exportExceedKey = "exportExceed";
  if (isYoutube) {
    exportExceedKey = "socialPublishExceed";
  } else if (isShortsPlayer) {
    exportExceedKey = "lftsfExportExceed";
    shortData = projectDetails.getIn(["shorts", shortId]);
  }

  const [isBurnSubtitle, setIsBurnSubtitle] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [isProgress, setProgress] = useState(false);
  const [resData, setResData] = useState([]);
  const [progressArray, setProgressArray] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [assetExceed, setAssetExceed] = useState({ usedStockCount: 0, usedMusicCount: 0 });
  const [vc, setVc] = useState({});
  const [buttonText, setButtonText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [formData, setFormData] = useState({
    title: projectTitle,
    dimensions: DEFAULT_QUALITY_OPTIONS(dimensionName, plan, exportquality),
    format: FILE_FORMATS[0].value,
    description: "",
    // tags: "",
    privacyStatus: "public"
  });

  /**
   * Function to update the formData state
   * @param {string} name 
   * @param {string} value 
   */
  const handleFormChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const toggleBurnSubtitle = () => {
    setIsBurnSubtitle(!isBurnSubtitle);
  };

  const countAssetUsage = (res) => {
    let count = 0;
    if (!isEnterPrise(plan)) {
      res.forEach((items) => {
        if (
          items.name !== "Elite Image" &&
          items.name !== "Elite Video" &&
          items.isCredited
        ) {
          count++;
        }
      });
    }
    return count;
  };

  const checkAvailability = (data) => {
    return Object.keys(data).filter(a => a !== 'bgremoval').every((category) => {
      const { stocks } = data[category];
      return stocks.every(({ available }) => available === true);
    });
  };

  const dropDownDataStates = {
    setButtonText,
    setActiveMenu
  };

  const renderProject = (nonce = "") => {
    exportVideo({
      projectId,
      title: formData.title,
      format: formData.format,
      dimensions: formData.dimensions,
      plan: vc.plan,
      exportType: isYoutube ? "youtube" : "download",
      isWatermark: vc.watermarkExceed ? 1 : 0,
      appMode: "full",
      ppe: `${isExeed(vc, isShortsPlayer, isYoutube) && totalCost ? 1 : 0}`,
      underReview: false,
      nonce,
      burnSubtitle: isBurnSubtitle,
      ...(isShortsPlayer && { shortId }),
      ...(isYoutube && {
        description: `${formData.description}\n${defaultDescription}`,
        privacyStatus: formData.privacyStatus,
        // tags: formData.tags,
        socialId: selectedChannel.socialId,
      })
    }).then(async (res) => {
      createVersion({ projectId, ...userDetails });
      if (res.exportId) {
        await proceedWait(2000);
        success(content.EXPORT_SUCCESS);
        navigate.push(`/publish/${res.exportId}`, { shortId });
      } else if ((!res.error || res?.showSuccessToast) && res.redirect_url) {
        openUrl(res.redirect_url, !shortId);
        success(content.EXPORT_SUCCESS);
      } else if (res.error) {
        warn(res.message || content.PAYMENT_FAILED);
      }
      setStageClarity(TRACKERS.PROJECT_EXPORTED);
      setLoading(false);
      if (isShortsPlayer) {
        // reloading to update the Credits after export.
        window.location.reload();
      }
    }).catch((err) => {
      warn(err.message || err.errormsg || content.FAILED_TO_EXPORT_VIDEO);
    });
  };

  const exportPaymentVideo = () => {
    const audios = projectDetails.get("audios").toJS();
    const workspaceItems = projectDetails.get("workspaceItems").toJS();
    setLoading(true);
    if (Object.keys(audios).length || Object.keys(workspaceItems).length) {
      if (!isEnterPrise(plan) && plan !== PLAN.LITE && pm === 0 && totalCost !== 0.0) {
        getCardNonce(teamId).then((res) => {
          renderProject(res.nonce);
        }).catch((err) => {
          warn(err.message || content.FAILED_TO_GENERATE_NONCE);
        });
      } else {
        renderProject();
      }
    } else {
      warn(content.EMPTY_PROJECT);
      setLoading(false);
    }
  };

  const exportVideoProject = () => {
    const isExceeded = isExeed(vc, isShortsPlayer, isYoutube);
    const headerText = content.RUN_OUT_OF_CREDITS;
    const subText = content.UPGRADE_TO_ENJOY_CREDITS;

    if (!formData.title) {
      warn(content.ENTER_PROJECT_TITLE);
    } else if ((plan === PLAN.LITE && isExceeded) || (isShortsPlayer && vc.lftsfExportExceed)) {
      dispatch(showUpgrade({ upgradesource: 'Export-count', headerText, subText, type: vc.durationExceed ? "durationExceed" : "default" }));
    } else if ((isEnterPrise(plan) && isExceeded) || vc.bgRemovalExceed) {
      const upgradesource = isExceeded ? 'Export-count' : 'Background-removal-credits';
      dispatch(showUpgrade({ upgradesource, headerText, subText }));
    } else if (vc.durationExceed) {
      dispatch(showUpgrade({ upgradesource: 'Video-export-length', headerText, subText, type: "durationExceed" }));
    } else {
      exportPaymentVideo();
    }
  };

  const onBlur = () => dispatch(setWorkspaceTextFocus(false));

  const onFocus = () => dispatch(setWorkspaceTextFocus(true));

  const onResolutionSelect = (resolutionObj) => {
    if (resolutionObj.isUpgrade) {
      dispatch(showUpgrade({ upgradesource: 'Export-quality', type: "resolutionExceed" }));
      return;
    }
    handleFormChange("dimensions", resolutionObj.value);
  }

  useEffect(() => {
    setActiveMenu("");
    const payload = {
      projectId,
      ...(isShortsPlayer && { shortId }),
    };
    vcCall(payload).then((res) => {
      if (res.exportStatus && !(res.exportStatus === "COMPLETED" || res.exportStatus === "FAILED")) {
        setProgress(true);
      } else {
        setProgress(false);
        let usedStockCount = 0;
        let usedMusicCount = 0;
        if (res.stockPurchase.images) {
          usedStockCount += countAssetUsage(res.stockPurchase.images.stocks) + res.stockPurchase.images.usedCreditLimit;
          if (res.stockPurchase.images.isExceeded) {
            res.assetExceed = true;
          }
        }
        if (res.stockPurchase.videos) {
          usedStockCount += countAssetUsage(res.stockPurchase.videos.stocks) + res.stockPurchase.videos.usedCreditLimit;
          if (res.stockPurchase.videos.isExceeded) {
            res.assetExceed = true;
          }
        }
        if (res.stockPurchase.audios) {
          usedMusicCount = countAssetUsage(res.stockPurchase.audios.stocks) + res.stockPurchase.audios.usedCreditLimit;
          if (res.stockPurchase.audios.isExceeded) {
            res.musicAssetExceed = true;
          }
        }
        const imagesCreditLimit = res.stockPurchase.images ? res.stockPurchase.images.totalCreditLimit : 0;
        const videosCreditLimit = res.stockPurchase.videos ? res.stockPurchase.videos.totalCreditLimit : 0;
        const musicCreditLimit = res.stockPurchase.audios ? res.stockPurchase.audios.totalCreditLimit : 0;

        const stockUsageArray = [];

        if (videosCreditLimit) {
          const { stocks, usedCreditLimit } = res.stockPurchase.videos;

          stockUsageArray.push({
            title: content.VIDEO,
            value: (videosCreditLimit - usedCreditLimit - stocks.length),
            max: videosCreditLimit,
          })
        } else {
          stockUsageArray.push({
            title: content.VIDEO,
            value: videosCreditLimit,
            max: videosCreditLimit,
          })
        }

        if (imagesCreditLimit) {
          const { stocks, usedCreditLimit } = res.stockPurchase.images;

          stockUsageArray.push({
            title: content.IMAGE,
            value: (imagesCreditLimit - usedCreditLimit - stocks.length),
            max: imagesCreditLimit,
          })
        } else {
          stockUsageArray.push({
            title: content.IMAGE,
            value: imagesCreditLimit,
            max: imagesCreditLimit,
          })
        }

        if (musicCreditLimit) {
          const { stocks, usedCreditLimit } = res.stockPurchase.audios;

          stockUsageArray.push({
            title: content.MUSIC,
            value: (musicCreditLimit - usedCreditLimit - stocks.length),
            max: musicCreditLimit,
          })
        } else {
          stockUsageArray.push({
            title: content.MUSIC,
            value: musicCreditLimit,
            max: musicCreditLimit,
          })
        }

        setProgressArray(stockUsageArray.filter((item) => item.value !== 0));

        setTotalCost(getPaymentValue(res, isShortsPlayer, isYoutube));

        if (Object.keys(res.stockPurchase).length > 0) {
          const avaliablity = checkAvailability(res.stockPurchase);
          setButtonDisable(!avaliablity);
        }
        const projectDuration = isShortsPlayer ? Math.round(shortData.get("end") - shortData.get("start")) : projectDetails.get("duration");
        const durationExceed = (exportlength * 60) < projectDuration;

        setAssetExceed({ ...assetExceed, usedStockCount, usedMusicCount, durationExceed });

        res.durationExceed = durationExceed;
        setVc(res);
      }
    }).catch((error) => {
      warn(error.message || content.FAILED_TO_LOAD_DATA);
      goBack();
    });

    return () => {
      setVc({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, projectId, dimensionName]);

  useEffect(() => {
    const data = QUALITY_OPTIONS(dimensionName, plan, exportquality);
    setResData([...data]);
  }, [dimensionName, plan, exportquality]);

  useEffect(() => {
    if (!isBurnSubtitle && hasSubtitle) {
      setIsBurnSubtitle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubtitle]);

  return isActive ?
    <>
      <BackButton>
        <Image
          src={`${STATIC_PATH}back-arrow.svg`}
          width="20px"
          height="20px"
          alt="back"
          className="back-button"
          onClick={() => setIsActive(false)}
        />
        <Typography
          content={content.PREMIUM_USED}
          cursor="pointer"
          font={font.mediumMini}
          enableTrim={false}
        />
      </BackButton>
      {(vc.assetExceed || vc.musicAssetExceed || vc[exportExceedKey] || vc.durationExceed || vc.bgRemovalExceed || buttonDisable) &&
        (<PremiumSection
          musicExceed={vc.musicAssetExceed}
          stockExceed={vc.assetExceed}
          planType={plan}
          exportExceed={vc.durationExceed ? false : vc[exportExceedKey]}
          durationExceed={vc.durationExceed}
          bgRemovalExceed={vc.bgRemovalExceed}
        />)}
      <GetDropdownData
        vc={vc}
        isShorts={isShortsPlayer}
        isYoutube={isYoutube}
        dropDownDataStates={dropDownDataStates}
        assetExceed={assetExceed}
        activeMenu={activeMenu}
      />
    </> :
    (isProgress ? (
      <ExportLoader
        modalHandler={goBack}
        exportStatus={vc.exportStatus}
      />
    ) : Object.keys(vc).length > 0 ? (
      isCard ? (
        <PaymentCard />
      ) : (
        <DownloadWrapper className="export-tool-bar">
          {!isYoutube && <BackButton>
            <Image
              src={`${STATIC_PATH}back-arrow.svg`}
              width="20px"
              height="20px"
              alt="back"
              className="back-button"
              onClick={goBack}
            />
            <Typography
              content={content.DOWNLOAD_VIDEO}
              cursor="pointer"
              font={font.boldRegular_25}
              enableTrim={false}
            />
          </BackButton>}
          <ExportWrapper isPayed={(plan !== PLAN.LITE && +totalCost && !vc.durationExceed) || (pm === 0 && cardType !== "none" && vc.stockExceed && !isEnterPrise(plan))}>
            {isYoutube && <>
              <ChannelWrap>
                {selectedChannel.img ?
                  <SmartImage
                    imageSource={selectedChannel.img}
                    placeHolderImage={`${STATIC_PATH}defaultplaceholder.svg`}
                    width="32px"
                    height="32px"
                    alt={selectedChannel.name}
                    style={{ borderRadius: "8px" }}
                  /> :
                  <ProfilePlaceHolder>
                    <Typography
                      innerContent={selectedChannel.name.charAt(0).toUpperCase()}
                      color={vmTheme[theme].panelPrimaryColor}
                      font={font.mediumBase_21}
                    />
                  </ProfilePlaceHolder>}
                <Typography
                  content={selectedChannel.name}
                  color={vmTheme[theme].panelPrimaryColor}
                  font={font.normalBase_21}
                />
              </ChannelWrap>
              {/* {isPaidUser(plan) ? <Celebration margin={"16px 0px 16px"}>
                <Typography
                  content={content.CONGRATS_FREE_PUBLISH}
                  color={vmTheme[theme].celebrationViolet}
                  enableTrim={false}
                  font={font.boldMini}
                  display={"block"}
                  width={"auto"}
                  padding={"2px 0px 8px"}
                />
              </Celebration> : null} */}
              {vc.assetExceed ?
                <PremiumText>
                  <Typography
                    className="premiumHeading"
                    font={font.bold_12_18}
                    content={content.PREMIUM_USAGE_EXCEEDED}
                    display="block"
                    enableTrim={true}
                    width="240px"
                    color={vmTheme[theme].exportTextColor}
                  />
                  <Typography
                    className="premiumContent"
                    font={font.normalMicro_11}
                    color={vmTheme[theme].exportTextColor}
                    content={content.EXHAUSTED_MONTHLY_LIMIT}
                    enableTrim={false}
                  />
                </PremiumText> : null}
            </>}
            <Typography
              content={isYoutube ? content.YOUTUBE_TITLE : content.NAME}
              color={vmTheme[theme].sbSecondaryColor}
              font={isShortsPlayer ? font.normalMini : font.normalBase}
              margin="0px"
              enableTrim={false}
            />
            <LabelInputField
              placeholder="Untitled video"
              border={vmTheme[theme].iconsBorder}
              error={!formData.title}
              value={formData.title}
              onChange={(e) => handleFormChange("title", e.target.value)}
              onFocus={onFocus}
              onBlur={() => {
                onBlur();
                dispatch(updateProjectData({ name: formData.title }));
              }}
            />
            <Typography
              content={content.SELECT_QUALITY}
              color={vmTheme[theme].sbSecondaryColor}
              font={isShortsPlayer ? font.normalMini : font.normalBase}
              margin="8px 0px"
              enableTrim={false}
            />
            <LabelDropdown
              position={"static"}
              options={resData}
              defaultValue={DEFAULT_QUALITY_OPTIONS(dimensionName, plan, exportquality)}
              margin="2px 0px 16px"
              fontStyle={{ color: vmTheme[theme].panelPrimaryColor }}
              onChange={onResolutionSelect}
            />
            {isYoutube ?
              <>
                <TextArea
                  height={"56px"}
                  resize="none"
                  value={formData.description}
                  labelColor={vmTheme[theme].labelSubtextColor}
                  labelFont={isShortsPlayer ? font.normalMini : font.normalBase}
                  onChange={(e) => handleFormChange("description", e.target.value)}
                  label="YouTube Video Description"
                  placeholder="Enter your Description here..."
                  defaultText={defaultDescription}
                />
                {/* <Tags
                  placeholder="Add Keywords"
                  value={"keywords"}
                  labelText="YouTube Video Tags"
                  labelEnable={true}
                  labelMargin={"16px 0px 8px"}
                  labelColor={vmTheme[theme].labelSubtextColor}
                  labelFont={font.normalSmall_14}
                  onChange={(value) => handleFormChange("tags", value)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                /> */}
                <LabelDropdown
                  position={"static"}
                  labelText="Privacy Status"
                  labelEnable={true}
                  labelColor={vmTheme[theme].labelSubtextColor}
                  labelFont={isShortsPlayer ? font.normalMini : font.normalBase}
                  fontStyle={{ color: vmTheme[theme].panelPrimaryColor }}
                  border={`1px solid ${vmTheme[theme].iconsBorder}`}
                  requiredStar={false}
                  isBottom={!progressArray.length}
                  isScroll
                  labelMargin={"16px 0px 0px"}
                  options={[
                    {
                      label: "Public",
                      value: "public",
                    },
                    {
                      label: "Private",
                      value: "private",
                    },
                    {
                      label: "Unlisted",
                      value: "unlisted",
                    },
                  ]}
                  height="36px"
                  defaultValue={formData.privacyStatus}
                  onChange={(obj) => handleFormChange("privacyStatus", obj.value)}
                /></>
              : <>
                <Typography
                  innerContent="File Type"
                  color={vmTheme[theme].sbSecondaryColor}
                  font={isShortsPlayer ? font.normalMini : font.normalBase}
                  margin="0px 0px 8px"
                  enableTrim={false}
                />
                <LabelDropdown
                  position={"static"}
                  options={FILE_FORMATS}
                  margin="2px 0px 16px"
                  fontStyle={{ color: vmTheme[theme].panelPrimaryColor }}
                  onChange={(obj) => handleFormChange("format", obj.value)}
                />
              </>}
            {hasSubtitle &&
              <>
                <Typography
                  content={content.SUBTITLE}
                  color={vmTheme[theme].sbSecondaryColor}
                  font={isShortsPlayer ? font.normalMini : font.normalBase}
                  margin="0px 0px 2px"
                  enableTrim={false}
                />
                <SubtitleToggle>
                  <InfoWrapper>
                    <Typography
                      content={content.BURN_SUBTITLES}
                      color={vmTheme[theme].sbSecondaryColor}
                      font={font.normalMini}
                      margin="10px 0px"
                    />
                    <CustomTooltipComponent
                      tooltipId={"info-tooltip"}
                      dataTooltip={content.INCLUDE_THE_SUBTITLE}
                      tooltipPosition="top"
                      width="253px"
                      height="58px"
                      font={font.normal_13}
                    >
                      <img
                        data-tooltip-id={"info-tooltip"}
                        src={`${STATIC_PATH}i-icon.svg`}
                        alt="info-icon"
                      />
                    </CustomTooltipComponent>
                  </InfoWrapper>
                  <Toggle
                    value={isBurnSubtitle}
                    clickHandler={toggleBurnSubtitle}
                    borderRadius="34px"
                    height="20px"
                    width="32px"
                    toggleColor={vmTheme[theme].secondaryBorderColor}
                  />
                </SubtitleToggle>
              </>}
            {progressArray.length ? <Typography
              content={content.PREMIUM_USED}
              font={isShortsPlayer ? font.normalMini : font.normalBase}
              color={vmTheme[theme].panelPrimaryColor}
              margin="16px 0px 0px"
              enableTrim={false}
            /> : null}
            <GetDropdownData
              vc={vc}
              isShorts={isShortsPlayer}
              isYoutube={isYoutube}
              dropDownDataStates={dropDownDataStates}
              assetExceed={assetExceed}
              activeMenu={activeMenu}
            />
            <StockUsage margin="12px 0px 0px">
              <ProgressBarComponent
                progressArray={progressArray}
                plan={plan}
                onClick={() => {
                  dispatch(showUpgrade({ upgradesource: "Stock-exceed" }));
                }}
                enableUpgrade={progressArray.some((data) => data.value === 0)}
              />
            </StockUsage>
          </ExportWrapper>
          <ButtonWrapper>
            {plan !== PLAN.LITE && +totalCost && !vc.durationExceed ? (
              <TotalPriceContainer>
                <Typography
                  content={content.TOTAL_AMOUNT}
                  font={font.normalBase}
                  display="block"
                  color={vmTheme[theme].labelTextColor}
                  margin="16px 0 0 0"
                  align="center"
                  onClick={() => setIsActive(true)}
                />
                <Typography
                  innerContent={`$${totalCost}.00`}
                  font={font.boldBase}
                  display="block"
                  margin="16px 0 0 0"
                  align="center"
                  onClick={() => setIsActive(true)}
                />
              </TotalPriceContainer>
            ) : null}
            <Footer padding="0px">
              <Action
                background={
                  buttonDisable
                    ? vmTheme[theme].buttonDisableColor
                    : buttonText === content.DOWNLOAD
                      ? vmTheme[theme].secondaryBorderColor
                      : vmTheme[theme].actionBgColor
                }
                hoverColor={
                  buttonDisable
                    ? vmTheme[theme].buttonDisableColor
                    : buttonText === content.DOWNLOAD
                      ? vmTheme[theme].openHoverColor
                      : vmTheme[theme].actionBgColor
                }
                icon={buttonText === content.UPGRADE ? `${STATIC_PATH}white-star.svg` : ""}
                borderRadius="8px"
                fontColor={vmTheme[theme].polarColor}
                isLoading={isLoading}
                font={font.mediumMiniBase}
                margin="16px 0 0 0"
                customClass={buttonText === content.UPGRADE ? "publish-btn" : ""}
                onClick={exportVideoProject}
                height="36px"
                text={(buttonText === content.DOWNLOAD && isYoutube) ? content.PUBLISH : buttonText}
                isDisabled={buttonDisable}
              />
            </Footer>
            {pm === 0 && cardType !== "none" && vc.stockExceed && !isEnterPrise(plan) && (
              <TopWrap>
                <Typography
                  innerContent={`Pay with - ${cardType} ending ${card}.`}
                  font={font.normalMini_12}
                  display="block"
                  margin="20px 0 0 0"
                  color={vmTheme[theme].panelPrimaryColor}
                  align="center"
                />
                <Typography
                  content={content.CHANGE}
                  font={font.normalMini_12}
                  display="none"
                  color={vmTheme[theme].panelPrimaryColor}
                  margin="20px 0 0 0"
                  align="center"
                  textStyles={{ textDecoration: "underline" }}
                  onClick={() => setIsCard(true)}
                  cursor={"pointer"}
                />
              </TopWrap>
            )}
          </ButtonWrapper>
        </DownloadWrapper>)
    ) : (
      <LoaderContainer>
        <Spinner type={SPINNER_TYPE.DOTTED_SPINNER} />
      </LoaderContainer>
    ));
};

ExportDetails.propTypes = {
  goBack: PropTypes.func,
  isShortsPlayer: PropTypes.bool,
  shortId: PropTypes.string,
  isYoutube: PropTypes.bool,
  selectedChannel: PropTypes.object
};
export default ExportDetails;
