import styled, { keyframes } from "styled-components";
import { STATIC_PATH } from "../constants/config";
import { font } from "../constants/font";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #e3e4e7;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    border: none;
  }
`;

export const RightSection = styled.div`
  width: 296px;
  background: ${(props) => props.theme.polarColor};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1399px) {
    /* width: 27%; */
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 525px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: calc(100vh - 60px);
    position: absolute;
    bottom: 0;
    background: ${(props) => props.theme.polarColor};
    transform: ${(props) => props.isMobileShow ? "translateY(0)" : "translateY(100%)"};
    transition: transform 0.3s ease-in-out;
    z-index: 12;
  }
`;

export const ExportSidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.padding || "80px 24px 24px"};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .social-media {
    box-shadow: 0px 0.5px 2px #3e3e3e3d;
    align-items: start;
    padding: 0.5px 12px;
    label {
      padding: 12px 30px 12px 42px;
      &::before {
        position: absolute;
        left: 12px;
      }
      &:after {
        content: url(${STATIC_PATH}vm-left-arrow.svg);
        position: absolute;
        right: 12px;
        top: 14px;
      }
    }
    &:hover {
      border: 1px solid ${(props) => props.theme.subtitleTextColor};
      label {
        &:after {
        filter: brightness(0.5);
      }}
    }
  }
  .social-media.upgrade {
    label {
      &::before {
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 1px 2px 3px;
        border-radius: 4px;
        background-color: #c96b00;
        overflow: hidden;
      }
      &:after {
        content: unset;
      }
    }
    &:hover {
      border: 1px solid ${(props) => props.theme.btnHoverLinearColor};
      border: 0px solid transparent;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 24px;
  }
`;

export const Celebration = styled.div`
  background: #f1edff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 12px;
  margin: ${(props) => props.margin || "0px"};
  label {
    position: relative;
    @media only screen and (max-width: 767px) {
      width: auto;
      padding-right: 20px;
    }
    &:after {
      content: url(${STATIC_PATH}vm-celebration.svg);
      position: absolute;
      margin-left: 6px;
      right: 0;
      top: 4px;
      @media only screen and (min-width: 1024px) and (max-width: 1399px) {
        /* display: none; */
      }
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        display: none;
      }
      @media only screen and (max-width: 767px) {
        right: 1px;
        top: 2px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      padding: 0px;
      display: block;
    }
  }
`;

export const ChannelListWrap = styled.div`
  padding: 16px;
  height: calc(-110px + 100vh);
  overflow-y: auto;
  .add-channel {
    label {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: start;
      width: 100%;
      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 32px;
        height: 32px;
        background: #f5f6fa;
        vertical-align: middle;
        border-radius: 6px;
        left: 4px;
      }
    }
    &:hover {
      label {
        &::before {
          background: ${(props) => props.theme.plusHoverColor};
        }
      }
      background: ${(props) => props.theme.subtitleActiveBgColor};
      label {
        color: ${(props) => props.theme.panelPrimaryColor};
        &::before {
          background: ${(props) => props.theme.activeDarkColor};
        }
      }
    }
  }
  @media screen and (max-width:767px){
    height: auto;
  }
`;
export const ChannelBody = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 190px);
  height: auto;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    height: calc(100vh -60px);
  }
`;

export const ChannelList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background: ${(props) => props.theme.polarColor};
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.subtitleBgColor};
    .channel-dropdown .dot-icon {
      filter: brightness(0.5);
    }
  }
  .rotate {
    transition: transform 0.5s ease;
  }
  .rotate-active {
    transform: rotate(360deg);
  }
  .channel-dropdown {
    a {
      color: ${(props) => props.theme.textboxErrorColor};
      padding: 0 0px 0px 8px;
    }
    .dot-icon {
      width: 20px;
      height: 20px;
      &:hover{
        background: ${(props) => props.theme.youtubeDotColor} !important;  
        filter: brightness(0.5) !important;
      }
    }
    .option-ul li {
      padding: 9.5px 0px 9.5px 12px;
      width: 100%;
      height: 52px;
      font: ${font.normalBase_21};
      &:hover {
        border-radius: 8px;
      }
    }
  }
`;

export const ChannelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  margin-bottom: 12px;
`;

export const Span = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  img {
    opacity: ${(props) => (props.isError ? "0.56" : "1")};
  }
  ${(props) =>
    props.isError &&
    `
  &:after{
    content:url(${STATIC_PATH}error-icon.svg);
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`}
`;
export const YouTubeScreen = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: calc(100% - 60px);
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 6px;
  height: 56px;
  border-bottom: 1px solid #e3e4e7;
  .back-button {
    width: 32px;
    height: 32px;
    padding: 6px;
    cursor: pointer;
    &:hover {
      background: #f5f6fa 0% 0% no-repeat padding-box;
      border-radius: 6px;
    }
  }
`;

// export const DetailWrap = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 16px;
//   height: calc(100vh - 190px);
//   overflow-y: auto;
//   ::-webkit-scrollbar {
//     display: none;
//   }
//   @media only screen and (max-width: 767px) {
//     height: calc(100vh -60px);
//     .drop-down {
//       position: relative;
//     }
//     .dropdown-options-wrapper {
//       width: 100%;
//       top: auto;
//     }
//   }
// `;

export const stripesAnimation = keyframes`
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 40px 0;
    }
  `;

export const ScrollContainer = styled.div`
  height: 238px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e4e8;
  border-radius: 12px 12px 0px 0px;
  padding: 12px;
  scroll-behavior: smooth;
  border-radius: 12px 12px 4px 4px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 6px;
  background-color: #ecedf1;
  border-radius: 0px 0px 12px 12px;
  overflow: hidden;
  position: absolute;
  bottom: -3px;
  left: 0px;
`;

export const ProgressBar = styled.div`
  height: 6px;
  width: ${({ progress }) => progress}%;
  background: repeating-linear-gradient(
    -45deg,
    #7e61fd,
    #aa96ff 21px,
    #aa96ff 10px,
    /* Even lighter purple */ #7e61fd 42px
  );
  animation: ${stripesAnimation} 2s linear infinite;
  transition: width 0.8s ease-in-out;
  border-radius: 12px;
`;

export const Container = styled.div`
  position: relative;
  margin: 24px 0px 32px;
`;

export const ConfirmationWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 60px);
  padding: 24px;
  gap: 16px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /* height: calc(100vh - 60px); */
  @media screen and (min-width: 1024px) {
    .drop-down {
      .dropdown-options-wrapper {
        width: 89%;
      }
    }
  }
`;

export const ExportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: ${(props) => props.isPayed ? "calc(100vh - 250px)" : "calc(100vh - 190px)"};
  overflow-y: auto;
  position: relative;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .drop-down {
      position: relative;
    }
    .dropdown-options-wrapper {
      width: 100%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  padding: 0px 16px 16px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 296px;
  background: #fff;
  z-index: 2;
  @media only screen and (min-width: 768px) and (max-width: 992px){
    width: 296px;
  }
  @media only screen and (max-width: 767px){
    width: 100%;
  }
`;

export const customStyles = `
margin: 7px 0px;
.dropdown-option-item{
  margin:8px 0px;
}
`;

export const ExportExceedWrapper = styled.div`
  background: #e0f7ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
`;

export const Footer = styled.div`
  padding: ${(props) => props.padding || "0px 24px"};
  .publish-btn {
    label {
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 2px;
        left: unset;
        margin-left: -30px;
      }
    }
  }
`;

export const LeftSection = styled.div`
  width: calc(100% - 296px);
  height: calc(100vh - 60px);
  background: ${(props) => props.theme.subtitleBgColor};
  position: relative;
  padding-left: 120px;
  ${Footer} {
    position: absolute;
    bottom: 0px;
  }
  @media only screen and (min-width: 1600px) {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-left: 120px;
    #animo-player{
      width: calc(100% - 120px);
      height: calc(100% - 220px);
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1399px) {
    /* width: 73%; */
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 100%;
    padding-left: 75px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 100px 0px 0px;
    height: calc(100vh - 60px);
    position: fixed;
  }
`;

export const PremiumText = styled.div`
  background: ${(props) => props.theme.premiumTextColor};
  padding: 12px;
  border-radius: 8px;
  margin: 10px 0 10px;
`;

export const WorkspaceWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* width: 760px;
  height: 428px; */
  width: 80%;
  height: 60%;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    height: 170px;
    width: 95%;
    margin: auto;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    /* width: 100%; */
  }
  @media screen and (min-width: 1600px) {
    width: 897px;
    height: 504.562px;
  }
`;

export const HeadingSec = styled.div`
  margin: 38px 0px 30px;
  width: 80%;
  @media screen and (min-width: 1600px) {
    width: 857px;
  }
  @media screen and (max-width: 767px) {
    margin: 38px 16px 30px;
  }
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
  padding-right: 4px;
  vertical-align: text-bottom;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Note = styled.div`
  color: ${(props) => props.theme.sbSecondaryColor};
  font: ${font.normalBase_15};
  text-align: center;
`;

export const Link = styled.a`
  color: rgb(0, 186, 255);
  text-align: center;
  cursor: pointer;
  width: auto;
  font: ${font.normalBase_15};
  text-decoration: underline;
  -webkit-user-drag: none;
  user-select: none;
`;

export const ProfilePlaceHolder = styled.div`
  background: ${(props) => props.theme.nameBgColor};
  border-radius: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
