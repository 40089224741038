import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PLAN } from "../../constants";
import { vcCall } from "../../redux/actions/exportUtils";
import useNotify from "./useNotify";
import content from "../../constants/content";
import ActionTypes from "../../constants/action-types";
import { isEnterPrise } from "../checkUser";
import { getPaymentValue } from "../../containers/modal/export/export-helper";


const countAssetUsage = (res, plan) => {
    let count = 0;
    if (!isEnterPrise(plan)) {
        res.forEach((items) => {
            if (items.name !== "Elite Image" && items.name !== "Elite Video" && items.isCredited) {
                count++;
            }
        });
    }
    return count;
};

const checkAvailability = (data) => {
    return Object.keys(data).filter(a => a !== 'bgremoval').every((category) => {
        const { stocks } = data[category];
        return stocks.every(({ available }) => available === true);
    });
};

const useExport = () => {

    const {
        plan, credits, musicExceed, stockExceed, dailyLimit,
        watermarkExceed, exportAmount, exportlength, srtDownload, greenScreen,
        uploadSizeLimit, propsExceed, projectId
    } = useSelector((state) => state.userDetails);
    const projectDetails = useSelector((store) => store.projectDetails);
    const location = useLocation();
    const { warn } = useNotify();
    const dispatch = useDispatch();
    const { shortId } = location.state || {};
    const { vc } = useSelector((state) => state.userDetails);
    const { isYoutube = false } = vc;
    const appReducer = useSelector((state) => state.app);
    const isShortsPlayer = appReducer.get("isShortsPlayer");
    const [exportCount, setExportCount] = useState({});

    const triggerVC = useCallback((isYoutube = false) => {
        // eslint-disable-next-line no-console
        console.log("triggering");
        vcCall({
            projectId,
            ...(isShortsPlayer && { shortId })
        }).then((res) => {
            if (res.exportStatus && !(res.exportStatus === "COMPLETED" || res.exportStatus === "FAILED")) {
                dispatch({
                    type: ActionTypes.SET_VC,
                    payload: {
                        ...res,
                        isExporting: true
                    },
                });
            } else {
                let usedStockCount = 0;
                let usedMusicCount = 0;
                /** Stock Images Purchases */
                if (res.stockPurchase.images) {
                    usedStockCount += countAssetUsage(res.stockPurchase.images.stocks, plan) + res.stockPurchase.images.usedCreditLimit;
                    if (res.stockPurchase.images.isExceeded) {
                        res.assetExceed = true;
                    }
                }
                /** Stock Videos Purchases */
                if (res.stockPurchase.videos) {
                    usedStockCount += countAssetUsage(res.stockPurchase.videos.stocks, plan) + res.stockPurchase.videos.usedCreditLimit;
                    if (res.stockPurchase.videos.isExceeded) {
                        res.assetExceed = true;
                    }
                }
                /** Stock Audios Purchases */
                if (res.stockPurchase.audios) {
                    usedMusicCount = countAssetUsage(res.stockPurchase.audios.stocks, plan) + res.stockPurchase.audios.usedCreditLimit;
                    if (res.stockPurchase.audios.isExceeded) {
                        res.musicAssetExceed = true;
                    }
                }
                const imagesCreditLimit = res.stockPurchase.images ? res.stockPurchase.images.totalCreditLimit : 0;
                const videosCreditLimit = res.stockPurchase.videos ? res.stockPurchase.videos.totalCreditLimit : 0;
                const musicCreditLimit = res.stockPurchase.audios ? res.stockPurchase.audios.totalCreditLimit : 0;

                const stockUsageArray = [];

                if (videosCreditLimit) {
                    const { stocks, usedCreditLimit } = res.stockPurchase.videos;
                    stockUsageArray.push({
                        title: content.VIDEO,
                        value: (videosCreditLimit - usedCreditLimit - stocks.length),
                        max: videosCreditLimit,
                    })
                } else {
                    stockUsageArray.push({
                        title: content.VIDEO,
                        value: videosCreditLimit,
                        max: videosCreditLimit,
                    })
                }

                if (imagesCreditLimit) {
                    const { stocks, usedCreditLimit } = res.stockPurchase.images;

                    stockUsageArray.push({
                        title: content.IMAGE,
                        value: (imagesCreditLimit - usedCreditLimit - stocks.length),
                        max: imagesCreditLimit,
                    })
                } else {
                    stockUsageArray.push({
                        title: content.IMAGE,
                        value: imagesCreditLimit,
                        max: imagesCreditLimit,
                    })
                }

                if (musicCreditLimit) {
                    const { stocks, usedCreditLimit } = res.stockPurchase.audios;

                    stockUsageArray.push({
                        title: content.MUSIC,
                        value: (musicCreditLimit - usedCreditLimit - stocks.length),
                        max: musicCreditLimit,
                    })
                } else {
                    stockUsageArray.push({
                        title: content.MUSIC,
                        value: musicCreditLimit,
                        max: musicCreditLimit,
                    })
                }

                res.stockUsageArray = stockUsageArray.filter((item) => item.value !== 0);

                res.totalCost = getPaymentValue(res, isShortsPlayer, isYoutube);

                if (Object.keys(res.stockPurchase).length > 0) {
                    res.isAvailability = !checkAvailability(res.stockPurchase);
                }

                let durationExceed = false;

                if (isShortsPlayer) {
                    const shortData = projectDetails.getIn(["shorts", shortId]);
                    durationExceed = (exportlength * 60) < (Math.round(shortData.get("end") - shortData.get("start")));
                } else {
                    durationExceed = (exportlength * 60) < projectDetails.get("duration");
                }

                res.exceedings = {
                    ...{
                        usedStockCount: 0, usedMusicCount: 0
                    }, usedStockCount, usedMusicCount, durationExceed
                };
                res.isYoutube = isYoutube;
                res.durationExceed = durationExceed;
                dispatch({
                    type: ActionTypes.SET_VC,
                    payload: res,
                });
            }
        }).catch((error) => {
            warn(error.message || content.FAILED_TO_LOAD_DATA);
            // goBack();
        })
    }, [projectId, isShortsPlayer, shortId, dispatch, plan, projectDetails, exportlength, warn]);


    const [showExportLimit, setShowExportLimit] = useState(false);
    const [minAssetCount, setMinAssetCount] = useState(2);
    // eslint-disable-next-line no-unused-vars
    const [showPremiumAssetsLimit, setShowPremiumAssetsLimit] = useState(false);


    useEffect(() => {
        if (Object.keys(credits).length) {
            if (isShortsPlayer) {
                setExportCount({
                    ...(credits.lftsfexportcount || {})
                })
            } else if (isYoutube) {
                setExportCount({
                    ...(credits.socialpublishcount || {})
                })
            } else {
                setExportCount({
                    ...(credits.exportcount || {})
                })
            }
        }

    }, [isYoutube, isShortsPlayer, credits]);

    useEffect(() => {
        if (Object.keys(exportCount).length) {
            const { totalCredits, usedCredits } = exportCount;
            const balance = totalCredits - usedCredits;
            switch (plan) {
                case PLAN.FREE:
                case PLAN.LITE:
                    setShowExportLimit(balance < 2);
                    setMinAssetCount(1);
                    break;
                default:
                    setShowExportLimit(balance < 3);
                    setMinAssetCount(3);
                    break
            }
        }

    }, [plan, exportCount]);


    return {
        plan, credits, musicExceed, stockExceed, dailyLimit,
        watermarkExceed, exportAmount, exportlength, srtDownload, greenScreen,
        uploadSizeLimit, propsExceed,
        showExportLimit, showPremiumAssetsLimit,
        triggerVC, minAssetCount, exportCount
    }

}
export default useExport;