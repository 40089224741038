import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ExportSidebar } from "./publish-component";
import Typography from "../common-components/Typography";
import content from "../constants/content";
import vmTheme from "../constants/theme";
import { font } from "../constants/font";
import Action from "../common-components/Action";

const ExportFailed = ({ onAction }) => {
    const theme = useSelector((state) => state.app.get("theme"));
    return (
        <ExportSidebar padding="40px 24px 24px">
            <Typography
                content={content.EXPORT_FAILED}
                color={vmTheme[theme].textboxErrorColor}
                font={font.boldRegular_25}
                align={"center"}
            />
            <Typography
                content={content.DONT_WORRY}
                color={vmTheme[theme].sbSecondaryColor}
                font={font.normalMicroLarge_16}
                align={"center"}
                padding="8px 0px"
                enableTrim={false}
            />
            <Typography
                content={content.PLEASE_RE_EXPORT}
                color={vmTheme[theme].panelPrimaryColor}
                font={font.normalMicroLarge_16}
                align={"center"}
                padding="32px 0px 8px"
                enableTrim={false}
            />
            <Action
                text={content.CONTACT_US}
                background={vmTheme[theme].polarColor}
                font={font.mediumMiniBase}
                fontColor={vmTheme[theme].paymentCardTextColor}
                borderRadius={"8px"}
                borderWidth={"1px"}
                borderColor={vmTheme[theme].socialBorderColor}
                onClick={onAction}
            />
        </ExportSidebar>
    );
};

ExportFailed.propTypes = {
    onAction: PropTypes.func
}

export default ExportFailed;
