// Helper function to escape only attribute values, ID, and class names in a selector
const escapeSelectorParts = (selector) => {
    return selector.replace(/([#.]?)([\w-]+)/g, (match, prefix, name) => {
        if (prefix === '#' || prefix === '.') {
            // Escape ID and class names
            return `${prefix}${CSS.escape(name)}`;
        }
        return match;  // Leave other parts like tag names or attributes unchanged
    }).replace(/(\[.*?=['"])(.*?)(['"]\])/g, (match, prefix, value, suffix) => {
        // Escape attribute values (inside quotes)
        return `${prefix}${CSS.escape(value)}${suffix}`;
    });
};

export const getElement = (selector) => {
    try {
        const escapedSelector = escapeSelectorParts(selector);
        return document.querySelector(escapedSelector);
    } catch (e) {
        return null;
    }
};

export const getElementAll = (selector) => {
    try {
        const escapedSelector = escapeSelectorParts(selector);
        return document.querySelectorAll(escapedSelector);
    } catch (e) {
        return null;
    }
};

export default getElement;
