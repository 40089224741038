import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ChannelBody, ChannelList, ChannelListWrap, ChannelWrap, Footer, Span, ProfilePlaceHolder } from "./publish-component";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import DotMenuIcon from "../common-components/DotMenuIcon";
import Action from "../common-components/Action";
import vmTheme from "../constants/theme";
import { SOCIAL_MEDIA_OPTIONS } from "../constants";
import { STATIC_PATH } from "../constants/config";
import ConfirmModal from "../containers/panels/library-panels/subtitle-panel/confirmModal";
import { Modal } from "../common-components/modal";
import content from "../constants/content";
import { SmartImage } from "../common-components/SmartImage";

const Channel = ({ channelList, onChannelClick, deleteChannel, addChannel, onRefresh, isDeleting }) => {
  const theme = useSelector((state) => state.app.get("theme"));

  const [isConfirmPrompt, setIsConfirmPrompt] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const handleOptionSelect = (channel) => {
    setSelectedChannel(channel);
    setIsConfirmPrompt(true);
  };

  const closePrompt = () => setIsConfirmPrompt(false);

  const handleConfirm = () => {
    deleteChannel(selectedChannel);
  }

  return (
    <div>
      <ChannelListWrap>
        <ChannelBody>
          {channelList.map((channel) => (
            <ChannelList
              key={channel.name}
              onClick={channel.error ? null : () => onChannelClick(channel)}
            >
              <ChannelWrap isError={channel.error}>
                <Span isError={channel.error}>
                  {channel.img ?
                    <SmartImage
                      imageSource={channel.img}
                      placeHolderImage={`${STATIC_PATH}defaultplaceholder.svg`}
                      width="32px"
                      height="32px"
                      alt={channel.name}
                      style={{ borderRadius: "8px" }}
                    /> :
                    <ProfilePlaceHolder>
                      <Typography
                        innerContent={channel.name.charAt(0).toUpperCase()}
                        color={vmTheme[theme].panelPrimaryColor}
                        font={font.mediumBase_21}
                      />
                    </ProfilePlaceHolder>}
                </Span>
                <Typography
                  content={channel.name}
                  color={
                    channel.error
                      ? vmTheme[theme].errorColor
                      : vmTheme[theme].panelPrimaryColor
                  }
                  font={font.normalBase_21}
                  cursor={"pointer"}
                  width={channel.error ? "104px" : "auto"}
                />
              </ChannelWrap>
              {channel.error && (
                <Action
                  text={"Refresh"}
                  width={"68px"}
                  height={"32px"}
                  background={vmTheme[theme].subtitleBgColor}
                  font={font.mediumMiniBase}
                  fontColor={vmTheme[theme].panelPrimaryColor}
                  borderColor={vmTheme[theme].socialBorderColor}
                  hoverColor={vmTheme[theme].refreshHoverColor}
                  borderWidth={"1px"}
                  marginBottom={"0px"}
                  marginTop={"0px"}
                  onClick={() => onRefresh(channel.id)}
                />
              )}
              <DotMenuIcon
                className="channel-dropdown"
                top="auto"
                subMenu={SOCIAL_MEDIA_OPTIONS}
                icon={`${STATIC_PATH}vm-sm-dot.svg`}
                inset="0px 0px auto auto"
                padding="12px"
                minWidth="100px"
                subTop="auto"
                mobileWidth="102px"
                customLeft="85"
                customTop="10"
                fontColor={"#F52222"}
                onSelect={() => handleOptionSelect(channel)}
              />
            </ChannelList>
          ))}
        </ChannelBody>
        <Footer padding="0px">
          <Action
            text={content.ADD_MORE_CHANNEL}
            customClass={"add-channel"}
            background={vmTheme[theme].polarColor}
            fontColor={vmTheme[theme].sbSecondaryColor}
            hoverColor={vmTheme[theme].polarColor}
            icon={`${STATIC_PATH}st-plus.svg`}
            borderRadius={"8px"}
            font={font.mediumBase_21}
            height="48px"
            marginTop={"2px"}
            marginBottom={"2px"}
            onClick={addChannel}
          />
        </Footer>
      </ChannelListWrap>
      {isConfirmPrompt && (
        <Modal
          width="564px"
          showModal={true}
          preventClose={false}
          onClose={closePrompt}
        >
          <ConfirmModal
            image={"vm-warn.svg"}
            title={content.DELETE_THIS_CHANNEL}
            subText={content.PUBLISH_VIDEOS}
            channel={selectedChannel}
            handleCancel={closePrompt}
            handleConfirm={handleConfirm}
            isLoading={isDeleting}
          />
        </Modal>
      )}
    </div>
  );
};

Channel.propTypes = {
  channelList: PropTypes.arrayOf(PropTypes.object),
  onChannelClick: PropTypes.func,
  deleteChannel: PropTypes.func,
  addChannel: PropTypes.func,
  isDeleting: PropTypes.bool,
  onRefresh: PropTypes.func
};

export default Channel;
