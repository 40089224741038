import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { SmartImage } from "../../../common-components/SmartImage";
import Typography from "../../../common-components/Typography";
import { font } from "../../../constants/font";
import AppConfig, { STATIC_PATH } from "../../../constants/config";
import { sec2time } from "../../../helper/formateDate";
import BreadCrumb from "../../../common-components/BreadCrumb";
import ModalFooter from "../../../common-components/ModalFooter";
import FolderPlaceholder from "../../../common-components/FolderPlaceholder";
import { getFileSystem } from "../../../redux/actions/uploadUtils";
import { setUploadModal } from "../../../redux/actions/appUtils";
import {
    ExportContainer,
    ExportList,
    Folder,
    LibraryWrapper,
    NewFolderWrapper,
    UploadFooter,
} from "./upload-components";
import vmTheme from "../../../constants/theme";
import content from "../../../constants/content";

const Library = ({ footer }) => {
    const dispatch = useDispatch();

    const [currentFolder, setCurrentFolder] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [assetData, setData] = useState(null);
    const [files, setFiles] = useState({});
    const [folders, setFolders] = useState({});
    const [assetId, setAssetId] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([{ id: 0, name: "My Library" }]);
    const [enableFooter, setEnableFooter] = useState(false);
    const theme = useSelector((state) => state.app.get("theme"));

    const triggerApi = useCallback(() => {
        setLoading(true);
        dispatch(getFileSystem(100, { currentFolder })).then((res) => {
            setFiles(res?.file);
            setFolders(res?.folder);
            setLoading(false);
        });
    }, [currentFolder]);

    const onClose = () => {
        setEnableFooter(false);
        setData(null);
        setAssetId(null);
        dispatch(setUploadModal({ show: false })); // close modal
    };

    const onAssetClick = (source) => {
        setData(source);
        setAssetId(source.id);
        setEnableFooter(true);
    };

    useEffect(() => {
        triggerApi();
    }, [currentFolder]);

    const updateBreadcrumbs = (folderId, folderName) => {
        const newBreadcrumbs = [...breadcrumbs];
        const existingIndex = newBreadcrumbs.findIndex(
            (breadcrumb) => breadcrumb.id === folderId
        );
        if (existingIndex !== -1) {
            newBreadcrumbs.splice(existingIndex + 1);
        } else {
            newBreadcrumbs.push({ id: folderId, name: folderName });
        }
        setBreadcrumbs(newBreadcrumbs);
    };

    const handleBreadcrumbClick = (folderId) => {
        const clickedIndex = breadcrumbs.findIndex(
            (crumb) => crumb.id === folderId.id
        );
        if (clickedIndex !== -1) {
            const updatedBreadcrumbs = breadcrumbs.slice(0, clickedIndex + 1);
            setBreadcrumbs(updatedBreadcrumbs);
        } else {
            setBreadcrumbs([...breadcrumbs, folderId]);
        }
        setCurrentFolder(folderId.id);
    };

    const onAction = () => {
        dispatch(
            setUploadModal({
                show: false,
                assetId: assetData?.id,
                assetData,
            })
        );
    };

    return (
        <>
            <LibraryWrapper enableFooter={enableFooter}>
                <BreadCrumb
                    data={breadcrumbs}
                    onAction={handleBreadcrumbClick}
                    font={font.medium_12}
                    top="6px"
                    dotMenuTop="11px"
                    maxWidth="180px"
                    margin
                    icon={`${STATIC_PATH}library-icon.svg`}
                />
                {isLoading ? (
                    <FolderPlaceholder
                        className="library-placeholder"
                        margin
                        folderCount={4}
                        fileCount={8}
                    />
                ) : null}
                <ExportContainer footer={footer} enableFooter={enableFooter}>
                    {(folders?.assets || []).length ? (
                        <NewFolderWrapper gap={"10px"} margin={"initial"}>
                            {folders?.assets.map(({ _source }) => {
                                return (
                                    <Folder
                                        key={_source.id}
                                        onClick={() => {
                                            setCurrentFolder(_source.id);
                                            updateBreadcrumbs(_source.id, _source.name);
                                        }}
                                        margin={"initial"}
                                    >
                                        <Typography
                                            innerContent={_source.name}
                                            enableTrim={true}
                                            font={font.normalSmall_14}
                                            color={vmTheme[theme].animoTextColor}
                                            padding="2px 28px"
                                            cursor={"pointer"}
                                        />
                                    </Folder>
                                );
                            })}
                        </NewFolderWrapper>
                    ) : null}

                    {(files?.assets || []).length ? (
                        files?.assets.map(({ _source }) => {
                            if (_source.type !== "IMG") {
                                return (
                                    <ExportList
                                        key={_source.id}
                                        onClick={() => onAssetClick(_source)}
                                        isSelected={assetId === _source.id}
                                        onDoubleClick={onAction}
                                    >
                                        <SmartImage
                                            className="option-image"
                                            imageSource={`${STATIC_PATH}music-placeholder.svg`}
                                            placeHolderImage={AppConfig.PLACEHOLDER_IMAGE}
                                            altImage={AppConfig.PLACEHOLDER_IMAGE}
                                            cursor="pointer"
                                            alt="placeholder"
                                        />
                                        {/* )} */}
                                        <Typography
                                            innerContent={sec2time(_source.duration)}
                                            font={font.medium_12}
                                            display="block"
                                            margin="0px"
                                            align="left"
                                            color={vmTheme[theme].polarColor}
                                            cursor="pointer"
                                            className="duration"
                                        />
                                        <Typography
                                            innerContent={_source.name}
                                            font={font.normalBase_21}
                                            display="block"
                                            enableTrim={true}
                                            margin="5px 5px 0px"
                                            align="left"
                                            width={"200px"}
                                            color={vmTheme[theme].panelPrimaryColor}
                                            cursor="pointer"
                                        />
                                    </ExportList>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <div>
                            <Typography
                                content={content.NO_AUDIO_FOUND}
                                padding="12px 0px"
                                color={vmTheme[theme].panelPrimaryColor}
                                font={font.normalMicroLarge_16}
                                enableTrim={false}
                                cursor="pointer"
                            />
                        </div>
                    )}
                </ExportContainer>
            </LibraryWrapper>
            <UploadFooter enableFooter={enableFooter}>
                <ModalFooter
                    onSave={onAction}
                    justifyContent={"space-between"}
                    confirmText={"Done"}
                    cancelText={"Cancel"}
                    padding="0px"
                    backgroundColor="#00BAFF"
                    cancelBackground={"#F5F6FA"}
                    onClose={onClose}
                />
            </UploadFooter>
        </>
    );
};

Library.propTypes = {
    footer: PropTypes.bool,
};

export default Library;
