import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { BackButton, Image, LoaderContainer, YouTubeScreen } from "./publish-component";
import { API, API_URL, STATIC_PATH } from "../constants/config";
import Typography from "../common-components/Typography";
import content from "../constants/content";
import { font } from "../constants/font";
import Channel from "./channel";
import useApi from "../helper/hooks/useApi";
import Spinner, { SPINNER_TYPE } from "../common-components/Spinner";
import useNotify from "../helper/hooks/useNotify";
import ExportDetails from "./export-details";

const Youtube = ({ onBackAction, isShortsPlayer, shortId }) => {
    const { post } = useApi();
    const { success, warn } = useNotify();
    const userDetails = useSelector((store) => store.userDetails);
    const { userId } = userDetails;

    const [selectedChannel, setSelectedChannel] = useState(null);
    const [channelList, setchannelList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);


    const loadChannelData = useCallback(() => {
        setIsLoading(true);
        post(`${API_URL}${API.YOUTUBE.GET_CHANNEL}`, { userId }).then((res) => {
            if (!res.error) {
                setchannelList(res.result);
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const handleAddChannel = () => {
        return new Promise((resolve) => {
            const url = `${API_URL}api/yauth/`;
            const win = window.open(url, "Youtube", "width=500, height=600");
            const REDIRECT = `${window.location.origin}/yt/ydu/`;

            const pollTimer = window.setInterval(() => {
                try {
                    if (win.location.href.indexOf(REDIRECT) !== -1) {
                        window.clearInterval(pollTimer);
                        win.close();
                        loadChannelData();
                        resolve({ error: false, errorMsg: "" });
                    }
                } catch (err) {
                    // This block is to silently catch cross-origin errors
                }

                if (win.closed) {
                    window.clearInterval(pollTimer);
                    resolve({ error: true, errorMsg: "User closed the window." });
                }
            }, 1000);
        });
    };


    const handleChannelDelete = (value) => {
        setIsDeleting(true);
        const payload = {
            userId,
            channelid: value.socialId,
        };
        post(`${API_URL}${API.YOUTUBE.CHANNEL_DELETE}`, payload).then((res) => {
            if (!res.error) {
                success("Channel deleted successfully");
                setIsDeleting(false);
                loadChannelData();
            } else {
                warn("Failed");
                setIsDeleting(false);
            }
        });
    };

    const handleChannelClick = (channel) => {
        setSelectedChannel(channel);
    };

    const onBack = () => {
        if (selectedChannel) {
            setSelectedChannel(null);
        } else {
            onBackAction();
        }
    }

    const onRefresh = (channelId) => {
        return new Promise((resolve) => {
            const url = `${API_URL}api/yauth/?channel_id=${channelId}`;
            const win = window.open(url, "Youtube Channels", 'width=500, height=600');
            const REDIRECT = `${window.location.origin}/yt/ydu/`;
            const pollTimer = window.setInterval(() => {
                try {
                    if (win.location.href.indexOf(REDIRECT) !== -1) {
                        win.close();
                        if (win.closed) {
                            window.clearInterval(pollTimer);
                            loadChannelData();
                            resolve({ "error": false, "errorMsg": "" })
                        }
                    }
                } catch (err) {
                    // This block is to silently catch cross-origin errors
                }
            });
        });
    };

    useEffect(() => {
        loadChannelData();
    }, [loadChannelData]);

    return isLoading ?
        <LoaderContainer>
            <Spinner type={SPINNER_TYPE.DOTTED_SPINNER} />
        </LoaderContainer> :
        <YouTubeScreen>
            <BackButton>
                <Image
                    src={`${STATIC_PATH}back-arrow.svg`}
                    width="20px"
                    height="20px"
                    alt="back"
                    className="back-button"
                    onClick={onBack}
                />
                <Image
                    src={`${STATIC_PATH}vm-youtube.svg`}
                    width="24px"
                    height="24px"
                    alt="youtube"
                />
                <Typography
                    content={content.YOUTUBE}
                    cursor="pointer"
                    font={font.boldRegular_25}
                />
            </BackButton>
            {!selectedChannel && (
                <Channel
                    channelList={channelList}
                    onChannelClick={handleChannelClick}
                    deleteChannel={handleChannelDelete}
                    addChannel={handleAddChannel}
                    onRefresh={onRefresh}
                    isDeleting={isDeleting}
                />
            )}
            {selectedChannel && (
                <ExportDetails
                    isYoutube
                    goBack={onBack}
                    selectedChannel={selectedChannel}
                    isShortsPlayer={isShortsPlayer}
                    shortId={shortId}
                />
            )}
        </YouTubeScreen>;
};

Youtube.propTypes = {
    onBackAction: PropTypes.func,
    isShortsPlayer: PropTypes.bool,
    shortId: PropTypes.string
}

export default Youtube;
