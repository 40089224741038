/* eslint-disable camelcase */
import { Record, fromJS, isImmutable } from "immutable";
import { prepareClipDetails, prepareSingleClipFrame } from "../frame/frame-helper";
import { isImageOnly, isVideoOnly, randomString } from "../timeline/timeline-helper";
import { ITEM_CONFIG } from "../../constants/config";
import { getTextBounds } from "../text/TextHelper";
import { CIRCLE_FRAME, DEFAULT_FRAME_BG } from "../../constants";

export const LibraryDragItemRecord = Record({
  id: "",
  data: null,
  originalData: null,
});

export const DEFAULT_SWAP_HOVER_DROP = fromJS({
  id: "",
  childId: "",
  targetType: ITEM_CONFIG.SWAP_TARGETS.NONE,
  sourceType: ITEM_CONFIG.SWAP_SOURCE.NONE,
  frameClipId: "",
  hovering: false,
  transitionLayer: null
});

export const getDuration = (clipLength) => {
  return parseFloat(
    `${parseFloat(clipLength.split(":")[0] * 3600) +
    parseFloat(clipLength.split(":")[1] * 60) +
    parseFloat(clipLength.split(":")[2])
    }.${parseFloat(clipLength.split(":")[3])}`
  );
};

const isMusicLibItem = (type, subType) => {
  const musicTypes = ["MUSIC", "BGM", "EAB", "SFX", "UPAUDIO", "AUDIO", "SSM"];
  return musicTypes.includes(type) || musicTypes.includes(subType);
};

export const canSwapByDnD = (libItem, workspaceItem) => {
  let canSwap = false;

  if (libItem && workspaceItem) {
    let { type, subType } = libItem;
    if (isImmutable(libItem)) {
      type = libItem.get("type");
      subType = libItem.get("subType");
    }

    if (isImageOnly(workspaceItem.get("type"), workspaceItem.get("subType"))) {
      canSwap = isImageOnly(type, subType);
    }
  }

  return canSwap;
};

const getPartialVideoItem = (params = {}) => {
  const { data, isUserUpload } = params;
  const {
    width,
    height,
    pheight,
    pwidth,
    type,
    subType,
    thumbnail,
    thumbstrip,
    id,
    src,
    proxy,
    duration,
    source_type,
    name,
    isTransparentAvatar
  } = data;

  const newItem = {
    pheight,
    pwidth,
    type,
    subType,
    thumb: thumbnail,
    thumbstrip,
    assetId: id,
    volume: 1,
    videoStart: 0,
    filter: ITEM_CONFIG.IMAGE.DEFAULT_FILTER,
  };

  if (isUserUpload && type === "VIDEO") {
    newItem.src = src;
    newItem.videoDuration = duration;
    newItem.videoEnd = duration;
    newItem.type = "UPVIDEO";
    newItem.subType = type;
    newItem.pwidth = width;
    newItem.pheight = height;
    newItem.sourceType = source_type;
    newItem.name = name;

    if (source_type === "webm" && isTransparentAvatar) {
      newItem.isTransparentAvatar = true;
    }

    if (subType === "RECORD") {
      newItem.subType = subType;
    } else if (type === "VIDEO") {
      newItem.subType = "UPVIDEO";
    }
  } else {
    let durationValue;
    if (type === "PEXELS_VIDEOS" || type === "PIXABAY_VIDEOS") {
      durationValue = parseFloat(
        `${parseFloat(data.clip_length.split(":")[0] * 0) +
        parseFloat(data.clip_length.split(":")[1] * 3600) +
        parseFloat(data.clip_length.split(":")[2] * 60) +
        parseFloat(data.clip_length.split(":")[3])
        }.${parseFloat(data.clip_length.split(":")[4])}`
      );

      if (type === "PEXELS_VIDEOS") {
        newItem.type = "PEXELS";
      } else if (type === "PIXABAY_VIDEOS") {
        newItem.type = "PIXABAY";
      }
    } else {
      durationValue = getDuration(data.clip_length);
    }

    newItem.src = proxy;
    newItem.videoDuration = durationValue;
    newItem.videoEnd = durationValue;
  }

  return newItem;
};

const getPartialImageItem = (params = {}) => {
  const { data, isUserUpload } = params;

  const newItem = {
    assetId: data.id,
    type: data.type,
    subType: data.subType,
    filter: ITEM_CONFIG.IMAGE.DEFAULT_FILTER,
  };

  if (data.type === "STOCKIMG") {
    newItem.src = data.display_sizes[0].uri;
    newItem.thumb = data.display_sizes[2].uri;
    newItem.width = Number(data.max_dimensions.width);
    newItem.height = Number(data.max_dimensions.height);
    newItem.pwidth = Number(data.max_dimensions.width);
    newItem.pheight = Number(data.max_dimensions.height);
  } else if (data.type === "gif") {
    newItem.type = "IMG";
    newItem.subType = "GIPHY";

    newItem.src = data.original.url;
    newItem.thumb = data.preview.url;
    newItem.width = Number(data.original.width);
    newItem.height = Number(data.original.height);
    newItem.pwidth = Number(data.original.width);
    newItem.pheight = Number(data.original.height);
  } else if (
    data.type === "pexels" ||
    data.type === "PEXELS" ||
    data.type === "pixabay" ||
    data.type === "PIXABAY" ||
    data.subType === "UNSPLASH" ||
    data.subtype === "OBGIMG"
  ) {
    if (data.subType === "UNSPLASH") {
      newItem.type = "UNSPLASH";
      newItem.subType = "IMAGE";
    } else if (data.subtype === "OBGIMG") {
      newItem.type = "IMG";
      newItem.subType = "OBGIMG";
    } else if (data.type === "pexels") {
      newItem.type = "PEXELS";
    } else if (data.type === "pixabay") {
      newItem.type = "PIXABAY";
    }

    newItem.src = data.src;
    newItem.thumb = data.thumbnail;
    newItem.width = Number(data.width);
    newItem.height = Number(data.height);
    newItem.pwidth = Number(data.width);
    newItem.pheight = Number(data.height);
  } else if (isUserUpload || data.subtype === "BRAND") {
    newItem.type = "UPIMAGE";
    newItem.subType = "UPIMAGE";

    if (data.subtype === "BRAND") {
      newItem.subType = data.subtype;
    }

    newItem.src = data.src;
    newItem.thumb = data.thumbnail;
    newItem.width = Number(data.width);
    newItem.height = Number(data.height);
    newItem.pwidth = Number(data.width);
    newItem.pheight = Number(data.height);
    newItem.sourceType = data.source_type;

    if (data.source_type === "svg" && data.color) {
      newItem.colors = JSON.parse(data.color);
      newItem.defaultColor = newItem.colors;
    }
  }

  return newItem;
};

const getPartialShapeItem = (params = {}) => {
  const { data } = params;

  const newItem = {
    assetId: data.id,
    type: data.type,
    subType: data.subType,
    groupBounds: data.groupBounds,
    pathData: data.pathData,
    scaleType: data.scaletype,
    colors: data.colors,
    defaultColor: data.colors,
    thumb: data.thumbnail,
    width: Number(data.width),
    height: Number(data.height),
    pwidth: Number(data.width),
    pheight: Number(data.height),
    xRatio: data.xRatio ? Number(data.xRatio) : 5,
    yRatio: data.yRatio ? Number(data.yRatio) : 5,
    widthRatio: data.widthRatio ? Number(data.widthRatio) : 90,
    heightRatio: data.heightRatio ? Number(data.heightRatio) : 90,
    isVertical: data.isVertical,
    isHorizontal: data.isHorizontal,
  };

  let isAutoFontSize = false;
  if (newItem.subType === "CALLOUT") {
    isAutoFontSize = true;
  }

  newItem.textData = {
    htmlText: " ",
    isDefault: true,
    formats: {
      bullet: {
        isBullet: false,
        type: "disc",
        bulletSpace: 0,
      },
      containerStyle: {
        textAlign: "center",
        fontFamily: "Arvo",
        fontSize: "38px",
        color: "#000000",
        lineHeight: 1.2,
      },
      others: {
        isAutoFontSize,
        isRTL: false,
        minWidth: null,
        isFixedWidth: false,
        family: "Arvo",
      },
    },
  };

  if (newItem.pathData && newItem.pathData.maxBounds) {
    newItem.xRatio = Number(newItem.pathData.maxBounds.x);
    newItem.yRatio = Number(newItem.pathData.maxBounds.y);
    newItem.widthRatio = Number(newItem.pathData.maxBounds.width);
    newItem.heightRatio = Number(newItem.pathData.maxBounds.height);
  }

  newItem.minXRatio = newItem.xRatio;
  newItem.minYRatio = newItem.yRatio;
  newItem.maxWidthRatio = newItem.widthRatio;
  newItem.maxHeightRatio = newItem.heightRatio;

  return newItem;
};

const getPartialFrameItem = (params = {}) => {
  const { data } = params;

  const newItem = {
    assetId: data.id,
    type: data.type,
    subType: data.subType ? data.subType : data.type,
    width: Number(data.width),
    height: Number(data.height),
    defaultWidth: Number(data.width),
    defaultHeight: Number(data.height),
    clipDetails: data.clipDetails,
    d: data.d,
  };
  newItem.clipDetails = prepareClipDetails(fromJS(newItem));

  return newItem;
};

const getPartialPropItem = (params = {}) => {
  const { data } = params;

  const newItem = {
    assetId: data.id,
    charId: data.charId,
    type: data.type,
    subType: data.subType,
    width: Number(data.width),
    height: Number(data.height),
    pwidth: Number(data.width),
    pheight: Number(data.height),
    colors: data.colors,
    defaultColor: data.colors,
    src: data.src,
    thumb: data.thumb,
    animsrc: data.animsrc,
    animdata: data.animdata,
    isAnimated: typeof data.animsrc === "string",
    isRepeated: data.isRepeated,
    repeatLoop: 0,
  };

  if (newItem.type === "EPROP") {
    newItem.type = "PROP";
    newItem.subType = "EPROP";
  } else if (typeof data.sub_type === "string") {
    newItem.subType = data.sub_type;
  }

  return newItem;
};

const getPartialMusicItem = (params = {}) => {
  const { data, isUserUpload } = params;

  const musicDuration = Number(data.duration);

  let type = "MUSIC";
  let subType = data.type;

  if (data.type === "BGM" && data.subType === "EAB") {
    type = "EAB";
  }
  if (data.subType === "SSM") {
    type = data.subType;
    subType = data.type;
  }
  if (isUserUpload) {
    subType = "UPAUDIO";
  }

  const audio = {
    src: data.src,
    type,
    subType,
    assetId: data.id,
    name: data.name,
    volume: 1,
    musicDuration,
    musicStart: 0,
    musicEnd: musicDuration,
    fadeDetails: [],
  };
  if (data.subType === "SSM") {
    audio.songId = data.song_id
  }
  const fadeDetails = [];
  const amp = audio.volume;
  if (audio.musicDuration > 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 2 });
    fadeDetails.push({ a: amp, t: audio.musicStart + 4 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 2 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 4 });
  } else if (audio.musicDuration <= 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 0.1 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 0.1 });
  }
  audio.fadeDetails = fadeDetails;

  return audio;
};

const getPartialTextItem = (params = {}) => {
  const { data, textOffset, workspaceWidth } = params;

  const fontData = data.data;
  const { fontFamily, lineHeight } = fontData;
  const isBoldSupported = Boolean(fontData.bold);
  const isItalicSupported = Boolean(fontData.italic);

  const fontFamilyToSet = fontData[data.style];
  if (!fontFamilyToSet) {
    return null;
  }

  // data for heading
  const textItem = {
    name: "Add a heading",
    type: "TEXT",
    subType: "DTXT",
    textData: {
      htmlText: `<span data-ff=${fontFamily} data-s=${data.style} data-uf="false" style="font-family: ${fontFamily}-${data.style}, gfs; line-height: ${lineHeight};">Add a heading</span>`,
      splittedText: "Add a heading",
      isDefault: true,
      formats: {
        bullet: { type: "none", bulletSpace: 10 },
        containerStyle: {
          textAlign: "center",
          fontFamily: fontFamilyToSet,
          fontSize: "43px",
          color: "#000000",
          lineHeight,
          margin: `${textOffset / 2}px`,
        },
        others: {
          isAutoFontSize: false,
          isRTL: false,
          family: fontFamily,
          isBold: isBoldSupported,
          isItalic: isItalicSupported,
        },
      },
    },
    width: 390,
    height: 78,
    widthRatio: null,
    heightRatio: null,
    xRatio: null,
    yRatio: null,
    src: "",
    thumb: "text1.png",
  };

  if (data.dropType === "subHeading") {
    textItem.name = "Add a subheading";
    textItem.textData.htmlText = "Add a subheading";
    textItem.textData.splittedText = textItem.textData.htmlText;
    textItem.textData.formats.containerStyle.fontSize = "37px";
  } else if (data.dropType === "body") {
    textItem.name = "Add little bit of body text to your design";
    textItem.textData.htmlText = "Add little bit of body text to your design";
    textItem.textData.splittedText = textItem.textData.htmlText;
    textItem.textData.formats.containerStyle.fontSize = "27px";
  } else if (data.dropType === "bullet") {
    textItem.name = "Add a bullet text";
    textItem.textData.htmlText = `<ul><li><span data-ff=${fontFamily} data-s=${data.style} data-uf="false" style="font-family: ${fontFamily}-${data.style}, gfs; line-height: ${lineHeight};">Add a bullet text</span></li></ul>`;
    textItem.textData.splittedText = textItem.textData.htmlText;
    textItem.textData.formats.containerStyle.fontSize = "27px";
    textItem.textData.formats.containerStyle.textAlign = "left";
  } else if (data.dropType !== "heading") {
    return null;
  }

  const textBounds = getTextBounds(fromJS(textItem.textData), workspaceWidth);
  if (!textBounds) {
    return null;
  }

  textItem.width = textBounds.width;
  textItem.height = textBounds.height;

  return textItem;
};

export const generatePartialLibItem = (params = {}) => {
  const { data } = params;
  const { type, subType, subtype } = data;
  let newItem = null;

  if (isVideoOnly(type, subType)) {
    newItem = getPartialVideoItem(params);
  } else if (
    isImageOnly(type, subType) ||
    type === "gif" ||
    subType === "UNSPLASH" ||
    subtype === "OBGIMG" ||
    subType === "IMAGE"
  ) {
    newItem = getPartialImageItem(params);
  } else if (type === "SHAPE") {
    newItem = getPartialShapeItem(params);
  } else if (type === "FRAME") {
    newItem = getPartialFrameItem(params);
  } else if (type === "PROP" || type === "EPROP") {
    newItem = getPartialPropItem(params);
  } else if (isMusicLibItem(type, subType)) {
    newItem = getPartialMusicItem(params);
  } else if (type === "TEXT") {
    newItem = getPartialTextItem(params);
  }

  return newItem;
};

/**
 * @param {object} params
 * @param {object} params.videoItem
 * @param {string | undefined} params.idToUse
 * @param {string | undefined} params.dropIdToUse
 * @param {boolean | undefined} params.returnImmutable
 * @returns
 */
export const videoItemToAudioItem = (params = {}) => {
  const { idToUse = "", dropIdToUse = "", returnImmutable = false } = params;
  const videoItem = fromJS(params.videoItem);

  let playStart = videoItem.get("enterStart");
  let playEnd = videoItem.get("exitEnd");

  if (playStart === undefined) {
    // workspace bg
    playStart = videoItem.get("playStart");
    playEnd = videoItem.get("playEnd");
  }

  const audio = {
    id: idToUse || randomString("detach"),
    dropId: dropIdToUse || randomString("detach"),
    src: videoItem.get("src"),
    type: videoItem.get("type"),
    subType: videoItem.get("subType"),
    assetId: videoItem.get("assetId"),
    name: videoItem.get("name") || videoItem.get("assetId"),
    volume: videoItem.get("volume"),
    playStart,
    playEnd,
    musicDuration: videoItem.get("videoDuration"),
    musicStart: videoItem.get("videoStart"),
    musicEnd: videoItem.get("videoEnd"),
    fadeDetails: [],
    sourceType: videoItem.get("sourceType"),
    // track: 0, // not setting track here since it has to be decided based on various params like available space
  };

  if (videoItem.get("speed")) {
    audio.speed = videoItem.get("speed");
  }

  const fadeDetails = [];
  const amp = audio.volume;
  if (audio.musicDuration > 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 2 });
    fadeDetails.push({ a: amp, t: audio.musicStart + 4 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 2 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 4 });
  } else if (audio.musicDuration <= 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 0.1 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 0.1 });
  }
  audio.fadeDetails = fadeDetails;

  if (returnImmutable) {
    return fromJS(audio);
  }

  return audio;
};

export const audioDetachHelper = (params = {}) => {
  const { item, container } = params;
  if (
    item &&
    isVideoOnly(item.get("type"), item.get("subType")) &&
    !item.get("isAudioDetached")
  ) {
    const detachedAudio = videoItemToAudioItem({
      videoItem: item,
      returnImmutable: false,
    });
    detachedAudio.isDetachedAudio = true;

    const toUpdate = [
      {
        container: "audios",
        id: detachedAudio.id,
        newItemData: detachedAudio,
        isAdd: true,
        isAudioDetach: true,
      },
    ];
    toUpdate.push({
      id: item.get("id"),
      container,
      toUpdate: {
        isAudioDetached: true,
        volume: 0,
        detachedAudioId: detachedAudio.id,
      },
    });

    return toUpdate;
  }

  return null;
};

const frameObjBuilder = (frame, id, workspaceWidth, workspaceHeight) => {
  const partialLibItem = generatePartialLibItem({ data: frame });
  const libItem = { ...partialLibItem };
  const assetWidth = libItem.defaultWidth;
  const assetHeight = libItem.defaultHeight;

  const leastVal = Math.min(workspaceWidth, workspaceHeight) - 50;
  const aspectRatio = assetWidth / assetHeight;

  if (assetWidth > assetHeight) {
    libItem.width = leastVal;
    libItem.height = libItem.width / aspectRatio;
  } else {
    libItem.height = leastVal;
    libItem.width = libItem.height * aspectRatio;
  }

  libItem.x = (workspaceWidth - libItem.width) / 2;
  libItem.y = (workspaceHeight - libItem.height) / 2;
  libItem.opacity = 1;
  libItem.angle = 0;
  libItem.flipPosition = 0;
  libItem.id = id;

  return fromJS(libItem);
}

export function getAvatarWorkspaceItem(item) {
  const { duration, thumb, assetId, dropId, src, width, height, script, workspaceWidth,
    workspaceHeight, appearance, isTransparentAvatar } = item;
  const dropItem = {
    videoEnd: duration,
    thumb,
    exitEnd: duration,
    exitEffectName: "no_Effect",
    enterEffectName: "no_Effect",
    type: "UPVIDEO",
    subType: "AVATAR",
    videoStart: 0,
    exitStart: 0,
    assetId,
    enterEnd: 0,
    angle: 0,
    id: `avatar${dropId}`,
    track: 0,
    src,
    enterStart: 0,
    pwidth: width,
    pheight: height,
    filter: "6464646464640032",
    volume: 1,
    videoDuration: duration,
    dropId: `avatar${dropId}`,
    sourceType: "webm",
    flipPosition: 0,
    width,
    height,
    name: `Avatar${dropId}.webm`,
    x: 0,
    y: 0,
    opacity: 1,
    isCropped: false,
    isAvatar: true,
    script,
    isTransparentAvatar,
  };

  const scale = Math.min(
    workspaceWidth / dropItem.pwidth,
    workspaceHeight / dropItem.pheight
  );
  dropItem.width = dropItem.pwidth * scale;
  dropItem.height = dropItem.pheight * scale;
  dropItem.x = workspaceWidth / 2 - dropItem.width / 2;
  dropItem.y = workspaceHeight / 2 - dropItem.height / 2;
  let dropItemData = dropItem;

  if (appearance === "circle") {
    const frameObj = frameObjBuilder(CIRCLE_FRAME, dropItem.id, workspaceWidth, workspaceHeight);
    dropItemData = prepareSingleClipFrame(frameObj, fromJS(dropItem)).toJS();
    dropItemData.clipDetails.clip_1.imgDetails.color = DEFAULT_FRAME_BG;
  }
  return dropItemData;
}

export const AVATAR_DEFAULT_VOICES = {
  "7528735effb7cde8cd5474dc110905c2": {
    "avatarId": "7528735effb7cde8cd5474dc110905c2",
    "name": "Avery",
    "gender": "female",
    "voiceId": "b2c11ebef1e47591f75bceef56635435"
  },
  "2db5bbcbd99a6243e8d1722a7dc5d86b": {
    "avatarId": "2db5bbcbd99a6243e8d1722a7dc5d86b",
    "name": "Aliyah",
    "gender": "female",
    "voiceId": "a2d01c59c7c0e743a36bff3ea415080b"
  },
  "3b747d450fd58b3589a192fc12316f43": {
    "avatarId": "3b747d450fd58b3589a192fc12316f43",
    "name": "Mia",
    "gender": "female",
    "voiceId": "a2d01c59c7c0e743a36bff3ea415080b"
  },
  "02e3a8393cc7f282cdff6bde16860b12": {
    "avatarId": "02e3a8393cc7f282cdff6bde16860b12",
    "name": "Shreya",
    "gender": "female",
    "voiceId": "97354dff3135a614b44d1de905201f91"
  },
  "25263cbaa5069a4898325a5b1cf9ada8": {
    "avatarId": "25263cbaa5069a4898325a5b1cf9ada8",
    "name": "Shreya",
    "gender": "female",
    "voiceId": "97354dff3135a614b44d1de905201f91"
  },
  "9d1ee6f20ef056efb1cb825f13a68bc3": {
    "avatarId": "9d1ee6f20ef056efb1cb825f13a68bc3",
    "name": "Shreya",
    "gender": "female",
    "voiceId": "97354dff3135a614b44d1de905201f91"
  },
  "b65ae2b84249b9a76f250f9d7cf14f10": {
    "avatarId": "b65ae2b84249b9a76f250f9d7cf14f10",
    "name": "Shreya",
    "gender": "female",
    "voiceId": "97354dff3135a614b44d1de905201f91"
  },
  "be327e48705ab1e68d757068df828bd9": {
    "avatarId": "be327e48705ab1e68d757068df828bd9",
    "name": "Aarya",
    "gender": "female",
    "voiceId": "6237361231e40c5788956238c065b003"
  },
  "a372c5b7073bf5f46397093e1d6f1b1f": {
    "avatarId": "a372c5b7073bf5f46397093e1d6f1b1f",
    "name": "Amanda",
    "gender": "female",
    "voiceId": "6237361231e40c5788956238c065b003"
  },
  "a8f14a6bbd38536df226f4b10de2ca91": {
    "avatarId": "a8f14a6bbd38536df226f4b10de2ca91",
    "name": "Carol",
    "gender": "female",
    "voiceId": "5dbd384524980c3f63e02ae4e3ece3d9"
  },
  "7b59e6b38071320890b6f29fdefac397": {
    "avatarId": "7b59e6b38071320890b6f29fdefac397",
    "name": "Carol",
    "gender": "female",
    "voiceId": "5dbd384524980c3f63e02ae4e3ece3d9"
  },
  "a7a79e8eaa57ba2b99fb08f1f98e6f06": {
    "avatarId": "a7a79e8eaa57ba2b99fb08f1f98e6f06",
    "name": "Carol",
    "gender": "female",
    "voiceId": "5dbd384524980c3f63e02ae4e3ece3d9"
  },
  "82e6899123f57dc4ce73ee4155bb25f7": {
    "avatarId": "82e6899123f57dc4ce73ee4155bb25f7",
    "name": "Carol",
    "gender": "female",
    "voiceId": "5dbd384524980c3f63e02ae4e3ece3d9"
  },
  "427abba375b4400e2cb76896f777c193": {
    "avatarId": "427abba375b4400e2cb76896f777c193",
    "name": "Lily",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "21a217be8b7ac848c1f78655bd3e0b39": {
    "avatarId": "21a217be8b7ac848c1f78655bd3e0b39",
    "name": "Lily",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ef978c58447ab89565f6d5b132e6a4b5": {
    "avatarId": "ef978c58447ab89565f6d5b132e6a4b5",
    "name": "Anya",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "7b845a317dbeff87546fe440fb66585c": {
    "avatarId": "7b845a317dbeff87546fe440fb66585c",
    "name": "Anya",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "5100b47985c421ea25391f029afc90b8": {
    "avatarId": "5100b47985c421ea25391f029afc90b8",
    "name": "Debbie",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "a8b298adf35deab78321021f1ac24934": {
    "avatarId": "a8b298adf35deab78321021f1ac24934",
    "name": "Anya",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "4bba994b18bcd1f0ac1ac749d9d0eaa9": {
    "avatarId": "4bba994b18bcd1f0ac1ac749d9d0eaa9",
    "name": "Debbie",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ac45850e2a9a4192eab764d2e88f03d5": {
    "avatarId": "ac45850e2a9a4192eab764d2e88f03d5",
    "name": "Bec",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "40a3a621c3b493b6c0625f538a169753": {
    "avatarId": "40a3a621c3b493b6c0625f538a169753",
    "name": "Bec",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "2edc3416e1bdf0be50e9be329772f5c4": {
    "avatarId": "2edc3416e1bdf0be50e9be329772f5c4",
    "name": "Rose",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "09a89fa729802049008b30ca8a06f466": {
    "avatarId": "09a89fa729802049008b30ca8a06f466",
    "name": "Rose",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "0e777b37f7dbb56f390950c39504d9b7": {
    "avatarId": "0e777b37f7dbb56f390950c39504d9b7",
    "name": "Bec",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "d124cd405e85e41902ca8ba00a4078d4": {
    "avatarId": "d124cd405e85e41902ca8ba00a4078d4",
    "name": "Linda",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "d7c9f4a946d1d89606a3222c45c098f5": {
    "avatarId": "d7c9f4a946d1d89606a3222c45c098f5",
    "name": "Chloe",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "b0af4ddb5875f693d0de6a43ca7e5456": {
    "avatarId": "b0af4ddb5875f693d0de6a43ca7e5456",
    "name": "Linda",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "f20a2a9ded5d7ed585c946b6a0107bfa": {
    "avatarId": "f20a2a9ded5d7ed585c946b6a0107bfa",
    "name": "Chloe",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "fac5e2f140e356655555ea3fd17f4810": {
    "avatarId": "fac5e2f140e356655555ea3fd17f4810",
    "name": "Ivy",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ef68bfd47638e196c8937166012873d0": {
    "avatarId": "ef68bfd47638e196c8937166012873d0",
    "name": "Ivy",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "55183ac5875e957eb2bafb36f79ba56b": {
    "avatarId": "55183ac5875e957eb2bafb36f79ba56b",
    "name": "Ivy",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "2d3bff1d04de69420c0459db744a66e5": {
    "avatarId": "2d3bff1d04de69420c0459db744a66e5",
    "name": "Melody",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ef1c1018a3da7a0e9e099a8bb4152a8a": {
    "avatarId": "ef1c1018a3da7a0e9e099a8bb4152a8a",
    "name": "Melody",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "db39586e0526ac8029e556d295893947": {
    "avatarId": "db39586e0526ac8029e556d295893947",
    "name": "Brianna",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "5a49d6619ccbb4a5a058e30749a1a9da": {
    "avatarId": "5a49d6619ccbb4a5a058e30749a1a9da",
    "name": "Veronica",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "7df6a5ffa3ccdf7b41db79059c569945": {
    "avatarId": "7df6a5ffa3ccdf7b41db79059c569945",
    "name": "Taylor",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "15880f64393a3e91c1bee23f91f55a76": {
    "avatarId": "15880f64393a3e91c1bee23f91f55a76",
    "name": "Stephanie",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "efa8b0310ef581bb22086538ca2b0cf9": {
    "avatarId": "efa8b0310ef581bb22086538ca2b0cf9",
    "name": "Stephanie",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "cde811fce0626b27e29ad25451c3701b": {
    "avatarId": "cde811fce0626b27e29ad25451c3701b",
    "name": "Maya",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "9e77c8067d63b63ceb338ea8b7035f56": {
    "avatarId": "9e77c8067d63b63ceb338ea8b7035f56",
    "name": "Maya",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "62701420d70c840289b9ca00115bde05": {
    "avatarId": "62701420d70c840289b9ca00115bde05",
    "name": "Angela",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ffedaf1067ca4c0fe67e2fed0b855ec1": {
    "avatarId": "ffedaf1067ca4c0fe67e2fed0b855ec1",
    "name": "Angela",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ef93468b8c1b34aa93fccd7e43c8e4ea": {
    "avatarId": "ef93468b8c1b34aa93fccd7e43c8e4ea",
    "name": "Joyce",
    "gender": "female",
    "voiceId": "5d4cb8bc0ce79a407d96a73edb5d0fca"
  },
  "ed3ed3e703f4e0806188fd33b645ed46": {
    "avatarId": "ed3ed3e703f4e0806188fd33b645ed46",
    "name": "Madison",
    "gender": "female",
    "voiceId": "5bd33a8038c3827cb9c03ee27ca15a8f"
  },
  "a536225972e95c22e141db0b68e616ef": {
    "avatarId": "a536225972e95c22e141db0b68e616ef",
    "name": "Madison",
    "gender": "female",
    "voiceId": "5bd33a8038c3827cb9c03ee27ca15a8f"
  },
  "de2ff9931ed545557883035422026330": {
    "avatarId": "de2ff9931ed545557883035422026330",
    "name": "Evie",
    "gender": "female",
    "voiceId": "56832edbaec5b4b4ea3abfabc545d7e2"
  },
  "8516f6dc13f6f992792ed66c286a3a3c": {
    "avatarId": "8516f6dc13f6f992792ed66c286a3a3c",
    "name": "Evie",
    "gender": "female",
    "voiceId": "56832edbaec5b4b4ea3abfabc545d7e2"
  },
  "39597c0cfeb2402fc25188abcf04ef6f": {
    "avatarId": "39597c0cfeb2402fc25188abcf04ef6f",
    "name": "Kelsey",
    "gender": "female",
    "voiceId": "56832edbaec5b4b4ea3abfabc545d7e2"
  },
  "3e32c365f0d8ae88004234ac510e8322": {
    "avatarId": "3e32c365f0d8ae88004234ac510e8322",
    "name": "Kelsey",
    "gender": "female",
    "voiceId": "56832edbaec5b4b4ea3abfabc545d7e2"
  },
  "d6544b86d0119438101a00db7bfe7076": {
    "avatarId": "d6544b86d0119438101a00db7bfe7076",
    "name": "Kelsey",
    "gender": "female",
    "voiceId": "56832edbaec5b4b4ea3abfabc545d7e2"
  },
  "4a81c292791c6769362962d45ff3771b": {
    "avatarId": "4a81c292791c6769362962d45ff3771b",
    "name": "Isabella",
    "gender": "female",
    "voiceId": "39fd9f2b11fadd742a86574d66694a00"
  },
  "0a6a7cbbd4c841129794ca03eb299bb9": {
    "avatarId": "0a6a7cbbd4c841129794ca03eb299bb9",
    "name": "Isabella",
    "gender": "female",
    "voiceId": "39fd9f2b11fadd742a86574d66694a00"
  },
  "e01ce3d5503200563ddbafc94a49e0f7": {
    "avatarId": "e01ce3d5503200563ddbafc94a49e0f7",
    "name": "Isabella",
    "gender": "female",
    "voiceId": "39fd9f2b11fadd742a86574d66694a00"
  },
  "a61bf73394e6d7b62e532a7f86dee03d": {
    "avatarId": "a61bf73394e6d7b62e532a7f86dee03d",
    "name": "Charlotte",
    "gender": "female",
    "voiceId": "39fd9f2b11fadd742a86574d66694a00"
  },
  "238f3b95c4df34cb1d49c8cfbe883d73": {
    "avatarId": "238f3b95c4df34cb1d49c8cfbe883d73",
    "name": "Olivia",
    "gender": "female",
    "voiceId": "3880934b88f3a1f07e955c5d10a52662"
  },
  "7835b8bb1773de58dd52f52d0b0fab62": {
    "avatarId": "7835b8bb1773de58dd52f52d0b0fab62",
    "name": "Theo",
    "gender": "male",
    "voiceId": "e5bd4daf6252c5cd4575d2cc8397b156"
  },
  "3a99501c6153fa08651af36b33fd729e": {
    "avatarId": "3a99501c6153fa08651af36b33fd729e",
    "name": "William",
    "gender": "male",
    "voiceId": "bae10f91298bd127c0966d3b24d4019d"
  },
  "efe430d476066f981b386cedf888cf4c": {
    "avatarId": "efe430d476066f981b386cedf888cf4c",
    "name": "Lucas",
    "gender": "male",
    "voiceId": "9ca6aebdf36f0efd3c3b897137c8f7f6"
  },
  "26dc9c142b49f835fd34ade4d958a5a1": {
    "avatarId": "26dc9c142b49f835fd34ade4d958a5a1",
    "name": "Lucas",
    "gender": "male",
    "voiceId": "9ca6aebdf36f0efd3c3b897137c8f7f6"
  },
  "970a8f915705f63ff6a83896cd0c9f0e": {
    "avatarId": "970a8f915705f63ff6a83896cd0c9f0e",
    "name": "Tim",
    "gender": "male",
    "voiceId": "5c1bc33607cdcb269e6b8fe34dfd7fbd"
  },
  "1cb2d23709a38616ec03b6988254aecd": {
    "avatarId": "1cb2d23709a38616ec03b6988254aecd",
    "name": "Raj",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "2e3b9f37d03d2110370cf89a3c41827c": {
    "avatarId": "2e3b9f37d03d2110370cf89a3c41827c",
    "name": "Raj",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "dc79c15f17351d1ae0f8c2d0aed33bf6": {
    "avatarId": "dc79c15f17351d1ae0f8c2d0aed33bf6",
    "name": "Noah",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "d4ae35aa366837f3d3ba3cadf34875f8": {
    "avatarId": "d4ae35aa366837f3d3ba3cadf34875f8",
    "name": "Noah",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "d034b1e24d44ecb896185e58ba6cf153": {
    "avatarId": "d034b1e24d44ecb896185e58ba6cf153",
    "name": "Noah",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "8f37a2ba598d0bba40d744420944696b": {
    "avatarId": "8f37a2ba598d0bba40d744420944696b",
    "name": "Noah",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "75cd92ea5d41a929e7fd563415abec03": {
    "avatarId": "75cd92ea5d41a929e7fd563415abec03",
    "name": "Noah",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "bcc7002469317e69f9a590aede3f1fa2": {
    "avatarId": "bcc7002469317e69f9a590aede3f1fa2",
    "name": "Cooper",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "117ca6328795730be9ab3370bbbc23ea": {
    "avatarId": "117ca6328795730be9ab3370bbbc23ea",
    "name": "Cooper",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "6bf8018cc877b271a1ccdd119983d6e5": {
    "avatarId": "6bf8018cc877b271a1ccdd119983d6e5",
    "name": "Cooper",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "1b2036480d1fbc3749e92ae0884fc07a": {
    "avatarId": "1b2036480d1fbc3749e92ae0884fc07a",
    "name": "Jaiden",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "d1571f90fbdb0632bac95c730c8bb17d": {
    "avatarId": "d1571f90fbdb0632bac95c730c8bb17d",
    "name": "Shiv",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "e83dc9191b1ab4916d3d56a8dc53e535": {
    "avatarId": "e83dc9191b1ab4916d3d56a8dc53e535",
    "name": "Chase",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "ea75df7f2e68e9841591b6b120c5cc62": {
    "avatarId": "ea75df7f2e68e9841591b6b120c5cc62",
    "name": "Gavin",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "b21c2fcd69c20fb3adbe7db6122ac9e9": {
    "avatarId": "b21c2fcd69c20fb3adbe7db6122ac9e9",
    "name": "Raymond",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "bb2868e1f25506b4542c3b30aa4760e5": {
    "avatarId": "bb2868e1f25506b4542c3b30aa4760e5",
    "name": "Jackson",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "829ef46b10927e2cffc2becf8b5668be": {
    "avatarId": "829ef46b10927e2cffc2becf8b5668be",
    "name": "Carlos",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "181230beb1b6ed1615097ffb60ae1459": {
    "avatarId": "181230beb1b6ed1615097ffb60ae1459",
    "name": "Carlos",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "3c317be22e98d0368215016a6213736d": {
    "avatarId": "3c317be22e98d0368215016a6213736d",
    "name": "Luis",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "0aa4455cd9ba4651cfc7df50f4c5101d": {
    "avatarId": "0aa4455cd9ba4651cfc7df50f4c5101d",
    "name": "Shiv",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "ec97a5347d2f26e4d3b7f433330b6477": {
    "avatarId": "ec97a5347d2f26e4d3b7f433330b6477",
    "name": "Chase",
    "gender": "male",
    "voiceId": "304bdca0d4412fd1e32441dc3db1bcd5"
  },
  "8b467a6e20e1c8a5dd6ff7960abc32ed": {
    "avatarId": "8b467a6e20e1c8a5dd6ff7960abc32ed",
    "name": "David",
    "gender": "male",
    "voiceId": "2cdec7624091a2f05c190e66a6ece448"
  },
  "4aa041b49c308ab73caca932635846dd": {
    "avatarId": "4aa041b49c308ab73caca932635846dd",
    "name": "Mateo",
    "gender": "male",
    "voiceId": "28257272108eed755f3ab86d6352a8c5"
  },
  "71a4a60892310a464738c39af9461ff6": {
    "avatarId": "71a4a60892310a464738c39af9461ff6",
    "name": "Mateo",
    "gender": "male",
    "voiceId": "28257272108eed755f3ab86d6352a8c5"
  },
  "1c1a1a806bc3280850553bcb780437a2": {
    "avatarId": "1c1a1a806bc3280850553bcb780437a2",
    "name": "Thomas",
    "gender": "male",
    "voiceId": "28257272108eed755f3ab86d6352a8c5"
  },
  "27438418877c1d6f4c3b320b984bc353": {
    "avatarId": "27438418877c1d6f4c3b320b984bc353",
    "name": "Thomas",
    "gender": "male",
    "voiceId": "28257272108eed755f3ab86d6352a8c5"
  },
  "3384c8c47f98211feca74407490716e8": {
    "avatarId": "3384c8c47f98211feca74407490716e8",
    "name": "Thomas",
    "gender": "male",
    "voiceId": "28257272108eed755f3ab86d6352a8c5"
  },
  "6cb12fd47c6f6d23ee78ec577c821b54": {
    "avatarId": "6cb12fd47c6f6d23ee78ec577c821b54",
    "name": "Thomas",
    "gender": "male",
    "voiceId": "28257272108eed755f3ab86d6352a8c5"
  },
  "3eefe26798ef4e25d7fc3c68988d2630": {
    "avatarId": "3eefe26798ef4e25d7fc3c68988d2630",
    "name": "Devon",
    "gender": "male",
    "voiceId": "269bdb2eb7cbb0b3e2a81447f7a15131"
  },
  "46ec4ce6f90eae213ae32daa0a340089": {
    "avatarId": "46ec4ce6f90eae213ae32daa0a340089",
    "name": "Devon",
    "gender": "male",
    "voiceId": "269bdb2eb7cbb0b3e2a81447f7a15131"
  },
  "a23d3982f8912976a6ad4efdd1c2d6fa": {
    "avatarId": "a23d3982f8912976a6ad4efdd1c2d6fa",
    "name": "Corey",
    "gender": "male",
    "voiceId": "269bdb2eb7cbb0b3e2a81447f7a15131"
  }
}