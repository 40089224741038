/* eslint-disable camelcase, no-restricted-syntax */

import { batch } from "react-redux";
import { fromJS, isImmutable } from "immutable";
import ActionTypes, {
  SAVE_START_ACTIONS,
} from "../../constants/action-types";
import {
  getSubtitleUpdater,
  getTimelineUpdater,
  setUpdaterProjectDuration,
} from "../../containers/timeline/timeline-autosavehelper";
import {
  applyColorComplete,
  applyFlipComplete,
  applyFrameClipPlotComplete,
  bulkObjectUpdateComplete,
  moveUpdateComplete,
  updateGroupTextComplete,
  updateObjectComplete,
  updateProjectDataComplete,
  updateSubtitleListComplete,
  updateTimelineTimeComplete,
} from "../actions/timelineUtils";
import { sendSocketMessage } from "../../socket";
import {
  isImageOnly,
  isVideoOnly,
} from "../../containers/timeline/timeline-helper";
import {
  getItemBounds,
  getObjectsBounds,
  getRotatedPoint,
} from "../../helper/TransformManagerHelper";
import { getUpdatedVolume } from "../../containers/workspace/workspace-helper";
import socketChannels from "../../constants/socket-channels";
import { addUploadHistory, insertSubtitleDataComplete, updateSubtitleDataComplete } from "../actions/appUtils";
import { PROJECT_CONTAINERS } from "../../constants";

let delayedActions = [];

const autosaveMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const { dispatch } = store;

  if (state.app.get("isPlayAll") && action.type.indexOf("Complete") > -1) {
    delayedActions.push(action);
    return;
  }

  if ((action.type === ActionTypes.UPDATE_INRO_OUTRO_TEXT || action.type === ActionTypes.APPLY_FLIP_INITIAL) && action.shouldHoldDispatch === true) {
    action.shouldHoldDispatch = false;
    delayedActions.push(action);
    return;
  }

  if (!state.app.get("isPlayAll") && delayedActions.length > 0) {
    batch(() => {
      const delayedActionsCopy = delayedActions;
      delayedActions = [];
      delayedActionsCopy.forEach((delayedAction) => {
        dispatch(delayedAction);
      });
    });
  }

  if (SAVE_START_ACTIONS.includes(action.type)) {
    dispatch({ type: ActionTypes.SAVE_START });
  }

  if (
    action.type === ActionTypes.UPDATE_TIMELINE ||
    action.type === ActionTypes.DELETE_OBJECT ||
    action.type === ActionTypes.ADD_OBJECT ||
    action.type === ActionTypes.ADD_REPLACE_OBJECT ||
    action.type === ActionTypes.MOVE_SUBTITLE ||
    action.type === ActionTypes.UPDATE_BLOB_ITEM
  ) {
    const willEmit = action.type !== ActionTypes.ADD_REPLACE_OBJECT;
    if (action.type === ActionTypes.UPDATE_BLOB_ITEM) {
      PROJECT_CONTAINERS.forEach((container)=>{
        if (state.projectDetails.get(container)) {
          state.projectDetails.get(container).forEach((item) => {
            if (item.get("isBlob") && (item.get("dropId") === action?.payload?.toUpdate[0]?.blobId)) {
              if (action.payload.toUpdate[0].isDelete) {
                action.payload.toUpdate[0].id = item.get("id");
              } else {
                action.payload.toUpdate[0].id = action.payload.toUpdate[0].blobId;
                const dummyId = item.get("assetId");
                const uploadData = {
                  [dummyId]: action.payload.toUpdate[1].uploadHistory,
                };
                // Keep uploaded response to upload history, It will help us to find the respective blob item
                dispatch(addUploadHistory(uploadData));
              }
            }
          });
        }
      })
    }

    let updaterObject = action.autosaveResult;
    const playhead = state.app.get("playhead");

    if (!updaterObject) {
      // first time this action is dispatched
      updaterObject = getTimelineUpdater({
        action,
        projectDetails: state.projectDetails,
        playhead,
        willEmit,
      });
      action.autosaveResult = updaterObject;
    } else {
      updaterObject = setUpdaterProjectDuration({
        projectDetails: state.projectDetails,
        updaterObject,
      });
    }
   // For blob redo, need update the blob item
   PROJECT_CONTAINERS.forEach((container) => {
      if (updaterObject.get(container)) {
        updaterObject.get(container).forEach((item) => {
          if (item.getIn(["data", "isBlob"])) {
            const upload = state.app.getIn(["uploadHistory", item.getIn(["data", "assetId"])]);
            if (upload) {
              updaterObject = updaterObject.updateIn([container, item.getIn(["data", "id"]), "data", "src"], () => upload.get("src"))
              updaterObject = updaterObject.updateIn([container, item.getIn(["data", "id"]), "data", "thumb"], () => upload.get("thumbnail"))
              updaterObject = updaterObject.updateIn([container, item.getIn(["data", "id"]), "data", "isBlob"], () => false)
              updaterObject = updaterObject.updateIn([container, item.getIn(["data", "id"]), "data", "assetId"], () => upload.get("id"))
            }
          }
        })
      }
    })

    let actionRecovery;
    if (action.payload.actionRecovery) {
      actionRecovery = action.payload.actionRecovery.get("recovery");
    }

    updaterObject = updaterObject.toJS();
    dispatch(updateTimelineTimeComplete({ data: updaterObject, actionRecovery }, true));
    if (willEmit) {
      sendSocketMessage("updateTimeline", { data: updaterObject });
    }
  }

  if (action.type === ActionTypes.UNDO_TIMELINE_UPDATE) {
    let updaterObject = action.payload.data;
    updaterObject = setUpdaterProjectDuration({
      projectDetails: state.projectDetails,
      updaterObject,
    });

    let actionRecovery;
    if (action.payload.actionRecovery) {
      actionRecovery = action.payload.actionRecovery.get("undoRecovery");
    }

    updaterObject = updaterObject.toJS();
    dispatch(updateTimelineTimeComplete({ data: updaterObject, actionRecovery }, true));
    sendSocketMessage("updateTimeline", { data: updaterObject });
  }

  if (action.type === ActionTypes.UPDATE_TIMELINE_COMPLETE) {
    const prevDefaultSubtitle = state.projectDetails.get("defaultSubtitle");
    let activeSubtitleId = "";
    if (isImmutable(action.payload.data)) {
      const subtitleList = action.payload.data?.getIn(["project", "data", "subtitle_data"]);
      if (subtitleList) {
        const activeSubtitle = subtitleList.find((entry) => entry.get("isActive"));
        if (activeSubtitle) {
          activeSubtitleId = activeSubtitle.get("subtitleId");
        }
      }
    } else {
      const subtitleList = action.payload.data?.project?.data?.subtitle_data;
      if (subtitleList) {
        const activeSubtitle = subtitleList.find((entry) => entry.isActive);
        if (activeSubtitle) {
          activeSubtitleId = activeSubtitle.subtitleId;
        }
      }
    }
    if (activeSubtitleId && prevDefaultSubtitle !== activeSubtitleId) {
      action.shouldReconcile = true;
      action.subtitleId = activeSubtitleId;
    }
  }

  if (action.type === ActionTypes.MOVE_UPDATE) {
    let { currentContainer } = action.payload;
    if (currentContainer === undefined) currentContainer = "workspaceItems";

    const getAllItems = state.projectDetails.get(currentContainer);
    let workspaceItems = fromJS({});
    let workspaceChildren = fromJS({});

    action.payload.items.entrySeq().forEach(([key, item]) => {
      if (item.get("x") !== undefined && item.get("y") !== undefined)
        workspaceItems = workspaceItems.set(
          key,
          fromJS({ x: item.get("x"), y: item.get("y") })
        );

      if (item.get("type") === "GROUP") {
        const item_to_merge = getAllItems.get(key);
        const dX = item.get("x") - item_to_merge.get("x");
        const dY = item.get("y") - item_to_merge.get("y");
        const groupItems = state.projectDetails.get("workspaceChildren");
        item
          .get("groupChildren")
          .entrySeq()
          .forEach(([, itemId]) => {
            const child = groupItems.get(itemId);
            const childToUpdate = {
              x: child.get("x") + dX,
              y: child.get("y") + dY,
            };
            workspaceChildren = workspaceChildren.set(
              itemId,
              fromJS(childToUpdate)
            );
          });
      }
    });

    const payload = {
      workspaceItems,
      workspaceChildren,
      currentContainer,
    };
    store.dispatch(moveUpdateComplete({ data: payload }, true));
    sendSocketMessage("moveUpdate", { data: payload });
  }

  if (action.type === ActionTypes.ROTATION_UPDATE) {
    let { currentContainer } = action.payload;
    if (currentContainer === undefined) currentContainer = "workspaceItems";

    const getAllItems = state.projectDetails.get(currentContainer);
    const groupItems = state.projectDetails.get("workspaceChildren");

    let workspaceItems = fromJS({});
    let workspaceChildren = fromJS({});

    action.payload.items.entrySeq().forEach(([key, item]) => {
      workspaceItems = workspaceItems.set(
        key,
        fromJS({ x: item.get("x"), y: item.get("y"), angle: item.get("angle") })
      );

      const item_to_merge = getAllItems.get(key);
      const dX = item.get("x") - item_to_merge.get("x");
      const dY = item.get("y") - item_to_merge.get("y");

      if (item.get("type") === "TXTOVLY" || item.get("type") === "GROUP") {
        item
          .get("groupChildren")
          .entrySeq()
          .forEach(([, itemId]) => {
            const child = groupItems.get(itemId);
            const childToUpdate = {
              x: child.get("x") + dX,
              y: child.get("y") + dY,
              angle: child.get("angle"),
            };
            workspaceChildren = workspaceChildren.set(
              itemId,
              fromJS(childToUpdate)
            );
          });
      }
    });

    const payload = {
      workspaceItems,
      workspaceChildren,
      currentContainer,
    };
    store.dispatch(moveUpdateComplete({ data: payload }, true));
    sendSocketMessage("moveUpdate", { data: payload });
  }

  if (action.type === ActionTypes.RESIZE_UPDATE) {
    let { currentContainer } = action.payload;
    if (currentContainer === undefined) currentContainer = "workspaceItems";

    const getAllItems = state.projectDetails.get(currentContainer);

    let workspaceItems = fromJS({});
    let workspaceChildren = fromJS({});

    action.payload.items.entrySeq().forEach(([key, item]) => {
      workspaceItems = workspaceItems.set(
        key,
        fromJS({
          x: item.get("x"),
          y: item.get("y"),
          width: item.get("width"),
          height: item.get("height"),
        })
      );

      if (action.payload.isOneDirectionalScale) {
        workspaceItems = workspaceItems
          .setIn([key, "isCropped"], item.get("isCropped"))
          .setIn([key, "original"], item.get("original"));
      }

      let item_to_merge = getAllItems.get(key);

      if (item.get("type") === "TEXT" || item.get("type") === "SHAPE") {
        const isFixedWidth = item_to_merge.getIn([
          "textData",
          "formats",
          "others",
          "isFixedWidth",
        ]);
        if (!isFixedWidth)
          item_to_merge = item_to_merge.setIn(
            ["textData", "formats", "others", "isFixedWidth"],
            true
          );

        item_to_merge = item_to_merge.setIn(
          ["textData", "formats", "containerStyle", "fontSize"],
          item.getIn(["textData", "formats", "containerStyle", "fontSize"])
        );
        item_to_merge = item_to_merge.setIn(
          ["textData", "splittedText"],
          item.getIn(["textData", "splittedText"])
        );

        workspaceItems = workspaceItems.setIn(
          [key, "textData"],
          item_to_merge.get("textData")
        );
      }

      if (item.get("type") === "SHAPE") {
        workspaceItems = workspaceItems.setIn(
          [key, "groupBounds"],
          item.get("groupBounds")
        );
        if (item.get("pathData") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "pathData"],
            item.get("pathData")
          );
        if (item.get("path") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "path"],
            item.get("path")
          );
      }

      if (item.get("type") === "TEXT" && item.get("subType") === "BANNER") {
        if (item.get("pathData") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "pathData"],
            item.get("pathData")
          );
        if (item.get("path") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "path"],
            item.get("path")
          );
        if (item.get("xRatio") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "xRatio"],
            item.get("xRatio")
          );
        if (item.get("yRatio") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "yRatio"],
            item.get("yRatio")
          );
        if (item.get("widthRatio") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "widthRatio"],
            item.get("widthRatio")
          );
        if (item.get("heightRatio") !== undefined)
          workspaceItems = workspaceItems.setIn(
            [key, "heightRatio"],
            item.get("heightRatio")
          );
      }

      if (item.get("type") === "TXTOVLY" || item.get("type") === "GROUP") {
        const groupItems = state.projectDetails.get("workspaceChildren");

        item
          .get("groupChildren")
          .entrySeq()
          .forEach(([, itemId]) => {
            const child = action.payload.children.get(itemId);
            let childItem = groupItems.get(itemId);

            if (child.get("type") === "TEXT") {
              const isFixedWidth = childItem.getIn([
                "textData",
                "formats",
                "others",
                "fixedWidth",
              ]);
              if (!isFixedWidth)
                childItem = childItem.setIn(
                  ["textData", "formats", "others", "fixedWidth"],
                  true
                );

              childItem = childItem.setIn(
                ["textData", "formats", "containerStyle", "fontSize"],
                child.getIn([
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ])
              );
            }

            const childToUpdate = {
              x: child.get("x"),
              y: child.get("y"),
              width: child.get("width"),
              height: child.get("height"),
            };
            if (child.get("path") !== undefined)
              childToUpdate.path = child.get("path");

            workspaceChildren = workspaceChildren.set(
              itemId,
              fromJS(childToUpdate)
            );

            if (
              child.get("type") === "OVLYSVG" &&
              item.get("isExpands") &&
              child.get("groupBounds")
            ) {
              workspaceChildren = workspaceChildren.setIn(
                [itemId, "groupBounds"],
                child.get("groupBounds")
              );
            }

            if (child.get("type") === "TEXT" || child.get("type") === "SHAPE") {
              const isFixedWidth = childItem.getIn([
                "textData",
                "formats",
                "others",
                "fixedWidth",
              ]);
              if (!isFixedWidth)
                childItem = childItem.setIn(
                  ["textData", "formats", "others", "fixedWidth"],
                  true
                );

              childItem = childItem.setIn(
                ["textData", "formats", "containerStyle", "fontSize"],
                child.getIn([
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ])
              );
            }

            if (child.get("type") === "GRID") {
              workspaceChildren = workspaceChildren.setIn(
                [itemId, "gridData"],
                child.get("gridData")
              );
            }

            workspaceChildren = workspaceChildren.setIn(
              [itemId, "textData"],
              childItem.get("textData")
            );
          });
      }

      if (item.get("type") === "GRID") {
        workspaceItems = workspaceItems.setIn(
          [key, "gridData"],
          item.get("gridData")
        );
      }
    });

    const payload = {
      workspaceItems,
      workspaceChildren,
      currentContainer,
    };
    store.dispatch(moveUpdateComplete({ data: payload }, true));
    sendSocketMessage("moveUpdate", { data: payload });
  }

  if (action.type === ActionTypes.BULK_OBJECT_UPDATE) {
    store.dispatch(bulkObjectUpdateComplete({ data: action.payload }, true));
    sendSocketMessage("bulkObjectUpdate", { data: action.payload });
  }

  if (action.type === ActionTypes.UNDO_MOVE) {
    store.dispatch(moveUpdateComplete({ data: action.payload }, true));
    sendSocketMessage("moveUpdate", { data: action.payload });
  }

  if (action.type === ActionTypes.UPDATE_OBJECT) {
    const payload = { ...action.payload };
    payload.canEmit = true;
    store.dispatch(updateObjectComplete({ data: payload }, true));
    sendSocketMessage("updateObject", { data: payload });
  }

  if (action.type === ActionTypes.UPDATE_OBJECT_BY_ID) {
    const payload = {
      ...action.payload,
      selectedItem: action.payload.id,
      canEmit: true,
    };
    delete payload.id;
    store.dispatch(updateObjectComplete({ data: payload }, true));
    sendSocketMessage("updateObject", { data: payload });
  }

  if (action.type === ActionTypes.UPDATE_ITEM || action.type === ActionTypes.CROP_IMAGE) {
    if (!action.payload.selectedItem) {
      // for bg removal and stickerify
      action.payload.selectedItem = state.app.getIn(["selectedItems", 0]);
    }
    const payload = { ...action.payload };
    payload.canEmit = true;
    store.dispatch(updateObjectComplete({ data: payload }, true));
    sendSocketMessage("updateObject", { data: payload });
  }

  if (
    action.type === ActionTypes.UPDATE_TEXT_EFFECTS ||
    action.type === ActionTypes.UNDO_TEXT_EFFECTS
  ) {
    const formats = action.payload.textData;
    let textData = state.projectDetails.getIn([
      action.payload.container,
      action.payload.id,
      "textData",
    ]);
    textData = textData.set("effects", fromJS(formats.effects));
    textData = textData.set("formats", fromJS(formats.formats));

    const payload = {
      selectedItem: action.payload.id,
      container: action.payload.container,
      toUpdate: { textData: textData.toJS() },
      canEmit: true,
    };

    if (typeof action.payload.canEmit === "boolean") {
      payload.canEmit = action.payload.canEmit;
    }

    store.dispatch(updateObjectComplete({ data: payload }, true));
    sendSocketMessage("updateObject", { data: payload });
  }

  if (action.type === ActionTypes.UPDATE_TEXT) {
    if (!state.app.get("isPlayAll")) {
      const { property, value } = action.data;
      let formats = fromJS({ containerStyle: {}, bullet: {}, others: {} });

      if (action.data.isPartial === undefined || !action.data.isPartial) {
        if (property === "bullet") {
          formats = formats.setIn(["bullet", "type"], value);

          if (formats.getIn(["containerStyle", "textAlign"]) !== "left")
            formats = formats.setIn(["containerStyle", "textAlign"], "left");
        } else if (property === "bulletSpace") {
          formats = formats.setIn(
            ["bullet", "bulletSpace"],
            parseInt(value, 10)
          );
        } else if (property === "textDirection") {
          formats = formats.setIn(["others", "isRTL"], value);
        } else {
          if (property === "fontSize") {
            if (value === "autopx") {
              formats = formats.setIn(["others", "isAutoFontSize"], true);
            } else {
              formats = formats.setIn(["others", "isAutoFontSize"], false);
            }
            formats = formats.setIn(["others", "sizeFixed"], true);
          }
          if (property === "fontFamily") {
            formats = formats.setIn(["containerStyle", "fontWeight"], null);
            formats = formats.setIn(["containerStyle", "fontStyle"], null);
          }
          formats = formats.setIn(["containerStyle", property], value);
        }

        if (property === "fontFamily" && action.data.textData !== undefined) {
          if (action.data.lineHeight !== undefined)
            formats = formats.setIn(
              ["containerStyle", "lineHeight"],
              action.data.lineHeight
            );
          formats = formats.set(
            "others",
            action.data.textData.getIn(["formats", "others"])
          );
        }
      } else if (
        property === "bullet" ||
        property === "fontSize" ||
        property === "fontFamily"
      ) {
        if (property === "bullet") {
          formats = formats.setIn(["bullet", "type"], value);
          if (formats.getIn(["containerStyle", "textAlign"]) !== "left")
            formats = formats.setIn(["containerStyle", "textAlign"], "left");
        } else if (property === "fontSize") {
          if (value === "autopx")
            formats = formats.setIn(["others", "isAutoFontSize"], true);
          else formats = formats.setIn(["others", "isAutoFontSize"], false);
          formats = formats.setIn(["others", "sizeFixed"], true);
        }
      }

      let textData = state.projectDetails.getIn([
        action.data.container,
        action.data.id,
        "textData",
      ]);

      textData = textData.set(
        "formats",
        textData.get("formats").mergeDeep(formats)
      );

      if (
        action.data.textData !== undefined &&
        action.data.textData.get("isfontSizeChanged") === true
      ) {
        textData = textData.setIn(
          ["formats", "containerStyle", "fontSize"],
          action.data.textData.getIn(["formats", "containerStyle", "fontSize"])
        );
      }

      if (
        action.data.textData !== undefined &&
        action.data.textData.getIn(["formats", "others", "isFixedWidth"]) ===
        true
      ) {
        textData = textData.setIn(
          ["formats", "others", "isFixedWidth"],
          action.data.textData.getIn(["formats", "others", "isFixedWidth"])
        );
      }

      if (action.data.htmlText !== undefined)
        textData = textData.set("htmlText", action.data.htmlText);
      let toUpdate = { textData: textData.toJS() };

      if (action.data.width !== undefined) toUpdate.width = action.data.width;
      if (action.data.height !== undefined)
        toUpdate.height = action.data.height;
      if (action.data.yRatio !== undefined)
        toUpdate.yRatio = action.data.yRatio;
      if (action.data.heightRatio !== undefined)
        toUpdate.heightRatio = action.data.heightRatio;
      if (action.data.x !== undefined) toUpdate.x = action.data.x;
      if (action.data.toUpdate !== undefined) {
        toUpdate = action.data.toUpdate;
      }

      const payload = {
        selectedItem: action.data.id,
        container: action.data.container,
        toUpdate,
        canEmit: true,
      };

      store.dispatch(updateObjectComplete({ data: payload }, true));
      sendSocketMessage("updateObject", { data: payload });
    }
  }

  if (action.type === ActionTypes.UPDATE_GROUP_TEXT) {
    if (!state.app.get("isPlayAll")) {
      const { groupId } = action.payload;
      const group = state.projectDetails.getIn(["workspaceItems", groupId]);

      const textChildId = action.payload.currentId;
      let textChild = state.projectDetails.getIn([
        "workspaceChildren",
        textChildId,
      ]);
      if (!action.payload.isGrouped) {
        textChild = state.projectDetails.getIn(["workspaceItems", textChildId]);
      }

      const prevTextChild = textChild;
      const prevTextChildBounds = getItemBounds(textChild);

      let workspaceItemsToUpdate = {};
      let workspaceChildrenToUpdate = {};

      // update text context
      if (action.payload.textData !== undefined) {
        textChild = textChild.set("textData", action.payload.textData);
      }

      // update width and height of text
      textChild = textChild.set(
        "width",
        textChild.get("width") + action.payload.dW
      );
      textChild = textChild.set(
        "height",
        textChild.get("height") + action.payload.dH
      );

      // get top-left corner of the text
      const rotatedPrevTextChildPostion = getRotatedPoint(
        prevTextChild.get("x") + prevTextChild.get("width") / 2,
        prevTextChild.get("y") + prevTextChild.get("height") / 2,
        prevTextChild.get("x"),
        prevTextChild.get("y"),
        prevTextChild.get("angle")
      );

      // find how much we need to move the previous top-left point...
      // based on the vertical alignment by finding rotated difference vector
      let textChildMoveBy = { x: 0, y: 0 };
      if (action.payload.verticalAlign === "middle") {
        textChildMoveBy.y = -action.payload.dH / 2;
      } else if (action.payload.verticalAlign === "bottom") {
        textChildMoveBy.y = -action.payload.dH;
      }
      textChildMoveBy = getRotatedPoint(
        0,
        0,
        textChildMoveBy.x,
        textChildMoveBy.y,
        prevTextChild.get("angle")
      );

      // find new rotated top-left point of the text
      let textChildPosition = {
        x: rotatedPrevTextChildPostion.x + textChildMoveBy.x,
        y: rotatedPrevTextChildPostion.y + textChildMoveBy.y,
      };

      // find new center of the text to unrotate the new rotated top-left point
      let textCenter = {
        x: textChild.get("width") / 2,
        y: textChild.get("height") / 2,
      };
      textCenter = getRotatedPoint(
        0,
        0,
        textCenter.x,
        textCenter.y,
        textChild.get("angle")
      );
      textCenter.x += textChildPosition.x;
      textCenter.y += textChildPosition.y;

      // find new unrotated top-left point of the text
      textChildPosition = getRotatedPoint(
        textCenter.x,
        textCenter.y,
        textChildPosition.x,
        textChildPosition.y,
        -textChild.get("angle")
      );

      // update new unrotated top-left point of the text
      textChild = textChild
        .set("x", textChildPosition.x)
        .set("y", textChildPosition.y);

      // update all items of group based on the position of the text
      let itemsToUpdate = fromJS({});
      let itemsToTakeBounds = fromJS({});
      let updateContainer = "workspaceChildren";
      let childIds;
      if (action.payload.isGrouped) {
        childIds = group.get("groupChildren").valueSeq();
      } else {
        updateContainer = "workspaceItems";
        childIds = action.payload.selectedItems;
      }
      const textChildBounds = getItemBounds(textChild);
      let moveOtherChildren = true;
      const FLOAT_ADJUST = 1;

      if (
        !(Math.abs(textChild.get("angle") - 0) < FLOAT_ADJUST) &&
        !(Math.abs(textChild.get("angle") - 360) < FLOAT_ADJUST)
      ) {
        moveOtherChildren = false;
      }

      for (const childId of childIds) {
        const child = state.projectDetails.getIn([updateContainer, childId]);
        if (childId === textChildId) {
          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "x"],
            textChild.get("x")
          );
          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "y"],
            textChild.get("y")
          );
          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "width"],
            textChild.get("width")
          );
          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "height"],
            textChild.get("height")
          );
          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "textData"],
            textChild.get("textData")
          );

          let item = itemsToUpdate.get(childId);
          item = item.set("angle", textChild.get("angle"));
          itemsToTakeBounds = itemsToTakeBounds.set(childId, item);
        } else if (child) {
          const moveChildBy = { x: 0, y: 0 };

          if (moveOtherChildren) {
            const childBounds = getItemBounds(child);
            const childCenter = {
              x: child.get("x") + child.get("width") / 2,
              y: child.get("y") + child.get("height") / 2,
            };
            const prevTextChildCenter = {
              x: prevTextChildBounds.x + prevTextChildBounds.width / 2,
              y: prevTextChildBounds.y + prevTextChildBounds.height / 2,
            };

            if (
              childCenter.y > prevTextChildCenter.y && // consider child is below the text
              childBounds.y + childBounds.height >
              prevTextChildBounds.y + prevTextChildBounds.height // bottom y of both child and text is below the text
            ) {
              moveChildBy.y =
                textChildBounds.y +
                textChildBounds.height -
                (prevTextChildBounds.y + prevTextChildBounds.height);
            }
          }

          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "x"],
            child.get("x") + moveChildBy.x
          );
          itemsToUpdate = itemsToUpdate.setIn(
            [childId, "y"],
            child.get("y") + moveChildBy.y
          );

          let item = itemsToUpdate.get(childId);
          item = item.set("width", child.get("width"));
          item = item.set("height", child.get("height"));
          item = item.set("angle", child.get("angle"));
          itemsToTakeBounds = itemsToTakeBounds.set(childId, item);
        }
      }

      if (action.payload.isGrouped) {
        workspaceChildrenToUpdate = itemsToUpdate.toJS();
        const groupBounds = getObjectsBounds(itemsToTakeBounds);
        workspaceItemsToUpdate = {
          [groupId]: {
            x: groupBounds.x,
            y: groupBounds.y,
            width: groupBounds.width,
            height: groupBounds.height,
          },
        };
      } else {
        workspaceItemsToUpdate = itemsToUpdate.toJS();
      }

      const payload = {
        workspaceItems: workspaceItemsToUpdate,
        workspaceChildren: workspaceChildrenToUpdate,
      };

      store.dispatch(updateGroupTextComplete({ data: payload }, true));
      sendSocketMessage("updateGroupText", { data: payload });
    }
  }

  if (action.type === ActionTypes.UPDATE_ALL_TEXTS) {
    const textApplyAllData = state.app.get("textApplyAllData");
    const isFontSizeApplyAll = state.app.getIn(["txtApplyAllOptions", "isFontSizeApplyAll"]);
    const isFontFamilyApplyAll = state.app.getIn(["txtApplyAllOptions", "isFontFamilyApplyAll"]);
    const isTextEffectsApplyAll = state.app.getIn(["txtApplyAllOptions", "isTextEffectsApplyAll"]);
    const applyAllMode =
      isFontFamilyApplyAll && isFontSizeApplyAll
        ? "cumulative"
        : isFontFamilyApplyAll
          ? "fontSpecific"
          : isFontSizeApplyAll
            ? "sizeSpecific"
            : "none";

    if (
      !state.app.get("isPlay") &&
      !state.app.get("isPlayAll") &&
      textApplyAllData.length !== 0
    ) {
      const textUpdateArr = [];
      let dataCount = 0;
      const processedTextIds = {};
      let formats = fromJS({ containerStyle: {}, bullet: {}, others: {} });

      let textData;
      let newTextData = fromJS({});
      let toUpdate = {};
      let textEffectsData;

      Array.from(textApplyAllData).forEach((data) => {
        if (data.property === "fontSize" && !isFontSizeApplyAll) {
          return;
        }

        if (
          (data.property === "fontFamily" ||
            data.property === "textDecoration" ||
            data.property === "textAlign" ||
            data.property === "textTransform" ||
            data.property === "textDirection" ||
            data.property === "letterSpacing" ||
            data.property === "bullet") &&
          !isFontFamilyApplyAll
        ) {
          return;
        }

        if (data.property === "textEffects" && !isTextEffectsApplyAll) {
          return;
        }

        if (data.id === textApplyAllData[0].id) dataCount += 1;
      });

      Array.from(textApplyAllData).forEach((data) => {
        const { property, value } = data;

        if (property === "fontSize" && !isFontSizeApplyAll) {
          return;
        }

        if (
          (property === "fontFamily" ||
            property === "textDecoration" ||
            property === "textAlign" ||
            property === "textTransform" ||
            property === "textDirection" ||
            property === "letterSpacing" ||
            property === "bullet") &&
          !isFontFamilyApplyAll
        ) {
          return;
        }

        if (property === "textEffects" && !isTextEffectsApplyAll) {
          return;
        }

        if (processedTextIds[data.id] === undefined) {
          processedTextIds[data.id] = 1;
          formats = fromJS({ containerStyle: {}, bullet: {}, others: {} });
          toUpdate = {};
        } else {
          processedTextIds[data.id] += 1;
        }

        const firstOccurrence = processedTextIds[data.id] === 1;
        const lastOccurrence = processedTextIds[data.id] === dataCount;

        if (property === "fontSize") {
          if (value === "autopx") {
            formats = formats.setIn(["others", "isAutoFontSize"], true);
          } else {
            formats = formats.setIn(["others", "isAutoFontSize"], false);
          }
          formats = formats.setIn(["others", "sizeFixed"], true);
        }
        if (property === "fontFamily") {
          formats = formats.setIn(["containerStyle", "fontWeight"], null);
          formats = formats.setIn(["containerStyle", "fontStyle"], null);
        }

        if (property !== "textEffects") {
          if (property === "textDirection") {
            formats = formats.setIn(["others", "isRTL"], value);
          } else if (property === "bullet") {
            formats = formats.setIn(["bullet", "type"], value);
          } else {
            formats = formats.setIn(["containerStyle", property], value);
          }
        } else {
          textEffectsData = data.textData.get("effects")
            ? data.textData.get("effects")
            : fromJS({ None: "" });
        }

        if (property === "fontFamily" && data.textData !== undefined) {
          if (data.lineHeight !== undefined)
            formats = formats.setIn(
              ["containerStyle", "lineHeight"],
              data.lineHeight
            );
          formats = formats.set(
            "others",
            data.textData.getIn(["formats", "others"])
          );

          // updating the text color
          formats = formats.setIn(
            ["containerStyle", "color"],
            data.textData.getIn(["formats", "containerStyle", "color"])
          );
        }

        if (
          property === "letterSpacing" &&
          data.container === "workspaceItems"
        ) {
          formats = formats.setIn(["others", "isFixedWidth"], true);
        }

        if (firstOccurrence)
          textData = state.projectDetails.getIn([
            data.container,
            data.id,
            "textData",
          ]);

        if (data.htmlText !== undefined && data.property !== "textEffects") {
          textData = textData.set("htmlText", data.htmlText);
          newTextData = newTextData.set("htmlText", data.htmlText);
        }

        if (property !== "textEffects") {
          toUpdate.width =
            applyAllMode === "cumulative"
              ? data.cWidth
              : applyAllMode === "sizeSpecific"
                ? data.sWidth
                : applyAllMode === "fontSpecific"
                  ? data.fWidth
                  : undefined;

          toUpdate.height =
            applyAllMode === "cumulative"
              ? data.cHeight
              : applyAllMode === "sizeSpecific"
                ? data.sHeight
                : applyAllMode === "fontSpecific"
                  ? data.fHeight
                  : undefined;
        }

        if (lastOccurrence) {
          if (applyAllMode !== "none") {
            textData = textData.set(
              "formats",
              textData.get("formats").mergeDeep(formats)
            );
            newTextData = newTextData.set("formats", textData.get("formats"));
          }

          if (isTextEffectsApplyAll) {
            textData = textData.set("effects", textEffectsData);
            newTextData = newTextData.set("effects", textEffectsData);
          }

          toUpdate.textData = newTextData.toJS();

          const textUpdateData = {
            id: data.id,
            container: "workspaceItems",
            data: toUpdate,
          };

          textUpdateArr.push(textUpdateData);
        }
      });

      dispatch(applyFlipComplete({ data: textUpdateArr }, true));
      sendSocketMessage("applyFlip", { data: textUpdateArr });
    }
  }

  if (action.type === ActionTypes.SYNC_TEXT) {
    const payload = {
      ...action.payload,
      canEmit: true,
    };

    store.dispatch(updateObjectComplete({ data: payload }, true));
    sendSocketMessage("updateObject", { data: payload });
  }

  if (action.type === ActionTypes.UPDATE_INRO_OUTRO_TEXT) {
    const payload = {
      ...action.payload,
      canEmit: true,
    };
    sendSocketMessage("updateObject", { data: payload });
  }

  if (action.type === ActionTypes.WRITE_TEXT) {
    const canEmit = !action.payload.isTyping || action.payload.undo;
    const payload = { ...action.payload, canEmit };
    store.dispatch(updateObjectComplete({ data: payload }, true));
    sendSocketMessage("updateObject", { data: payload });
  }

  if (action.type === ActionTypes.APPLY_FRAME_CLIP_PLOT) {
    store.dispatch(applyFrameClipPlotComplete({ data: action.payload }, true));
    sendSocketMessage("applyFrameClipPlot", { data: action.payload });
  }

  if (action.type === ActionTypes.CHANGE_AUDIO_VOLUME) {
    if (!action.payload.fadeDetails) {
      const audioItem = state.projectDetails.getIn([
        "audios",
        action.payload.id,
      ]);
      const volumeResult = getUpdatedVolume({
        audioItem,
        newVolume: action.payload.volume,
      });
      // update fadeDetails in action itself for redo
      action.payload.fadeDetails = volumeResult.newFadeDetails;
    }

    const payload = [
      {
        container: "audios",
        id: action.payload.id,
        data: {
          volume: action.payload.volume,
          fadeDetails: action.payload.fadeDetails,
        },
      },
    ];

    dispatch(applyFlipComplete({ data: payload }, true));
    sendSocketMessage("applyFlip", { data: payload });
  }

  if (action.type === ActionTypes.SET_PLAY_ALL) {
    if (action.payload.isPlayAll) {
      let prefetchCount = state.projectDetails.get("audios").size + 1; // all audios + player audio context;

      ["workspaceItems", "workspaceBG"].forEach((container) => {
        const containerData = state.projectDetails.get(container);
        for (const item of containerData.valueSeq()) {
          const type = item.get("type");
          const subType = item.get("subType");

          let itemPrefetch = 0;

          if (
            isImageOnly(type, subType) ||
            isVideoOnly(type, subType) ||
            type === "PROP" ||
            type === "SCR"
          ) {
            itemPrefetch = 1;
          } else if (type === "FRAME") {
            for (const clipDetail of item.get("clipDetails").valueSeq()) {
              const imgDetails = clipDetail.get("imgDetails");
              const requiresClipPreload = !(
                imgDetails.get("color") !== undefined &&
                imgDetails.get("color") !== "" &&
                imgDetails.get("src") === ""
              );
              if (requiresClipPreload) {
                itemPrefetch += 1;
              }
            }
          }

          prefetchCount += itemPrefetch;
        }
      });

      action.payload.prefetchCount = prefetchCount; // update here to use it in appReducer
    }
  }

  if (action.type === ActionTypes.CHANGE_TEXT) {
    const updaterObject = {
      [action.payload.container]: {
        [action.payload.active]: {
          isNew: true, // used to replace text item
          isChangeText: true, // used only for complete handler in projectReducer to skip selection handling
          data: action.payload.text.toJS(),
        },
      },
    };

    dispatch(updateTimelineTimeComplete({ data: updaterObject }, true));
    sendSocketMessage("updateTimeline", { data: updaterObject });
  }

  if (action.type === ActionTypes.APPLY_FLIP || action.type === ActionTypes.UNDO_FLIP) {
    dispatch(applyFlipComplete({ data: action.payload }, true));
    sendSocketMessage("applyFlip", { data: action.payload });
  }

  if (action.type === ActionTypes.APPLY_FLIP_INITIAL) {
    sendSocketMessage("applyFlip", { data: action.payload });
  }

  if (action.type === ActionTypes.APPLY_COLOR || action.type === ActionTypes.UNDO_COLOR) {
    dispatch(applyColorComplete({ data: action.payload }, true));
    sendSocketMessage("applyColor", { data: action.payload });
  }

  if (action.type === ActionTypes.UPDATE_PROJECT_DATA) {
    dispatch(updateProjectDataComplete({ toUpdate: action.payload }, true));
    sendSocketMessage(socketChannels.UPDATE_PROJECT_DATA, { toUpdate: action.payload });
  }

  const isInsertSubtitleAction =
    action.type === ActionTypes.INSERT_SUBTITLE_DATA && !action.switchSubtitle;
  if (
    isInsertSubtitleAction ||
    action.type === ActionTypes.RECONCILE_SUBTITLE_DATA
  ) {
    let updaterObject = action.autosaveResult;
    if (!updaterObject) {
      updaterObject = getSubtitleUpdater({
        subtitleDropMap: action.payload.subtitleData.data,
        subtitleId: action.payload.subtitleId,
      });
      action.autosaveResult = updaterObject;
      dispatch(insertSubtitleDataComplete(action.payload));
      if (action.type === ActionTypes.INSERT_SUBTITLE_DATA) {
        sendSocketMessage("triggerSubtitleReconcile", {
          data: { subtitleId: action.payload.subtitleId },
        });
      }
    } else {
      dispatch(updateTimelineTimeComplete({ data: updaterObject }, true));
      if (action.type === ActionTypes.INSERT_SUBTITLE_DATA) {
        sendSocketMessage("updateTimeline", { data: updaterObject });
      }
    }
  }

  if (action.type === ActionTypes.UPDATE_SUBTITLE_DATA) {
    let updaterObject = action.autosaveResult;
    if (!updaterObject) {
      updaterObject = getSubtitleUpdater({
        subtitleDropMap: action.payload.subtitleData.data,
        subtitleId: action.payload.subtitleId,
      });
      updaterObject = updaterObject.setIn(["project", "data", "subtitle_data"], fromJS(action.payload.subtitleList));
      action.autosaveResult = updaterObject;
      dispatch(updateSubtitleDataComplete(action.payload));
      sendSocketMessage("triggerSubtitleReconcile", {
        data: { subtitleId: action.payload.subtitleId },
      });
    } else {
      dispatch(updateTimelineTimeComplete({ data: updaterObject }, true));
      sendSocketMessage("updateTimeline", { data: updaterObject });
    }
  }

  const isSubtitleSwitchAction =
    action.type === ActionTypes.INSERT_SUBTITLE_DATA && action.switchSubtitle;
  if (action.type === ActionTypes.SWITCH_SUBTITLE || isSubtitleSwitchAction) {
    const { subtitleId } = action.payload;
    let subtitleList = state.projectDetails.get("subtitleData");
    subtitleList = subtitleList.map((entry) => {
      return entry.set("isActive", entry.get("subtitleId") === subtitleId);
    });
    dispatch(updateSubtitleListComplete({ data: subtitleList }), true);
    sendSocketMessage("updateSubtitleList", { data: subtitleList });
  }

  if (action.type === ActionTypes.UPDATE_SUBTITLE_LIST) {
    const { toUpdate } = action.payload;
    let subtitleList = state.projectDetails.get("subtitleData");
    for (const updater of toUpdate) {
      const { id, data } = updater;
      if (updater.isNew) {
        subtitleList = subtitleList.push(fromJS(data));
      } else if (updater.isDelete) {
        subtitleList = subtitleList.filter(
          (entry) => entry.get("subtitleId") !== id
        );
      } else {
        const index = subtitleList.findIndex(
          (entry) => entry.get("subtitleId") === id
        );
        if (index >= 0) {
          const entry = subtitleList.get(index).merge(fromJS(data));
          subtitleList = subtitleList.set(index, entry);
        }
      }
    }
    dispatch(updateSubtitleListComplete({ data: subtitleList }), true);
    sendSocketMessage("updateSubtitleList", { data: subtitleList });
  }

  if (action.type === ActionTypes.UNDO_UPDATE_SUBTITLE_LIST) {
    dispatch(updateSubtitleListComplete({ data: action.payload.subtitleList }), true);
    sendSocketMessage("updateSubtitleList", { data: action.payload.subtitleList });
  }

  if (action.type === ActionTypes.UPDATE_SUBTITLE_LIST_COMPLETE) {
    const prevDefaultSubtitle = state.projectDetails.get("defaultSubtitle");
    let activeSubtitleId = "";
    if (isImmutable(action.payload.data)) {
      const activeSubtitle = action.payload.data.find((entry) => entry.get("isActive"));
      if (activeSubtitle) {
        activeSubtitleId = activeSubtitle.get("subtitleId");
      }
    } else {
      const activeSubtitle = action.payload.data.find((entry) => entry.isActive);
      if (activeSubtitle) {
        activeSubtitleId = activeSubtitle.subtitleId;
      }
    }
    if (activeSubtitleId && prevDefaultSubtitle !== activeSubtitleId) {
      action.shouldReconcile = true;
      action.subtitleId = activeSubtitleId;
    }
  }

  next(action);
};

export default autosaveMiddleware;
