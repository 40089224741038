import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { font } from "../constants/font";
import Backdrop from "./BackDrop";

const DotWrapSection = styled.div`
  position: relative;
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;
  font-family: "VM-Poppins", sans-serif;
  width: max-content;
  outline: 0;
  box-sizing: border-box;
  .active {
    &::after {
      background-color: rgb(245, 246, 247);
    }
  }
`;
const DotWrap = styled.a`
  position: relative;
  top: -4px;
  cursor: pointer;
  padding-right: 20px;
  &:hover {
    &::after {
      background-color: rgb(245, 246, 247);
    }
  }
  &::after {
    content: "···";
    border-radius: 5px;
    padding: 8px;
    margin-top: 2px;
    padding: 0 8px;
    cursor: pointer;
  }
  &::before {
    content: "";
    position: absolute;
    right: 8px;
    z-index: 11;
    display: block;
    width: 5px;
    height: 5px;
    top: 8px;
    border-bottom: 1px solid #8796c1;
    border-right: 1px solid #8796c1;
    transform: rotate(-45deg);
  }
`;
const UL = styled.ul`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #0000000d;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin: 0;
  list-style: none;
  transform: translate(-50px, 0px);
  width: auto;
  padding: 0px;
  position: absolute;
  z-index: 9;
  &.transForm {
    transform: perspective(1000px) rotateX(0deg);
  }
`;
const LI = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 2px 16px 2px 3px;
  &:hover {
    background: #e6e6e6;
  }
  label {
    color: #46445d;
    display: inline-block;
    width: 100%;
    padding: 8px 13px;
    text-align: left;
    cursor: pointer;
  }
`;

const BreadCrumbTooltip = (props) => {
    const { options, onAction } = props;
    const [option, setOption] = useState(false);

    const handleSelectedValue = (value) => {
        setOption(!option);
        onAction(value);
    };

    return options.length ? (
        <DotWrapSection>
            <DotWrap
                className={option ? "active" : ""}
                onClick={() => setOption(!option)}
                {...props}
                styles={props.selectEleStyle}
            />
            {option && (
                <div className="options">
                    <UL>
                        <Backdrop
                            height="100vh"
                            width="100vw"
                            top="-14rem"
                            left="-24rem"
                            zIndex="-1"
                            onClick={() => setOption(false)}
                        />
                        {options.map((option) => (
                            <LI key={`${option.name}`}>
                                <Typography
                                    font={font.normalMini}
                                    onClick={() => handleSelectedValue(option)}
                                    content={option.name}
                                    cursor="pointer"
                                />
                            </LI>
                        ))}
                    </UL>
                </div>
            )}
        </DotWrapSection>
    ) : null;
};
/**
 * Default Props Value
 */
BreadCrumbTooltip.defaultProps = {
    title: "",
    options: [],
    backgroundColor: "#886DE5",
    iconColor: "#FFFFFF",
    fontColor: "#FFFFFF",
    lableColor: "#46445D",
    minWidth: "25ch",
    selectEleStyle: {},
    selectedLabelProps: {},
    menuWidth: "100%",
    enableHover: true,
};
/**
 * Initialize Prop Types
 */
BreadCrumbTooltip.propTypes = {
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ Value: PropTypes.string })),
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string,
    fontColor: PropTypes.string,
    lableColor: PropTypes.string,
    minWidth: PropTypes.string,
    selectEleStyle: PropTypes.object,
    selectedLabelProps: PropTypes.object,
    menuWidth: PropTypes.string,
    enableHover: PropTypes.bool,
    onAction: PropTypes.func,
};
export default BreadCrumbTooltip;