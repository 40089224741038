import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { STATIC_PATH } from "../../../../constants/config";
import Typography from "../../../../common-components/Typography";
import vmTheme from "../../../../constants/theme";
import { font } from "../../../../constants/font";
import content from "../../../../constants/content";
import Action from "../../../../common-components/Action";

const ModalWrapper = styled.div`
  padding: 20px;
`;

const Footer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  label {
    /* padding: 12px; */
  }
  .cancel-btn {
    &:hover {
      background-color: ${(props) => props.theme.subtitleBtnBorder};
      label {
        color: ${(props) => props.theme.panelPrimaryColor};
      }
    }
  }
  .confirm-btn {
    &:hover {
      background-color: ${(props) => props.theme.polarColor};
      border: 1px solid ${(props) => props.theme.secondaryBorderColor};

      label {
        color: ${(props) => props.theme.secondaryBorderColor};
      }
    }
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin: 8px auto;
  display: block;
`;

const ConfirmModal = ({
  image,
  subText,
  title,
  handleCancel,
  handleConfirm,
  isLoading
}) => {
  const theme = useSelector((state) => state.app.get("theme"));
  return (
    <ModalWrapper>
      <Image src={`${STATIC_PATH}${image}`} alt="vm-icon" />
      <Typography
        content={title}
        color={vmTheme[theme].panelPrimaryColor}
        font={font.boldSmall}
        display="block"
        enableTrim={false}
        align="center"
        padding="8px 0px 0px"
      />
      <Typography
        content={subText}
        color={vmTheme[theme].sbSecondaryColor}
        font={font.normalBase_19}
        display="block"
        enableTrim={false}
        align="center"
        padding="8px 20px"
      />
      <Footer>
        <Action
          text={content.CANCEL}
          width="138px"
          height="40px"
          fontColor={vmTheme[theme].panelPrimaryColor}
          borderWidth="1px"
          borderColor={vmTheme[theme].subtitleBtnBorder}
          background={vmTheme[theme].polarColor}
          className="cancel-btn"
          borderRadius="8px"
          onClick={handleCancel}
        />
        <Action
          text={content.YES}
          width="138px"
          height="40px"
          fontColor={vmTheme[theme].polarColor}
          borderWidth="1px"
          borderColor={vmTheme[theme].secondaryBorderColor}
          background={vmTheme[theme].secondaryBorderColor}
          className="confirm-btn"
          borderRadius="8px"
          onClick={handleConfirm}
          isLoading={isLoading}
        />
      </Footer>
    </ModalWrapper>
  );
};

ConfirmModal.propTypes = {
  image: PropTypes.string,
  subText: PropTypes.string,
  title: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  isLoading: PropTypes.bool
};
export default ConfirmModal;
