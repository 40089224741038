/* eslint-disable camelcase */

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import InnerFrameImage from "./frame-innerframeimage";
import {
  FRAME_CLASSES,
  calculateAspectRatio,
  getFrameDropAreaId,
  prepareClipFromWorkspace,
  prepareSingleClipFrame,
  removeImgFromSingleClipFrame,
} from "./frame-helper";
import { DroppableContainer } from "./frame-components";
import { isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import { getFlipPosition } from "../workspace/workspace-helper";
import { FRAME, ITEM_CONFIG } from "../../constants/config";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import { selectFrameClip, setPropertyWindow, updateSwapHoverDrop, updateTransformStatus } from "../../redux/actions/appUtils";
import { updateTimelineTime } from "../../redux/actions/timelineUtils";

function DroppableAreasComponent(props) {
  const {
    // from parent
    Id,
    groupId,
    isGrouped,
    clip_id,
    frameItemData,
    droppableItemData,
    isFrameVisible,
    playhead,
  } = props;
  const {
    // from store
    libraryDragItem,
    transformStatus,
    selectedItem,
    selectedItemId,
    swapHoverDrop,
    selectedFrameClip,
    propertyWindow,
    isFraming,
    isPlay,
    isPlayAll,
    isSwapping,
  } = props;
  const {
    // dispatch
    updateSwapHoverDrop,
    updateTimelineTime,
    selectFrameClip,
    setPropertyWindow,
    updateTransformStatus,
  } = props;

  const lastMouseUp = useRef(0);
  const canAllowClick = useRef(false);
  const imageRef = useRef();
  const imageContainerRef = useRef();
  const droppableAreaRef = useRef();

  const [showImage, setShowImage] = useState(true);
  const [imageHovered, setImageHovered] = useState(false);
  const [hoveredImageStyles, setHoveredImageStyles] = useState({});
  const [hoverImage, setHoverImage] = useState();

  useEffect(() => {
    if (transformStatus.get("transforming")) {
      canAllowClick.current = false;
    }
  }, [transformStatus]);

  const isItemMoving = transformStatus.get("moving");
  const isWorkspaceImageDragging =
    isItemMoving &&
    selectedItem &&
    (isImageOnly(selectedItem.get("type"), selectedItem.get("subType")) ||
      isVideoOnly(selectedItem.get("type"), selectedItem.get("subType")));

  const libraryImageDrag = libraryDragItem.get("data");
  const isLibraryItemDragging = Boolean(libraryDragItem.get("data"));
  const isLibImageDragging = (
    isLibraryItemDragging
    && libraryImageDrag
    && (
      isImageOnly(libraryImageDrag.get("type"), libraryImageDrag.get("subType"))
      || isVideoOnly(libraryImageDrag.get("type"), libraryImageDrag.get("subType"))
    )
  );

  useEffect(() => {
    if (!isLibImageDragging && !isWorkspaceImageDragging && imageHovered) {
      setImageHovered(false);
      setHoverImage();
      setHoveredImageStyles({});
      updateSwapHoverDrop({ reset: true });
    }
  }, [
    isLibImageDragging,
    imageHovered,
    isWorkspaceImageDragging,
    updateSwapHoverDrop,
  ]);

  useEffect(() => {
    if (
      droppableItemData.getIn(["imgDetails", "color"]) !== undefined &&
      droppableItemData.getIn(["imgDetails", "color"]) !== "" &&
      droppableItemData.getIn(["imgDetails", "src"]) === ""
    ) {
      setShowImage(false);
      // props.onLoadData(currIndex);
    } else {
      // color picker undo
      setShowImage(true);
    }
  }, [droppableItemData]);

  function getStyles() {
    const flipPosition = droppableItemData.getIn([
      "imgDetails",
      "flipPosition",
    ]);

    let styles;

    if (imageHovered) {
      const { width, height, X, Y } = hoveredImageStyles;
      styles = {
        position: "relative",
        width: `${frameItemData.get("defaultWidth") * width}px`,
        height: `${frameItemData.get("defaultHeight") * height}px`,
        transform: `translate(${frameItemData.get("defaultWidth") * X}px, ${
          frameItemData.get("defaultHeight") * Y
        }px)`,
      };
    } else {
      const imageWidth =
        frameItemData.get("defaultWidth") *
        droppableItemData.getIn(["imgDetails", "original", "width"]);
      const imageHeight =
        frameItemData.get("defaultHeight") *
        droppableItemData.getIn(["imgDetails", "original", "height"]);

      const imageX =
        frameItemData.get("defaultWidth") *
        droppableItemData.getIn(["imgDetails", "original", "x"]);
      const imageY =
        frameItemData.get("defaultHeight") *
        droppableItemData.getIn(["imgDetails", "original", "y"]);

      styles = {
        position: "relative",
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        transform: `translate(${imageX}px, ${imageY}px) ${getFlipPosition(
          flipPosition
        )}`,
      };
    }

    return styles;
  }

  function getSource() {
    let imageDetails = droppableItemData.getIn(["imgDetails"]);

    if (imageHovered) {
      imageDetails = hoverImage;
    }

    const { src, thumb, fallbackSrc } = getWorkspaceItemSource({
      item: {
        bgremoval: imageDetails.get("bgremoval"),
        isBlob: imageDetails.get("isBlob"),
        src: imageDetails.get("src"),
        stickerify: imageDetails.get("stickerify"),
        subType: imageDetails.get("subType"),
        thumb: imageDetails.get("thumb"),
        thumbnail: imageDetails.get("thumbnail"),
        type: imageDetails.get("type"),
      },
    });
    let srcToReturn = src;
    let fallbackSrcToReturn = fallbackSrc;

    if (
      !imageDetails.get("src") &&
      !imageDetails.get("stickerify") &&
      !imageDetails.get("bgremoval")
    ) {
      srcToReturn = FRAME.DEFAULT_IMAGE;
      fallbackSrcToReturn = undefined;
    }

    if (imageHovered) {
      srcToReturn = thumb;
      fallbackSrcToReturn = undefined;
    }

    return {
      src: srcToReturn,
      thumb,
      fallbackSrc: fallbackSrcToReturn,
    };
  }

  const loadHoverData = useCallback(
    ({ data, fromLib = true } = {}) => {
      if (
        !isImageOnly(data.get("type"), data.get("subType")) &&
        !isVideoOnly(data.get("type"), data.get("subType"))
      ) {
        return;
      }

      let hoverImgData = null;
      if (fromLib) {
        const { thumb: thumbSrc } = getWorkspaceItemSource({
          item: {
            src: data.get("src"),
            subType: data.get("subType"),
            thumb: data.get("thumb"),
            thumbnail: data.get("thumbnail"),
            type: data.get("type"),
          },
        });

        hoverImgData = fromJS({
          width: data.get("width"),
          height: data.get("height"),
          src: thumbSrc,
          thumb: thumbSrc,
        });
      } else {
        hoverImgData = data;
      }

      if (hoverImgData !== null) {
        setHoverImage(hoverImgData);
        setHoveredImageStyles(
          calculateAspectRatio(frameItemData, hoverImgData, clip_id)
        );
      }
    },
    [clip_id, frameItemData]
  );

  const handleMouseEnter = useCallback(() => {
    if (!isLibImageDragging && !isWorkspaceImageDragging) {
      return;
    }

    if (
      isLibImageDragging &&
      libraryImageDrag.get("subType") === "UPIMAGE" &&
      libraryImageDrag.get("sourceType") === "svg" &&
      libraryImageDrag.get("colors")
    ) {
      // svg will be rendered using CustomSVGRender
      return;
    }

    if (
      isWorkspaceImageDragging && // svg will be rendered using CustomSVGRender
      selectedItem.get("subType") === "UPIMAGE" &&
      selectedItem.get("sourceType") === "svg" &&
      selectedItem.get("colors")
    ) {
      return;
    }

    if (isWorkspaceImageDragging && selectedItem.get("isSingleClipFrame")) {
      // this item is considered as frame
      return;
    }

    let sourceType = ITEM_CONFIG.SWAP_SOURCE.WORKSPACE;
    if (isLibImageDragging) {
      sourceType = ITEM_CONFIG.SWAP_SOURCE.LIBRARY;
    }

    if (isGrouped) {
      updateSwapHoverDrop({
        id: groupId,
        childId: Id,
        frameClipId: clip_id,
        hovering: true,
        targetType: ITEM_CONFIG.SWAP_TARGETS.FRAME_CLIP,
        sourceType,
      });
    } else {
      updateSwapHoverDrop({
        id: Id,
        frameClipId: clip_id,
        hovering: true,
        targetType: ITEM_CONFIG.SWAP_TARGETS.FRAME_CLIP,
        sourceType,
      });
    }

    if (isLibImageDragging) {
      loadHoverData({ data: libraryImageDrag, fromLib: true });
      setImageHovered(true);
    } else if (isWorkspaceImageDragging) {
      loadHoverData({ data: selectedItem, fromLib: false });
      setImageHovered(true);
    }
  }, [
    groupId,
    Id,
    clip_id,
    isGrouped,
    selectedItem,
    isLibImageDragging,
    isWorkspaceImageDragging,
    libraryImageDrag,
    updateSwapHoverDrop,
    loadHoverData,
  ]);

  const handleMouseLeave = useCallback(() => {
    if (isGrouped) {
      const hoveredWithItem =
        swapHoverDrop.get("id") === groupId &&
        swapHoverDrop.get("childId") === Id &&
        swapHoverDrop.get("frameClipId") === clip_id;
      if (hoveredWithItem) {
        updateSwapHoverDrop({
          id: groupId,
          childId: Id,
          frameClipId: clip_id,
          hovering: false,
        });
      }
    } else {
      const hoveredWithItem =
        swapHoverDrop.get("id") === Id &&
        swapHoverDrop.get("frameClipId") === clip_id;
      if (hoveredWithItem) {
        updateSwapHoverDrop({
          id: Id,
          frameClipId: clip_id,
          hovering: false,
        });
      }
    }

    if (imageHovered) {
      setImageHovered(false);
      setHoverImage();
      setHoveredImageStyles({});
    }
  }, [
    groupId,
    Id,
    clip_id,
    isGrouped,
    imageHovered,
    swapHoverDrop,
    updateSwapHoverDrop,
  ]);

  const handleWorkspaceImageDrop = useCallback(() => {
    if (
      !(
        swapHoverDrop.get("hovering") &&
        swapHoverDrop.get("targetType") ===
          ITEM_CONFIG.SWAP_TARGETS.FRAME_CLIP &&
        swapHoverDrop.get("sourceType") === ITEM_CONFIG.SWAP_SOURCE.WORKSPACE
      )
    ) {
      return false;
    }

    const dropContainer = isGrouped ? "workspaceChildren" : "workspaceItems";
    const updater = [
      {
        id: selectedItemId,
        container: "workspaceItems",
        isDelete: true,
      },
    ];

    if (frameItemData.get("clipDetails").size === 1) {
      updater.push({
        id: Id,
        container: dropContainer,
        newItemData: prepareSingleClipFrame(removeImgFromSingleClipFrame(frameItemData), selectedItem).toJS(),
        isAdd: true,
        isWorkspaceReplace: true,
      });
    } else {
      const dropImageDetails = prepareClipFromWorkspace({
        Item: selectedItem,
        ItemId: selectedItemId,
        Frame: frameItemData,
        clipId: clip_id,
      });
      updater.push({
        id: Id,
        container: dropContainer,
        toUpdate: {
          frameClipImage: {
            clipId: swapHoverDrop.get("frameClipId"),
            imgDetails: dropImageDetails,
          },
        },
      });
    }

    updateTimelineTime({ toUpdate: updater });
    updateSwapHoverDrop({ reset: true });
    updateTransformStatus({ transforming: false, moving: false, isFrameClipDrop: true });

    if (imageHovered) {
      setImageHovered(false);
      setHoverImage();
      setHoveredImageStyles({});
    }

    return true;
  }, [
    isGrouped,
    Id,
    clip_id,
    selectedItemId,
    selectedItem,
    frameItemData,
    swapHoverDrop,
    updateTimelineTime,
    imageHovered,
    updateSwapHoverDrop,
    updateTransformStatus,
  ]);

  const handleMouseDown = () => {
    canAllowClick.current = true;
  };

  const handleMouseUp = useCallback(
    (e) => {
      const workspaceImageDropped = handleWorkspaceImageDrop();
      if (
        (!isGrouped && selectedItemId !== Id && !workspaceImageDropped) ||
        (isGrouped && selectedItemId !== groupId && !workspaceImageDropped) || // item other than a droppable is being dragged
        workspaceImageDropped || // no need to select clip when image dropped from workspace
        !canAllowClick.current
      ) {
        canAllowClick.current = false;
        return;
      }
      canAllowClick.current = false;

      if (e.timeStamp - lastMouseUp.current < 250) {
        // double click
        if (
          !isFraming &&
          !frameItemData.get("locked") &&
          !isGrouped &&
          droppableItemData.getIn(["imgDetails", "src"])
        ) {
          selectFrameClip({
            id: Id,
            groupId: isGrouped ? groupId : "",
            clipId: clip_id,
            isFraming: true,
          });
        }

        lastMouseUp.current = 0;
      } else {
        if (
          selectedFrameClip.get("id") === Id &&
          (!isGrouped ||
            (isGrouped && groupId === selectedFrameClip.get("groupId"))) &&
          clip_id === selectedFrameClip.get("clipId")
        ) {
          // clip unselect
          if (
            propertyWindow.get("isOpened") &&
            propertyWindow.get("component") === "imageSetting"
          ) {
            // close prop window
            setPropertyWindow({ component: "", type: "", isOpened: false });
          }

          selectFrameClip({
            clipId: "",
          });
        } else {
          // clip select
          if (
            !isImageOnly(
              droppableItemData.getIn(["imgDetails", "type"]),
              droppableItemData.getIn(["imgDetails", "subType"])
            ) &&
            !isVideoOnly(
              droppableItemData.getIn(["imgDetails", "type"]),
              droppableItemData.getIn(["imgDetails", "subType"])
            ) &&
            propertyWindow.get("isOpened") &&
            propertyWindow.get("component") === "imageSetting"
          ) {
            // close prop window
            setPropertyWindow({ component: "", type: "", isOpened: false });
          }

          selectFrameClip({
            id: Id,
            groupId: isGrouped ? groupId : "",
            clipId: clip_id,
          });
        }
        lastMouseUp.current = e.timeStamp;
      }
    },
    [
      groupId,
      Id,
      clip_id,
      isGrouped,
      selectedItemId,
      frameItemData,
      droppableItemData,
      selectedFrameClip,
      isFraming,
      propertyWindow,
      setPropertyWindow,
      selectFrameClip,
      handleWorkspaceImageDrop,
    ]
  );

  useEffect(() => {
    const droppableAreaEl = droppableAreaRef.current;
    if (droppableAreaEl && transformStatus.get("transforming")) {
      droppableAreaEl.addEventListener("touch-swap-enter", handleMouseEnter);
      droppableAreaEl.addEventListener("touch-swap-leave", handleMouseLeave);
      droppableAreaEl.addEventListener("touch-swap-up", handleMouseUp);
    }

    return () => {
      if (droppableAreaEl) {
        droppableAreaEl.removeEventListener(
          "touch-swap-enter",
          handleMouseEnter
        );
        droppableAreaEl.removeEventListener(
          "touch-swap-leave",
          handleMouseLeave
        );
        droppableAreaEl.removeEventListener("touch-swap-up", handleMouseUp);
      }
    };
  }, [transformStatus, handleMouseEnter, handleMouseLeave, handleMouseUp]);

  let dropAreaPointerEvents = "auto";
  if (
    frameItemData.get("isLocked") || // frame item locked
    (!isLibImageDragging && isLibraryItemDragging) || // library item other than image is being dragged (this check is required if swap by dnd is enabled for all items)
    isPlay ||
    isPlayAll ||
    !isFrameVisible ||
    isSwapping
  ) {
    dropAreaPointerEvents = "none";
  }

  const isCurrentClipFraming =
    isFraming &&
    selectedItemId === Id &&
    clip_id === selectedFrameClip.get("clipId");

  const { src, thumb, fallbackSrc } = getSource();

  return (
    <DroppableContainer
      ref={droppableAreaRef}
      style={{
        clipPath: `path('${droppableItemData.get("clipData")}')`,
        backgroundColor: droppableItemData.getIn(["imgDetails", "color"]),
        pointerEvents: dropAreaPointerEvents,
        touchAction: "none",
      }}
      id={getFrameDropAreaId(Id, clip_id)}
      className={FRAME_CLASSES.DROP_AREA}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onPointerDown={handleMouseDown}
      onPointerUp={handleMouseUp}
      data-tswap-tkn={getFrameDropAreaId(Id, clip_id)}
    >
      <InnerFrameImage
        imageContainerRef={imageContainerRef}
        containerStyles={getStyles()}
        Id={Id}
        clip_id={clip_id}
        droppableItemData={droppableItemData}
        src={src}
        thumb={thumb}
        fallbackSrc={fallbackSrc}
        imageRef={imageRef}
        showImage={showImage || imageHovered}
        isFraming={isCurrentClipFraming}
        isImageHovered={imageHovered}
        playhead={playhead}
        frameEnterStart={frameItemData.get("enterStart")}
        frameExitEnd={frameItemData.get("exitEnd")}
      />
    </DroppableContainer>
  );
}

DroppableAreasComponent.propTypes = {
  selectedItemId: PropTypes.string,
  selectedItem: PropTypes.object,
  isFraming: PropTypes.bool,
  transformStatus: PropTypes.object,
  libraryDragItem: PropTypes.object,
  swapHoverDrop: PropTypes.object,
  selectedFrameClip: PropTypes.object,
  propertyWindow: PropTypes.object,
  isPlay: PropTypes.bool,
  isPlayAll: PropTypes.bool,
  isSwapping: PropTypes.bool,
  selectFrameClip: PropTypes.func,
  updateTimelineTime: PropTypes.func,
  updateSwapHoverDrop: PropTypes.func,
  setPropertyWindow: PropTypes.func,
  updateTransformStatus: PropTypes.func,
  Id: PropTypes.string,
  groupId: PropTypes.string,
  isGrouped: PropTypes.bool,
  clip_id: PropTypes.string,
  frameItemData: PropTypes.object,
  droppableItemData: PropTypes.object,
  isFrameVisible: PropTypes.bool,
  playhead: PropTypes.number,
};

const mapStateToProps = (state) => ({
  selectedItemId: state.app.getIn(["selectedItems", 0]),
  selectedItem:
    state.app.get("selectedItems").size === 1
      ? state.projectDetails.getIn([
          "workspaceItems",
          state.app.getIn(["selectedItems", 0]),
        ])
      : undefined,
  isFraming: state.app.get("isFraming"),
  transformStatus: state.app.get("transformStatus"),
  libraryDragItem: state.app.get("libraryDragItem"),
  swapHoverDrop: state.app.get("swapHoverDrop"),
  selectedFrameClip: state.app.get("selectedFrameClip"),
  propertyWindow: state.app.get("propertyWindow"),
  isPlay: false,
  isPlayAll: state.app.get("isPlayAll"),
  isSwapping: Boolean(state.app.get("swapDetails")),
});

const mapDispatchToProps = (dispatch) => ({
  selectFrameClip: (data) => dispatch(selectFrameClip(data)),
  updateTimelineTime: (payload) => dispatch(updateTimelineTime(payload)),
  updateSwapHoverDrop: (data) => dispatch(updateSwapHoverDrop(data)),
  setPropertyWindow: (data) => dispatch(setPropertyWindow(data)),
  updateTransformStatus: (data) => dispatch(updateTransformStatus(data)),
});

const DroppableAreas = connect(
  mapStateToProps,
  mapDispatchToProps
)(DroppableAreasComponent);

export default DroppableAreas;
