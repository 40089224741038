import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { SliderType, StepperType } from "../timeline-proptypes";
import Typography from "../../../common-components/Typography";
import { STATIC_PATH } from "../../../constants/config";
import { getThumbStripSource, getAudioSource } from "../../../helper/URLHelper";
import { isImageOnly, isTextOnly, isVideoOnly, pxToStep2 } from "../timeline-helper";
import { RULER_OPTIONS, SLIDER_TYPES } from "../timeline-constants";
import { WaveformManagerContext } from "../timeline-waveformmanager";
import vmTheme from "../../../constants/theme";
import { font } from "../../../constants/font";
import { TimelineScrollContext } from "../contexts/timeline-scroll-context";
import { BgContainer, TextContainer, ThumbstripStyled, ThumbWrapper1, ThumbWrapper2 } from "../slider/timeline-slider-components";

class WaveFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waveformPath: "",
    };

    this.unmount = false;

    this.getPathId = this.getPathId.bind(this);
    this.setWaveFormPath = this.setWaveFormPath.bind(this);
    this.handleWaveForm = this.handleWaveForm.bind(this);
  }

  componentDidMount() {
    const pathId = this.getPathId(this.props);
    if (pathId) {
      this.waveformTimer = setTimeout(() => {
        this.handleWaveForm({
          slider: this.props.slider,
          sliderData: this.props.sliderData,
          sliderHeight: this.props.sliderHeight,
          pathId,
        });
      }, 100);
    }
  }

  componentDidUpdate(prevProps) {
    const { sliderData, sliderHeight, slider } = this.props;

    const pathId = this.getPathId(this.props);
    const prevPathId = this.getPathId(prevProps);

    if (prevPathId !== pathId && pathId) {
      clearTimeout(this.waveformTimer);
      this.waveformTimer = setTimeout(() => {
        this.handleWaveForm({
          slider,
          sliderData,
          sliderHeight,
          pathId,
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.waveformTimer);
    this.unmount = true;
  }

  // eslint-disable-next-line react/sort-comp
  getPathId(props) {
    const { sliderData, stepSizePx, sliderHeight, slider, utilityWorker } = props;
    let pathId = "";

    if (utilityWorker) {
      const { src } = getAudioSource({
        item: {
          type: sliderData.get("type"),
          subType: sliderData.get("subType"),
          src: sliderData.get("src"),
        },
      });
      pathId = `${src}${stepSizePx}${sliderHeight}${slider.sliderType}`;
    }

    return pathId;
  }

  /**
   * @param {object} params
   * @param {Slider} params.slider
   * @param {number} params.sliderHeight
   * @param {string | undefined} params.pathId
   * @param {object} params.pcmItem
   * @param {boolean | undefined} params.throttlePathUpdate
   */
  async setWaveFormPath(params = {}) {
    const { pathId, pcmItem, slider, sliderHeight } = params;

    try {
      if (!this.props.requestWaveformPath) {
        return;
      }

      const pathResult = await this.props.requestWaveformPath({
        pathId,
        pcmItem,
        slider,
        sliderHeight,
      });

      if (this.unmount) {
        return;
      }

      const recentPathId = this.getPathId(this.props); // check recent props before setting
      if (pathResult.pathId === recentPathId) {
        this.setState({ waveformPath: pathResult.path });
      }
    } catch (error) { }
  }

  /**
   * @param {object} params
   * @param {object} params.sliderData
   * @param {Slider} params.slider
   * @param {number} params.sliderHeight
   * @param {string | undefined} params.pathId
   * @param {boolean | undefined} params.throttlePathUpdate
   */
  handleWaveForm(params = {}) {
    const { sliderData, slider, sliderHeight, pathId, throttlePathUpdate } =
      params;

    const { src } = getAudioSource({
      item: {
        type: sliderData.get("type"),
        subType: sliderData.get("subType"),
        src: sliderData.get("src"),
      },
    });
    if (this.props.getPCMData) {
      this.props
        .getPCMData(src)
        .then((pcmItem) => {
          this.setWaveFormPath({
            pathId,
            pcmItem,
            slider,
            sliderHeight,
            throttlePathUpdate,
          });
        })
        .catch(() => { });
    }
  }

  render() {
    const { isDragSlider, isThumbDrag, audioWaveColor, slider, sliderHeight } =
      this.props;
    const { waveformPath } = this.state;

    const { enterStart, exitEnd } = slider;
    let enterStartPosition = enterStart.position;
    let exitEndPosition = exitEnd.position;
    const { mediaMin } = enterStart;

    if (isThumbDrag && isDragSlider) {
      if (enterStart.dragPosition || enterStart.trackPosition) {
        enterStartPosition =
          enterStart.dragPosition || enterStart.trackPosition;
      }
      if (exitEnd.dragPosition || exitEnd.trackPosition) {
        exitEndPosition = exitEnd.dragPosition || exitEnd.trackPosition;
      }
    }

    const svgPlot = {
      x: enterStartPosition.x - mediaMin.x,
      y: 0,
      width: exitEndPosition.x - enterStartPosition.x,
      height: sliderHeight,
    };

    const svgViewBox = `${svgPlot.x} ${svgPlot.y} ${svgPlot.width} ${svgPlot.height}`;

    return (
      <svg
        viewBox={svgViewBox}
        style={{
          width: `${svgPlot.width}px`,
          height: `${svgPlot.height}px`,
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        {waveformPath && <path d={waveformPath} fill={audioWaveColor} />}
      </svg>
    );
  }
}
WaveFormComponent.propTypes = {
  utilityWorker: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  isDragSlider: PropTypes.bool,
  isThumbDrag: PropTypes.bool,
  audioWaveColor: PropTypes.string,
  slider: SliderType,
  sliderHeight: PropTypes.number,
  sliderData: PropTypes.object,
  stepSizePx: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  getPCMData: PropTypes.func,
  requestWaveformPath: PropTypes.func,
};

const WaveForm = (props) => {
  const utilityWorker = useSelector((state) =>
    state.app.getIn(["workers", "UTILITY_1"])
  );
  const managerCallbacks = useContext(WaveformManagerContext);

  return (
    <WaveFormComponent
      {...props}
      getPCMData={managerCallbacks.getPCMData}
      requestWaveformPath={managerCallbacks.requestWaveformPath}
      utilityWorker={utilityWorker}
    />
  );
};

const ThumbstripComponent = (props) => {
  const {
    userDetails,
    item,
    timelinePlotWidth,
    slider,
    sliderHeight,
    stepSizePx,
    isDragSlider,
  } = props;
  const { enterStart, exitEnd, itemType, itemSubType } = slider;

  const timelineScroll = useContext(TimelineScrollContext);
  const timelineScrollX = timelineScroll.x;

  let enterStartStep = enterStart.position.step;
  let exitEndStep = exitEnd.position.step;
  let mediaMinStep = enterStart.mediaMin
    ? enterStart.mediaMin.step
    : enterStartStep;
  let mediaMaxStep = exitEnd.mediaMax ? exitEnd.mediaMax.step : exitEndStep;
  let enterStartDragStepDiff = 0;
  let exitEndDragStepDiff = 0;

  let mediaDurationSteps = exitEndStep - enterStartStep;
  if (
    isVideoOnly(itemType, itemSubType) &&
    itemType !== "PEXELS" &&
    itemType !== "PIXABAY" && !item.get("isAvatar")
  ) {
    mediaDurationSteps = mediaMaxStep - mediaMinStep;
  } else {
    // pexels and pixabay videos will not have thumbstrip
    mediaMinStep = enterStartStep;
    mediaMaxStep = exitEndStep;
  }

  if (isDragSlider) {
    const enterStartDragPosition =
      enterStart.dragPosition || enterStart.trackPosition;
    const exitEndDragPosition = exitEnd.dragPosition || exitEnd.trackPosition;
    if (enterStartDragPosition) {
      enterStartDragStepDiff = enterStartDragPosition.step - enterStartStep;
      enterStartStep = enterStartDragPosition.step;
    }
    if (exitEndDragPosition) {
      exitEndDragStepDiff = exitEndDragPosition.step - exitEndStep;
      exitEndStep = exitEndDragPosition.step;
    }
    if (enterStartDragPosition && exitEndDragPosition) {
      // slider drag
      mediaMinStep += enterStartDragStepDiff;
      mediaMaxStep += exitEndDragStepDiff;
    }
  }

  const result = useMemo(() => {
    const result = {
      thumbstripSrc: "",
      maxThumbCount: null,
      THUMBSTRIP_WIDTH: 90, // width of thumb to show on screen (thumbstrip's actual width will vary)
      THUMBSTRIP_HEIGHT: 50, // height of thumb in thumbstrip
    };
    if (item) {
      result.thumbstripSrc = getThumbStripSource({
        userId: userDetails.userId,
        assetId: item.get("assetId"),
        type: item.get("type"),
        subType: item.get("subType"),
        src: item.get("src"),
        thumb: item.get("thumb"),
        thumbstrip: item.get("thumbstrip"),
      });
      const thumb = item.get("thumb");
      if (
        (item.get("type") !== "STOCKVID" || thumb?.startsWith("https://")) &&
        item.get("type") !== "UPVIDEO" || !item.get("thumbstrip")
      ) {
        result.maxThumbCount = 1;
        result.THUMBSTRIP_HEIGHT = sliderHeight;
      }
      let width = item.get("width");
      let height = item.get("height");
      if (Number.isFinite(item.get("pwidth"))) {
        width = item.get("pwidth");
        height = item.get("pheight");
      }
      result.THUMBSTRIP_WIDTH = Math.floor(
        result.THUMBSTRIP_HEIGHT * (width / height)
      );
      result.speed = Number.isFinite(item.get("speed")) ? item.get("speed") : 1;
    }
    return result;
  }, [userDetails, item, sliderHeight]);
  const { speed, thumbstripSrc, maxThumbCount, THUMBSTRIP_WIDTH, THUMBSTRIP_HEIGHT } =
    result;

  const thumbStyle = useMemo(() => {
    const thumbStyle = {
      backgroundPosition: "",
      backgroundImage: "",
      backgroundSize: "",
    };

    const addThumb = (x, y) => {
      x = Math.round(x * 10) / 10;
      y = Math.round(y * 10) / 10;
      if (!thumbStyle.backgroundImage) {
        thumbStyle.backgroundImage = `url(${thumbstripSrc})`;
      } else {
        thumbStyle.backgroundImage += `,url(${thumbstripSrc})`;
      }
      if (!thumbStyle.backgroundPosition) {
        thumbStyle.backgroundPosition = `${x}px ${y}px`;
      } else {
        thumbStyle.backgroundPosition += `,${x}px ${y}px`;
      }
    };

    const duration = mediaDurationSteps * RULER_OPTIONS.interval; // media total duration
    let sliderMaxWidth = mediaDurationSteps * stepSizePx; // maximum width slider(sliderbg) can take for duration

    // NOTE: update thumb count logic if logic is changed in animaker api repo
    let thumbCount = 150; // number of thumbs in thumbstrip
    if (duration < 300) {
      thumbCount = Math.ceil(duration);
      if (thumbCount < 1) {
        thumbCount = 1;
      }
    }

    const enterStart = enterStartStep;
    const exitEnd = exitEndStep;

    const timelineScrollStep = pxToStep2({
      stepSizePx,
      x: timelineScrollX - RULER_OPTIONS.paddingLeft,
    });
    const timelineScrollStep2 =
      timelineScrollStep + pxToStep2({ stepSizePx, x: timelinePlotWidth });

    let startPx = (enterStart - mediaMinStep) * stepSizePx;
    let endPx = sliderMaxWidth - (mediaMaxStep - exitEnd) * stepSizePx;

    let minPx = startPx + (timelineScrollStep - enterStart) * stepSizePx;
    let maxPx = startPx + (timelineScrollStep2 - enterStart) * stepSizePx;

    if (maxThumbCount !== null) {
      thumbCount = maxThumbCount;
      thumbStyle.backgroundSize = "contain";
      thumbStyle.minWidth = `${THUMBSTRIP_WIDTH}px`;
      if (enterStartDragStepDiff > 0) {
        // left thumb is being dragged
        thumbStyle.right = 0;
      } else {
        thumbStyle.left = 0;
      }

      if (enterStartDragStepDiff < 0) {
        const startDiff =
          Math.ceil((-enterStartDragStepDiff * stepSizePx) / THUMBSTRIP_WIDTH) *
          THUMBSTRIP_WIDTH;
        startPx += startDiff;
        endPx += startDiff;
        minPx += startDiff;
        maxPx += startDiff;
        sliderMaxWidth += startDiff;
      }
      if (exitEndDragStepDiff > 0) {
        const endDiff =
          Math.ceil((exitEndDragStepDiff * stepSizePx) / THUMBSTRIP_WIDTH) *
          THUMBSTRIP_WIDTH;
        endPx += endDiff;
        maxPx += endDiff;
        sliderMaxWidth += endDiff;
      }
    }

    const totalIdealThumbs = Math.max(
      1,
      Math.ceil(sliderMaxWidth / THUMBSTRIP_WIDTH)
    ); // total thumbs required to fill slider
    if (totalIdealThumbs <= thumbCount) {
      const thumbRenderStartIdx = Math.max(
        0,
        Math.floor(totalIdealThumbs * (minPx / sliderMaxWidth)) - 1,
        Math.floor(totalIdealThumbs * (startPx / sliderMaxWidth)) - 1
      );
      const thumbRenderEndIdx = Math.min(
        Math.ceil(totalIdealThumbs * (endPx / sliderMaxWidth)) + 1,
        Math.ceil(totalIdealThumbs * (maxPx / sliderMaxWidth)) + 1,
        totalIdealThumbs - 1
      );

      for (
        let thumbRenderIdx = thumbRenderStartIdx;
        thumbRenderIdx <= thumbRenderEndIdx;
        thumbRenderIdx += 1
      ) {
        const x = THUMBSTRIP_WIDTH * thumbRenderIdx;
        const thumbStep = pxToStep2({ stepSizePx, x });
        const thumbTime = thumbStep * RULER_OPTIONS.interval;
        const thumbIdx = Math.floor(thumbCount * ((thumbTime * speed) / duration));
        const y = THUMBSTRIP_HEIGHT * thumbIdx * -1; // move thumbstrip towards top to reveal one by one
        addThumb(x - startPx, y);
      }
    } else {
      const excessThumbCount = Math.max(0, totalIdealThumbs - thumbCount); // how much thumbs we need
      const excessCountPerThumb = Math.floor(excessThumbCount / thumbCount); // distribute excess to all available thumbs
      const missedExcessCount = Math.max(
        0,
        excessThumbCount - excessCountPerThumb * thumbCount
      ); // correct for excess floor
      const bleedingWidth =
        Math.max(0, sliderMaxWidth - totalIdealThumbs * THUMBSTRIP_WIDTH) +
        missedExcessCount * THUMBSTRIP_WIDTH; // how much bg color of slider will bleed through thumbs at the end

      const totalThumbsRender = thumbCount + excessCountPerThumb * thumbCount; // total thumbs to render on slider

      const bleedingWidthPerThumb = bleedingWidth / totalThumbsRender; // equally distribute bleeding gap to all thumbs
      const thumbWidth = THUMBSTRIP_WIDTH + bleedingWidthPerThumb;

      const thumbRenderStartIdx = Math.max(
        0,
        Math.floor(totalThumbsRender * (minPx / sliderMaxWidth)) - 1,
        Math.floor(totalThumbsRender * (startPx / sliderMaxWidth)) - 1
      );
      const thumbRenderEndIdx = Math.min(
        Math.ceil(totalThumbsRender * (endPx / sliderMaxWidth)) + 1,
        Math.ceil(totalThumbsRender * (maxPx / sliderMaxWidth)) + 1,
        totalThumbsRender - 1
      );

      // chrome and firefox for some reason brings overlapping duplicate thumbs to the top even when they are added before next thumb
      // so add overlapping thumbs when all thumbs are added
      const fillLater = [];

      for (
        let thumbRenderIdx = thumbRenderStartIdx;
        thumbRenderIdx <= thumbRenderEndIdx;
        thumbRenderIdx += 1
      ) {
        const thumbIdx = Math.floor(thumbRenderIdx / (excessCountPerThumb + 1));
        const x = thumbWidth * thumbRenderIdx - startPx;
        const y = THUMBSTRIP_HEIGHT * thumbIdx * -1; // move thumbstrip towards top to reveal one by one
        addThumb(x, y);

        const repeat = Math.ceil(bleedingWidthPerThumb / THUMBSTRIP_WIDTH);
        for (let r = 1; r <= repeat; r += 1) {
          fillLater.push([x + THUMBSTRIP_WIDTH * r, y]);
        }
      }

      fillLater.forEach((fillParams) => addThumb(...fillParams));
    }

    return thumbStyle;
  }, [
    THUMBSTRIP_WIDTH,
    THUMBSTRIP_HEIGHT,
    mediaDurationSteps,
    mediaMinStep,
    enterStartStep,
    exitEndStep,
    mediaMaxStep,
    stepSizePx,
    thumbstripSrc,
    timelineScrollX,
    timelinePlotWidth,
    maxThumbCount,
    enterStartDragStepDiff,
    exitEndDragStepDiff,
    speed,
  ]);

  return <ThumbstripStyled style={thumbStyle} />;
};
ThumbstripComponent.propTypes = {
  isDragSlider: PropTypes.bool,
  slider: SliderType,
  sliderHeight: PropTypes.number,
  item: PropTypes.object,
  timelinePlotWidth: PropTypes.number,
  stepSizePx: PropTypes.number,
  userDetails: PropTypes.object,
};

const SliderBG = ({
  slider,
  sliderPlot,
  sliderData,
  trackType,
  stepper,
  isMute,
  isDragSlider,
  isThumbDrag,
  audioWaveColor,
  textColor,
  timelinePlotWidth,
}) => {
  const [text, setText] = useState("");
  const userDetails = useSelector((store) => store.userDetails);
  const data = useSelector((store) =>
    store.projectDetails.getIn(["workspaceItems", slider.id])
  );
  const { sliderType } = slider;
  const theme = useSelector((state) => state.app.get("theme"));

  useEffect(() => {
    if (sliderData) {
      if (sliderType === SLIDER_TYPES.SUBTITLE) {
        setText(sliderData.get("text"));
      }
      if (isTextOnly(sliderData.get("type"))) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = data?.getIn(["textData", "htmlText"]);
        setText(tempDiv.textContent || tempDiv.innerText || "");
      }
      if (sliderData.get("type") === "SHAPE") {
        let selectedShapeName = sliderData.getIn(["pathData", "type"]);
        selectedShapeName = selectedShapeName.replaceAll("_", " ");
        selectedShapeName =
          selectedShapeName.charAt(0).toUpperCase() +
          selectedShapeName.substr(1);
        setText(selectedShapeName);
      }
      if (sliderData.get("type") === "PROP") {
        setText("Properties");
      }
    }
  }, [data, sliderData, sliderType]);

  if (sliderType === SLIDER_TYPES.MINI_SUBTITLE) {
    return (
      <BgContainer>
        <TextContainer className={`slider-bg--tc-${sliderType}`}>
          <img
            src={`${STATIC_PATH}${vmTheme[theme].icons.subtitleIconSlider}`}
            alt="Subtitles"
            width="12px"
            height="12px"
          />
          <Typography
            textAlign="left"
            font={font.normalMicro}
            color={textColor}
            innerContent="Subtitles"
            enableTrim
          />
        </TextContainer>
      </BgContainer>
    );
  }

  if (sliderData) {
    if (sliderType === SLIDER_TYPES.SUBTITLE) {
      return (
        <BgContainer>
          <TextContainer>
            <Typography
              textAlign="left"
              font={font.normalMini_12}
              color={vmTheme[theme].polarColor}
              innerContent={text}
              enableTrim
            />
          </TextContainer>
        </BgContainer>
      );
    }
    if (
      isTextOnly(sliderData.get("type")) ||
      sliderData.get("type") === "SHAPE" ||
      sliderData.get("type") === "PROP"
    ) {
      const isShape =
        sliderData.get("type") === "SHAPE" || sliderData.get("type") === "PROP";
      return (
        <BgContainer>
          <TextContainer isShape={isShape}>
            <img
              src={`${STATIC_PATH}${isShape ? vmTheme[theme].icons.shapeIcon : vmTheme[theme].icons.textTimelineIcon}`}
              alt="T"
              width="20px"
              height="20px"
            />
            <Typography
              textAlign="left"
              font={font.normalMini_12}
              color={
                isShape
                  ? vmTheme[theme].shapeColor
                  : vmTheme[theme].sliderTextContainerColor
              }
              innerContent={text}
              enableTrim
            />
          </TextContainer>
        </BgContainer>
      );
    }
    if (
      isVideoOnly(sliderData.get("type"), sliderData.get("subType")) &&
      sliderData.get("type") !== "PEXELS" &&
      sliderData.get("type") !== "PIXABAY" &&
      !(
        sliderData.get("type") === "STOCKVID" &&
        sliderData.get("thumb")?.startsWith("https://")
      ) &&
      sliderType !== SLIDER_TYPES.AUDIO
    ) {
      // If video and is not form PEXELS and PIXABAY.
      return (
        <BgContainer>
          {trackType === "video" && <ThumbWrapper1 />}
          <ThumbstripComponent
            timelinePlotWidth={timelinePlotWidth}
            slider={slider}
            sliderHeight={sliderPlot.height}
            stepSizePx={stepper.stepSizePx}
            userDetails={userDetails}
            item={sliderData}
            isDragSlider={isDragSlider}
          />
          {trackType === "video" && <ThumbWrapper2 />}
        </BgContainer>
      );
    }
    if (
      isImageOnly(sliderData.get("type"), sliderData.get("subType")) ||
      sliderData.get("type") === "PEXELS" ||
      sliderData.get("type") === "PIXABAY" ||
      (sliderData.get("type") === "STOCKVID" && sliderData.get("thumb")?.startsWith("https://"))
    ) {
      // If image and videos form PEXELS and PIXABAY.
      return (
        <BgContainer>
          <ThumbstripComponent
            timelinePlotWidth={timelinePlotWidth}
            slider={slider}
            sliderHeight={sliderPlot.height}
            stepSizePx={stepper.stepSizePx}
            userDetails={userDetails}
            item={sliderData}
            isDragSlider={isDragSlider}
          />
        </BgContainer>
      );
    }

    if (sliderType === SLIDER_TYPES.AUDIO && !isMute) {
      return (
        <WaveForm
          isDragSlider={isDragSlider}
          isThumbDrag={isThumbDrag}
          audioWaveColor={audioWaveColor}
          slider={slider}
          sliderHeight={sliderPlot.height}
          sliderData={sliderData}
          stepSizePx={stepper.stepSizePx}
        />
      );
    }
  }
  return null;
};

SliderBG.propTypes = {
  slider: SliderType.isRequired,
  sliderPlot: PropTypes.object,
  sliderData: PropTypes.object,
  trackType: PropTypes.string,
  stepper: StepperType,
  isMute: PropTypes.bool,
  isDragSlider: PropTypes.bool,
  isThumbDrag: PropTypes.bool,
  audioWaveColor: PropTypes.string,
  textColor: PropTypes.string,
  timelinePlotWidth: PropTypes.number,
};

export default SliderBG;
