import React from 'react';
import ReactDOM from "react-dom";
import { useSelector } from 'react-redux';
import { PORTAL_ID } from '../../../constants';
import { font } from '../../../constants/font';
import Typography from '../../../common-components/Typography';
import vmTheme from '../../../constants/theme';
import { TooltipWrapper } from '../timeline-components';

const OFFSET = {
    HEIGHT: 20,
    WIDTH: 10
};

const VolumeTooltip = ({ options }) => {
    const theme = useSelector((state) => state.app.get('theme'));
    const { x, y, amp } = options;

    return ReactDOM.createPortal(
        <TooltipWrapper position={{ x: x + OFFSET.WIDTH, y: y - OFFSET.HEIGHT }}>
            <Typography
                innerContent={`${parseFloat((amp * 100).toFixed(0))}`}
                font={font.normalMicroLarge}
                color={vmTheme[theme].polarColor}
                display="block"
                align="center"
                padding="2px"
            />
        </TooltipWrapper>, document.getElementById(PORTAL_ID)
    )
}

export default VolumeTooltip;