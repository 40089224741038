/* eslint-disable no-restricted-syntax */
import { fromJS } from "immutable";
import ActionTypes from "../../constants/action-types";
import AppConfig from "../../constants/config";
import content from "../../constants/content";
import triggerSimpleAjax from "../../helper/httpHelper";
import { projectMigrationHelper } from "../../helper/jsonMigrationHelper";
import proceedWait from "../../helper/proceedWait";
import uuid from "../../helper/uuid";
import { addNotification } from "./notificationUtils";
import { addCordsToSubtitle, updateAutoSubtitle } from "./subtitleUtils";
import { removeInvalidProjectKeys } from "../../helper/projectValidation";
import { getTextBounds } from "../../containers/text/TextHelper";
import { getSubtitlesFontToLoad, loadSubtitleFontFamily } from "../../helper/fontLoadHelper";
import { updateIntroOutroText, applyFlipInitial } from "./appUtils";
// import { getRandomInt } from "../../helper/formateDate";
import openUrl from "../../helper/openUrl";

const { API_URL, API } = AppConfig;

export const saveProject = (projectId, details) => {
  return new Promise((res, rej) => {

    triggerSimpleAjax(
      `${API_URL}${API.SAVE_PROJECT_REST}`,
      "POST",
      false,
      {
        projectId,
        data: removeInvalidProjectKeys(details),
      },
      (args) => {
        res(args);
      },
      (err) => {
        rej(err);
      }
    );
  });
};

export async function getSubtitleData(params) {
  const { subtitleId, projectId } = params;
  return new Promise((resolve, reject) => {
    const body = { subtitleId, projectId };
    const onSuccess = async (res) => {
      if (res.status === "error") {
        return reject(new Error(content.FAILED_TO_LOAD_SUBTITLE_DATA))
      }
      return resolve(res);
    };
    const onError = () =>
      reject(new Error(content.FAILED_TO_LOAD_SUBTITLE_DATA));
    triggerSimpleAjax(
      `${API_URL}${API.SUBTITLE.GET}`,
      "POST",
      false,
      body,
      onSuccess,
      onError
    );
  });
}

export function setProjectDetails(id, userResponse, isApp = false, isPublish = false) {
  const apiUrl = `${API_URL}${isApp ? API.GET_PROJECT : isPublish ? API.GET_RENDER_PROJECT : API.PREVIEW_PROJECT}`;

  return (dispatch) => {
    const noPermission = async (access, url) => {
      dispatch(
        addNotification({
          id: uuid(),
          toastType: "WARNING",
          description: `You don't have ${access} access to this project.`,
        })
      );
      await proceedWait(2000);
      window.location.href = `${url}`;
    };
    return new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        { [isPublish ? "exportid" : "projectId"]: id },
        async (response) => {
          let { permission } = response;
          const { modified } = response;
          permission = isPublish ? 1 : permission
          const { project, subtitle_data: subtitleData, projectId = "" } = response;
          if (!project) {
            openUrl(`${API_URL}dashboard/`, true);
          }
          const { width, height } = project;
          let titleTextPayLoad;
          const applyFlipItems = [];
          const workspaceItemsLs = Object.entries(project.workspaceItems);
          if (workspaceItemsLs.length) {
            /* const leftIndexArr = [getRandomInt(0, 4), getRandomInt(7, 10)];
            const centerIndexArr = [getRandomInt(4, 7)];
            let leftIndex = leftIndexArr[0];
            let centerIndex = centerIndexArr[0];
            let leftCursor = 0;
            let index = 0; */
            workspaceItemsLs.forEach(([key, value]) => {
              if (key !== "titletext") {
                const { x, y, type, yLoc, xLoc } = value;
                if (type === "TEXT" && (yLoc !== undefined && yLoc !== null) && (xLoc !== undefined && xLoc !== null) && (x === null || y === null)) {
                  const { textData } = value;
                  const { width: itemWidth, height: itemHeight } = getTextBounds(fromJS(textData), width);
                  // const xLoc = 0.5;
                  /* if (index === leftIndex) {
                    leftCursor++;
                    if (leftCursor > 1) {
                      leftCursor = 0;
                    }
                    leftIndex += leftIndexArr[leftCursor];
                    xLoc = 0.2;
                  } else if (index === centerIndex) {
                    xLoc = 0.5;
                    centerIndex += centerIndexArr[0];
                  } */
                  const newX = width * xLoc - (itemWidth / 2);
                  const newY = height * yLoc - (itemHeight / 2);
                  const toUpdate = {
                    x: newX,
                    y: newY,
                    width: itemWidth,
                    height: itemHeight
                  }
                  applyFlipItems.push({
                    container: "workspaceItems",
                    id: key,
                    data: toUpdate
                  })
                  /** update the project data through object reference. */
                  value.x = newX;
                  value.y = newY;
                  value.width = itemWidth;
                  value.height = itemHeight;
                  // index++;
                }
              }
            })
          }
          const { titletext } = project.workspaceItems || {};
          if (titletext) {
            const { isVerified } = titletext.textData.formats.others; /** @desc for the first time we verify if the titleText is properly fit to the space */
            if (!isVerified) {
              const maxWidth = titletext.width;
              const { family: fontFamilyName } = titletext.textData.formats.others;
              const { fontFamily } = titletext.textData.formats.containerStyle;
              const textData = {
                fontFamilyName, fontFamily,
                others: {
                  isUserFont: titletext.textData.formats.others.isUserFont,
                  cssUrl: titletext.textData.formats.others.cssUrl
                }
              }
              const fontToLoad = getSubtitlesFontToLoad(undefined, fromJS([]), textData);
              if (fontToLoad.length) {
                const sampleText = titletext.textData.htmlText;
                await loadSubtitleFontFamily(fontToLoad, sampleText);
              }
              const textBounds = getTextBounds(fromJS(titletext.textData), maxWidth);
              const paddOffSet = 15;
              const newY = (height / 2) - (textBounds.height / 2) - paddOffSet;
              const newHeight = textBounds.height + paddOffSet * 2;
              titletext.textData.formats.others.isVerified = true;
              const shapeProps = {};
              if (titletext.type === "SHAPE") {
                shapeProps.yRatio = (((newHeight / 2) - textBounds.height / 2) / newHeight) * 100;
                shapeProps.heightRatio = (textBounds.height / newHeight) * 100;
              }
              project.workspaceItems.titletext = { ...titletext, y: newY, height: newHeight, ...shapeProps };
              const { x, y, width: txtWidth, height: txtHeight } = project.workspaceItems.titletext;
              titleTextPayLoad = {
                container: "workspaceItems",
                selectedItem: "titletext",
                isTyping: false,
                toUpdate: {
                  textData: titletext.textData,
                  x, y, width: txtWidth, height: txtHeight,
                  ...shapeProps
                }
              }
            }
          }
          if (subtitleData && subtitleData.length) {
            const defaultSubtitle = subtitleData.find(
              (subtitle) => subtitle.isActive !== false
            );
            try {
              const result = await getSubtitleData({
                projectId: isPublish ? projectId : id,
                subtitleId: defaultSubtitle.subtitleId,
              });
              project.subtitle = result.subtitle;
              project.defaultSubtitle = defaultSubtitle.subtitleId;
              project.subtitleData = subtitleData;
              let hasValidCords = false;
              let isAllEmpty = true;
              for (const [, subtitleMap] of Object.entries(result.subtitle.data)) {
                for (const [, subtitleItem] of Object.entries(subtitleMap)) {
                  isAllEmpty = false;
                  if (Number.isFinite(subtitleItem.x) && Number.isFinite(subtitleItem.y) && Number.isFinite(subtitleItem.width) &&
                    Number.isFinite(subtitleItem.height) && subtitleItem.width !== 0 && subtitleItem.height !== 0) {
                    hasValidCords = true;
                    break;
                  }
                }
              }
              if (!hasValidCords && !isAllEmpty) {
                const { updatedSubtitles, textStyles, textEffects } =
                  await addCordsToSubtitle(result.subtitle, {
                    loadedFonts: fromJS([]),
                    workspaceWidth: width,
                    workspaceHeight: height,
                  });
                await updateAutoSubtitle(
                  updatedSubtitles,
                  isPublish ? projectId : id,
                  defaultSubtitle.subtitleId,
                  textStyles,
                  textEffects,
                  Boolean(result.subtitle.position)
                );
                project.subtitle.data = updatedSubtitles.toJS();
                delete project.subtitle.position;
              }
            } catch (err) {
              project.subtitle = undefined;
              dispatch(
                addNotification({
                  id: uuid(),
                  toastType: "WARNING",
                  description: err.message || content.WENT_WRONG,
                })
              );
            }
          }
          let dimensionName = "";
          if (width > height) {
            dimensionName = "horizontal";
          } else if (width === height) {
            dimensionName = "square";
          } else {
            dimensionName = "vertical";
          }
          let projectData;
          if (project.scenes?.length) {
            projectData = projectMigrationHelper(project);
            saveProject(isPublish ? projectId : id, projectData).then(() => {
              dispatch({
                type: ActionTypes.ADD_NOTIFICATION,
                payload: {
                  id: uuid(),
                  toastType: "SUCCESS",
                  description: "Project has been Migrated successfully.",
                },
              });
            });
          } else {
            projectData = project;
          }

          if (isApp && !permission) {
            noPermission("edit", `${window.location.origin}/preview/${id}`);
          }
          dispatch({
            type: ActionTypes.SET_USER_DATA,
            payload: {
              isLoggedIn: true,
              projectId: isPublish ? projectId : id,
              dimensionName,
              permission,
              ...userResponse,
              modified
            },
          });
          dispatch({
            type: ActionTypes.SET_PROJECT,
            payload: {
              ...projectData,
            },
          });
          if (applyFlipItems.length) { 
            dispatch(applyFlipInitial(applyFlipItems));
          }
          if (titleTextPayLoad) { /** @Note always keep the dispatch in the bottom */
            dispatch(updateIntroOutroText(titleTextPayLoad));
          }
          res(response);
        },
        async (error) => {
          if (!isApp) {
            noPermission("view", AppConfig.API_URL);
          }
          dispatch({
            type: ActionTypes.SET_USER_DATA,
            isLoggedIn: true,
            ...userResponse,
          });
          rej(error);
        }
      );
    });
  };
}

export const setUserPaymentDetails = (id) => {
  const apiUrl = `${API_URL}${API.USER_PROJECT_DETAILS}`;

  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        { projectId: id },
        (response) => {
          dispatch({
            type: ActionTypes.SET_PAYMENT_DETAILS,
            payload: { ...response },
          });
        },
        (err) => {
          dispatch(
            addNotification({
              id: uuid(),
              toastType: "WARNING",
              description:
                err.message || content.FAILED_TO_GET_USER_PROJECT_DETAILS,
            })
          );
          rej(err);
        }
      );
    });
};

export function setAnimoProjectDetails(id, isSubtitle) {
  const apiUrl = `${API_URL}${API.GET_ANIMO_PROJECT}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        {
          projectId: id,
        },
        async (response) => {
          const { project, subtitle_data: subtitleData } = response;
          const { width, height } = project;
          if (subtitleData && subtitleData.length) {
            const defaultSubtitle = subtitleData.find(
              (subtitle) => subtitle.isActive !== false
            );
            try {
              const result = await getSubtitleData({
                projectId: id,
                subtitleId: defaultSubtitle.subtitleId,
              });
              project.subtitle = result.subtitle;
              project.defaultSubtitle = defaultSubtitle.subtitleId;
              let hasValidCords = false;
              let isAllEmpty = true;
              for (const [, subtitleMap] of Object.entries(result.subtitle.data)) {
                for (const [, subtitleItem] of Object.entries(subtitleMap)) {
                  isAllEmpty = false;
                  if (Number.isFinite(subtitleItem.x) && Number.isFinite(subtitleItem.y) && Number.isFinite(subtitleItem.width) &&
                    Number.isFinite(subtitleItem.height) && subtitleItem.width !== 0 && subtitleItem.height !== 0) {
                    hasValidCords = true;
                    break;
                  }
                }
              }
              if (!hasValidCords && !isAllEmpty) {
                const { updatedSubtitles, textStyles, textEffects } =
                  await addCordsToSubtitle(result.subtitle, {
                    loadedFonts: fromJS([]),
                    workspaceWidth: width,
                    workspaceHeight: height,
                  });
                await updateAutoSubtitle(
                  updatedSubtitles,
                  id,
                  defaultSubtitle.subtitleId,
                  textStyles,
                  textEffects
                );
                project.subtitle.data = updatedSubtitles.toJS();
              }
            } catch (err) {
              project.subtitle = undefined;
              dispatch(
                addNotification({
                  id: uuid(),
                  toastType: "WARNING",
                  description: err.message || content.WENT_WRONG,
                })
              );
            }
          }
          dispatch({
            type: ActionTypes.SET_USER_DATA,
            payload: {
              isLoggedIn: true,
              projectId: id,
            },
          });
          dispatch({
            type: ActionTypes.SET_PROJECT,
            payload: {
              ...project,
            },
            isAnimoSubtitle: isSubtitle,
          });
          res(response);
        },
        (error) => {
          dispatch({
            type: ActionTypes.SET_USER_DATA,
            isLoggedIn: true,
          });
          dispatch(
            addNotification({
              id: uuid(),
              toastType: "WARNING",
              description:
                error.message || content.FAILED_TO_GET_PROJECT_DETAILS,
            })
          );
          rej(error);
        }
      );
    });
}

export function getUserDetails() {
  const apiUrl = `${API_URL}${API.USER_DETAILS}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {},
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export function getCredits() {
  const apiUrl = `${API_URL}${API.GET_CREDITS}`;
  return (dispatch) => new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {},
      (response) => {
        dispatch({
          type: ActionTypes.SET_CREDITS,
          payload: response,
        });
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  })
}

export function getTeamDetails() {
  const apiUrl = `${API_URL}${API.TEAM_DETAILS}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {},
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export function switchTeam(dataT) {
  const apiUrl = `${API_URL}${API.SWITCH_TEAM}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      { dataT },
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export function getProjectInfo(id) {
  const apiUrl = `${API_URL}${API.GET_PROJECT_INFO}`;
  return (dispatch) => new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {
        projectId: id
      },
      (response) => {
        dispatch({
          type: ActionTypes.SET_PROJECT_INFO,
          payload: { ...response }
        });
        res(response);
      },
      (e) => {
        rej(e);
      }
    );
  });
}

export async function insertSubtitle(reqBody) {
  return new Promise((resolve, reject) => {
    const onSuccess = async (res) => {
      if (!res.success) {
        return reject(new Error(content.FAILED_TO_IMPORT_SUBTITLE_DATA));
      }
      return resolve(res);
    };
    const onError = () =>
      reject(new Error(content.FAILED_TO_IMPORT_SUBTITLE_DATA));
    triggerSimpleAjax(
      `${API_URL}${API.SUBTITLE.INSERT}`,
      "POST",
      false,
      reqBody,
      onSuccess,
      onError
    );
  });
}