import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

const RangeContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const RangeButton = styled.button`
  padding: 10px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background-color: ${({ isSelected }) => (isSelected ? "#e6f7ff" : "#fff")};
  color: #32304a;
  font: ${font.mediumMiniBase};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: #00baff;
    color: #00baff;
  `}
`;

const EstimatedVideoRange = ({ rangeOptions, onRangeSelect }) => {
  const [selectedRange, setSelectedRange] = useState("");

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    onRangeSelect(range);
  };

  return (
    <RangeContainer>
      {rangeOptions.map((range) => (
        <RangeButton
          key={range}
          isSelected={selectedRange === range}
          onClick={() => handleRangeSelect(range)}
        >
          {range}
        </RangeButton>
      ))}
    </RangeContainer>
  );
};

EstimatedVideoRange.propTypes = {
  rangeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRangeSelect: PropTypes.func.isRequired,
};

export default EstimatedVideoRange;
