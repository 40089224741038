/* eslint-disable no-console */
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { IS_LOCAL, SW_URL, SW_VERSION } from "../constants/config";
import { setInitStages } from "../redux/actions/appUtils";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
const { NODE_ENV } = process.env;
const canRegister = NODE_ENV === "production" && "serviceWorker" in navigator;

const localConsoleLog = (...args) => {
    if (IS_LOCAL) {
        // eslint-disable-next-line no-console
        console.log(...args);
    }
};

const registerValidSW = async (swUrl, config = {}) => {
    const {
        onWorkerUpdate,
        onWorkerInstall,
        onWorkerActivation,
        onWorkerReady,
        onWorkerFail,
        onWorkerVersionChange,
    } = config;

    const prevWorkerExists = Boolean(navigator.serviceWorker.controller);
    let isCheckDone = false;
    /** @type {ServiceWorkerState | undefined} */
    let state; // eslint-disable-line no-unused-vars
    let timer;

    if (!navigator.serviceWorker.controller) {
        try {
            const registrations = await navigator.serviceWorker.getRegistrations();
            const reg = registrations.find((reg) => {
                return Boolean(reg.active);
            });
            if (reg) {
                // cmd + shift + r will load page without service worker
                // user intentionally tried to load page with hard refresh
                isCheckDone = true;
                onWorkerFail("possible: hard refresh");
                return;
            }

            // to handle a case where user manually unregisters service and hits refresh without reopening the page
            timer = setTimeout(() => {
                // cmd + shift + r will load page without service worker
                if (!isCheckDone /* && state === undefined */) {
                    isCheckDone = true;
                    onWorkerFail("time limit reached");
                }
            }, 7000);
        } catch (error) {
            isCheckDone = true;
            onWorkerFail(error);
            clearTimeout(timer);
        }
    }

    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            const checkVersion = (event) => {
                if (event.data && event.data.type === "GET_SERVICE_VERSION") {
                    if (event.data.version === SW_VERSION) {
                        if (!isCheckDone) {
                            onWorkerReady(registration);
                        }
                        isCheckDone = true;
                        clearTimeout(timer);
                    } else if (isCheckDone) {
                        // different tab of this app has installed new service worker which might serve different icons
                        // show toast or modal to let user know they need to refresh if needed
                        localConsoleLog(
                            "ServiceWorkerRegister getVersion",
                            event.data.version
                        );
                        onWorkerVersionChange(event.data.version);
                    }
                }
            };

            const onControllerChange = () => {
                if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({
                        type: "GET_SERVICE_VERSION",
                    });
                } else {
                    // service might have uninstalled
                }
            };

            navigator.serviceWorker.addEventListener("message", checkVersion);
            navigator.serviceWorker.addEventListener(
                "controllerchange",
                onControllerChange
            );

            const requestVersion = () => {
                if (!navigator.serviceWorker.controller) {
                    return;
                }
                navigator.serviceWorker.controller.postMessage({
                    type: "GET_SERVICE_VERSION",
                });
            };

            if (prevWorkerExists) {
                requestVersion();
            }

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (!installingWorker) {
                    return;
                }

                installingWorker.onstatechange = () => {
                    state = installingWorker.state;
                    if (installingWorker.state === "installed") {
                        if (prevWorkerExists) {
                            // service worker updated for existing page
                            onWorkerUpdate(registration);
                        } else {
                            // new service worker installed
                            onWorkerInstall(registration);
                        }
                    } else if (installingWorker.state === "activated") {
                        // service worker activated
                        onWorkerActivation(registration);
                        if (!prevWorkerExists && !isCheckDone) {
                            onWorkerReady(registration);
                            isCheckDone = true;
                            clearTimeout(timer);
                        }

                        requestVersion();
                    } else if (installingWorker.state === "redundant") {
                        if (!isCheckDone) {
                            isCheckDone = true;
                            clearTimeout(timer);
                            onWorkerFail("possible: activation failed");
                        }
                    }
                };
            };
        })
        .catch((error) => {
            // worker registration failed
            if (!isCheckDone) {
                isCheckDone = true;
                onWorkerFail(error);
            }
            clearTimeout(timer);
        });
};

const checkValidServiceWorker = (swUrl, config = {}) => {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type");
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf("javascript") === -1)
            ) {
                // No service worker found.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister();
                });
                config.onWorkerFail("worker file not found");
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch((error) => {
            config.onWorkerFail(error);
        });
};

const register = (config = {}) => {
    if (!canRegister) {
        config.onWorkerReady("registration skipped");
    } else {
        const swUrl = new URL(SW_URL, window.location.href);
        if (swUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            config.onWorkerFail();
            return;
        }
        if (isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            checkValidServiceWorker(SW_URL, config);
        } else {
            // Is not localhost. Just register service worker
            registerValidSW(SW_URL, config);
        }
    }
};

/*
const unregister = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch(() => {
                // unregistration failed
            });
    }
};
*/

const ServiceWorkerRegister = () => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        register({
            onWorkerUpdate: (registration) => {
                localConsoleLog("ServiceWorkerRegister updated: ", registration);
            },
            onWorkerInstall: (registration) => {
                localConsoleLog("ServiceWorkerRegister installed: ", registration);
            },
            onWorkerActivation: (registration) => {
                localConsoleLog("ServiceWorkerRegister activated: ", registration);
            },
            onWorkerReady: (registration) => {
                localConsoleLog("ServiceWorkerRegister ready: ", registration);
                dispatch(setInitStages({ swReady: true }));
            },
            onWorkerFail: (reason) => {
                localConsoleLog("ServiceWorkerRegister failed: ", reason);
                dispatch(setInitStages({ swReady: true }));
            },
            onWorkerVersionChange: (version) => {
                localConsoleLog("ServiceWorkerRegister version changed: ", version);
                // if (verChangeCB) {
                //     verChangeCB(version);
                // }
            },
        });
    }, [dispatch]);

    return null;
};

export default ServiceWorkerRegister;
