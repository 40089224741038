import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import Action from "../common-components/Action";
import openUrl from "../helper/openUrl";
import DropdownLabel from "../common-components/DropdownLabel";
import {
  CloseIcon,
  ContactSalesFormWrap,
  Error,
} from "./animo-component";
import LabeledInput from "../common-components/LabeledInput";
import { contactSales } from "../redux/actions/appUtils";
import content from "../constants/content";
import Backdrop from "../common-components/BackDrop";
import { STATIC_PATH } from "../constants/config";
import vmTheme from "../constants/theme";
import { BUSSINESS_DOMAINS } from "../constants";

const Contact = ({ setShowContact }) => {
  const [formValue, setFormValue] = useState({
    name: "",
    businessEmail: "",
    companyName: "",
  });
  const [selectedCompanySize, setSelectedCompanySize] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [licenses, setLicenses] = useState("");
  const [teamHelp, setTeamHelp] = useState("");
  const [planning, setPlanning] = useState("");
  const [formErrors, setFormErrors] = useState({
    name: "",
    businessEmail: "",
    companyName: "",
    businessType: " ",
    licenses: "",
    teamHelp: "",
    planning: "",
  });
  const theme = useSelector((state) => state.app.get('theme'));
  const isBusinessEmail = (email) =>
    BUSSINESS_DOMAINS.includes(email.split("@")[1]);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formValue.name) {
      errors.name = "Please enter your name";
      isValid = false;
    }

    if (!formValue.businessEmail) {
      errors.businessEmail = "Please enter your business email";
      isValid = false;
    } else if (isBusinessEmail(formValue.businessEmail)) {
      errors.businessEmail = "Please enter a valid business email.";
      isValid = false;
    }
    if (!formValue.companyName) {
      errors.companyName = "Please enter your company";
      isValid = false;
    }
    if (businessType === "") {
      errors.businessType = "Please select any one option";
      isValid = false;
    }
    if (licenses === "") {
      errors.licenses = "Please select any one option";
      isValid = false;
    }
    if (teamHelp === "") {
      errors.teamHelp = "Please select any one option";
      isValid = false;
    }
    if (planning === "") {
      errors.planning = "Please select any one option";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleDropdownChange = (selectedValue, setStateFunction, fieldName) => {
    setStateFunction(selectedValue);
    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const closeContactForm = () => {
    setShowContact(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
    }));
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const reqBody = {
        Name: formValue.name,
        Business_email: formValue.businessEmail,
        Company: formValue.companyName,
        employee_count: selectedCompanySize,
        Business_type: businessType,
        licenses,
        sales_team_help: teamHelp,
        sales_plan: planning,
      };

      contactSales(reqBody)
        .then(() => {
          openUrl(
            "https://outplayr.animaker.com/meeting/animaker/harishtg?selecteddate=2023-11-16&token="
          );
          closeContactForm();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((_e) => {
          // _e
        });
    }
  };
  return (
    <>
      <Backdrop
        background="rgba(0,0,0,0.5)"
        zIndex={6}
        onClick={() => setShowContact(false)}
      />
      <ContactSalesFormWrap>
        <CloseIcon
          src={`${STATIC_PATH}close-icon.svg`}
          alt="close"
          onClick={() => setShowContact(false)}
          style={{ top: "0px", position: "absolute", right: "0px" }}
        />
        <Typography
          content={"Let’s get you the best deal"}
          display="block"
          margin="0 auto"
          color={vmTheme[theme].contactLabelTextColor}
          align="center"
          font={font.boldSmall}
        />
        <LabeledInput
          label="Name"
          value={formValue.name}
          onChange={handleChange}
          type="text"
          name="name"
          required={true}
        />
        {formErrors.name && <Error>{formErrors.name}</Error>}
        <LabeledInput
          label="Business Email"
          value={formValue.businessEmail}
          onChange={handleChange}
          type="text"
          name="businessEmail"
          required={true}
        />
        {formErrors.businessEmail && <Error>{formErrors.businessEmail}</Error>}
        <LabeledInput
          label="Company"
          value={formValue.companyName}
          onChange={handleChange}
          type="text"
          name="companyName"
          required={true}
        />
        {formErrors.companyName && <Error>{formErrors.companyName}</Error>}
        <DropdownLabel
          position={"static"}
          className="inputDropdown"
          labelText="Business type"
          labelColor={vmTheme[theme].contactLabelColor}
          labelEnable
          height="40px"
          fontStyle={font.normal}
          optionWidth="92%"
          labelMargin="20px 0px 0px"
          options={[
            { label: "Commercial Business", value: "commercial-business" },
            {
              label: "Educational Business",
              value: "educational-business",
            },
          ]}
          value={businessType}
          onChange={(value) =>
            handleDropdownChange(value, setBusinessType, "businessType")
          }
        />
        {formErrors.businessType && <Error>{formErrors.businessType}</Error>}
        <DropdownLabel
          className="inputDropdown"
          position={"static"}
          optionWidth="92%"
          width="100%"
          labelColor={vmTheme[theme].contactLabelColor}
          labelMargin="20px 0px 0px"
          labelText="Employee count"
          labelEnable
          fontStyle={font.normal}
          height="40px"
          options={[
            { label: "1 - 10", value: "1 - 10" },
            { label: "11 - 50", value: "11 - 50" },
            { label: "51 - 200", value: "51 - 200" },
            { label: "201 - 1000", value: "201 - 1000" },
            { label: "1001 - 5000", value: "1001 - 5000" },
            { label: "5000+", value: "5000+" },
          ]}
          value={selectedCompanySize}
          onChange={(value) =>
            handleDropdownChange(
              value,
              setSelectedCompanySize,
              "selectedCompanySize"
            )
          }
        />
        {formErrors.selectedCompanySize && (
          <Error>{formErrors.selectedCompanySize}</Error>
        )}
        <DropdownLabel
          className="inputDropdown"
          width="100%"
          labelText="Required number of licenses"
          labelEnable
          labelColor={vmTheme[theme].contactLabelColor}
          height="40px"
          labelMargin="20px 0px 0px"
          position={"static"}
          optionWidth="92%"
          fontStyle={font.normal}
          options={[
            { label: "1 - 5", value: "1 - 5" },
            { label: "6 - 10", value: "6 - 10" },
            { label: "11 - 25", value: "11 - 25" },
            { label: "26 - 50", value: "26 - 50" },
            { label: "51+", value: "51+" },
          ]}
          value={licenses}
          onChange={(value) =>
            handleDropdownChange(value, setLicenses, "licenses")
          }
        />
        {formErrors.licenses && <Error>{formErrors.licenses}</Error>}

        <DropdownLabel
          className="inputDropdown"
          width="100%"
          labelText="How can our sales team help?"
          labelEnable
          labelColor={vmTheme[theme].contactLabelColor}
          labelMargin="20px 0px 0px"
          height="40px"
          position={"static"}
          fontStyle={font.normal}
          optionWidth="92%"
          options={[
            {
              label: "Discuss bulk purchase discounts",
              value: "Discuss bulk purchase discounts",
            },
            {
              label: "Arrange a meeting with sales representative",
              value: "Arrange a meeting with sales representative",
            },
            {
              label: "Provide support with new purchase process",
              value: "Provide support with new purchase process",
            },
            {
              label: "Provide assistance with renewals or upgrades",
              value: "Provide assistance with renewals or upgrades",
            },
            {
              label: "Provide assistance with any other enquiry",
              value: "Provide assistance with any other enquiry",
            },
          ]}
          value={teamHelp}
          onChange={(value) =>
            handleDropdownChange(value, setTeamHelp, "teamHelp")
          }
        />
        {formErrors.teamHelp && <Error>{formErrors.teamHelp}</Error>}
        <DropdownLabel
          className="inputDropdown"
          width="100%"
          labelText="What are you planning to use Vmaker for?"
          labelEnable
          labelColor={vmTheme[theme].contactLabelColor}
          height="40px"
          fontStyle={font.normal}
          labelMargin="20px 0px 0px"
          position={"static"}
          optionWidth="92%"
          options={[
            {
              label: "Learning and Development",
              value: "Learning and Development",
            },
            { label: "Marketing/Sales", value: "Marketing/Sales" },
            {
              label: "Internal Communications",
              value: "Internal Communications",
            },
            { label: "Personal Usage", value: "Personal Usage" },
            { label: "Customer Success", value: "Customer Success" },
            { label: "Others", value: "Others" },
          ]}
          value={planning}
          onChange={(value) =>
            handleDropdownChange(value, setPlanning, "planning")
          }
        />
        {formErrors.planning && <Error>{formErrors.planning}</Error>}
        <Action
          className="contact-btn"
          onClick={submitHandler}
          borderRadius="4px"
          text={content.CONTACT_SALES}
          marginTop="25px"
        />
      </ContactSalesFormWrap>
    </>
  );
};

Contact.propTypes = {
  setShowContact: PropTypes.func,
};

export default Contact;
