import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { APP_Z_INDICES, PORTAL_ID } from "../constants";
import { font } from "../constants/font";
import Typography from "./Typography";
import vmTheme from "../constants/theme";
import getElement from "../helper/getElement";

const ToolTipPositionStyles = css`
  &.default {
    transform: translateY(-50%);

    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &.top {
    transform: translate(-50%, -100%);

    &::after {
      border-top-color: ${(props) => props.theme.tooltipBorderColor};
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.top-left {
    transform: translateY(-100%);

    &::after {
      border-top-color: ${(props) => props.theme.tooltipBorderColor};
      top: 100%;
      right: 85%;
    }
  }

  &.top-right {
    transform: translate(-100%, -100%);

    &::after {
      border-top-color: ${(props) => props.theme.tooltipBorderColor};
      top: 100%;
      left: 85%;
    }
  }

  &.right {
    transform: translateY(-50%);

    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &.right-top {
    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      top: 0;
      right: 100%;
      transform: translateY(85%);
    }
  }

  &.right-bottom {
    transform: translateY(-100%);

    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 15%;
      right: 100%;
      transform: translateY(-15%);
    }
  }

  &.left {
    transform: translate(-100%, -50%);

    &::after {
      border-left-color: ${(props) => props.theme.tooltipBorderColor};
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
  }

  &.left-top {
    transform: translateX(-100%);

    &::after {
      border-left-color: ${(props) => props.theme.tooltipBorderColor};
      top: 0;
      left: 100%;
      transform: translateY(85%);
    }
  }

  &.left-bottom {
    transform: translate(-100%, -100%);

    &::after {
      border-left-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 15%;
      left: 100%;
      transform: translateY(-15%);
    }
  }

  &.bottom {
    transform: translateX(-50%);

    &::after {
      border-bottom-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bottom-left {
    &::after {
      border-bottom-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 100%;
      right: 85%;
    }
  }

  &.bottom-right {
    transform: translateX(-100%);

    &::after {
      border-bottom-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 100%;
      left: 85%;
    }
  }
`;

const StyledTooltip = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  background: ${(props) => props.theme.blackColor} 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: ${font.normalBase} !important;
  user-select: none;
  z-index: ${APP_Z_INDICES.customtooltip};
  color: ${(props) => props.theme.menuBgColor};
  padding: 12px;
  border: 0;
  opacity: 0;
  transition: opacity 0.1s ease-in;

  ${ToolTipPositionStyles}
`;

const HorizontalLine = styled.div`
  width: 240px;
  height: 1px;
  margin: 8px -12px 8px;
  background: ${(props) => props.theme.lineBgColor} 0% 0% no-repeat padding-box;
`;

/**
 * @description Checks if the text inside the element is overflowed or not
 * @param {string} elementIdentifier
 * @returns {boolean} is text overflowed
 */
const isTextEllipses = (elementIdentifier) => {
  const textElement = getElement(
    `[data-text-id="${elementIdentifier}"]`
  );
  if (textElement && textElement.offsetWidth < textElement.scrollWidth) {
    return true;
  }
  return false;
};

/**
 * @description Calculates where to place the tooltip
 * @param {Element} targetElement - target element for tooltip
 * @param {string} position - tooltip position
 * @param {Number} offset - how much px away from element
 * @returns {Object} points - tooltip points
 */
const calculateTooltipPoints = (targetElement, position, offset) => {
  const targetElementRect = targetElement.getBoundingClientRect();
  const points = {
    top: 0,
    left: 0,
  };

  // calculating top and left of tooltip based on parent element
  const scrollDistance = window.scrollY;
  switch (position) {
    case "top":
      points.top = targetElementRect.top + scrollDistance - offset;
      points.left = (targetElementRect.left + targetElementRect.right) / 2;
      break;
    case "top-left":
      points.top = targetElementRect.top + scrollDistance - offset;
      points.left = targetElementRect.left;
      break;
    case "top-right":
      points.top = targetElementRect.top + scrollDistance - offset;
      points.left = targetElementRect.right;
      break;
    case "right":
      points.top =
        (targetElementRect.top + targetElementRect.bottom) / 2 + scrollDistance;
      points.left = targetElementRect.right + offset;
      break;
    case "right-top":
      points.top = targetElementRect.top + scrollDistance;
      points.left = targetElementRect.right + offset;
      break;
    case "right-bottom":
      points.top = targetElementRect.bottom + scrollDistance;
      points.left = targetElementRect.right + offset;
      break;
    case "left":
      points.top =
        (targetElementRect.top + targetElementRect.bottom) / 2 + scrollDistance;
      points.left = targetElementRect.left - offset;
      break;
    case "left-top":
      points.top = targetElementRect.top + scrollDistance;
      points.left = targetElementRect.left - offset;
      break;
    case "left-bottom":
      points.top = targetElementRect.bottom + scrollDistance;
      points.left = targetElementRect.left - offset;
      break;
    case "bottom":
      points.top = targetElementRect.bottom + offset + scrollDistance;
      points.left = (targetElementRect.left + targetElementRect.right) / 2;
      break;
    case "bottom-left":
      points.top = targetElementRect.bottom + offset + scrollDistance;
      points.left = targetElementRect.left;
      break;
    case "bottom-right":
      points.top = targetElementRect.bottom + offset + scrollDistance;
      points.left = targetElementRect.right;
      break;
    default:
      points.top =
        (targetElementRect.top + targetElementRect.bottom) / 2 + scrollDistance;
      points.left = targetElementRect.right + offset;
  }

  return points;
};

/**
 * Creates a tooltip for the target element
 * @param {object} props
 * @param {string} props.tooltipId To identify target element - Recommended way --> tt-componentname-uniqueIdentifier
 * @param {"top" | "top-left" | "top-right" | "bottom" | "bottom-left" | "bottom-right" | "left" | "left-top" | "left-bottom" | "right" | "right-top" | "right-bottom"} props.tooltipPosition Position of tooltip
 * @param {number} props.offset how much px away from target element, default to 10
 * @param {number} props.delayVisibilityBy Delay (in ms) to make the tooltip visible - Default 500ms
 * @param {string} props.checkTextOverflowFor data-text-id for text to conditionally show tooltip based on text overflowed or not. Can be same as tooltipId.
 */
const PricingTooltipComponent = (props) => {
  const {
    tooltipId,
    tooltipPosition = "right",
    offset = 4,
    delayVisibilityBy = 100,
    checkTextOverflowFor = null,
    pricingInfo = null,
    children,
    isGif,
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPoints, setTooltipPoints] = useState({});
  const [adjustedTooltipPosition, setAdjustedTooltipPosition] = useState(null);
  const [blockTooltip, setBlockTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const theme = useSelector((state) => state.app.get('theme'));
  
  useEffect(() => {
    const targetElement = getElement(
      `[data-tooltip-id="${tooltipId}"]`
    ); // select targeted element
    let delayTimer;

    const handleShowTooltip = () => {
      // Calculating only on mouse hover on targeted element
      const points = calculateTooltipPoints(
        targetElement,
        tooltipPosition,
        offset
      );
      setTooltipPoints(points);
      setShowTooltip(true);

      // Logic to flip tooltip position if tooltip overflows out of window
      if (tooltipRef.current && !adjustedTooltipPosition) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const MARGIN = 5;
        if (tooltipRect.x + tooltipRect.width + MARGIN >= windowWidth) {
          setAdjustedTooltipPosition("left");
          const points = calculateTooltipPoints(targetElement, "left", offset);
          setTooltipPoints(points);
        } else if (tooltipRect.x - MARGIN <= 0) {
          setAdjustedTooltipPosition("right");
          const points = calculateTooltipPoints(targetElement, "right", offset);
          setTooltipPoints(points);
        } else if (
          tooltipRect.y + tooltipRect.height + MARGIN >=
          windowHeight
        ) {
          setAdjustedTooltipPosition("top");
          const points = calculateTooltipPoints(targetElement, "top", offset);
          setTooltipPoints(points);
        } else if (tooltipRect.y - MARGIN <= 0) {
          setAdjustedTooltipPosition("bottom");
          const points = calculateTooltipPoints(
            targetElement,
            "bottom",
            offset
          );
          setTooltipPoints(points);
        }
      }

      if (checkTextOverflowFor !== null) {
        const textEllipses = isTextEllipses(checkTextOverflowFor);
        if (!textEllipses) {
          setBlockTooltip(true);
        }
      }

      delayTimer = setTimeout(() => {
        if (tooltipRef.current) tooltipRef.current.style.opacity = "0.8";
      }, delayVisibilityBy);
    };

    const handleHideTooltip = () => {
      clearTimeout(delayTimer);
      setShowTooltip(false);
      setAdjustedTooltipPosition(null);
    };

    if (targetElement) {
      targetElement.addEventListener("mouseenter", handleShowTooltip);
      targetElement.addEventListener("mouseleave", handleHideTooltip);
      targetElement.addEventListener("click", handleHideTooltip);
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener("mouseenter", handleShowTooltip);
        targetElement.removeEventListener("mouseleave", handleHideTooltip);
        targetElement.removeEventListener("click", handleHideTooltip);
      }
      clearTimeout(delayTimer);
    };
  }, [
    adjustedTooltipPosition,
    checkTextOverflowFor,
    delayVisibilityBy,
    offset,
    pricingInfo,
    tooltipId,
    tooltipPosition,
  ]);

  const shouldShowTooltip = showTooltip && tooltipPoints && !blockTooltip;

  const className = adjustedTooltipPosition || tooltipPosition || "default";
  const portalDOM = document.getElementById(PORTAL_ID);

  const tooltip = pricingInfo && (
    <StyledTooltip
      ref={tooltipRef}
      data-tooltip-for={tooltipId}
      className={className}
      style={{
        left: `${tooltipPoints.left}px`,
        top: `${tooltipPoints.top}px`,
      }}
    >
      {!isGif ? (
        <>
          {pricingInfo.title && (
            <Typography
              cursor="pointer"
              margin="auto"
              font={font.mediumMini}
              color={vmTheme[theme].menuBgColor}
              innerContent={pricingInfo.title}
              maxWidth="216px"
              display="block"
              padding="0px 0px 4px 0px"
              height="19px"
            />
          )}
          {pricingInfo.artist && (
            <Typography
              cursor="pointer"
              margin="auto"
              font={font.normalMicro_11}
              color={vmTheme[theme].priceInfoColor}
              innerContent={`By ${pricingInfo.artist} From ${pricingInfo.collection_name}`}
              maxWidth="216px"
              height="16px"
              display="block"
            />
          )}
          {pricingInfo.cost && (
            <>
              <HorizontalLine />
              <Typography
                cursor="pointer"
                margin="auto"
                font={font.mediumMini}
                color={vmTheme[theme].menuBgColor}
                innerContent={`Cost: ${pricingInfo.cost} Per use`}
                maxWidth="216px"
                display="block"
                height="19px"
              />
            </>
          )}
          {pricingInfo.note && (
            <Typography
              cursor="pointer"
              margin="auto"
              font={font.normalMicroMini}
              color={vmTheme[theme].menuBgColor}
              innerContent={pricingInfo.note}
              maxWidth="216px"
              display="block"
              enableTrim={false}
            />
          )}
        </>
      ) : (
        <>
          {pricingInfo.username && (
            <Typography
              cursor="pointer"
              margin="auto"
              font={font.mediumMini}
              color={vmTheme[theme].menuBgColor}
              innerContent={`Author: ${pricingInfo.username}`}
              maxWidth="216px"
              display="block"
              padding="0px 0px 4px 0px"
              height="19px"
            />
          )}
          {pricingInfo.category && (
            <Typography
              cursor="pointer"
              margin="auto"
              font={font.mediumMini}
              color={vmTheme[theme].menuBgColor}
              innerContent={`Title: ${pricingInfo.category}`}
              maxWidth="216px"
              display="block"
              padding="0px 0px 4px 0px"
              height="19px"
            />
          )}
        </>
      )}
    </StyledTooltip>
  );
  return (
    <>
      {children}
      {shouldShowTooltip &&
        portalDOM &&
        ReactDOM.createPortal(tooltip, portalDOM)}
    </>
  );
};

PricingTooltipComponent.propTypes = {
  tooltipId: PropTypes.string,
  tooltipPosition: PropTypes.string,
  offset: PropTypes.number,
  delayVisibilityBy: PropTypes.number,
  checkTextOverflowFor: PropTypes.string,
  pricingInfo: PropTypes.object,
  children: PropTypes.object,
  isGif: PropTypes.bool,
};

export default PricingTooltipComponent;
