/* eslint-disable no-continue, no-restricted-syntax, operator-assignment, max-len, jsx-a11y/no-static-element-interactions, react/sort-comp */

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { getSelectedItemIds, getSelectionContainer } from "../../../helper/IndividualSelectHelper";
import { ITEM_CONFIG, STATIC_PATH } from "../../../constants/config";
import { AdjustButtonStyled, AdjustSliderContainer, AdjustmentList, ApplyAll, ApplyAllContainer, FilterList, ImageFilterContainer } from "./propertywindow-components";
import content from "../../../constants/content";
import { isGiphyOnly, isImageOnly, isVideoOnly } from "../../timeline/timeline-helper";
import { ADJUSTMENT_KEYS, FILTER_LIST, PRESET_FILTERS } from "./propertywindow-presetfilters";
import { getFrameClipFilterSvgId, getFrameClipVignetteId, singleClipFrameToFrame } from "../../frame/frame-helper";
import { clampFilterValue, spontaneousFilters } from "./imagefilter-helper";
import { applyFlip } from "../../../redux/actions/timelineUtils";
import Slider from "../../zoom/zoom-slider";
import { Modal } from "../../../common-components/modal";
import vmTheme from "../../../constants/theme";
import { createMemoizedFunction } from "../../../helper/createMemoizedFunction";

const FilterTabList = (props) => {
    const { currentTab, setCurrentTab } = props;

    let filterCls = "imgf--tab-btn";
    let filterImgPath = `${STATIC_PATH}filter.svg`;
    if (currentTab === "filter-list") {
        filterImgPath = `${STATIC_PATH}filter-active.svg`;
        filterCls = `${filterCls} tab-btn-active`;
    }

    let adjustCls = "imgf--tab-btn";
    let adjustImgPath = `${STATIC_PATH}adjustment.svg`;
    if (currentTab === "filter-adjust") {
        adjustImgPath = `${STATIC_PATH}adjust-filter.svg`;
        adjustCls = `${adjustCls} tab-btn-active`;
    }

    return (
        <div className={`imgf--tabs ${currentTab}`}>
            <div className={filterCls} onClick={() => setCurrentTab("filter-list")}>
                <img draggable={false} src={filterImgPath} alt="filters" />
                <FormattedMessage id={content.FILTERS} />
            </div>
            <div className={adjustCls} onClick={() => setCurrentTab("filter-adjust")}>
                <img draggable={false} src={adjustImgPath} alt="filters" />
                <FormattedMessage id={content.ADJUSTMENT} />
            </div>
        </div>
    );
}
FilterTabList.propTypes = {
    currentTab: PropTypes.string,
    setCurrentTab: PropTypes.func,
};

class ImageFilterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterWindowOpen: this.props.isAlwaysOpen,
            currentTab: "filter-list",

            applyAllOpen: false,
            filterApplyAllUpdater: [],

            currentFilterCode: "",
            presetFilterCode: "",
            filterType: "",

            initialIntensity: 50,
            intensity: 50,
            bright: 0,
            contrast: 0,
            hue: 0,
            saturate: 0,
            tint: 0,
            blur: 0,
            vignette: 0,
        };

        this.filterValues = FILTER_LIST;
        this.presetFilters = PRESET_FILTERS;
        this.adjustmentKeys = ADJUSTMENT_KEYS;
        this.defaultFilterValues = Object.freeze({
            initIntensity: 50,
            initBright: 100,
            initContrast: 100,
            initHue: 100,
            initSaturate: 100,
            initTint: 100,
            initBlur: 100,
            initvignette: 0,
        });

        this.isFrameClip = this.isFrameClip.bind(this);
        this.setInitialFilters = this.setInitialFilters.bind(this);
        this.setPresetType = this.setPresetType.bind(this);
        this.convertFilterCodeToAttributes = this.convertFilterCodeToAttributes.bind(this);
        this.getPresetType = this.getPresetType.bind(this);
        this.filterClick = this.filterClick.bind(this);
        this.convertToHex = this.convertToHex.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleFilterWindow = this.toggleFilterWindow.bind(this);
        this.setCurrentTab = this.setCurrentTab.bind(this);
        this.toggleApplyAllModal = this.toggleApplyAllModal.bind(this);
        this.getFilterApplyAllUpdater = this.getFilterApplyAllUpdater.bind(this);
        this.filterApplyAll = this.filterApplyAll.bind(this);

        this.svgItem;
        this.vignetteDOMId;
    }

    componentDidMount() {
        const { selectedFrameClip, workspaceItem } = this.props;
        if (this.isFrameClip()) {
            this.svgItem = document.getElementById(getFrameClipFilterSvgId(workspaceItem.get("id"), selectedFrameClip.get("clipId")));
            this.vignetteDOMId = getFrameClipVignetteId(workspaceItem.get("id"), selectedFrameClip.get("clipId"));
        } else if (workspaceItem) {
            this.svgItem = document.getElementById(`scene-item-filtersvg-${workspaceItem.get("id")}`);
            this.vignetteDOMId = `scene-item-vignette-${workspaceItem.get("id")}`;
        }
        this.setInitialFilters();
        this.setPresetType(this.props.itemFilterData);
    }

    componentDidUpdate(prevProps) {
        const { selectedFrameClip, workspaceItem } = this.props;

        const currentFilterCode = this.props.itemFilterData;
        const prevFilterCode = prevProps.itemFilterData;

        if (this.state.currentFilterCode !== currentFilterCode && currentFilterCode !== prevFilterCode) {
            this.setInitialFilters();
            this.setPresetType(currentFilterCode);
        }

        if (this.isFrameClip() && (prevProps.selectedFrameClip.get("clipId") !== selectedFrameClip.get("clipId") || workspaceItem !== prevProps.workspaceItem)) {
            this.svgItem = document.getElementById(getFrameClipFilterSvgId(workspaceItem.get("id"), selectedFrameClip.get("clipId")));
            this.vignetteDOMId = getFrameClipVignetteId(workspaceItem.get("id"), selectedFrameClip.get("clipId"));
        } else if (workspaceItem !== prevProps.workspaceItem && workspaceItem) {
            this.svgItem = document.getElementById(`scene-item-filtersvg-${workspaceItem.get("id")}`);
            this.vignetteDOMId = `scene-item-vignette-${workspaceItem.get("id")}`;
        }
    }

    isFrameClip() {
        return (
            this.props.workspaceItem
            && this.props.workspaceItem.get("type") === "FRAME"
            && this.props.selectedFrameClip.get("clipId")
        );
    }

    setInitialFilters(copiedFilters, action) {
        let toReturn = null;
        let filter;

        if (copiedFilters !== undefined && (action === "paste" || action === 'filterEdit')) {
            filter = copiedFilters;
        } else {
            filter = this.props.itemFilterData;
        }

        if (filter !== undefined && (filter !== this.state.currentFilterCode || action === 'filterEdit')) {
            toReturn = { ...this.state };

            const setBright = (parseInt(filter.substr(0, 2), 16) >= -100) ? parseInt(filter.substr(0, 2), 16) : this.defaultFilterValues.initBright;
            const setContrast = (parseInt(filter.substr(2, 2), 16) >= -100) ? parseInt(filter.substr(2, 2), 16) : this.defaultFilterValues.initContrast;
            const setHue = (parseInt(filter.substr(4, 2), 16) >= 0) ? parseInt(filter.substr(4, 2), 16) : this.defaultFilterValues.initHue;
            const setSaturate = (parseInt(filter.substr(6, 2), 16) >= -100) ? parseInt(filter.substr(6, 2), 16) : this.defaultFilterValues.initSaturate;
            const setTint = (parseInt(filter.substr(8, 2), 16) >= -1) ? parseInt(filter.substr(8, 2), 16) : this.defaultFilterValues.initTint;
            const setBlur = (parseInt(filter.substr(10, 2), 16) >= 0) ? parseInt(filter.substr(10, 2), 16) : this.defaultFilterValues.initBlur;
            const setVignette = (parseInt(filter.substr(12, 2), 16) >= 0) ? parseInt(filter.substr(12, 2), 16) : this.defaultFilterValues.initvignette;
            const setIntensity = (parseInt(filter.substr(14, 2), 16) >= 0) ? parseInt(filter.substr(14, 2), 16) : this.defaultFilterValues.initIntensity;

            toReturn.bright = setBright - 100;
            toReturn.contrast = setContrast - 100;
            toReturn.hue = setHue - 100;
            toReturn.saturate = setSaturate - 100;
            toReturn.tint = setTint - 100;
            toReturn.blur = setBlur - 100;
            toReturn.vignette = setVignette;
            toReturn.intensity = setIntensity;
            toReturn.currentFilterCode = filter;

            this.setState(toReturn);
        }
    }

    setPresetType(filterCode) {
        let isCustom = true;

        if (filterCode) {
            const filterType = this.getPresetType(filterCode);
            if (filterType !== "") {
                isCustom = false;
                const initialFilterPercentage = this.state.initialIntensity / 100;
                const presetFilterValues = this.presetFilters.getIn([filterType]);

                const newBright = Math.round(presetFilterValues.get("bright") * initialFilterPercentage);
                const newContrast = Math.round(presetFilterValues.get("contrast") * initialFilterPercentage);
                const newHue = Math.round(presetFilterValues.get("hue") * initialFilterPercentage);
                const newSaturate = Math.round(presetFilterValues.get("saturate") * initialFilterPercentage);
                const newTint = presetFilterValues.get("tint");
                const newBlur = Math.round(presetFilterValues.get("blur") * initialFilterPercentage);
                const newVignette = Math.round(presetFilterValues.get("vignette") * initialFilterPercentage);

                const toUpdate = {};
                toUpdate.givenBright = newBright;
                toUpdate.givenContrast = newContrast;
                toUpdate.givenHue = newHue;
                toUpdate.givenSaturate = newSaturate;
                toUpdate.givenTint = newTint;
                toUpdate.givenBlur = newBlur;
                toUpdate.givenVignette = newVignette;
                toUpdate.filterType = filterType;

                this.setState(toUpdate);
            }
        }

        if (isCustom) {
            this.setState({
                filterType: "CUSTOM",
            });
        }
    }

    /**
     * @param {string} filterCode Filter code
     * @returns {object} the attributes of filter
     * @note update this function whenever {@link setInitialFilters} is changed
     */
    convertFilterCodeToAttributes(filterCode) {
        let bright = (parseInt(filterCode.substr(0, 2), 16) >= -100) ? parseInt(filterCode.substr(0, 2), 16) : this.defaultFilterValues.initBright;
        let contrast = (parseInt(filterCode.substr(2, 2), 16) >= -100) ? parseInt(filterCode.substr(2, 2), 16) : this.defaultFilterValues.initContrast;
        let hue = (parseInt(filterCode.substr(4, 2), 16) >= 0) ? parseInt(filterCode.substr(4, 2), 16) : this.defaultFilterValues.initHue;
        let saturate = (parseInt(filterCode.substr(6, 2), 16) >= -100) ? parseInt(filterCode.substr(6, 2), 16) : this.defaultFilterValues.initSaturate;
        let tint = (parseInt(filterCode.substr(8, 2), 16) >= -1) ? parseInt(filterCode.substr(8, 2), 16) : this.defaultFilterValues.initTint;
        let blur = (parseInt(filterCode.substr(10, 2), 16) >= 0) ? parseInt(filterCode.substr(10, 2), 16) : this.defaultFilterValues.initBlur;
        const vignette = (parseInt(filterCode.substr(12, 2), 16) >= 0) ? parseInt(filterCode.substr(12, 2), 16) : this.defaultFilterValues.initvignette;
        const intensity = (parseInt(filterCode.substr(14, 2), 16) >= 0) ? parseInt(filterCode.substr(14, 2), 16) : this.defaultFilterValues.initIntensity;

        bright = bright - 100;
        contrast = contrast - 100;
        hue = hue - 100;
        saturate = saturate - 100;
        tint = tint - 100;
        blur = blur - 100;

        return {
            bright, contrast, hue, saturate, tint, blur, vignette, intensity
        }
    }

    getPresetType(filterCode) {
        const filterAttributes = this.convertFilterCodeToAttributes(filterCode);
        const ALLOWED_DEVIATION = 0;

        let presetKey = "";
        this.presetFilters.some((currentPreset, currentPresetKey) => {
            const isFilterMatched = (
                Math.abs(filterAttributes.blur - Math.round(clampFilterValue(currentPreset.get("blur"), filterAttributes.intensity))) <= ALLOWED_DEVIATION
                && Math.abs(filterAttributes.bright - Math.round(clampFilterValue(currentPreset.get("bright"), filterAttributes.intensity))) <= ALLOWED_DEVIATION
                && Math.abs(filterAttributes.contrast - Math.round(clampFilterValue(currentPreset.get("contrast"), filterAttributes.intensity))) <= ALLOWED_DEVIATION
                && Math.abs(filterAttributes.hue - Math.round(clampFilterValue(currentPreset.get("hue"), filterAttributes.intensity))) <= ALLOWED_DEVIATION
                && Math.abs(filterAttributes.saturate - Math.round(clampFilterValue(currentPreset.get("saturate"), filterAttributes.intensity))) <= ALLOWED_DEVIATION
                && Math.abs(filterAttributes.tint - Math.round(currentPreset.get("tint"))) <= ALLOWED_DEVIATION
                && Math.abs(filterAttributes.vignette - Math.round(clampFilterValue(currentPreset.get("vignette"), filterAttributes.intensity))) <= ALLOWED_DEVIATION
            );

            if (isFilterMatched) {
                presetKey = currentPresetKey;
                return true;
            }

            return false;
        });

        return presetKey;
    }

    filterClick(type) {
        try {
            const initialFilterPercentage = this.state.initialIntensity / 100;
            const { bright, contrast, hue, saturate, tint, blur, vignette } = this.presetFilters.getIn([type]).toJS();

            const newBright = Math.round(bright * initialFilterPercentage)
            const newContrast = Math.round(contrast * initialFilterPercentage)
            const newHue = Math.round(hue * initialFilterPercentage)
            const newSaturate = Math.round(saturate * initialFilterPercentage)
            const newTint = tint
            const newBlur = Math.round(blur * initialFilterPercentage)
            const newVignette = Math.round(vignette * initialFilterPercentage)
            const intensity = 50

            const stateObject = { ...this.state }

            stateObject.filterType = type
            stateObject.intensity = intensity

            stateObject.bright = newBright
            stateObject.contrast = newContrast
            stateObject.hue = newHue
            stateObject.saturate = newSaturate
            stateObject.tint = newTint
            stateObject.blur = newBlur
            stateObject.vignette = newVignette

            stateObject.givenBright = newBright
            stateObject.givenContrast = newContrast
            stateObject.givenHue = newHue
            stateObject.givenSaturate = newSaturate
            stateObject.givenTint = newTint
            stateObject.givenBlur = newBlur
            stateObject.givenVignette = newVignette

            stateObject.currentFilterCode =
                this.convertToHex(newBright + 100) +
                this.convertToHex(newContrast + 100) +
                this.convertToHex(newHue + 100) +
                this.convertToHex(newSaturate + 100) +
                this.convertToHex(newTint + 100) +
                this.convertToHex(newBlur + 100) +
                this.convertToHex(newVignette) +
                this.convertToHex(intensity);

            stateObject.presetFilterCode =
                this.convertToHex(newBright + 100) +
                this.convertToHex(newContrast + 100) +
                this.convertToHex(newHue + 100) +
                this.convertToHex(newSaturate + 100) +
                this.convertToHex(newTint + 100) +
                this.convertToHex(newBlur + 100) +
                this.convertToHex(newVignette) +
                this.convertToHex(intensity);

            this.setState({ ...stateObject }, this.handleSaveClick());
        } catch (error) { }
    }

    convertToHex(d) {
        const HexString = Number(d).toString(16);
        return (HexString.length === 1) ? `0${HexString}` : HexString;
    }

    handleSaveClick() {
        try {
            const { bright, contrast, hue, saturate, tint, blur, vignette, intensity } = this.state;

            const filterHexCode =
                this.convertToHex(bright + 100) +
                this.convertToHex(contrast + 100) +
                this.convertToHex(hue + 100) +
                this.convertToHex(saturate + 100) +
                this.convertToHex(tint + 100) +
                this.convertToHex(blur + 100) +
                this.convertToHex(vignette) +
                this.convertToHex(intensity)

            this.setState({ currentFilterCode: filterHexCode }, () => {
                if (this.state.currentFilterCode === this.props.itemFilterData) {
                    return;
                }

                const { selectionContainer, workspaceItem } = this.props;

                let filterUpdate;

                if (this.isFrameClip()) {
                    filterUpdate = {
                        container: selectionContainer,
                        id: this.props.selectedFrameClip.get("id"),
                        clipId: this.props.selectedFrameClip.get("clipId"),
                        data: { filter: this.state.currentFilterCode },
                    };
                } else if (workspaceItem) {
                    filterUpdate = {
                        container: selectionContainer,
                        id: workspaceItem.get("id"),
                        data: { filter: this.state.currentFilterCode },
                    };
                }

                if (filterUpdate) {
                    this.props.applyFlip([filterUpdate]);
                }
            });
        } catch (error) { }
    }

    handleInputChange(val, type, shouldSave) {
        try {
            const saveCallback = shouldSave ? this.handleSaveClick : undefined;

            if (type === "intensity") {
                const { givenBright, givenContrast, givenHue, givenSaturate, givenTint, givenBlur, givenVignette } = this.state;

                const newBright = clampFilterValue(givenBright * 2, val);
                const newContrast = clampFilterValue(givenContrast * 2, val);
                const newHue = clampFilterValue(givenHue * 2, val);
                const newSaturate = clampFilterValue(givenSaturate * 2, val);
                const newTint = givenTint;
                const newBlur = clampFilterValue(givenBlur * 2, val);
                const newVignette = clampFilterValue(givenVignette * 2, val);

                if (this.svgItem) {
                    spontaneousFilters(this.svgItem, this.state.filterType, this.presetFilters, val, type, this.vignetteDOMId);
                }

                this.setState({
                    intensity: val,
                    bright: Math.round(newBright),
                    contrast: Math.round(newContrast),
                    hue: Math.round(newHue),
                    saturate: Math.round(newSaturate),
                    tint: Math.round(newTint),
                    blur: Math.round(newBlur),
                    vignette: Math.round(newVignette),

                    presetFilterCode:
                        this.convertToHex(Math.round(newBright) + 100) +
                        this.convertToHex(Math.round(newContrast) + 100) +
                        this.convertToHex(Math.round(newHue) + 100) +
                        this.convertToHex(Math.round(newSaturate) + 100) +
                        this.convertToHex(Math.round(newTint) + 100) +
                        this.convertToHex(Math.round(newBlur) + 100) +
                        this.convertToHex(Math.round(newVignette)) +
                        this.convertToHex(val),
                }, saveCallback);
            } else {
                if (this.svgItem) {
                    spontaneousFilters(this.svgItem, this.state.filterType, null, val, type, this.vignetteDOMId);
                }

                this.setState({ [type]: val }, saveCallback);
            }
        } catch (error) { }
    }

    toggleFilterWindow() {
        this.setState(prev => ({ filterWindowOpen: !prev.filterWindowOpen }));
    }

    setCurrentTab(tab) {
        this.setState({ currentTab: tab });
    }

    toggleApplyAllModal() {
        const prevApplyAllOpen = this.state.applyAllOpen;
        let applyAllOpen = !prevApplyAllOpen;
        let filterApplyAllUpdater = [];

        if (applyAllOpen) {
            filterApplyAllUpdater = this.getFilterApplyAllUpdater();
            applyAllOpen = filterApplyAllUpdater.length > 0;
        }

        this.setState({
            applyAllOpen,
            filterApplyAllUpdater,
        });
    }

    getFilterApplyAllUpdater() {
        const { selectedImage, workspaceItems, workspaceChildren } = this.props;
        const { currentFilterCode } = this.state;
        const updater = [];

        if (!selectedImage) {
            return updater;
        }

        const containers = {
            workspaceItems,
            workspaceChildren,
        };

        for (const container of Reflect.ownKeys(containers)) {
            const containerData = containers[container];

            for (let item of containerData.valueSeq()) {
                if (item.get("isSingleClipFrame")) {
                    item = singleClipFrameToFrame(item);
                }

                if (item.get("type") === "FRAME") {
                    for (const [clipId, clip] of item.get("clipDetails").entrySeq()) {
                        const imgDetails = clip.get("imgDetails");

                        const isImage = (
                            isImageOnly(selectedImage.get("type"), selectedImage.get("subType"))
                            && isImageOnly(imgDetails.get("type"), imgDetails.get("subType"))
                        );
                        const isVideo = (
                            isVideoOnly(selectedImage.get("type"), selectedImage.get("subType"))
                            && isVideoOnly(imgDetails.get("type"), imgDetails.get("subType"))
                        );
                        const filterDifferent = currentFilterCode !== imgDetails.get("filter");

                        if ((isImage || isVideo) && filterDifferent) {
                            updater.push({
                                container,
                                id: item.get("id"),
                                clipId,
                                data: { filter: currentFilterCode },
                            });
                        }
                    }
                } else {
                    const isImage = (
                        isImageOnly(selectedImage.get("type"), selectedImage.get("subType"))
                        && isImageOnly(item.get("type"), item.get("subType"))
                    );
                    const isVideo = (
                        isVideoOnly(selectedImage.get("type"), selectedImage.get("subType"))
                        && isVideoOnly(item.get("type"), item.get("subType"))
                    );
                    const filterDifferent = currentFilterCode !== item.get("filter");

                    if ((isImage || isVideo) && filterDifferent) {
                        updater.push({
                            container,
                            id: item.get("id"),
                            data: { filter: currentFilterCode },
                        });
                    }
                }
            }
        }

        return updater;
    }

    filterApplyAll() {
        const { filterApplyAllUpdater } = this.state;

        if (filterApplyAllUpdater.length) {
            this.props.applyFlip(filterApplyAllUpdater);
        }

        this.setState({
            applyAllOpen: false,
            filterApplyAllUpdater: [],
        });
    }

    render() {
        const { isAlwaysOpen, selectedImage, parentWindowLevel = 0 } = this.props;
        const { filterWindowOpen, currentTab, filterType } = this.state;
        const canCloseWindow = !isAlwaysOpen;
        const { theme } = this.props;
        const components = [];

        const isVideo = Boolean(
            selectedImage
            && isVideoOnly(selectedImage.get("type"), selectedImage.get("subType"))
        );
        const isGiphy = Boolean(
            selectedImage
            && isGiphyOnly(selectedImage.get("type"), selectedImage.get("subType"))
        );
        
        let adjustMsg = content.ADJUST_IMAGE;
        let applyAllMsg = content.APPLY_TO_ALL_IMAGES;
        let applyAllHeadingMsg = content.IMG_FILTER_APPLYALL_H;
        let backMsg = content.EDIT_IMAGE;
        if (isVideo) {
            adjustMsg = content.ADJUST_VIDEO;
            applyAllMsg = content.APPLY_TO_ALL_VIDEOS;
            applyAllHeadingMsg = content.VID_FILTER_APPLYALL_H;
            backMsg = content.EDIT_VIDEO;
        }

        components.push(
            <AdjustButtonStyled key="filter-button" onClick={this.toggleFilterWindow}>
                <img draggable={false} src={`${STATIC_PATH}adjust-filter.svg`} alt="adjust filter" />
                <FormattedMessage id={adjustMsg} />
            </AdjustButtonStyled>
        );

        if (filterWindowOpen) {
            let tab = null;

            if (currentTab === "filter-list") {
                const gridColumns = 3;
                let intensitySlider = null;
                let insertSliderAtIndex = null;
                let insertIndex = 0;
                const filterIcons = [];

                for (let iconIndex = 0; iconIndex < this.filterValues.size; iconIndex += 1) {
                    const filterIcon = this.filterValues.get(iconIndex);
                    const presetFilter = this.presetFilters.get(filterIcon);

                    if ((isVideo || isGiphy) && presetFilter.get("tint") !== 0) { // removing tint for video and giphy (Phase 2)
                        continue;
                    }

                    const iconPath = `${STATIC_PATH}filter-icons/${filterIcon.toLowerCase()}.png`;
                    const langId = `FILTER_${filterIcon.substr(0, 2).toLowerCase() + iconIndex}`;

                    let btnCls = "imgf-l--filter-btn";
                    const isActive = filterType === filterIcon;

                    if (isActive) {
                        btnCls = `${btnCls} filter-btn-active`;
                    }

                    if (isActive && filterIcon !== "ORIGINAL") {
                        insertSliderAtIndex = Math.ceil((insertIndex + 1) / gridColumns) * gridColumns;
                        intensitySlider = (
                            <div className="imgf-l--filter-slider">
                                <Slider
                                    min={0}
                                    max={100}
                                    inputMin={0}
                                    inputMax={100}
                                    step={1}
                                    value={this.state.intensity}
                                    onChangeSliderValue={(e, value) => this.handleInputChange(value, "intensity", false)}
                                    onMouseUp={this.handleSaveClick}
                                    border={"none"}
                                    borderRadius={"3px"}
                                    background={vmTheme[theme].veryLightGray}
                                    margin={"0"}
                                    height={"5px"}
                                    thumb={{
                                        background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                                        border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                                        height: "16px",
                                        width: "16px",
                                        hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                                        hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                                    }}
                                    progressBackground={vmTheme[theme].secondaryBorderColor}
                                    isChangeBg={true}
                                    showValue={false}
                                    showToolTip={true}
                                />
                            </div>
                        );
                    }

                    let slider = null;
                    if (insertSliderAtIndex === insertIndex) {
                        slider = intensitySlider;
                    }

                    filterIcons.push(
                        <React.Fragment key={filterIcon}>
                            {slider}
                            <div className={btnCls} onClick={() => this.filterClick(filterIcon)}>
                                <img draggable={false} src={iconPath} alt={filterIcon} />
                                <FormattedMessage id={content[langId]} />
                            </div>
                        </React.Fragment>
                    );

                    insertIndex = insertIndex + 1;
                }

                if (insertSliderAtIndex >= insertIndex && intensitySlider) {
                    filterIcons.push(
                        <React.Fragment key={"slider"}>
                            {intensitySlider}
                        </React.Fragment>
                    );
                }

                tab = (
                    <FilterList>
                        {filterIcons}
                    </FilterList>
                );
            } else if (currentTab === "filter-adjust") {
                const sliderList = this.adjustmentKeys.entrySeq().map(([stateKey, langId]) => {
                    if ((isVideo || isGiphy) && stateKey === "tint") { // removing tint for video and giphy (Phase 2)
                        return null;
                    }

                    let background = "#ECECEC";
                    let isChangeBg = true;

                    if (stateKey === "tint") {
                        background = "linear-gradient(to left, rgb(255, 0, 0) 0%, rgb(255, 0, 255) 16.66%, rgb(0, 0, 255) 33.33%, rgb(0, 255, 255) 50%, rgb(0, 255, 0) 66.66%, rgb(255, 255, 0) 83.33%, rgb(255, 0, 0) 100%)";
                        isChangeBg = false;
                    }

                    const sliderRange = { min: -100, max: 100 };
                    if (stateKey === "vignette") {
                        sliderRange.min = 0;
                    }

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <AdjustSliderContainer key={stateKey}>
                            <span><FormattedMessage id={langId} /></span>
                            <Slider
                                min={sliderRange.min}
                                max={sliderRange.max}
                                inputMin={sliderRange.min}
                                inputMax={sliderRange.max}
                                step={1}
                                value={this.state[stateKey]}
                                onChangeSliderValue={(e, value) => this.handleInputChange(value, stateKey, false)}
                                onMouseUp={this.handleSaveClick}
                                border={"none"}
                                borderRadius={"3px"}
                                background={background}
                                margin={"0"}
                                height={"5px"}
                                thumb={{
                                    background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                                    border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                                    height: "16px",
                                    width: "16px",
                                    hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                                    hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                                }}
                                progressBackground={vmTheme[theme].secondaryBorderColor}
                                isChangeBg={isChangeBg}
                                showValue={false}
                                showToolTip={true}
                                tooltipBottomOffset={"11px"}
                                doubleSide={true}
                            />
                        </AdjustSliderContainer>
                    );
                });

                let resetBtn = null;
                if (filterType && filterType !== "CUSTOM") {
                    resetBtn = (
                        <p className="imgf-a--reset" onClick={() => this.filterClick(filterType)}>
                            <FormattedMessage id={content.RESET_ALL} />
                        </p>
                    );
                }

                tab = (
                    <AdjustmentList>
                        {resetBtn}
                        {sliderList}
                    </AdjustmentList>
                );
            }

            components.push(
                <ImageFilterContainer key="filter-window" $windowLevel={parentWindowLevel + 1}>
                    <div
                        className="imgf--header"
                        onClick={canCloseWindow ? this.toggleFilterWindow : undefined}
                        style={{
                            cursor: !canCloseWindow ? "default" : undefined,
                        }}
                    >
                        {
                            canCloseWindow && (
                                <img draggable={false} src={`${STATIC_PATH}downArrow.svg`} alt="back" />
                            )
                        }
                        <FormattedMessage id={backMsg} />
                    </div>
                    <FilterTabList
                        currentTab={currentTab}
                        setCurrentTab={this.setCurrentTab}
                    />
                    <ApplyAll onClick={this.toggleApplyAllModal} style={{margin: "16px"}}>
                        <FormattedMessage id={applyAllMsg} />
                    </ApplyAll>
                    <div className="imgf--body">
                        {tab}
                    </div>
                    <Modal
                        isDisabled={true}
                        onClose={this.toggleApplyAllModal}
                        showModal={this.state.applyAllOpen}
                        useWrapper={false}
                    >
                        <ApplyAllContainer>
                            <p className="apply-all--heading">
                                <FormattedMessage id={applyAllHeadingMsg} />
                            </p>
                            <div className="apply-all--btn-grp">
                                <div
                                    className="apply-all--btn apply-all--cancel"
                                    onClick={this.toggleApplyAllModal}
                                >
                                    <FormattedMessage id={content.CANCEL} />
                                </div>
                                <div
                                    className="apply-all--btn apply-all--apply"
                                    onClick={this.filterApplyAll}
                                >
                                    <FormattedMessage id={content.APPLY_ALL} />
                                </div>
                            </div>
                        </ApplyAllContainer>
                    </Modal>
                </ImageFilterContainer>
            );
        }

        return components;
    }
}

ImageFilterComponent.propTypes = {
    selectionContainer: PropTypes.string,
    workspaceItem: PropTypes.object,
    selectedFrameClip: PropTypes.object,
    itemFilterData: PropTypes.string,
    selectedImage: PropTypes.object,
    workspaceItems: PropTypes.object,
    workspaceChildren: PropTypes.object,
    applyFlip: PropTypes.func,
    parentWindowLevel: PropTypes.number,
    isAlwaysOpen: PropTypes.bool,
    theme: PropTypes.string,
};

const mapStateToProps = () => {
    const memoizedFunction = createMemoizedFunction();

    return (state) => {
        const container = getSelectionContainer({
            childrenSelection: state.app.get("childrenSelection"),
            selectedItems: state.app.get("selectedItems"),
        });
        const selectedItems = getSelectedItemIds({
            childrenSelection: state.app.get("childrenSelection"),
            selectedItems: state.app.get("selectedItems")
        });
        const selectedFrameClip = state.app.get("selectedFrameClip");
        const orgWorkspaceItem = state.projectDetails.getIn([container, selectedItems.first()]);

        const { itemFilterData, selectedImage, workspaceItem } = memoizedFunction(() => {
            let workspaceItem = orgWorkspaceItem;
            if (workspaceItem && workspaceItem.get("isSingleClipFrame")) {
                workspaceItem = singleClipFrameToFrame(workspaceItem);
            }

            let itemFilterData = ITEM_CONFIG.IMAGE.DEFAULT_FILTER; // default filter code
            let selectedImage = workspaceItem;

            if (workspaceItem) {
                if (workspaceItem.get("type") === "FRAME") {
                    if (selectedFrameClip.get("clipId")) { // clip is selected
                        const imgDetails = workspaceItem.getIn(["clipDetails", selectedFrameClip.get("clipId"), "imgDetails"]);
                        itemFilterData = imgDetails.get("filter");
                        selectedImage = imgDetails;
                    }
                } else {
                    itemFilterData = workspaceItem.get("filter");
                }
            }

            return { itemFilterData, selectedImage, workspaceItem };
        }, [orgWorkspaceItem, selectedFrameClip]);

        return {
            theme: state.app.get("theme"),
            selectionContainer: container,
            selectedItems,
            selectedFrameClip: state.app.get("selectedFrameClip"),
            workspaceItems: state.projectDetails.get("workspaceItems"),
            workspaceChildren: state.projectDetails.get("workspaceChildren"),
            workspaceItem,
            itemFilterData,
            selectedImage,
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    applyFlip: (data) => dispatch(applyFlip(data)),
});

const ImageFilter = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImageFilterComponent));

export default ImageFilter;
