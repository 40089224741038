/* eslint-disable camelcase, no-continue */
/// <reference path="./timeline-types.js" />
/* eslint-disable operator-assignment, no-restricted-syntax */

import { Set, fromJS, isImmutable } from "immutable";
import { DEFAULT_DURATION, RULER_OPTIONS } from "./timeline-constants";
import {
  buildLocalSubtitles,
  isAudioOnly,
  isImageOnly,
  isUpImageSVG,
  isVideoOnly,
  randomString,
} from "./timeline-helper";
import { applyCropToDropItem } from "../../helper/TransformManagerHelper";

/**
 * function to handle track change if items are moved to new track
 * @param {HandleNewTracksParams} params
 */
const handleNewTracks = (params = {}) => {
  const { newTracks } = params;
  let { updaterObject } = params;
  let { project } = params;

  for (const container of Reflect.ownKeys(newTracks)) {
    /** indices of new tracks in ascending order */
    const trackIndices = Reflect.ownKeys(newTracks[container]);
    const items = project.get(container);
    let newItems = items;

    for (const newTrackIndex of trackIndices) {
      const newTrackDetail = newTracks[container][newTrackIndex];
      const alreadyMovedItemIds = newTrackDetail.ids;

      for (const [itemId, item] of items.entrySeq()) {
        if (
          item.get("track") >= newTrackDetail.trackIndex &&
          !alreadyMovedItemIds.includes(itemId)
        ) {
          const newTrack = item.get("track") + 1;
          newItems = newItems.setIn([itemId, "track"], newTrack);
          updaterObject = updaterObject.setIn(
            [container, itemId, "data", "track"],
            newTrack
          );
        }
      }
    }

    project = project.set(container, newItems);
  }

  return {
    project,
    updaterObject,
  };
};

/**
 * removes overlap of items timing (e.g. when an item is moved to different track)
 * @param {TrackChangeItemOverlap} params
 */
const trackChangeItemOverlap = (params = {}) => {
  const { movedItemIds, items } = params;
  let { updaterObject } = params;
  let newItems = items;

  const itemIdsGroupedByTracks = {};

  for (const movedItemId of movedItemIds) {
    const movedItem = items.get(movedItemId);
    const trackIndex = movedItem.get("track");

    let currentTrackItemIds = itemIdsGroupedByTracks[trackIndex];
    if (!currentTrackItemIds) {
      currentTrackItemIds = newItems
        .filter((item) => item.get("track") === trackIndex)
        .keySeq()
        .toArray();
      itemIdsGroupedByTracks[trackIndex] = currentTrackItemIds;
    }

    for (const itemId of currentTrackItemIds) {
      if (itemId !== movedItemId && newItems.get(itemId)) {
        const item = newItems.get(itemId);

        let left;
        let right;
        let leftUpdater = fromJS({});
        let rightUpdater = leftUpdater;

        let isOverlapping = !(
          movedItem.get("enterStart") >= item.get("exitEnd") || // moved item is after current item and does not overlap
          // moved item is before current item and does not overlap
          movedItem.get("exitEnd") <= item.get("enterStart")
        );

        if (
          item.get("enterStart") < movedItem.get("enterStart") &&
          movedItem.get("enterStart") < item.get("exitEnd")
        ) {
          left = item;

          const leftExitEnd = movedItem.get("enterStart");
          if (leftExitEnd !== left.get("exitEnd")) {
            left = item.set("exitEnd", leftExitEnd);
            leftUpdater = leftUpdater.set("exitEnd", leftExitEnd);
          }

          if (isVideoOnly(left.get("type"), left.get("subType"))) {
            const videoEnd =
              left.get("videoStart") +
              (left.get("exitEnd") - left.get("enterStart"));
            if (videoEnd !== left.get("videoEnd")) {
              left = left.set("videoEnd", videoEnd);
              leftUpdater = leftUpdater.set("videoEnd", videoEnd);
            }
          }

          if (
            left.get("exitEnd") - left.get("enterStart") <
            RULER_OPTIONS.interval
          ) {
            left = undefined;
            leftUpdater = undefined;
          } else {
            // check and handle effects
            if (
              left.get("enterEffectName") &&
              left.get("enterEffectName") !== "no_Effect" &&
              left.get("enterEnd") > movedItem.get("enterStart")
            ) {
              const enterEnd = movedItem.get("enterStart");
              if (enterEnd !== left.get("enterEnd")) {
                left = left.set("enterEnd", enterEnd);
                leftUpdater = leftUpdater.set("enterEnd", enterEnd);
              }

              if (
                left.get("enterEnd") - left.get("enterStart") <
                RULER_OPTIONS.interval
              ) {
                left = left
                  .set("enterEffectName", "no_Effect")
                  .set("enterEnd", 0);
                leftUpdater = leftUpdater
                  .set("enterEffectName", "no_Effect")
                  .set("enterEnd", 0);
              }
            }

            if (
              left.get("exitEffectName") &&
              left.get("exitEffectName") !== "no_Effect" &&
              left.get("exitEnd") - left.get("exitStart") <
              RULER_OPTIONS.interval
            ) {
              left = left
                .set("exitEffectName", "no_Effect")
                .set("exitStart", 0);
              leftUpdater = leftUpdater
                .set("exitEffectName", "no_Effect")
                .set("exitStart", 0);
            }
          }
          isOverlapping = true;
        }

        if (
          item.get("enterStart") < movedItem.get("exitEnd") &&
          movedItem.get("exitEnd") < item.get("exitEnd")
        ) {
          const isSpeed = updaterObject.getIn(["workspaceItems", movedItem.get("id"), "data", "speed"])
          if (isSpeed) {    // If speed applied for current item, Don't allow item overlap
            updaterObject = updaterObject.setIn(
              ["workspaceItems", movedItem.get("id"), "data", "exitEnd"],
              item.get("enterStart")
            );
            isOverlapping = false;
          } else {
            right = item;

            const enterStart = movedItem.get("exitEnd");
            if (enterStart !== item.get("enterStart")) {
              right = item.set("enterStart", enterStart);
              rightUpdater = rightUpdater.set("enterStart", enterStart);
            }
  
            if (isVideoOnly(right.get("type"), right.get("subType"))) {
              const videoStart =
                right.get("videoEnd") -
                (right.get("exitEnd") - right.get("enterStart"));
  
              if (videoStart !== right.get("videoStart")) {
                right = right.set("videoStart", videoStart);
                rightUpdater = rightUpdater.set("videoStart", videoStart);
              }
            }
  
            if (
              right.get("exitEnd") - right.get("enterStart") <
              RULER_OPTIONS.interval
            ) {
              right = undefined;
              rightUpdater = undefined;
            } else {
              // check and handle effects
              if (
                right.get("exitEffectName") &&
                right.get("exitEffectName") !== "no_Effect" &&
                right.get("exitStart") < movedItem.get("exitEnd")
              ) {
                const exitStart = movedItem.get("exitEnd");
                if (exitStart !== right.get("exitStart")) {
                  right = right.set("exitStart", movedItem.get("exitEnd"));
                  rightUpdater = rightUpdater.set(
                    "exitStart",
                    movedItem.get("exitEnd")
                  );
                }
                if (
                  right.get("exitEnd") - right.get("exitStart") <
                  RULER_OPTIONS.interval
                ) {
                  right = right
                    .set("exitEffectName", "no_Effect")
                    .set("exitStart", 0);
                  rightUpdater = rightUpdater
                    .set("exitEffectName", "no_Effect")
                    .set("exitStart", 0);
                }
              }
  
              if (
                right.get("enterEffectName") &&
                right.get("enterEffectName") !== "no_Effect" &&
                right.get("enterEnd") - right.get("enterStart") <
                RULER_OPTIONS.interval
              ) {
                right = right
                  .set("enterEffectName", "no_Effect")
                  .set("enterEnd", 0);
                rightUpdater = rightUpdater
                  .set("enterEffectName", "no_Effect")
                  .set("enterEnd", 0);
              }
            }
            isOverlapping = true;
          }
        }

        if (isOverlapping) {
          if (left && right && leftUpdater && rightUpdater) {
            right = right.set("id", randomString("split"));
            newItems = newItems
              .set(left.get("id"), left)
              .set(right.get("id"), right);
            currentTrackItemIds.push(right.get("id"));

            if (leftUpdater.size > 0) {
              const prevLeftUpdater = updaterObject.getIn(["workspaceItems", left.get("id"), "data"]);
              if (prevLeftUpdater) {
                leftUpdater = prevLeftUpdater.mergeDeep(leftUpdater);
              }
              updaterObject = updaterObject.setIn(
                ["workspaceItems", left.get("id"), "data"],
                leftUpdater
              );
            }
            updaterObject = updaterObject.setIn(
              ["workspaceItems", right.get("id"), "isNew"],
              true
            );
            updaterObject = updaterObject.setIn(
              ["workspaceItems", right.get("id"), "isSplit"],
              true
            );
            updaterObject = updaterObject.setIn(
              ["workspaceItems", right.get("id"), "data"],
              right
            );
          } else if (!left && !right) {
            newItems = newItems.delete(item.get("id"));
            updaterObject = updaterObject.setIn(
              ["workspaceItems", item.get("id"), "isDelete"],
              true
            );
          } else if ((left && leftUpdater) || (right && rightUpdater)) {
            let updatedItem = left;
            let updater = leftUpdater;

            if (right && rightUpdater) {
              updatedItem = right;
              updater = rightUpdater;
            }

            newItems = newItems.set(updatedItem.get("id"), updatedItem);
            if (updater.size > 0) {
              const prevUpdater = updaterObject.getIn(["workspaceItems", updatedItem.get("id"), "data"]);
              if (prevUpdater) {
                updater = prevUpdater.mergeDeep(updater);
              }
              updaterObject = updaterObject.setIn(
                ["workspaceItems", updatedItem.get("id"), "data"],
                updater
              );
            }
          }
        }
      }
    }
  }

  return {
    newItems,
    updaterObject,
  };
};

/**
 * removes overlap of media timing
 * (e.g. when an audio is moved to different track or obj is moved to video track)
 * @param {TrackChangeMediaOverlap} params
 */
const trackChangeMediaOverlap = (params = {}) => {
  const { movedMediaIds, mediaData, container } = params;
  let { updaterObject } = params;
  let newMedia = mediaData;

  const mediaIdsGroupedByTracks = {};
  let mediaStart = "musicStart";
  let mediaEnd = "musicEnd";

  if (container === "workspaceBG") {
    mediaStart = "videoStart";
    mediaEnd = "videoEnd";
  }

  for (const movedMediaId of movedMediaIds) {
    const movedMedia = mediaData.get(movedMediaId);
    const trackIndex =
      container === "workspaceBG" ? 0 : movedMedia.get("track");

    let currentTrackMediaIds = mediaIdsGroupedByTracks[trackIndex];
    if (!currentTrackMediaIds) {
      if (container === "workspaceBG") {
        currentTrackMediaIds = newMedia.keySeq().toArray();
      } else {
        currentTrackMediaIds = newMedia
          .filter((item) => item.get("track") === trackIndex)
          .keySeq()
          .toArray();
      }
      mediaIdsGroupedByTracks[trackIndex] = currentTrackMediaIds;
    }

    for (const mediaId of currentTrackMediaIds) {
      if (mediaId !== movedMediaId && newMedia.get(mediaId)) {
        const media = newMedia.get(mediaId);

        let left;
        let right;
        let leftUpdater = fromJS({});
        let rightUpdater = leftUpdater;

        let isOverlapping = !(
          // moved media is after current media and does not overlap
          (
            movedMedia.get("playStart") >= media.get("playEnd") ||
            // moved media is before current media and does not overlap
            movedMedia.get("playEnd") <= media.get("playStart")
          )
        );

        if (
          media.get("playStart") < movedMedia.get("playStart") &&
          movedMedia.get("playStart") < media.get("playEnd")
        ) {
          left = media;
          const playEnd = movedMedia.get("playStart");

          if (playEnd !== left.get("playEnd")) {
            left = media.set("playEnd", playEnd);
            leftUpdater = leftUpdater.set("playEnd", playEnd);
          }

          const mediaEndTime =
            left.get(mediaStart) +
            (left.get("playEnd") - left.get("playStart"));

          if (mediaEndTime !== left.get(mediaEnd)) {
            left = left.set(mediaEnd, mediaEndTime);
            leftUpdater = leftUpdater.set(mediaEnd, mediaEndTime);
          }

          if (
            left.get("playEnd") - left.get("playStart") <
            RULER_OPTIONS.interval
          ) {
            left = undefined;
            leftUpdater = undefined;
          }
          isOverlapping = true;
        }

        if (
          media.get("playStart") < movedMedia.get("playEnd") &&
          movedMedia.get("playEnd") < media.get("playEnd")
        ) {
          right = media;
          const playStart = movedMedia.get("playEnd");

          if (playStart !== media.get("playStart")) {
            right = media.set("playStart", playStart);
            rightUpdater = rightUpdater.set("playStart", playStart);
          }

          const mediaStartTime =
            right.get(mediaEnd) -
            (right.get("playEnd") - right.get("playStart"));
          if (mediaStartTime !== right.get(mediaStart)) {
            right = right.set(mediaStart, mediaStartTime);
            rightUpdater = rightUpdater.set(mediaStart, mediaStartTime);
          }

          if (
            right.get("playEnd") - right.get("playStart") <
            RULER_OPTIONS.interval
          ) {
            right = undefined;
          }
          isOverlapping = true;
        }

        if (isOverlapping) {
          if (left && right && leftUpdater && rightUpdater) {
            right = right.set("id", randomString("split"));
            newMedia = newMedia
              .set(left.get("id"), left)
              .set(right.get("id"), right);
            currentTrackMediaIds.push(right.get("id"));

            if (leftUpdater.size > 0) {
              updaterObject = updaterObject.setIn(
                [container, left.get("id"), "data"],
                leftUpdater
              );
            }
            updaterObject = updaterObject.setIn(
              [container, right.get("id"), "isNew"],
              true
            );
            updaterObject = updaterObject.setIn(
              [container, right.get("id"), "isSplit"],
              true
            );
            updaterObject = updaterObject.setIn(
              [container, right.get("id"), "data"],
              right
            );
          } else if (!left && !right) {
            newMedia = newMedia.delete(media.get("id"));
            updaterObject = updaterObject.setIn(
              [container, media.get("id"), "isDelete"],
              true
            );
          } else if ((left && leftUpdater) || (right && rightUpdater)) {
            let updatedItem = left;
            let updater = leftUpdater;

            if (right && rightUpdater) {
              updatedItem = right;
              updater = rightUpdater;
            }

            newMedia = newMedia.set(updatedItem.get("id"), updatedItem);
            if (updater.size > 0) {
              updaterObject = updaterObject.setIn(
                [container, updatedItem.get("id"), "data"],
                updater
              );
            }
          }
        }
      }
    }
  }

  return {
    newMedia,
    updaterObject,
  };
};

/**
 * Use this function to remove empty tracks if an item's track is changed
 * @param {RemoveEmptyTracksParams} params
 */
const removeEmptyTracks = (params = {}) => {
  const { items, container } = params;
  let { updaterObject } = params;

  let trackToAssign = 0;
  let prevTrack = null;
  const sortedItems = items
    .sortBy((item) => item.get("track"))
    .map((item) => {
      if (prevTrack === null) {
        // first item
        prevTrack = item.get("track");
      }
      if (prevTrack !== item.get("track")) {
        trackToAssign = trackToAssign + 1;
        prevTrack = item.get("track");
      }
      if (trackToAssign !== item.get("track")) {
        item = item.set("track", trackToAssign);
        updaterObject = updaterObject.setIn(
          [container, item.get("id"), "data", "track"],
          trackToAssign
        );
      }
      return item;
    });

  return {
    items: sortedItems,
    updaterObject,
  };
};

/**
 * function to get duration of project by traversing all items in project
 * @param {GetProjectDurationParams} params
 */
export const getProjectDuration = (params = {}) => {
  const { project } = params;
  let duration = 0;

  for (const item of project.get("workspaceItems").valueSeq()) {
    if (item.get("exitEnd") > duration) {
      duration = item.get("exitEnd");
    }
  }

  for (const mediaContainer of ["audios", "workspaceBG"]) {
    for (const media of project.get(mediaContainer).valueSeq()) {
      if (media.get("playEnd") > duration) {
        duration = media.get("playEnd");
      }
    }
  }

  if (duration === 0) {
    // if duration is 0 then probably no item is in the project, keep default duraiton
    duration = RULER_OPTIONS.projectMinDuration;
  }

  return duration;
};

/**
 * Function to find which track has enough space for given time
 * @param {object} params
 * @param {number} params.startTime
 * @param {number} params.endTime
 * @param {"workspaceItems" | "audios"} params.container
 * @param {object} params.projectDetails
 * @param {"last" | "first"} params.order whether to insert at first or last track
 */
const getDropTrack = (params) => {
  const {
    container,
    endTime,
    projectDetails,
    startTime,
    order = "last",
  } = params;

  const containerData = projectDetails.get(container);

  if (containerData.size === 0) {
    return {
      track: 0,
      isNewTrack: false,
    };
  }

  let startKey = "playStart";
  let endKey = "playEnd";
  if (container === "workspaceItems") {
    startKey = "enterStart";
    endKey = "exitEnd";
  }

  let track = null;

  const sortedItems = containerData.sortBy((item) => item.get(startKey));
  const tracks = sortedItems
    .groupBy((item) => item.get("track"))
    .sortBy((trackItems, trackIndex) => {
      trackIndex = Number(trackIndex);
      return trackIndex;
    });

  const trackKeySeq = tracks.keySeq();
  const firstTrackKey = trackKeySeq.first();
  const lastTrackKey = trackKeySeq.last();
  const trackKey = order === "last" ? lastTrackKey : firstTrackKey;
  const trackItems = tracks.get(trackKey);
  const lastItem = trackItems.last();
  let prevEnd = 0;

  if (lastItem && startTime >= lastItem.get(endKey)) {
    track = Number(trackKey);
  } else {
    for (const item of tracks.get(trackKey).valueSeq()) {
      const curStart = item.get(startKey);
      const curEnd = item.get(endKey);

      if (startTime >= prevEnd && endTime <= curStart) {
        track = Number(trackKey);
        break;
      }

      prevEnd = curEnd;
    }
  }

  let isNewTrack = false;
  if (track === null && order === "last") {
    track = Number(lastTrackKey) + 1;
    isNewTrack = true;
  } else if (track === null && order === "first") {
    track = Number(firstTrackKey) - 1;
    if (track < 0) {
      track = 0;
    }
    isNewTrack = true;
  }

  return {
    isNewTrack,
    track,
  };
};

/**
 * Function to library replace
 * @param {object} params
 * @param {object} params.item
 * @param {object} params.newItem
 * @param {boolean | undefined} params.isNoCrop
 */
const replaceWorkspaceItem = (params = {}) => {
  const { item, isNoCrop = false } = params;
  let { newItem } = params;
  const isEnableCrop =
    !isNoCrop &&
    ((isVideoOnly(newItem.get("type"), newItem.get("subType")) &&
      isVideoOnly(item.get("type"), item.get("subType")) &&
      !newItem.get("isSingleClipFrame") &&
      !isUpImageSVG(item) &&
      !isUpImageSVG(newItem)) ||
      (isImageOnly(item.get("type"), item.get("subType")) &&
        isImageOnly(newItem.get("type"), newItem.get("subType")) &&
        !newItem.get("isSingleClipFrame") &&
        !isUpImageSVG(item) &&
        !isUpImageSVG(newItem)));

  const keysToCopy = [
    "enterStart",
    "exitEnd",
    "enterEnd",
    "exitStart",
    "enterEffectName",
    "exitEffectName",
    "angle",
    "flipPosition",
    "opacity",
    "track",
  ];
  for (const key of keysToCopy) {
    newItem = newItem.set(key, item.get(key));
  }

  if (isVideoOnly(newItem.get("type"), newItem.get("subType"))) {
    let itemDuration = newItem.get("exitEnd") - newItem.get("enterStart");
    const playDuration = newItem.get("videoEnd") - newItem.get("videoStart");

    if (playDuration < itemDuration) {
      newItem = newItem.set("exitEnd", newItem.get("enterStart") + playDuration);
    }

    itemDuration = newItem.get("exitEnd") - newItem.get("enterStart");

    if (playDuration > itemDuration) {
      newItem = newItem.set("videoEnd", newItem.get("videoStart") + itemDuration);
    }

    if (item.get("transitionEnterEffect")) {
      newItem = newItem.set("transitionEnterEffect", item.get("transitionEnterEffect"));
    }

    if (item.get("transitionExitEffect")) {
      newItem = newItem.set("transitionExitEffect", item.get("transitionExitEffect"));
    }

    if (item.get("transitionEnterId")) {
      newItem = newItem.set("transitionEnterId", item.get("transitionEnterId"));
    }

    if (item.get("transitionExitId")) {
      newItem = newItem.set("transitionExitId", item.get("transitionExitId"));
    }

    if (item.get("transitionDuration")) {
      newItem = newItem.set("transitionDuration", item.get("transitionDuration"));
    }

    if (item.get("isTransition")) {
      newItem = newItem.set("isTransition", item.get("isTransition"));
    }

    if (item.get("speed")) {
      newItem = newItem.set("speed", item.get("speed"));
    }

    if (!newItem.get("isSingleClipFrame")) {
      newItem = newItem.set("volume", item.get("volume"));
    }
  }

  if (isEnableCrop) {
    const dropItem = {
      libAssetWidth: newItem.get("width"),
      libAssetHeight: newItem.get("height"),
    };
    const targetPlot = {
      x: item.get("x"),
      y: item.get("y"),
      width: item.get("width"),
      height: item.get("height"),
    };
    const { drop_item_original, item_plot } = applyCropToDropItem(dropItem, targetPlot);

    newItem = newItem.set("x", item_plot.x);
    newItem = newItem.set("y", item_plot.y);
    newItem = newItem.set("width", item_plot.w);
    newItem = newItem.set("height", item_plot.h);
    newItem = newItem.set("original", fromJS(drop_item_original));
    newItem = newItem.set("isCropped", true);
  }

  if (item.get("radius")) {
    newItem = newItem.set("radius", item.get("radius"));
  }

  if (item.get("filter") && isImageOnly(newItem.get("type"), newItem.get("subType"))) {
    newItem = newItem.set("filter", item.get("filter"));
  }

  if (newItem.get("exitEffectName") && newItem.get("exitEffectName") !== "no_Effect") {
    if (newItem.get("exitEnd") - newItem.get("exitStart") < RULER_OPTIONS.interval) {
      newItem = newItem.set("exitEffectName", "no_Effect").set("exitStart", 0);
    }
  }

  if (newItem.get("enterEffectName") && newItem.get("enterEffectName") !== "no_Effect") {
    if (newItem.get("enterEnd") > newItem.get("exitEnd")) {
      newItem = newItem.set("enterEnd", newItem.get("exitEnd"));
    }
    if (newItem.get("enterEnd") - newItem.get("enterStart") < RULER_OPTIONS.interval) {
      newItem = newItem.set("enterEffectName", "no_Effect").set("enterEnd", 0);
    }
  }

  return newItem;
};

/**
 * Function to replace audio.
 * @param {item: object, newItem: object} params
 * @returns newItem object
 */
const replaceAudioItem = (params = {}) => {
  const { item } = params;
  let { newItem } = params;

  const keysToCopy = [
    "playStart",
    "playEnd",
    "volume",
    "fadeDetails",
    "track",
  ];
  for (const key of keysToCopy) {
    newItem = newItem.set(key, item.get(key));
  }

  let itemDuration = newItem.get("playEnd") - newItem.get("playStart");
  const playDuration = newItem.get("musicEnd") - newItem.get("musicStart");

  if (playDuration < itemDuration) {
    newItem = newItem.set("playEnd", newItem.get("playStart") + playDuration);
  }

  itemDuration = newItem.get("playEnd") - newItem.get("playStart");

  if (playDuration > itemDuration) {
    newItem = newItem.set("musicEnd", newItem.get("musicStart") + itemDuration);
  }

  return newItem;
}

/**
 * Function to handle autosave for timeline trim/move
 * @param {object} params
 * @param {object} params.action action object of timeline update dispatch
 * @param {object} params.projectDetails project state from redux
 * @param {number} params.playhead playhead time from redux
 * @param {number} params.willEmit whether returned object will be sent through socket
 * @returns {object}
 */
export const getTimelineUpdater = (params = {}) => {
  const { action, projectDetails, playhead, willEmit } = params;
  const { payload } = action;

  let newProjectDetails = projectDetails;

  let mayItemsOverlap = false;
  let checkItemEmptyTracks = false;
  const movedItemIds = [];

  let mayAudiosOverlap = false;
  let checkAudioEmptyTracks = false;
  const movedAudioIds = [];

  let mayVideosOverlap = false;
  const movedVideoIds = [];

  const newTracks = {};

  let removedMediaDropIds = fromJS({});
  let subtitleItemDeleteDropIds = Set();

  let subtitleList = null;

  let updaterObject = fromJS({});
  payload.toUpdate.forEach((itemUpdate) => {
    if (itemUpdate.container === "subtitleGlobal") {
      Object.entries(itemUpdate.toUpdate).forEach(([key, value]) => {
        let subtitleStyleData = newProjectDetails.getIn(["subtitle", key]);
        subtitleStyleData = subtitleStyleData ? subtitleStyleData.merge(fromJS(value)) : fromJS(value);
        updaterObject = updaterObject.setIn(["subtitle", itemUpdate.container, key], subtitleStyleData);
      })
    } else if (itemUpdate.container === "subtitleDropData") {
      if (itemUpdate.isAdd) {
        const newDropData = fromJS(itemUpdate.newItemData);

        newProjectDetails = newProjectDetails.setIn(
          ["subtitle", "data", itemUpdate.dropId],
          newDropData
        );

        const subtitleUpdater = fromJS({
          isNew: true,
          data: newDropData,
        });

        updaterObject = updaterObject.setIn(
          [
            "subtitle",
            itemUpdate.container,
            itemUpdate.dropId,
          ],
          subtitleUpdater
        );
      } else if (itemUpdate.isDelete) {
        newProjectDetails = newProjectDetails.deleteIn([
          "subtitle",
          "data",
          itemUpdate.dropId,
        ]);
        const subtitleUpdater = fromJS({
          isDelete: true,
        });
        updaterObject = updaterObject.setIn(
          [
            "subtitle",
            itemUpdate.container,
            itemUpdate.dropId,
          ],
          subtitleUpdater
        );
      } else {
        // if you ever need to implement this else case, you probably need to use `subtitleData` container to add/remove items, not this container
      }
    } else if (itemUpdate.container === "subtitleData") {
      if (itemUpdate.isAdd) {
        const newItem = fromJS(itemUpdate.newItemData);

        newProjectDetails = newProjectDetails.setIn(
          ["subtitle", "data", itemUpdate.dropId, itemUpdate.id],
          newItem
        );

        let subtitleUpdater = fromJS({
          timelineId: itemUpdate.timelineId,
          isNew: true,
          data: newItem,
        });
        if (itemUpdate.isSplit) {
          subtitleUpdater = subtitleUpdater.set("isSplit", true);
        }
        if (itemUpdate.isReplace) {
          subtitleUpdater = subtitleUpdater.set("isReplace", true);
        }

        updaterObject = updaterObject.setIn(
          [
            "subtitle",
            itemUpdate.container,
            itemUpdate.dropId,
            itemUpdate.id,
          ],
          subtitleUpdater
        );
      } else if (itemUpdate.isDelete) {
        newProjectDetails = newProjectDetails.deleteIn([
          "subtitle",
          "data",
          itemUpdate.dropId,
          itemUpdate.id,
        ]);
        const subtitleUpdater = fromJS({
          timelineId: itemUpdate.timelineId,
          isDelete: true,
        });
        updaterObject = updaterObject.setIn(
          [
            "subtitle",
            itemUpdate.container,
            itemUpdate.dropId,
            itemUpdate.id,
          ],
          subtitleUpdater
        );
        subtitleItemDeleteDropIds = subtitleItemDeleteDropIds.add(itemUpdate.dropId);
      } else {
        let item = newProjectDetails.getIn([
          "subtitle",
          "data",
          itemUpdate.dropId,
          itemUpdate.id,
        ]);
        let subtitleUpdater = fromJS({
          timelineId: itemUpdate.timelineId,
          data: {},
        });

        Reflect.ownKeys(itemUpdate.toUpdate).forEach((updateKey) => {
          const updateVal = itemUpdate.toUpdate[updateKey];
          item = item.set(updateKey, updateVal);

          subtitleUpdater = subtitleUpdater.setIn(
            ["data", updateKey],
            updateVal
          );
        });

        if (subtitleUpdater.get("data").size) {
          updaterObject = updaterObject.setIn(
            [
              "subtitle",
              itemUpdate.container,
              itemUpdate.dropId,
              itemUpdate.id,
            ],
            subtitleUpdater
          );
        }

        newProjectDetails = newProjectDetails.setIn(
          [itemUpdate.container, "data", itemUpdate.dropId, itemUpdate.id],
          item
        );
      }
    } else if (itemUpdate.container === "subtitleList") {
      if (!subtitleList) {
        subtitleList = newProjectDetails.get("subtitleData");
      }
      if (itemUpdate.isAdd) {
        subtitleList = subtitleList.push(fromJS(itemUpdate.data));
      } else if (itemUpdate.isDelete) {
        subtitleList = subtitleList.filter(
          (entry) => entry.get("subtitleId") !== itemUpdate.id
        );
      } else {
        const index = subtitleList.findIndex(
          (entry) => entry.get("subtitleId") === itemUpdate.id
        );
        if (index >= 0) {
          const entry = subtitleList.get(index).merge(fromJS(itemUpdate.data));
          subtitleList = subtitleList.set(index, entry);
        }
      }
      newProjectDetails = newProjectDetails.set("subtitleData", subtitleList);
    } else if (itemUpdate.isAdd) {
      let newItem = fromJS(itemUpdate.newItemData);

      if (itemUpdate.isLibraryDrop || itemUpdate.isAudioDetach) {
        let startTime = 0;
        let endTime = 0;

        if (itemUpdate.isAudioDetach) {
          startTime = newItem.get("playStart");
          endTime = newItem.get("playEnd");
        } else if (itemUpdate.container === "workspaceItems") {
          newItem = newItem.set("enterStart", playhead);

          let duration;
          // let duration = projectDetails.get("duration") - playhead;
          if (isVideoOnly(newItem.get("type"), newItem.get("subType"))) {
            duration = newItem.get("videoDuration");
          } else if (isAudioOnly(newItem.get("type"))) {
            duration = newItem.get("musicDuration");
          } else {
            duration = DEFAULT_DURATION;
          }
          newItem = newItem.set("exitEnd", playhead + duration);

          newItem = newItem.set("enterEnd", 0);
          newItem = newItem.set("exitStart", 0);
          newItem = newItem.set("enterEffectName", "no_Effect");
          newItem = newItem.set("exitEffectName", "no_Effect");

          startTime = newItem.get("enterStart");
          endTime = newItem.get("exitEnd");
        } else {
          newItem = newItem.set("playStart", playhead);
          newItem = newItem.set(
            "playEnd",
            playhead + newItem.get("musicDuration")
          );

          startTime = newItem.get("playStart");
          endTime = newItem.get("playEnd");
        }

        const { track, isNewTrack } = getDropTrack({
          container: itemUpdate.container,
          startTime,
          endTime,
          order: "last",
          projectDetails,
        });
        newItem = newItem.set("track", track);

        if (
          isNewTrack
          && (
            itemUpdate.container === "workspaceItems"
            || itemUpdate.container === "audios"
          )
        ) {
          if (!newTracks[itemUpdate.container]) {
            newTracks[itemUpdate.container] = {};
          }
          if (!newTracks[itemUpdate.container][track]) {
            newTracks[itemUpdate.container][track] = {
              trackIndex: track,
              ids: [],
            };
          }
          newTracks[itemUpdate.container][track].ids.push(
            itemUpdate.id
          );
        }
      } else if (itemUpdate.isWorkspaceReplace) {
        let prevItem = newProjectDetails.getIn([
          itemUpdate.container,
          itemUpdate.id,
        ]);
        if (itemUpdate.swapDetails) {
          prevItem = itemUpdate.swapDetails;
        }
        if (itemUpdate.container === "audios") {
          newItem = replaceAudioItem({ item: prevItem, newItem });
        } else {
          newItem = replaceWorkspaceItem({ item: prevItem, newItem, isNoCrop: itemUpdate.isNoCrop });
        }

        const prevType = prevItem.get("type");
        const prevSubType = prevItem.get("subType");
        const prevDropId = prevItem.get("dropId");
        if (isVideoOnly(prevType, prevSubType) || isAudioOnly(prevType, prevSubType)) {
          removedMediaDropIds = removedMediaDropIds.setIn([itemUpdate.container, prevDropId], true);
        }

        if (isVideoOnly(prevType, prevSubType) && !isVideoOnly(newItem.get("type"), newItem.get("subType"))) {
          if (prevItem.get("transitionExitId") !== "none" && prevItem.get("transitionExitId") !== undefined) {
            updaterObject = updaterObject.setIn(
              [itemUpdate.container, prevItem.get("transitionExitId"), "data", "transitionEnterId"],
              "none"
            );
            newItem = newItem.set("transitionExitId", "none");
          }
          if (prevItem.get("transitionEnterId") !== "none" && prevItem.get("transitionEnterId") !== undefined) {
            updaterObject = updaterObject.setIn(
              [itemUpdate.container, prevItem.get("transitionEnterId"), "data", "transitionExitId"],
              "none"
            );
            newItem = newItem.set("transitionEnterId", "none");
          }
        }
      } else if (itemUpdate.isNewTrack && (itemUpdate.container === "workspaceItems" || itemUpdate.container === "audios")) {
        const track = newItem.get("track");
        if (!newTracks[itemUpdate.container]) {
          newTracks[itemUpdate.container] = {};
        }
        if (!newTracks[itemUpdate.container][track]) {
          newTracks[itemUpdate.container][track] = {
            trackIndex: track,
            ids: [],
          };
        }
        newTracks[itemUpdate.container][track].ids.push(itemUpdate.id);
      }

      newProjectDetails = newProjectDetails.setIn(
        [itemUpdate.container, newItem.get("id")],
        newItem
      );
      updaterObject = updaterObject.setIn(
        [itemUpdate.container, newItem.get("id"), "isNew"],
        true
      );
      updaterObject = updaterObject.setIn(
        [itemUpdate.container, newItem.get("id"), "data"],
        newItem
      );
      if (itemUpdate.isWorkspaceReplace) {
        updaterObject = updaterObject.setIn(
          [itemUpdate.container, newItem.get("id"), "isReplace"],
          true
        );
      }
      if (itemUpdate.isSplit) {
        updaterObject = updaterObject.setIn(
          [itemUpdate.container, newItem.get("id"), "isSplit"],
          true
        );
      }
      if (itemUpdate.isDontSelect) {
        updaterObject = updaterObject.setIn(
          [itemUpdate.container, newItem.get("id"), "isDontSelect"],
          true
        );
      }

      if (itemUpdate.container === "workspaceBG") {
        movedVideoIds.push(newItem.get("id"));
      } else if (itemUpdate.container === "workspaceItems") {
        movedItemIds.push(newItem.get("id"));
      } else if (itemUpdate.container === "audios") {
        movedAudioIds.push(newItem.get("id"));
      }
    } else if (itemUpdate.isDelete) {
      const item = newProjectDetails.getIn([
        itemUpdate.container,
        itemUpdate.id,
      ]);
      newProjectDetails = newProjectDetails.deleteIn([
        itemUpdate.container,
        itemUpdate.id,
      ]);

      if (isVideoOnly(item.get("type"), item.get("subType"))) {
        if (item.get("transitionExitId") !== "none" && item.get("transitionExitId") !== undefined) {
          updaterObject = updaterObject.setIn(
            [itemUpdate.container, item.get("transitionExitId"), "data", "transitionEnterId"],
            "none"
          );

          newProjectDetails = newProjectDetails.setIn([
            itemUpdate.container,
            item.get("transitionExitId"),
            "transitionEnterId"
          ], "none");

        }
        if (item.get("transitionEnterId") !== "none" && item.get("transitionEnterId") !== undefined) {
          updaterObject = updaterObject.setIn(
            [itemUpdate.container, item.get("transitionEnterId"), "data", "transitionExitId"],
            "none"
          );
          newProjectDetails = newProjectDetails.setIn([
            itemUpdate.container,
            item.get("transitionEnterId"),
            "transitionExitId"
          ], "none");
        }
      }

      updaterObject = updaterObject.setIn(
        [itemUpdate.container, itemUpdate.id, "isDelete"],
        true
      );
      if (itemUpdate.container === "workspaceItems") {
        checkItemEmptyTracks = true;
      } else if (itemUpdate.container === "audios") {
        checkAudioEmptyTracks = true;
      }

      const type = item.get("type");
      const subType = item.get("subType");
      const dropId = item.get("dropId");
      if (isVideoOnly(type, subType) || isAudioOnly(type, subType)) {
        removedMediaDropIds = removedMediaDropIds.setIn([itemUpdate.container, dropId], true);
      }
    } else if (itemUpdate.isObjToVid || itemUpdate.isVidToObj) {
      let newItem = fromJS(itemUpdate.newItemData);
      const item = newProjectDetails.getIn([
        itemUpdate.fromContainer,
        itemUpdate.id,
      ]);

      newItem = newItem
        .set("id", item.get("id"))
        .set("assetId", item.get("assetId"))
        .set("type", item.get("type"))
        .set("subType", item.get("subType"))
        .set("src", item.get("src"))
        .set("thumb", item.get("thumb"))
        .set("x", item.get("x"))
        .set("y", item.get("y"))
        .set("width", item.get("width"))
        .set("height", item.get("height"))
        .set("pwidth", item.get("pwidth"))
        .set("pheight", item.get("pheight"))
        .set("angle", 0)
        .set("flipPosition", item.get("flipPosition"))
        .set("filter", item.get("filter"))
        .set("volume", item.get("volume"))
        .set("stickerify", item.get("stickerify"))
        .set("strokeOptions", item.get("strokeOptions"))
        .set("firstEdit", item.get("firstEdit"))
        .set("isTransparent", item.get("isTransparent"))
        .set("stickerifyPath", item.get("stickerifyPath"))
        .set("originalPath", item.get("originalPath"))
        .set("recentEdit", item.get("recentEdit"))
        .set("bgremoval", item.get("bgremoval"))
        .set("bgremovalPath", item.get("bgremovalPath"))
        .set("sourceType", item.get("sourceType"))
        .set("isBlob", item.get("isBlob"));

      if (newItem.get("isBlob")) {
        newItem = newItem.set("thumb", "");
      }

      if (itemUpdate.isObjToVid) {
        const workspaceWidth = newProjectDetails.get("width");
        const workspaceHeight = newProjectDetails.get("height");

        let bgWidth = newItem.get("pwidth");
        let bgHeight = newItem.get("pheight");

        if (!Number.isFinite(bgWidth)) {
          bgWidth = newItem.get("width");
          if (newItem.get("isCropped") && newItem.get("original")) {
            bgWidth =
              newItem.get("width") * newItem.getIn(["original", "width"]);
          }
        }
        if (!Number.isFinite(bgHeight)) {
          bgHeight = newItem.get("height");
          if (newItem.get("isCropped") && newItem.get("original")) {
            bgHeight =
              newItem.get("height") * newItem.getIn(["original", "height"]);
          }
        }

        // first try to fit width
        let scaledWidth = workspaceWidth;
        let scaledHeight = (bgHeight / bgWidth) * scaledWidth;

        // if there is gap, fit height
        if (scaledHeight < workspaceHeight) {
          scaledHeight = workspaceHeight;
          scaledWidth = (bgWidth / bgHeight) * scaledHeight;
        }

        const scaledCenter = { x: scaledWidth / 2, y: scaledHeight / 2 };
        const workspaceCenter = {
          x: workspaceWidth / 2,
          y: workspaceHeight / 2,
        };

        const x = workspaceCenter.x - scaledCenter.x;
        const y = workspaceCenter.y - scaledCenter.y;

        newItem = newItem
          .set("x", x / workspaceWidth)
          .set("y", y / workspaceHeight)
          .set("width", scaledWidth / workspaceWidth)
          .set("height", scaledHeight / workspaceHeight);
      } else if (itemUpdate.isVidToObj) {
        const workspaceWidth = newProjectDetails.get("width");
        const workspaceHeight = newProjectDetails.get("height");

        const scaledWidth = item.get("width") * workspaceWidth;
        const scaledHeight = item.get("height") * workspaceHeight;

        let dropWidth = scaledWidth;
        let dropHeight = scaledHeight;

        if (scaledWidth > scaledHeight) {
          dropWidth = workspaceWidth / 2;
          dropHeight = scaledHeight * (dropWidth / scaledWidth);
        } else {
          dropHeight = workspaceWidth / 2;
          dropWidth = scaledWidth * (dropHeight / scaledHeight);
        }

        const x = workspaceWidth / 2 - dropWidth / 2;
        const y = workspaceHeight / 2 - dropHeight / 2;

        newItem = newItem
          .set("opacity", 1)
          .set("x", x)
          .set("y", y)
          .set("width", dropWidth)
          .set("height", dropHeight);
      }

      const sourceContainerData = newProjectDetails
        .get(itemUpdate.fromContainer)
        .remove(itemUpdate.id);
      newProjectDetails = newProjectDetails.set(
        itemUpdate.fromContainer,
        sourceContainerData
      );
      updaterObject = updaterObject.setIn(
        [itemUpdate.fromContainer, itemUpdate.id, "isDelete"],
        true
      );

      newProjectDetails = newProjectDetails.setIn(
        [itemUpdate.container, newItem.get("id")],
        newItem
      );
      updaterObject = updaterObject.setIn(
        [itemUpdate.container, newItem.get("id"), "isNew"],
        true
      );
      updaterObject = updaterObject.setIn(
        [itemUpdate.container, newItem.get("id"), "data"],
        newItem
      );

      if (itemUpdate.isObjToVid) {
        mayVideosOverlap = true;
        movedVideoIds.push(newItem.get("id"));
      } else if (itemUpdate.isVidToObj) {
        if (itemUpdate.isNewTrack) {
          if (!newTracks[itemUpdate.container]) {
            newTracks[itemUpdate.container] = {};
          }
          if (!newTracks[itemUpdate.container][newItem.get("track")]) {
            newTracks[itemUpdate.container][newItem.get("track")] = {
              trackIndex: newItem.get("track"),
              ids: [],
            };
          }
          newTracks[itemUpdate.container][newItem.get("track")].ids.push(
            newItem.id
          );
        } else {
          movedItemIds.push(newItem.get("id"));
        }
      }
      mayItemsOverlap = true;
    } else if (
      itemUpdate.container === "workspaceItems" ||
      itemUpdate.container === "workspaceChildren" || // for workspace item -> frame child drag and drop
      itemUpdate.container === "audios" ||
      itemUpdate.container === "workspaceBG"
    ) {
      let item = newProjectDetails.getIn([itemUpdate.container, itemUpdate.id]);

      Reflect.ownKeys(itemUpdate.toUpdate).forEach((updateKey) => {
        let updateObj = itemUpdate.toUpdate[updateKey];
        let updatePath = [updateKey];
        let updateVal = updateObj;

        if (updateKey === "frameClipImage") {
          updatePath = ["clipDetails", updateObj.clipId, "imgDetails"];
          updateVal = updateObj.imgDetails;
          updateObj = fromJS(updateObj);
        }

        item = item?.setIn(updatePath, updateVal);
        updaterObject = updaterObject.setIn(
          [itemUpdate.container, item.get("id"), "data", updateKey],
          updateObj
        );

        if (itemUpdate.container === "workspaceItems") {
          mayItemsOverlap = true;
          movedItemIds.push(itemUpdate.id);
        } else if (itemUpdate.container === "audios") {
          mayAudiosOverlap = true;
          movedAudioIds.push(itemUpdate.id);
        } else if (itemUpdate.container === "workspaceBG") {
          mayVideosOverlap = true;
          movedVideoIds.push(itemUpdate.id);
        }
      });

      if (
        itemUpdate.isNewTrack &&
        (itemUpdate.container === "workspaceItems" ||
          itemUpdate.container === "audios")
      ) {
        if (!newTracks[itemUpdate.container]) {
          newTracks[itemUpdate.container] = {};
        }
        if (!newTracks[itemUpdate.container][item.get("track")]) {
          newTracks[itemUpdate.container][item.get("track")] = {
            trackIndex: item.get("track"),
            ids: [],
          };
        }
        newTracks[itemUpdate.container][item.get("track")].ids.push(
          itemUpdate.id
        );
      }

      newProjectDetails = newProjectDetails.setIn(
        [itemUpdate.container, itemUpdate.id],
        item
      );
    }
  });

  if (newTracks.workspaceItems || newTracks.audios) {
    const result = handleNewTracks({
      newTracks,
      project: newProjectDetails,
      updaterObject,
    });
    newProjectDetails = result.project;
    updaterObject = result.updaterObject;
  }

  if (mayItemsOverlap || checkItemEmptyTracks) {
    let newItems = newProjectDetails.get("workspaceItems");

    if (mayItemsOverlap) {
      const overlapResult = trackChangeItemOverlap({
        items: newItems,
        movedItemIds,
        updaterObject,
      });
      newItems = overlapResult.newItems;
      updaterObject = overlapResult.updaterObject;
    }

    const emptyTrackResult = removeEmptyTracks({
      items: newItems,
      container: "workspaceItems",
      updaterObject,
    });
    newItems = emptyTrackResult.items;
    updaterObject = emptyTrackResult.updaterObject;
    newProjectDetails = newProjectDetails.set("workspaceItems", newItems);
  }

  if (mayAudiosOverlap || checkAudioEmptyTracks) {
    let newAudios = newProjectDetails.get("audios");

    if (mayAudiosOverlap) {
      const overlapResult = trackChangeMediaOverlap({
        container: "audios",
        mediaData: newAudios,
        movedMediaIds: movedAudioIds,
        updaterObject,
      });
      newAudios = overlapResult.newMedia;
      updaterObject = overlapResult.updaterObject;
    }

    const emptyTrackResult = removeEmptyTracks({
      items: newAudios,
      container: "audios",
      updaterObject,
    });
    newAudios = emptyTrackResult.items;
    updaterObject = emptyTrackResult.updaterObject;
    newProjectDetails = newProjectDetails.set("audios", newAudios);
  }

  if (mayVideosOverlap) {
    const overlapResult = trackChangeMediaOverlap({
      container: "workspaceBG",
      mediaData: newProjectDetails.get("workspaceBG"),
      movedMediaIds: movedVideoIds,
      updaterObject,
    });
    const newVideos = overlapResult.newMedia;
    updaterObject = overlapResult.updaterObject;
    newProjectDetails = newProjectDetails.set("workspaceBG", newVideos);
  }

  const newSubtitle = newProjectDetails.get("subtitle");
  if (willEmit && newSubtitle) {
    for (const [container, removedIds] of removedMediaDropIds.entrySeq()) {
      for (const dropId of removedIds.keySeq()) {
        const dropHasSubtitle = newProjectDetails.hasIn(["subtitle", "data", dropId]);
        if (dropHasSubtitle) {
          const hasDropId = newProjectDetails.get(container).some(item => item.get("dropId") === dropId);
          if (!hasDropId) {
            updaterObject = updaterObject.setIn(
              [
                "subtitle",
                "subtitleDropData",
                dropId,
                "isDelete",
              ],
              true
            );
          }
        }
      }
    }
  }

  if (subtitleItemDeleteDropIds.size && updaterObject.get("subtitle")) {
    for (const dropId of subtitleItemDeleteDropIds.valueSeq()) {
      const dropData = newProjectDetails.getIn(["subtitle", "data", dropId]);
      if (dropData && dropData.size === 0) {
        // all subtitle items of this dropId is deleted, so delete whole dropId
        newProjectDetails = newProjectDetails.deleteIn(["subtitle", "data", dropId]);
        const subtitleUpdater = fromJS({ isDelete: true });
        updaterObject = updaterObject.setIn(["subtitle", "subtitleDropData", dropId], subtitleUpdater);
        // remove whole map of individual delete updater
        updaterObject = updaterObject.deleteIn(["subtitle", "subtitleData", dropId]);
      }
    }
    const subtitleData = updaterObject.getIn(["subtitle", "subtitleData"]);
    if (subtitleData && subtitleData.size === 0) {
      updaterObject = updaterObject.deleteIn(["subtitle", "subtitleData"]);
    }
  }

  if (updaterObject.get("subtitle")) {
    updaterObject = updaterObject.setIn(["subtitle", "id"], newProjectDetails.get("defaultSubtitle"));
  }

  if (newProjectDetails !== projectDetails) {
    newProjectDetails = newProjectDetails.set(
      "duration",
      getProjectDuration({ project: newProjectDetails })
    );
    if (newProjectDetails.get("duration") !== projectDetails.get("duration")) {
      updaterObject = updaterObject.setIn(
        ["project", "data", "duration"],
        newProjectDetails.get("duration")
      );
    }
  }

  if (subtitleList) {
    updaterObject = updaterObject.setIn(
      ["project", "data", "subtitle_data"],
      subtitleList
    );
  }

  if (updaterObject && updaterObject.get("workspaceItems")) {
    updaterObject = updaterObject.updateIn(["workspaceItems"], workspaceItems =>
      workspaceItems.map(item =>
        item.get("isDelete") ? item.delete("data") : item
      )
    );
  }

  return updaterObject;
};

/**
 * returns autosave updater for passed subtitle data
 * @param {object} params
 * @param {Immutable.Map | object} params.subtitleDropMap
 * @param {string} params.subtitleId
 */
export const getSubtitleUpdater = (params = {}) => {
  const { subtitleDropMap, subtitleId } = params;

  let dropMap = subtitleDropMap;
  if (!isImmutable(dropMap)) {
    dropMap = fromJS(dropMap);
  }

  let updaterObject = fromJS({});
  for (const [dropId, dropData] of subtitleDropMap.entrySeq()) {
    const subtitleUpdater = fromJS({ isNew: true, data: dropData });
    updaterObject = updaterObject.setIn(
      [
        "subtitle",
        "subtitleDropData",
        dropId,
      ],
      subtitleUpdater
    );
  }

  if (updaterObject.get("subtitle")) {
    updaterObject = updaterObject.setIn(["subtitle", "id"], subtitleId);
  }

  return updaterObject;
}

/**
 * Function to apply changes of timeline emitted by user
 * @param {object} params
 * @param {object} params.updaterObject updaterObject from autosave/undo
 * @param {object} params.projectDetails project state from redux
 * @returns {object}
 */
export const applyTimelineUpdaterObject = (params = {}) => {
  const { projectDetails, updaterObject } = params;
  let newProjectDetails = projectDetails;

  let itemsMightChangeTrack = false;
  let audiosMightChangeTrack = false;
  let shouldBuildSubtitle = false;
  let subtitlesToSort = Set();

  for (const [container, containerData] of updaterObject.entrySeq()) {
    if (container === "project") {
      for (const updateEntry of containerData.get("data").entrySeq()) {
        let key = updateEntry[0];
        const val = updateEntry[1];
        if (key === "subtitle_data") {
          key = "subtitleData";
          const activeSubtitle = val.find((entry) => entry.get("isActive"));
          if (activeSubtitle) {
            newProjectDetails = newProjectDetails.set(
              "defaultSubtitle",
              activeSubtitle.get("subtitleId")
            );
          } else {
            newProjectDetails = newProjectDetails.set("defaultSubtitle", "");
          }
          shouldBuildSubtitle = true;
        }
        newProjectDetails = newProjectDetails.set(key, val);
      }
    } else if (container === "subtitle") {
      if (containerData.get("id") !== newProjectDetails.get("defaultSubtitle")) {
        // someother subtitle is selected currently by current user
        continue;
      }

      if (containerData.has("subtitleGlobal")) {
        for (const [key, value] of containerData.get("subtitleGlobal").entrySeq()) {
          newProjectDetails = newProjectDetails.setIn(["subtitle", key], fromJS(value));
        }
      }
      if (containerData.has("subtitleDropData")) {
        for (const [dropId, subtitleDropUpdater] of containerData.get("subtitleDropData").entrySeq()) {
          if (subtitleDropUpdater.get("isNew")) {
            newProjectDetails = newProjectDetails.setIn(
              ["subtitle", "data", dropId],
              subtitleDropUpdater.get("data")
            );
            subtitlesToSort = subtitlesToSort.add(dropId);
            shouldBuildSubtitle = true;
          } else if (subtitleDropUpdater.get("isDelete")) {
            newProjectDetails = newProjectDetails.deleteIn([
              "subtitle",
              "data",
              dropId,
            ]);
            shouldBuildSubtitle = true;
          } else {
            // no need to implement this else case, pls use `subtitleData` container
          }
        }
      }
      if (containerData.has("subtitleData")) {
        for (const [dropId, subtitleItems] of containerData.get("subtitleData").entrySeq()) {
          for (const [
            subtitleItemId,
            subtitleUpdater,
          ] of subtitleItems.entrySeq()) {
            if (subtitleUpdater.get("isNew")) {
              newProjectDetails = newProjectDetails.setIn(
                ["subtitle", "data", dropId, subtitleItemId],
                subtitleUpdater.get("data")
              );
              subtitlesToSort = subtitlesToSort.add(dropId);
              shouldBuildSubtitle = true;
            } else if (subtitleUpdater.get("isDelete")) {
              newProjectDetails = newProjectDetails.deleteIn([
                "subtitle",
                "data",
                dropId,
                subtitleItemId,
              ]);
              shouldBuildSubtitle = true;
            } else {
              let item = newProjectDetails.getIn([
                "subtitle",
                "data",
                dropId,
                subtitleItemId,
              ]);
              const timelineId = subtitleUpdater.get("timelineId");
              let isTimeChanged = false;

              for (const [dataKey, value] of subtitleUpdater.get("data").entrySeq()) {
                item = item?.set(dataKey, value);
                if (dataKey === "start" || dataKey === "end") {
                  isTimeChanged = true;
                }
              }

              newProjectDetails = newProjectDetails.setIn(
                ["subtitle", "data", dropId, subtitleItemId],
                item
              );

              if (isTimeChanged) {
                subtitlesToSort = subtitlesToSort.add(dropId);
                shouldBuildSubtitle = true;
              } else if (timelineId && newProjectDetails.getIn(["localSubtitle", timelineId])) {
                let localItem = newProjectDetails.getIn(["localSubtitle", timelineId]);
                for (const [dataKey, value] of subtitleUpdater.get("data").entrySeq()) {
                  localItem = localItem.set(dataKey, value);
                }
                newProjectDetails = newProjectDetails.setIn(["localSubtitle", timelineId], localItem);
              } else {
                shouldBuildSubtitle = true;
              }
            }
          }
        }
      }
    } else {
      for (const [itemId, itemUpdater] of containerData.entrySeq()) {
        if (itemUpdater.get("isDelete")) {
          newProjectDetails = newProjectDetails.deleteIn([container, itemId]);

          if (container === "workspaceItems") {
            itemsMightChangeTrack = true;
          } else if (container === "audios") {
            audiosMightChangeTrack = true;
          }
        } else if (itemUpdater.get("isNew")) {
          newProjectDetails = newProjectDetails.setIn(
            [container, itemId],
            itemUpdater.get("data")
          );

          if (container === "workspaceItems") {
            itemsMightChangeTrack = true;
          } else if (container === "audios") {
            audiosMightChangeTrack = true;
          }
        } else {
          let newItem = newProjectDetails.getIn([container, itemId]);

          for (const [updateKey, updateObj] of itemUpdater.get("data").entrySeq()) {
            let updatePath = [updateKey];
            let updateVal = updateObj;

            if (updateKey === "frameClipImage") {
              updatePath = [
                "clipDetails",
                updateObj.get("clipId"),
                "imgDetails",
              ];
              updateVal = updateObj.get("imgDetails");
            }

            newItem = newItem?.setIn(updatePath, updateVal);

            if (updateKey === "track" && container === "workspaceItems") {
              itemsMightChangeTrack = true;
            } else if (updateKey === "track" && container === "audios") {
              audiosMightChangeTrack = true;
            }
          }

          newProjectDetails = newProjectDetails.setIn(
            [container, itemId],
            newItem
          );
        }

        if (!shouldBuildSubtitle) {
          const prevItem = projectDetails.getIn([container, itemId]);
          const item = newProjectDetails.getIn([container, itemId]);

          if (item) {
            const type = item.get("type");
            const subType = item.get("subType");
            const dropId = item.get("dropId");

            if (
              (isVideoOnly(type, subType) || isAudioOnly(type)) &&
              projectDetails.getIn(["subtitle", "data", dropId])
            ) {
              shouldBuildSubtitle = true;
            }
          }

          if (prevItem) {
            const type = prevItem.get("type");
            const subType = prevItem.get("subType");
            const dropId = prevItem.get("dropId");

            if (
              (isVideoOnly(type, subType) || isAudioOnly(type)) &&
              newProjectDetails.getIn(["subtitle", "data", dropId])
            ) {
              shouldBuildSubtitle = true;
            }
          }
        }
      }
    }
  }

  if (itemsMightChangeTrack) {
    const sortedItems = newProjectDetails.get("workspaceItems").sort((itemA, itemB) => {  // For transitions              // Transition slider
      let result = itemA.get("track") - itemB.get("track")
      if (!result) {
        result = itemB.get("enterStart") - itemA.get("enterStart");
      }
      return result;
    })
    newProjectDetails = newProjectDetails.set("workspaceItems", sortedItems);
  }

  if (audiosMightChangeTrack) {
    const sortedAudios = newProjectDetails.get("audios").sortBy(audio => audio.get("track"));
    newProjectDetails = newProjectDetails.set("audios", sortedAudios);
  }

  if (subtitlesToSort.size) {
    for (const dropId of subtitlesToSort.valueSeq()) {
      const sortedSubtitleData = newProjectDetails
        .getIn(["subtitle", "data", dropId])
        .sortBy((item) => item.get("start"));
      newProjectDetails = newProjectDetails.setIn(
        ["subtitle", "data", dropId],
        sortedSubtitleData
      );
    }
  }

  if (shouldBuildSubtitle) {
    newProjectDetails = buildLocalSubtitles(newProjectDetails);
  }

  return newProjectDetails;
};

/**
 * Function to handle project duration change for autosave
 * @param {object} params
 * @param {object} params.updaterObject updaterObject from autosave/undo
 * @param {object} params.projectDetails project state from redux
 * @returns {object}
 */
export const setUpdaterProjectDuration = (params = {}) => {
  const { projectDetails } = params;
  let { updaterObject } = params;

  // apply timeline changes to get next project duration
  const newProjectDetails = applyTimelineUpdaterObject({
    projectDetails,
    updaterObject,
  });
  const projectDuration = getProjectDuration({ project: newProjectDetails });

  if (projectDuration !== projectDetails.get("duration")) {
    updaterObject = updaterObject.setIn(
      ["project", "data", "duration"],
      projectDuration
    );
  }

  return updaterObject;
};
