import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Typography from "../../../../common-components/Typography";
import { font } from "../../../../constants/font";
import Action from "../../../../common-components/Action";
import content from "../../../../constants/content";
import vmTheme from "../../../../constants/theme";
import { ApplyAll } from "../propertywindow-components";
import {
  EXIT_EFFECTS,
  ENTER_EFFECTS,
  EFFECT_TYPES,
  ENTER_VIDEO_IMAGE_EFFECTS,
  EXIT_VIDEO_IMAGE_EFFECTS,
} from "./effects-data";
import AnimationEffects from "./animation-effects";
import ApplyAllModal from "./apply-modal";
import { AnimWrapper, AnimationContainer, InnerAction, TextAnimationContainer } from "./animation-components";
import { isGiphyOnly, isImageOnly, isVideoOnly } from "../../../timeline/timeline-helper";
import useNotify from "../../../../helper/hooks/useNotify";

const { ANIMATE, APPLY_TO_ALL_VIDEOS, APPLY_EFFECTS_TO_ALL_VIDEOS, APPLY_ALL_VIDEOS_SUCCESS,
  APPLY_TO_ALL_IMAGES, APPLY_EFFECTS_TO_ALL_IMAGES, APPLY_ALL_IMAGES_SUCCESS,
  APPLY_TO_ALL_TEXTS, TEXT_EFFECTS_APPLY_ALL, APPLY_ALL_TEXT_SUCCESS,
  APPLY_TO_ALL_PROP, APPLY_EFFECTS_TO_ALL_PROPERTIES, APPLY_ALL_PROPERTIES_SUCCESS } = content;


const AnimationPanel = () => {
  const theme = useSelector((state) => state.app.get("theme"));
  const { warn } = useNotify();
  const [effectType, setEffect] = useState(EFFECT_TYPES.ENTER);
  const [txt, setTxt] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [disableTab, setDissableTab] = useState("")
  const [isMedia, setMedia] = useState(false);
  const [isTextItem, setIsTextItem] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState({ enterIcon: '', exitIcon: '', enterEffect: '', exitEffect: '' });

  const onOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen])

  const selectedItem = useSelector((state) => {
    return state.app.get("selectedItems");
  });

  const item = useSelector((state) => {
    return state.projectDetails.getIn([
      "workspaceItems",
      selectedItem.get(0)
    ]);
  });

  const enterEffectIcon = item.get("enterEffectIcon");
  const exitEffectIcon = item.get("exitEffectIcon");
  const enterEffectName = item.get("enterEffectName");
  const exitEffectName = item.get("exitEffectName");
  const transitionExitId = item.get("transitionExitId");
  const transitionEnterId = item.get("transitionEnterId");
  const transitionEnterEffect = item.get("transitionEnterEffect");
  const transitionExitEffect = item.get("transitionExitEffect");

  const selectedType = item.get("type");
  const selectedsubType = item.get("subType");

  useEffect(() => {
    if (enterEffectIcon || enterEffectName) {
      setSelectedIcon(prevState => ({
        ...prevState,
        enterIcon: enterEffectIcon,
        enterEffect: enterEffectName
      }));
    }
    if (exitEffectIcon || exitEffectName) {
      setSelectedIcon(prevState => ({
        ...prevState,
        exitIcon: exitEffectIcon,
        exitEffect: exitEffectName
      }));
    }
  }, [enterEffectIcon, exitEffectIcon, enterEffectName, exitEffectName])

  useEffect(() => {
    if (isVideoOnly(selectedType, selectedsubType)) {
      setTxt({
        root: APPLY_TO_ALL_VIDEOS,
        modal: APPLY_EFFECTS_TO_ALL_VIDEOS,
        toast: APPLY_ALL_VIDEOS_SUCCESS
      });
    } else if (isImageOnly(selectedType, selectedsubType)) {
      setTxt({
        root: APPLY_TO_ALL_IMAGES,
        modal: APPLY_EFFECTS_TO_ALL_IMAGES,
        toast: APPLY_ALL_IMAGES_SUCCESS
      });
    } else if (selectedType === "TEXT") {
      setTxt({
        root: APPLY_TO_ALL_TEXTS,
        modal: TEXT_EFFECTS_APPLY_ALL,
        toast: APPLY_ALL_TEXT_SUCCESS
      });
      setIsTextItem(true);
    } else {
      setTxt({
        root: APPLY_TO_ALL_PROP,
        modal: APPLY_EFFECTS_TO_ALL_PROPERTIES,
        toast: APPLY_ALL_PROPERTIES_SUCCESS
      });
    }

    if (isGiphyOnly(selectedType, selectedsubType)
      || isVideoOnly(selectedType, selectedsubType)
      || isImageOnly(selectedType, selectedsubType)) {
      setMedia(true);
    }

  }, [selectedType]);

  const handleSelectedEffect = (effect, src) => {
    if (effect === content.ENTER) {
      setSelectedIcon(prevState => ({
        ...prevState,
        enterIcon: src
      }));
    } else {
      setSelectedIcon(prevState => ({
        ...prevState,
        exitIcon: src
      }));
    }
  }

  const toggleEffectsTab = (type, index, showToast = false) => {
    if (transitionExitId && transitionExitId !== "none" && transitionExitEffect !== "none_transition") {
      setEffect(EFFECT_TYPES.EXIT);
      setActiveTab(1);
      setDissableTab(EFFECT_TYPES.ENTER);
      if (showToast) {
        warn(content.UNABLE_TO_ADD_ENTER_EFFECT)
      }
    } else if (transitionEnterId && transitionEnterId !== "none" && transitionEnterEffect !== "none_transition") {
      setEffect(EFFECT_TYPES.ENTER);
      setDissableTab(EFFECT_TYPES.EXIT);
      setActiveTab(0);
      if (showToast) {
        warn(content.UNABLE_TO_ADD_EXIT_EFFECT)
      }
    } else {
      setEffect(type);
      setActiveTab(index);
    }
  }

  useEffect(() => {
    if (transitionEnterId || transitionExitId || transitionEnterEffect || transitionExitEffect) {
      toggleEffectsTab(EFFECT_TYPES.ENTER, 0);
    }
  }, [transitionEnterId, transitionExitId,transitionEnterEffect, transitionExitEffect])

  return (
    <AnimationContainer style={{ padding: "17px" }}>
      <Typography
        content={ANIMATE}
        color={vmTheme[theme].panelPrimaryColor}
        font={font.boldBase}
        height="19px"
      />
      <ApplyAll styled={{ margin: "0px" }} onClick={onOpen}>
        {txt.root && <FormattedMessage id={txt.root} />}
      </ApplyAll>
      <AnimWrapper>
        <TextAnimationContainer>
          {Object.keys(EFFECT_TYPES).map((key, index) => {
            const effect = EFFECT_TYPES[key] === effectType;
            return (
              <AnimationContainer key={key} effect={effect} disableTab={disableTab}
                selectedIcon={EFFECT_TYPES[key] === content.ENTER ?
                  selectedIcon.enterIcon : selectedIcon.exitIcon} active={index === activeTab}>
                  
                <Action
                  borderRadius="6px"
                  width="152px"
                  height="34px"
                  showHoverColor={true}
                  borderWidth={"1px"}
                  borderColor={"transparent"}
                  background="transparent"
                  effect={effect}
                  hoverEffects={`border: 1px solid ${vmTheme[theme].polarColor}`}
                  text={EFFECT_TYPES[key]}
                  font={font.mediumMini_12}
                  onClick={() => { toggleEffectsTab(EFFECT_TYPES[key], index, true) }}
                  fontColor={vmTheme[theme].panelPrimaryColor}
                  hoverColor="transparent"
                  className="group-btn"
                />
              </AnimationContainer>
            );
          })}
          <InnerAction className="inneraction" activeTab={activeTab}/>
        </TextAnimationContainer>
      </AnimWrapper>

      {isMedia ? (
        <AnimationEffects
          textEffect={
            effectType === EFFECT_TYPES.ENTER
              ? ENTER_VIDEO_IMAGE_EFFECTS
              : EXIT_VIDEO_IMAGE_EFFECTS
          }
          type={effectType}
          selectedEffect={handleSelectedEffect}
          selectedIcon={effectType === content.ENTER ?
            selectedIcon.enterIcon : selectedIcon.exitIcon}
          effectName={effectType === content.ENTER ?
            selectedIcon.enterEffect : selectedIcon.exitEffect}
        />
      ) : (
        <AnimationEffects
          textEffect={
            effectType === EFFECT_TYPES.ENTER ? ENTER_EFFECTS : EXIT_EFFECTS
          }
          type={effectType}
          selectedEffect={handleSelectedEffect}
          selectedIcon={effectType === content.ENTER ?
            selectedIcon.enterIcon : selectedIcon.exitIcon}
          effectName={effectType === content.ENTER ?
            selectedIcon.enterEffect : selectedIcon.exitEffect}
          isText = {isTextItem}  
        />
      )}
      <ApplyAllModal
        itemType={selectedType}
        modalData={txt.modal}
        successMessage={txt.toast}
        isOpen={isOpen}
        onClose={onOpen}
      />
    </AnimationContainer>
  );
};

export default AnimationPanel;
