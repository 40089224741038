import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContentBody, ContentContainer, ContentHeader, Footer, Links, SocialMedia } from "./shorts-components";
import Typography from "../../common-components/Typography";
import vmTheme from "../../constants/theme";
import { font } from "../../constants/font";
import content from "../../constants/content";
import Action from "../../common-components/Action";
import openUrl from "../../helper/openUrl";
import { API_URL, STATIC_PATH, API, SITE_URL, ENVIRONMENT } from "../../constants/config";
import Export from "../modal/export";
import useApi from "../../helper/hooks/useApi";

const ShortsContent = ({ shortData, subtitlesInRange }) => {
  const navigate = useHistory();
  const { post } = useApi();
  const theme = useSelector((state) => state.app.get("theme"));
  const { permission, projectId } = useSelector((state) => state.userDetails);

  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModalExport = () => setIsModal(false);

  const openNewProject = useCallback((shortId) => {
    setIsLoading(true);
    post(`${API_URL}${API.SHORTS.CREATE}`, { projectId, shortId }).then((res) => {
      if (!res.error) {
        openUrl(`${SITE_URL}${ENVIRONMENT === "LOCAL" ? "" : "editproject/"}${res.projectId}`);
        setIsLoading(false);
      }
    });
  },
    [projectId, post]
  );

  const truncate = (str, length) => {
    if (str.length > length) {
      return `${str.substr(0, length)}...`;
    }
    return str;
  };


  const onExportClick = () => {
    navigate.push(`/render/${projectId}`, { shortId: shortData.get("id") });
  }

  return (
    <ContentContainer>
      <ContentHeader>
        {permission !== 0 && (
          <>
            <Action
              text={content.EDIT_AS_PROJECT}
              font={font.mediumBase_21}
              className="edit-project"
              marginTop="0px"
              fontColor={vmTheme[theme].shortsSubText}
              marginBottom="0px"
              width="156px"
              height={"36px"}
              borderRadius={"8px"}
              borderWidth={"1px"}
              borderColor={vmTheme[theme].shortsBorderColor}
              onClick={() => openNewProject(shortData.get("id"))}
              isLoading={isLoading}
            />
            <Action
              text={content.EXPORT}
              font={font.mediumBase_21}
              className="export-project"
              marginTop="0px"
              fontColor={vmTheme[theme].shortsSecondaryColor}
              marginBottom="0px"
              width="99px"
              height={"36px"}
              borderRadius={"8px"}
              background={vmTheme[theme].shortsBtnBgColor}
              onClick={onExportClick}
            />
          </>
        )}
        <Export
          modalHandler={closeModalExport}
          isShowModal={isModal}
          projectTitle={truncate(shortData.get("title"), 40)}
          onCloseModal={() => setIsModal(false)}
          shortId={shortData.get("id")}
        />
      </ContentHeader>
      <Typography
        content={shortData.get("title")}
        font={font.bold_19}
        color={vmTheme[theme].shortsTitle}
        padding="0px 0px 0px"
        enableTrim={false}
      />
      <ContentBody>
        <Typography
          innerContent={subtitlesInRange}
          font={font.normalBase_19}
          color={vmTheme[theme].subtitleTextColor}
          padding="0px 0px 0px"
          display="block"
          enableTrim={false}
        />
      </ContentBody>
      <Footer>
        <Typography content={content.SUITABLE_FOR} />
        {shortData.get("socialMedia") && (
          <SocialMedia>
            {shortData.get("socialMedia").map((icon) => {
              return (
                <Links key={icon}>
                  <img
                    src={`${STATIC_PATH + icon.split(" ")[0]}.svg`}
                    alt={icon}
                  />
                </Links>
              );
            })}
          </SocialMedia>
        )}
      </Footer>
    </ContentContainer>
  );
};

ShortsContent.propTypes = {
  shortData: PropTypes.object,
  subtitlesInRange: PropTypes.array,
};

export default ShortsContent;
