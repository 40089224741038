/* eslint-disable no-continue, no-restricted-syntax, jsx-a11y/media-has-caption */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addTween,
  prefetchComplete,
  setPlayAll,
} from "../../redux/actions/appUtils";
import { getAudioSource } from "../../helper/URLHelper";
import Media from "./player-media";
import { PlayerAudioCallbackContext } from "./player-context";

class AudioComponent extends React.Component {
  constructor(props) {
    super(props);

    this.cleanupAudioContext = this.cleanupAudioContext.bind(this);
    this.initializeAudioPlayer = this.initializeAudioPlayer.bind(this);
    this.playPauseAudio = this.playPauseAudio.bind(this);
    this.assignMediaRef = this.assignMediaRef.bind(this);

    this.AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = null;

    /** @type {Object.<string, HTMLAudioElement | HTMLVideoElement | null>} */
    this.mediaRef = {};
    /** @type {Object.<string, { gainNode: GainNode, srcNode: MediaElementAudioSourceNode }>} */
    this.audioNodes = {};

    this.playerCallbacks = {
      assignMediaRef: this.assignMediaRef,
      playPauseAudio: this.playPauseAudio,
    };
  }

  componentDidMount() {
    if (this.props.isPlayAll) {
      this.initializeAudioPlayer();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPlayAll !== prevProps.isPlayAll) {
      this.cleanupAudioContext();
    }

    if (this.props.isPlayAll !== prevProps.isPlayAll && this.props.isPlayAll) {
      this.initializeAudioPlayer();
    }
  }

  componentWillUnmount() {
    this.cleanupAudioContext();
  }

  cleanupAudioContext() {
    if (this.audioContext) {
      this.audioContext.close();
    }
    this.audioNodes = {};
  }

  initializeAudioPlayer() {
    this.audioContext = new this.AudioContext();
    try {
      this.prepareAudioContext();
      this.props.prefetchComplete(this.props.prefetchToken);
    } catch (error) {
      // if an error is catched here, probably there is an issue in handling audio context
      this.props.setPlayAll(false);
    }
  }

  /**
   * @param {object} params
   * @param {object} params.id
   * @param {object} params.playhead
   * @param {"audio" | "video"} params.mediaType
   */
  playPauseAudio(params = {}) {
    const { id, playhead, mediaType = "audio" } = params;

    try {
      const audioId = id;
      const audio = this.mediaRef[audioId];
      const audioNode = this.audioNodes[audioId];
      const audioCtx = this.audioContext;

      let audioItem = this.props.audios.get(audioId);
      let mediaStartKey = "musicStart";
      if (mediaType === "video") {
        audioItem = this.props.videos.get(audioId);
        mediaStartKey = "videoStart";
      }

      if (!audioNode || !audio || !audioCtx || !audioItem) {
        return;
      }

      const playStart = audioItem.get("playStart");
      const playEnd = audioItem.get("playEnd");

      const { srcNode } = audioNode;
      let { gainNode } = audioNode;

      // disconnect old gain node to cleanup old scheduled fade
      gainNode.disconnect(audioCtx.destination);
      srcNode.disconnect(gainNode);

      // create new node to schedule fade
      gainNode = audioCtx.createGain();
      srcNode.connect(gainNode);
      gainNode.connect(audioCtx.destination);
      audioNode.gainNode = gainNode;

      if (playhead >= playStart && playhead < playEnd) {
        const ctxTime = audioCtx.currentTime;
        const defaultAmplitude = audioItem.get("defaultAmplitude")
          ? audioItem.get("defaultAmplitude")
          : 1;
        const fadeDetails = audioItem.get("fadeDetails");
        const musicStart = parseFloat(audioItem.get(mediaStartKey));

        if (fadeDetails) {
          let addedFadeIdx = 0;
          for (let fadeIdx = 0; fadeIdx < fadeDetails.size; fadeIdx += 1) {
            const fadeDetail = fadeDetails.get(fadeIdx);
            const time = parseFloat(fadeDetail.get("t"));
            const amplitude =
              parseFloat(fadeDetail.get("a")) * this.props.globalVolume;

            const fadeTime = playStart + (time - musicStart);
            if (
              fadeTime < playStart ||
              fadeTime > playEnd ||
              fadeTime < playhead
            ) {
              continue;
            }

            const localTime = fadeTime - playhead;
            const scheduleTime = ctxTime + localTime;

            if (scheduleTime < 0) {
              continue;
            }

            if (addedFadeIdx === 0) {
              gainNode.gain.setValueAtTime(amplitude, 0);
              if (ctxTime !== 0) {
                gainNode.gain.setValueAtTime(amplitude, scheduleTime);
              }
            } else {
              const prevFadeDetail = fadeDetails.get(fadeIdx - 1);
              const prevAmplitude =
                parseFloat(prevFadeDetail.get("a")) * this.props.globalVolume;
              if (amplitude === prevAmplitude) {
                gainNode.gain.setValueAtTime(amplitude, scheduleTime);
              } else {
                gainNode.gain.linearRampToValueAtTime(amplitude, scheduleTime);
              }
            }

            addedFadeIdx += 1;
          }

          if (addedFadeIdx === 0 && fadeDetails.size !== 0) {
            const fadeDetail = fadeDetails.get(fadeDetails.size - 1);
            const amplitude = parseFloat(fadeDetail.get("a")) * this.props.globalVolume;
            gainNode.gain.setValueAtTime(amplitude, 0);
          }
          if (fadeDetails.size === 0) {
            gainNode.gain.setValueAtTime(defaultAmplitude, 0);
          }
        } else {
          audio.volume = audioItem.get("volume") * this.props.globalVolume;
        }

      }
    } catch (error) {
      // errors happening here might be fatal
      // example: audio volume is increased in first loop and second loop failed to decrease it
      // this scenario will not happen in most cases as we pause audio before applying gain, but just in case...
      this.props.setPlayAll(false);
    }
  }

  prepareAudioContext() {
    const prepareAudioNode = ([audioId]) => {
      const audioCtx = this.audioContext;
      const audio = this.mediaRef[audioId];

      if (!audio || !audioCtx) {
        // this shouldn't happen but just in case
        return;
      }

      const srcNode = audioCtx.createMediaElementSource(audio);
      const gainNode = audioCtx.createGain();
      srcNode.connect(gainNode);
      gainNode.connect(audioCtx.destination);

      this.audioNodes[audioId] = { srcNode, gainNode };
    };

    this.props.audios.entrySeq().forEach(prepareAudioNode);
    this.props.videos.entrySeq().forEach(prepareAudioNode);
  }

  assignMediaRef(r, id) {
    if (r) {
      this.mediaRef[id] = r;
    } else {
      delete this.mediaRef[id];
    }
  }

  render() {
    const audioElList = [];

    this.props.audios.valueSeq().forEach((audioItem) => {
      const audioId = audioItem.get("id");
      const { src } = getAudioSource({
        item: {
          type: audioItem.get("type"),
          subType: audioItem.get("subType"),
          src: audioItem.get("src"),
        },
        isFreeUser: true /** @todo check based on user plan */,
      });

      audioElList.push(
        <Media
          key={audioId}
          id={audioId}
          src={src}
          playStart={audioItem.get("playStart")}
          playEnd={audioItem.get("playEnd")}
          mediaStart={audioItem.get("musicStart")}
          mediaEnd={audioItem.get("musicEnd")}
          speed={audioItem.get("speed")}
          mediaType="audio"
          allowCustomCaching={audioItem.get("type") !== "PIXABAY"}
        />
      );
    });

    return (
      <PlayerAudioCallbackContext.Provider value={this.playerCallbacks}>
        {this.props.children}
        {audioElList}
      </PlayerAudioCallbackContext.Provider>
    );
  }
}

AudioComponent.propTypes = {
  audios: PropTypes.object,
  videos: PropTypes.object,
  prefetchToken: PropTypes.string,
  prefetchComplete: PropTypes.func,
  isPlayAll: PropTypes.bool,
  setPlayAll: PropTypes.func,
  globalVolume: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  t1: state.app.get("t1"),
  runningState: state.app.get("runningState"),
  isPlayAll: state.app.get("isPlayAll"),
  prefetchToken: state.app.get("prefetchToken"),
  isLoaded: state.app.get("isLoaded"),
  videoBufferStatus: state.app.get("videoBufferStatus"),
  globalVolume: state.app.get("globalVolume"),
});

const mapDispatchToProps = (dispatch) => ({
  prefetchComplete: (token, count) => dispatch(prefetchComplete(token, count)),
  addTween: (data, tweenType) => dispatch(addTween(data, tweenType)),
  setPlayAll: (data) => dispatch(setPlayAll(data)),
});

const PlayerAudio = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioComponent);

export default PlayerAudio;
