/* eslint-disable operator-assignment, one-var, prefer-template */

import { fromJS, Map } from "immutable";
import { ITEM_CONFIG, FRAME } from "../../constants/config";
import { isVideoOnly, randomString } from "../timeline/timeline-helper";
import { DEFAULT_FRAME_BG } from "../../constants";

export const FRAME_CLASSES = {
  DROP_AREA: "frame-droppable-area",
  INNER_IMAGE: "frame-img-item",
};

export const DEFAULT_SELECTED_FRAME_CLIP = fromJS({
  id: "",
  groupId: "",
  clipId: "",
});

const DEFAULT_IMAGE_DETAILS = fromJS({
  src: FRAME.DEFAULT_IMAGE,
  width: 1500,
  height: 600,
});

export const BASE_IMAGE_DETAILS = fromJS({
  src: "",
  thumb: "",
  defaultImageDetails: DEFAULT_IMAGE_DETAILS,
  flipPosition: 0,
  filter: "6464646464640032",
  type: "",
  id: "default",
});

export const calculateAspectRatio = (Frame, Image, clip) => {
  const selectedItem = Frame;

  let imageWidth;
  let imageHeight;

  if (Image !== null) {
    const selectedImage = Image;
    imageWidth = selectedImage.get("width");
    imageHeight = selectedImage.get("height");
    if (isVideoOnly(Image.get("type"), Image.get("subType"))) {
      imageWidth = selectedImage.get("pwidth")
        ? selectedImage.get("pwidth")
        : selectedImage.get("width");
      imageHeight = selectedImage.get("pheight")
        ? selectedImage.get("pheight")
        : selectedImage.get("height");
    }
  } else {
    imageWidth = selectedItem.getIn([
      "clipDetails",
      clip,
      "imgDetails",
      "width",
    ]);
    imageHeight = selectedItem.getIn([
      "clipDetails",
      clip,
      "imgDetails",
      "height",
    ]);
  }
  const imageAspectRatio = imageWidth / imageHeight;

  const frameScale =
    selectedItem.get("width") / selectedItem.get("defaultWidth");
  const clipWidth =
    selectedItem.getIn(["clipDetails", clip, "clipWidth"]) * frameScale;
  const clipHeight =
    selectedItem.getIn(["clipDetails", clip, "clipHeight"]) * frameScale;

  const frameAspectRatio = clipWidth / clipHeight;

  let width,
    height,
    type,
    X = selectedItem.getIn(["clipDetails", clip, "clipX"]) * frameScale,
    Y = selectedItem.getIn(["clipDetails", clip, "clipY"]) * frameScale;

  if (imageAspectRatio < frameAspectRatio) {
    width = clipWidth;
    height = clipWidth * (imageHeight / imageWidth);

    Y = Y + clipHeight / 2 - height / 2;

    type = 1;
  } else if (imageAspectRatio > frameAspectRatio) {
    width = clipHeight * (imageWidth / imageHeight);
    height = clipHeight;

    X = X + clipWidth / 2 - width / 2;

    type = 2;
  } else if (imageAspectRatio === frameAspectRatio) {
    width = clipWidth;
    height = clipHeight;

    X = X + clipWidth / 2 - width / 2;
    Y = Y + clipHeight / 2 - height / 2;

    type = 0;
  }

  const frameHeight = selectedItem.get("height");
  const frameWidth = selectedItem.get("width");

  width = width / frameWidth;
  height = height / frameHeight;
  X = X / frameWidth;
  Y = Y / frameHeight;

  return { width, height, X, Y, type };
};

export const prepareClipDetails = (Frame) => {
  const clipDetails = Frame.get("clipDetails");
  clipDetails.keySeq().forEach((key) => {
    Frame = Frame.setIn(["clipDetails", key, "imgDetails"], BASE_IMAGE_DETAILS);
    const {
      width,
      height,
      X: x,
      Y: y,
    } = calculateAspectRatio(Frame, DEFAULT_IMAGE_DETAILS, key);
    Frame = Frame.setIn(
      ["clipDetails", key, "imgDetails", "original"],
      fromJS({ width, height, x, y })
    );
  });

  return Frame.get("clipDetails");
};

export const prepareDefaultImgDetails = (Frame, clipId) => {
  const imgDetails = BASE_IMAGE_DETAILS.merge({
    color: Frame.getIn(["clipDetails", clipId, "imgDetails", "color"]),
  });
  Frame = Frame.setIn(["clipDetails", clipId, "imgDetails"], imgDetails);
  const {
    width,
    height,
    X: x,
    Y: y,
  } = calculateAspectRatio(Frame, DEFAULT_IMAGE_DETAILS, clipId);
  Frame = Frame.setIn(
    ["clipDetails", clipId, "imgDetails", "original"],
    fromJS({ width, height, x, y })
  );
  return Frame.getIn(["clipDetails", clipId, "imgDetails"]);
};

export const prepareClipFromWorkspace = ({
  Item,
  ItemId,
  Frame,
  clipId,
} = {}) => {
  const type = Item.get("type");
  const subType = Item.get("subType");
  const ClipData = Frame.getIn(["clipDetails", clipId]);

  const imgDetails = ClipData.getIn(["imgDetails"]);
  let imageData = imgDetails
    .set("subType", subType)
    .set("type", type)
    .set("width", Item.get("width"))
    .set("height", Item.get("height"))
    .set("assetId", Item.get("assetId"))
    .set("src", Item.get("src"))
    .set("thumb", Item.get("thumb") || Item.get("thumbnail"))
    .set("stickerify", Item.get("stickerify"))
    .set("strokeOptions", Item.get("strokeOptions"))
    .set("firstEdit", Item.get("firstEdit"))
    .set("isTransparent", Item.get("isTransparent"))
    .set("stickerifyPath", Item.get("stickerifyPath"))
    .set("originalPath", Item.get("originalPath"))
    .set("recentEdit", Item.get("recentEdit"))
    .set("bgremoval", Item.get("bgremoval"))
    .set("bgremovalPath", Item.get("bgremovalPath"))
    .set("id", ItemId)
    .set("colors", Item.get("colors"))
    .set(
      "filter",
      Item.get("filter") ? Item.get("filter") : ITEM_CONFIG.IMAGE.DEFAULT_FILTER
    )
    .set("flipPosition", 0)
    .set("defaultColor", Item.get("defaultColor"))
    .set("sourceType", Item.get("sourceType"))
    .set("chromaKey", Item.get("chromaKey"))
    .set("tolerance", Item.get("tolerance"))
    .set("pwidth", Item.get("pwidth"))
    .set("pheight", Item.get("pheight"));

  if (
    Item.get("isBlob") &&
    Item.get("src") &&
    Item.get("src").startsWith("blob:")
  ) {
    imageData = imageData.remove("thumb");
    imageData = imageData.set("isBlob", Item.get("isBlob"));
  }

  if (isVideoOnly(type, subType)) {
    imageData = imageData
      .set("videoStart", Item.get("videoStart"))
      .set("videoEnd", Item.get("videoEnd"))
      .set("videoDuration", Item.get("videoDuration"))
      .set("volume", Item.get("volume"))
      .set("speed", Item.get("speed"))
      .set("enableBgRemoval", Item.get("enableBgRemoval"))
      .set("isAvatar", Item.get("isAvatar"))
      .set("isTransparentAvatar", Item.get("isTransparentAvatar"))
      .set("duration", Item.get("duration"));
  }

  const imagePlotRatio = calculateAspectRatio(Frame, imageData, clipId);

  imageData = imageData.set(
    "original",
    fromJS({
      x: imagePlotRatio.X,
      y: imagePlotRatio.Y,
      width: imagePlotRatio.width,
      height: imagePlotRatio.height,
    })
  );

  return imageData;
};

export const prepareSingleClipFrame = (frameItem, item) => {
  const orgFrameItem = frameItem;
  const firstClipId = frameItem.get("clipDetails").keySeq().first();

  let imgDetails = prepareClipFromWorkspace({
    Item: item,
    ItemId: item.get("id"),
    Frame: frameItem,
    clipId: firstClipId,
  });

  let newImgDetails = prepareDefaultImgDetails(frameItem, firstClipId).merge({
    color: imgDetails.get("color"),
    original: imgDetails.get("original"),
    filter: imgDetails.get("filter"),
    flipPosition: imgDetails.get("flipPosition"),
  });
  if ((item.get("sourceType") === "webm" || item.get("isTransparentAvatar")) && !newImgDetails.get("color")) {
    newImgDetails = newImgDetails.set("color", DEFAULT_FRAME_BG);
  }
  frameItem = frameItem.setIn(
    ["clipDetails", firstClipId, "imgDetails"],
    newImgDetails
  );

  imgDetails = imgDetails
    .delete("defaultImageDetails")
    .delete("color")
    .delete("original")
    .delete("width")
    .delete("height")
    .delete("id")
    .delete("filter")
    .delete("flipPosition");
  frameItem = frameItem.merge(imgDetails, {
    isSingleClipFrame: true,
    frameAssetId: orgFrameItem.get("assetId"),
    frameDropId: orgFrameItem.get("dropId"),
    dropId: item.get("dropId") || randomString("clipdrop"), // try to maintain dropId for subtitle
  });
  return frameItem;
};

export const singleClipFrameToFrame = (item) => {
  const [firstClipId, firstClip] = item.get("clipDetails").entrySeq().first();
  const orgImgDetails = firstClip.get("imgDetails");
  let imgDetails = prepareClipFromWorkspace({
    Item: item,
    ItemId: item.get("id"),
    Frame: item,
    clipId: firstClipId,
  });
  imgDetails = imgDetails.merge({
    original: orgImgDetails.get("original"),
    flipPosition: orgImgDetails.get("flipPosition"),
    filter: orgImgDetails.get("filter"),
  });
  item = item.setIn(["clipDetails", firstClipId, "imgDetails"], imgDetails);
  item = item.set("type", "FRAME").set("subType", "FRAME");
  return item;
};

export const removeImgFromSingleClipFrame = (item) => {
  const [firstClipId, firstClip] = item.get("clipDetails").entrySeq().first();

  let newItem = Map({
    clipDetails: item.get("clipDetails"),
    d: item.get("d"),
    defaultWidth: item.get("defaultWidth"),
    defaultHeight: item.get("defaultHeight"),

    track: item.get("track"),
    enterStart: item.get("enterStart"),
    enterEnd: item.get("enterEnd"),
    enterEffectName: item.get("enterEffectName"),
    exitStart: item.get("exitStart"),
    exitEnd: item.get("exitEnd"),
    exitEffectName: item.get("exitEffectName"),

    type: "FRAME",
    subType: "FRAME",

    id: item.get("id"),
    dropId: item.get("frameDropId") || item.get("dropId"),
    assetId: item.get("frameAssetId") || item.get("assetId"),

    flipPosition: item.get("flipPosition"),
    x: item.get("x"),
    y: item.get("y"),
    width: item.get("width"),
    height: item.get("height"),
    angle: item.get("angle"),
    opacity: item.get("opacity"),
  });

  const defaultImageDetails = prepareDefaultImgDetails(
    newItem,
    firstClipId
  ).set("color", firstClip.getIn(["imgDetails", "color"]));
  newItem = newItem.setIn(
    ["clipDetails", firstClipId, "imgDetails"],
    defaultImageDetails
  );

  return newItem;
};

export const getFrameDropAreaId = (id, clipId) => {
  return id + "_droppable_area_" + clipId;
};

export const getFrameSvgId = (id, svgIndex) => {
  return id + "_svg_" + svgIndex;
};

export const getFrameClipFilterSvgId = (id, clipId) => {
  return id + "_svgFilter_" + clipId;
};

export const getFrameClipFilterId = (id, clipId, filterId) => {
  return id + "_svgFilter_" + clipId + "_" + filterId;
};

export const getFrameClipVignetteId = (id, clipId) => {
  return id + "-vignette-" + clipId;
};

export const getFrameInnerImageId = (id, clipId) => {
  return id + "_innerimg_" + clipId;
};
