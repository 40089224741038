import styled, { css } from "styled-components";
import { PlotType } from "./timeline-proptypes";
import { APP_Z_INDICES } from "../../constants";

export const TimelineContainer = styled.div.attrs((props) => {
  return {
    style: {
      left: `${props.$timelinePlot.x}px`,
      top: `${props.$timelinePlot.y}px`,
      width: `${props.$timelinePlot.width}px`,
      height: `${props.$timelinePlot.height}px`,
    },
  };
})`
  position: absolute;
  width: 100%;
  background-color: ${(props) => props.theme.TIMELINE_BACKGROUND};
  user-select: none;
  box-sizing: border-box;
  touch-action: none;
  z-index: ${APP_Z_INDICES.timeline.default};

  &.tc--animate-container {
    transition: all .4s;
  }

  * {
    user-select: none;
    box-sizing: border-box;
    touch-action: none;
  }

  &.tc--block-events,
  &.tc--block-events * {
    pointer-events: none !important;
  }

  --TimelineHeight: ${props => `${props.$timelinePlot.height}px`};

  @media (max-width: 768px) {
    width: 100vw !important;
    left: 0px !important;
    bottom: 80px;
    top: auto !important;
    z-index: 10;

    ${props =>
    props.$timelinePlot.height > 100 &&
    css`
        height: calc(var(--TimelineHeight) - 100px) !important;
      `}
  }
`;
TimelineContainer.propTypes = {
  $timelinePlot: PlotType.isRequired,
};
TimelineContainer.displayName = "TimelineContainer";

export const TimelineResize = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: transparent;
  cursor: row-resize;
  border-bottom: 1px solid ${(props) => props.theme.boxShadowColor};
  @media (max-width: 768px) {
    height: 20px;
  }
  &::after {
    content: " ";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 4px;
    height: 5px;
    width: 32px;
    border-radius: 4px;
    border: 1.5px solid ${(props) => props.theme.secondaryColor};
    background-color: ${(props) => props.theme.polarColor};
  }

  &:hover,
  &.tr--is-dragging {
    border-color: ${(props) => props.theme.secondaryBorderColor};
    ::after {
      background: ${(props) => props.theme.secondaryBorderColor};
      border-color: ${(props) => props.theme.secondaryBorderColor};
    }
  }
`;
TimelineResize.displayName = "TimelineResize";

// Components of AudioContextMenu
export const MenuWrapper = styled.div.attrs((props) => {
  return {
    style: {
      top: props.position.y,
      left: props.position.x,
    },
  };
})`
  width: ${(props) => props.width || "100px"};
  position: absolute;
  z-index: 11;
  background: ${(props) => props.theme.secondaryColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px ${(props) => props.theme.rgbaBlack};
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid #424158;
  border-radius: 15px;
  opacity: 1;
  height: 30px;
  padding: 4px;
  opacity: 0.8;
  cursor: pointer;
`;

export const OptionWrapper = styled.div`
   display: flex;
  justify-content: space-between;
  align-items: center;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
`;

export const CloseIcon = styled.img`
  height: 22px;
  width: 22px;
`;

// Components of VolumeTooplip
export const TooltipWrapper = styled.div.attrs((props) => {
  return {
    style: {
      top: props.position.y,
      left: props.position.x,
    },
  };
})`
  background: ${(props) => props.theme.secondaryColor} 0% 0% no-repeat padding-box;;
  border: 2px solid #424158;
  width: 40px;
  height: 20px;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  z-index: 10;
`;

// Timeline Audio player components
export const Icon = styled.img`
  height: 30px;
  width: 30px;
  margin-left: 8px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
`;

export const IconSVG = styled.div`
  svg {
    height: 16px;
    width: 16px;
    margin-left: 8px;
    position: absolute;
    top: 9px;
    path {
      fill: ${(props) => props.theme.svgIconPurple};
    }
    rect {
      fill: ${(props) => props.theme.svgIconPurple};
    }
  }
`;
