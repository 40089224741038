import { TimelineMax, Ease } from "gsap";
import CustomEase from "gsap/CustomEase";
import AppConfig from "../../constants/config";

/**
 * Function to get zoom factor and workspace bounds
 * @param {object} params
 * @param {object} params.workspaceStage
 * @param {boolean | undefined} params.isFit defaults to true
 * @param {number | undefined} params.zoomFactorToUse
 * skips calculating new zoom factor and uses this value if isFit is falsy, defaults to 1
 * @param {number} params.projectWidth
 * width of the project
 * @param {number} params.projectHeight
 * height of the project
 */
export const getZoom = (params = {}) => {
  const {
    workspaceStage,
    isFit = true,
    zoomFactorToUse = 1,
    projectWidth,
    projectHeight,
    isAnimoPlayer,
    isShortsPlayer,
    animoPlayerId
  } = params;
  let zoomFactor = 1;

  let stageLeft =
    workspaceStage.left.menu +
    workspaceStage.left.menuBorder +
    workspaceStage.left.library +
    workspaceStage.left.toggle;
  let stageRight = 0;
  let stageTop = workspaceStage.top.menu + workspaceStage.top.toolbar;
  let stageBottom = workspaceStage.bottom.timeline;

  if (isFit) {
    stageLeft += workspaceStage.left.padding;
    stageTop += workspaceStage.top.padding;
    stageRight += workspaceStage.right.padding;
    stageBottom += workspaceStage.bottom.padding;
  }

  if (AppConfig.ANIMO_RENDER || isAnimoPlayer || isShortsPlayer) {
    stageLeft = 0;
    stageRight = 0;
    stageTop = 0;
    stageBottom = 0;
  }

  let stageWidth;
  let stageHeight;
  try {
    if (isAnimoPlayer || isShortsPlayer) {
      stageWidth = document.getElementById(animoPlayerId).offsetWidth;
      stageHeight = document.getElementById(animoPlayerId).offsetHeight;
    } else {
      stageWidth = window.innerWidth - (stageLeft + stageRight);
      stageHeight = window.innerHeight - (stageTop + stageBottom);
    }
  } catch (er) {
    stageWidth = window.innerWidth - ((stageLeft + stageRight) || 0);
    stageHeight = window.innerHeight - ((stageTop + stageBottom) || 0);
  }

  const cx = stageLeft + stageWidth / 2;
  const cy = stageTop + stageHeight / 2;

  zoomFactor = zoomFactorToUse;
  if (isFit) {
    const widthScale = stageWidth / projectWidth;
    const heightScale = stageHeight / projectHeight;
    zoomFactor = Math.min(widthScale, heightScale);
  }

  if (zoomFactor <= 0) {
    zoomFactor = 0.01;
  }

  return {
    zoomFactor,
    workspaceBounds: { cx, cy, width: stageWidth, height: stageHeight },
  };
};

export const getFlipPosition = (flipValue) => {
  if (flipValue === 1) {
    return " scaleX(-1)";
  }
  if (flipValue === 2) {
    return " scaleY(-1)";
  }
  if (flipValue === 3) {
    return " scaleX(-1) scaleY(-1)";
  }
  return "";
};

export const getUpdatedVolume = (params = {}) => {
  const { audioItem, newVolume } = params;
  const oldVolume = audioItem.get("volume");
  let newFadeDetails = audioItem.get("fadeDetails");

  const volumeDiff = Math.abs(oldVolume - newVolume);

  if (newVolume === 0) {
    newFadeDetails = newFadeDetails.map((fadeDetail) => {
      return fadeDetail.set("a", 0);
    });
  } else {
    newFadeDetails = newFadeDetails.map((fadeDetail) => {
      const currentAmp = fadeDetail.get("a");
      let updatedAmp = currentAmp;

      if (newVolume >= oldVolume) {
        updatedAmp = currentAmp + volumeDiff;
      } else if (newVolume < oldVolume) {
        updatedAmp = currentAmp - volumeDiff;
      }

      if (updatedAmp > 1) {
        updatedAmp = 1;
      } else if (updatedAmp < 0) {
        updatedAmp = 0;
      }

      return fadeDetail.set("a", parseFloat(updatedAmp.toFixed(2)));
    });
  }

  return {
    newVolume,
    newFadeDetails,
  };
};

/**
 * @param {TimelineMax} timeline
 */
export const clearTimeline = (timeline) => {
  if (timeline !== null) {
    timeline.pause(0);
    timeline.getChildren().forEach((val) => {
      if (val.target instanceof TimelineMax) {
        clearTimeline(val.target);
        val.target = null;
      }
      if (val.vars.ease instanceof CustomEase) {
        delete Ease.map[val.vars.ease.id];
      }
      val.kill();
      timeline.remove(val);
    });
    timeline.clear(0);
    timeline.kill();
  }
};

export const getImageSize = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const data = {
        width: img.naturalWidth,
        height: img.naturalHeight
      }
      resolve(data);
    };
    // img.onerror = () => {
    //   reject('Failed to load image');
    // };
    img.src = src;
  });
};

export const getVideoSize = (src) => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = src;
    video.addEventListener("loadedmetadata", function () {
      const data = {
        width: this.videoWidth,
        height: this.videoHeight,
      };
      video.src = ""
      resolve(data);
    });
  });
};
