/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Style from "./style";
import Socket from "./socket";
import createStore from "./redux/createStore";
import AppConfig from "./constants/config";
import initSentry from "./helper/initSentry";
import AnimoPlayer from "./animo-player/animo-player";
import Shorts from "./containers/shorts/shorts";
import Loader from "./common-components/Loader";
import ErrorBoundary from "./common-components/Errorboundary";
import Publisher from "./publish";
import useClarity from "./helper/hoc/useClarity";

const { ENVIRONMENT, ANIMO_RENDER } = AppConfig;

const Main = () => {
  useClarity();
  useEffect(() => {
    initSentry();
  }, []);

  return (
    <BrowserRouter>
      <Style />
      <ErrorBoundary>
        <Socket>
          <Switch>
            <Route path={"/shorts/0ja5lph20net9ssf"} component={Loader} />
            <Route path={"/shorts/:id"} component={Shorts} />
            <Route path={"/render/:id"} render={(props) => <Publisher {...props} isPublish={false} />} />
            <Route path={"/publish/:id"} render={(props) => <Publisher {...props} isPublish={true} />} />
            <Route path={"/preview/:id"} component={AnimoPlayer} />
            <Route path={`${ENVIRONMENT !== "LOCAL" && !ANIMO_RENDER ? "/editproject" : ""}/:id`} component={App} />
          </Switch>
        </Socket>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={createStore()}>
    <Main />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
