/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
// src/hooks/useClarity.js
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setMicrosoftClarity } from "../trackClarity";

const useClarity = () => {
    const userDetails = useSelector((store) => store.userDetails);
    const { userId } = userDetails;
    useEffect(() => {
        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
            t = l.createElement(r);
            t.async = 1;
            t.src = `https://www.clarity.ms/tag/${i}`;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "c1h2mw469f");

        if (window.clarity && userId) {
            setMicrosoftClarity("set", "user_id", userId);
        }

    }, [userId]);
};

export default useClarity;