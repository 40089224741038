import { PLAN } from "../../../constants";
import content from "../../../constants/content";

export const computePrice = (data) => {
    if (data) {
        let amount = 0;
        data.map((stock) => {
            if (!stock.isCredited) {
                amount += stock.amount || 0;
            }
            return 0;
        });
        return amount;
    }
    return 0;
};

export const isExeed = (arg, isShorts, isYoutube = false) => {
    return (
        arg.stockExceed ||
        arg.musicExceed ||
        (!isShorts && !isYoutube && arg.exportExceed) ||
        (isShorts && !isYoutube && arg.lftsfExportExceed) ||
        (isYoutube && arg.socialPublishExceed) ||
        arg.propsExceed ||
        arg.dailyLimit ||
        arg.bgRemovalExceed ||
        arg.durationExceed
    );
};

export const getPaymentValue = (arg, isShorts = false, isYoutube = false) => {
    let totalAssetsCost = Object.values(arg.stockPurchase)
        .flatMap((category) => category.stocks)
        .reduce((acc, stock) => acc + (stock.isCredited === false ? stock.amount : 0), 0);
    if (isYoutube ? arg.socialPublishExceed : isShorts ? arg.lftsfExportExceed : arg.exportExceed) {
        try {
            totalAssetsCost += +arg.export_cost[arg.plan]
        } catch (e) {
            totalAssetsCost += 0;
        }
    }
    return totalAssetsCost;
}

export const getRenderData = (arg) => {
    let musicExceedContent = "";
    switch (arg.planType) {
        case PLAN.TEAM:
            musicExceedContent = content.TEAM_MUSIC_LIMIT;
            break;
        case PLAN.STARTER:
            musicExceedContent = content.STARTER_MUSIC_LIMIT;
            break;
        case PLAN.ENTERPRISE:
            musicExceedContent = `Enterprise can use max. of ${arg.musicAssetExceed} tracks`;
            break;
        default:
            musicExceedContent = "";
            break;
    }
    const returnData = [];
    if (arg.musicExceed) {
        returnData.push({
            mainText: content.MUSIC_USAGE_EXCEEDED,
            subText: musicExceedContent
        })
    }
    if (arg.exportExceed) {
        returnData.push({
            mainText: content.EXPORT_LIMIT_EXCEED,
            subText: `You have exhausted your monthly limit of Exports. Please upgrade for more.`
        })
    }
    if (arg.bgRemovalExceed) {
        returnData.push({
            mainText: content.BACKGROUND_LIMIT_EXCEED,
            subText: `You background removal duration has been exceeded for your current plan`
        })
    }

    if (arg.durationExceed) {
        returnData.push({
            mainText: content.DURATION_LIMIT_EXCEED,
            subText: `Project duration has been exceeded for your current plan.`
        })
    }
    if (arg.stockExceed) {
        returnData.push({
            mainText: content.PREMIUM_USAGE_EXCEEDED,
            subText: arg.planType === PLAN.ENTERPRISE
                ? content.EXHAUSTED_MONTHLY_LIMIT_ENTERPRISE
                : content.EXHAUSTED_MONTHLY_LIMIT
        })
    }
    return returnData;
}